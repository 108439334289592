import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 28px;
  background-color: ${(props) => props.theme.legacyColor.colorEggWhite};
`;

const Content = styled.div`
  flex-grow: 1;
  max-width: 1200px;
  font-size: 0.875rem;
`;

const CampaignWarning = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>{t('strategy.strategyWizard.campaign.warning')}</Content>
    </Container>
  );
};

export default CampaignWarning;
