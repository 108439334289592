import { Routes, Route, Outlet, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import TeamsTreeList from 'team/TeamsTreeList';
import { useTeamAdapter } from 'team/TeamAdapter';
import Space from 'shared/components/Space';
import Button from 'shared/components/Button';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import { canWriteOrgStrategyOrWriteAnyOrgUnit } from 'user/ability/canPerformOrgUnitAction';
import { useActiveOrg } from 'org/ActiveOrgProvider';

import TeamTeamHierarchyLayout from './TeamTeamHierarchyLayout';
import TeamTeamHierarchyProvider, {
  useTeamTeamHierarchy,
} from './TeamTeamHierarchyProvider';
import styles from './TeamTeamHierarchy.module.scss';
import { teamTeamHierarchyRoutingPaths } from './TeamTeamHierarchy.routing.paths';
import TeamTeamHierarchyCreateOrgUnitModal from './TeamTeamHierarchyCreateOrgUnitModal';

const TeamTeamHierarchy = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { activeOrg } = useActiveOrg();
  const { teamAdapter } = useTeamAdapter();

  const { orgUnits } = useTeamTeamHierarchy();

  const handleCreateTeam = useCallback(
    () => navigate(teamTeamHierarchyRoutingPaths.create()),
    [navigate],
  );

  const showCreateOrgUnitButton = canWriteOrgStrategyOrWriteAnyOrgUnit(
    activeOrg,
    orgUnits,
  );

  return (
    <TeamTeamHierarchyLayout>
      <Space className={styles.controls}>
        {showCreateOrgUnitButton && (
          <Button
            icon={PlusIcon}
            iconPosition={'start'}
            onClick={handleCreateTeam}
          >
            {t('team.myTeams.createTeamButton')}
          </Button>
        )}
      </Space>
      <div className={styles.container}>
        <TeamsTreeList
          orgUnits={orgUnits}
          orgKey={teamAdapter.orgKey}
          className={styles.treeList}
          allExpanded={true}
          selectedItemsIds={[teamAdapter.keyArg]}
        />
      </div>
      <Outlet />
    </TeamTeamHierarchyLayout>
  );
};

const TeamTeamHierarchyWithRouting = () => (
  <Routes>
    <Route
      path={teamTeamHierarchyRoutingPaths.root()}
      element={<TeamTeamHierarchy />}
    >
      <Route
        path={teamTeamHierarchyRoutingPaths.create()}
        element={<TeamTeamHierarchyCreateOrgUnitModal />}
      />
    </Route>
  </Routes>
);

const TeamTeamHierarchyWithProvider = () => (
  <TeamTeamHierarchyProvider>
    <TeamTeamHierarchyWithRouting />
  </TeamTeamHierarchyProvider>
);

export default TeamTeamHierarchyWithProvider;
