import { createContext } from 'react';

export type CompletedFilterValue = {
  showCompleted: boolean;
  toggleShowCompleted: () => void;
};

const CompletedFilterContext = createContext<CompletedFilterValue | undefined>(
  undefined,
);

export default CompletedFilterContext;
