import { connect } from 'formik';
import { usePrevious } from 'react-use';
import { useEffect } from 'react';
type FormikEffectProps = {
  onChange?: (values: any) => void;
  onError?: (error: any) => void;
};

const FormikEffect = connect<FormikEffectProps>(
  ({ onChange, onError, formik: { values, errors } }) => {
    const previousValues = usePrevious(values);

    useEffect(() => {
      if (previousValues) {
        onChange?.(values);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, onChange]);

    useEffect(() => {
      if (Object.values(errors).length) {
        onError?.(errors);
      }
    }, [errors, onError]);

    return null;
  },
);

export default FormikEffect;
