import type { MutationUpdaterFunction, ApolloCache } from '@apollo/client';

import type {
  UnlaunchStrategyUpdateOrgMutation,
  UnlaunchStrategyUpdateOrgMutationVariables,
  UnlaunchStrategyUpdateOrgUnitMutation,
  UnlaunchStrategyUpdateOrgUnitMutationVariables,
} from './useUnlaunchStrategy.graphql.generated';

export const unlaunchOrgStrategy: MutationUpdaterFunction<
  UnlaunchStrategyUpdateOrgMutation,
  UnlaunchStrategyUpdateOrgMutationVariables,
  unknown,
  ApolloCache<unknown>
> = (cache, { data }, { variables }) => {
  if (data) {
    cache.modify({
      id: cache.identify(data?.updateOrg),
      fields: {
        currentStrategy: () => null,
      },
    });
  }
  cache.modify({
    id: cache.identify({
      __typename: 'Strategy',
      id: variables!.idToRemove,
    }),
    fields: {
      isLive: () => false,
      isComplete: () => true,
    },
  });
};

export const unlaunchOrgUnitStrategy: MutationUpdaterFunction<
  UnlaunchStrategyUpdateOrgUnitMutation,
  UnlaunchStrategyUpdateOrgUnitMutationVariables,
  unknown,
  ApolloCache<unknown>
> = (cache, { data }, { variables }) => {
  if (data) {
    cache.modify({
      id: cache.identify(data.updateOrgUnit),
      fields: {
        currentStrategy: () => null,
      },
    });
  }
  cache.modify({
    id: cache.identify({
      __typename: 'Strategy',
      id: variables!.idToRemove,
    }),
    fields: {
      isLive: () => false,
      isComplete: () => true,
    },
  });
};
