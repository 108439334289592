import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import styles from './SiderDescription.module.scss';
import Heading from '../Heading';
import Paragraph from '../Paragraph/Paragraph';

type SiderDescriptionProps = {
  className?: string;
  description: Maybe<string>;
};

const SiderDescription = ({
  description,
  className,
}: SiderDescriptionProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.description, className)}>
      <Heading
        level={3}
        as={5}
        hasMargin={false}
        className={cn({ [styles.heading]: !!description })}
      >
        {t('objective.objectiveLayout.sider.description.heading')}
      </Heading>
      {description ? (
        <Paragraph hasMargin={false} className={styles.descriptionText}>
          {description}
        </Paragraph>
      ) : null}
    </div>
  );
};

export default SiderDescription;
