import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash';

import Heading from 'shared/components/Heading';
import Text from 'shared/components/Text';
import Space from 'shared/components/Space';
import { useTeamAdapter } from 'team/TeamAdapter';

import type { StrategyRoadmapSideModalProps } from '../StrategyRoadmapSideModal';
import { illustrations, backgroundColors } from '../StrategyRoadmap.static';
import StrategyRoadmapSideModal from '../StrategyRoadmapSideModal';
import type { StrategyRoadmapStrategyFieldsFragment } from '../StrategyRoadmapProvider/StrategyRoadmapProvider.graphql.generated';
import { getEditStrategyPath } from '../StrategyRoadmap.utils';

type StrategyRoadmapVisionModalProps = {
  showEditButton: boolean;
  strategy: StrategyRoadmapStrategyFieldsFragment;
} & Pick<StrategyRoadmapSideModalProps, 'isOpen' | 'onClose'>;

const StrategyRoadmapVisionModal = ({
  strategy,
  isOpen,
  onClose,
  showEditButton,
}: StrategyRoadmapVisionModalProps) => {
  const { vision } = strategy;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { teamAdapter } = useTeamAdapter();

  const content = useMemo(
    () => (
      <Space direction={'vertical'}>
        <Heading level={3} hasMargin={false}>
          {vision?.name}
        </Heading>
        <Text variant={'emphasis'}>{vision?.description}</Text>
      </Space>
    ),
    [vision],
  );

  const handleEdit = useCallback(() => {
    onClose();
    navigate(getEditStrategyPath(teamAdapter, strategy, 'vision'));
  }, [onClose, navigate, teamAdapter, strategy]);

  return (
    <>
      <StrategyRoadmapSideModal
        top={{
          illustration: illustrations.vision,
          backgroundColor: backgroundColors.vision,
        }}
        heading={capitalize(t('strategy.vision'))}
        description={t('strategy.strategyRoadmap.vision.modal.description')}
        updateDateTime={vision?.auditRecord?.updateDateTime}
        onEdit={showEditButton ? handleEdit : undefined}
        isOpen={isOpen}
        onClose={onClose}
      >
        {content}
      </StrategyRoadmapSideModal>
    </>
  );
};

export default StrategyRoadmapVisionModal;
