import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import compact from 'lodash/compact';

import ContextMenu from 'shared/components/ContextMenu';
import type { ContextMenuOnActionFn } from 'shared/components/ContextMenu';
import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';
import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';
import { ReactComponent as DetailsIcon } from 'shared/static/icons/icon-details.svg';
import { ReactComponent as DownloadIcon } from 'shared/static/icons/icon-download.svg';
import useCanPerformOrgOrOrgUnitAction from 'user/ability/useCanPerformOrgOrOrgUnitAction';

import type { ReportsTableRow } from '../ReportsTable.type';
import type { ReportsTableContextMenuItem } from './ReportsTableContextMenu.type';

export type ReportsTableContextMenuProps = {
  onAction: ContextMenuOnActionFn<ReportsTableContextMenuItem['id']>;
  row: ReportsTableRow;
};

const ReportsTableContextMenu = ({
  row,
  onAction,
}: ReportsTableContextMenuProps) => {
  const { t } = useTranslation();

  const canWrite = useCanPerformOrgOrOrgUnitAction('WRITE_STRATEGY', 'WRITE');

  const items = useMemo<ReportsTableContextMenuItem[]>(
    () =>
      compact([
        row.reportStage === 'PUBLISHED' && {
          id: 'open',
          title: t('open'),
          icon: DetailsIcon,
        },
        canWrite && {
          id: 'edit',
          title: t('edit'),
          icon: EditIcon,
        },
        row.reportStage === 'PUBLISHED' && {
          id: 'export',
          title: t('export'),
          icon: DownloadIcon,
        },
        canWrite && {
          id: 'delete',
          title: t('remove'),
          variant: 'danger',
          icon: TrashIcon,
        },
      ]),
    [canWrite, row.reportStage, t],
  );

  return (
    <ContextMenu<ReportsTableContextMenuItem>
      items={items}
      onAction={onAction}
    />
  );
};

export default ReportsTableContextMenu;
