import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useState } from 'react';
import { css } from '@emotion/react';

import Drawer from 'shared/components/Drawer';
import useScrollToBottom from 'shared/hooks/useScrollToBottom';
import Text from 'shared/components/Text';
import Tag from 'shared/components/Tag';

import type { PromptMessage } from './Prompt';
import Prompt from './Prompt';
import MessageItem from './MessageItem';
import Start from './Start';

export type SummaryMessage = {
  actionIds: string[];
  hints?: string[];
  initiativeIds: string[];
  metricIds: string[];
  numberOfCharacters: number;
  objectiveIds: string[];
  strategyId?: string;
  temperature: number;
  type: 'SummaryMessage';
};

export type InputMessage = {
  content: string;
  type: 'InputMessage';
};

export type Message = SummaryMessage | InputMessage;

const Container = styled.div`
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
`;

const StartContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const MessagesContainer = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  overflow-y: auto;
`;

const Messages = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 24px;
  gap: 16px;
  padding: 16px 16px 0 16px;
`;

const PromptContainer = styled.div<{ isScrolledToBottom: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px;
  box-shadow: ${(props) =>
    props.isScrolledToBottom
      ? 'none'
      : '0px 15px 12px 10px rgba(0, 0, 0, 0.8)'};
  z-index: 10;
`;

type Props = {
  infoText: string;
  isOpen: boolean;
  onClose: () => void;
  promptMessages: PromptMessage[];
};

const StrategySummarySidePanel = ({
  isOpen,
  onClose,
  promptMessages,
  infoText,
}: Props) => {
  const { t } = useTranslation();
  const {
    isScrolledToBottom,
    scrollToBottom,
    setScrollableDiv,
    setGrowableDiv,
  } = useScrollToBottom();

  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);

  const handleClose = () => {
    onClose();
    setMessages([]);
    setIsLoading(false);
  };

  const addMessage = (message: Message) => {
    setMessages((messages) => [...messages, message]);
  };

  const isChatEmpty = messages.length === 0;

  return (
    <Drawer
      isOpen={isOpen}
      onClose={handleClose}
      width={'medium'}
      heading={
        <>
          {t('ai.strategySummary.title')}
          <Tag variant={'primary'} css={css({ marginLeft: 12 })}>
            {t('ai.strategySummary.experimental')}
          </Tag>
        </>
      }
      padding={'none'}
      scrollbarGutter={'auto'}
    >
      <Container>
        {isChatEmpty ? (
          <StartContainer>
            <Start infoText={infoText} />
          </StartContainer>
        ) : (
          <MessagesContainer ref={setScrollableDiv}>
            <Messages ref={setGrowableDiv}>
              {messages.map((message, index) => (
                <MessageItem
                  key={`${index}-${message.type}`}
                  message={message}
                  setIsLoading={setIsLoading}
                />
              ))}
            </Messages>
          </MessagesContainer>
        )}

        <PromptContainer
          isScrolledToBottom={isChatEmpty ? true : isScrolledToBottom}
        >
          <Prompt
            messages={promptMessages}
            addMessage={addMessage}
            isLoading={isLoading}
            scrollToBottom={scrollToBottom}
          />
          <Text size={'tiny'} isCentered={true} css={css({ maxWidth: 500 })}>
            {t('ai.strategySummary.disclaimer')}
          </Text>
        </PromptContainer>
      </Container>
    </Drawer>
  );
};

export default StrategySummarySidePanel;
