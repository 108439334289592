import { css } from '@emotion/react';

export const getResetButtonStyle = () => css`
  background: unset;
  border: unset;
  cursor: unset;

  &:focus {
    outline: unset;
  }

  &:active {
    color: unset;
  }
`;
