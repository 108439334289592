import { useTranslation } from 'react-i18next';

import type { CampaignQuestionType } from 'types.graphql.generated';
import Emoji from 'shared/components/Emoji';
import useHandleApolloError from 'shared/errors/useHandleApolloError';

import { useCampaignResponsesQuery } from './CampaignResponses.graphql.generated';

type Props = {
  campaignId: string;
  questionType: CampaignQuestionType;
};

const ResponseIcon = ({ campaignId, questionType }: Props) => {
  const handleApolloError = useHandleApolloError();

  const { data } = useCampaignResponsesQuery({
    variables: { campaignId },
    onError: handleApolloError,
  });

  const { t } = useTranslation();

  if (!data) return null;

  const answer = data.campaign.currentUserResponse?.answers.find(
    (answer) => answer.question.questionType === questionType,
  );

  if (!answer) return null;

  return (
    <Emoji
      icon={t(
        `toolkit.toolPage.walkOfStrategy.survey.anwer.${answer.rating}.icon`,
      )}
    />
  );
};

export default ResponseIcon;
