import { createContext } from 'react';

import type { ObjectiveActionsQuery } from './ObjectiveActionsProvider.graphql.generated';

export type ActionsContextValue = {
  isObjectiveActionsLoading: boolean;
  objective: Maybe<ObjectiveActionsQuery['objective']>;
};

export const ActionsContext = createContext<ActionsContextValue | undefined>(
  undefined,
);
