import type { ContributionMyFollowUpsOneThingCampaignFragment } from 'contribution/ContributionMyFollowUpsViewMore/ContributionMyFollowUpsViewMore.graphql.generated';
import OneThingCreateTaskButton from 'toolkit/OneThingCampaignRequestButton/OneThingCreateTaskButton';
import OneThingUpdateTaskButton from 'toolkit/OneThingCampaignRequestButton/OneThingUpdateTaskButton';
import type { ButtonVariant } from 'shared/components/Button';

type Props = {
  campaign: ContributionMyFollowUpsOneThingCampaignFragment;
  variant: ButtonVariant;
};

const OneThingCampaignRequestButton = ({ campaign, variant }: Props) => {
  const response = campaign.currentUserTask;

  const isCampaignActive = campaign.state.stage === 'ACTIVE';
  const isTaskCreated = !!response;
  const isTaskDone = response?.status === 'DONE';

  if (isTaskCreated) {
    return (
      <OneThingUpdateTaskButton
        task={{ ...response, campaign }}
        isReadOnly={!isCampaignActive || isTaskDone}
        variant={variant}
      />
    );
  }

  if (isCampaignActive) {
    return <OneThingCreateTaskButton campaign={campaign} variant={variant} />;
  }

  return null;
};

export default OneThingCampaignRequestButton;
