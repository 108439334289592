import styled from '@emotion/styled';
import { RadioGroup } from 'react-aria-components';
import { sortBy, uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import type { OrgUnit } from 'types.graphql.generated';
import Flex from 'shared/components/Flex';
import Text from 'shared/components/Text';

import type { CreateTaskObjective } from '../OneThingCreateTaskButton.types';
import ObjectivesSelectionSection from './ObjectivesSelectionSection';

const SeletionContainer = styled.div`
  .react-aria-RadioGroup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .react-aria-Radio {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &:before {
      content: '';
      display: block;
      width: 1rem;
      min-width: 1rem;
      height: 1rem;
      box-sizing: border-box;
      border: 0.143rem solid ${(props) => props.theme.color.strokeDark};
      border-radius: 1.286rem;
      transition: all 200ms;
    }

    &[data-pressed]:before {
      border-color: ${(props) => props.theme.color.primary};
    }

    &[data-selected] {
      &:before {
        background-color: ${(props) => props.theme.color.primary};
        border-color: ${(props) => props.theme.color.primary};
      }

      &[data-pressed]:before {
        border-color: ${(props) => props.theme.color.primary};
      }
    }

    &[data-focus-visible]:before {
      outline: 2px solid ${(props) => props.theme.color.primary};
      outline-offset: 2px;
    }
  }
`;

type Props = {
  objectives: CreateTaskObjective[];
  orgUnits: Pick<OrgUnit, 'id' | 'name'>[];
  selectedObjective?: CreateTaskObjective;
  setSelectedObjective: (objective?: CreateTaskObjective) => void;
};

const ObjectivesSelection = ({
  selectedObjective,
  setSelectedObjective,
  objectives,
  orgUnits,
}: Props) => {
  const { t } = useTranslation();
  const themes = uniqBy(
    objectives.map((objective) => objective.theme).filter(Boolean),
    (theme) => theme.id,
  );
  const sortedThemes = [
    ...sortBy(themes, (theme) => theme.orderNumber),
    undefined,
  ];

  if (objectives.length === 0)
    return (
      <Text isCentered={true}>
        {t('toolkit.toolPage.oneThing.createTask.wizard.selectObjective.empty')}
      </Text>
    );

  return (
    <SeletionContainer>
      <RadioGroup
        value={selectedObjective?.id}
        onChange={(newValue) =>
          setSelectedObjective(
            objectives.find((objective) => objective.id === newValue),
          )
        }
      >
        <Flex direction={'column'} gap={'2rem'}>
          {orgUnits.flatMap((orgUnit) =>
            sortedThemes.map((theme) => (
              <ObjectivesSelectionSection
                key={`${orgUnit.id}-${theme?.id}`}
                orgUnit={orgUnit}
                theme={theme}
                objectives={objectives}
              />
            )),
          )}

          {sortedThemes.map((theme) => (
            <ObjectivesSelectionSection
              key={`org-${theme?.id}`}
              theme={theme}
              objectives={objectives}
            />
          ))}
        </Flex>
      </RadioGroup>
    </SeletionContainer>
  );
};

export default ObjectivesSelection;
