import type * as Types from '../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteInitiativesMutationVariables = Types.Exact<{
  initiativeId: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type DeleteInitiativesMutation = { __typename: 'Mutation', deleteInitiatives: boolean };


export const DeleteInitiativesDocument = gql`
    mutation DeleteInitiatives($initiativeId: [ID!]!) {
  deleteInitiatives(idsToDelete: $initiativeId)
}
    `;
export type DeleteInitiativesMutationFn = Apollo.MutationFunction<DeleteInitiativesMutation, DeleteInitiativesMutationVariables>;

/**
 * __useDeleteInitiativesMutation__
 *
 * To run a mutation, you first call `useDeleteInitiativesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInitiativesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInitiativesMutation, { data, loading, error }] = useDeleteInitiativesMutation({
 *   variables: {
 *      initiativeId: // value for 'initiativeId'
 *   },
 * });
 */
export function useDeleteInitiativesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInitiativesMutation, DeleteInitiativesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInitiativesMutation, DeleteInitiativesMutationVariables>(DeleteInitiativesDocument, options);
      }
export type DeleteInitiativesMutationHookResult = ReturnType<typeof useDeleteInitiativesMutation>;
export type DeleteInitiativesMutationResult = Apollo.MutationResult<DeleteInitiativesMutation>;
export type DeleteInitiativesMutationOptions = Apollo.BaseMutationOptions<DeleteInitiativesMutation, DeleteInitiativesMutationVariables>;