import type * as Types from '../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteObjectiveMutationVariables = Types.Exact<{
  objectiveId: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type DeleteObjectiveMutation = { __typename: 'Mutation', deleteObjectives: boolean };


export const DeleteObjectiveDocument = gql`
    mutation DeleteObjective($objectiveId: [ID!]!) {
  deleteObjectives(idsToDelete: $objectiveId)
}
    `;
export type DeleteObjectiveMutationFn = Apollo.MutationFunction<DeleteObjectiveMutation, DeleteObjectiveMutationVariables>;

/**
 * __useDeleteObjectiveMutation__
 *
 * To run a mutation, you first call `useDeleteObjectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteObjectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteObjectiveMutation, { data, loading, error }] = useDeleteObjectiveMutation({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *   },
 * });
 */
export function useDeleteObjectiveMutation(baseOptions?: Apollo.MutationHookOptions<DeleteObjectiveMutation, DeleteObjectiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteObjectiveMutation, DeleteObjectiveMutationVariables>(DeleteObjectiveDocument, options);
      }
export type DeleteObjectiveMutationHookResult = ReturnType<typeof useDeleteObjectiveMutation>;
export type DeleteObjectiveMutationResult = Apollo.MutationResult<DeleteObjectiveMutation>;
export type DeleteObjectiveMutationOptions = Apollo.BaseMutationOptions<DeleteObjectiveMutation, DeleteObjectiveMutationVariables>;