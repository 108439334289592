import type { PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import type { Level } from 'shared/components/Heading';
import Heading from 'shared/components/Heading';
import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';
import type { IconButtonProps } from 'shared/components/IconButton';
import IconButton from 'shared/components/IconButton';
import Space from 'shared/components/Space';
import { layoutContentMaxWidth } from 'shared/styles/sizes';

const Header = styled.header<
  Pick<
    ModalHeaderProps,
    'forFullSizeModal' | 'belowHeading' | 'description' | 'hasPadding'
  >
>`
  border-bottom: 1px solid ${(props) => props.theme.legacyColor.colorGallery};
  flex-shrink: 0;
  height: ${(props) =>
    props.belowHeading
      ? 'unset'
      : props.forFullSizeModal
      ? '4rem'
      : props.description
      ? '5rem'
      : '6.625rem'};
  padding: ${(props) => (props.description ? 0 : '1.25rem 0')};
  padding-bottom: ${(props) => (props.belowHeading ? 0 : '1.25rem')};
  position: relative;

  ${(props) =>
    props.hasPadding &&
    css`
      padding-left: 2rem;
      padding-right: 2rem;
    `}
`;

const HeaderContent = styled.div<
  Pick<ModalHeaderProps, 'belowHeading'> & { hasChildren: boolean }
>`
  display: ${(props) => (props.hasChildren ? 'grid' : 'flex')};
  padding: ${(props) => (props.hasChildren ? '0 2rem' : 0)};
  align-items: center;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  height: ${(props) => (props.belowHeading ? '2rem' : '100%')};
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${layoutContentMaxWidth};
  position: relative;
`;

const StyledHeading = styled(Heading)<
  Pick<ModalHeaderProps, 'forFullSizeModal' | 'isHeadingCentered'>
>`
  align-self: ${(props) => (props.forFullSizeModal ? 'auto' : 'flex-end')};
  margin: 0 1rem 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${(props) =>
    props.isHeadingCentered &&
    css`
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    `}
`;

const HeadingDescription = styled.span`
  color: ${(props) => props.theme.legacyColor.colorDoveGray};
  font-size: 0.9rem;
`;

const HeaderChildrenContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const HeaderButtons = styled(Space)`
  position: relative;
  right: 3rem;
`;

const HeadingAndDescription = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`;

const BelowHeading = styled.div<{ fullWidth: boolean }>`
  margin: 2.5rem auto 0;
  max-width: ${(props) => (props.fullWidth ? '100%' : layoutContentMaxWidth)};
`;

const CloseButton = styled(IconButton)<
  Pick<ModalHeaderProps, 'forFullSizeModal' | 'isHeadingCentered'>
>`
  position: relative;

  ${(props) =>
    (props.forFullSizeModal || props.isHeadingCentered) &&
    css`
      left: auto;
      position: absolute;
      right: 0;
    `}
`;

export type ModalHeaderProps = PropsWithChildren<{
  belowHeading?: ReactNode;
  buttons?: Omit<IconButtonProps, 'variant'>[];
  closeButtonClassName?: string;
  description?: string;
  forFullSizeModal: boolean;
  hasPadding?: boolean;
  heading?: string | ReactNode;
  headingLevel?: Level;
  isBelowHeadingFullWidth?: boolean;
  isHeadingCentered?: boolean;
  onClose: () => void;
}>;

const ModalHeader = ({
  isHeadingCentered = false,
  forFullSizeModal,
  onClose,
  heading,
  headingLevel,
  belowHeading,
  description,
  isBelowHeadingFullWidth,
  hasPadding = true,
  buttons,
  children,
  closeButtonClassName,
}: ModalHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Header
      forFullSizeModal={forFullSizeModal}
      belowHeading={belowHeading}
      hasPadding={hasPadding}
    >
      <HeaderContent hasChildren={!!children} belowHeading={belowHeading}>
        <HeadingAndDescription>
          <StyledHeading
            level={headingLevel || 2}
            as={headingLevel || 5}
            forFullSizeModal={forFullSizeModal}
            isHeadingCentered={isHeadingCentered}
          >
            {heading}
          </StyledHeading>
          {description && (
            <HeadingDescription role={'heading'} aria-level={3}>
              {description}
            </HeadingDescription>
          )}
        </HeadingAndDescription>
        {children && (
          <HeaderChildrenContainer>{children}</HeaderChildrenContainer>
        )}
        {buttons?.length ? (
          <HeaderButtons>
            {buttons?.map((button, buttonIndex) => (
              <IconButton {...button} variant={'outlined'} key={buttonIndex} />
            ))}
          </HeaderButtons>
        ) : null}
        <CloseButton
          forFullSizeModal={forFullSizeModal}
          icon={CloseIcon}
          onClick={onClose}
          className={closeButtonClassName}
        >
          {t('modal.closeButton')}
        </CloseButton>
      </HeaderContent>
      {belowHeading ? (
        <BelowHeading fullWidth={!!isBelowHeadingFullWidth}>
          {belowHeading}
        </BelowHeading>
      ) : null}
    </Header>
  );
};

export default ModalHeader;
