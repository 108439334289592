import type { ReactNode } from 'react';
import { useMemo } from 'react';
import styled from '@emotion/styled';

import { groupByTimeUnit, sortByYearDesc } from './ActivityTimeline.utils';
import type { RenderActivityTimelineRowContentParams } from './ActivityTimeline.type';
import ActivityTimelineSection from './ActivityTimelineSection';

const ListItem = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export type ActivityTimelineProps<ActivityTimelineItem> = {
  className?: string;
  itemDateKey: string;
  items: ActivityTimelineItem[];
  renderFirstRowContent?: () => ReactNode;
  renderLastRowContent?: () => ReactNode;
  renderRowContent: (
    params: RenderActivityTimelineRowContentParams<ActivityTimelineItem>,
  ) => ReactNode;
};

/**
 * @param {Array} items - items to display on ActivityTimeline
 * @param {string} itemDateKey- the potentially nested keypath to date variable by which to group items, e.g `auditRecord.createDateTime`
 * @param {Function} renderFirstRowContent - the function to render content for a row
 * @param {Function=} renderFirstRowContent - the function to render content for the first row
 * @param {Function=} renderLastRowContent - the function to render content for the last row
 */
const ActivityTimeline = <ActivityItem extends { id: string }>({
  itemDateKey,
  items: itemsProp,
  renderRowContent,
  renderFirstRowContent,
  renderLastRowContent,
  className,
}: ActivityTimelineProps<ActivityItem>) => {
  const itemsGroupedByTimeUnit = useMemo(
    () => sortByYearDesc(groupByTimeUnit(itemsProp, { key: itemDateKey })),
    [itemsProp, itemDateKey],
  );

  const hasChildItems = !!itemsGroupedByTimeUnit.length;

  return (
    <article className={className}>
      {hasChildItems ? (
        itemsGroupedByTimeUnit.map((timePeriodItem, timePeriodItemIndex) => {
          const timePeriod = timePeriodItem[0];
          const timePeriodContent = timePeriodItem[1];
          const isCurrentTimePeriod = timePeriodItemIndex === 0;
          const isOldestTimePeriod =
            timePeriodItemIndex === itemsGroupedByTimeUnit.length - 1;

          return (
            <ActivityTimelineSection
              items={timePeriodContent}
              timePeriod={timePeriod}
              isCurrentTimePeriod={isCurrentTimePeriod}
              isOldestTimePeriod={isOldestTimePeriod}
              renderRowContent={renderRowContent}
              renderFirstRowContent={renderFirstRowContent}
              renderLastRowContent={renderLastRowContent}
              key={timePeriod}
            />
          );
        })
      ) : (
        <ListItem>{renderLastRowContent?.()}</ListItem>
      )}
    </article>
  );
};

export default ActivityTimeline;
