import { useDefinedContext } from 'shared/utils/context.utils';

import { InsightsReportWizardContext } from './InsightsReportWizardProvider.context';
import InsightsReportWizardProvider from './InsightsReportWizardProvider';

export const useInsightsReportWizard = () =>
  useDefinedContext(InsightsReportWizardContext, {
    hookName: useInsightsReportWizard.name,
    providerName: InsightsReportWizardProvider.name,
  });
