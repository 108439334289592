import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from 'shared/components/Button';
import Space from 'shared/components/Space';
import type { ModalProps } from 'shared/components/__DEPRECATED__/Modal';
import Modal from 'shared/components/__DEPRECATED__/Modal';
import type { Initiative, Objective } from 'types.graphql.generated';
import { ReactComponent as ArrowRightIcon } from 'shared/static/icons/icon-arrow-right.svg';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';

export type InitiativeCreated = Pick<Initiative, 'id' | 'name'> & {
  objective?: Pick<Objective, 'id'>;
};

type Props = Pick<ModalProps, 'onClose' | 'isOpen'> & {
  initiative?: InitiativeCreated;
};

const InitiativeCreatedDialog = ({ initiative, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamAdapter } = useTeamAdapter();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      heading={t('initiative.createInitiative.success.heading')}
      size={'small'}
    >
      {initiative ? (
        <Space direction={'vertical'}>
          <Button
            icon={ArrowRightIcon}
            onClick={() => {
              initiative.objective &&
                navigate(
                  objectiveRoutesPaths.initiatives.initiative.root({
                    params: {
                      objectiveId: initiative.objective?.id,
                      teamSlug: teamAdapter.toParam(),
                      initiativeId: initiative.id,
                    },
                  }),
                );
            }}
          >
            {initiative.name}
          </Button>
          <p>{t('initiative.createInitiative.success.content')}</p>
        </Space>
      ) : null}
    </Modal>
  );
};

export default InitiativeCreatedDialog;
