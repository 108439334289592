import { useDefinedContext } from 'shared/utils/context.utils';

import { ThemeContext } from './ThemesProvider.context';
import ThemesProvider from './ThemesProvider';

export const useThemes = () =>
  useDefinedContext(ThemeContext, {
    hookName: useThemes.name,
    providerName: ThemesProvider.name,
  });
