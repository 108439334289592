import type { PropsWithChildren } from 'react';
import { css } from '@emotion/react';

type SideModalContentProps = PropsWithChildren<{
  className?: string;
}>;

const SideModalContent = ({ className, children }: SideModalContentProps) => (
  <div
    className={className}
    css={css`
      padding: 0 2rem;
    `}
  >
    {children}
  </div>
);

export default SideModalContent;
