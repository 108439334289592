import type {
  ComponentType,
  ElementType,
  PropsWithChildren,
  SVGProps,
} from 'react';
import cn from 'classnames';
import type { To } from 'react-router-dom';
import { Link } from 'react-router-dom';

import styles from './OverviewLinkItem.module.scss';

type OverviewLinkItemProps = PropsWithChildren<{
  as?: ElementType;
  className?: string;
  icon: ComponentType<SVGProps<SVGSVGElement>>;
  to: To;
}>;

const OverviewLinkItem = ({
  children,
  className,
  as: Component = 'li',
  icon: Icon,
  to,
}: OverviewLinkItemProps) => (
  <Component className={cn(styles.container, className)}>
    <Link to={to} className={styles.link}>
      <Icon className={styles.icon} />
      {children}
    </Link>
  </Component>
);

export default OverviewLinkItem;
