import { useDefinedContext } from 'shared/utils/context.utils';

import { TeamMembersContext } from './TeamMembersProvider.context';
import TeamMembersProvider from './TeamMembersProvider';

export const useTeamMembers = () =>
  useDefinedContext(TeamMembersContext, {
    hookName: useTeamMembers.name,
    providerName: TeamMembersProvider.name,
  });
