import cn from 'classnames';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useTheme } from '@emotion/react';

import { StatusIndicator } from 'shared/status/StatusIndicator';
import type { Size } from 'shared/types/size.type';
import { ReactComponent as CompletedStatusMarkIcon } from 'shared/static/icons/icon-status-mark-completed.svg';

import styles from './StatusMark.module.scss';

export type StatusMarkProps = {
  className?: string;
  hasBorder?: boolean;
  size?: Extract<Size, 'medium' | 'small'>;
  statusIndicator?: Maybe<StatusIndicator>;
};

const StatusMark = ({
  size = 'medium',
  hasBorder = false,
  statusIndicator = new StatusIndicator(),
  className,
}: StatusMarkProps) => {
  const theme = useTheme();
  const isCompleted = statusIndicator?.isCompleted;
  const color = statusIndicator?.getColor();
  const name = statusIndicator?.getName();

  const inside = useMemo<ReactNode>(() => {
    if (isCompleted) {
      return (
        <CompletedStatusMarkIcon
          className={styles.statusMarkInside}
          color={theme.color.primary}
        />
      );
    }

    return (
      <div
        className={cn(
          styles.statusMarkInside,
          styles.statusMarkInsideWithBackground,
        )}
        style={{
          backgroundColor: color,
        }}
      />
    );
  }, [isCompleted, color, theme.color.primary]);

  return (
    <div
      role={'img'}
      className={cn(
        styles.statusMark,
        {
          [styles.statusMarkBorder]: hasBorder,
          [styles.statusMarkSmall]: size === 'small',
          [styles.statusMarkMedium]: size === 'medium',
          [styles.statusMarkIcon]: isCompleted,
        },
        className,
      )}
      aria-label={name}
      title={name}
      style={{
        backgroundColor: color && `rgba(${color}, .3)`,
      }}
    >
      {inside}
    </div>
  );
};

export default StatusMark;
