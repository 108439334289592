import type { ReactNode } from 'react';
import { useRef } from 'react';
import { DismissButton, Overlay, usePopover } from 'react-aria';
import type { AriaPopoverProps } from 'react-aria';
import type { OverlayTriggerState } from 'react-stately';

import styles from './Popover.module.scss';

type PopoverProps = {
  children: ReactNode;
  state: OverlayTriggerState;
} & Omit<AriaPopoverProps, 'popoverRef'>;

const Popover = ({ children, state, ...props }: PopoverProps) => {
  const popoverRef = useRef(null);
  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      popoverRef,
    },
    state,
  );

  return (
    <Overlay>
      <div {...underlayProps} className={styles.underlay} />
      <div {...popoverProps} ref={popoverRef} style={popoverProps.style}>
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
};

export default Popover;
