import { useField } from 'formik';
import { useEffect } from 'react';

import { getAiSuggestionField } from 'ai/getAiSuggestionField';

import { useSuggestions } from './SuggestionsProvider';

type Props = {
  fieldName?: string;
  suggestionField: string;
};

const AiSuggestionsInputField = ({
  suggestionField,
  fieldName = suggestionField,
}: Props) => {
  const { selectedSuggestion } = useSuggestions();

  const [field, _meta, helpers] = useField(fieldName);

  useEffect(() => {
    if (!selectedSuggestion) return;

    const currentValue = field.value;
    const suggestedValue = getAiSuggestionField(
      selectedSuggestion,
      suggestionField,
    );

    if (currentValue === suggestedValue) return;

    const newValue =
      currentValue === ''
        ? suggestedValue
        : `${currentValue} ${suggestedValue}`;

    helpers.setValue(newValue).then(() => {
      helpers.setTouched(true);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSuggestion]);

  return null;
};

export default AiSuggestionsInputField;
