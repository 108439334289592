import * as yup from 'yup';

import { i18n } from './i18n.service';

i18n.on('loaded', () => {
  yup.setLocale({
    mixed: {
      required: i18n.t('missingValue'),
    },
    string: {
      email: i18n.t('invalidEmail'),
    },
  });
});

i18n.on('languageChanged', () => {
  yup.setLocale({
    mixed: {
      required: i18n.t('missingValue'),
    },
    string: {
      email: i18n.t('invalidEmail'),
    },
  });
});

export { yup };
