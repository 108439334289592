import type { RefObject } from 'react';
import { useState, useEffect } from 'react';

export default function useElementWidth<T extends HTMLElement>(
  ref: RefObject<T>,
): number | undefined {
  const [width, setWidth] = useState<number>();

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);

  return width;
}
