import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Modal from 'shared/components/__DEPRECATED__/Modal';

import CampaignResponseContent from './CampaignResponseContent';
import QuestionsProvider from './QuestionsProvider';
import ChatProvider from './ChatProvider';

const WalkOfStrategyCampaignResponse = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal
      isOpen={true}
      onClose={() => navigate(-1)}
      size={'full'}
      scrollType={'outer'}
      heading={t('toolkit.tools.walkOfStrategy.name')}
    >
      <ChatProvider>
        <QuestionsProvider>
          <CampaignResponseContent />
        </QuestionsProvider>
      </ChatProvider>
    </Modal>
  );
};

export default WalkOfStrategyCampaignResponse;
