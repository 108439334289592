import { useDefinedContext } from 'shared/utils/context.utils';

import { ActionStatusMultiSelectProviderContext } from './ActionStatusMultiSelectProvider.context';
import ActionStatusMultiSelectProvider from './ActionStatusMultiSelectProvider';

export const useActionStatusMultiSelect = () =>
  useDefinedContext(ActionStatusMultiSelectProviderContext, {
    hookName: useActionStatusMultiSelect.name,
    providerName: ActionStatusMultiSelectProvider.name,
  });
