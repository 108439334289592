import styled from '@emotion/styled';

import type { FollowUpActivityItem } from './ContributionMyFollowUpsViewMore.type';
import TimelineEntryContentStatusRequest from './TimelineEntryContentStatusRequest';
import TimelineEntryContentCampaign from './TimelineEntryContentCampaign';
import TimelineEntryContentOneThingCampaign from './TimelineEntryContentOneThingCampaign';

const Container = styled.div`
  display: flex;
`;

const InnerContainer = styled.div`
  border: 1px solid ${(props) => props.theme.color.neutral3};
  border-radius: 12px;
  flex-grow: 1;
  overflow: hidden;
`;

type Props = {
  item: FollowUpActivityItem;
};

const TimelineEntryContent = ({ item }: Props) => {
  switch (item.type) {
    case 'status-request':
      return (
        <Container>
          <InnerContainer>
            <TimelineEntryContentStatusRequest item={item} />
          </InnerContainer>
        </Container>
      );
    case 'walk-of-strategy':
      return (
        <Container>
          <InnerContainer>
            <TimelineEntryContentCampaign item={item} />
          </InnerContainer>
        </Container>
      );
    case 'one-thing':
      return (
        <Container>
          <InnerContainer>
            <TimelineEntryContentOneThingCampaign item={item} />
          </InnerContainer>
        </Container>
      );
  }
};

export default TimelineEntryContent;
