import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';

import Text from 'shared/components/Text';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import { ReactComponent as CheckIcon } from 'shared/static/icons/icon-check.svg';
import Flex from 'shared/components/Flex';
import { getAiSuggestionField } from 'ai/getAiSuggestionField';

import type { Suggestion } from './MultiAiSuggestions';

const Container = styled.div`
  display: flex;
  gap: 8px;
`;

const Position = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 20px;
  color: ${(props) => props.theme.color.typoSecondary};
  margin-top: 5px;
`;

const Title = styled.div`
  margin-top: 5px;
`;

const Button = styled.button<{
  isRecentlySelected: boolean;
  isSelected: boolean;
}>`
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  pointer-events: ${(props) => (props.isRecentlySelected ? 'none' : 'auto')};

  background-color: ${(props) =>
    props.isSelected
      ? props.theme.legacyColor.colorAiPurpleButtonPressed
      : props.theme.color.white};

  &:hover {
    background-color: ${(props) => props.theme.color.white};
  }

  svg {
    width: 16px;
    height: 16px;
  }

  .check-icon {
    display: ${(props) => (props.isSelected ? 'inline-block' : 'none')};
  }

  .plus-icon {
    display: ${(props) => (props.isSelected ? 'none' : 'inline-block')};
  }

  &:hover {
    .check-icon {
      display: none;
    }

    .plus-icon {
      display: inline-block;
    }
  }
`;

type Props = {
  index: number;
  onSelect: () => void;
  suggestion: Suggestion;
};

const ResultsPageItem = ({ index, suggestion, onSelect }: Props) => {
  const theme = useTheme();
  const [isSelected, setIsSelected] = useState(false);
  const [isRecentlySelected, setIsRecentlySelected] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const position = index + 1;
  const name = getAiSuggestionField(suggestion, 'name');
  const description = getAiSuggestionField(suggestion, 'description');

  const handleSelect = () => {
    setIsSelected(true);
    setIsRecentlySelected(true);
    onSelect();

    timeoutRef.current = setTimeout(() => {
      setIsRecentlySelected(false);
    }, 3000);
  };

  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    [],
  );

  return (
    <Container>
      <Position>
        {position}
        {'.'}
      </Position>
      <div>
        <Title>{name}</Title>
        <div css={css({ fontSize: '0.875rem', marginTop: 3.5 })}>
          <Text>{description}</Text>
        </div>
      </div>
      <Flex grow={1} />
      <div>
        <Button
          type={'button'}
          isSelected={isSelected}
          isRecentlySelected={isRecentlySelected}
          onClick={handleSelect}
        >
          <CheckIcon
            className={'check-icon'}
            css={css({ color: theme.legacyColor.colorAiPurple })}
          />
          <PlusIcon
            className={'plus-icon'}
            css={css({ color: theme.color.black })}
          />
        </Button>
      </div>
    </Container>
  );
};

export default ResultsPageItem;
