import cn from 'classnames';

import styles from './RoadmapItemBackgroundTileFixed.module.scss';
import type { FixedTileColumn } from './RoadmapItemBackgroundTileFixed.type';
import { getColumnClassName } from './RoadmapItemBackgroundTileFixed.utils';

type RoadmapItemBackgroundTileFixedProps = {
  column: FixedTileColumn;
  isHidden?: boolean;
};

const RoadmapItemBackgroundTileFixed = ({
  isHidden = false,
  column,
}: RoadmapItemBackgroundTileFixedProps) => {
  const columnClassName = getColumnClassName(column);

  return (
    <div
      className={cn(styles.container, columnClassName, {
        [styles.containerHidden]: isHidden,
      })}
    />
  );
};

export default RoadmapItemBackgroundTileFixed;
