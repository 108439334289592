import { useSubscription } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import type { PropsWithChildren } from 'react';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import { useDefinedContext } from 'shared/utils/context.utils';
import type {
  AiSuggestionElement,
  AiSuggestionElementInput,
  InputMaybe,
  StrategyElementListInput,
} from 'types.graphql.generated';
import useAiAssistentActive from 'ai/useAiAssistentActive';

type Props<SubscriptionVariables extends SubscriptionVariablesInterface> =
  PropsWithChildren<{
    elementIds?: StrategyElementListInput;
    elements?: AiSuggestionElementInput[];
    hasVariables: ((variables?: SubscriptionVariables) => boolean) | boolean;
    hints?: string[] | string;
    shouldReinitialize?: boolean;
    subscriptionDocument: DocumentNode;
  }>;

type Value<SubscriptionVariables extends SubscriptionVariablesInterface> = {
  fetchSuggestions: (temperature?: number) => void;
  hasVariables: boolean;
  isAiAssistantActive: boolean;
  isComplete: boolean;
  isTriggered: boolean;
  selectedSuggestion?: AiSuggestionElement;
  setSelectedSuggestion: (suggestion: AiSuggestionElement) => void;
  setTemperature: (temperature: number) => void;
  setVariables: (variables: SubscriptionVariables) => void;
  stopSuggestions: () => void;
  suggestions?: AiSuggestionElement[];
  variables: SubscriptionVariables;
};

const SuggestionsContext = createContext<
  Value<SubscriptionVariablesInterface> | undefined
>(undefined);

export type SubscriptionResultInterface = {
  suggestions: AiSuggestionElement[];
};
export type SubscriptionVariablesInterface = {
  elementIds?: InputMaybe<StrategyElementListInput>;
  elements?: AiSuggestionElementInput[] | AiSuggestionElementInput;
  hints?: string[] | string;
  languageLocaleCode: string;
  numberOfSuggestions: number;
  temperature?: InputMaybe<number>;
};

const numberOfSuggestions = 20;

const SuggestionsProvider = <
  SubscriptionResult extends SubscriptionResultInterface,
  SubscriptionVariables extends SubscriptionVariablesInterface,
>({
  subscriptionDocument,
  hasVariables,
  shouldReinitialize,
  hints = [],
  elements,
  elementIds,
  children,
}: Props<SubscriptionVariables>) => {
  const [variables, setVariables] = useState<
    SubscriptionVariables | undefined
  >();
  const [temperature, setTemperature] = useState<number>();
  const { activeOrg } = useActiveOrg();
  const [isTriggered, setIsTriggered] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const { isAiAssistantActive } = useAiAssistentActive();

  const hasVariablesResult =
    typeof hasVariables === 'function' ? hasVariables(variables) : hasVariables;

  const skip = !isAiAssistantActive || !hasVariablesResult || !isTriggered;

  const { data, loading } = useSubscription<
    SubscriptionResult,
    SubscriptionVariables
  >(subscriptionDocument, {
    skip,
    variables: {
      ...variables,
      languageLocaleCode: activeOrg.languageLocaleCode,
      numberOfSuggestions,
      hints,
      elements,
      elementIds,
      temperature,
    } as SubscriptionVariables,
    onSubscriptionComplete() {
      setIsComplete(true);
    },
  });

  useEffect(() => {
    setIsTriggered(false);
  }, [variables]);

  useEffect(() => {
    if (loading) {
      setIsComplete(false);
    }
  }, [loading]);

  const [selectedSuggestion, setSelectedSuggestion] =
    useState<AiSuggestionElement>();

  const fetchSuggestions = useCallback((temperature?: number) => {
    setTemperature(temperature);
    setIsTriggered(true);
  }, []);

  const stopSuggestions = useCallback(() => {
    setIsTriggered(false);
    setSelectedSuggestion(undefined);
    setIsComplete(false);
  }, []);

  const value = useMemo(
    () =>
      ({
        isTriggered,
        isComplete,
        suggestions: data?.suggestions,
        variables: variables as SubscriptionVariables,
        setVariables: setVariables as (
          variables: SubscriptionVariablesInterface,
        ) => void,
        setTemperature,
        fetchSuggestions,
        stopSuggestions,
        isAiAssistantActive,
        hasVariables: hasVariablesResult,
        selectedSuggestion,
        setSelectedSuggestion: (suggestion?: AiSuggestionElement) => {
          setSelectedSuggestion(undefined);
          setTimeout(() => {
            setSelectedSuggestion(suggestion);
          }, 0);
        },
      } satisfies Value<SubscriptionVariables>),
    [
      data?.suggestions,
      fetchSuggestions,
      hasVariablesResult,
      isAiAssistantActive,
      isComplete,
      isTriggered,
      selectedSuggestion,
      stopSuggestions,
      variables,
    ],
  );

  useEffect(() => {
    if (shouldReinitialize) {
      stopSuggestions();
    }
  }, [shouldReinitialize, stopSuggestions]);

  return (
    <SuggestionsContext.Provider value={value}>
      {children}
    </SuggestionsContext.Provider>
  );
};

export default SuggestionsProvider;

export const useSuggestions = <
  SubscriptionVariables extends SubscriptionVariablesInterface,
>() =>
  useDefinedContext(
    SuggestionsContext as unknown as React.Context<
      Value<SubscriptionVariables> | undefined
    >,
  );
