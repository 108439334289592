import cn from 'classnames';
import type { Merge } from 'type-fest';
import type { ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DropdownIcon } from 'shared/static/icons/icon-dropdown.svg';

import styles from './DropdownButton.module.scss';

type DropdownButtonProps = Merge<
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'title'>,
  {
    className?: string;
    isActive?: boolean;
  }
>;

const DropdownButton = ({
  isActive = false,
  className,
  ...restProps
}: DropdownButtonProps) => {
  const { t } = useTranslation();

  const title = t(isActive ? 'collapse' : 'expand');

  return (
    <button
      {...restProps}
      title={title}
      aria-pressed={isActive}
      className={cn(
        styles.dropdownButton,
        { [styles.dropdownButtonActive]: isActive },
        className,
      )}
    >
      <span className={styles.dropdownButtonContent}>{title}</span>
      <DropdownIcon aria-hidden={true} className={styles.dropdownButtonIcon} />
    </button>
  );
};

export default DropdownButton;
