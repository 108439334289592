import { differenceInDays, format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import Space from 'shared/components/Space';
import Text from 'shared/components/Text';

type Props = {
  deadline?: Date;
  endDate?: Date;
  hideTimeline?: boolean;
  orgUnitName?: string;
  startDate?: Date;
};

const CampaignDetailsSubheader = ({
  orgUnitName,
  startDate,
  deadline,
  endDate,
  hideTimeline,
}: Props) => {
  const { t } = useTranslation();

  const duration =
    startDate && endDate ? differenceInDays(endDate, startDate) : null;

  return (
    <Space gap={20}>
      {orgUnitName && (
        <span>
          {t('toolkit.toolPage.campaign.details.description')}{' '}
          <Text color={'primary'}>{orgUnitName}</Text>
        </span>
      )}

      {duration !== null && (
        <span>
          {t('toolkit.toolPage.campaign.details.duration', {
            duration,
          })}
        </span>
      )}

      {!hideTimeline && startDate && (
        <span>
          {t('toolkit.toolPage.campaign.details.startDate', {
            date: format(startDate, 'P'),
          })}
        </span>
      )}

      {!hideTimeline && deadline && (
        <span>
          {t('toolkit.toolPage.campaign.details.deadline', {
            date: format(deadline, 'P'),
          })}
        </span>
      )}

      {!hideTimeline && endDate && (
        <span>
          {t('toolkit.toolPage.campaign.details.endDate', {
            date: format(endDate, 'P'),
          })}
        </span>
      )}
    </Space>
  );
};

export default CampaignDetailsSubheader;
