import cn from 'classnames';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CalendarIcon } from 'shared/static/icons/icon-calendar.svg';
import UserAvatar from 'user/UserAvatar';
import { date } from 'shared/services/date.service';
import { StatusIndicator } from 'shared/status/StatusIndicator';
import StatusMark from 'shared/status/StatusMark';
import type { Objective } from 'types.graphql.generated';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';

import styles from './InitiativesContentListRow.module.scss';
import type { InitiativesContentInitiative } from '../InitiativesContentProvider/InitiativesContentProvider.type';

type InitiativeContentListRowProps = {
  initiative: InitiativesContentInitiative;
  objectiveId: Objective['id'];
};

const InitiativeContentListRow = ({
  initiative,
  objectiveId,
}: InitiativeContentListRowProps) => {
  const { t } = useTranslation();

  const statusIndicator =
    initiative.currentInitiativeStatus?.statusIndicator ||
    new StatusIndicator();

  const { teamAdapter } = useTeamAdapter();

  const navigate = useNavigate();

  const navigateToInitiative = () =>
    navigate(
      objectiveRoutesPaths.initiatives.initiative.root({
        params: {
          objectiveId: objectiveId,
          teamSlug: teamAdapter.toParam(),
          initiativeId: initiative.id,
        },
      }),
    );

  const timelineRangeText = useMemo(() => {
    const dateEndTime =
      initiative.timeLine.endDate ?? initiative.timeLine.completeDateTime;

    const hasDates = !!(initiative.timeLine.startDate || dateEndTime);

    const startDateText = initiative.timeLine.startDate
      ? date.format(initiative.timeLine.startDate, 'MMM yyyy')
      : t('notSet');

    const endDateText = dateEndTime
      ? date.format(
          initiative.timeLine.endDate ?? initiative.timeLine.completeDateTime,
          'MMM yyyy',
        )
      : t('notSet');

    return hasDates ? `${startDateText} - ${endDateText}` : null;
  }, [
    initiative.timeLine.completeDateTime,
    initiative.timeLine.endDate,
    initiative.timeLine.startDate,
    t,
  ]);

  return (
    <>
      <motion.li
        className={styles.row}
        onClick={navigateToInitiative}
        layout={true}
      >
        <motion.div layout={true} className={styles.name}>
          <StatusMark
            statusIndicator={statusIndicator}
            className={styles.objectiveChipCurrentObjectiveStatus}
            hasBorder={false}
          />
          <p className={styles.nameText}>{initiative.name}</p>
        </motion.div>

        <motion.div layout={true} className={cn(styles.timeline)}>
          {timelineRangeText && (
            <>
              <CalendarIcon className={styles.timelineIcon} />
              <motion.div layout={true} className={cn(styles.timelineText)}>
                {timelineRangeText}
              </motion.div>
            </>
          )}
        </motion.div>
        {initiative.owner ? (
          <UserAvatar
            user={initiative.owner}
            containerClassName={styles.avatarContainer}
          />
        ) : (
          <div className={styles.avatarContainerEmpty} />
        )}
      </motion.li>
    </>
  );
};

export default InitiativeContentListRow;
