import { useDefinedContext } from 'shared/utils/context.utils';

import ObjectiveOverviewProvider from './ObjectiveOverviewProvider';
import { ObjectiveOverviewContext } from './ObjectiveOverviewProvider.context';

export const useObjectiveOverview = () =>
  useDefinedContext(ObjectiveOverviewContext, {
    hookName: useObjectiveOverview.name,
    providerName: ObjectiveOverviewProvider.name,
  });
