import { useTranslation } from 'react-i18next';
import { useMap, useDebounce } from 'react-use';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import ms from 'ms';
import { usePromiseTracker } from 'react-promise-tracker';

import type { ModalProps } from 'shared/components/__DEPRECATED__/Modal';
import Modal from 'shared/components/__DEPRECATED__/Modal';
import type { Org, Strategy } from 'types.graphql.generated';
import Steps, { useSteps } from 'shared/components/Steps';
import { useToasts } from 'shared/toast/useToasts';
import { withSteps } from 'shared/components/Steps/withSteps';

import StrategyWizardChoices from './StrategyWizardChoices';
import StrategyWizardBehaviors from './StrategyWizardBehaviors';
import StrategyWizardName from './StrategyWizardName';
import StrategyWizardMission from './StrategyWizardMission';
import StrategyWizardVision from './StrategyWizardVision';
import StrategyWizardGoals from './StrategyWizardGoals';
import StrategyWizardValues from './StrategyWizardValues';
import { areValuesValidForCreatingStrategy } from './StrategyWizard.utils';
import type { StrategyWizardValues as StrategyWizardValuesType } from './StrategyWizard.type';
import { steps } from './StrategyWizard.static';
import CampaignWarning from './CampaignWarning';

export type StrategyWizardProps = Pick<ModalProps, 'onClose' | 'isOpen'> & {
  hasActiveCampaign: boolean;
  initialValues?: StrategyWizardValuesType;
  onChangeStep?: (values: StrategyWizardValuesType) => void;
  onSubmit: (values: StrategyWizardValuesType) => void;
  onUnsavedChanges?: (hasUnsavedChanges: boolean) => void;
  org: Pick<Org, 'orgKey' | 'displayName'>;
  renderHeading: (strategy: Pick<Strategy, 'name'>) => ReactNode;
  triggerChangeStepOnlyWithValidData?: boolean;
  triggerSubmitOnlyWithValidData?: boolean;
};

export const strategyWizardPromiseTrackerArea =
  'strategyWizardPromiseTrackerArea';

const StrategyWizard = ({
  initialValues,
  renderHeading,
  onSubmit,
  isOpen,
  onClose,
  onChangeStep,
  onUnsavedChanges,
  org,
  triggerChangeStepOnlyWithValidData,
  triggerSubmitOnlyWithValidData,
  hasActiveCampaign,
}: StrategyWizardProps) => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const { activeStep, setActiveStep } = useSteps();

  const isFormsDataInitialized = useRef(false);
  const [formsData, { set: setFormData, reset }] =
    useMap<StrategyWizardValuesType>(initialValues);

  const { promiseInProgress } = usePromiseTracker({
    area: strategyWizardPromiseTrackerArea,
  });

  useDebounce(
    () => {
      if (isFormsDataInitialized.current) {
        onUnsavedChanges?.(true);
      }
      isFormsDataInitialized.current = true;
    },
    ms('0.5 seconds'),
    [onUnsavedChanges, formsData],
  );

  useEffect(() => {
    const shouldTriggerOnChangeStep = triggerChangeStepOnlyWithValidData
      ? areValuesValidForCreatingStrategy(formsData)
      : true;
    if (shouldTriggerOnChangeStep) {
      onChangeStep?.(formsData);
    }
    // We want to capture value of formsData only when previousActiveStepId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep?.id]);

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const handleNameChange = useCallback(
    (values: StrategyWizardValuesType['name']) => {
      setFormData('name', values);
    },
    [setFormData],
  );

  const handleMissionChange = useCallback(
    (values: StrategyWizardValuesType['mission']) => {
      setFormData('mission', values);
    },
    [setFormData],
  );

  const handleVisionChange = useCallback(
    (values: StrategyWizardValuesType['vision']) => {
      setFormData('vision', values);
    },
    [setFormData],
  );

  const handleChoicesChange = useCallback(
    (values: StrategyWizardValuesType['choices']) => {
      setFormData('choices', values);
    },
    [setFormData],
  );

  const handleGoalsChange = useCallback(
    (values: StrategyWizardValuesType['goals']) => {
      setFormData('goals', values);
    },
    [setFormData],
  );

  const handleBehaviorsChange = useCallback(
    (values: StrategyWizardValuesType['behaviors']) => {
      setFormData('behaviors', values);
    },
    [setFormData],
  );

  const handleValuesChange = useCallback(
    (values: StrategyWizardValuesType['values']) => {
      setFormData('values', values);
    },
    [setFormData],
  );

  const handleSubmit = useCallback(
    (formsData: StrategyWizardValuesType) => {
      if (triggerSubmitOnlyWithValidData) {
        if (areValuesValidForCreatingStrategy(formsData)) {
          onSubmit(formsData);
        } else {
          setActiveStep('name');
          addToast({
            variant: 'error',
            children: t('strategy.strategyWizard.strategyNameIsRequired'),
          });
        }
      } else {
        onSubmit(formsData);
      }
    },
    [onSubmit, triggerSubmitOnlyWithValidData, addToast, setActiveStep, t],
  );

  const renderControls = (activeStepId?: string) => {
    if (activeStepId) {
      return (
        <Modal.Controls
          buttons={[
            {
              disabled: promiseInProgress,
              onClick: () => {
                handleSubmit(formsData);
              },
              children: t('save'),
            },
          ]}
        />
      );
    }
    return null;
  };

  return (
    <Modal
      heading={renderHeading({
        name: formsData.name?.name,
      })}
      isOpen={isOpen}
      onClose={onClose}
      size={'full'}
      scrollType={'outer'}
      renderFooterContent={() => renderControls(activeStep?.id)}
      belowHeading={<Steps.Navigation variant={'tabs'} />}
      beforeContent={hasActiveCampaign && <CampaignWarning />}
      isHeadingCentered={true}
      hasHeaderPadding={true}
      hasBackground={false}
    >
      <Steps.Item id={'name'}>
        <StrategyWizardName
          initialValues={formsData.name || undefined}
          onChange={handleNameChange}
        />
      </Steps.Item>
      <Steps.Item id={'mission'}>
        <StrategyWizardMission
          initialValues={formsData.mission || undefined}
          onChange={handleMissionChange}
          org={org}
        />
      </Steps.Item>
      <Steps.Item id={'vision'}>
        <StrategyWizardVision
          initialValues={formsData.vision || undefined}
          onChange={handleVisionChange}
          org={org}
        />
      </Steps.Item>
      <Steps.Item id={'goals'}>
        <StrategyWizardGoals
          initialValues={formsData.goals || undefined}
          onChange={handleGoalsChange}
          org={org}
        />
      </Steps.Item>
      <Steps.Item id={'choices'}>
        <StrategyWizardChoices
          initialValues={formsData.choices || undefined}
          onChange={handleChoicesChange}
          org={org}
        />
      </Steps.Item>
      <Steps.Item id={'behaviors'}>
        <StrategyWizardBehaviors
          initialValues={formsData.behaviors || undefined}
          onChange={handleBehaviorsChange}
        />
      </Steps.Item>
      <Steps.Item id={'values'}>
        <StrategyWizardValues
          initialValues={formsData.values || undefined}
          onChange={handleValuesChange}
          org={org}
        />
      </Steps.Item>
    </Modal>
  );
};

export default withSteps(StrategyWizard, Object.values(steps));
