import type { AnchorHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import type { Merge } from 'type-fest';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

type AnchorVariant = 'primary' | 'secondary';

export type AnchorProps = Merge<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  {
    isStrong?: boolean;
    variant?: AnchorVariant;
  }
>;

const StyledAnchor = styled.a<{ isStrong?: boolean; variant: AnchorVariant }>`
  text-decoration: none;
  color: inherit;
  &:focus {
    outline: none;
  }

  cursor: pointer;
  opacity: 1;

  &:active {
    opacity: 0.8;
  }

  ${(props) =>
    props.variant === 'primary' &&
    css`
      color: ${props.theme.color.primary};
      font-weight: 700;
    `}

  ${(props) =>
    props.variant === 'secondary' &&
    css`
      color: ${props.theme.legacyColor.colorMineShaft};
    `}

  ${(props) =>
    props.isStrong &&
    css`
      font-weight: 700;
    `}
`;

const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(
  ({ variant = 'primary', isStrong, className, ...restProps }, ref) => (
    <StyledAnchor
      {...restProps}
      variant={variant}
      isStrong={isStrong}
      ref={ref}
    />
  ),
);

export default Anchor;
