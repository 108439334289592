import { useTranslation } from 'react-i18next';

import Tag from 'shared/components/Tag';
import type { Tool } from 'types.graphql.generated';
import useIsToolEnabled from 'toolkit/useIsToolEnabled';

type Props = {
  tool: Tool;
};

const ToolEnabledTag = ({ tool }: Props) => {
  const { t } = useTranslation();
  const isEnabled = useIsToolEnabled(tool);

  return isEnabled ? (
    <Tag variant={'success'} size={'small'}>
      {t('enabled')}
    </Tag>
  ) : (
    <Tag variant={'ghost'} size={'small'}>
      {t('disabled')}
    </Tag>
  );
};

export default ToolEnabledTag;
