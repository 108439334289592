import type { NavigateFunction } from 'react-router-dom';

import type {
  ContributionMyContributionLocationOptions,
  RedirectToMyContributionLocationOptions,
} from 'contribution/ContributionMyContribution/ContributionMyContribution.type';
import { contributionRoutesPaths } from 'contribution/contribution.routing.paths';

export const handleOnClose = ({
  options,
  orgTeamSlug,
  navigate,
}: {
  navigate: NavigateFunction;
  options: Maybe<RedirectToMyContributionLocationOptions>;
  orgTeamSlug: string;
}) => {
  if (options?.state?.goToMyContribution) {
    navigate(
      contributionRoutesPaths.root({
        params: {
          teamSlug: orgTeamSlug,
        },
      }),
      {
        state: {
          scrollMyStrategyIntoView: !!options?.state?.scrollToMyStrategy,
        },
      } satisfies ContributionMyContributionLocationOptions,
    );
  } else if (options?.state?.goToMyFollowUps) {
    navigate(
      contributionRoutesPaths.followUps({
        params: {
          teamSlug: orgTeamSlug,
        },
      }),
    );
  } else {
    navigate('../');
  }
};
