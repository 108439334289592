import { useContext } from 'react';

import StrategyProvider from './StrategyProvider';
import { StrategyProviderContext } from './StrategyProvider.context';

export const useStrategy = () => {
  const context = useContext(StrategyProviderContext);
  if (!context) {
    throw new Error(
      `${useStrategy.name} should not be used outside ${StrategyProvider.name}`,
    );
  }
  return context;
};
