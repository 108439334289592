import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { useToasts } from 'shared/toast/useToasts';
import type { TeamAdapter } from 'team/TeamAdapter';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import useCanPerformOrgOrOrgUnitAction from 'user/ability/useCanPerformOrgOrOrgUnitAction';

import {
  useUnlaunchStrategyStrategyQuery,
  useUnlaunchStrategyUpdateOrgMutation,
  useUnlaunchStrategyUpdateOrgUnitMutation,
} from './useUnlaunchStrategy.graphql.generated';
import {
  unlaunchOrgStrategy,
  unlaunchOrgUnitStrategy,
} from './useUnlaunchStrategy.utils';

export type UnlaunchStrategyParams = {
  strategyId: string;
  teamAdapter: TeamAdapter;
};

type UnlaunchStrategyFn = (params: {
  onSuccess?: () => void;
}) => Promise<unknown>;

type UnlaunchStrategyReturnType = {
  canUnlaunchStrategy?: boolean;
  unlaunchStrategy: UnlaunchStrategyFn;
};

export const useUnlaunchStrategy = ({
  strategyId,
  teamAdapter,
}: UnlaunchStrategyParams): UnlaunchStrategyReturnType => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const handleApolloError = useHandleApolloError();

  const { data: strategyData } = useUnlaunchStrategyStrategyQuery({
    variables: { strategyId },
    onError: handleApolloError,
  });

  const [updateOrg] = useUnlaunchStrategyUpdateOrgMutation({
    variables: { orgKey: teamAdapter.keyArg, idToRemove: strategyId },
    onError: handleApolloError,
    update: unlaunchOrgStrategy,
  });

  const [updateOrgUnit] = useUnlaunchStrategyUpdateOrgUnitMutation({
    variables: { orgUnitId: teamAdapter.keyArg, idToRemove: strategyId },
    onError: handleApolloError,
    update: unlaunchOrgUnitStrategy,
  });

  const handleMutationComplete = useCallback(() => {
    addToast({
      children: t('updateSuccessToast'),
      variant: 'success',
    });
  }, [addToast, t]);

  const handleUnlaunchOrgStrategy = useCallback(
    (onSuccess?: () => void) =>
      updateOrg({
        onCompleted: () => {
          handleMutationComplete();
          onSuccess?.();
        },
      }),
    [updateOrg, handleMutationComplete],
  );

  const handleUnlaunchOrgUnitStrategy = useCallback(
    (onSuccess?: () => void) =>
      updateOrgUnit({
        onCompleted: () => {
          handleMutationComplete();
          onSuccess?.();
        },
      }),
    [updateOrgUnit, handleMutationComplete],
  );

  const canWrite = useCanPerformOrgOrOrgUnitAction('WRITE_STRATEGY', 'WRITE');

  const canUnlaunchStrategy =
    canWrite && strategyData !== undefined && strategyData.strategy.isLive;

  const handleUnlaunchStrategy = useCallback<UnlaunchStrategyFn>(
    async ({ onSuccess }) => {
      if (canUnlaunchStrategy && teamAdapter.isOrg) {
        handleUnlaunchOrgStrategy(onSuccess);
      } else if (canUnlaunchStrategy && teamAdapter.isOrgUnit) {
        handleUnlaunchOrgUnitStrategy(onSuccess);
      }
    },
    [
      teamAdapter,
      canUnlaunchStrategy,
      handleUnlaunchOrgStrategy,
      handleUnlaunchOrgUnitStrategy,
    ],
  );

  return {
    canUnlaunchStrategy,
    unlaunchStrategy: handleUnlaunchStrategy,
  };
};
