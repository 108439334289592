import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import AddMemberToOrgUnitModal from 'orgUnit/AddMemberToOrgUnitModal';
import { useTeamAdapter } from 'team/TeamAdapter';

const TeamMembersAddMember = () => {
  const navigate = useNavigate();

  const { teamAdapter } = useTeamAdapter();

  const handleClose = useCallback(() => {
    navigate('../');
  }, [navigate]);

  if (teamAdapter.isOrgUnit) {
    return (
      <AddMemberToOrgUnitModal
        isOpen={true}
        onClose={handleClose}
        teamAdapter={teamAdapter}
      />
    );
  }

  // todo implement TeamMembersAddMember behavior when team.variant === 'org
  return null;
};

export default TeamMembersAddMember;
