import {
  parse as dateFnsParse,
  parseISO as dateFnsParseISO,
  format as dateFnsFormat,
  formatRFC3339 as dateFnsFormatRFC3339,
  formatISO as dateFnsFormatISO,
  formatDistance as dateFnsFormatDistance,
  formatDistanceToNow as dateFnsFormatDistanceToNow,
  isDate as dateFnsIsDate,
  isValid as dateFnsIsValid,
  isEqual as dateFnsIsEqual,
  isBefore as dateFnsIsBefore,
  isAfter as dateFnsIsAfter,
  getYear as dateFnsGetYear,
  sub as dateFnsSub,
  add as dateFnsAdd,
  min as dateFnsMin,
  max as dateFnsMax,
  differenceInSeconds,
  differenceInDays,
  differenceInMonths,
  differenceInQuarters,
  differenceInYears,
  isSameDay,
  isSameMonth,
  isSameQuarter,
  isSameYear,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachQuarterOfInterval,
  areIntervalsOverlapping as dateFnsAreIntervalsOverlapping,
  eachYearOfInterval,
  formatISODuration,
  intervalToDuration,
  differenceInWeeks,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import enUS from 'date-fns/locale/en-US';
import type { Duration, Interval } from 'date-fns';
import { parse as parseDuration } from 'iso8601-duration';

const defaultDateLocale = enUS;

export type TimeUnit =
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'quarter'
  | 'year';

export type { Interval };

export type PartialInterval = {
  end?: Maybe<Interval['end']>;
  start?: Maybe<Interval['start']>;
};

class DateService {
  locale: Locale;

  constructor(locale: Locale) {
    this.locale = locale;
  }

  async loadLocale(languageCode: string): Promise<void> {
    const localeFileName = (() => {
      switch (languageCode) {
        case 'no': {
          return 'nb';
        }
        default: {
          return 'en-US';
        }
      }
    })();
    const newLocaleModule = await import(
      /* webpackMode: "lazy", webpackChunkName: "df-[index]", webpackExclude: /_lib/ */
      `date-fns/locale/${localeFileName}/index.js`
    );
    this.locale = newLocaleModule.default;
  }

  parse(date: string, format: string): Date {
    return dateFnsParse(date, format, new Date(), {
      locale: this.locale,
    });
  }

  parseISO(date: string): Date {
    return dateFnsParseISO(date);
  }

  format(date: Date | number, format: string): string {
    return dateFnsFormat(date, format, {
      locale: this.locale,
    });
  }

  formatShort(date: Date | number): string {
    return dateFnsFormat(
      date,
      this.locale.formatLong?.date({
        width: 'short',
      }),
    );
  }

  formatRfc3339(date: Date | number): string {
    return dateFnsFormatRFC3339(date);
  }

  formatIso(
    date: Date | number,
    options?: {
      format?: 'extended' | 'basic';
      representation?: 'complete' | 'date' | 'time';
    },
  ): string {
    return dateFnsFormatISO(date, options);
  }

  formatDistance(
    date: Date | number,
    baseDate: Date | number,
    options?: {
      addSuffix?: boolean;
      includeSeconds?: boolean;
    },
  ): string {
    return dateFnsFormatDistance(date, baseDate, {
      ...options,
      locale: this.locale,
    });
  }

  formatDistanceToNow(
    date: Date | number,
    options?: {
      addSuffix?: boolean;
      includeSeconds?: boolean;
    },
  ): string {
    return dateFnsFormatDistanceToNow(date, {
      ...options,
      locale: this.locale,
    });
  }

  isDate(value: unknown): value is Date {
    return dateFnsIsDate(value);
  }

  isValid(value: unknown): boolean {
    return dateFnsIsValid(value);
  }

  add(date: Date | number, duration: Duration): Date {
    return dateFnsAdd(date, duration);
  }

  subtract(date: Date | number, duration: Duration): Date {
    return dateFnsSub(date, duration);
  }

  isEqual(date: Date | number, dateToCompare: Date | number): boolean {
    return dateFnsIsEqual(date, dateToCompare);
  }

  isAfter(date: Date | number, dateToCompare: Date | number): boolean {
    return dateFnsIsAfter(date, dateToCompare);
  }

  isBefore(date: Date | number, dateToCompare: Date | number): boolean {
    return dateFnsIsBefore(date, dateToCompare);
  }

  isSameUnit(
    dateLeft: Date | number,
    dateRight: Date | number,
    unit: Extract<TimeUnit, 'day' | 'month' | 'quarter' | 'year'>,
  ): boolean {
    const isSameFn = {
      day: isSameDay,
      month: isSameMonth,
      quarter: isSameQuarter,
      year: isSameYear,
    }[unit];
    return isSameFn(dateLeft, dateRight);
  }

  getYear(date: Date | number): number {
    return dateFnsGetYear(date);
  }

  getDifference(
    dateLeft: Date | number,
    dateRight: Date | number,
    unit: Extract<
      TimeUnit,
      'day' | 'week' | 'month' | 'quarter' | 'year' | 'second'
    >,
  ): number {
    const differenceFn = {
      second: differenceInSeconds,
      day: differenceInDays,
      week: differenceInWeeks,
      month: differenceInMonths,
      quarter: differenceInQuarters,
      year: differenceInYears,
    }[unit];
    return differenceFn(dateLeft, dateRight);
  }

  getEachOfInterval(
    interval: Interval,
    unit: Extract<TimeUnit, 'day' | 'month' | 'quarter' | 'year'>,
  ): Date[] {
    const eachOfIntervalFn = {
      day: eachDayOfInterval,
      month: eachMonthOfInterval,
      quarter: eachQuarterOfInterval,
      year: eachYearOfInterval,
    }[unit];
    return eachOfIntervalFn(interval);
  }

  areIntervalsOverlapping(
    intervalLeft: Interval,
    intervalRight: Interval,
    options: {
      inclusive?: boolean;
    } = {},
  ): boolean {
    return dateFnsAreIntervalsOverlapping(intervalLeft, intervalRight, options);
  }

  getDurationLeft(
    targetDate?: Date,
  ): Pick<Duration, 'days' | 'hours' | 'minutes'> {
    if (!targetDate) {
      return {
        hours: 0,
        days: 0,
        minutes: 0,
      };
    }

    const now = new Date();
    if (date.isAfter(now, targetDate)) {
      return {
        hours: 0,
        days: 0,
        minutes: 0,
      };
    }
    const duration = formatISODuration(
      intervalToDuration({
        start: now,
        end: targetDate,
      }),
    );
    const { days, hours, minutes } = parseDuration(duration);
    return {
      days,
      hours,
      minutes,
    };
  }

  min(dates: (Date | number)[]): Maybe<Date> {
    if (
      dates.length &&
      dates.every((date) => this.isDate(date) && this.isValid(date))
    ) {
      return dateFnsMin(dates);
    }
    return undefined;
  }

  max(dates: (Date | number)[]): Maybe<Date> {
    if (
      dates.length &&
      dates.every((date) => this.isDate(date) && this.isValid(date))
    ) {
      return dateFnsMax(dates);
    }
    return undefined;
  }

  fillPartialInterval(interval: PartialInterval): Interval {
    const defaultStartDate = new Date('1970');
    const defaultEndDate = new Date('3000');

    if (this.isPartialIntervalEmpty(interval)) {
      throw new Error('interval must have at least one date');
    }

    const start = interval.start || defaultStartDate;

    const end = interval.end
      ? this.isBefore(interval.end, start)
        ? start
        : interval.end
      : defaultEndDate;

    return { start, end };
  }

  isPartialIntervalEmpty(interval: PartialInterval): boolean {
    return !interval.start && !interval.end;
  }
}

const date = new DateService(defaultDateLocale);

export { date };
