import { createContext } from 'react';

import type { Org } from 'types.graphql.generated';

export type StrategyProfileAttributesContextValue = {
  attributes?: Pick<
    NonNullable<
      Org['currentStrategyProfile']
    >['strategyAttributeDefinitions'][0],
    'name' | 'title'
  >[];
  isAttributesLoading: boolean;
};

export const StrategyProfileAttributesContext = createContext<
  StrategyProfileAttributesContextValue | undefined
>(undefined);
