import type { PropsWithChildren } from 'react';

import Heading from 'shared/components/Heading';
import Logo from 'shared/components/Logo';

import styles from './SignUpLayout.module.scss';

type SignUpLayoutProps = PropsWithChildren<{
  description: string;
  heading: string;
}>;

const SignUpLayout = ({
  heading,
  description,
  children,
}: SignUpLayoutProps) => (
  <div className={styles.container}>
    <div className={styles.backgroundContainer}>
      <h1 className={styles.logo}>
        <Logo variant={'mono'} />
      </h1>
    </div>
    <div className={styles.contentContainer}>
      <div className={styles.content}>
        <Heading level={2} className={styles.contentHeading}>
          {heading}
        </Heading>
        <div>
          <p className={styles.contentDescription}>{description}</p>
        </div>
        {children}
      </div>
    </div>
  </div>
);

export default SignUpLayout;
