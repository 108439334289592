import { createContext } from 'react';

import type { OrgUnitWithAuthorizedActions } from 'user/ability/canPerformOrgUnitAction';

import type { CurrentUserQuery } from './UserProvider.graphql.generated';

export type UserProviderContextValue = {
  user: CurrentUserQuery['currentUser'];
  userOrgUnits: OrgUnitWithAuthorizedActions[];
};

export const UserProviderContext = createContext<
  UserProviderContextValue | undefined
>(undefined);
