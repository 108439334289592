import { useMemo } from 'react';

import Spinner from 'shared/spinner/Spinner';
import InitiativesContentProvider from 'initiative/InitiativesContent/InitiativesContentProvider/InitiativesContentProvider';
import Layout from 'shared/components/Layout';
import InitiativesContent from 'initiative/InitiativesContent/InitiativesContent';

import InitiativesContentHeader from '../InitiativesContent/InitiativesContentHeader';
import ObjectiveInitiativesProvider from './ObjectiveInitiativesProvider/ObjectiveInitiativesProvider';
import { useObjectiveInitiatives } from './ObjectiveInitiativesProvider/useObjectiveInitiatives';
import styles from './ObjectiveInitiatives.module.scss';

const ObjectiveInitiatives = () => {
  const { objective } = useObjectiveInitiatives();

  const objectives = useMemo(() => [objective].filter(Boolean), [objective]);

  return (
    <Layout.Content
      hasPadding={false}
      variant={'contained'}
      className={styles.layout}
    >
      <InitiativesContentProvider objectives={objectives}>
        {objective ? (
          <>
            <InitiativesContentHeader objective={objective} />
            <InitiativesContent
              hasHeaders={false}
              sortListByTheme={false}
              areAllSectionsExpanded={true}
            />
          </>
        ) : (
          <Spinner.Circle />
        )}
      </InitiativesContentProvider>
    </Layout.Content>
  );
};

export default () => (
  <ObjectiveInitiativesProvider>
    <ObjectiveInitiatives />
  </ObjectiveInitiativesProvider>
);
