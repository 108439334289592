import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { MetricOverviewMetricStatusFragmentDoc } from '../MetricOverview/MetricOverviewProvider/MetricOverviewProvider.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddMetricStatusMutationVariables = Types.Exact<{
  input: Types.MetricStatusInput;
}>;


export type AddMetricStatusMutation = { __typename: 'Mutation', addMetricStatus: { __typename: 'MetricStatus', id: string, complete: boolean, forecastValue?: number | undefined, statusValue?: number | undefined, comment?: string | undefined, statusDateTime: any, statusIndicator: StatusIndicatorClass, metric: { __typename: 'Metric', id: string, timeLine: { __typename: 'TimeLine', completeDateTime?: any | undefined }, currentMetricStatus?: { __typename: 'MetricStatus', id: string } | undefined, metricStatusListAll: Array<{ __typename: 'MetricStatus', id: string }> }, auditRecord: { __typename: 'AuditRecord', createDateTime?: any | undefined, updateDateTime?: any | undefined, createBy?: { __typename: 'AuditRecordUser', displayName?: string | undefined, email?: string | undefined, photoUrl?: string | undefined } | undefined }, actionComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }>, challengeComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }>, successComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }> } };


export const AddMetricStatusDocument = gql`
    mutation AddMetricStatus($input: MetricStatusInput!) {
  addMetricStatus(input: $input) {
    ...metricOverviewMetricStatus
    metric {
      id
      timeLine {
        completeDateTime
      }
      currentMetricStatus {
        id
      }
      metricStatusListAll {
        id
      }
    }
  }
}
    ${MetricOverviewMetricStatusFragmentDoc}`;
export type AddMetricStatusMutationFn = Apollo.MutationFunction<AddMetricStatusMutation, AddMetricStatusMutationVariables>;

/**
 * __useAddMetricStatusMutation__
 *
 * To run a mutation, you first call `useAddMetricStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMetricStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMetricStatusMutation, { data, loading, error }] = useAddMetricStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMetricStatusMutation(baseOptions?: Apollo.MutationHookOptions<AddMetricStatusMutation, AddMetricStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMetricStatusMutation, AddMetricStatusMutationVariables>(AddMetricStatusDocument, options);
      }
export type AddMetricStatusMutationHookResult = ReturnType<typeof useAddMetricStatusMutation>;
export type AddMetricStatusMutationResult = Apollo.MutationResult<AddMetricStatusMutation>;
export type AddMetricStatusMutationOptions = Apollo.BaseMutationOptions<AddMetricStatusMutation, AddMetricStatusMutationVariables>;