import styled from '@emotion/styled';

import type { FollowUpActivityItemStatusRequest } from './ContributionMyFollowUpsViewMore.type';
import TimelineEntryContentStatusRequestBodyItem from './TimelineEntryContentStatusRequestBodyItem';

const Container = styled.div`
  border-top: 1px solid ${(props) => props.theme.color.strokeLight};
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 18px;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 18px;
`;

type Props = {
  statusRequests: FollowUpActivityItemStatusRequest['statusRequests'];
};

const TimelineEntryContentStatusRequestBody = ({ statusRequests }: Props) => (
  <Container>
    {statusRequests.map((statusRequest) => (
      <TimelineEntryContentStatusRequestBodyItem
        key={statusRequest.id}
        statusRequest={statusRequest}
      />
    ))}
  </Container>
);

export default TimelineEntryContentStatusRequestBody;
