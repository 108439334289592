import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Space from 'shared/components/Space';
import Heading from 'shared/components/Heading';
import Text from 'shared/components/Text';
import EmojiIcon from 'shared/components/EmojiIcon';
import Tag from 'shared/components/Tag';
import type { Tool } from 'toolkit/toolkit.static';
import { useTeamAdapter } from 'team/TeamAdapter';
import Button from 'shared/components/Button';
import useCanPerformOrgAction from 'user/ability/useCanPerformOrgAction';

const Container = styled.div`
  border: 1px solid ${(props) => props.theme.color.strokeLight};
  border-radius: 12px;
  height: 9.357rem;
  padding: 1.4rem;
  position: relative;
`;

const TagContainer = styled.div`
  position: absolute;
  right: 0.4rem;
  top: 0.4rem;
`;

type ToolCardProps = {
  tool: Tool;
};

const ToolCard = ({ tool }: ToolCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamAdapter } = useTeamAdapter();

  const canWriteOrgStrategy = useCanPerformOrgAction('WRITE_STRATEGY');

  const handleClick = useMemo(
    () =>
      tool.getLink && canWriteOrgStrategy
        ? () => tool.getLink && navigate(tool.getLink(teamAdapter.toParam()))
        : undefined,
    [canWriteOrgStrategy, navigate, teamAdapter, tool],
  );

  const tag = useMemo<ReactNode>(() => {
    if (handleClick) {
      return (
        <Button
          css={css({ height: '2.25rem', padding: '0 1rem', border: 0 })}
          color={'primary'}
          fontSize={'small'}
        >
          {t('open')}
        </Button>
      );
    }

    switch (tool.availability) {
      case 'AVAILABLE': {
        return <Tag variant={'success'}>{t('toolkit.availableNow')}</Tag>;
      }
      case 'COMING_SOON': {
        return <Tag variant={'alert'}>{t('toolkit.comingSoon')}</Tag>;
      }
    }
  }, [handleClick, tool.availability, t]);

  const name = t(`toolkit.tools.${tool.id}.name`);
  const description = t(`toolkit.tools.${tool.id}.description`);
  const symbol = t(`toolkit.tools.${tool.id}.symbol`);

  return (
    <Container
      css={handleClick && css({ cursor: 'pointer' })}
      onClick={handleClick}
    >
      <TagContainer>{tag}</TagContainer>

      <Space>
        <EmojiIcon emoji={symbol} size={'small'} css={css({ flexShrink: 0 })} />

        <Space direction={'vertical'} size={'tiny'}>
          <Heading
            level={2}
            as={4}
            hasMargin={false}
            css={css({ fontSize: '1.143rem', marginTop: '.8rem' })}
          >
            {name}
          </Heading>

          <Text
            css={css({ fontSize: '1.143rem' })}
            ellipsis={{
              lineClamp: 2,
            }}
          >
            {description}
          </Text>
        </Space>
      </Space>
    </Container>
  );
};

export default ToolCard;
