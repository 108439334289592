import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import type { ObjectivesSort } from 'actions/actions.type';
import type { Theme, User } from 'types.graphql.generated';
import type { ActionsObjectiveFragment } from 'actions/actions.fragment.graphql.generated';

export type StrategyObjectivesActionsContextValue = {
  isStrategyActionsLoading: boolean;
  objectives: Maybe<ActionsObjectiveFragment[]>;
  objectivesSort: ObjectivesSort;
  objectivesWithActions: Maybe<ActionsObjectiveFragment[]>;
  selectedThemes: Array<Theme['id'] | null>;
  selectedUsers: Array<Pick<User, 'id' | 'displayName' | 'email'> | null>;
  setObjectivesSort: Dispatch<SetStateAction<ObjectivesSort>>;
  setSelectedThemes: Dispatch<SetStateAction<Array<Theme['id'] | null>>>;
  setSelectedUsers: Dispatch<
    SetStateAction<Array<Pick<User, 'id' | 'displayName' | 'email'> | null>>
  >;
  showCompletedObjectives: boolean;
  toggleShowCompletedObjectives: (nextValue?: any) => void;
};

export const StrategyObjectivesActionsContext = createContext<
  StrategyObjectivesActionsContextValue | undefined
>(undefined);
