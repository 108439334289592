import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import Heading from 'shared/components/Heading';
import Text from 'shared/components/Text';
import type {
  StatusCommentsFormProps,
  StatusCommentsFormValues,
} from 'shared/status/StatusCommentsForm';
import StatusCommentsForm from 'shared/status/StatusCommentsForm';
import type { Report } from 'types.graphql.generated';
import AiSuggestions from 'ai/AiSuggestions';
import SuggestionsProvider from 'ai/AiSuggestions/SuggestionsProvider';
import { createInitialValues } from 'shared/status/StatusCommentsForm/StatusCommentsForm.utils';
import Form from 'shared/form/Form';
import Emoji from 'shared/components/Emoji';

import InsightReportWizardInfoCard from '../InsightReportWizardInfoCard';
import type {
  InsightsReportChallengeSuggestionsSubscription,
  InsightsReportChallengeSuggestionsSubscriptionVariables,
} from './InsightsReportChallengeSuggestions.graphql.generated';
import { InsightsReportChallengeSuggestionsDocument } from './InsightsReportChallengeSuggestions.graphql.generated';
import AllCommentsSideCard from '../AllCommentsSideCard';

export type InsightReportWizardChallengesValues =
  StatusCommentsFormValues<'CHALLENGE_COMMENT'>;

export type InsightReportWizardChallengesProps = Pick<
  StatusCommentsFormProps<'CHALLENGE_COMMENT'>,
  'initialValues' | 'onChange' | 'onSubmit' | 'id'
> & { reportId?: Report['id'] };

const InsightReportWizardChallenges = (
  props: InsightReportWizardChallengesProps,
) => {
  const { t } = useTranslation();

  const initialValues = useMemo(
    () => props.initialValues || createInitialValues(['CHALLENGE_COMMENT']),
    [props.initialValues],
  );

  const renderForm = () => (
    <>
      <Heading level={3}>
        {t('report.insightReportWizard.challenges.heading')}
      </Heading>
      <Text>{t('report.insightReportWizard.challenges.description')}</Text>
      <StatusCommentsForm
        type={'CHALLENGE_COMMENT'}
        suggestionField={'challenge comment'}
        hasTypeHeadings={false}
        enableReinitialize={true}
        {...props}
      />
    </>
  );

  return (
    <SuggestionsProvider<
      InsightsReportChallengeSuggestionsSubscription,
      InsightsReportChallengeSuggestionsSubscriptionVariables
    >
      subscriptionDocument={InsightsReportChallengeSuggestionsDocument}
      hasVariables={() => props.reportId !== undefined}
      elementIds={{ reportIds: props.reportId ? [props.reportId] : [] }}
    >
      <Form {...props} initialValues={initialValues}>
        <FormWithInfoCardLayout
          renderForm={renderForm}
          renderAfterInfoCard={
            <>
              <InsightReportWizardInfoCard wizardStep={'challenges'} />

              <AllCommentsSideCard
                reportId={props.reportId}
                commentType={'CHALLENGE_COMMENT'}
                icon={<Emoji icon={'🚧️'} />}
                title={t('report.insightReportWizard.challenges.viewAll.title')}
                showButtonLabel={t(
                  'report.insightReportWizard.challenges.viewAll.button.show',
                )}
                hideButtonLabel={t(
                  'report.insightReportWizard.challenges.viewAll.button.hide',
                )}
                emptyMessage={t(
                  'report.insightReportWizard.challenges.viewAll.empty',
                )}
              />

              <AiSuggestions
                title={t(
                  'report.insightReportWizard.challenges.askSophie.title',
                )}
                buttonLabel={t(
                  'report.insightReportWizard.challenges.askSophie.button.label',
                )}
                buttonLabelAfterFirstTrigger={t(
                  'report.insightReportWizard.challenges.askSophie.button.triggeredLabel',
                )}
                titleFieldName={'challenge comment'}
                suggestionAction={'add'}
              />
            </>
          }
        />
      </Form>
    </SuggestionsProvider>
  );
};

export default InsightReportWizardChallenges;
