import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import styles from './RoadmapItem.module.scss';
import RoadmapItemBackground from '../RoadmapItemBackground';
import type { ItemColumn } from '../Roadmap.type';
import { getSize } from './RoadmapItem.utils';

export type RoadmapItemProps = PropsWithChildren<{
  column: ItemColumn;
  isFirst?: boolean;
  isLast?: boolean;
}>;

const RoadmapItem = ({
  column,
  isFirst = false,
  isLast = false,
  children,
}: RoadmapItemProps) => {
  const size = useMemo(() => getSize(column), [column]);

  return (
    <div className={styles.container}>
      <RoadmapItemBackground
        column={column}
        width={size.backgroundWidth}
        isFirst={isFirst}
        isLast={isLast}
      />
      <div className={styles.content}>{children}</div>
      <div
        className={styles.spacer}
        style={{
          ...(size.spacerWidth && {
            width: size.spacerWidth,
          }),
        }}
      />
    </div>
  );
};

export default RoadmapItem;
