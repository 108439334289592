import type { CSSProperties } from 'react';

import styles from 'shared/components/Text/Text.module.scss';

import type {
  TextElement,
  TextVariant,
  TextEllipsis,
  TextWhiteSpace,
} from './Text.type';

export const getTextElement = (variant: TextVariant) => {
  const elements: Record<TextVariant, TextElement> = {
    light: 'span',
    regular: 'span',
    emphasis: 'em',
    strong: 'strong',
    note: 'span',
  };
  return elements[variant];
};

export const getEllipsisProps = (
  ellipsis: TextEllipsis,
): {
  className?: string;
  style?: CSSProperties;
} => {
  if (ellipsis === true) {
    return {
      className: styles.textSingleLineEllipsis,
      style: {},
    };
  } else if (typeof ellipsis === 'object') {
    return {
      className: styles.textMultiLineEllipsis,
      style: {
        WebkitLineClamp: ellipsis.lineClamp,
      },
    };
  }
  return {
    className: '',
    style: {},
  };
};

export const getWhiteSpaceProps = (
  whiteSpace: TextWhiteSpace,
): {
  style: CSSProperties;
} => {
  switch (whiteSpace) {
    case 'preWrap': {
      return {
        style: {
          whiteSpace: 'pre-wrap',
        },
      };
    }
    case 'preLine': {
      return {
        style: {
          whiteSpace: 'pre-line',
        },
      };
    }
    case 'noWrap': {
      return {
        style: {
          whiteSpace: 'nowrap',
        },
      };
    }
    default: {
      return {
        style: {},
      };
    }
  }
};
