import type { ListState } from 'react-stately';
import { createContext } from 'react';

import type { ThemeMultiSelectItem } from '../ThemeMultiSelect.type';

export type ThemeMultiSelectProviderContextValue = {
  items: ThemeMultiSelectItem[];
  state: ListState<ThemeMultiSelectItem>;
};

export const ThemeMultiSelectProviderContext =
  createContext<Maybe<ThemeMultiSelectProviderContextValue>>(undefined);
