import partition from 'lodash/partition';
import { useMemo } from 'react';

import type {
  UseExpandableTreeItemsParams,
  UseExpandableTreeItemsReturnType,
} from './useExpandableTreeItems';
import { useExpandableTreeItems } from './useExpandableTreeItems';
import type { BaseTreeItem, GetParentTreeItemFn } from './useTree.type';
import type {
  UseSelectableTreeItemsReturnType,
  UseSelectableTreeItemsParams,
} from './useSelectableTreeItems';
import { useSelectableTreeItems } from './useSelectableTreeItems';

export type UseTreeParams<TreeItemParam extends BaseTreeItem> = {
  getParentItem: GetParentTreeItemFn<TreeItemParam>;
  items: TreeItemParam[];
} & UseExpandableTreeItemsParams &
  UseSelectableTreeItemsParams<TreeItemParam>;

export type UseTreeReturnType<TreeItemParam extends BaseTreeItem> = {
  nonParentItems: TreeItemParam[];
  parentItems: TreeItemParam[];
} & UseExpandableTreeItemsReturnType<TreeItemParam> &
  UseSelectableTreeItemsReturnType<TreeItemParam>;

export const useTree = <TreeItemParam extends BaseTreeItem>(
  params: UseTreeParams<TreeItemParam>,
): UseTreeReturnType<TreeItemParam> => {
  const { items, getParentItem, allExpanded, expandedItemsIds } = params;

  const [parentItems, nonParentItems] = useMemo(() => {
    return partition(items, (item) => {
      const parentItem = getParentItem(item);
      return !parentItem || items.every((item) => item.id !== parentItem.id);
    });
  }, [getParentItem, items]);

  const { toggleIsItemExpanded, isItemExpanded } =
    useExpandableTreeItems<TreeItemParam>({ allExpanded, expandedItemsIds });

  const { toggleIsItemSelected, isItemSelected } =
    useSelectableTreeItems<TreeItemParam>();

  return {
    parentItems,
    nonParentItems,
    isItemExpanded,
    toggleIsItemExpanded,
    isItemSelected,
    toggleIsItemSelected,
  };
};
