import styled from '@emotion/styled';
import { css } from '@emotion/react';

import StatusMark from 'shared/status/StatusMark';
import UserAvatar from 'user/UserAvatar';
import type { StatusIndicator } from 'shared/status/StatusIndicator';
import Flex from 'shared/components/Flex';

import type { StrategyOperationalItemOwner } from '../StrategyBoardList/StrategyBoardList.type';

const Container = styled.a<{ hasLink: boolean; isDisabled?: boolean }>`
  border: 1px solid
    ${({ theme, hasLink }) =>
      hasLink ? theme.color.strokeMedium : theme.color.strokeLight};
  border-radius: 8px;
  color: ${({ theme, hasLink }) =>
    hasLink ? theme.color.typoPrimary : theme.color.neutral2};
  display: flex;
  gap: 14px;
  overflow: hidden;
  padding: 5px 10px 5px 15px;
  position: relative;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  text-decoration: none;
  align-items: flex-start;

  &:hover {
    text-decoration: ${(props) => (props.hasLink ? 'underline' : 'none')};
    background-color: ${({ theme, hasLink }) =>
      hasLink && theme.color.hoverLight};
    opacity: 1;
  }
`;

const AvatarContainer = styled.div`
  height: 28px;
  width: 28px;
`;

type Props = {
  isDisabled?: boolean;
  linkTo?: string;
  owner?: Maybe<StrategyOperationalItemOwner>;
  statusIndicator?: Maybe<StatusIndicator>;
  textContent: Maybe<string>;
};

const StrategyBoardListRow = ({
  linkTo,
  statusIndicator,
  textContent,
  owner,
  isDisabled,
}: Props) => {
  return (
    <Container hasLink={!!linkTo} isDisabled={isDisabled} href={linkTo}>
      {linkTo && (
        <Flex alignItems={'center'} css={css({ height: 28 })}>
          <StatusMark statusIndicator={statusIndicator} hasBorder={false} />
        </Flex>
      )}

      <Flex css={css({ flexGrow: '1', marginTop: '3.5px' })}>
        {textContent}
      </Flex>

      {owner ? <UserAvatar user={owner} /> : <AvatarContainer />}
    </Container>
  );
};

export default StrategyBoardListRow;
