import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateOrgBackgroundImageMutationVariables = Types.Exact<{
  orgKey: Types.Scalars['String'];
  fileUrl: Types.Scalars['String'];
}>;


export type UpdateOrgBackgroundImageMutation = { __typename: 'Mutation', updateOrg: { __typename: 'Org', id: string, backgroundPhotoUrl?: string | undefined } };


export const UpdateOrgBackgroundImageDocument = gql`
    mutation UpdateOrgBackgroundImage($orgKey: String!, $fileUrl: String!) {
  updateOrg(input: {orgKeyToUpdate: $orgKey, backgroundPhotoUrl: $fileUrl}) {
    id
    backgroundPhotoUrl
  }
}
    `;
export type UpdateOrgBackgroundImageMutationFn = Apollo.MutationFunction<UpdateOrgBackgroundImageMutation, UpdateOrgBackgroundImageMutationVariables>;

/**
 * __useUpdateOrgBackgroundImageMutation__
 *
 * To run a mutation, you first call `useUpdateOrgBackgroundImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgBackgroundImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgBackgroundImageMutation, { data, loading, error }] = useUpdateOrgBackgroundImageMutation({
 *   variables: {
 *      orgKey: // value for 'orgKey'
 *      fileUrl: // value for 'fileUrl'
 *   },
 * });
 */
export function useUpdateOrgBackgroundImageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrgBackgroundImageMutation, UpdateOrgBackgroundImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrgBackgroundImageMutation, UpdateOrgBackgroundImageMutationVariables>(UpdateOrgBackgroundImageDocument, options);
      }
export type UpdateOrgBackgroundImageMutationHookResult = ReturnType<typeof useUpdateOrgBackgroundImageMutation>;
export type UpdateOrgBackgroundImageMutationResult = Apollo.MutationResult<UpdateOrgBackgroundImageMutation>;
export type UpdateOrgBackgroundImageMutationOptions = Apollo.BaseMutationOptions<UpdateOrgBackgroundImageMutation, UpdateOrgBackgroundImageMutationVariables>;