import type { Member } from './Members.type';

export const getMembersWithEmail = (members: Member[]) =>
  members.filter((member) => !!member.user?.email);

export const getMembersWithOwner = (params: {
  members: Member[];
  owner?: Member;
}) => {
  const { members, owner } = params;
  if (owner) {
    return [
      {
        id: owner.id,
        user: owner as Member['user'],
      },
      ...members.filter((member) => member.id !== owner.id),
    ];
  }
  return members;
};
