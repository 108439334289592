import { motion } from 'framer-motion';
import cn from 'classnames';

import { ReactComponent as CheckmarkDoneIcon } from 'shared/static/icons/icon-checkmark-done.svg';
import CompletionRateIcon from 'shared/components/CompletionRate/CompletionRateIcon';

import styles from './CompletionRate.module.scss';

type CompletionRateProps = {
  complete: Maybe<boolean>;
  completionRate: Maybe<number>;
};

const CompletionRate = ({ complete, completionRate }: CompletionRateProps) => (
  <motion.div layout={true} className={styles.completionRate}>
    {complete ? (
      <CheckmarkDoneIcon
        className={cn(
          styles.completionRateIcon,
          styles.completionRateIconComplete,
        )}
      />
    ) : (
      <CompletionRateIcon
        className={cn(
          styles.completionRateIcon,
          styles.completionRateIconIncomplete,
        )}
        completionRate={completionRate || 0}
      />
    )}
    <span className={styles.completionRateText}>{`${
      completionRate || 0
    }%`}</span>
  </motion.div>
);

export default CompletionRate;
