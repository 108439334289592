import { differenceInMilliseconds } from 'date-fns';
import { useTranslation } from 'react-i18next';

import Flex from 'shared/components/Flex';

import ProgressBar from './ProgressBar';
import Label from './Label';

type Props = {
  deadline?: Date;
  endDate?: Date;
  startDate: Date;
};

const TimelineProgressBar = ({ startDate, deadline, endDate }: Props) => {
  const { t } = useTranslation();
  const now = new Date();
  const finalDate = endDate || deadline || now;

  const activeRatio = calculateTimePercentagePassed(startDate, finalDate, now);
  const deadlineRatio = deadline
    ? calculateTimePercentagePassed(startDate, finalDate, deadline)
    : undefined;

  return (
    <Flex direction={'column'} gap={'0.2rem'}>
      <Flex>
        <Label label={t('start')} date={startDate} />

        <Flex grow={1} />

        <Flex gap={'2rem'}>
          <Label label={t('deadline')} date={deadline} />
          <Label label={t('end')} date={endDate} />
        </Flex>
      </Flex>

      <ProgressBar ratio={activeRatio} markerRatio={deadlineRatio} />
    </Flex>
  );
};

export default TimelineProgressBar;

function calculateTimePercentagePassed(
  startDate: Date,
  endDate: Date,
  currentDate: Date,
): number {
  const totalDuration = differenceInMilliseconds(endDate, startDate);
  const elapsedDuration = differenceInMilliseconds(currentDate, startDate);
  const percentagePassed = (elapsedDuration / totalDuration) * 100;
  return Math.min(Math.max(percentagePassed, 0), 100);
}
