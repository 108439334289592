import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import ListView from 'shared/components/ListView';
import CreateUserButton from 'user/CreateUserButton';
import Flex from 'shared/components/Flex';
import Text from 'shared/components/Text';

import TeamMembersAddMemberButton from '../TeamMembersAddMemberButton';
import { useTeamMembers } from '../TeamMembersProvider';
import type { TeamMembersListViewRowProps } from '../TeamMembersListViewRow';
import TeamMembersListViewRow from '../TeamMembersListViewRow';
import type { TeamMembersUserFragment } from '../TeamMembersProvider/TeamMembersProvider.graphql.generated';

export type TeamMembersListViewProps = Pick<
  TeamMembersListViewRowProps,
  'onAction'
>;

const TeamMembersListView = ({ onAction }: TeamMembersListViewProps) => {
  const { t } = useTranslation();
  const { users, orgUnit } = useTeamMembers();

  if (users.length === 0) return null;

  return (
    <ListView<TeamMembersUserFragment>
      items={users}
      Row={(rowProps) => (
        <TeamMembersListViewRow {...rowProps} onAction={onAction} />
      )}
      before={
        <Flex
          justifyContent={'space-between'}
          alignItems={'center'}
          css={css`
            padding: 0.75rem 0;
          `}
        >
          <Text size={'large'} color={'black'} css={css({ fontSize: 21 })}>
            {t('team.teamMembers.title')}
          </Text>
          {orgUnit ? <TeamMembersAddMemberButton /> : <CreateUserButton />}
        </Flex>
      }
    />
  );
};

export default TeamMembersListView;
