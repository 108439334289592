import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { capitalize } from 'lodash';

import Card from 'shared/components/Card';
import Text from 'shared/components/Text';
import MetricInsightsChart from 'metric/MetricInsightsChart';
import StatusMark from 'shared/status/StatusMark';
import Heading from 'shared/components/Heading';
import Collapse from 'shared/components/Collapse';
import Space from 'shared/components/Space';
import StatusComments from 'shared/status/StatusComments/StatusComments';
import { migrateTextItemsToStatusComments } from 'shared/status/status.utils';
import UserUpdateStatus from 'user/UserUpdateStatus/UserUpdateStatus';

import type { StrategyInsightsMetricFragment } from '../StrategyInsights.graphql.generated';
import styles from './StrategyInsightsMetric.module.scss';

export type StrategyInsightsMetricProps = {
  metric: StrategyInsightsMetricFragment;
  summaryBackground?: boolean;
} & {
  className?: string;
};

const StrategyInsightsMetric = ({
  metric,
  summaryBackground,
}: StrategyInsightsMetricProps) => {
  const { metricStatusListAll, currentMetricStatus } = metric;

  const { t } = useTranslation();

  const [isInsightSummaryCollapsed, toggleIsInsightSummaryCollapsed] =
    useToggle(true);

  return (
    <Card
      className={styles.card}
      contentClassName={styles.cardContent}
      border={false}
    >
      <Card.Heading
        level={5}
        before={
          <StatusMark
            statusIndicator={metric.currentMetricStatus?.statusIndicator}
          />
        }
        after={<Text variant={'emphasis'}>{metric.name}</Text>}
      >
        {capitalize(t('metric.metric'))}
      </Card.Heading>
      {metricStatusListAll.length ? (
        <MetricInsightsChart metric={metric} className={styles.chart} />
      ) : (
        <Text>
          {t('strategy.strategyInsights.objective.metric.noMetricInsights')}
        </Text>
      )}
      {currentMetricStatus ? (
        <Collapse
          renderHeading={() => (
            <Heading level={4} hasMargin={false}>
              {t(
                'strategy.strategyInsights.objective.metric.insightSummary.heading',
              )}
            </Heading>
          )}
          isCollapsed={isInsightSummaryCollapsed}
          onToggleIsCollapsed={toggleIsInsightSummaryCollapsed}
          hasBackground={summaryBackground}
          className={styles.insightSummaryContainer}
        >
          <Space direction={'vertical'} size={'big'}>
            <StatusComments
              items={migrateTextItemsToStatusComments({
                challengeComments: currentMetricStatus.challengeComments,
                successComments: currentMetricStatus.successComments,
                actionComments: currentMetricStatus.actionComments,
              })}
            />
            <UserUpdateStatus
              auditRecordUser={currentMetricStatus.auditRecord.updateBy}
              updateDateTime={currentMetricStatus.auditRecord.updateDateTime}
            />
          </Space>
        </Collapse>
      ) : null}
    </Card>
  );
};

export default StrategyInsightsMetric;
