import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useHandleApolloError from 'shared/errors/useHandleApolloError';

import type { ObjectiveOverviewContextValue } from './ObjectiveOverviewProvider.context';
import { ObjectiveOverviewContext } from './ObjectiveOverviewProvider.context';
import { useObjectiveOverviewQuery } from './ObjectiveOverviewProvider.graphql.generated';

type ObjectiveOverviewProviderProps = PropsWithChildren<object>;

const ObjectiveOverviewProvider = ({
  children,
}: ObjectiveOverviewProviderProps) => {
  const { objectiveId } = useParams() as {
    objectiveId: string;
  };

  const handleApolloError = useHandleApolloError();

  const { data, loading: isObjectiveOverviewLoading } =
    useObjectiveOverviewQuery({
      variables: {
        objectiveId,
      },
      onError: handleApolloError,
    });

  const contextValue = useMemo<ObjectiveOverviewContextValue>(
    () => ({
      objective: data?.objective,
      isObjectiveOverviewLoading,
    }),
    [data, isObjectiveOverviewLoading],
  );

  return (
    <ObjectiveOverviewContext.Provider value={contextValue}>
      {children}
    </ObjectiveOverviewContext.Provider>
  );
};

export default ObjectiveOverviewProvider;
