import { useDefinedContext } from 'shared/utils/context.utils';

import { InitiativeContext } from './InitiativeOverviewProvider.context';
import InitiativeProvider from './InitiativeOverviewProvider';

export const useInitiativeOverview = () =>
  useDefinedContext(InitiativeContext, {
    hookName: useInitiativeOverview.name,
    providerName: InitiativeProvider.name,
  });
