import type { ListState } from 'react-stately';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Space from 'shared/components/Space';
import Text from 'shared/components/Text';

import type { ThemeMultiSelectItem } from '../ThemeMultiSelect.type';

type ThemeMultiSelectLabelProps = {
  state: ListState<ThemeMultiSelectItem>;
};

const ThemeMultiSelectLabel = ({ state }: ThemeMultiSelectLabelProps) => {
  const { t } = useTranslation();

  const selectedKeysCount = state.selectionManager.selectedKeys.size;

  const content = useMemo<ReactNode>(() => {
    const value = selectedKeysCount
      ? `${selectedKeysCount} ${t('theme.theme', { count: selectedKeysCount })}`
      : t('theme.allThemes');
    return (
      <Trans i18nKey={'theme.themeMultiSelect.label'} values={{ value }}>
        <Text variant={'emphasis'} />
      </Trans>
    );
  }, [selectedKeysCount, t]);

  return (
    <Space size={'small'}>
      <Text>{content}</Text>
    </Space>
  );
};

export default ThemeMultiSelectLabel;
