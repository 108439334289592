import type { ListState } from 'react-stately';
import { createContext } from 'react';

import type { MultiSelectProps } from 'shared/components/MultiSelect';

import type { StatusMultiSelectItem } from '../StatusMultiSelect.type';

export type StatusMultiSelectProviderContextValue = {
  items: StatusMultiSelectItem[];
  state: ListState<StatusMultiSelectItem>;
} & Pick<MultiSelectProps, 'onSelectionChange' | 'selectedKeys'>;

export const StatusMultiSelectProviderContext =
  createContext<Maybe<StatusMultiSelectProviderContextValue>>(undefined);
