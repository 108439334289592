import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import Button from 'shared/components/Button';
import Emoji from 'shared/components/Emoji';
import SideCard from 'shared/components/SideCard';

import { usePreviousInsights } from './usePreviousInsights';
import PreviousInsightsSideModal from './PreviousInsightsSideModal';

const PreviousInsightsSidecard = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { onOpenPreviousInsights } = usePreviousInsights();

  return (
    <>
      <SideCard
        title={t('status.sideCard.title')}
        backgroundColor={theme.legacyColor.colorAlabaster}
        iconBackgroundColor={theme.legacyColor.colorDarkGrey}
        icon={<Emoji icon={'💡'} />}
      >
        <div>{t('status.sideCard.description')}</div>
        <Button variant={'outlined'} onClick={() => onOpenPreviousInsights()}>
          {t('status.sideCard.button')}
        </Button>
      </SideCard>

      <PreviousInsightsSideModal />
    </>
  );
};

export default PreviousInsightsSidecard;
