import { useDefinedContext } from 'shared/utils/context.utils';

import { PreviousInsightsContext } from './PreviousInsightsContext';
import PreviousInsightsProvider from './PreviousInsightsProvider';

export const usePreviousInsights = () =>
  useDefinedContext(PreviousInsightsContext, {
    hookName: usePreviousInsights.name,
    providerName: PreviousInsightsProvider.name,
  });
