import type { User } from 'firebase/auth';
import { createContext } from 'react';

export type AuthProviderContextValue = {
  error: Error | undefined;
  isLoading: boolean;
  signOut: () => Promise<void>;
  user: User | undefined;
};

export const AuthProviderContext = createContext<
  AuthProviderContextValue | undefined
>(undefined);
