import StrategyRoadmap from 'strategy/StrategyRoadmap';
import Space from 'shared/components/Space';
import { useStrategy } from 'strategy/StrategyProvider';
import { useTeamAdapter } from 'team/TeamAdapter';

const StrategyOverviewMap = () => {
  const { strategy } = useStrategy();

  const { teamAdapter } = useTeamAdapter();

  if (!strategy) {
    return null;
  }

  return (
    <Space direction={'vertical'} size={'medium'}>
      <StrategyRoadmap
        teamAdapter={teamAdapter}
        strategyId={strategy.id}
        showGuide={true}
      />
    </Space>
  );
};

export default StrategyOverviewMap;
