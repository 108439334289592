import { createContext } from 'react';

import type { ObjectiveQuery } from './ObjectiveProvider.graphql.generated';

export type ObjectiveProviderContextValue = {
  objective: ObjectiveQuery['objective'];
};

export const ObjectiveProviderContext = createContext<
  ObjectiveProviderContextValue | undefined
>(undefined);
