import styled from '@emotion/styled';
import type { PropsWithChildren, ReactNode } from 'react';

const Container = styled.li<MenuItemProps>`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: stretch;
  user-select: none;
  width: 12.75rem;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.isDisabled ? 0.4 : 1)};

  &:hover {
    background: ${(props) =>
      !props.isDisabled ? props.theme.legacyColor.colorAlabaster : 'none'};
  }
`;

const IconContainer = styled.div`
  padding-left: 0.5rem;
`;

const Content = styled.span`
  align-items: center;
  display: flex;
  font-size: 1rem;
  overflow: hidden;
  padding: 0.25em 1em;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export type MenuItemProps = PropsWithChildren<{
  className?: string;
  icon?: ReactNode;
  isDisabled?: boolean;
  onClick?: () => void;
}>;

const MenuItem = ({
  icon,
  children,
  onClick,
  isDisabled,
  className,
}: MenuItemProps) => (
  <Container
    role={'menuitem'}
    onClick={isDisabled ? undefined : onClick}
    className={className}
  >
    {icon && <IconContainer>{icon}</IconContainer>}
    <Content>{children}</Content>
  </Container>
);

export default MenuItem;
