import type { CaptionElement, CaptionSize } from './Caption.type';

export const getCaptionElement = (size: CaptionSize) => {
  const elements: Record<CaptionSize, CaptionElement> = {
    C1: 'h2',
    C2: 'h3',
    C3: 'h4',
  };
  return elements[size];
};
