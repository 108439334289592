import { useTranslation } from 'react-i18next';

import Result from 'shared/components/Result';
import { ReactComponent as EmptyIllustration } from 'shared/static/illustrations/illustration-empty-state.svg';

const NoMatchingResultsResult = () => {
  const { t } = useTranslation();

  return (
    <Result
      description={t('noMatchingResults')}
      illustration={EmptyIllustration}
      hasBackground={true}
    />
  );
};

export default NoMatchingResultsResult;
