import type { AuthError } from 'firebase/auth';
import { AuthErrorCodes, getAuth } from 'firebase/auth';

import { isErrorWithCode } from 'shared/utils/error.utils';
export const isAuthError = (error: unknown): error is AuthError => {
  const baseErrorCodes = Object.values(AuthErrorCodes) as string[];
  const additionalErrorCodes = ['auth/missing-email'];
  const errorCodes = [...baseErrorCodes, ...additionalErrorCodes];
  return (
    isErrorWithCode(error) &&
    typeof error.code === 'string' &&
    errorCodes.includes(error.code)
  );
};

export const getIdToken = async (
  forceRefresh?: boolean,
): Promise<Maybe<string>> => {
  const auth = getAuth();
  return auth.currentUser?.getIdToken(forceRefresh);
};
