import { Trans } from 'react-i18next';
import { useMemo } from 'react';
import cn from 'classnames';

import { date } from 'shared/services/date.service';
import AuditRecordUserAvatar from 'user/AuditRecordUserAvatar/AuditRecordUserAvatar';

import styles from './MetricOverviewInsightsFirstRow.module.scss';
import type { MetricOverviewMetric } from '../MetricOverview.type';

type MetricInsightsFirstRowProps = {
  isLastRow: boolean;
  metric: Maybe<
    Pick<MetricOverviewMetric, 'id' | 'auditRecord' | 'metricStatusListAll'>
  >;
};

const MetricInsightsFirstRow = ({
  metric,
  isLastRow,
}: MetricInsightsFirstRowProps) => {
  const createDateTime = useMemo(
    () =>
      metric?.auditRecord.createDateTime
        ? date.format(metric?.auditRecord.createDateTime, 'dd MMM yyyy')
        : '',
    [metric?.auditRecord.createDateTime],
  );

  const isOnlyItem = useMemo(
    () => !metric?.metricStatusListAll.length,
    [metric?.metricStatusListAll],
  );

  return (
    <li
      key={metric?.id}
      className={cn(styles.item, {
        [styles.lastRowItem]: isLastRow,
        [styles.onlyItem]: isOnlyItem,
      })}
    >
      <div className={styles.header}>
        <AuditRecordUserAvatar
          auditRecordUser={metric?.auditRecord.createBy}
          size={'small'}
        />
        <div className={styles.shortText}>
          <Trans
            i18nKey={'metric.insights.recentInsights.metricCreated'}
            values={{
              user:
                metric?.auditRecord.createBy?.displayName ??
                metric?.auditRecord.createBy?.email,
              date: createDateTime,
            }}
            components={{
              emphasis: <span className={styles.emphasis} />,
            }}
          />
        </div>
      </div>
    </li>
  );
};

export default MetricInsightsFirstRow;
