import { memo } from 'react';
import cn from 'classnames';

import Space from 'shared/components/Space';
import Text from 'shared/components/Text';
import { StatusIndicator } from 'shared/status/StatusIndicator';
import StatusMark from 'shared/status/StatusMark';
import type { Size } from 'shared/types/size.type';

import type { ObjectiveChipObjective } from './ObjectiveChip.type';
import styles from './ObjectiveChip.module.scss';

export type ObjectiveChipProps = {
  className?: string;
  objective: ObjectiveChipObjective;
  size?: Extract<Size, 'medium' | 'big'>;
};

const ObjectiveChip = ({
  objective,
  size = 'medium',
  className,
}: ObjectiveChipProps) => {
  const statusIndicator =
    objective.currentObjectiveStatus?.statusIndicator || new StatusIndicator();

  return (
    <Space
      className={cn(
        styles.objectiveChip,
        { [styles.objectiveChipSizeBig]: size === 'big' },
        className,
      )}
    >
      <Space>
        <StatusMark
          statusIndicator={statusIndicator}
          className={styles.objectiveChipCurrentObjectiveStatus}
        />
        <Text
          variant={'emphasis'}
          ellipsis={{
            lineClamp: 2,
          }}
          className={styles.objectiveChipName}
        >
          {objective.name}
        </Text>
      </Space>
    </Space>
  );
};

const MemoizedObjectiveChip = memo(ObjectiveChip);

export default MemoizedObjectiveChip;
