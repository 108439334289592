import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

import StrategyRoomThemesManageThemes from 'strategy/StrategyRoomThemesManageThemes/StrategyRoomThemesManageThemes';
import StrategyRoomThemesManageObjectives from 'strategy/StrategyRoomThemesManageObjectives/StrategyRoomThemesManageObjectives';
import StrategyProvider from 'strategy/StrategyProvider';

import StrategyRoomStrategy from '../StrategyRoomStrategy';
import StrategyRoomLayout from '../StrategyRoomLayout';
import { strategyRoomRoutesPaths } from './StrategyRoom.routing.paths';
import NavigateToStrategyRoomStrategy from './NavigateToStrategyRoomStrategy';

export const StrategyRoom = () => (
  <Routes>
    <Route
      path={strategyRoomRoutesPaths.root()}
      element={<StrategyRoomLayout />}
    >
      <Route
        path={strategyRoomRoutesPaths.strategy.root({
          allowNestedRoutes: true,
        })}
        element={<StrategyRoomStrategy />}
      />
      <Route
        path={strategyRoomRoutesPaths.themes.root({ allowNestedRoutes: true })}
        element={<Outlet />}
      >
        <Route
          index={true}
          element={
            <Navigate
              to={strategyRoomRoutesPaths.themes.manageThemes()}
              replace={true}
            />
          }
        />
        <Route
          path={strategyRoomRoutesPaths.themes.manageThemes()}
          element={<StrategyRoomThemesManageThemes />}
        />
        <Route
          path={strategyRoomRoutesPaths.themes.manageObjectives()}
          element={
            <StrategyProvider>
              <StrategyRoomThemesManageObjectives />
            </StrategyProvider>
          }
        />
      </Route>
      <Route index={true} element={<NavigateToStrategyRoomStrategy />} />
    </Route>
  </Routes>
);

export default StrategyRoom;
