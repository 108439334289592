import { format, isPast } from 'date-fns';
import { css, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckIcon } from 'shared/static/icons/icon-check.svg';

import Flex from '../Flex';
import Text from '../Text';

type Props = {
  date?: Date;
  label: string;
};

const Label = ({ label, date }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const iconColor =
    date && isPast(date) ? theme.color.primary : theme.color.typoSecondary;

  const textColor =
    date && isPast(date) ? theme.color.black : theme.color.typoSecondary;

  return (
    <Flex alignItems={'center'} gap={'0.2rem'} css={css({ color: textColor })}>
      {date && <CheckIcon color={iconColor} />}
      {label}
      {': '}
      <Text>{date ? format(date, 'P') : t('notAvailableShort')}</Text>
    </Flex>
  );
};

export default Label;
