import type { Objective } from 'types.graphql.generated';

import type {
  StrategyBoardInitiative,
  StrategyBoardMetric,
  StrategyBoardObjective,
} from '../StrategyBoard.type';

export const getMetricsWithStatus = (
  metrics: StrategyBoardMetric[],
  objectiveId: Objective['id'],
) =>
  metrics.map(({ currentMetricStatus, ...metric }) => ({
    objectiveId: objectiveId,
    status: currentMetricStatus,
    ...metric,
  }));

export const getObjectiveWithStatus = (
  objective: Omit<StrategyBoardObjective, 'metrics' | 'initiatives'>,
) => {
  const { currentObjectiveStatus, ...restOfObjective } = objective;
  return {
    objectiveId: objective.id,
    status: currentObjectiveStatus,
    ...restOfObjective,
  };
};

export const getInitiativesWithStatus = (
  initiatives: StrategyBoardInitiative[],
  objectiveId: Objective['id'],
) =>
  initiatives.map(({ currentInitiativeStatus, ...initiative }) => ({
    objectiveId: objectiveId,
    status: currentInitiativeStatus,
    ...initiative,
  }));
