import type { PropsWithChildren } from 'react';
import cn from 'classnames';
import { forwardRef, useMemo } from 'react';

import {
  getTextElement,
  getEllipsisProps,
  getWhiteSpaceProps,
} from './Text.utils';
import type {
  TextVariant,
  TextColor,
  TextEllipsis,
  TextSize,
  TextWhiteSpace,
} from './Text.type';
import styles from './Text.module.scss';

export type TextProps = PropsWithChildren<{
  className?: string;
  color?: TextColor;
  ellipsis?: TextEllipsis;
  isBold?: boolean;
  isCentered?: boolean;
  isItalic?: boolean;
  isUppercase?: boolean;
  onClick?: () => void;
  size?: TextSize;
  variant?: TextVariant;
  whiteSpace?: TextWhiteSpace;
}>;

/**
 * Renders lines of text and paragraphs within an interface.
 */
const Text = forwardRef<HTMLElement, TextProps>(
  (
    {
      variant = 'regular',
      ellipsis = false,
      size = 'medium',
      color = 'regular',
      isUppercase = false,
      isItalic = false,
      isBold,
      isCentered = false,
      whiteSpace = 'normal',
      children,
      className,
      ...restProps
    },
    ref,
  ) => {
    const Element = getTextElement(variant);

    const ellipsisProps = getEllipsisProps(ellipsis);
    const whiteSpaceProps = getWhiteSpaceProps(whiteSpace);

    const inlinesStyles = useMemo(
      () => ({
        ...whiteSpaceProps.style,
        ...ellipsisProps.style,
      }),
      [ellipsisProps.style, whiteSpaceProps.style],
    );

    return (
      <Element
        ref={ref}
        {...restProps}
        className={cn(
          styles.text,
          {
            [styles.textRegular]: variant === 'regular',
            [styles.textNote]: variant === 'note',
            [styles.textEmphasis]: variant === 'emphasis',
            [styles.textStrong]: variant === 'strong',
            [styles.textLight]: variant === 'light',
            [styles.textSizeMedium]: size === 'medium',
            [styles.textSizeSmall]: size === 'small',
            [styles.textSizeTiny]: size === 'tiny',
            [styles.textSizeBig]: size === 'big',
            [styles.textSizeLarge]: size === 'large',
            [styles.textColorPrimary]: color === 'primary',
            [styles.textColorError]: color === 'error',
            [styles.textColorBlack]: color === 'black',
            [styles.textUppercase]: isUppercase,
            [styles.textCentered]: isCentered,
            [styles.textItalic]: isItalic,
            [styles.textBold]: isBold,
          },
          ellipsisProps.className,
          className,
        )}
        style={inlinesStyles}
      >
        {children}
      </Element>
    );
  },
);

export default Text;
