import compact from 'lodash/compact';

import { getNodesFromConnection } from 'shared/graphql/utils';

import type { SelectThemeInputOption } from '../SelectThemeInput.type';
import type {
  SelectThemeInputForOrgUnitOrgFragment,
  SelectThemeInputForOrgUnitOrgUnitFragment,
  SelectThemeInputForOrgUnitThemeFragment,
} from './SelectThemeInputForOrgUnitProvider.graphql.generated';

export const getEmptyOption = (): SelectThemeInputOption => ({
  value: null,
});

const mapThemeToOption = (
  theme: SelectThemeInputForOrgUnitThemeFragment,
): SelectThemeInputOption => ({
  value: {
    id: theme.id,
    name: theme.name,
  },
});

export const getOptions = (params: {
  org: SelectThemeInputForOrgUnitOrgFragment;
  orgUnit: SelectThemeInputForOrgUnitOrgUnitFragment;
}): SelectThemeInputOption[] => {
  const { org, orgUnit } = params;
  const orgUnitOptions: SelectThemeInputOption = {
    label: orgUnit.name,
    options: getNodesFromConnection(orgUnit.themes).map(mapThemeToOption),
  };
  const parentOrgUnitOptions: Maybe<SelectThemeInputOption> =
    orgUnit.parentOrgUnit
      ? {
          label: orgUnit.parentOrgUnit.name,
          options: getNodesFromConnection(orgUnit.parentOrgUnit.themes).map(
            mapThemeToOption,
          ),
        }
      : undefined;
  const orgOptions: SelectThemeInputOption = {
    label: org.displayName,
    options: getNodesFromConnection(org.themes).map(mapThemeToOption),
  };
  return compact([
    getEmptyOption(),
    orgUnitOptions,
    parentOrgUnitOptions,
    orgOptions,
  ]);
};
