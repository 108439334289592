import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import OpenTextInputField from 'shared/components/OpenTextInput/OpenTextInput.field';
import FieldBox from 'shared/form/FieldBox';
import { DatePickerField } from 'shared/components/DatePicker';
import {
  UserEmailInputField,
  UserEmailInputProvider,
} from 'user/UserEmailInput';
import { useTeamAdapter } from 'team/TeamAdapter';
import {
  SelectObjectiveInputField,
  SelectObjectiveInputProvider,
} from 'objective/SelectObjectiveInput';
import {
  SelectThemeInputField,
  SelectThemeInputProvider,
} from 'objective/SelectThemeInput';
import Flex from 'shared/components/Flex';
import { PrioritySelectField } from 'strategy/PrioritySelect';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';
import Spinner from 'shared/spinner/Spinner';
import {
  SelectOrgUnitInputField,
  SelectOrgUnitInputProvider,
} from 'orgUnit/SelectOrgUnitInput';
import AttributesFields from 'shared/components/AttributesFields';
import ExpandableFormContainer from 'shared/form/FormContainer/ExpandableFormContainer';
import ObjectiveNameCheatSheetButton from 'objective/ObjectiveNameCheatSheetButton';

import type { ObjectiveFormValues } from '../ObjectiveForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DetailsTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  border: 1px solid ${(props) => props.theme.color.strokeLight};
  border-radius: 20px 20px 0 0;
`;

const DetailsBottom = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid ${(props) => props.theme.color.strokeLight};
  border-top: 0;
  border-radius: 0 0 20px 20px;
  background-color: ${(props) => props.theme.color.backgroundLight};
`;

const ObjectiveFormFields = () => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();

  const { values, getFieldMeta } = useFormikContext<ObjectiveFormValues>();

  const hasError = (field: string) => getFieldMeta(field).error !== undefined;

  const { priorities, isPrioritiesLoading } = useStrategyProfilePriorities();

  if (isPrioritiesLoading) return <Spinner.Circle />;

  return (
    <Container>
      <div>
        <DetailsTop>
          <Field
            component={OpenTextInputField}
            name={'name'}
            hasError={hasError('name')}
            placeholder={t('objective.objectiveForm.name.placeholder')}
            fontSize={'big'}
            maxLength={100}
            autoFocus={true}
          />
          <ObjectiveNameCheatSheetButton />
          <Field
            component={OpenTextInputField}
            name={'description'}
            hasError={hasError('description')}
            placeholder={t('objective.objectiveForm.description.placeholder')}
            maxLength={1000}
            inputType={'textarea'}
          />
        </DetailsTop>

        <DetailsBottom>
          <Flex gap={20} css={css(`> div { flex-grow: 1 }`)}>
            <FieldBox
              name={'timeLine.startDate'}
              label={t('objective.objectiveForm.timeline.startDate.label')}
              hasError={hasError('startDate')}
              hasMargin={false}
            >
              <Field
                name={'timeLine.startDate'}
                component={DatePickerField}
                maxDate={values.timeLine.endDate}
                triggerFullWidth={true}
              />
            </FieldBox>
            <FieldBox
              name={'timeLine.endDate'}
              label={t('objective.objectiveForm.timeline.endDate.label')}
              hasError={hasError('timeLine.endDate')}
              hasMargin={false}
            >
              <Field
                name={'timeLine.endDate'}
                component={DatePickerField}
                minDate={values.timeLine.startDate}
                triggerFullWidth={true}
              />
            </FieldBox>
          </Flex>

          <FieldBox
            name={'owner'}
            label={t('objective.objectiveForm.owner.label')}
            hasError={hasError('owner')}
          >
            <UserEmailInputProvider teamAdapter={teamAdapter}>
              <Field
                component={UserEmailInputField}
                name={'owner'}
                hasError={hasError('owner')}
                autoComplete={'false'}
                placeholder={t('actions.form.owner.placeholder')}
              />
            </UserEmailInputProvider>
          </FieldBox>

          <FieldBox
            name={'orgUnit'}
            label={t('objective.objectiveForm.orgUnit.label')}
            hasMargin={false}
          >
            <SelectOrgUnitInputProvider
              filterOrgByAction={'WRITE_STRATEGY_ELEMENT'}
              filterOrgUnitsByAction={'WRITE_STRATEGY_ELEMENT'}
            >
              <Field
                name={'orgUnit'}
                component={SelectOrgUnitInputField}
                size={'full'}
              />
            </SelectOrgUnitInputProvider>
          </FieldBox>

          <FieldBox
            name={'parentObjective'}
            label={t('objective.parentObjective')}
            hasMargin={false}
          >
            <SelectObjectiveInputProvider
              teamAdapter={teamAdapter}
              objectiveToExclude={values.id || undefined}
              filterByAction={'READ'}
            >
              <Field
                name={'parentObjective'}
                component={SelectObjectiveInputField}
                size={'full'}
              />
            </SelectObjectiveInputProvider>
          </FieldBox>

          <FieldBox
            name={'theme'}
            label={t('objective.objectiveForm.theme.label')}
            hasMargin={false}
          >
            <SelectThemeInputProvider teamAdapter={teamAdapter}>
              <Field
                name={'theme'}
                component={SelectThemeInputField}
                size={'full'}
              />
            </SelectThemeInputProvider>
          </FieldBox>

          {priorities && (
            <FieldBox
              name={'priority'}
              label={t('objective.objectiveForm.priority.label')}
            >
              <Field
                name={'priority'}
                component={PrioritySelectField}
                priorities={priorities}
                fullWidth={true}
              />
            </FieldBox>
          )}
        </DetailsBottom>
      </div>

      {values.attributes.length > 0 && (
        <ExpandableFormContainer
          title={t('attributes.attributes')}
          isOptional={true}
        >
          <AttributesFields name={'attributes'} />
        </ExpandableFormContainer>
      )}
    </Container>
  );
};

export default ObjectiveFormFields;
