import type { SetOptional } from 'type-fest';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import EditOrgUnitForm from 'orgUnit/EditOrgUnitForm';
import type { EditOrgUnitFormProps } from 'orgUnit/EditOrgUnitForm';
import type { CompactModalProps } from 'shared/components/__DEPRECATED__/CompactModal';
import { useToasts } from 'shared/toast/useToasts';
import Dialog from 'shared/components/Dialog';

const editOrgUnitFormId = 'editOrgUnitForm';

export type EditOrgUnitModalProps = SetOptional<
  EditOrgUnitFormProps,
  'onSuccess'
> &
  Pick<CompactModalProps, 'isOpen' | 'onClose'>;

const EditOrgUnitModal = ({
  isOpen,
  onClose,
  onSuccess,
  ...restProps
}: EditOrgUnitModalProps) => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const handleSuccess = useCallback<EditOrgUnitFormProps['onSuccess']>(
    (...params) => {
      onSuccess?.(...params);
      addToast({
        children: t('orgUnit.editOrgUnitModal.successToast'),
        variant: 'success',
      });
      onClose();
    },
    [addToast, onSuccess, t, onClose],
  );

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t('orgUnit.editOrgUnitModal.heading')}
      confirmLabel={t('update')}
      cancelLabel={t('cancel')}
      formId={editOrgUnitFormId}
    >
      <EditOrgUnitForm
        {...restProps}
        id={editOrgUnitFormId}
        onSuccess={handleSuccess}
      />
    </Dialog>
  );
};

export default EditOrgUnitModal;
