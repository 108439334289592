import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Heading from 'shared/components/Heading';
import Space from 'shared/components/Space';
import Text from 'shared/components/Text';
import ToolOverviewCard from 'toolkit/ToolOverviewCard';
import { ReactComponent as IllustrationAwareness } from 'shared/static/illustrations/illustration-awareness.svg';
import { ReactComponent as IllustrationFeedback1 } from 'shared/static/illustrations/illustration-feedback-1.svg';
import { ReactComponent as IllustrationFeedback2 } from 'shared/static/illustrations/illustration-feedback-2.svg';
import Layout from 'shared/components/Layout';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  align-items: stretch;
`;

const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.legacyColor.colorLightOragne};
  border-radius: 20px;
  padding: 48px;
  gap: 8px;
`;

const WalkOfStrategyOverview = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Layout.Content hasPadding={false}>
      <Container>
        <HeadContainer>
          <Text
            isBold={true}
            isUppercase={true}
            size={'small'}
            css={css({ color: theme.color.secondaryVariant })}
          >
            {t('toolkit.toolPage.walkOfStrategy.overview.toolkit')}
          </Text>
          <Heading level={1} hasMargin={false}>
            {t('toolkit.tools.walkOfStrategy.name')}
          </Heading>
          <Text>{t('toolkit.tools.walkOfStrategy.description')}</Text>
        </HeadContainer>
        <Space isCentered={true}>
          <Text css={css({ maxWidth: 620 })} isCentered={true}>
            {t('toolkit.toolPage.walkOfStrategy.overview.intro')}
          </Text>
        </Space>
        <Space css={css({ gap: 24 })}>
          <ToolOverviewCard
            Image={IllustrationAwareness}
            background={1}
            title={t(
              'toolkit.toolPage.walkOfStrategy.overview.card.build.title',
            )}
            description={t(
              'toolkit.toolPage.walkOfStrategy.overview.card.build.description',
            )}
          />
          <ToolOverviewCard
            Image={IllustrationFeedback1}
            background={2}
            title={t(
              'toolkit.toolPage.walkOfStrategy.overview.card.engage.title',
            )}
            description={t(
              'toolkit.toolPage.walkOfStrategy.overview.card.engage.description',
            )}
          />
          <ToolOverviewCard
            Image={IllustrationFeedback2}
            background={3}
            title={t(
              'toolkit.toolPage.walkOfStrategy.overview.card.collect.title',
            )}
            description={t(
              'toolkit.toolPage.walkOfStrategy.overview.card.collect.description',
            )}
          />
        </Space>
      </Container>
    </Layout.Content>
  );
};

export default WalkOfStrategyOverview;
