import useHandleApolloError from 'shared/errors/useHandleApolloError';

import { useStrategyProfilePrioritiesQuery } from './StrategyProfileFilters.graphql.generated';

const useStrategyProfilePriorities = () => {
  const handleApolloError = useHandleApolloError();

  const { data } = useStrategyProfilePrioritiesQuery({
    fetchPolicy: 'cache-first',
    onError: handleApolloError,
  });

  return {
    isPrioritiesLoading: !data,
    priorities: data?.activeOrg.currentStrategyProfile?.priorities,
  };
};

export default useStrategyProfilePriorities;
