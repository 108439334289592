import type { CellProps } from 'react-table';
import type { Merge } from 'type-fest';

import ReportStageTag from 'report/ReportStageTag';
import Text from 'shared/components/Text';

import styles from './ReportsTable.module.scss';
import type { ReportsTableRow } from './ReportsTable.type';

type ReportNameCellProps = Merge<
  CellProps<ReportsTableRow, ReportsTableRow['name']>,
  {
    onClick: (report: ReportsTableRow) => void;
  }
>;

export const ReportNameCell = ({
  value,
  onClick,
  cell,
}: ReportNameCellProps) => (
  <Text
    variant={'emphasis'}
    onClick={() => {
      onClick(cell.row.original);
    }}
    className={styles.reportNameCell}
  >
    {value}
  </Text>
);

type ReportStageCellProps = CellProps<
  ReportsTableRow,
  ReportsTableRow['reportStage']
>;

export const ReportStageCell = ({ value }: ReportStageCellProps) => (
  <ReportStageTag reportStage={value} />
);

type ReportOwnerCellProps = CellProps<
  ReportsTableRow,
  ReportsTableRow['owner']
>;

export const ReportOwnerCell = ({ value }: ReportOwnerCellProps) => (
  <>{value?.displayName || value?.email}</>
);
