import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import OneThingCampaignForm from 'toolkit/OneThingCampaignForm';
import Spinner from 'shared/spinner/Spinner';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import Dialog from 'shared/components/Dialog';
import { campaignFormId } from 'toolkit/WalkOfStrategyCampaignForm/WalkOfStrategyCampaignForm';
import { resolveOrgUnitInputInitialValue } from 'orgUnit/SelectOrgUnitInput/SelectOrgUnitInput.utils';
import { useActiveOrg } from 'org/ActiveOrgProvider';

import { useGetOneThingCampaignQuery } from './GetOneThingCampaign.graphql.generated';

type Props = {
  campaignId: string;
  isOpen: boolean;
  onClose: () => void;
};

const OneThingEditCampaign = ({ campaignId, isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  const { activeOrg } = useActiveOrg();

  const handleApolloError = useHandleApolloError();

  const { data } = useGetOneThingCampaignQuery({
    variables: { id: campaignId },
    fetchPolicy: 'network-only',
    onError: handleApolloError,
    skip: !isOpen,
  });

  return (
    <Dialog
      headerTitle={t('toolkit.toolPage.oneThing.campaigns.edit.title')}
      isOpen={isOpen}
      onClose={onClose}
      confirmLabel={t('update')}
      cancelLabel={t('cancel')}
      formId={campaignFormId}
    >
      {data ? (
        <OneThingCampaignForm
          onSuccess={onClose}
          initialValues={{
            deadline: data.campaign.deadline,
            startDate: data.campaign.timeLine.startDate,
            endDate: data.campaign.timeLine.endDate,
            id: data.campaign.id,
            name: data.campaign.name,
            owner: data.campaign.owner.email,
            orgUnit: resolveOrgUnitInputInitialValue(
              activeOrg,
              data.campaign.orgUnit,
            ),
          }}
        />
      ) : (
        <div css={css({ minHeight: 200 })}>
          <Spinner.Circle />
        </div>
      )}
    </Dialog>
  );
};

export default OneThingEditCampaign;
