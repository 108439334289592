import { forwardRef } from 'react';

import type { AuditRecordUser } from 'types.graphql.generated';
import type { AvatarProps } from 'shared/components/Avatar';
import Avatar from 'shared/components/Avatar';

export type AuditRecordUserAvatarProps = Omit<AvatarProps, 'children'> & {
  auditRecordUser?: Pick<AuditRecordUser, 'displayName' | 'email' | 'photoUrl'>;
};

const AuditRecordUserAvatar = forwardRef<
  HTMLDivElement,
  AuditRecordUserAvatarProps
>(({ auditRecordUser, ...restProps }, ref) => (
  <Avatar
    ref={ref}
    photoUrl={auditRecordUser?.photoUrl}
    displayName={auditRecordUser?.displayName}
    email={auditRecordUser?.email}
    {...restProps}
  />
));

export default AuditRecordUserAvatar;
