import { css, useTheme } from '@emotion/react';

import Flex from 'shared/components/Flex';
import StatusMark from 'shared/status/StatusMark';
import UserAvatar from 'user/UserAvatar';
import TimelineRange from 'shared/components/TimelineRange';
import TeamImage from 'team/TeamImage';
import TeamLink from 'team/TeamLink';
import { ReactComponent as ChevronRightIcon } from 'shared/static/icons/icon-chevron-right.svg';
import OptionalLink from 'shared/components/OptionalLink';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import { useTeamSlug } from 'team/TeamAdapter/useTeamSlug';

import type { StrategyMetricsMetricFragment } from '../StrategyMetricsProvider/StrategyMetricsProvider.graphql.generated';
import type { StrategyMetricsObjective } from '../StrategyMetricsProvider/StrategyMetricsProvider.type';

type Props = {
  metric: StrategyMetricsMetricFragment;
  objective: StrategyMetricsObjective;
};

const MetricsListThemesRow = ({ metric, objective }: Props) => {
  const styleTheme = useTheme();
  const { teamSlug } = useTeamSlug();

  return (
    <Flex
      key={metric.id}
      alignItems={'center'}
      gap={14}
      css={css`
        height: 50px;
        border-top: 1px solid ${styleTheme.color.strokeLight};

        &:first-child {
          border-top: none;
        }
      `}
    >
      <StatusMark
        statusIndicator={metric.currentMetricStatus?.statusIndicator}
      />
      <div
        css={css`
          flex-grow: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `}
      >
        <OptionalLink
          isInline={true}
          isActive={canPerformStrategyElementAction(metric, 'READ')}
          to={objectiveRoutesPaths.metrics.metric.root({
            params: {
              metricId: metric.id,
              objectiveId: objective.id,
              teamSlug,
            },
          })}
        >
          {metric.name}
        </OptionalLink>
        <span
          css={css`
            margin-left: 14px;
            color: ${styleTheme.color.neutral2};
          `}
        >
          <ChevronRightIcon width={12} css={css({ marginTop: -2 })} />{' '}
          <OptionalLink
            isInline={true}
            isActive={canPerformStrategyElementAction(objective, 'READ')}
            color={styleTheme.color.neutral2}
            to={objectiveRoutesPaths.root({
              params: { objectiveId: objective.id, teamSlug },
            })}
          >
            {objective.name}
          </OptionalLink>
        </span>
      </div>
      <TimelineRange timeLine={metric.timeLine} />
      <TeamLink orgUnit={objective.orgUnit}>
        <TeamImage orgUnit={objective.orgUnit} size={'small'} />
      </TeamLink>
      <UserAvatar user={metric.owner} />
    </Flex>
  );
};

export default MetricsListThemesRow;
