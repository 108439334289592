import type {
  OrgAuthorizedAction,
  OrgUnitAuthorizedAction,
} from 'types.graphql.generated';
import { useTeamAdapter } from 'team/TeamAdapter';

import useCanPerformOrgAction from './useCanPerformOrgAction';
import useCanPerformOrgUnitAction from './useCanPerformOrgUnitAction';

export default function useCanPerformOrgOrOrgUnitAction(
  orgAction: OrgAuthorizedAction,
  orgUnitAction: OrgUnitAuthorizedAction,
) {
  const { teamAdapter } = useTeamAdapter();

  const canPerformOrgAction = useCanPerformOrgAction(orgAction);
  const canPerformOrgUnitAction = useCanPerformOrgUnitAction(orgUnitAction);

  return teamAdapter.isOrg ? canPerformOrgAction : canPerformOrgUnitAction;
}
