import { useTranslation } from 'react-i18next';

import TeamImage from 'team/TeamImage';
import type { OrgUnit } from 'types.graphql.generated';
import { useActiveOrg } from 'org/ActiveOrgProvider';

import styles from './TeamHeading.module.scss';

type TeamHeadingProps = {
  isPrimaryTeam?: boolean;
  orgUnit?: Pick<
    OrgUnit,
    'id' | 'name' | 'nameAbbreviated' | 'backgroundColorAsHex'
  >;
};

const TeamHeading = ({ orgUnit, isPrimaryTeam }: TeamHeadingProps) => {
  const { t } = useTranslation();
  const { activeOrg } = useActiveOrg();

  const name = orgUnit
    ? orgUnit.name || orgUnit.nameAbbreviated || orgUnit.id
    : activeOrg.displayName || activeOrg.domainNames[0] || activeOrg.id;

  return (
    <div className={styles.container}>
      <TeamImage orgUnit={orgUnit} size={'small'} />
      <h4 className={styles.heading}>{name}</h4>
      {isPrimaryTeam && (
        <span className={styles.primary}>{t('team.primary')}</span>
      )}
    </div>
  );
};

export default TeamHeading;
