import { createRoutePath } from 'shared/utils/router';

export const contributionRoutesPaths = {
  root: createRoutePath<{
    teamSlug: string;
  }>(`/teams/:teamSlug/my-contribution`),
  followUps: createRoutePath<{
    teamSlug: string;
  }>(`/teams/:teamSlug/my-contribution/follow-ups`),
  user: createRoutePath<{
    teamSlug: string;
    userId: string;
  }>(`/teams/:teamSlug/user-contribution/:userId`),
};
