import type { StatusIndicator } from 'shared/status';
import { isStatusIndicatorEnum } from 'shared/status';

import type { StatusMultiSelectItemId } from './StatusMultiSelect.type';

export const testStatusIndicatorAgainstId = (
  id: StatusMultiSelectItemId,
  statusIndicator?: Maybe<StatusIndicator>,
) => {
  if (id === 'ALL' && !statusIndicator?.isCompleted) {
    return true;
  }

  if (id === 'UNKNOWN') {
    return !statusIndicator?.value && !statusIndicator?.isCompleted;
  }

  if (id === 'COMPLETED') {
    return statusIndicator?.isCompleted;
  }

  return statusIndicator?.value === id && !statusIndicator?.isCompleted;
};

export const isStatusMultiSelectId = (
  value: unknown,
): value is StatusMultiSelectItemId => {
  if (typeof value === 'string') {
    return (
      isStatusIndicatorEnum(value) ||
      ['UNKNOWN', 'ALL', 'COMPLETED'].includes(value)
    );
  }
  return false;
};
