import { useCallback } from 'react';
import { trackPromise } from 'react-promise-tracker';

import type { OrgUnitFormProps } from 'orgUnit/OrgUnitForm';
import OrgUnitForm from 'orgUnit/OrgUnitForm';
import type { OrgUnit } from 'types.graphql.generated';
import useHandleApolloError from 'shared/errors/useHandleApolloError';

import { resolveCreateOrgUnitInput } from './CreateOrgUnitForm.utils';
import { useCreateOrgUnitMutation } from './CreateOrgUnitForm.graphql.generated';

export const createOrgUnitFormPromiseTrackerArea =
  'createOrgUnitFormPromiseTrackerArea';

export type CreateOrgUnitFormProps = Omit<
  OrgUnitFormProps,
  'initialValues' | 'onSubmit'
> & {
  onSuccess: (orgUnit: Pick<OrgUnit, 'id' | 'name'>) => void;
};

const CreateOrgUnitForm = ({
  orgKey,
  onSuccess,
  id,
  ...restProps
}: CreateOrgUnitFormProps) => {
  const handleApolloError = useHandleApolloError();

  const [createOrgUnit] = useCreateOrgUnitMutation({
    onError: handleApolloError,
  });

  const handleSubmit = useCallback<NonNullable<OrgUnitFormProps['onSubmit']>>(
    async (values) => {
      const result = await trackPromise(
        createOrgUnit({
          variables: {
            input: resolveCreateOrgUnitInput({
              values,
              orgKey,
            }),
          },
        }),
        createOrgUnitFormPromiseTrackerArea,
      );
      const createdOrgUnit = result?.data?.addOrgUnit;
      if (createdOrgUnit) {
        onSuccess(createdOrgUnit);
      }
    },
    [onSuccess, createOrgUnit, orgKey],
  );

  return (
    <OrgUnitForm
      {...restProps}
      orgKey={orgKey}
      onSubmit={handleSubmit}
      id={id}
    />
  );
};

export default CreateOrgUnitForm;
