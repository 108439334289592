import cn from 'classnames';

import styles from './BricksSpinner.module.scss';

type BricksSpinnerProps = {
  className?: string;
  hasBackground?: boolean;
  isCentered?: boolean;
  isGlobal?: boolean;
};

const BricksSpinner = ({
  isCentered = true,
  hasBackground = true,
  isGlobal = true,
  className,
}: BricksSpinnerProps) => (
  <div
    role={'status'}
    className={cn(
      styles.container,
      {
        [styles.containerCentered]: isCentered,
        [styles.containerBackground]: hasBackground,
        [styles.containerGlobal]: isGlobal,
      },
      className,
    )}
  >
    <div className={styles.bricks}>
      <div className={cn(styles.brick, styles.brick1)} />
      <div className={cn(styles.brick, styles.brick2)} />
      <div className={cn(styles.brick, styles.brick3)} />
    </div>
  </div>
);

export default BricksSpinner;
