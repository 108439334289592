import { useCallback } from 'react';
import cn from 'classnames';

import { ReactComponent as MoreIcon } from 'shared/static/icons/icon-more.svg';
import type { MenuTriggerComponentProps } from 'shared/components/Menu';

import styles from './ContextMenuTrigger.module.scss';

type ContextMenuTriggerProps = MenuTriggerComponentProps;

// todo replace button element with Button component implemented with react-aria
// pass triggerProps to it to provide better accessibility
const ContextMenuTrigger = ({
  triggerRef,
  triggerProps: _triggerProps,
  state,
  className,
  testId,
}: ContextMenuTriggerProps) => {
  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      state.toggle();
    },
    [state],
  );

  return (
    <button
      ref={triggerRef}
      className={cn(className, styles.contextMenuTrigger)}
      onClick={handleClick}
      data-context-menu-trigger={testId}
    >
      <MoreIcon className={styles.contextMenuTriggerIcon} />
    </button>
  );
};

export default ContextMenuTrigger;
