import type { TFunction } from 'react-i18next';
import type { Theme } from '@emotion/react';

import {
  getInitiativesStatsActive,
  getInitiativessCompletedCount,
} from 'initiative/InitiativesContent/InitiativesContentHeader/InitiativesContentHeader.utils';
import {
  getMetricsCompletedCount,
  getMetricsStatsActive,
} from 'metric/MetricsList/MetricsListHeader/MetricsListHeader.utils';
import {
  getObjectivesActive,
  getObjectivesStatsActive,
} from 'objective/objective.utils';
import { getMetricsActive } from 'metric/MetricsList/MetricsListProvider/MetricsListProvider.utils';
import { getInitiativesActive } from 'initiative/InitiativesContent/InitiativesContentProvider/InitiativesContentProvider.utils';

import type {
  StrategyBoardInitiative,
  StrategyBoardMetric,
  StrategyBoardObjective,
} from '../StrategyBoard.type';

export const getStats = (
  objectives: StrategyBoardObjective[],
  theme: Theme,
  t: TFunction,
) => {
  const strategyMetrics = getStrategyMetrics(objectives) ?? [];
  const strategyInitiatives = getStrategyInitiatives(objectives) ?? [];

  const strategyObjectivesActive = getObjectivesActive(objectives);
  const strategyMetricsFromObjectivesActive = getStrategyMetrics(
    strategyObjectivesActive,
  );
  const strategyInitiativesFromObjectivesActive = getStrategyInitiatives(
    strategyObjectivesActive,
  );

  const statDoneValue = getStatDone(
    objectives ?? [],
    strategyMetrics,
    strategyInitiatives,
  );

  const objectivesStatsActive = getObjectivesStatsActive(
    strategyObjectivesActive,
  );
  const metricsStatsActive = getMetricsStatsActive(
    strategyMetricsFromObjectivesActive,
  );
  const initiativesStatsActive = getInitiativesStatsActive(
    strategyInitiativesFromObjectivesActive,
  );

  const strategyObjectivesActiveCount = strategyObjectivesActive?.length ?? 0;
  const strategyMetricsActiveCount =
    getMetricsActive(strategyMetricsFromObjectivesActive)?.length ?? 0;
  const strategyInitiativesActiveCount =
    getInitiativesActive(strategyInitiativesFromObjectivesActive)?.length ?? 0;

  const statsActive = [
    {
      name: t('statusIndicator.UNKNOWN.name'),
      value:
        objectivesStatsActive.noStatus +
        metricsStatsActive.noStatus +
        initiativesStatsActive.noStatus,
      color: theme.color.neutral2,
    },
    {
      name: t('statusIndicator.BLOCKED.name'),
      value:
        objectivesStatsActive.blocked +
        metricsStatsActive.blocked +
        initiativesStatsActive.blocked,
      color: theme.color.error,
    },
    {
      name: t('statusIndicator.AT_RISK.name'),
      value:
        objectivesStatsActive.atRisk +
        metricsStatsActive.atRisk +
        initiativesStatsActive.atRisk,
      color: theme.color.alert,
    },
    {
      name: t('statusIndicator.ON_TRACK.name'),
      value:
        objectivesStatsActive.onTrack +
        metricsStatsActive.onTrack +
        initiativesStatsActive.onTrack,
      color: theme.color.success,
    },
  ];

  const statDone = {
    name: t('statusIndicator.COMPLETED.name'),
    value: statDoneValue,
    color: theme.color.primary,
  };

  const hasStats = !!objectives.length;

  return {
    strategyObjectivesActiveCount,
    strategyMetricsActiveCount,
    strategyInitiativesActiveCount,
    hasStats,
    statsActive,
    statDone,
  };
};

export const getStrategyMetrics = (
  objectives: Maybe<StrategyBoardObjective[]>,
) =>
  objectives?.reduce<StrategyBoardMetric[]>(
    (acc, cur) => [...acc, ...cur.metrics],
    [],
  );

export const getStrategyInitiatives = (
  objectives: Maybe<StrategyBoardObjective[]>,
) =>
  objectives?.reduce<StrategyBoardInitiative[]>(
    (acc, cur) => [...acc, ...cur.initiatives],
    [],
  );

export const getObjectivesCompletedCount = (
  objectives: StrategyBoardObjective[],
) => {
  if (objectives) {
    return objectives.reduce<number>(
      (acc, cur) => (cur.currentObjectiveStatus?.complete ? acc + 1 : acc),
      0,
    );
  } else {
    return 0;
  }
};

export const getStatDone = (
  objectives: StrategyBoardObjective[],
  metrics: StrategyBoardMetric[],
  initiatives: StrategyBoardInitiative[],
) => {
  const objectivesDoneCount = getObjectivesCompletedCount(objectives);
  const metricsDoneCount = metrics ? getMetricsCompletedCount(metrics) : 0;
  const initiativesDoneCount = initiatives
    ? getInitiativessCompletedCount(initiatives)
    : 0;
  const statCount =
    objectivesDoneCount + metricsDoneCount + initiativesDoneCount;
  const statLength =
    objectives.length + (metrics?.length ?? 0) + (initiatives?.length ?? 0);

  const statDone = (statCount / statLength) * 100;

  return Math.round(statDone);
};
