import type * as Types from '../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendStatusRequestMutationVariables = Types.Exact<{
  elements: Types.StrategyElementListInput;
}>;


export type SendStatusRequestMutation = { __typename: 'Mutation', saveStatusRequests: Array<{ __typename: 'StatusRequest', id: string }> };


export const SendStatusRequestDocument = gql`
    mutation SendStatusRequest($elements: StrategyElementListInput!) {
  saveStatusRequests(input: {elements: $elements}) {
    id
  }
}
    `;
export type SendStatusRequestMutationFn = Apollo.MutationFunction<SendStatusRequestMutation, SendStatusRequestMutationVariables>;

/**
 * __useSendStatusRequestMutation__
 *
 * To run a mutation, you first call `useSendStatusRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendStatusRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendStatusRequestMutation, { data, loading, error }] = useSendStatusRequestMutation({
 *   variables: {
 *      elements: // value for 'elements'
 *   },
 * });
 */
export function useSendStatusRequestMutation(baseOptions?: Apollo.MutationHookOptions<SendStatusRequestMutation, SendStatusRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendStatusRequestMutation, SendStatusRequestMutationVariables>(SendStatusRequestDocument, options);
      }
export type SendStatusRequestMutationHookResult = ReturnType<typeof useSendStatusRequestMutation>;
export type SendStatusRequestMutationResult = Apollo.MutationResult<SendStatusRequestMutation>;
export type SendStatusRequestMutationOptions = Apollo.BaseMutationOptions<SendStatusRequestMutation, SendStatusRequestMutationVariables>;