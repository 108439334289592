import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useToggle } from 'react-use';
import { capitalize } from 'lodash';

import { hasVision } from 'strategy/strategy.utils';
import Text from 'shared/components/Text';
import type { CardBorder } from 'shared/components/Card/Card.type';

import { illustrations, backgroundColors } from '../StrategyRoadmap.static';
import StrategyRoadmapCard from '../StrategyRoadmapCard';
import StrategyRoadmapVisionModal from '../StrategyRoadmapVisionModal';
import type { StrategyRoadmapStrategyFieldsFragment } from '../StrategyRoadmapProvider/StrategyRoadmapProvider.graphql.generated';

type StrategyRoadmapVisionCardProps = {
  border?: CardBorder;
  hasShadow?: boolean;
  showEditButton?: boolean;
  strategy: StrategyRoadmapStrategyFieldsFragment;
};

const StrategyRoadmapVisionCard = ({
  strategy,
  hasShadow,
  border,
  showEditButton = true,
}: StrategyRoadmapVisionCardProps) => {
  const { vision } = strategy;

  const { t } = useTranslation();

  const [isModalOpened, toggleIsModalOpened] = useToggle(false);

  const isEmpty = useMemo(() => !hasVision(strategy), [strategy]);
  const content = useMemo(() => {
    if (!isEmpty) {
      return (
        <Text variant={'emphasis'} ellipsis={{ lineClamp: 4 }}>
          {vision?.name}
        </Text>
      );
    }
    return t('noData');
  }, [isEmpty, vision, t]);

  return (
    <>
      <StrategyRoadmapCard
        illustration={illustrations.vision}
        illustrationBackgroundColor={backgroundColors.vision}
        heading={capitalize(t('strategy.vision'))}
        onMaximize={!isEmpty ? toggleIsModalOpened : undefined}
        hasShadow={hasShadow}
        border={border}
      >
        {content}
      </StrategyRoadmapCard>
      <StrategyRoadmapVisionModal
        strategy={strategy}
        isOpen={isModalOpened}
        onClose={toggleIsModalOpened}
        showEditButton={showEditButton}
      />
    </>
  );
};

export default StrategyRoadmapVisionCard;
