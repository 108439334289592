import { useMemo } from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import { determineIfIsOverdue } from 'actions/action.utils';
import ActionChipOverdue from 'actions/ActionChipOverdue';
import { ReactComponent as DateIcon } from 'shared/static/icons/icon-date.svg';
import { ReactComponent as CheckSquareIcon } from 'shared/static/icons/icon-check-square.svg';
import UserAvatar from 'user/UserAvatar';
import { date } from 'shared/services/date.service';
import CompletionRate from 'shared/components/CompletionRate';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import type { ActionWithObjective } from 'actions/actions.type';
import { useActionsLayout } from 'actions/ActionsLayout/ActionsLayoutProvider';
import { useTeamAdapter } from 'team/TeamAdapter';

import styles from './ActionRow.module.scss';

type ActionRowProps = {
  action: ActionWithObjective;
};

const ActionRow = ({ action }: ActionRowProps) => {
  const { teamAdapter } = useTeamAdapter();

  const { layout } = useActionsLayout();

  const navigate = useNavigate();

  const isActionOverdue = useMemo(() => determineIfIsOverdue(action), [action]);

  const handleNavigateToAction = () => {
    navigate(
      objectiveRoutesPaths.actions.action.root({
        params: {
          teamSlug: teamAdapter.toParam(),
          objectiveId: action.objective.id,
          actionId: action.id,
        },
        query: {
          view: layout,
        },
      }),
      {
        preventScrollReset: true,
      },
    );
  };

  return (
    <motion.li
      className={styles.row}
      onClick={handleNavigateToAction}
      layout={true}
    >
      <motion.div layout={true} className={styles.name}>
        <CheckSquareIcon className={styles.nameIcon} />
        <p className={styles.nameText}>{action.name}</p>
      </motion.div>
      {isActionOverdue && <ActionChipOverdue />}
      <motion.div
        layout={true}
        className={cn(styles.timeline, {
          [styles.timelineMargin]: !isActionOverdue,
        })}
      >
        <DateIcon className={styles.timelineIcon} />
        <motion.div
          layout={true}
          className={cn(styles.timelineText, {
            [styles.timelineTextOverdue]: isActionOverdue,
          })}
        >
          {date.format(action.timeLine.endDate, 'dd MMM yyyy')}
        </motion.div>
      </motion.div>
      <CompletionRate
        complete={action.currentStatus?.complete}
        completionRate={action.currentStatus?.completionRate}
      />
      <UserAvatar
        user={action.owner}
        containerClassName={styles.avatarContainer}
      />
    </motion.li>
  );
};

export default ActionRow;
