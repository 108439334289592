import cn from 'classnames';
import type { ComponentType, ReactNode, SVGProps } from 'react';
import { useMemo, useRef } from 'react';
import type { AriaButtonProps } from 'react-aria';
import { useButton } from 'react-aria';
import { css, useTheme } from '@emotion/react';

import styles from './TextButton.module.scss';
import type {
  TextButtonIconPlacement,
  TextButtonVariant,
} from './TextButton.type';

type TextButtonProps = Pick<AriaButtonProps, 'onPress' | 'children'> & {
  className?: string;
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
  iconPlacement?: TextButtonIconPlacement;
  variant?: TextButtonVariant;
};

/**
 * Button styled to resemble a text element, allows user to perform an action or navigate to another page.
 */
const TextButton = (props: TextButtonProps) => {
  const {
    variant = 'regular',
    icon: Icon,
    iconPlacement = 'start',
    className,
    ...buttonProps
  } = props;
  const theme = useTheme();
  const buttonRef = useRef(null);
  const { buttonProps: buttonElementProps } = useButton(buttonProps, buttonRef);

  const icon = useMemo<ReactNode>(() => {
    if (Icon) {
      return (
        <span className={styles.textButtonIconContainer}>
          <Icon className={styles.textButtonIcon} />
        </span>
      );
    }
    return null;
  }, [Icon]);

  return (
    <button
      {...buttonElementProps}
      className={cn(
        styles.textButton,
        {
          [styles.textButtonVariantStrong]: variant === 'strong',
          [styles.textButtonIconPlacementStart]: iconPlacement === 'start',
          [styles.textButtonIconPlacementEnd]: iconPlacement === 'end',
        },
        className,
      )}
      css={css`
        border-radius: 6px;

        &:focus-visible {
          box-shadow: 0 0 0 2px ${theme.color.primary};
        }
      `}
    >
      {iconPlacement === 'start' && icon}
      {buttonProps.children}
      {iconPlacement === 'end' && icon}
    </button>
  );
};

export default TextButton;
