import { useTranslation } from 'react-i18next';

import styles from './ActionChipOverdue.module.scss';

const ActionChipOverdue = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>{t('actions.stats.name.overdue')}</div>
  );
};

export default ActionChipOverdue;
