import type { PropsWithChildren } from 'react';

import type {
  OrgAuthorizedAction,
  OrgUnitAuthorizedAction,
} from '../../types.graphql.generated';
import useCanPerformOrgOrOrgUnitAction from './useCanPerformOrgOrOrgUnitAction';

type Props = PropsWithChildren<{
  orgAction: OrgAuthorizedAction;
  orgUnitAction: OrgUnitAuthorizedAction;
}>;

const IfCanPerformOrgOrOrgUnitAction = ({
  orgAction,
  orgUnitAction,
  children,
}: Props) => {
  const canPerform = useCanPerformOrgOrOrgUnitAction(orgAction, orgUnitAction);

  return canPerform ? <>{children}</> : null;
};

export default IfCanPerformOrgOrOrgUnitAction;
