import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import type { ContextMenuOnActionFn } from 'shared/components/ContextMenu';
import ContextMenu from 'shared/components/ContextMenu';
import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';
import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';
import { ReactComponent as PublishIcon } from 'shared/static/icons/icon-publish.svg';
import { ReactComponent as EyeOpenIcon } from 'shared/static/icons/icon-eye-open.svg';
import { ReactComponent as StopIcon } from 'shared/static/icons/icon-stop.svg';
import { ReactComponent as PauseIcon } from 'shared/static/icons/icon-pause.svg';
import { ReactComponent as RefreshIcon } from 'shared/static/icons/icon-refresh.svg';
import type { ContextMenuItemVariant } from 'shared/components/ContextMenu/ContextMenu.type';
import useCanPerformOrgOrOrgUnitAction from 'user/ability/useCanPerformOrgOrOrgUnitAction';

import type { StrategyRoomStrategy } from '../StrategyRoom.type';
import type {
  StrategyRoomTableViewContextMenuItem,
  StrategyRoomTableViewContextMenuItemId,
} from './StrategyRoomTableViewContextMenu.type';

export type StrategyRoomTableViewContextMenuProps = {
  onAction: ContextMenuOnActionFn<StrategyRoomTableViewContextMenuItem['id']>;
  strategy: StrategyRoomStrategy;
};

const StrategyRoomTableViewContextMenu = ({
  onAction,
  strategy,
}: StrategyRoomTableViewContextMenuProps) => {
  const { t } = useTranslation();

  const canWrite = useCanPerformOrgOrOrgUnitAction('WRITE_STRATEGY', 'WRITE');
  const canLaunch = canWrite && !strategy.isLive && !strategy.isComplete;
  const canUnlaunch = canWrite && strategy.isLive;
  const canEdit = canWrite && !strategy.isComplete;
  const canPreview = canWrite;
  const canArchive = canWrite && !strategy.isLive && !strategy.isComplete;
  const canUnarchive = canWrite && strategy.isComplete;
  const canDelete = canWrite && !strategy.isLive;

  const items = useMemo(
    (): StrategyRoomTableViewContextMenuItem[] =>
      [
        canLaunch && {
          id: 'launch' as StrategyRoomTableViewContextMenuItemId,
          icon: PublishIcon,
          title: t('strategy.launch'),
        },
        canUnlaunch && {
          id: 'unlaunch' as StrategyRoomTableViewContextMenuItemId,
          icon: StopIcon,
          title: t('strategy.unlaunch'),
          variant: 'danger' as ContextMenuItemVariant,
        },
        canEdit && {
          id: 'edit' as StrategyRoomTableViewContextMenuItemId,
          icon: EditIcon,
          title: t('edit'),
        },
        canPreview && {
          id: 'preview' as StrategyRoomTableViewContextMenuItemId,
          icon: EyeOpenIcon,
          title: t('preview'),
        },
        canArchive && {
          id: 'archive' as StrategyRoomTableViewContextMenuItemId,
          icon: PauseIcon,
          title: t('strategy.archive'),
        },
        canUnarchive && {
          id: 'unarchive' as StrategyRoomTableViewContextMenuItemId,
          icon: RefreshIcon,
          title: t('strategy.unarchive'),
        },
        canDelete && {
          id: 'delete' as StrategyRoomTableViewContextMenuItemId,
          icon: TrashIcon,
          title: t('remove'),
          variant: 'danger' as ContextMenuItemVariant,
        },
      ].filter(Boolean),
    [
      canLaunch,
      t,
      canUnlaunch,
      canEdit,
      canPreview,
      canArchive,
      canUnarchive,
      canDelete,
    ],
  );

  return (
    <ContextMenu<StrategyRoomTableViewContextMenuItem>
      items={items}
      onAction={onAction}
      testId={strategy.name}
    />
  );
};

export default StrategyRoomTableViewContextMenu;
