import { useCallback, useEffect, useMemo } from 'react';
import { useSet } from 'react-use';
import { Navigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Space from 'shared/components/Space';
import Heading from 'shared/components/Heading';
import Text from 'shared/components/Text';
import { toolkitRoutesPaths } from 'toolkit/toolkit.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';
import { categories } from 'toolkit/toolkit.static';
import type { ToolGroup, ToolCategoryId } from 'toolkit/toolkit.static';

import type { ToolkitOverviewCategoryControlsProps } from '../ToolkitOverviewCategoryControls';
import Controls from '../ToolkitOverviewCategoryControls';
import ToolsGrid from '../ToolkitOverviewToolsGrid';
import styles from './ToolkitOverviewCategory.module.scss';

const ToolkitOverviewCategory = () => {
  const { t } = useTranslation();
  const { category: categoryId } = useParams() as {
    category: ToolCategoryId;
  };

  const { teamAdapter } = useTeamAdapter();

  const category = categories.find((category) => category.id === categoryId);

  const [
    selectedGroupsIds,
    {
      toggle: toggleSelectedGroup,
      reset: resetSelectedGroups,
      has: isGroupSelected,
    },
  ] = useSet<ToolGroup>();
  const noGroupsSelected = !selectedGroupsIds.size;

  const name = t(`toolkit.categories.${categoryId}.name`);
  const description = t(`toolkit.categories.${categoryId}.description`);

  const heading = useMemo(() => {
    if (category) {
      return (
        <Space isCentered={true} direction={'vertical'} size={'small'}>
          <Heading level={2} as={3} hasMargin={false}>
            {name}
          </Heading>
          <Text className={styles.description}>{description}</Text>
        </Space>
      );
    }
    return null;
  }, [category, description, name]);

  const handleSelectGroup = useCallback<
    ToolkitOverviewCategoryControlsProps['selectGroup']
  >(
    (groupId) => {
      if (groupId) {
        resetSelectedGroups();
        toggleSelectedGroup(groupId);
      } else {
        resetSelectedGroups();
      }
    },
    [resetSelectedGroups, toggleSelectedGroup],
  );

  useEffect(
    () => () => {
      resetSelectedGroups();
    },
    [resetSelectedGroups],
  );

  if (category) {
    return (
      <div className={styles.container}>
        {heading}
        <Controls
          noGroupsSelected={noGroupsSelected}
          groups={category.groups}
          isGroupSelected={isGroupSelected}
          selectGroup={handleSelectGroup}
        />
        <ToolsGrid
          selectedGroupsIds={selectedGroupsIds}
          categoryId={category.id}
        />
      </div>
    );
  }

  return (
    <Navigate
      to={toolkitRoutesPaths.root({
        params: {
          teamSlug: teamAdapter.toParam(),
        },
      })}
      replace={true}
    />
  );
};

export default ToolkitOverviewCategory;
