import type {
  MutationUpdaterFunction,
  ApolloCache,
  Reference,
} from '@apollo/client';

import type {
  RemoveInitiativeMilestoneMutation,
  RemoveInitiativeMilestoneMutationVariables,
} from './useRemoveInitiativeMilestone.graphql.generated';

export const evictInitiativeMilestone: MutationUpdaterFunction<
  RemoveInitiativeMilestoneMutation,
  RemoveInitiativeMilestoneMutationVariables,
  unknown,
  ApolloCache<unknown>
> = (cache, { data }, { variables }) => {
  if (data && variables) {
    cache.modify({
      id: cache.identify(data.updateInitiative),
      fields: {
        milestones: (existingMilestones: Reference[], { readField }) =>
          existingMilestones.filter(
            (existingMilestone) =>
              readField('id', existingMilestone) !==
              variables.milestoneIdToRemove,
          ),
      },
    });
  }
};
