import type { ListState } from 'react-stately';
import { createContext } from 'react';

import type { MultiSelectProps } from 'shared/components/MultiSelect';

import type { PriorityMultiSelectItem } from '../PriorityMultiSelect.type';

export type PriorityMultiSelectProviderContextValue = {
  hasPriorities: boolean;
  items: PriorityMultiSelectItem[];
  state: ListState<PriorityMultiSelectItem>;
} & Pick<MultiSelectProps, 'onSelectionChange' | 'selectedKeys'>;

export const PriorityMultiSelectProviderContext =
  createContext<Maybe<PriorityMultiSelectProviderContextValue>>(undefined);
