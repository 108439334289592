import { useMemo } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { RoadmapProps } from 'shared/components/Roadmap';
import Roadmap from 'shared/components/Roadmap';
import type { Strategy } from 'types.graphql.generated';
import Result from 'shared/components/Result';
import { ReactComponent as LookingAtCardBoardBoxIllustration } from 'shared/static/illustrations/illustration-looking-at-cardboard-box.svg';
import Space from 'shared/components/Space';
import type { TeamAdapter } from 'team/TeamAdapter';

import { StrategyRoadmapConfig } from './StrategyRoadmapConfig';
import StrategyRoadmapProvider, {
  useStrategyRoadmap,
} from './StrategyRoadmapProvider';
import EditStrategy from './StrategyRoadmapEditStrategy';
import { strategyRoadmapRoutesPaths } from './StrategyRoadmap.routing.paths';
import StrategyRoadmapGuide from './StrategyRoadmapGuide/StrategyRoadmapGuide';

export type StrategyRoadmapProps = {
  strategyId: Strategy['id'];
} & InnerStrategyRoadmapProps;

type InnerStrategyRoadmapProps = {
  showGuide: boolean;
  teamAdapter: TeamAdapter;
};

const StrategyRoadmap = ({ showGuide }: InnerStrategyRoadmapProps) => {
  const { strategy, objectivesByTheme, oneThingCampaigns } =
    useStrategyRoadmap();

  const { t } = useTranslation();

  const config = useMemo(
    () => new StrategyRoadmapConfig(strategy, objectivesByTheme),
    [strategy, objectivesByTheme],
  );

  const items = useMemo<RoadmapProps['items']>(() => {
    const config = new StrategyRoadmapConfig(strategy, objectivesByTheme);
    return config.items.map((item) => {
      const Component = item.component;
      return {
        column: item.column,
        children: (
          <Component
            strategy={strategy}
            objectivesByTheme={objectivesByTheme}
            hasShadow={true}
            border={false}
          />
        ),
      };
    });
  }, [strategy, objectivesByTheme]);

  if (!config.isEmpty) {
    return (
      <>
        {showGuide && (
          <StrategyRoadmapGuide
            strategy={strategy}
            oneThingCampaigns={oneThingCampaigns}
          />
        )}
        <Roadmap items={items} />
      </>
    );
  }

  return (
    <Space isCentered={true}>
      <Result
        description={t('strategy.strategyRoadmap.emptyMessage')}
        illustration={LookingAtCardBoardBoxIllustration}
        hasBackground={true}
      />
    </Space>
  );
};

const StrategyRoadmapWithProviderAndRouting = ({
  strategyId,
  showGuide,
  teamAdapter,
}: StrategyRoadmapProps) => (
  <StrategyRoadmapProvider teamAdapter={teamAdapter} strategyId={strategyId}>
    <Routes>
      <Route
        path={strategyRoadmapRoutesPaths.root()}
        element={
          <>
            <StrategyRoadmap teamAdapter={teamAdapter} showGuide={showGuide} />
            <Outlet />
          </>
        }
      >
        <Route
          path={strategyRoadmapRoutesPaths.edit()}
          element={<EditStrategy />}
        />
      </Route>
    </Routes>
  </StrategyRoadmapProvider>
);

export default StrategyRoadmapWithProviderAndRouting;
