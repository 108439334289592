import { useDefinedContext } from 'shared/utils/context.utils';

import { ThemeContext } from './ThemeProvider.context';
import ThemeProvider from './ThemeProvider';

export const useTheme = () =>
  useDefinedContext(ThemeContext, {
    hookName: useTheme.name,
    providerName: ThemeProvider.name,
  });
