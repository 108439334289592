import { useTranslation } from 'react-i18next';

import RequestStatusUpdateForm from 'report/RequestStatusUpdateForm';
import Heading from 'shared/components/Heading';
import Text from 'shared/components/Text';
import type { Report } from 'types.graphql.generated';

import styles from './InsightReportWizardStatusUpdate.module.scss';

type InsightReportWizardStatusUpdateProps = {
  objectivesCount: number;
  report?: Pick<Report, 'id' | 'reportStage'>;
};

const InsightReportWizardStatusUpdate = ({
  report,
  objectivesCount,
}: InsightReportWizardStatusUpdateProps) => {
  const { t } = useTranslation();
  if (report && objectivesCount > 0) {
    return (
      <section className={styles.container}>
        <Heading level={3} className={styles.heading}>
          {t('report.insightReportWizard.statusUpdate.heading')}
        </Heading>
        <Text>{t('report.insightReportWizard.statusUpdate.description')}</Text>
        <RequestStatusUpdateForm report={report} />
      </section>
    );
  } else {
    return (
      <Text variant={'emphasis'}>
        {t('report.insightReportWizard.statusUpdate.noObjectives')}
      </Text>
    );
  }
};

export default InsightReportWizardStatusUpdate;
