import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const ScrollToError = () => {
  const { isSubmitting, isValidating, errors } = useFormikContext();

  useEffect(() => {
    const errorKeys = Object.keys(errors);

    if (errorKeys.length > 0 && isSubmitting && !isValidating) {
      const errorElement = document.querySelector<HTMLElement>(
        '[aria-invalid=true]',
      );

      errorElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });

      errorElement?.focus({ preventScroll: true });
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};

export default ScrollToError;
