import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateInitiativeMutationVariables = Types.Exact<{
  input: Types.InitiativeInput;
}>;


export type UpdateInitiativeMutation = { __typename: 'Mutation', updateInitiative: { __typename: 'Initiative', id: string, name?: string | undefined, description?: string | undefined, isCurrentUserOwner: boolean, owner?: { __typename: 'UserLimited', id: string } | undefined, timeLine: { __typename: 'TimeLine', endDate?: any | undefined, startDate?: any | undefined }, objective?: { __typename: 'Objective', id: string } | undefined, priority?: { __typename: 'Priority', number: number, label: string } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, milestones: Array<{ __typename: 'Milestone', id: string, name?: string | undefined, comment?: string | undefined, timeLine?: { __typename: 'TimeLine', endDate?: any | undefined } | undefined }>, attributes: Array<{ __typename: 'StrategyItemAttribute', id: string, name?: string | undefined, title?: string | undefined, description?: string | undefined, orderNumber?: number | undefined }> } };


export const UpdateInitiativeDocument = gql`
    mutation UpdateInitiative($input: InitiativeInput!) {
  updateInitiative(input: $input) {
    id
    name
    description
    isCurrentUserOwner
    owner {
      id
    }
    timeLine {
      endDate
      startDate
    }
    objective {
      id
    }
    priority {
      number
      label
    }
    orgUnit {
      id
    }
    milestones {
      id
      name
      comment
      timeLine {
        endDate
      }
    }
    attributes {
      id
      name
      title
      description
      orderNumber
    }
  }
}
    `;
export type UpdateInitiativeMutationFn = Apollo.MutationFunction<UpdateInitiativeMutation, UpdateInitiativeMutationVariables>;

/**
 * __useUpdateInitiativeMutation__
 *
 * To run a mutation, you first call `useUpdateInitiativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInitiativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInitiativeMutation, { data, loading, error }] = useUpdateInitiativeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInitiativeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInitiativeMutation, UpdateInitiativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInitiativeMutation, UpdateInitiativeMutationVariables>(UpdateInitiativeDocument, options);
      }
export type UpdateInitiativeMutationHookResult = ReturnType<typeof useUpdateInitiativeMutation>;
export type UpdateInitiativeMutationResult = Apollo.MutationResult<UpdateInitiativeMutation>;
export type UpdateInitiativeMutationOptions = Apollo.BaseMutationOptions<UpdateInitiativeMutation, UpdateInitiativeMutationVariables>;