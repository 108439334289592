import { useDefinedContext } from 'shared/utils/context.utils';

import { MetricsListObjectivesContext } from './MetricsListProvider.context';
import MetricsListProvider from './MetricsListProvider';

export const useMetricsListObjectives = () =>
  useDefinedContext(MetricsListObjectivesContext, {
    hookName: useMetricsListObjectives.name,
    providerName: MetricsListProvider.name,
  });
