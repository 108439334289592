import type { ControlProps } from 'react-select';
import { components } from 'react-select';
import { css, useTheme } from '@emotion/react';

type SelectControlProps = ControlProps;

const SelectControl = ({
  isFocused,
  className,
  ...restProps
}: SelectControlProps) => {
  const theme = useTheme();

  return (
    <components.Control
      {...restProps}
      isFocused={isFocused}
      className={className}
      css={css`
        border: 1px solid ${theme.color.strokeDark};
        border-radius: 0.75rem !important;
        height: 3rem;

        ${isFocused &&
        css`
          border-color: ${theme.color.black} !important;
          box-shadow: none !important;
          outline: none !important;
        `}
      `}
    />
  );
};

export default SelectControl;
