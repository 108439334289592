export const getNameAbbreviation = (name?: string) => {
  if (name) {
    return name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase())
      .slice(0, 3)
      .join('');
  }
  return '';
};
