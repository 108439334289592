import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { uniqBy } from 'lodash';

import { useTeamAdapter } from 'team/TeamAdapter';
import { useStatusFilter } from 'shared/status/useStatusFilter';
import { useUserFilter } from 'user/useUserFilter';
import { useDateFilter } from 'shared/hooks/useDateFilter';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';
import { usePriorityFilter } from 'shared/priority/usePriorityFilter';
import Spinner from 'shared/spinner/Spinner';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { useCalendarControls } from 'shared/components/CalendarControls/useCalendarControls';
import { useCompletedFilter } from 'shared/components/CompletedFilterButton/useCompletedFilter';
import type { StatusMultiSelectItemId } from 'shared/status/StatusMultiSelect';
import { useShowChildrenFilter } from 'shared/components/ShowChildrenFilterButton/useShowChildrenFilter';
import {
  groupObjectivesByTheme,
  sortObjectivesByTheme,
} from 'objective/objective.utils';

import type { StrategyObjectivesInitiativesContextValue } from './StrategyInitiativesProvider.context';
import { StrategyObjectivesInitiativesContext } from './StrategyInitiativesProvider.context';
import type { StrategyInitiativesThemeFragment } from './StrategyInitiativesProvider.graphql.generated';
import {
  useStrategyInitiativesOrgQuery,
  useStrategyInitiativesOrgUnitQuery,
} from './StrategyInitiativesProvider.graphql.generated';
import {
  filterObjectivesByStatusIndicator,
  filterObjectivesByDates,
  filterObjectivesByUser,
  getObjectivesOwners,
  filterObjectiveInitiativesByPriority,
} from './StrategyInitiativesProvider.utils';
import type { StrategyInitiativesObjective } from './StrategyInitiativesProvider.type';

type StrategyInitiativesProviderProps = PropsWithChildren<object>;

const StrategyInitiativesProvider = ({
  children,
}: StrategyInitiativesProviderProps) => {
  const [, setSearchParams] = useSearchParams();

  const { teamAdapter } = useTeamAdapter();

  const { priorities, isPrioritiesLoading } = useStrategyProfilePriorities();

  const handleApolloError = useHandleApolloError();

  const { data: dataOrg, loading: isDataLoadingOrg } =
    useStrategyInitiativesOrgQuery({
      fetchPolicy: 'cache-and-network',
      skip: !teamAdapter.isOrg,
      onError: handleApolloError,
    });

  const { data: dataOrgUnit, loading: isDataLoadingOrgUnit } =
    useStrategyInitiativesOrgUnitQuery({
      variables: {
        orgUnitId: teamAdapter.keyArg,
      },
      fetchPolicy: 'cache-and-network',
      skip: teamAdapter.isOrg,
      onError: handleApolloError,
    });

  const { statusFilter, setStatusFilter } = useStatusFilter();

  const { userFilter, setUserFilter } = useUserFilter();

  const { dateFilter: startDateFilter, setDateFilter: setStartDateFilter } =
    useDateFilter({ paramName: 'startDate' });

  const { dateFilter: endDateFilter, setDateFilter: setEndDateFilter } =
    useDateFilter({ paramName: 'endDate' });

  const { priorityFilter, setPriorityFilter } = usePriorityFilter({
    priorities,
  });

  const { calendarIntervalStartDate, calendarIntervalEndDate, calendarCheck } =
    useCalendarControls();

  const { showCompleted } = useCompletedFilter();
  const { showChildren } = useShowChildrenFilter();

  const clearFilters = useCallback(() => {
    setSearchParams((searchParams) => {
      searchParams.delete('status');
      searchParams.delete('user');
      searchParams.delete('startDate');
      searchParams.delete('endDate');
      searchParams.delete('priority');
      return searchParams;
    });
  }, [setSearchParams]);

  const contextValue =
    useMemo<StrategyObjectivesInitiativesContextValue>(() => {
      const dataObjectives =
        (teamAdapter.isOrg
          ? dataOrg?.activeOrg
          : dataOrgUnit?.orgUnit
        )?.objectives.edges.map((n) => n.node) ?? [];

      const objectives = showChildren
        ? uniqBy(
            [
              ...dataObjectives,
              ...dataObjectives.flatMap(
                (objective) => objective.childObjectives,
              ),
            ],
            (objective) => objective.id,
          )
        : dataObjectives;

      const filteredObjectives = filterObjectivesByDates(
        filterObjectivesByDates(
          filterObjectivesByUser(
            filterObjectivesByStatusIndicator(
              filterObjectiveInitiativesByPriority(objectives, priorityFilter),
              [
                ...statusFilter,
                showCompleted && ('COMPLETED' as StatusMultiSelectItemId),
              ].filter(Boolean),
            ),
            userFilter,
          ),
          startDateFilter,
          endDateFilter,
        ),
        calendarIntervalStartDate,
        calendarIntervalEndDate,
        calendarCheck,
      ).filter((objectives) => objectives.initiatives.length > 0);

      const users = getObjectivesOwners(objectives);

      const objectivesByTheme = groupObjectivesByTheme<
        StrategyInitiativesObjective,
        StrategyInitiativesThemeFragment
      >(filteredObjectives);

      const sortedObjectivesByTheme = sortObjectivesByTheme(
        objectivesByTheme,
        [
          ...(dataOrg?.activeOrg.orgUnits ||
            dataOrgUnit?.activeOrg.orgUnits ||
            []),
        ].reverse(),
      );

      return {
        objectives: filteredObjectives,
        objectivesByTheme: sortedObjectivesByTheme,

        isStrategyInitiativesLoading: teamAdapter.isOrg
          ? isDataLoadingOrg && !dataOrg
          : isDataLoadingOrgUnit && !dataOrgUnit,

        filters: {
          priorityFilter,
          setPriorityFilter,
          clearFilters,
          userFilter,
          startDateFilter,
          setStartDateFilter,
          setEndDateFilter,
          endDateFilter,
          setUserFilter,
          setStatusIndicatorFilter: setStatusFilter,
          statusIndicatorFilter: statusFilter,
        },
        users,
      };
    }, [
      calendarCheck,
      calendarIntervalEndDate,
      calendarIntervalStartDate,
      clearFilters,
      dataOrg,
      dataOrgUnit,
      endDateFilter,
      isDataLoadingOrg,
      isDataLoadingOrgUnit,
      priorityFilter,
      setEndDateFilter,
      setPriorityFilter,
      setStartDateFilter,
      setStatusFilter,
      setUserFilter,
      showChildren,
      showCompleted,
      startDateFilter,
      statusFilter,
      teamAdapter.isOrg,
      userFilter,
    ]);

  const isLoading =
    (teamAdapter.isOrg
      ? !dataOrg && isDataLoadingOrg
      : !dataOrgUnit && isDataLoadingOrgUnit) || isPrioritiesLoading;

  if (isLoading) return <Spinner.Circle />;

  return (
    <StrategyObjectivesInitiativesContext.Provider value={contextValue}>
      {children}
    </StrategyObjectivesInitiativesContext.Provider>
  );
};

export default StrategyInitiativesProvider;
