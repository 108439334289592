import type { PropsWithChildren } from 'react';

import Form from 'shared/form/Form';
import type { OrgInput } from 'types.graphql.generated';
import { yup } from 'shared/services/yup.service';
import { useActiveOrg } from 'org/ActiveOrgProvider';

export type OrgFormValues = {
  acronym: string;
  color: string;
  industryDescription: string;
  industryName: string;
  lead: string;
  name: string;
};

export const orgFormId = 'org';

type Props = PropsWithChildren<{
  initialValues: OrgFormValues;
  onSubmit: (input: OrgInput) => Promise<unknown>;
}>;

const OrgForm = ({ initialValues, onSubmit, children }: Props) => {
  const { activeOrg } = useActiveOrg();

  const validationSchema = yup.object({
    name: yup.string().required(),
    acronym: yup.string().required(),
    lead: yup.string().email().required(),
  });

  const handleSubmit = (values: OrgFormValues) => {
    onSubmit({
      orgKeyToUpdate: activeOrg.orgKey,
      displayName: values.name,
      displayNameAbbreviated: values.acronym,
      backgroundColorAsHex: values.color,
      orgLead: {
        emailToSet: values.lead,
      },
      industry: {
        name: values.industryName,
        description: values.industryDescription,
      },
    });
  };

  return (
    <Form<OrgFormValues>
      id={orgFormId}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

export default OrgForm;
