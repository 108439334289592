import type { CellProps } from 'react-table';
import { useTranslation } from 'react-i18next';

import { date } from 'shared/services/date.service';
import Text from 'shared/components/Text';
import StrategyStatusTag from 'strategy/StrategyStatusTag';

import type { StrategyRoomStrategy } from '../StrategyRoom.type';

export const NameCell = ({
  value,
}: CellProps<StrategyRoomStrategy, StrategyRoomStrategy['name']>) => {
  const { t } = useTranslation();

  return (
    <Text variant={'emphasis'}>{value || t('strategy.unnamedStrategy')}</Text>
  );
};

export const CreatedCell = ({
  value,
}: CellProps<
  StrategyRoomStrategy,
  StrategyRoomStrategy['auditRecord']['createDateTime']
>) => {
  if (value) {
    return <>{date.formatShort(value)}</>;
  }
  return null;
};

export const UpdatedCell = ({
  value,
}: CellProps<
  StrategyRoomStrategy,
  StrategyRoomStrategy['auditRecord']['createDateTime']
>) => {
  if (value) {
    return <>{date.formatShort(value)}</>;
  }
  return null;
};

export const StatusCell = ({ row }: CellProps<StrategyRoomStrategy>) => (
  <StrategyStatusTag strategy={row.original} />
);
