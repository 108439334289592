import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import type { Selection } from 'react-stately';
import { useListState } from 'react-stately';

import { useListBoxCollectionChildren } from 'shared/components/ListBox';
import MultiSelect from 'shared/components/MultiSelect';
import Text from 'shared/components/Text';
import { withField } from 'shared/form/form.utils';
import type { Authority } from 'types.graphql.generated';

const Container = styled.span`
  button {
    width: 100%;
  }
`;

type Item = {
  id: Authority;
};

const selectableRoles: Authority[] = [
  'ROLE_ORG_ACCOUNT_ADMIN',
  'ROLE_ORG_STRATEGY_ADMIN',
  'ROLE_ORG_GUEST',
];

const items: Item[] = selectableRoles.map((role) => ({ id: role }));

type Props = {
  onChange: (value: Authority[]) => void;
  value: Authority[];
};

const RoleMultiSelect = ({ value, onChange }: Props) => {
  const { t } = useTranslation();

  const collectionChildren = useListBoxCollectionChildren({
    Row: ({ item }) => <Text variant={'emphasis'}>{t(`role.${item.id}`)}</Text>,
  });

  const unselectableRoles = value.filter(
    (role) => !selectableRoles.includes(role),
  );
  const selectedKeys = value.filter((role) => selectableRoles.includes(role));

  const state = useListState<Item>({
    items,
    selectionMode: 'multiple',
    selectedKeys,
    children: collectionChildren,
    onSelectionChange: (keys: Selection) =>
      onChange([...unselectableRoles, ...(Array.from(keys) as Authority[])]),
  });

  const label =
    selectedKeys.length === 0
      ? t('role.member')
      : selectedKeys.length === 1
      ? t(`role.${selectedKeys[0]}`)
      : selectedKeys.length === items.length
      ? t('role.allRoles')
      : t('role.count', { count: selectedKeys.length });

  return (
    <Container>
      <MultiSelect<Item> state={state} items={items} label={label} />
    </Container>
  );
};

export default withField(RoleMultiSelect);
