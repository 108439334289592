import { useMemo } from 'react';

import MetricsListHeader from 'metric/MetricsList/MetricsListHeader';
import MetricsList from 'metric/MetricsList';
import MetricsListProvider from 'metric/MetricsList/MetricsListProvider';
import Spinner from 'shared/spinner/Spinner';
import Layout from 'shared/components/Layout';

import ObjectiveMetricsProvider from './ObjectiveMetricsProvider';
import { useObjectiveMetrics } from './ObjectiveMetricsProvider/useObjectiveMetrics';
import styles from './ObjectiveMetrics.module.scss';

const ObjectiveMetrics = () => {
  const { objective } = useObjectiveMetrics();

  const objectives = useMemo(() => [objective].filter(Boolean), [objective]);

  return (
    <Layout.Content
      hasPadding={false}
      className={styles.layout}
      variant={'contained'}
    >
      <MetricsListProvider objectives={objectives}>
        {objective ? (
          <>
            <MetricsListHeader objective={objective} />
            <MetricsList hasHeader={false} />
          </>
        ) : (
          <Spinner.Circle />
        )}
      </MetricsListProvider>
    </Layout.Content>
  );
};

export default () => (
  <ObjectiveMetricsProvider>
    <ObjectiveMetrics />
  </ObjectiveMetricsProvider>
);
