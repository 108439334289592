import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useObjective } from 'objective/ObjectiveProvider';
import { resolveInitialValue as resolveSelectObjectiveInputInitialValue } from 'objective/SelectObjectiveInput';
import type { ActionInput } from 'types.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import { resolvePriorityInitialValue } from 'strategy/PrioritySelect/resolvePriorityInitialValue';
import type { RedirectToMyContributionLocation } from 'contribution';
import Spinner from 'shared/spinner/Spinner';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import Dialog from 'shared/components/Dialog';
import ActionQuickFormFields from 'actions/CreateActionButton/CreateAction/ActionQuickFormFields';
import { useAction } from 'actions/ActionProvider';
import { ObjectiveActionsDocument } from 'actions/ObjectiveActions/ObjectiveActionsProvider/ObjectiveActionsProvider.graphql.generated';
import {
  StrategyActionsOrgDocument,
  StrategyActionsOrgUnitDocument,
} from 'actions/StrategyActions/StrategyActionsProvider.graphql.generated';
import { useActionsLayout } from 'actions/ActionsLayout/ActionsLayoutProvider';
import { useTeamSlug } from 'team/TeamAdapter/useTeamSlug';

import { handleOnClose } from './handleOnClose';
import { useUpdateActionMutation } from './UpdateAction.graphql.generated';
import type { ActionFormValues } from './ActionForm';
import ActionForm from './ActionForm';

const EditAction = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state, pathname } = useLocation() as RedirectToMyContributionLocation;
  const formId = useRef<string>(`action-form-${uuid()}`);

  const { teamSlug, orgTeamSlug } = useTeamSlug();
  const { addToast } = useToasts();
  const { objective } = useObjective();
  const { action } = useAction();
  const { layout } = useActionsLayout();

  const handleApolloError = useHandleApolloError();

  const [updateAction] = useUpdateActionMutation({
    onError: handleApolloError,
    refetchQueries: [
      ObjectiveActionsDocument,
      StrategyActionsOrgDocument,
      StrategyActionsOrgUnitDocument,
    ],
  });

  const initialValues: ActionFormValues | false = useMemo(
    () =>
      !!action && {
        id: action.id,
        name: action.name || '',
        description: action.description || '',
        owner: action.owner?.email || '',
        objective: resolveSelectObjectiveInputInitialValue(objective),
        priority: resolvePriorityInitialValue(action.priority),
        timeLine: {
          endDate: action.timeLine.endDate,
        },
      },
    [action, objective],
  );

  const handleClose = useCallback(
    () =>
      handleOnClose({
        options: { state },
        teamSlug,
        orgTeamSlug,
        navigate,
        layout,
        pathname,
        objectiveId: action.objective?.id,
      }),
    [
      action.objective?.id,
      layout,
      navigate,
      orgTeamSlug,
      pathname,
      state,
      teamSlug,
    ],
  );

  const handleSubmit = useCallback(
    async (input: ActionInput) => {
      const result = await updateAction({ variables: { input } });

      const isSuccess = result.data?.updateAction.id && !result.errors;

      if (isSuccess) {
        addToast({
          variant: 'success',
          children: t('actions.toasts.editActionSuccess'),
        });

        handleClose();
      }
    },
    [updateAction, addToast, t, handleClose],
  );

  if (!initialValues) return <Spinner.Circle />;

  return (
    <ActionForm
      id={formId.current}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Dialog
        isOpen={true}
        onClose={handleClose}
        headerTitle={t('actions.form.heading.edit')}
        confirmLabel={t('update')}
        cancelLabel={t('cancel')}
        formId={formId.current}
      >
        <ActionQuickFormFields showObjectiveField={true} />
      </Dialog>
    </ActionForm>
  );
};

export default EditAction;
