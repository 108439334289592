import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import type { Column, CellProps } from 'react-table';

import type { TableViewProps } from 'shared/components/TableView';
import TableView from 'shared/components/TableView';

import type { StrategyRoomTableViewContextMenuItem } from '../StrategyRoomTableViewContextMenu';
import StrategyRoomTableViewContextMenu from '../StrategyRoomTableViewContextMenu';
import type { StrategyRoomStrategyFragment } from '../StrategyRoomProvider';
import type { StrategyRoomStrategy } from '../StrategyRoom.type';
import {
  NameCell,
  CreatedCell,
  UpdatedCell,
  StatusCell,
} from './StrategyRoomTableView.utils';

export type StrategyRoomTableViewProps = {
  onAction: (
    action: StrategyRoomTableViewContextMenuItem['id'],
    row: StrategyRoomStrategy,
  ) => void;
  strategies: StrategyRoomStrategyFragment[];
} & Pick<TableViewProps<StrategyRoomStrategy>, 'before'>;

const StrategyRoomTableView = ({
  strategies,
  onAction,
  before,
}: StrategyRoomTableViewProps) => {
  const { t } = useTranslation();

  const columns = useMemo<Column<StrategyRoomStrategy>[]>(
    () => [
      {
        accessor: 'name',
        Header: t('strategy.strategyName'),
        Cell: NameCell,
        width: '40%',
      },
      {
        id: 'auditRecord.createDateTime',
        accessor: (row) => row.auditRecord.createDateTime,
        Header: t('created'),
        Cell: CreatedCell,
        width: '20%',
      },
      {
        id: 'auditRecord.updateDateTime',
        accessor: (row) => row.auditRecord.updateDateTime,
        Header: t('updated'),
        Cell: UpdatedCell,
        width: '20%',
      },
      {
        id: 'status',
        Header: t('status.status'),
        Cell: StatusCell,
        width: '20%',
      },
      {
        id: 'menu',
        Cell: (table: CellProps<StrategyRoomStrategy>) => (
          <StrategyRoomTableViewContextMenu
            onAction={(id) => {
              onAction(id, table.row.original);
            }}
            strategy={table.row.original}
          />
        ),
      },
    ],
    [onAction, t],
  );

  return (
    <TableView<StrategyRoomStrategy>
      data={strategies}
      columns={columns}
      before={before}
      hasBorder={false}
    />
  );
};

export default StrategyRoomTableView;
