import { useTranslation } from 'react-i18next';
import type { FormikValues } from 'formik';
import { Field } from 'formik';

import FieldBox from 'shared/form/FieldBox';
import type { DatePickerProps } from 'shared/components/DatePicker';
import { DatePickerField } from 'shared/components/DatePicker';
import { useForm } from 'shared/form/Form';

import styles from './TimelineField.module.scss';

export type TimelineFieldValue = {
  endDate: Date | null;
  startDate: Date | null;
};

type TimelineFieldProps = {
  className?: string;
  endDateLabel?: string;
  endDateProps?: DatePickerProps;
  name: string;
  showField?: { endDate?: boolean; startDate?: boolean };
  startDateLabel?: string;
  startDateProps?: DatePickerProps;
};

const TimelineField = <FormValues extends FormikValues>({
  name,
  startDateProps,
  startDateLabel,
  endDateProps,
  endDateLabel,
  showField = { endDate: true, startDate: true },
}: TimelineFieldProps) => {
  const { t } = useTranslation();

  const { hasError } = useForm<FormValues>();

  return (
    <>
      {showField.startDate && (
        <FieldBox
          name={`${name}.startDate`}
          label={startDateLabel ?? t('timeline.startDate')}
          hasMargin={false}
          className={styles.datePickerInputBox}
          hasError={hasError(`${name}.startDate`)}
        >
          <Field
            name={`${name}.startDate`}
            component={DatePickerField}
            hasError={hasError(`${name}.startDate`)}
            triggerFullWidth={true}
            {...startDateProps}
          />
        </FieldBox>
      )}

      {showField.endDate && (
        <FieldBox
          name={`${name}.endDate`}
          label={endDateLabel ?? t('timeline.endDate')}
          hasMargin={false}
          className={styles.datePickerInputBox}
          hasError={hasError(`${name}.endDate`)}
        >
          <Field
            name={`${name}.endDate`}
            component={DatePickerField}
            hasError={hasError(`${name}.endDate`)}
            triggerFullWidth={true}
            {...endDateProps}
          />
        </FieldBox>
      )}
    </>
  );
};

export default TimelineField;
