import { createRoutePath } from 'shared/utils/router';

export const toolkitRoutesPaths = {
  root: createRoutePath<{ teamSlug: string }>('/teams/:teamSlug/toolkit'),
  category: createRoutePath<{ category: string; teamSlug: string }>(
    '/teams/:teamSlug/toolkit/:category',
  ),
  walkOfStrategy: {
    root: createRoutePath<{
      teamSlug: string;
    }>('/teams/:teamSlug/toolkit/walk-of-strategy'),
    overview: createRoutePath<{
      teamSlug: string;
    }>('/teams/:teamSlug/toolkit/walk-of-strategy/overview'),
    campaigns: {
      root: createRoutePath<{
        teamSlug: string;
      }>('/teams/:teamSlug/toolkit/walk-of-strategy/campaigns'),
      results: {
        root: createRoutePath<{
          campaignId: string;
          teamSlug: string;
        }>(
          '/teams/:teamSlug/toolkit/walk-of-strategy/campaigns/:campaignId/results',
        ),
        insights: createRoutePath<{
          campaignId: string;
          teamSlug: string;
        }>(
          '/teams/:teamSlug/toolkit/walk-of-strategy/campaigns/:campaignId/results/insights',
        ),
        comments: createRoutePath<{
          campaignId: string;
          teamSlug: string;
        }>(
          '/teams/:teamSlug/toolkit/walk-of-strategy/campaigns/:campaignId/results/comments',
        ),
      },
    },
    campaignResponses: {
      respond: createRoutePath<{
        campaignId: string;
        teamSlug: string;
      }>(
        '/teams/:teamSlug/toolkit/walk-of-strategy/campaign-responses/:campaignId/respond',
      ),
    },
  },
  oneThing: {
    root: createRoutePath<{
      teamSlug: string;
    }>('/teams/:teamSlug/toolkit/one-thing'),
    overview: createRoutePath<{
      teamSlug: string;
    }>('/teams/:teamSlug/toolkit/one-thing/overview'),
    campaigns: {
      root: createRoutePath<{
        teamSlug: string;
      }>('/teams/:teamSlug/toolkit/one-thing/campaigns'),
      results: createRoutePath<{
        campaignId: string;
        teamSlug: string;
      }>('/teams/:teamSlug/toolkit/one-thing/campaigns/:campaignId/results'),
    },
  },
};
