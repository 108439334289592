import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import type { PropsWithChildren, ReactNode } from 'react';
import {
  Dialog,
  Modal as AriaModal,
  ModalOverlay,
} from 'react-aria-components';

import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';

import Text from '../Text';
import Button from '../Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 20px - 20px);
  margin: 20px;
  border-radius: 20px;
  overflow: hidden;
  background-color: ${(props) => props.theme.color.white};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 0 20px;
  z-index: 10;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  max-width: 1057px;
`;

const HeaderControls = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const HeaderButton = styled(Button)`
  height: 40px;
  min-width: 40px;
  border: none;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: auto;
  height: 100%;
`;

const Content = styled.div`
  flex-grow: 1;
  max-width: 1057px;
`;

const overlayStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--visual-viewport-height);
  background: rgba(0 0 0 / 0.5);
  z-index: 5500;

  &[data-entering] {
    animation: side-modal-fade 300ms;
  }

  &[data-exiting] {
    animation: side-modal-fade 300ms reverse ease-in;
  }

  @keyframes side-modal-fade {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

const modalStyle = css`
  height: 100vh;
  display: flex;
  justify-content: flex-end;

  &[data-entering] {
    animation: side-modal-slide-from-right 300ms;
  }

  &[data-exiting] {
    animation: side-modal-slide-from-right 300ms reverse ease-in;
  }

  @keyframes side-modal-slide-from-right {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
    }
  }
`;

const dialogStyle = css`
  max-width: 640px;
  flex-grow: 1;

  :focus-visible {
    outline: none;
  }
`;

type Props = PropsWithChildren<{
  headerControls?: ReactNode;
  headerTitle: string;
  isOpen: boolean;
  onClose: () => void;
}>;

const SideModal = ({
  headerTitle,
  headerControls,
  isOpen,
  onClose,
  children,
}: Props) => {
  const theme = useTheme();

  return (
    <ModalOverlay
      css={overlayStyle}
      isOpen={isOpen}
      onOpenChange={(isOpen) => isOpen || onClose()}
      isDismissable={true}
    >
      <AriaModal css={modalStyle}>
        <Dialog css={dialogStyle}>
          <Container>
            <Header>
              <HeaderContent>
                <Text variant={'emphasis'} size={'large'} isBold={true}>
                  {headerTitle}
                </Text>

                <HeaderControls>
                  {headerControls}
                  <HeaderButton
                    variant={'icon'}
                    icon={CloseIcon}
                    css={css({
                      color: 'black',
                      borderColor: theme.color.strokeMedium,
                    })}
                    onClick={onClose}
                  />
                </HeaderControls>
              </HeaderContent>
            </Header>

            <ContentContainer>
              <Content>{children}</Content>
            </ContentContainer>
          </Container>
        </Dialog>
      </AriaModal>
    </ModalOverlay>
  );
};

export default SideModal;
