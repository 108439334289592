import { useTranslation } from 'react-i18next';

import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import type {
  StrategyBehaviorsFormProps,
  StrategyBehaviorsFormValues,
} from 'strategy/StrategyBehaviorsForm';
import StrategyBehaviorsForm from 'strategy/StrategyBehaviorsForm';
import InfoCard from 'shared/components/InfoCard';
import Sidesheets from 'shared/sidesheets';

import styles from './StrategyWizardBehaviors.module.scss';

export type StrategyWizardBehaviorsValues = StrategyBehaviorsFormValues;

type StrategyWizardBehaviorsProps = Pick<
  StrategyBehaviorsFormProps,
  'id' | 'className' | 'onChange' | 'initialValues'
>;

const StrategyWizardBehaviors = (props: StrategyWizardBehaviorsProps) => {
  const { t } = useTranslation();

  const renderForm = () => <StrategyBehaviorsForm {...props} />;

  const renderInfoCard = () => (
    <InfoCard
      title={t('strategy.strategyWizard.steps.behaviors.infoCard.heading')}
      contentKey={'strategy.strategyWizard.steps.behaviors.infoCard.content'}
      sideSheetTitle={t('sidesheets.context.strategy.heading')}
      orgTermKey={'strategy.behavior'}
    >
      <Sidesheets context={'strategy'} wizardStep={'behaviors'} />
    </InfoCard>
  );

  return (
    <FormWithInfoCardLayout
      renderForm={renderForm}
      renderInfoCard={renderInfoCard}
      formCardClassName={styles.formCard}
      formCardContentClassName={styles.formCardContent}
    />
  );
};

export default StrategyWizardBehaviors;
