import styled from '@emotion/styled';
import type { ComponentType, PropsWithChildren, SVGProps } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Label as AriaLabel } from 'react-aria-components';

import Text from '../Text/Text';

const StyledLabel = styled(AriaLabel)<{ isLabelVisible?: boolean }>(
  ({ isLabelVisible }) => ({
    alignItems: 'center',
    display: 'flex',
    fontSize: '.875rem',
    gap: '.5rem',
    justifyContent: 'flex-start',
    marginBottom: '.375rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    visibility: isLabelVisible ? 'visible' : 'hidden',
  }),
);

const Icon = styled.svg((props) => ({
  color: props.theme.legacyColor.colorSilver,
  marginTop: '.125em',
}));

type LabelProps = PropsWithChildren<{
  htmlFor?: string;
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
  isLabelVisible?: boolean;
  isOptional?: boolean;
}>;

const Label = ({
  children,
  htmlFor,
  isLabelVisible,
  icon: IconComponent,
  isOptional,
}: LabelProps) => {
  const { t } = useTranslation();

  if (!children) return null;

  return (
    <StyledLabel htmlFor={htmlFor} isLabelVisible={isLabelVisible}>
      {IconComponent ? <Icon as={IconComponent} /> : null}
      {children}
      {isOptional && (
        <Text css={css({ fontSize: '0.875rem' })}>
          {t('inputBox.optional')}
        </Text>
      )}
    </StyledLabel>
  );
};

export default Label;
