import List from './KeyIndicatorsList';
import Tiles from './KeyIndicatorsTiles';

export type KeyIndicatorsProps = {
  className?: string;
  keyIndicatorOnBudget?: boolean | null;
  keyIndicatorOnOutcome?: boolean | null;
  keyIndicatorOnTime?: boolean | null;
  variant?: 'list' | 'tiles';
};

const KeyIndicators = ({
  variant = 'list',
  ...restProps
}: KeyIndicatorsProps) => {
  switch (variant) {
    case 'list': {
      return <List {...restProps} />;
    }
    case 'tiles': {
      return <Tiles {...restProps} />;
    }
  }
};

export default KeyIndicators;
