import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import type { MetricsListObjectivesContextValue } from './MetricsListProvider.context';
import { MetricsListObjectivesContext } from './MetricsListProvider.context';
import {
  getMetricsActive,
  getObjectiveMetrics,
  getObjectivesWithMetricsActive,
} from './MetricsListProvider.utils';
import type { MetricsListObjective } from './MetricsListProvider.type';

type Props = PropsWithChildren<{
  objectives?: Maybe<MetricsListObjective[]>;
}>;

const MetricsListObjectivesProvider = ({ objectives, children }: Props) => {
  const contextValue = useMemo<
    MetricsListObjectivesContextValue | undefined
  >(() => {
    const allObjectives = objectives;

    const allObjectivesActiveWithMetricsActive =
      getObjectivesWithMetricsActive(allObjectives);

    const allMetrics = getObjectiveMetrics(allObjectives);
    const allMetricsActive = getMetricsActive(allMetrics);

    const filteredMetricsActive = getObjectiveMetrics(allObjectives);

    return {
      isMetricsListObjectivesLoading: !objectives,
      allMetrics,
      allMetricsActive,
      filteredMetricsActive,
      allObjectives,
      allObjectivesActiveWithMetricsActive,
    };
  }, [objectives]);

  return (
    <MetricsListObjectivesContext.Provider value={contextValue}>
      {children}
    </MetricsListObjectivesContext.Provider>
  );
};

export default MetricsListObjectivesProvider;
