import { useTranslation } from 'react-i18next';

import Text from 'shared/components/Text';
import Space from 'shared/components/Space';

type InsightReportWizardObjectivesSummaryProps = {
  initiativesCount: number;
  metricsCount: number;
  objectivesCount: number;
};

const InsightReportWizardObjectivesSummary = ({
  objectivesCount,
  metricsCount,
  initiativesCount,
}: InsightReportWizardObjectivesSummaryProps) => {
  const { t } = useTranslation();

  return (
    <Space size={'big'}>
      <Text variant={'strong'}>
        {t('report.insightReportWizard.objectivesSummary.selected')}
      </Text>
      <Space gap={4}>
        <Text variant={'strong'}>{objectivesCount}</Text>
        <Text variant={'regular'}>
          {t('objective.objective', { count: objectivesCount })}
        </Text>
      </Space>
      <Space gap={4}>
        <Text variant={'strong'}>{metricsCount}</Text>
        <Text variant={'regular'}>
          {t('metric.metric', { count: metricsCount })}
        </Text>
      </Space>
      <Space gap={4}>
        <Text variant={'strong'}>{initiativesCount}</Text>
        <Text variant={'regular'}>
          {t('initiative.initiative', { count: initiativesCount })}
        </Text>
      </Space>
    </Space>
  );
};

export default InsightReportWizardObjectivesSummary;
