import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { capitalize } from 'lodash';

import StrategyCard from 'strategy/StrategyCard';
import { useStrategy } from 'strategy/StrategyProvider';
import { useThemes } from 'themes/ThemesProvider';
import {
  hasMission,
  hasVision,
  hasBehaviors,
  hasChoices,
  hasGoals,
  hasValues,
  hasThemes,
} from 'strategy/strategy.utils';
import Space from 'shared/components/Space';

import { getThemesByCategory } from './StrategyOverviewCore.utils';
import styles from './StrategyOverviewCore.module.scss';
import Result from '../../shared/components/Result';
import { ReactComponent as LookingAtCardBoardBoxIllustration } from '../../shared/static/illustrations/illustration-looking-at-cardboard-box.svg';

const StrategyOverviewCore = () => {
  const { t } = useTranslation();

  const { strategy } = useStrategy();
  const { themes } = useThemes();

  const themesByCategory = useMemo(
    () => strategy && getThemesByCategory(themes),
    [strategy, themes],
  );

  if (
    !strategy ||
    (!hasMission(strategy) &&
      !hasVision(strategy) &&
      !hasBehaviors(strategy) &&
      !hasChoices(strategy) &&
      !hasGoals(strategy) &&
      !hasValues(strategy) &&
      !hasMission(strategy) &&
      !hasThemes(themes))
  ) {
    return (
      <Space isCentered={true}>
        <Result
          description={t('strategy.strategyRoadmap.emptyMessage')}
          illustration={LookingAtCardBoardBoxIllustration}
          hasBackground={true}
        />
      </Space>
    );
  }

  return (
    <Space
      direction={'vertical'}
      className={styles.card}
      size={'custom'}
      value={0}
    >
      {hasMission(strategy) ? (
        <StrategyCard
          heading={capitalize(t('strategy.mission'))}
          title={strategy?.mission?.name}
          description={strategy?.mission?.description}
        />
      ) : null}
      {hasVision(strategy) ? (
        <StrategyCard
          heading={t('strategy.coreStrategy.vision')}
          title={strategy?.vision?.name}
          description={strategy?.vision?.description}
        />
      ) : null}
      {hasGoals(strategy) ? (
        <StrategyCard
          heading={capitalize(t('strategy.goal_other'))}
          itemsList={strategy.goals.map((goal) => ({
            title: goal.name,
            description: goal.description,
          }))}
        />
      ) : null}
      {hasChoices(strategy) ? (
        <StrategyCard
          heading={t('strategy.coreStrategy.choices')}
          itemsList={strategy.choices.map((choice) => ({
            title: choice.name,
            description: choice.description,
          }))}
        />
      ) : null}
      {themesByCategory?.length ? (
        <StrategyCard
          heading={t('strategy.themes')}
          items={themesByCategory.map(({ themes, category }) => ({
            description:
              category?.name || t('strategy.coreStrategy.noThemeCategory'),
            items: themes.map((theme) => ({
              title: theme.name || '',
              description: theme.description || '',
              items: [],
            })),
          }))}
        />
      ) : null}
      {hasBehaviors(strategy) ? (
        <StrategyCard
          heading={t('strategy.coreStrategy.behaviors')}
          items={strategy.behaviors.map((behavior) => ({
            title: behavior.name,
            description: behavior.description,
            items: behavior.behaviorSetGroups.map((behaviorSetGroup) => ({
              title: behaviorSetGroup.name,
              description: behaviorSetGroup.description,
              items: behaviorSetGroup.items.map((item) => ({
                title: item.text,
              })),
            })),
          }))}
        />
      ) : null}
      {hasValues(strategy) ? (
        <StrategyCard
          heading={t('strategy.coreStrategy.values')}
          itemsList={strategy.values.map((value) => ({
            title: value.name,
            description: value.description,
          }))}
        />
      ) : null}
    </Space>
  );
};

export default StrategyOverviewCore;
