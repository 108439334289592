import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ChevronLeft } from 'shared/static/icons/icon-chevron-left.svg';
import { ReactComponent as ChevronRight } from 'shared/static/icons/icon-chevron-right.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.typoSecondary};
  font-size: 0.875rem;
  user-select: none;
`;

const ChevronContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;

  svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  &[aria-disabled='true'] {
    pointer-events: none;
    color: ${(props) => props.theme.color.neutral2};
  }
`;

const Count = styled.span`
  display: flex;
  justify-content: center;
  width: 15px;
`;

type Props = {
  currentPageIndex: number;
  pageCount: number;
  setCurrentPageIndex: (index: number) => void;
};

const Pagination = ({
  currentPageIndex,
  pageCount,
  setCurrentPageIndex,
}: Props) => {
  const { t } = useTranslation();
  const currentPagePosition = currentPageIndex + 1;

  const isFirst = currentPagePosition === 1;
  const isLast = currentPagePosition === pageCount;

  return (
    <Container>
      <ChevronContainer
        aria-disabled={isFirst}
        onClick={() => setCurrentPageIndex(currentPageIndex - 1)}
      >
        <ChevronLeft />
      </ChevronContainer>
      <Count>{currentPagePosition}</Count> {t('of')} <Count>{pageCount}</Count>
      <ChevronContainer
        aria-disabled={isLast}
        onClick={() => setCurrentPageIndex(currentPageIndex + 1)}
      >
        <ChevronRight />
      </ChevronContainer>
    </Container>
  );
};

export default Pagination;
