import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ObjectiveDocument, useObjective } from 'objective/ObjectiveProvider';
import type { ObjectiveFormValues } from 'objective/EditObjective/ObjectiveForm';
import { resolveInitialValue as resolveSelectObjectiveInputInitialValue } from 'objective/SelectObjectiveInput';
import { resolveInitialValue as resolveSelectThemeInputInitialValue } from 'objective/SelectThemeInput';
import ObjectiveForm from 'objective/EditObjective/ObjectiveForm';
import type { ObjectiveInput } from 'types.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import { resolvePriorityInitialValue } from 'strategy/PrioritySelect/resolvePriorityInitialValue';
import type { RedirectToMyContributionLocation } from 'contribution';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { resolveOrgUnitInputInitialValue } from 'orgUnit/SelectOrgUnitInput/SelectOrgUnitInput.utils';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import { useTeamSlug } from 'team/TeamAdapter/useTeamSlug';

import { handleOnClose } from './handleOnClose';
import { useUpdateObjectiveMutation } from './UpdateObjective.graphql.generated';
import ObjectiveFormModal from './ObjectiveFormModal/ObjectiveFormModal';

const EditObjective = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation() as RedirectToMyContributionLocation;

  const { activeOrg } = useActiveOrg();
  const { orgTeamSlug } = useTeamSlug();
  const { addToast } = useToasts();
  const { objective } = useObjective();

  const handleApolloError = useHandleApolloError();

  const [updateObjective] = useUpdateObjectiveMutation({
    onError: handleApolloError,
    refetchQueries: [ObjectiveDocument],
  });

  const initialValues: ObjectiveFormValues = useMemo(
    () => ({
      id: objective.id,
      name: objective.name || '',
      description: objective.description || '',
      owner: objective.owner?.email || '',
      parentObjective: resolveSelectObjectiveInputInitialValue(
        objective.parentObjective,
      ),
      theme: resolveSelectThemeInputInitialValue(objective.theme),
      priority: resolvePriorityInitialValue(objective.priority),
      timeLine: {
        startDate: objective.timeLine.startDate,
        endDate: objective.timeLine.endDate,
      },
      attributes: objective.attributes.map((attribute) => ({
        id: attribute.id,
        name: attribute.name || '',
        title: attribute.title || '',
        description: attribute.description || '',
      })),
      orgUnit: resolveOrgUnitInputInitialValue(activeOrg, objective.orgUnit),
    }),
    [
      activeOrg,
      objective.attributes,
      objective.description,
      objective.id,
      objective.name,
      objective.orgUnit,
      objective.owner?.email,
      objective.parentObjective,
      objective.priority,
      objective.theme,
      objective.timeLine.endDate,
      objective.timeLine.startDate,
    ],
  );

  const handleClose = useCallback(
    () =>
      handleOnClose({
        options: { state },
        orgTeamSlug,
        navigate,
      }),
    [navigate, orgTeamSlug, state],
  );

  const handleSubmit = useCallback(
    async (input: ObjectiveInput) => {
      const result = await updateObjective({ variables: { input } });

      const isSuccess = result.data?.updateObjective.id && !result.errors;

      if (isSuccess) {
        addToast({
          variant: 'success',
          children: t('objective.editObjectiveModal.successToast'),
        });

        handleClose();
      }
    },
    [updateObjective, addToast, t, handleClose],
  );

  const formId = 'objective';

  return (
    <ObjectiveForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      formId={formId}
    >
      <ObjectiveFormModal
        headerTitle={t('objective.editObjectiveModal.heading')}
        confirmLabel={t('update')}
        isOpen={true}
        onClose={handleClose}
        formId={formId}
      />
    </ObjectiveForm>
  );
};

export default EditObjective;
