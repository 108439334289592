import type { ReactElement } from 'react';
import { Item, Section } from 'react-stately';
import { useCallback } from 'react';

import type { BaseMenuItem, MenuRowComponent } from './Menu.type';

type MenuCollectionChildrenParams<MenuItem extends BaseMenuItem> = {
  Row: MenuRowComponent<MenuItem>;
};

type MenuCollectionChildrenReturnType<MenuItem extends BaseMenuItem> = (
  item: MenuItem,
) => ReactElement;

export const useMenuCollectionChildren = <MenuItem extends BaseMenuItem>({
  Row,
}: MenuCollectionChildrenParams<MenuItem>): MenuCollectionChildrenReturnType<MenuItem> => {
  const renderCollectionChildren = useCallback(
    (item: MenuItem) => (
      <Item textValue={`${item.id}`}>
        <Row item={item} />
      </Item>
    ),
    [Row],
  );

  const renderCollectionChildrenWithSection = useCallback(
    (item: MenuItem) => {
      if (item.children) {
        return (
          <Section items={item.children as MenuItem[]} title={item.title}>
            {renderCollectionChildren}
          </Section>
        );
      }
      return renderCollectionChildren(item);
    },
    [renderCollectionChildren],
  );

  return renderCollectionChildrenWithSection;
};
