import { css } from '@emotion/react';
import type { CSSProperties, PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  alignItems?: CSSProperties['alignItems'];
  alignSelf?: CSSProperties['alignSelf'];
  className?: string;
  direction?: CSSProperties['flexDirection'];
  gap?: CSSProperties['gap'];
  grow?: CSSProperties['flexGrow'];
  justifyContent?: CSSProperties['justifyContent'];
  onClick?: () => void;
  shrink?: CSSProperties['flexShrink'];
  wrap?: CSSProperties['flexWrap'];
}>;

const Flex = ({
  className,
  direction,
  gap,
  alignItems,
  alignSelf,
  justifyContent,
  grow,
  shrink,
  wrap,
  onClick,
  children,
}: Props) => (
  <div
    className={className}
    onClick={onClick}
    css={css({
      display: 'flex',
      gap,
      alignItems,
      alignSelf,
      justifyContent,
      flexDirection: direction,
      flexGrow: grow,
      flexShrink: shrink,
      flexWrap: wrap,
    })}
  >
    {children}
  </div>
);

export default Flex;
