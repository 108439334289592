import type { PropsWithChildren } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { datadogRum } from '@datadog/browser-rum';

import type { AuthProviderContextValue } from './AuthProvider.context';
import { AuthProviderContext } from './AuthProvider.context';

const auth = getAuth();

type AuthProviderProps = PropsWithChildren<{
  onSignOut: () => void;
}>;

const AuthProvider = ({ children, onSignOut }: AuthProviderProps) => {
  const [user, setUser] = useState<AuthProviderContextValue['user']>(undefined);
  const [isLoading, setIsLoading] =
    useState<AuthProviderContextValue['isLoading']>(true);
  const [error, setError] =
    useState<AuthProviderContextValue['error']>(undefined);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        if (user) {
          datadogRum.setUser({
            id: user.uid,
            name: user.displayName || user.email || undefined,
            email: user.email || undefined,
          });
          setUser(user);
        } else {
          datadogRum.clearUser();
          setUser(undefined);
        }

        setIsLoading(false);
      },
      (error) => {
        setError(error);
      },
    );
    return () => {
      unsubscribe();
    };
  }, [onSignOut]);

  const handleSignOut = useCallback(
    () => signOut(auth).then(onSignOut),
    [onSignOut],
  );

  const authContextValue = useMemo<AuthProviderContextValue>(
    () => ({
      user,
      isLoading,
      error,
      signOut: handleSignOut,
    }),
    [user, isLoading, error, handleSignOut],
  );

  return (
    <AuthProviderContext.Provider value={authContextValue}>
      {children}
    </AuthProviderContext.Provider>
  );
};

export default AuthProvider;
