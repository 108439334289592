import type { StatusFormValues } from './StatusForm.type';

export const createInitialValues = (
  completeStatus?: boolean,
): StatusFormValues => ({
  comment: '',
  statusIndicator: '',
  complete: !!completeStatus,
  date: new Date(),
});
