import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useMap } from 'react-use';

import type { FormRefsProviderContextValue } from './FormRefsProvider.context';
import { FormRefsProviderContext } from './FormRefsProvider.context';

type FormRefsProviderProps = PropsWithChildren<object>;

const FormRefsProvider = ({ children }: FormRefsProviderProps) => {
  const [formRefs, { set: addFormRef, remove: deleteFormRef }] = useMap();

  const contextValue = useMemo<FormRefsProviderContextValue>(
    () => ({
      formRefs,
      addFormRef,
      deleteFormRef,
    }),
    // References to addFormRef and deleteFormRef functions have no importance
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formRefs],
  );

  return (
    <FormRefsProviderContext.Provider value={contextValue}>
      {children}
    </FormRefsProviderContext.Provider>
  );
};

export default FormRefsProvider;
