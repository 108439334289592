import { createRoutePath } from 'shared/utils/router';
import type { Strategy } from 'types.graphql.generated';
import type { StrategyWizardStepId } from 'strategy/StrategyWizard';

export const strategyRoomRoutesPaths = {
  root: createRoutePath('/'),
  strategy: {
    root: createRoutePath('strategy'),
    pyramid: createRoutePath('pyramid'),
  },
  themes: {
    root: createRoutePath('themes'),
    manageThemes: createRoutePath('manage-themes'),
    manageObjectives: createRoutePath('manage-objectives'),
  },
  create: createRoutePath('create'),
  edit: createRoutePath<
    never,
    {
      step?: StrategyWizardStepId;
      strategyId: Strategy['id'];
    }
  >('strategy/edit'),
  preview: createRoutePath<
    never,
    {
      strategyId: Strategy['id'];
    }
  >('strategy/preview'),
};
