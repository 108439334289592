import styled from '@emotion/styled';
import hexToRgba from 'hex-to-rgba';
import { useTranslation } from 'react-i18next';

import type { LikertScaleRating } from 'types.graphql.generated';
import Emoji from 'shared/components/Emoji';

import { useQuestions } from './QuestionsProvider.context';

const Button = styled.button<{ isDisabled: boolean; isSelected: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: ${(props) =>
    props.isSelected
      ? hexToRgba(props.theme.color.primaryVariant, 0.3)
      : props.theme.color.white};
  border-radius: 0.75rem;
  padding: 0.25rem 0.75rem;
  border: 1px solid
    ${(props) =>
      props.isSelected
        ? props.theme.color.primary
        : props.theme.color.strokeDark};
  align-self: stretch;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
`;

const Icon = styled.span`
  font-size: 22px;
`;

type Props = {
  isSelected: boolean;
  onSelect: () => void;
  rating: LikertScaleRating;
};

const AnswerPickerOption = ({ rating, isSelected, onSelect }: Props) => {
  const { t } = useTranslation();
  const { isCampaignActive } = useQuestions();

  const isDisabled = !isCampaignActive;

  return (
    <Button
      isSelected={isSelected}
      isDisabled={isDisabled}
      onClick={() => !isDisabled && onSelect()}
    >
      <Icon>
        <Emoji
          icon={t(
            `toolkit.toolPage.walkOfStrategy.survey.anwer.${rating}.icon`,
          )}
        />
      </Icon>
      <span>
        {t(`toolkit.toolPage.walkOfStrategy.survey.anwer.${rating}.label`)}
      </span>
    </Button>
  );
};

export default AnswerPickerOption;
