import { date } from 'shared/services/date.service';

import type { CalendarItem } from '../Calendar.type';

export const getLeftPosition = (
  item: CalendarItem,
  startDateTime: Date,
  calendarDuration: number,
) => {
  const fraction = item.startDateTime.getTime() - startDateTime.getTime();
  const baseDistance = (fraction / calendarDuration) * 100;

  //show just a little bit of the item on the calendar if it starts on Dec 31
  const distance = baseDistance === 100 ? 99.8 : baseDistance;

  return distance;
};

export const getRightPosition = (
  item: CalendarItem,
  endDateTime: Date,
  calendarDuration: number,
) => {
  const fraction = endDateTime.getTime() - item.endDateTime.getTime();
  const baseDistance = (fraction / calendarDuration) * 100;

  //show just a little bit of the item on the calendar if it ends on Jan 1st
  const distance = baseDistance === 100 ? 99.8 : baseDistance;

  return distance;
};

export const isOutOfRange = (
  item: CalendarItem,
  currentYearStartDateTime: Date,
  currentYearEndDateTime: Date,
) =>
  date.isAfter(
    date.getYear(item.startDateTime),
    date.getYear(currentYearStartDateTime),
  ) ||
  date.isAfter(
    date.getYear(currentYearEndDateTime),
    date.getYear(item.endDateTime),
  );
