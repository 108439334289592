import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddInitiativeMutationVariables = Types.Exact<{
  input: Types.InitiativeInput;
}>;


export type AddInitiativeMutation = { __typename: 'Mutation', addInitiative: { __typename: 'Initiative', id: string, name?: string | undefined, objective?: { __typename: 'Objective', id: string } | undefined } };


export const AddInitiativeDocument = gql`
    mutation AddInitiative($input: InitiativeInput!) {
  addInitiative(input: $input) {
    id
    name
    objective {
      id
    }
  }
}
    `;
export type AddInitiativeMutationFn = Apollo.MutationFunction<AddInitiativeMutation, AddInitiativeMutationVariables>;

/**
 * __useAddInitiativeMutation__
 *
 * To run a mutation, you first call `useAddInitiativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInitiativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInitiativeMutation, { data, loading, error }] = useAddInitiativeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddInitiativeMutation(baseOptions?: Apollo.MutationHookOptions<AddInitiativeMutation, AddInitiativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInitiativeMutation, AddInitiativeMutationVariables>(AddInitiativeDocument, options);
      }
export type AddInitiativeMutationHookResult = ReturnType<typeof useAddInitiativeMutation>;
export type AddInitiativeMutationResult = Apollo.MutationResult<AddInitiativeMutation>;
export type AddInitiativeMutationOptions = Apollo.BaseMutationOptions<AddInitiativeMutation, AddInitiativeMutationVariables>;