import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateOneThingTaskMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.OneThingTaskInput>;
}>;


export type CreateOneThingTaskMutation = { __typename: 'Mutation', saveOneThingTask: { __typename: 'OneThingTask', id: string } };


export const CreateOneThingTaskDocument = gql`
    mutation CreateOneThingTask($input: OneThingTaskInput) {
  saveOneThingTask(input: $input) {
    id
  }
}
    `;
export type CreateOneThingTaskMutationFn = Apollo.MutationFunction<CreateOneThingTaskMutation, CreateOneThingTaskMutationVariables>;

/**
 * __useCreateOneThingTaskMutation__
 *
 * To run a mutation, you first call `useCreateOneThingTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneThingTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneThingTaskMutation, { data, loading, error }] = useCreateOneThingTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneThingTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneThingTaskMutation, CreateOneThingTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneThingTaskMutation, CreateOneThingTaskMutationVariables>(CreateOneThingTaskDocument, options);
      }
export type CreateOneThingTaskMutationHookResult = ReturnType<typeof useCreateOneThingTaskMutation>;
export type CreateOneThingTaskMutationResult = Apollo.MutationResult<CreateOneThingTaskMutation>;
export type CreateOneThingTaskMutationOptions = Apollo.BaseMutationOptions<CreateOneThingTaskMutation, CreateOneThingTaskMutationVariables>;