import type { Animation } from 'shared/types/animation.type';

export const menuPopoverAnimation: Animation = {
  initial: 'collapsed',
  animate: 'expanded',
  exit: 'collapsed',
  variants: {
    expanded: {
      opacity: 1,
      scale: 1,
      x: 0,
      y: 10,
      transition: {
        opacity: {
          duration: 0.2,
        },
        y: {
          duration: 0.5,
          type: 'spring',
          bounce: 0.65,
        },
      },
    },
    collapsed: {
      opacity: 0,
      scale: 1,
      x: 0,
      y: 0,
      transition: {
        opacity: {
          duration: 0.1,
        },
        y: {
          duration: 0.1,
        },
      },
    },
  },
};
