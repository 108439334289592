import cn from 'classnames';

import type { ModalProps } from 'shared/components/__DEPRECATED__/Modal';
import Modal from 'shared/components/__DEPRECATED__/Modal';
import {
  slideFromLeftAnimation,
  slideFromRightAnimation,
} from 'shared/animations/slide.animation';
import type { Animation } from 'shared/types/animation.type';

import styles from './Drawer.module.scss';

type Width = 'small' | 'medium';

type Placement = 'right' | 'left';

const getAnimation = (placement: Placement): Animation =>
  ({
    left: slideFromLeftAnimation,
    right: slideFromRightAnimation,
  }[placement]);

type DrawerProps = Exclude<
  ModalProps,
  'className' | 'overlayClassName' | 'size' | 'animation'
> & {
  placement?: Placement;
  width?: Width;
};

const Drawer = ({
  placement = 'right',
  width = 'small',
  className,
  ...restProps
}: DrawerProps) => {
  const animation = getAnimation(placement);

  return (
    <Modal
      {...restProps}
      className={cn(styles.drawerModal, className)}
      overlayClassName={cn(
        styles.drawerOverlay,
        cn(styles.drawer, {
          [styles.drawerLeft]: placement === 'left',
          [styles.drawerRight]: placement === 'right',
          [styles.drawerSmall]: width === 'small',
          [styles.drawerMedium]: width === 'medium',
        }),
      )}
      size={'full'}
      animation={animation}
    />
  );
};

export default Drawer;
