import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import type { SetOptional } from 'type-fest';

import type { UserEmailInputProviderProps } from 'user/UserEmailInput';
import type { FormProps } from 'shared/form/Form/Form';
import {
  UserEmailInputField,
  UserEmailInputProvider,
} from 'user/UserEmailInput';
import Form from 'shared/form/Form/Form';
import FieldBox from 'shared/form/FieldBox';
import { yup } from 'shared/services/yup.service';
import { TextInputField } from 'shared/components/TextInput';
import type { TeamAdapter } from 'team/TeamAdapter';

export type AddTeamMemberFormValues = {
  role: string;
  user: string;
};

const createInitialValues = (): AddTeamMemberFormValues => ({
  user: '',
  role: '',
});

export type AddTeamMemberFormProps = SetOptional<
  Pick<
    FormProps<AddTeamMemberFormValues>,
    'id' | 'className' | 'onSubmit' | 'initialValues'
  >,
  'initialValues'
> &
  Pick<UserEmailInputProviderProps, 'usersToExclude'> & {
    teamAdapter: TeamAdapter;
  };

const AddTeamMemberForm = ({
  id,
  className,
  onSubmit,
  initialValues = createInitialValues(),
  usersToExclude,
  teamAdapter,
}: AddTeamMemberFormProps) => {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    user: yup.string().email().required(),
  });

  return (
    <UserEmailInputProvider
      teamAdapter={teamAdapter}
      usersToExclude={usersToExclude}
    >
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        id={id}
        className={className}
      >
        {(formRenderProps) => {
          const { hasError } = formRenderProps;
          return (
            <>
              <FieldBox
                name={'user'}
                label={t('user.addTeamMemberForm.user.label')}
                hasError={hasError('user')}
              >
                <Field
                  name={'user'}
                  component={UserEmailInputField}
                  hasError={hasError('user')}
                />
              </FieldBox>
              <FieldBox
                name={'role'}
                label={t('user.addTeamMemberForm.role.label')}
                isOptional={true}
              >
                <Field
                  name={'role'}
                  component={TextInputField}
                  maxLength={50}
                />
              </FieldBox>
            </>
          );
        }}
      </Form>
    </UserEmailInputProvider>
  );
};

export default AddTeamMemberForm;
