import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { StatusMultiSelectItemId } from 'shared/status/StatusMultiSelect';
import { isStatusMultiSelectId } from 'shared/status/StatusMultiSelect';

type StatusFilterParams = {
  paramName?: string;
};

type StatusFilterReturnType = {
  setStatusFilter: (value: StatusMultiSelectItemId[]) => void;
  statusFilter: StatusMultiSelectItemId[];
};

export const useStatusFilter = ({
  paramName = 'status',
}: StatusFilterParams = {}): StatusFilterReturnType => {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultValue = useMemo<StatusMultiSelectItemId[]>(() => ['ALL'], []);

  const statusFilter = useMemo<StatusFilterReturnType['statusFilter']>(() => {
    const params = searchParams.getAll(paramName);
    if (params.length && params.every(isStatusMultiSelectId)) {
      return params;
    }
    return defaultValue;
  }, [searchParams, paramName, defaultValue]);

  const setStatusFilter = useCallback<
    StatusFilterReturnType['setStatusFilter']
  >(
    (value) => {
      setSearchParams((searchParams) => {
        searchParams.delete(paramName);
        value.forEach((id) => {
          searchParams.append(paramName, id);
        });
        return searchParams;
      });
    },
    [setSearchParams, paramName],
  );

  return {
    statusFilter,
    setStatusFilter,
  };
};
