import styled from '@emotion/styled';

import Message from './Message';
import type { ChatMessage, ChatMessageObject } from './ChatProvider';
import { useQuestions } from './QuestionsProvider.context';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  max-width: 528px;
  gap: 8px;
  margin-bottom: 88px;
`;

const MessageContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const AvatarContainer = styled.div`
  width: 35px;
  min-width: 35px;
`;

type Props = {
  isChatSkipActive: boolean;
  messages: ChatMessage[];
};

const Chat = ({ messages, isChatSkipActive }: Props) => {
  const { currentQuestion } = useQuestions();

  return (
    <Container>
      {messages.map(getMessageObject).map((message, index) => (
        <MessageContainer key={`${currentQuestion.id}-${message.key}-${index}`}>
          <AvatarContainer>{message.avatar}</AvatarContainer>
          <Message
            waitForInput={message.waitForInput}
            bubbleWrap={message.bubbleWrap}
            isChatSkipActive={isChatSkipActive}
          >
            {message.content}
          </Message>
        </MessageContainer>
      ))}
    </Container>
  );
};

export default Chat;

const getMessageObject = (message: ChatMessage): ChatMessageObject => {
  const isObject = typeof message === 'object' && 'content' in message;

  if (isObject) {
    return message;
  } else {
    const content = message;

    return {
      key: message.toString(),
      content,
    };
  }
};
