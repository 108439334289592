import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@emotion/react';
import 'shared/config/firebase.config';
import 'shared/chart/chart.config';
import 'shared/styles/index.scss';
import { Suspense } from 'react';

import { theme } from 'theme';
import I18nextProvider from 'shared/providers/I18nextProvider';
import { ActingOrg } from 'org/ActingOrg';
import ToastProvider from 'shared/toast/ToastProvider';
import GlobalSpinner, {
  GlobalSpinnerProvider,
} from 'shared/spinner/GlobalSpinner';
import { FormRefsProvider } from 'shared/form/Form';
import AuthProvider from 'auth/AuthProvider';
import { createApolloClient } from 'shared/config/apollo.config';
import './polyfills';
import useSupressResizeObservableErrors from 'shared/hooks/useSupressResizeObservableErrors';
import UserValidationProvider from 'user/UserValidationProvider';

import { typePolicies } from './App.typePolicies';
import { routes } from './App.routing';

export const apolloClient = createApolloClient({
  typePolicies,
  additionalLinks: [ActingOrg.getActingOrgLink()],
});

const router = createBrowserRouter(routes);

const handleSignOut = () => {
  sessionStorage.clear();
  localStorage.clear();
  apolloClient.cache.reset();
};

const App = () => {
  useSupressResizeObservableErrors();

  return (
    <AuthProvider onSignOut={handleSignOut}>
      <UserValidationProvider>
        <I18nextProvider>
          <ThemeProvider theme={theme}>
            <ToastProvider>
              <ApolloProvider client={apolloClient}>
                <GlobalSpinnerProvider>
                  <FormRefsProvider>
                    <Suspense fallback={<GlobalSpinner />}>
                      <RouterProvider router={router} />
                    </Suspense>
                  </FormRefsProvider>
                </GlobalSpinnerProvider>
              </ApolloProvider>
            </ToastProvider>
          </ThemeProvider>
        </I18nextProvider>
      </UserValidationProvider>
    </AuthProvider>
  );
};

export default App;
