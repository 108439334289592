import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserLimitedProfileFragmentDoc } from '../../user/userProfile.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StrategyRoomThemesManageObjectivesQueryVariables = Types.Exact<{
  themesOrgUnitIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  objectivesOrgUnitIdsFilter: Types.IdInListFilterInput;
}>;


export type StrategyRoomThemesManageObjectivesQuery = { __typename: 'Query', orgThemes: { __typename: 'ThemeConnection', edges: Array<{ __typename: 'ThemeEdge', node: { __typename: 'Theme', id: string, name?: string | undefined, isActive: boolean, description?: string | undefined, orderNumber?: number | undefined, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, themeCategory?: { __typename: 'ThemeCategory', id: string, name?: string | undefined } | undefined } }> }, orgUnitThemes: { __typename: 'ThemeConnection', edges: Array<{ __typename: 'ThemeEdge', node: { __typename: 'Theme', id: string, name?: string | undefined, isActive: boolean, description?: string | undefined, orderNumber?: number | undefined, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, themeCategory?: { __typename: 'ThemeCategory', id: string, name?: string | undefined } | undefined } }> }, teamObjectives: { __typename: 'ObjectiveConnection', edges: Array<{ __typename: 'ObjectiveEdge', node: { __typename: 'Objective', id: string, name?: string | undefined, theme?: { __typename: 'Theme', id: string } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, complete: boolean, statusIndicator: StatusIndicatorClass } | undefined } }> } };

export type StrategyRoomManageObjectivesThemeFragment = { __typename: 'Theme', id: string, name?: string | undefined, isActive: boolean, description?: string | undefined, orderNumber?: number | undefined, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, themeCategory?: { __typename: 'ThemeCategory', id: string, name?: string | undefined } | undefined };

export type StrategyRoomManageObjectivesObjectiveFragment = { __typename: 'Objective', id: string, name?: string | undefined, theme?: { __typename: 'Theme', id: string } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, complete: boolean, statusIndicator: StatusIndicatorClass } | undefined };

export const StrategyRoomManageObjectivesThemeFragmentDoc = gql`
    fragment strategyRoomManageObjectivesTheme on Theme {
  id
  name
  isActive
  description
  orderNumber
  orgUnit {
    id
    name
  }
  themeCategory {
    id
    name
  }
}
    `;
export const StrategyRoomManageObjectivesObjectiveFragmentDoc = gql`
    fragment strategyRoomManageObjectivesObjective on Objective {
  id
  name
  theme {
    id
  }
  orgUnit {
    id
  }
  owner {
    ...userLimitedProfile
  }
  currentObjectiveStatus {
    id
    complete
    statusIndicator
  }
}
    ${UserLimitedProfileFragmentDoc}`;
export const StrategyRoomThemesManageObjectivesDocument = gql`
    query StrategyRoomThemesManageObjectives($themesOrgUnitIds: [ID!]!, $objectivesOrgUnitIdsFilter: IdInListFilterInput!) {
  orgThemes: allThemes(filter: {orgUnitIds: {operator: IS_NULL}}) {
    edges {
      node {
        ...strategyRoomManageObjectivesTheme
      }
    }
  }
  orgUnitThemes: allThemes(
    filter: {orgUnitIds: {operator: IN, values: $themesOrgUnitIds}}
  ) {
    edges {
      node {
        ...strategyRoomManageObjectivesTheme
      }
    }
  }
  teamObjectives: allObjectives(
    filter: {status: {isComplete: false}, orgUnitIds: $objectivesOrgUnitIdsFilter}
  ) {
    edges {
      node {
        ...strategyRoomManageObjectivesObjective
      }
    }
  }
}
    ${StrategyRoomManageObjectivesThemeFragmentDoc}
${StrategyRoomManageObjectivesObjectiveFragmentDoc}`;

/**
 * __useStrategyRoomThemesManageObjectivesQuery__
 *
 * To run a query within a React component, call `useStrategyRoomThemesManageObjectivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategyRoomThemesManageObjectivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategyRoomThemesManageObjectivesQuery({
 *   variables: {
 *      themesOrgUnitIds: // value for 'themesOrgUnitIds'
 *      objectivesOrgUnitIdsFilter: // value for 'objectivesOrgUnitIdsFilter'
 *   },
 * });
 */
export function useStrategyRoomThemesManageObjectivesQuery(baseOptions: Apollo.QueryHookOptions<StrategyRoomThemesManageObjectivesQuery, StrategyRoomThemesManageObjectivesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StrategyRoomThemesManageObjectivesQuery, StrategyRoomThemesManageObjectivesQueryVariables>(StrategyRoomThemesManageObjectivesDocument, options);
      }
export function useStrategyRoomThemesManageObjectivesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StrategyRoomThemesManageObjectivesQuery, StrategyRoomThemesManageObjectivesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StrategyRoomThemesManageObjectivesQuery, StrategyRoomThemesManageObjectivesQueryVariables>(StrategyRoomThemesManageObjectivesDocument, options);
        }
export type StrategyRoomThemesManageObjectivesQueryHookResult = ReturnType<typeof useStrategyRoomThemesManageObjectivesQuery>;
export type StrategyRoomThemesManageObjectivesLazyQueryHookResult = ReturnType<typeof useStrategyRoomThemesManageObjectivesLazyQuery>;
export type StrategyRoomThemesManageObjectivesQueryResult = Apollo.QueryResult<StrategyRoomThemesManageObjectivesQuery, StrategyRoomThemesManageObjectivesQueryVariables>;