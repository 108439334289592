import type { TypePolicies } from '@apollo/client';

import { readDateTimeField } from 'shared/graphql/utils';

import { readActionStatusIndicatorField } from './actions.typePolicies.utils';

export const actionTypePolicies: TypePolicies = {
  ActionStatus: {
    fields: {
      statusIndicator: {
        read: readActionStatusIndicatorField,
      },
      statusDateTime: {
        read: readDateTimeField,
      },
    },
  },
};
