import { useMemo } from 'react';
import { css } from '@emotion/react';

import Space from 'shared/components/Space';
import NoMatchingResultsResult from 'shared/components/NoMatchingResultsResult';

import { useStrategyBoard } from '../StrategyBoardProvider';
import StrategyBoardThemesSection from '../StrategyBoardThemeSection';

type StrategyBoardContentProps = {
  areAllSectionsExpanded?: boolean;
};

const StrategyBoardContent = ({
  areAllSectionsExpanded,
}: StrategyBoardContentProps) => {
  const { themes } = useStrategyBoard();

  const isEmpty = useMemo(
    () => themes.every(({ objectives }) => !objectives.length),
    [themes],
  );

  if (isEmpty) {
    return (
      <Space isCentered={true}>
        <NoMatchingResultsResult />
      </Space>
    );
  }

  return (
    <article css={css({ position: 'relative' })}>
      {themes.map((objectivesByTheme, index) => (
        <StrategyBoardThemesSection
          objectivesByTheme={objectivesByTheme}
          isExpanded={areAllSectionsExpanded}
          key={`${objectivesByTheme.theme?.id} + ${index}}`}
        />
      ))}
    </article>
  );
};

export default StrategyBoardContent;
