import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import MessageBubble from 'toolkit/WalkOfStrategyCampaignResponse/MessageBubble';
import UserAvatar from 'user/UserAvatar';
import { date } from 'shared/services/date.service';
import Text from 'shared/components/Text';
import type { User } from 'types.graphql.generated';

const Info = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 4px;
  margin-top: 4px;
`;

const Content = styled.div`
  display: flex;
  border-left: 1px solid ${(props) => props.theme.color.strokeMedium};
  margin-left: 10px;
  padding-left: 24px;
  padding-top: 6px;
  padding-bottom: 18px;
`;

type Comment = {
  commentDate: Date;
  content: string;
  id: string;
  user: Pick<User, 'id' | 'email' | 'displayName' | 'photoUrl' | 'isActive'>;
};

type Props = {
  comment: Comment;
};

const TimelineEntry = ({ comment }: Props) => {
  const { t } = useTranslation();

  const commentDate = date.formatShort(comment.commentDate);

  const userName = comment.user.displayName || comment.user.email;

  return (
    <li>
      <Info>
        <UserAvatar user={comment.user} hasLabel={false} size={'small'} />
        <span>
          {userName}{' '}
          <Text>
            {t('toolkit.toolPage.walkOfStrategy.results.comments.onDate', {
              date: commentDate,
            })}
          </Text>
        </span>
      </Info>
      <Content>
        <MessageBubble source={'remote'}>{comment.content}</MessageBubble>
      </Content>
    </li>
  );
};

export default TimelineEntry;
