import cn from 'classnames';
import type { AriaButtonProps } from 'react-aria';
import { useButton } from 'react-aria';
import type { ReactNode } from 'react';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'shared/components/Text';
import StatusMarks from 'shared/status/StatusMarks/StatusMarks';
import Space from 'shared/components/Space';

import styles from './ThemeButton.module.scss';
import type {
  ThemeButtonObjectiveStatus,
  ThemeButtonTheme,
} from './ThemeButton.type';

type ThemeButtonProps = AriaButtonProps & {
  className?: string;
  noThemeText?: string;
  objectiveStatuses?: ThemeButtonObjectiveStatus[];
  theme?: ThemeButtonTheme;
};

const ThemeButton = (props: ThemeButtonProps) => {
  const {
    theme,
    objectiveStatuses,
    noThemeText,
    className,
    ...ariaButtonProps
  } = props;

  const { t } = useTranslation();

  const buttonElementRef = useRef<HTMLButtonElement>(null);

  const { buttonProps } = useButton(
    {
      type: 'button',
      ...ariaButtonProps,
    },
    buttonElementRef,
  );

  const name = useMemo<ReactNode>(
    () => (
      <Text
        variant={'emphasis'}
        ellipsis={true}
        className={styles.themeChipName}
      >
        {theme ? theme.name : noThemeText || t('theme.noTheme')}
      </Text>
    ),
    [t, theme, noThemeText],
  );

  const statusMarks = useMemo<ReactNode>(() => {
    if (objectiveStatuses?.length) {
      return (
        <StatusMarks
          items={objectiveStatuses.map((objectiveStatus) => ({
            statusIndicator: objectiveStatus.statusIndicator,
            updateDateTime: objectiveStatus.auditRecord?.updateDateTime,
          }))}
          size={'small'}
        />
      );
    }
  }, [objectiveStatuses]);

  return (
    <button
      {...buttonProps}
      className={cn(
        styles.themeButton,
        { [styles.themeButtonPressed]: buttonProps['aria-pressed'] },
        className,
      )}
      ref={buttonElementRef}
    >
      <Space className={styles.themeButtonContent}>
        {name}
        {statusMarks}
      </Space>
    </button>
  );
};

export default ThemeButton;
