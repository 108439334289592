import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import ToolIconHeader from 'toolkit/ToolIconHeader';
import Text from 'shared/components/Text';
import Flex from 'shared/components/Flex';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';

import type { OneThingTaskForUpdate } from './OneThingCreateTaskButton.types';

const ObjectiveName = styled.a`
  color: ${(props) => props.theme.color.primary};
  text-decoration: underline;
`;

type Props = {
  isReadOnly: boolean;
  task: OneThingTaskForUpdate;
};

const DialogHeader = ({ task, isReadOnly }: Props) => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();

  return (
    <Flex direction={'column'} gap={'0.5rem'}>
      <ToolIconHeader tool={'oneThing'} variant={'label'} />
      <Text color={'black'} css={css({ fontSize: '1.8rem' })}>
        {task.task}
      </Text>
      <div>
        <Text>
          {t('toolkit.toolPage.oneThing.updateTask.subtitle.objective')}
          {': '}
          <ObjectiveName
            href={objectiveRoutesPaths.root({
              params: {
                teamSlug: teamAdapter.toParam(),
                objectiveId: task.objective.id,
              },
            })}
          >
            {task.objective.name}
          </ObjectiveName>
          {'.'}
        </Text>
        {isReadOnly || (
          <>
            <br />
            <Text>
              {t('toolkit.toolPage.oneThing.updateTask.subtitle.leaderNotice')}
            </Text>
          </>
        )}
      </div>
    </Flex>
  );
};

export default DialogHeader;
