import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';

import Descriptions from 'shared/components/Descriptions';

import type { KeyIndicatorsProps } from '../KeyIndicators';

export type KeyIndicatorsListProps = Omit<KeyIndicatorsProps, 'variant'>;

const KeyIndicatorsList = ({
  keyIndicatorOnBudget,
  keyIndicatorOnOutcome,
  keyIndicatorOnTime,
  className,
}: KeyIndicatorsListProps) => {
  const { t } = useTranslation();

  const renderKeyIndicator = (name: string, value?: boolean | null) => {
    const children = (() => {
      if (isNil(value)) {
        return t('noData');
      }
      return t(value ? 'yes' : 'no');
    })();
    return (
      <Descriptions.Item
        label={t(`initiative.keyIndicators.${name}.description`)}
      >
        {children}
      </Descriptions.Item>
    );
  };

  return (
    <Descriptions className={className}>
      {renderKeyIndicator('onBudget', keyIndicatorOnBudget)}
      {renderKeyIndicator('onTime', keyIndicatorOnTime)}
      {renderKeyIndicator('onOutcome', keyIndicatorOnOutcome)}
    </Descriptions>
  );
};

export default KeyIndicatorsList;
