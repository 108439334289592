import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { useState } from 'react';

import Layout from 'shared/components/Layout';
import Spinner from 'shared/spinner/Spinner';
import Heading from 'shared/components/Heading';
import { useTeamAdapter } from 'team/TeamAdapter';
import Flex from 'shared/components/Flex';
import CampaignDetailsSubheader from 'toolkit/CampaignDetailsSubheader';
import CampaignStageTag from 'toolkit/CampaignStageTag/CampaignStageTag';
import CampaignContextMenu from 'toolkit/OneThingCampaigns/CampaignContextMenu';
import type { ProgressStatus } from 'types.graphql.generated';

import { useOneThingCampaignResultsQuery } from './OneThingCampaignResults.graphql.generated';
import { getBreadcrumbItems } from './OneThingCampaignResults.utils';
import OverviewSection from './OverviewSection';
import ObjectivesSection from './ObjectivesSection';
import TasksSection from './TasksSection';
import TasksSectionStatusFilter from './TasksSectionStatusFilter';

const Section = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.color.strokeLight};
  border-radius: 1.333rem;
  overflow: hidden;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 1.333rem;
`;

const OneThingCampaignResults = () => {
  const { campaignId } = useParams<{ campaignId: string }>();

  if (!campaignId) throw new Error('campaign id is not present');

  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();

  const [taskStatuses, setTaskStatuses] = useState<ProgressStatus[]>([
    'NOT_STARTED',
    'IN_PROGRESS',
    'DONE',
  ]);

  const { data } = useOneThingCampaignResultsQuery({
    variables: { campaignId },
  });

  if (!data) return <Spinner.Circle />;

  const campaign = data.oneThingCampaign;

  return (
    <>
      <Layout.Header
        heading={campaign.name}
        description={
          <CampaignDetailsSubheader
            orgUnitName={campaign.orgUnit?.name}
            startDate={campaign.timeLine.startDate}
            deadline={campaign.deadline}
            endDate={campaign.timeLine.endDate}
            hideTimeline={true}
          />
        }
        breadcrumbsItems={getBreadcrumbItems(teamAdapter, t, campaign.name)}
      >
        <CampaignStageTag stage={campaign.state.stage} />
        <CampaignContextMenu
          campaignId={campaign.id}
          campaignStage={campaign.state.stage}
        />
      </Layout.Header>
      <Layout.Content hasPadding={false} hasBorder={false}>
        <Flex direction={'column'} gap={'1.333rem'}>
          <Section>
            <HeadingContainer>
              <Heading level={4} hasMargin={false}>
                {t('toolkit.toolPage.oneThing.campaignResults.overview.title')}
              </Heading>
            </HeadingContainer>
            <OverviewSection campaign={campaign} />
          </Section>
          <Section>
            <HeadingContainer>
              <Heading level={4} hasMargin={false}>
                {t(
                  'toolkit.toolPage.oneThing.campaignResults.objectives.title',
                )}
              </Heading>
            </HeadingContainer>
            <ObjectivesSection campaign={campaign} />
          </Section>
          <Section>
            <HeadingContainer>
              <Heading level={4} hasMargin={false}>
                {t('toolkit.toolPage.oneThing.campaignResults.tasks.title')}
              </Heading>
              <TasksSectionStatusFilter
                values={taskStatuses}
                setValues={setTaskStatuses}
              />
            </HeadingContainer>
            <TasksSection campaign={campaign} statuses={taskStatuses} />
          </Section>
        </Flex>
      </Layout.Content>
    </>
  );
};

export default OneThingCampaignResults;
