import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { useToasts } from 'shared/toast/useToasts';

import { useSendStatusRequestMutation } from './SendStatusRequest.graphql.generated';

export type StrategyElement = {
  __typename:
    | 'Objective'
    | 'ChildObjective'
    | 'Metric'
    | 'Initiative'
    | 'Action';
  id: string;
  isCurrentUserOwner: boolean;
};

const elementKeyByType = {
  Objective: 'objectiveIds',
  ChildObjective: 'objectiveIds',
  Metric: 'metricIds',
  Initiative: 'initiativeIds',
  Action: 'actionIds',
};

export default function useSendStatusRequest() {
  const { t } = useTranslation();
  const onError = useHandleApolloError();
  const { addToast } = useToasts();

  const [
    triggerSendStatusRequestMutation,
    { loading: isSendingStatusRequest },
  ] = useSendStatusRequestMutation({
    onCompleted: () =>
      addToast({
        variant: 'success',
        children: t('statusRequest.toast.success'),
      }),
    onError,
  });

  const sendStatusRequest = useCallback(
    (strategyElement: StrategyElement) =>
      triggerSendStatusRequestMutation({
        variables: {
          elements: {
            [elementKeyByType[strategyElement.__typename]]: [
              strategyElement.id,
            ],
          },
        },
      }),
    [triggerSendStatusRequestMutation],
  );

  return useMemo(
    () => ({
      isSendingStatusRequest,
      sendStatusRequest,
    }),
    [isSendingStatusRequest, sendStatusRequest],
  );
}
