import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useListState } from 'react-stately';

import type { MultiSelectProps } from 'shared/components/MultiSelect';
import { useListBoxCollectionChildren } from 'shared/components/ListBox';

import type { ActionStatusMultiSelectProviderContextValue } from './ActionStatusMultiSelectProvider.context';
import { ActionStatusMultiSelectProviderContext } from './ActionStatusMultiSelectProvider.context';
import ActionStatusMultiSelectListBoxRow from '../ActionStatusMultiSelectListBoxRow';
import type { ActionStatusMultiSelectItem } from '../ActionStatusMultiSelect.type';
import { getActionMultiSelectIds } from '../ActionStatusMultiSelect.utils';

type ActionStatusMultiSelectProviderProps = PropsWithChildren<
  Pick<
    MultiSelectProps<ActionStatusMultiSelectItem>,
    'onSelectionChange' | 'selectedKeys'
  >
>;

const ActionStatusMultiSelectProvider = ({
  children,
  onSelectionChange,
  selectedKeys,
}: ActionStatusMultiSelectProviderProps) => {
  const items = useMemo<ActionStatusMultiSelectItem[]>(
    () => getActionMultiSelectIds().map((id) => ({ id })),
    [],
  );

  const collectionChildren = useListBoxCollectionChildren({
    Row: ActionStatusMultiSelectListBoxRow,
  });
  const state = useListState<ActionStatusMultiSelectItem>({
    items,
    selectionMode: 'multiple',
    children: collectionChildren,
    selectedKeys,
    onSelectionChange,
  });

  const contextValue = useMemo<ActionStatusMultiSelectProviderContextValue>(
    () => ({
      state,
      items,
    }),
    [state, items],
  );

  return (
    <ActionStatusMultiSelectProviderContext.Provider value={contextValue}>
      {children}
    </ActionStatusMultiSelectProviderContext.Provider>
  );
};

export default ActionStatusMultiSelectProvider;
