import { createRoutePath } from 'shared/utils/router';

export const metricOverviewRoutesPaths = {
  root: createRoutePath('/'),
  attributes: createRoutePath('attributes'),
  edit: createRoutePath('edit'),
  insights: {
    root: createRoutePath('insights'),
    submit: createRoutePath<never, { completeStatus?: string }>('submit'),
  },
};
