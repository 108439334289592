import type { TypePolicies } from '@apollo/client';

import { readDateTimeField } from 'shared/graphql/utils';
import { readStatusIndicatorField } from 'shared/status/StatusIndicator';

export const initiativeTypePolicies: TypePolicies = {
  InitiativeStatus: {
    fields: {
      statusIndicator: {
        read: readStatusIndicatorField,
      },
      statusDateTime: readDateTimeField,
    },
  },
  MilestoneStatus: {
    fields: {
      statusIndicator: {
        read: (value, { readField }) => {
          const isCompleted = readField('completionRate') === 100;

          return readStatusIndicatorField(value, { readField }, isCompleted);
        },
      },
    },
  },
};
