import type { ListState } from 'react-stately';
import { createContext } from 'react';

import type { UserMultiSelectItem } from '../UserMultiSelect.type';

export type UserMultiSelectProviderContextValue = {
  items: UserMultiSelectItem[];
  state: ListState<UserMultiSelectItem>;
};

export const UserMultiSelectProviderContext =
  createContext<Maybe<UserMultiSelectProviderContextValue>>(undefined);
