import type { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { useAuth } from 'auth/AuthProvider';
import { authRoutesPaths } from 'auth/auth.routing.paths';
import GlobalSpinner from 'shared/spinner/GlobalSpinner';
import { useRedirectAfterSignUp } from 'auth/useRedirectAfterSignUp';
import { useUserValidation } from 'user/UserValidationProvider/useUserValidation';

type AuthGuardProps = PropsWithChildren<{
  forGuestOnly?: boolean;
  forLoggedInOnly?: boolean;
}>;

const AuthGuard = ({
  forLoggedInOnly,
  forGuestOnly,
  children,
}: AuthGuardProps) => {
  const location = useLocation();
  const { user, isLoading } = useAuth();
  const { isValidated } = useUserValidation();
  const { setRedirectAfterSignupPathname } = useRedirectAfterSignUp();

  useEffect(() => {
    if (!isLoading && forLoggedInOnly && !user) {
      setRedirectAfterSignupPathname(location.pathname);
    }
  }, [
    user,
    isLoading,
    forLoggedInOnly,
    setRedirectAfterSignupPathname,
    location.pathname,
  ]);

  const isValidatingUser = user && !isValidated;

  if (isLoading || isValidatingUser) {
    return <GlobalSpinner />;
  }

  if (forGuestOnly && user) {
    return <Navigate to={'/'} />;
  }

  if (forLoggedInOnly && !user) {
    return <Navigate to={authRoutesPaths.signUp()} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
