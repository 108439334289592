import type { TFunction } from 'react-i18next';

const DOTS = '...';

export const shortenEmail = (email?: string, maxLength = 30) => {
  if (email && email.length > maxLength) {
    const emailSignIndex = email.indexOf('@');
    const username = email.slice(0, emailSignIndex);
    const domain = email.slice(emailSignIndex, email.length);

    const usernameMaxLength = maxLength - domain.length;

    const numberOfCharsToReplace =
      Math.abs(usernameMaxLength - username.length) + DOTS.length;

    const startIndex = (username.length - numberOfCharsToReplace) / 2;
    const endIndex = Math.ceil(startIndex);

    const substringToReplace = username.slice(startIndex, -endIndex);

    return email.replace(substringToReplace, DOTS);
  }

  return email;
};

export const removeTrailingDot = (str: string) => str.replace(/\.$/, '');

export const hasContent = (str?: string) =>
  str !== undefined && str !== null && str !== '';

export const enumerate = (t: TFunction, items: any[], forceComma = false) => {
  const presentItems = items.filter(Boolean);

  return presentItems.reduce<string[]>((result, item, index) => {
    const isFirstItem = index === 0;
    const isLastItem = index === presentItems.length - 1;

    const maybeComma = forceComma ? ',' : '';
    const junction = isFirstItem
      ? ''
      : isLastItem
      ? `${maybeComma} ${t('and')} `
      : ', ';

    return [...result, junction, item];
  }, []);
};
