import styles from './StrategyCardItem.module.scss';

type StrategyCardItem = {
  description?: string;
  items?: StrategyCardItem[];
  title?: string;
};

export type StrategyCardItemProps = {
  description?: string;
  items?: Array<{
    description?: string;
    items?: StrategyCardItem[];
    title?: string;
  }>;
  title?: string;
};

const StrategyCardItem = ({
  title,
  description,
  items,
}: StrategyCardItemProps) => (
  <div className={styles.container}>
    {title && (
      <div className={styles.title}>
        <span className={styles.titleText} role={'heading'} aria-level={3}>
          {title}
        </span>
      </div>
    )}
    {description ? (
      <div className={styles.desc}>
        <span className={styles.descText} role={'heading'} aria-level={4}>
          {description}
        </span>
      </div>
    ) : null}
    <ul className={styles.list}>
      {items?.length
        ? items.map((item) => (
            <li key={`${item.title}${item.description}`}>
              <StrategyCardItem {...item} />
            </li>
          ))
        : null}
    </ul>
  </div>
);

export default StrategyCardItem;
