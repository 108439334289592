import { css } from '@emotion/react';
import hexToRgba from 'hex-to-rgba';

import type { Theme } from 'theme';

export const getDefaultModalContainerStyle = (theme: Theme) =>
  css({
    height: '100vh',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100vw',
    zIndex: 5000,
    backgroundColor: hexToRgba(theme.color.black, 0.5),
    overflow: 'hidden',
  });
