import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { getObjectivesActionsWithParentObjective } from 'actions/action.utils';
import type { ActionsObjectiveFragment } from 'actions/actions.fragment.graphql.generated';
import Space from 'shared/components/Space';
import Result from 'shared/components/Result';
import { ReactComponent as EmptyIllustration } from 'shared/static/illustrations/illustration-empty-state.svg';

import ActionsListSection from './ActionsListSection';

type ActionsGridList = {
  areAllSectionsExpanded?: boolean;
  objectives: Maybe<ActionsObjectiveFragment[]>;
};

const ActionsList = ({
  objectives,
  areAllSectionsExpanded,
}: ActionsGridList) => {
  const { t } = useTranslation();

  const objectivesActions = getObjectivesActionsWithParentObjective(objectives);

  const hasObjectives = !!objectivesActions?.length;

  const hasMoreThanOneObjective = objectivesActions?.length >= 2;

  return (
    <>
      {hasObjectives ? (
        <>
          {objectivesActions.map((objective) => (
            <ActionsListSection
              objective={objective}
              key={objective.id}
              hasHeader={hasMoreThanOneObjective}
              expanded={areAllSectionsExpanded}
            />
          ))}
        </>
      ) : (
        <Space isCentered={true}>
          <Result
            description={t('actions.empty')}
            illustration={EmptyIllustration}
            hasBackground={true}
          />
        </Space>
      )}
      <Outlet />
    </>
  );
};

export default ActionsList;
