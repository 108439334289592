import toPath from 'lodash/toPath';

/**
 * Gets value for key in object at arbitrarily nested keypath
 * @param {Object} object - the object
 * @param {string|Array<string>} key - the potentially nested keypath
 * @return {unknown} - the value(s) at the nested keypath
 */
export const getItemValues = (
  object: any,
  key: string | string[],
  defaultValue?: unknown,
  p = 0,
) => {
  const path = toPath(key);
  while (object && p < path.length) {
    // eslint-disable-next-line no-param-reassign
    object = object[path[p++]];
  }
  return object === undefined ? defaultValue : object;
};
