import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { useToasts } from 'shared/toast/useToasts';
import Dialog from 'shared/components/Dialog';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import UserProfileForm from 'user/UserProfileForm';
import type { UserInput } from 'types.graphql.generated';
import { userFormId } from 'user/UserProfileForm/UserProfileForm';
import {
  TeamMembersFromOrgDocument,
  TeamMembersFromOrgUnitDocument,
} from 'team/TeamMembers/TeamMembersProvider/TeamMembersProvider.graphql.generated';

import { useCreateUserProfileMutation } from './CreateUserProfile.graphql.generated';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const CreateUserProfileModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const handleApolloError = useHandleApolloError();

  const [updateUserProfile] = useCreateUserProfileMutation({
    onError: handleApolloError,
    refetchQueries: [
      TeamMembersFromOrgDocument,
      TeamMembersFromOrgUnitDocument,
    ],
  });

  const handleSubmit = useCallback(
    async (input: UserInput) => {
      const result = await updateUserProfile({
        variables: { input },
      });

      if (!result.errors) {
        addToast({
          children: t('user.createProfile.toast.success'),
          variant: 'success',
        });
        onClose();
      }
    },
    [addToast, onClose, t, updateUserProfile],
  );

  const initialValues = {
    email: '',
    photoUrl: '',
    displayName: '',
    authorities: [],
    orgUnit: {},
  };

  return (
    <Dialog
      headerTitle={t('user.createProfile.title')}
      isOpen={isOpen}
      onClose={onClose}
      confirmLabel={t('create')}
      cancelLabel={t('cancel')}
      size={'small'}
      formId={userFormId}
    >
      <UserProfileForm initialValues={initialValues} onSubmit={handleSubmit} />
    </Dialog>
  );
};

export default CreateUserProfileModal;
