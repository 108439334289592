import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { strategyRoutesPaths } from 'strategy/strategy.routing.paths';

import type { InitiativesContentLayoutContextValue } from './InitiativesContentLayoutProvider.context';
import { InitiativesContentLayoutContext } from './InitiativesContentLayoutProvider.context';
import { initiativesContentRoutesPaths } from '../InitiativesContent.routing';

type InitiativesContentLayoutProviderProps = PropsWithChildren<object>;

const InitiativesContentLayoutProvider = ({
  children,
}: InitiativesContentLayoutProviderProps) => {
  const navigate = useNavigate();

  const isMatchStrategyList = useMatch(strategyRoutesPaths.initiatives.list());

  const isMatchObjectiveList = useMatch(
    objectiveRoutesPaths.initiatives.initiative.list(),
  );

  const isMatchList = isMatchStrategyList || isMatchObjectiveList;

  const contextValue = useMemo<InitiativesContentLayoutContextValue>(
    () => ({
      layout: isMatchList
        ? initiativesContentRoutesPaths.list()
        : initiativesContentRoutesPaths.calendar(),
      toggleLayout: isMatchList
        ? () => navigate(initiativesContentRoutesPaths.calendar())
        : () => navigate(initiativesContentRoutesPaths.list()),
    }),
    [isMatchList, navigate],
  );

  return (
    <InitiativesContentLayoutContext.Provider value={contextValue}>
      {children}
    </InitiativesContentLayoutContext.Provider>
  );
};

export default InitiativesContentLayoutProvider;
