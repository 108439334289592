import { useFilePicker } from 'use-file-picker';
import { FileSizeValidator } from 'use-file-picker/validators';
import type { SelectedFiles } from 'use-file-picker/dist/interfaces';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import type { StorageObjectType } from 'types.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';

import { useGenerateUploadUrlMutation } from './GenerateUploadUrl.graphql.generated';

type Args = {
  accept: string;
  maxFileSize?: number; // Mb
  objectType: StorageObjectType;
  onFileUploaded: (fileUrl: string) => any | Promise<any>;
};

type Result = {
  openFilePicker: () => void;
};

const useFileUpload = ({
  accept,
  objectType,
  onFileUploaded,
  maxFileSize = 1, // 2Mb
}: Args): Result => {
  const { activeOrg } = useActiveOrg();

  const [generateUploadUrl] = useGenerateUploadUrlMutation();

  const { addToast } = useToasts();
  const { t } = useTranslation();

  const showError = useCallback(
    (errorMessage?: string) => {
      addToast({
        variant: 'error',
        children: errorMessage || t(`fileUpload.error.generic`),
      });
    },
    [addToast, t],
  );

  const { openFilePicker } = useFilePicker({
    multiple: false,
    accept,
    readFilesContent: true,
    readAs: 'DataURL',
    validators: [
      new FileSizeValidator({ maxFileSize: maxFileSize * 1024 * 1024 }),
    ],
    onFilesRejected: () =>
      showError(t('fileUpload.error.size', { maxFileSize })),
    onFilesSuccessfullySelected: async (files: SelectedFiles<string>) => {
      try {
        const file = files.plainFiles[0];

        const generateUploadUrlResult = await generateUploadUrl({
          variables: {
            orgKey: activeOrg.orgKey,
            objectType,
            fileName: file.name,
          },
        });

        if (!generateUploadUrlResult.data) {
          showError();
          return;
        }

        const { fileUrl, signedUrl } =
          generateUploadUrlResult.data.generateUploadUrl;

        const uploadToSignedUrlResponse = await fetch(signedUrl, {
          mode: 'cors',
          method: 'PUT',
          credentials: 'omit',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        });

        if (!uploadToSignedUrlResponse.ok) {
          showError();
          return;
        }

        await onFileUploaded(fileUrl);

        addToast({
          variant: 'success',
          children: t('fileUpload.success'),
        });
      } catch (error) {
        showError();
      }
    },
  });

  return { openFilePicker };
};

export default useFileUpload;
