import { useCallback, useEffect, useState } from 'react';

const useTimeout = (ms: number) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!isReady) {
      const handler = setTimeout(() => {
        setIsReady(true);
      }, ms);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [isReady, ms]);

  const reset = useCallback(() => {
    setIsReady(false);
  }, []);

  return { isReady, reset };
};

export default useTimeout;
