import { useMemo, type PropsWithChildren } from 'react';
import { sortBy } from 'lodash';
import type { TFunction } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import type {
  Objective,
  Org,
  OrgUnit,
  StrategyElementAuthorizedActions,
  Theme,
} from 'types.graphql.generated';
import type { TeamAdapter } from 'team/TeamAdapter';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';

import type { SelectObjectiveInputContextValue } from '../SelectObjectiveInput.context';
import { SelectObjectiveInputContext } from '../SelectObjectiveInput.context';
import type {
  ObjectiveSelectObjectiveFragment,
  ObjectiveSelectOrgUnitFragment,
} from './SelectObjectiveInputProvider.graphql.generated';
import { useSelectObjectiveInputProviderQuery } from './SelectObjectiveInputProvider.graphql.generated';

type Props = PropsWithChildren<{
  filterByAction?: StrategyElementAuthorizedActions;
  objectiveToExclude?: Objective['id'];
  teamAdapter: TeamAdapter;
}>;

const SelectObjectiveInputProvider = ({
  objectiveToExclude,
  filterByAction,
  children,
}: Props) => {
  const { t } = useTranslation();
  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const handleApolloError = useHandleApolloError();

  const { data } = useSelectObjectiveInputProviderQuery({
    fetchPolicy: 'cache-and-network',
    onError: handleApolloError,
  });

  const contextValue = useMemo<SelectObjectiveInputContextValue>(() => {
    if (!data) return { options: [] };

    const orgUnits = data.allOrgUnits.edges.map((edge) => edge.node);
    const priorityOrgUnitId = orgUnit?.id;
    const priorityOrgUnits = getOrgUnitParentTree(orgUnits, priorityOrgUnitId);

    const topLevelOrgUnits = orgUnits.filter(
      (orgUnit) => !orgUnit.parentOrgUnit,
    );

    const orgUnitsSortedByLevel = sortOrgUnitsByLevel(
      orgUnits,
      topLevelOrgUnits,
    );

    const objectives = data.allObjectives.edges
      .map((edge) => edge.node)
      .filter((objective) => objective.id !== objectiveToExclude)
      .filter(
        (objective) =>
          !filterByAction ||
          canPerformStrategyElementAction(objective, filterByAction),
      );

    const sortedObjectives = sortBy(objectives, (objective) =>
      objective.name?.toLowerCase(),
    );

    const themes = objectives.reduce<
      Record<Theme['id'], Pick<Theme, 'id' | 'name' | 'orderNumber'>>
    >(
      (themesById, objective) =>
        objective.theme && !themesById[objective.theme.id]
          ? { ...themesById, [objective.theme.id]: objective.theme }
          : themesById,
      {},
    );

    const sortedThemes = sortBy(
      Object.values(themes),
      (theme) => theme.orderNumber,
    );

    const priorityOrgUnitObjectiveOptions = priorityOrgUnits.flatMap(
      (orgUnit) =>
        getOptionsForOrgUnit(
          t,
          activeOrg,
          sortedThemes,
          sortedObjectives.filter(
            (objective) => objective.orgUnit?.id === orgUnit.id,
          ),
          orgUnit,
        ),
    );

    const orgLevelObjectiveOptions = getOptionsForOrgUnit(
      t,
      activeOrg,
      sortedThemes,
      sortedObjectives.filter((objective) => !objective.orgUnit),
    );

    const orgUnitOptions = orgUnitsSortedByLevel
      .filter((orgUnit) => !priorityOrgUnits.includes(orgUnit))
      .flatMap((orgUnit) =>
        getOptionsForOrgUnit(
          t,
          activeOrg,
          sortedThemes,
          sortedObjectives.filter(
            (objective) => objective.orgUnit?.id === orgUnit.id,
          ),
          orgUnit,
        ),
      );

    return {
      options: [
        { value: undefined },
        ...priorityOrgUnitObjectiveOptions,
        ...orgLevelObjectiveOptions,
        ...orgUnitOptions,
      ].filter(Boolean),
    };
  }, [activeOrg, data, filterByAction, objectiveToExclude, orgUnit?.id, t]);

  return (
    <SelectObjectiveInputContext.Provider value={contextValue}>
      {children}
    </SelectObjectiveInputContext.Provider>
  );
};

const getOrgUnitParentTree = (
  allOrgUnits: ObjectiveSelectOrgUnitFragment[],
  childOrgUnitId?: OrgUnit['id'],
): ObjectiveSelectOrgUnitFragment[] => {
  const childOrgUnit = allOrgUnits.find(
    (orgUnit) => orgUnit.id === childOrgUnitId,
  );

  return childOrgUnit
    ? [
        childOrgUnit,
        ...getOrgUnitParentTree(allOrgUnits, childOrgUnit.parentOrgUnit?.id),
      ]
    : [];
};

const getOptionsForOrgUnit = (
  t: TFunction,
  org: Pick<Org, 'displayName' | 'domainNames'>,
  allThemes: Pick<Theme, 'id' | 'name'>[],
  objectives: ObjectiveSelectObjectiveFragment[],
  orgUnit?: Pick<OrgUnit, 'id' | 'name'>,
) => {
  const themesWithNoTheme = [...allThemes, undefined];

  return themesWithNoTheme
    .map((theme) => getOptionsForTheme(t, org, objectives, orgUnit, theme))
    .filter(Boolean);
};

const getOptionsForTheme = (
  t: TFunction,
  org: Pick<Org, 'displayName' | 'domainNames'>,
  allObjectives: ObjectiveSelectObjectiveFragment[],
  orgUnit?: Pick<OrgUnit, 'id' | 'name'>,
  theme?: Pick<Theme, 'id' | 'name'>,
) => {
  const themeObjectives = allObjectives.filter(
    (objective) => objective.theme?.id === theme?.id,
  );

  if (themeObjectives.length === 0) return undefined;

  const orgUnitName = orgUnit
    ? orgUnit.name
    : org.displayName || org.domainNames[0];

  const themeName = theme ? theme.name : t('theme.noTheme');

  return {
    label: `${orgUnitName} / ${themeName}`,
    options: themeObjectives.map((objective) => ({
      label: objective.name,
      value: {
        id: objective.id,
        name: objective.name,
      },
    })),
  };
};

const sortOrgUnitsByLevel = (
  allOrgUnits: ObjectiveSelectOrgUnitFragment[],
  parentOrgUnits: ObjectiveSelectOrgUnitFragment[],
): ObjectiveSelectOrgUnitFragment[] => {
  const sortedParentOrgUnits = sortBy(parentOrgUnits, (orgUnit) =>
    orgUnit.name?.toLowerCase(),
  );

  const currentOrgUnitIds = parentOrgUnits.map((orgUnit) => orgUnit.id);

  const childOrgUnits = allOrgUnits.filter(
    (orgUnit) =>
      orgUnit.parentOrgUnit &&
      currentOrgUnitIds.includes(orgUnit.parentOrgUnit.id),
  );

  const sortedChildOrgUnits =
    childOrgUnits.length > 0
      ? sortOrgUnitsByLevel(allOrgUnits, childOrgUnits)
      : [];

  return [...sortedParentOrgUnits, ...sortedChildOrgUnits];
};

export default SelectObjectiveInputProvider;
