import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import type { BreadcrumbsItem } from '../Breadcrumbs.type';

type BreadcrumbProps = {
  isFirstItem: boolean;
  isLinkMatched: boolean;
  item: BreadcrumbsItem;
};

const BreadcrumbLi = styled(motion.li)`
  &:last-of-type:not(:first-of-type) a {
    color: ${(props) => props.theme.color.neutral2};
  }
`;

const BreadcrumbLink = styled(Link)<{
  isFirstItem: boolean;
  isLinkMatched: boolean;
}>`
  text-decoration: none;

  &:focus {
    outline: none;
  }

  color: ${(props) => props.theme.color.typoPrimary};
  cursor: pointer;
  font-size: 0.857rem;
  user-select: none;

  ${(props) =>
    props.isLinkMatched &&
    !props.isFirstItem &&
    css`
      color: ${props.theme.color.neutral2};
    `}

  ${(props) =>
    !props.isFirstItem &&
    css`
      &::before {
        content: '/';
        display: inline-block;
        margin: 0 4px;
      }
    `}

  &:hover {
    color: ${(props) => props.theme.color.neutral1};
  }
`;

const Breadcrumb = ({ isFirstItem, isLinkMatched, item }: BreadcrumbProps) => (
  <BreadcrumbLi
    animate={{ x: 0, opacity: 1 }}
    initial={{ x: isFirstItem ? 0 : -8, opacity: 0 }}
    transition={{ type: 'spring', duration: 0.35, bounce: 0 }}
  >
    <BreadcrumbLink
      to={item.to || '.'}
      isLinkMatched={isLinkMatched}
      isFirstItem={isFirstItem}
    >
      {item.name}
    </BreadcrumbLink>
  </BreadcrumbLi>
);

export default Breadcrumb;
