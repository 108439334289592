import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import type { ReactNode } from 'react';

import Caption from 'shared/components/Caption';

import styles from './InsightsComment.module.scss';

type InsightsCommentProps = {
  className?: string;
  comment: Maybe<string | ReactNode>;
  title?: string;
};

const InsightsComment = ({
  title,
  comment,
  className,
}: InsightsCommentProps) => {
  const { t } = useTranslation();

  if (!comment || (typeof comment === 'string' && comment.trim().length === 0))
    return null;

  return (
    <section className={cn(styles.section, className)}>
      <Caption size={'C2'} className={styles.caption}>
        {title ?? t(`objective.insights.recentInsights.comment.heading`)}
      </Caption>
      <span className={styles.comment}>{comment}</span>
    </section>
  );
};

export default InsightsComment;
