import { createContext } from 'react';

import type { ItemSize } from '../List.type';

export type ListProviderContextValue = {
  itemSize: ItemSize;
  setItemSize: (value: ItemSize) => void;
};

export const ListProviderContext = createContext<
  ListProviderContextValue | undefined
>(undefined);
