import { useMedia } from 'react-use';

import { breakpoints } from 'shared/config/breakpoints';

export const useViewport = () => {
  const isTabletOrBigger = useMedia(`(min-width: ${breakpoints.tablet}px)`);
  const isDesktopOrBigger = useMedia(`(min-width: ${breakpoints.desktop}px)`);

  return {
    isTabletOrBigger,
    isDesktopOrBigger,
  };
};
