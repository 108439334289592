import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash';

import { date } from 'shared/services/date.service';
import Card from 'shared/components/Card';
import Text from 'shared/components/Text';
import Space from 'shared/components/Space';
import { useTeamAdapter } from 'team/TeamAdapter';

import type { StrategyRoadmapSideModalProps } from '../StrategyRoadmapSideModal';
import { illustrations, backgroundColors } from '../StrategyRoadmap.static';
import StrategyRoadmapSideModal from '../StrategyRoadmapSideModal';
import type { StrategyRoadmapStrategyFieldsFragment } from '../StrategyRoadmapProvider/StrategyRoadmapProvider.graphql.generated';
import { getEditStrategyPath } from '../StrategyRoadmap.utils';

type StrategyRoadmapGoalsModalProps = {
  showEditButton: boolean;
  strategy: StrategyRoadmapStrategyFieldsFragment;
} & Pick<StrategyRoadmapSideModalProps, 'isOpen' | 'onClose'>;

const StrategyRoadmapGoalsModal = ({
  strategy,
  isOpen,
  onClose,
  showEditButton,
}: StrategyRoadmapGoalsModalProps) => {
  const { goals } = strategy;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { teamAdapter } = useTeamAdapter();

  const content = useMemo(
    () => (
      <Space direction={'vertical'}>
        {goals.map((goal) => (
          <Card
            border={'full'}
            padding={'small'}
            hasBackground={true}
            key={goal.id}
          >
            <Card.Heading>{goal.name}</Card.Heading>
            <Text variant={'emphasis'} whiteSpace={'preWrap'}>
              {goal.description}
            </Text>
          </Card>
        ))}
      </Space>
    ),
    [goals],
  );

  const updateDateTime = date.max(
    goals.map((goal) => goal.auditRecord.updateDateTime),
  );

  const handleEdit = useCallback(() => {
    onClose();
    navigate(getEditStrategyPath(teamAdapter, strategy, 'goals'));
  }, [onClose, navigate, teamAdapter, strategy]);

  return (
    <>
      <StrategyRoadmapSideModal
        top={{
          illustration: illustrations.goals,
          backgroundColor: backgroundColors.goals,
        }}
        heading={capitalize(t('strategy.goal_other'))}
        description={t('strategy.strategyRoadmap.goals.modal.description')}
        updateDateTime={updateDateTime}
        onEdit={showEditButton ? handleEdit : undefined}
        isOpen={isOpen}
        onClose={onClose}
      >
        {content}
      </StrategyRoadmapSideModal>
    </>
  );
};

export default StrategyRoadmapGoalsModal;
