import cn from 'classnames';
import type { CSSProperties } from 'react';

import type { FlexibleTileColumn } from './RoadmapitemBackgroundTileFlexible.type';
import styles from './RoadmapItemBackgroundTileFlexible.module.scss';

const backgroundTileHeight = parseInt(styles.backgroundTileHeightSize);

export const getColumnClassName = (column?: FlexibleTileColumn) => {
  if (column) {
    return cn(
      styles[`containerColumn${column}`],
      styles.containerColumnSpecified,
    );
  }
  return undefined;
};

export const getContainerMinHeightForSeamlessBackground = (
  initialHeight?: number,
): string | undefined => {
  if (initialHeight) {
    if (backgroundTileHeight !== 0) {
      const minHeight =
        Math.ceil(initialHeight / backgroundTileHeight) * backgroundTileHeight;
      return `${minHeight}px`;
    }
  }
  return undefined;
};

export const getCutStyles = (params: {
  cutSize?: CSSProperties['height'];
  isCutOnBottom?: boolean;
  isCutOnTop?: boolean;
}): CSSProperties => {
  const { cutSize, isCutOnBottom, isCutOnTop } = params;
  if (cutSize && isCutOnBottom) {
    return {
      borderBottomWidth: cutSize,
    };
  }
  if (cutSize && isCutOnTop) {
    return {
      borderTopWidth: cutSize,
    };
  }
  return {};
};
