import { animate, useMotionValue } from 'framer-motion';
import { useEffect, useLayoutEffect, useRef } from 'react';

import { spring } from 'shared/animations/spring.animation';

export const useMotionNumberValue = (
  value: Maybe<number>,
  placeholder?: string,
  initValue?: number,
) => {
  const init = initValue ?? 0;
  const motionValue = useMotionValue(init);
  const ref = useRef<HTMLElement>(null);

  const isNumber = value != null && !Number.isNaN(value);

  useLayoutEffect(() => {
    const unsubscribe = motionValue.onChange(
      (motionValue) =>
        ref.current &&
        (ref.current.textContent = Math.floor(motionValue).toLocaleString()),
    );
    return unsubscribe;
  }, [motionValue]);

  useEffect(() => {
    if (value) {
      const controls = animate(motionValue, value, spring);
      return controls.stop;
    }
  }, [value, motionValue]);

  return {
    isNumber,
    containerProps: { ref },
    textValue: isNumber
      ? Math.floor(value).toLocaleString()
      : placeholder ?? '-',
  };
};
