import { Field } from 'formik';

import Space from 'shared/components/Space';
import type { RenderLabelParams } from 'shared/components/RadioInput';
import RadioInput from 'shared/components/RadioInput';
import StatusButton from 'shared/status/StatusButton';
import { StatusIndicator } from 'shared/status/StatusIndicator';
import FieldBox from 'shared/form/FieldBox';
import { useForm } from 'shared/form/Form';

import styles from './StatusFormStatusIndicatorField.module.scss';
import { selectableStatusIndicators } from '../StatusForm.static';
import type { StatusFormValues } from '../StatusForm.type';

const StatusFormStatusIndicatorField = () => {
  const { hasError } = useForm<StatusFormValues>();

  return (
    <FieldBox name={'statusIndicator'} className={styles.fieldBox}>
      <Space direction={'vertical'}>
        {selectableStatusIndicators.map((statusIndicator) => (
          <Field
            type={'radio'}
            as={RadioInput}
            name={'statusIndicator'}
            value={statusIndicator}
            renderLabel={({ isChecked }: RenderLabelParams) => (
              <StatusButton
                hasError={hasError('statusIndicator')}
                statusIndicator={new StatusIndicator(statusIndicator)}
                isActive={isChecked}
              />
            )}
            key={statusIndicator}
          />
        ))}
      </Space>
    </FieldBox>
  );
};

export default StatusFormStatusIndicatorField;
