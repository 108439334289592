import type { OverlayTriggerState } from 'react-stately';
import type { AriaPopoverProps } from 'react-aria';
import { Overlay, usePopover } from 'react-aria';
import type { PropsWithChildren } from 'react';
import { useRef } from 'react';
import { motion } from 'framer-motion';
import type { DOMAttributes } from '@react-types/shared';

import { menuPopoverAnimation } from './MenuPopover.animation';
import styles from './MenuPopover.module.scss';

type MenuPopoverProps = PropsWithChildren<
  Pick<AriaPopoverProps, 'placement' | 'triggerRef'> & {
    state: OverlayTriggerState;
  }
>;

const MenuPopover = (props: MenuPopoverProps) => {
  const { children, state, ...popoverProps } = props;

  const popoverElementRef = useRef<HTMLDivElement>(null);

  const { popoverProps: popoverElementProps, underlayProps } = usePopover(
    { ...popoverProps, popoverRef: popoverElementRef },
    state,
  );

  return (
    <Overlay>
      <div {...underlayProps} />
      <motion.div
        {...menuPopoverAnimation}
        {...(popoverElementProps as Omit<
          DOMAttributes,
          'onAnimationStart' | 'onDragStart' | 'onDragEnd' | 'onDrag'
        >)}
        className={styles.menuPopover}
        ref={popoverElementRef}
      >
        {children}
      </motion.div>
    </Overlay>
  );
};

export default MenuPopover;
