import { createContext } from 'react';

import type { MetricsListObjective } from 'metric/MetricsList/MetricsListProvider/MetricsListProvider.type';

export type ObjectiveMetricsContextValue = {
  isObjectiveMetricsLoading: boolean;
  objective: Maybe<MetricsListObjective>;
};

export const ObjectiveMetricsContext = createContext<
  ObjectiveMetricsContextValue | undefined
>(undefined);
