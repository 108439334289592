import ContributionSection from 'contribution/ContributionSection/ContributionSection';
import EmojiIcon from 'shared/components/EmojiIcon/EmojiIcon';

import styles from './ContributionNoSection.module.scss';
type ContributionNoSectionProps = {
  description: string;
};

const ContributionNoSection = ({ description }: ContributionNoSectionProps) => (
  <ContributionSection.InnerContainer className={styles.container}>
    <EmojiIcon emoji={'📂'} />
    <p className={styles.description}>{description}</p>
  </ContributionSection.InnerContainer>
);

export default ContributionNoSection;
