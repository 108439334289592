import type { ReactNode } from 'react';
import { useMemo } from 'react';

import type { StrategyRoadmapProps } from 'strategy/StrategyRoadmap';
import StrategyRoadmap from 'strategy/StrategyRoadmap';
import type { ModalProps } from 'shared/components/__DEPRECATED__/Modal';
import Modal from 'shared/components/__DEPRECATED__/Modal';
import { useLaunchStrategy } from 'strategy/useLaunchStrategy';
import type { TeamAdapter } from 'team/TeamAdapter';
import useHandleApolloError from 'shared/errors/useHandleApolloError';

import type { PreviewStrategyModalLaunchBarProps } from './PreviewStrategyModalLaunchBar';
import PreviewStrategyModalLaunchBar from './PreviewStrategyModalLaunchBar';
import PreviewStrategyModalHeading from './PreviewStrategyModalHeading';
import { usePreviewStrategyModalQuery } from './PreviewStrategyModal.graphql.generated';
import styles from './PreviewStrategyModal.module.scss';

type PreviewStrategyModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> &
  Pick<StrategyRoadmapProps, 'strategyId'> &
  Pick<PreviewStrategyModalLaunchBarProps, 'onLaunchStrategySuccess'> & {
    teamAdapter: TeamAdapter;
  };

const PreviewStrategyModal = ({
  strategyId,
  teamAdapter,
  onClose,
  onLaunchStrategySuccess,
  isOpen,
}: PreviewStrategyModalProps) => {
  const { canLaunchStrategy } = useLaunchStrategy({
    teamAdapter,
    strategyId,
  });

  const handleApolloError = useHandleApolloError();

  const { data: previewStrategyModalData } = usePreviewStrategyModalQuery({
    variables: { strategyId },
    onError: handleApolloError,
  });

  const heading = useMemo<ReactNode>(() => {
    if (previewStrategyModalData) {
      return (
        <PreviewStrategyModalHeading
          strategy={previewStrategyModalData.strategy}
        />
      );
    }
    return null;
  }, [previewStrategyModalData]);

  const belowHeading = useMemo<ReactNode>(() => {
    if (previewStrategyModalData && canLaunchStrategy) {
      return (
        <PreviewStrategyModalLaunchBar
          strategyId={previewStrategyModalData.strategy.id}
          onLaunchStrategySuccess={onLaunchStrategySuccess}
          teamAdapter={teamAdapter}
        />
      );
    }
    return null;
  }, [
    previewStrategyModalData,
    canLaunchStrategy,
    onLaunchStrategySuccess,
    teamAdapter,
  ]);

  return (
    <Modal
      heading={heading}
      belowHeading={belowHeading}
      isBelowHeadingFullWidth={true}
      hasHeaderPadding={false}
      isOpen={isOpen}
      size={'full'}
      scrollType={'outer'}
      onClose={onClose}
      contentClassName={styles.previewStrategyModalContent}
      className={styles.previewStrategyModal}
    >
      <StrategyRoadmap
        teamAdapter={teamAdapter}
        strategyId={strategyId}
        showGuide={false}
      />
    </Modal>
  );
};

export default PreviewStrategyModal;
