import { useRef } from 'react';
import { useLink } from 'react-aria';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import styles from './NavbarLink.module.scss';
import type { NavbarLinkType } from '../Navbar.type';

type NavbarLinkProps = NavbarLinkType & {
  onClick?: () => void;
};

const NavbarLink = (props: NavbarLinkProps) => {
  const { name, to, end, onClick, ...restProps } = props;
  const linkRef = useRef<HTMLAnchorElement>(null);

  const { linkProps } = useLink(restProps, linkRef);

  return (
    <NavLink
      ref={linkRef}
      to={to}
      end={end}
      {...linkProps}
      className={({ isActive }) =>
        cn(styles.link, {
          [styles.linkActive]: isActive,
        })
      }
      preventScrollReset={true}
      onClick={onClick}
    >
      {name}
    </NavLink>
  );
};

export default NavbarLink;
