import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import KeyIndicators from 'initiative/KeyIndicators';
import Button from 'shared/components/Button';
import { useInitiativeOverview } from 'initiative/InitiativeOverview/InitiativeOverviewProvider';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';

import styles from './InitiativeOverviewMilestones.module.scss';
import InitiativeOverviewMilestonesInsights from '../InitiativeOverviewMilestonesInsights/InitiativeOverviewMilestonesInsights';

const InitiativeOverviewMilestones = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { objectiveId, initiativeId } = useParams() as {
    initiativeId: string;
    objectiveId: string;
  };

  const { teamAdapter } = useTeamAdapter();

  const { initiative } = useInitiativeOverview();
  const hasStatus = !!initiative?.currentInitiativeStatus;
  const hasMilestones = !!initiative?.milestones.length;

  const addMilestone = () => {
    navigate(
      objectiveRoutesPaths.initiatives.initiative.edit({
        params: {
          initiativeId,
          objectiveId,
          teamSlug: teamAdapter.toParam(),
        },
      }),
      {
        state: { scrollIntoView: true },
      },
    );
  };

  const canEdit =
    initiative && canPerformStrategyElementAction(initiative, 'WRITE');

  return (
    <>
      {hasStatus ? (
        <KeyIndicators
          variant={'tiles'}
          keyIndicatorOnOutcome={
            initiative.currentInitiativeStatus?.keyIndicatorOnOutcome
          }
          keyIndicatorOnBudget={
            initiative.currentInitiativeStatus?.keyIndicatorOnBudget
          }
          keyIndicatorOnTime={
            initiative.currentInitiativeStatus?.keyIndicatorOnTime
          }
          className={styles.keyIndicators}
        />
      ) : null}

      {hasMilestones && (
        <InitiativeOverviewMilestonesInsights initiative={initiative} />
      )}

      <div>
        {canEdit && (
          <Button
            variant={'outlined'}
            color={'mono'}
            icon={PlusIcon}
            iconPosition={'start'}
            iconSize={'small'}
            onClick={addMilestone}
          >
            {t('initiative.initiativeMilestones.milestones.newMilestone')}
          </Button>
        )}
      </div>
    </>
  );
};

export default InitiativeOverviewMilestones;
