import type * as Types from '../../../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StrategySummarySubscriptionVariables = Types.Exact<{
  numberOfCharacters: Types.Scalars['Int'];
  temperature: Types.Scalars['Float'];
  languageLocaleCode: Types.Scalars['String'];
  hints?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  objectiveIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  metricIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  initiativeIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  actionIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  strategyIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
}>;


export type StrategySummarySubscription = { __typename: 'Subscription', aiSummaryStream: string };


export const StrategySummaryDocument = gql`
    subscription StrategySummary($numberOfCharacters: Int!, $temperature: Float!, $languageLocaleCode: String!, $hints: [String!], $objectiveIds: [ID!], $metricIds: [ID!], $initiativeIds: [ID!], $actionIds: [ID!], $strategyIds: [ID!]) {
  aiSummaryStream(
    input: {model: {temperature: $temperature}, maxNumberOfCharacters: $numberOfCharacters, languageLocaleCode: $languageLocaleCode, hints: $hints, elements: {objectiveIds: $objectiveIds, metricIds: $metricIds, initiativeIds: $initiativeIds, actionIds: $actionIds, strategyIds: $strategyIds}}
  )
}
    `;

/**
 * __useStrategySummarySubscription__
 *
 * To run a query within a React component, call `useStrategySummarySubscription` and pass it any options that fit your needs.
 * When your component renders, `useStrategySummarySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategySummarySubscription({
 *   variables: {
 *      numberOfCharacters: // value for 'numberOfCharacters'
 *      temperature: // value for 'temperature'
 *      languageLocaleCode: // value for 'languageLocaleCode'
 *      hints: // value for 'hints'
 *      objectiveIds: // value for 'objectiveIds'
 *      metricIds: // value for 'metricIds'
 *      initiativeIds: // value for 'initiativeIds'
 *      actionIds: // value for 'actionIds'
 *      strategyIds: // value for 'strategyIds'
 *   },
 * });
 */
export function useStrategySummarySubscription(baseOptions: Apollo.SubscriptionHookOptions<StrategySummarySubscription, StrategySummarySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<StrategySummarySubscription, StrategySummarySubscriptionVariables>(StrategySummaryDocument, options);
      }
export type StrategySummarySubscriptionHookResult = ReturnType<typeof useStrategySummarySubscription>;
export type StrategySummarySubscriptionResult = Apollo.SubscriptionResult<StrategySummarySubscription>;