import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useToggle } from 'react-use';
import { capitalize } from 'lodash';

import { hasGoals } from 'strategy/strategy.utils';
import type { CardBorder } from 'shared/components/Card/Card.type';

import { illustrations, backgroundColors } from '../StrategyRoadmap.static';
import StrategyRoadmapCard from '../StrategyRoadmapCard';
import StrategyRoadmapChips from '../StrategyRoadmapChips';
import StrategyRoadmapGoalsModal from '../StrategyRoadmapGoalsModal';
import type { StrategyRoadmapStrategyFieldsFragment } from '../StrategyRoadmapProvider/StrategyRoadmapProvider.graphql.generated';

type StrategyRoadmapGoalsCardProps = {
  border?: CardBorder;
  hasShadow?: boolean;
  showEditButton?: boolean;
  strategy: StrategyRoadmapStrategyFieldsFragment;
};

const StrategyRoadmapGoalsCard = ({
  strategy,
  hasShadow,
  border,
  showEditButton = true,
}: StrategyRoadmapGoalsCardProps) => {
  const { goals } = strategy;

  const { t } = useTranslation();

  const [isModalOpened, toggleIsModalOpened] = useToggle(false);

  const isEmpty = useMemo(() => !hasGoals(strategy), [strategy]);
  const content = useMemo(() => {
    if (!isEmpty) {
      const items = goals?.map((goal) => ({
        id: goal.id,
        children: goal.name,
      }));
      return (
        <StrategyRoadmapChips
          items={items}
          maxItemsCount={3}
          onClickMore={toggleIsModalOpened}
        />
      );
    }
    return t('noData');
  }, [goals, isEmpty, toggleIsModalOpened, t]);

  return (
    <>
      <StrategyRoadmapCard
        illustration={illustrations.goals}
        illustrationBackgroundColor={backgroundColors.goals}
        heading={capitalize(t('strategy.goal_other'))}
        onMaximize={!isEmpty ? toggleIsModalOpened : undefined}
        hasShadow={hasShadow}
        border={border}
      >
        {content}
      </StrategyRoadmapCard>
      <StrategyRoadmapGoalsModal
        strategy={strategy}
        isOpen={isModalOpened}
        onClose={toggleIsModalOpened}
        showEditButton={showEditButton}
      />
    </>
  );
};

export default StrategyRoadmapGoalsCard;
