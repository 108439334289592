import { useTranslation } from 'react-i18next';

import type {
  StrategyItemListFormProps,
  StrategyItemListFormValues,
} from 'strategy/StrategyItemListForm';
import StrategyItemListForm from 'strategy/StrategyItemListForm';
import Heading from 'shared/components/Heading';
import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import InfoCard from 'shared/components/InfoCard';
import Sidesheets from 'shared/sidesheets';

export type StrategyWizardChoicesValues = StrategyItemListFormValues;

type StrategyWizardChoicesProps = Pick<
  StrategyItemListFormProps,
  'id' | 'className' | 'initialValues' | 'org' | 'onChange'
>;

const StrategyWizardChoices = (props: StrategyWizardChoicesProps) => {
  const { t } = useTranslation();

  const renderForm = () => (
    <StrategyItemListForm
      {...props}
      renderItemsHeading={() => (
        <Heading level={4} as={5}>
          {t('strategy.strategyWizard.steps.choices.form.itemsHeading')}
        </Heading>
      )}
      isSymbolDisplayed={false}
    />
  );

  const renderInfoCard = () => (
    <InfoCard
      title={t('strategy.strategyWizard.steps.choices.infoCard.heading')}
      contentKey={'strategy.strategyWizard.steps.choices.infoCard.content'}
      sideSheetTitle={t('sidesheets.context.strategy.heading')}
      orgTermKey={'strategy.choice'}
    >
      <Sidesheets context={'strategy'} wizardStep={'choices'} />
    </InfoCard>
  );

  return (
    <FormWithInfoCardLayout
      renderForm={renderForm}
      renderInfoCard={renderInfoCard}
    />
  );
};

export default StrategyWizardChoices;
