import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';

import useSearchParamState from 'shared/hooks/useSearchParamState';

import type { ShowChildrenFilterValue } from './ShowChildrenFilter.context';
import ShowChildrenFilterContext from './ShowChildrenFilter.context';

type Props = PropsWithChildren<{
  storeToSession: boolean;
}>;

const ShowChildrenFilterProvider = ({ storeToSession, children }: Props) => {
  const [showChildren, setShowChildren] = useSearchParamState<boolean>(
    'showChildren',
    false,
    storeToSession,
    JSON.parse,
  );

  const toggleShowChildren = useCallback(() => {
    setShowChildren(!showChildren);
  }, [setShowChildren, showChildren]);

  const value = useMemo<ShowChildrenFilterValue>(
    () => ({ showChildren, toggleShowChildren }),
    [showChildren, toggleShowChildren],
  );

  return (
    <ShowChildrenFilterContext.Provider value={value}>
      {children}
    </ShowChildrenFilterContext.Provider>
  );
};

export default ShowChildrenFilterProvider;
