import type { OrgUnitAuthorizedAction } from 'types.graphql.generated';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';

import { canPerformOrgUnitAction } from './canPerformOrgUnitAction';

export default function useCanPerformOrgUnitAction(
  action: OrgUnitAuthorizedAction,
) {
  const { orgUnit } = useOrgUnit();

  return !!orgUnit && canPerformOrgUnitAction(orgUnit, action);
}
