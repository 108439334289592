import cn from 'classnames';
import type { HTMLAttributes } from 'react';

import styles from './ListItem.module.scss';
import { useList } from '../ListProvider';

type ListItemProps = HTMLAttributes<HTMLLIElement>;

const ListItem = ({ className, ...restProps }: ListItemProps) => {
  const { itemSize } = useList();

  return (
    <li
      {...restProps}
      className={cn(
        styles.listItem,
        {
          [styles.listItemSizeBig]: itemSize === 'big',
        },
        className,
      )}
    />
  );
};

export default ListItem;
