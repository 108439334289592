import type { Plugin, Chart } from 'chart.js';

export class DoughnutInnerText implements Plugin<'doughnut'> {
  id = 'doughnutInnerText';
  chart!: Chart<'doughnut'>;
  fontFamily?: string;
  titleText: string;
  subTitleText: string;
  titleColor: string;
  subTitleColor: string;
  legendLabelColor: string;
  backgroundColor: string;

  constructor(params: {
    backgroundColor: string;
    legendLabelColor: string;
    subTitleColor: string;
    subTitleText: string;
    titleColor: string;
    titleText: string;
  }) {
    this.titleColor = params.titleColor;
    this.subTitleColor = params.subTitleColor;
    this.backgroundColor = params.backgroundColor;
    this.titleText = params.titleText;
    this.subTitleText = params.titleText;
    this.legendLabelColor = params.legendLabelColor;
  }

  install(chart: Chart<'doughnut'>) {
    this.chart = chart;
    this.fontFamily = chart.options.font?.family;
  }

  beforeDraw() {
    this.drawBackground();
    this.drawTitleText();
    this.drawSubTitleText();
  }

  private drawBackground() {
    const { ctx, chartArea } = this.chart;
    ctx.beginPath();
    ctx.arc(
      chartArea.width / 2 + chartArea.left,
      chartArea.height / 2 + chartArea.top,
      chartArea.height / 2.2,
      0,
      2 * Math.PI,
    );
    ctx.fillStyle = this.backgroundColor;
    ctx.fill();
  }

  private drawTitleText() {
    const { ctx, chartArea } = this.chart;
    const fontSize = `${(chartArea.height / 150).toFixed(2)}em`;
    ctx.font = `${fontSize} ${this.fontFamily}`;
    ctx.textBaseline = 'middle';
    ctx.fillStyle = this.titleColor;
    const text = this.titleText;
    const x = Math.round(
      (chartArea.width - ctx.measureText(text).width) / 2 + chartArea.left,
    );
    const y = chartArea.height / 2.1 + chartArea.top;
    ctx.fillText(text, x, y);
  }

  private drawSubTitleText() {
    const { ctx, config, chartArea } = this.chart;
    const fontSize = `${(chartArea.height / 300).toFixed(2)}em`;
    ctx.font = `${fontSize} ${this.fontFamily}`;
    ctx.textBaseline = 'middle';
    const text = config.data.datasets[0].label as string;
    const textMetrics = ctx.measureText(text);
    const x = Math.round(
      (chartArea.width - ctx.measureText(text).width) / 2 + chartArea.left,
    );
    const y =
      chartArea.height / 1.9 +
      textMetrics.fontBoundingBoxAscent +
      textMetrics.fontBoundingBoxDescent +
      chartArea.top;
    ctx.fillStyle = this.subTitleColor;
    ctx.fillText(text, x, y);
    ctx.fillStyle = this.legendLabelColor;
  }
}
