import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import Button from 'shared/components/Button';
import { ReactComponent as SendIcon } from 'shared/static/icons/icon-send.svg';

import type { StrategyElement } from './useSendStatusRequest';
import useSendStatusRequest from './useSendStatusRequest';

type Props = {
  strategyElement: StrategyElement;
};

const SendStatusRequestButton = ({ strategyElement }: Props) => {
  const { t } = useTranslation();
  const { sendStatusRequest, isSendingStatusRequest } = useSendStatusRequest();

  const handleClick = useCallback(
    () => sendStatusRequest(strategyElement),
    [sendStatusRequest, strategyElement],
  );

  const canRequestStatus = !strategyElement.isCurrentUserOwner;

  if (!canRequestStatus) return null;

  return (
    <Button
      icon={SendIcon}
      iconPosition={'start'}
      variant={'outlined'}
      onClick={handleClick}
      disabled={isSendingStatusRequest}
    >
      {t('statusRequest.action')}
    </Button>
  );
};

export default SendStatusRequestButton;
