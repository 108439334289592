import { useTranslation } from 'react-i18next';

import type { ButtonProps } from 'shared/components/Button';
import { ReactComponent as LeftArrowIcon } from 'shared/static/icons/icon-arrow-left.svg';
import { submitForm } from 'shared/form/form.utils';
import { ReactComponent as RightArrowIcon } from 'shared/static/icons/icon-arrow-right.svg';
import { useSteps } from 'shared/components/Steps';
import Modal from 'shared/components/__DEPRECATED__/Modal';

type MetricStatusWizardControlsProps = {
  buttonLabel: string;
  isLoading: boolean;
};

const MetricStatusWizardControls = ({
  isLoading,
  buttonLabel,
}: MetricStatusWizardControlsProps) => {
  const { t } = useTranslation();

  const { activeStep, goToPreviousStep } = useSteps();

  const getSubmitOptions = () => ({
    goToNextStep: true,
    ...(activeStep?.isLast && {
      submitWizard: true,
    }),
  });

  return (
    <Modal.Controls
      buttons={
        [
          {
            onClick: () => {
              submitForm(activeStep?.form as string, getSubmitOptions());
            },
            variant: 'contained',
            icon: RightArrowIcon,
            disabled: isLoading,
            children: activeStep?.isLast ? buttonLabel : t('next'),
          },
          !activeStep?.isFirst
            ? {
                onClick: goToPreviousStep,
                variant: 'outlined',
                icon: LeftArrowIcon,
                iconPosition: 'start',
                children: t('back'),
              }
            : undefined,
        ].filter(Boolean) as ButtonProps[]
      }
    />
  );
};

export default MetricStatusWizardControls;
