import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Sketch from '@uiw/react-color-sketch';
import { useCallback } from 'react';
import { Button, Dialog, DialogTrigger, Popover } from 'react-aria-components';

const buttonStyle = css`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
`;

const ColorPreview = styled.div<{ color?: string }>`
  width: 50px;
  height: 50px;
  background-color: ${(props) => props.color};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.color.strokeMedium};
`;

type Props = {
  onChange: (value: string) => void;
  value?: string;
};

const ColorPicker = ({ value, onChange }: Props) => {
  const handleChange = useCallback(
    (color: { hex: string }) => {
      onChange(color.hex);
    },
    [onChange],
  );

  return (
    <DialogTrigger>
      <Button css={buttonStyle}>
        <ColorPreview color={value} />
      </Button>
      <Popover>
        <Dialog>
          <Sketch
            style={{ marginLeft: 20 }}
            color={value}
            disableAlpha={true}
            onChange={handleChange}
          />
        </Dialog>
      </Popover>
    </DialogTrigger>
  );
};

export default ColorPicker;
