import type { PropsWithChildren, ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Text from '../Text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 12px;
  color: ${(props) => props.theme.legacyColor.colorTextGrey};
`;

const Header = styled.div`
  display: flex;
  gap: 14px;
`;

const Icon = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 48px;
  width: 48px;
  border-radius: 12px;
  font-size: 30px;
`;

type Props = PropsWithChildren<{
  backgroundColor: string;
  icon: ReactNode;
  iconBackgroundColor: string;
  title: string;
}>;

const SideCard = ({
  title,
  backgroundColor,
  icon,
  iconBackgroundColor,
  children,
}: Props) => {
  return (
    <Container css={css({ backgroundColor })}>
      <Header>
        <Icon css={css({ backgroundColor: iconBackgroundColor })}>{icon}</Icon>

        <Text color={'black'} size={'big'}>
          {title}
        </Text>
      </Header>

      {children}
    </Container>
  );
};

export default SideCard;
