import type {
  SidesheetsContext,
  SidesheetsWizardStep,
} from '../Sidesheets.type';

export const getOrgTermAlsoKnownAsI18nKey = (params: {
  context: SidesheetsContext;
  wizardStep?: SidesheetsWizardStep;
}) => {
  const { context, wizardStep } = params;
  switch (context) {
    case 'strategy': {
      const lookup: Partial<Record<NonNullable<SidesheetsWizardStep>, string>> =
        {
          mission: 'strategy.mission',
          vision: 'strategy.vision',
          goals: 'strategy.goal',
          choices: 'strategy.choice',
          behaviors: 'strategy.behavior',
          values: 'strategy.value',
        };
      if (wizardStep) {
        return lookup[wizardStep];
      }
      break;
    }
    case 'objective': {
      return 'objective.objective';
    }
    case 'metric': {
      return 'metric.metric';
    }
    case 'initiative': {
      return 'initiative.initiative';
    }
    default: {
      return undefined;
    }
  }
};
