import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCallback } from 'react';

import { useToasts } from 'shared/toast/useToasts';
import { teamRoutesPaths } from 'team/team.routing.paths';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { CurrentUserDocument } from 'user/UserProvider/UserProvider.graphql.generated';
import {
  MyTeamsFromActiveOrgDocument,
  MyTeamsFromCurrentUserDocument,
} from 'team/MyTeams/MyTeamsProvider/MyTeamsProvider.graphql.generated';
import { canPerformOrgUnitAction } from 'user/ability/canPerformOrgUnitAction';

import type { OrgUnitForDeleteFragment } from './useDeleteOrgUnit.graphql.generated';
import { useDeleteOrgUnitDeleteOrgUnitMutation } from './useDeleteOrgUnit.graphql.generated';

export type UseDeleteOrgUnitParams = {
  orgUnit: OrgUnitForDeleteFragment;
};

export type UseDeleteOrgUnitReturnType = {
  deleteOrgUnit: () => void;
};

export const useDeleteOrgUnit = ({
  orgUnit,
}: UseDeleteOrgUnitParams): UseDeleteOrgUnitReturnType => {
  const location = useLocation();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { addToast } = useToasts();

  const handleApolloError = useHandleApolloError();

  const [deleteOrgUnit] = useDeleteOrgUnitDeleteOrgUnitMutation({
    variables: {
      id: orgUnit.id,
    },
    refetchQueries: [
      CurrentUserDocument,
      MyTeamsFromCurrentUserDocument,
      MyTeamsFromActiveOrgDocument,
    ],
  });

  const canWriteOrgUnit = canPerformOrgUnitAction(orgUnit, 'WRITE');

  const handleDeleteOrgUnit = useCallback(() => {
    if (canWriteOrgUnit) {
      const previousLocation = location.pathname;
      navigate(teamRoutesPaths.myTeams());
      deleteOrgUnit({
        onCompleted: () => {
          addToast({
            children: t('team.deleteTeamSuccessToast', {
              teamName: orgUnit.name,
            }),
            variant: 'success',
          });
        },
        onError: (error) => {
          handleApolloError(error);
          navigate(previousLocation);
        },
      });
    }
  }, [
    canWriteOrgUnit,
    location.pathname,
    navigate,
    deleteOrgUnit,
    addToast,
    t,
    orgUnit.name,
    handleApolloError,
  ]);

  return {
    deleteOrgUnit: handleDeleteOrgUnit,
  };
};
