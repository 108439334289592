import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { capitalize } from 'lodash';

import Space from 'shared/components/Space';
import StatusMark from 'shared/status/StatusMark';
import Text from 'shared/components/Text';
import { ReactComponent as LinkIcon } from 'shared/static/icons/icon-link.svg';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import type { TeamAdapter } from 'team/TeamAdapter';

import styles from './InitiativeChip.module.scss';
import type { InitiativeChipInitiative } from './InitiativeChip.type';

export type InitiativeChipProps = {
  className?: string;
  initiative: InitiativeChipInitiative;
  left?: number;
  right?: number;
  teamAdapter: TeamAdapter;
};

const linkIconWidth = 16;
const gapWidth = 16;

const InitiativeChip = ({
  initiative,
  teamAdapter,
  className,
  left = 0,
}: InitiativeChipProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement>(null);
  const initiativeChipNameRef = useRef<HTMLDivElement | null>(null);

  const [containerRightPosition, setContainerRightPosition] = useState(0);
  const [initiativeNameRightPosition, setInitiativeNameRightPosition] =
    useState(0);
  const [objectiveRightPosition, setObjectiveRightPosition] = useState(0);

  const handleMouseEnter = () => {
    debouncedHandleMouseLeave.cancel();
    initiativeChipNameRef.current?.classList.remove(styles.overflowHidden);
  };

  const debouncedHandleMouseLeave = debounce(
    () => initiativeChipNameRef.current?.classList.add(styles.overflowHidden),
    2000,
  );

  const horizontalPosition = useMemo(() => {
    const leftPosition = left <= 0 ? 0 : left;

    return {
      left: `${leftPosition}%`,
      right: 0,
    };
  }, [left]);

  return (
    <div
      className={cn(styles.initiativeChip, className)}
      style={left ? horizontalPosition : undefined}
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={debouncedHandleMouseLeave}
      onClick={() =>
        initiative.objective &&
        navigate(
          objectiveRoutesPaths.initiatives.initiative.root({
            params: {
              teamSlug: teamAdapter.toParam(),
              initiativeId: initiative.id,
              objectiveId: initiative.objective.id,
            },
          }),
        )
      }
    >
      <Space
        className={cn(styles.initiativeChipContent, {
          [styles.initiativeExpandable]:
            containerRightPosition < initiativeNameRightPosition,
        })}
      >
        <StatusMark
          statusIndicator={initiative?.currentInitiativeStatus?.statusIndicator}
          className={styles.initiativeChipStatusMark}
          hasBorder={false}
        />
        <Space
          direction={'vertical'}
          size={'tiny'}
          ref={(el) => {
            if (el) {
              initiativeChipNameRef.current = el;
              el.classList.add(styles.overflowHidden);
              setContainerRightPosition(el.getBoundingClientRect().right);
              containerRef.current?.style.setProperty(
                '--width',
                `${el.getBoundingClientRect().width}px`,
              );
            }
          }}
        >
          <span className={styles.initiativeChipNameText}>
            <Text
              variant={'strong'}
              isUppercase={true}
              ellipsis={true}
              whiteSpace={'noWrap'}
              className={styles.initiativeChipNameType}
              ref={(el) => {
                containerRef.current?.style.setProperty(
                  '--initiative-label-width',
                  `${el?.getBoundingClientRect().width}px`,
                );
              }}
            >
              {capitalize(t('initiative.initiative'))}
            </Text>
            <Text
              size={'medium'}
              ellipsis={true}
              whiteSpace={'noWrap'}
              className={styles.initiativeChipName}
              ref={(el) => {
                if (el) {
                  setInitiativeNameRightPosition(
                    el.getBoundingClientRect().right,
                  );
                  containerRef.current?.style.setProperty(
                    '--initiative-text-width',
                    `${el.getBoundingClientRect().width + gapWidth}px`,
                  );
                }
              }}
            >
              {initiative.name}
            </Text>
          </span>
          <div
            className={cn(styles.initiativeChipObjectiveLink, {
              [styles.objectiveExpandable]:
                containerRightPosition < objectiveRightPosition,
            })}
          >
            <LinkIcon className={styles.initiativeChipObjectiveLinkIcon} />
            <Text
              ellipsis={true}
              whiteSpace={'noWrap'}
              className={styles.initiativeChipObjectiveLinkContent}
              ref={(el) => {
                if (el) {
                  setObjectiveRightPosition(el.getBoundingClientRect().right);
                  el.parentElement?.style.setProperty(
                    '--objective-link-width',
                    `${
                      el?.getBoundingClientRect().width +
                      linkIconWidth +
                      gapWidth
                    }px`,
                  );
                }
              }}
            >
              {initiative.objective?.name}
            </Text>
          </div>
        </Space>
      </Space>
    </div>
  );
};

export default InitiativeChip;
