import { useDefinedContext } from 'shared/utils/context.utils';

import StrategyThemesFormProvider from './ThemeFormProvider';
import { ThemeFormContext } from './ThemeFormProvider.context';

export const useThemeForm = () =>
  useDefinedContext(ThemeFormContext, {
    hookName: useThemeForm.name,
    providerName: StrategyThemesFormProvider.name,
  });
