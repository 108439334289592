import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LaunchStrategyStrategyQueryVariables = Types.Exact<{
  strategyId: Types.Scalars['ID'];
}>;


export type LaunchStrategyStrategyQuery = { __typename: 'Query', strategy: { __typename: 'Strategy', id: string, isLive: boolean, isComplete: boolean, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined } };

export type LaunchStrategyUpdateOrgMutationVariables = Types.Exact<{
  orgKey: Types.Scalars['String'];
  strategyId: Types.Scalars['ID'];
}>;


export type LaunchStrategyUpdateOrgMutation = { __typename: 'Mutation', updateOrg: { __typename: 'Org', id: string, currentStrategy?: { __typename: 'Strategy', id: string, isLive: boolean } | undefined } };

export type LaunchStrategyUpdateOrgUnitMutationVariables = Types.Exact<{
  orgUnitId: Types.Scalars['ID'];
  strategyId: Types.Scalars['ID'];
}>;


export type LaunchStrategyUpdateOrgUnitMutation = { __typename: 'Mutation', updateOrgUnit: { __typename: 'OrgUnit', id: string, currentStrategy?: { __typename: 'Strategy', id: string, isLive: boolean } | undefined } };


export const LaunchStrategyStrategyDocument = gql`
    query LaunchStrategyStrategy($strategyId: ID!) {
  strategy(id: $strategyId) {
    id
    isLive
    isComplete
    orgUnit {
      id
    }
  }
}
    `;

/**
 * __useLaunchStrategyStrategyQuery__
 *
 * To run a query within a React component, call `useLaunchStrategyStrategyQuery` and pass it any options that fit your needs.
 * When your component renders, `useLaunchStrategyStrategyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLaunchStrategyStrategyQuery({
 *   variables: {
 *      strategyId: // value for 'strategyId'
 *   },
 * });
 */
export function useLaunchStrategyStrategyQuery(baseOptions: Apollo.QueryHookOptions<LaunchStrategyStrategyQuery, LaunchStrategyStrategyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LaunchStrategyStrategyQuery, LaunchStrategyStrategyQueryVariables>(LaunchStrategyStrategyDocument, options);
      }
export function useLaunchStrategyStrategyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LaunchStrategyStrategyQuery, LaunchStrategyStrategyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LaunchStrategyStrategyQuery, LaunchStrategyStrategyQueryVariables>(LaunchStrategyStrategyDocument, options);
        }
export type LaunchStrategyStrategyQueryHookResult = ReturnType<typeof useLaunchStrategyStrategyQuery>;
export type LaunchStrategyStrategyLazyQueryHookResult = ReturnType<typeof useLaunchStrategyStrategyLazyQuery>;
export type LaunchStrategyStrategyQueryResult = Apollo.QueryResult<LaunchStrategyStrategyQuery, LaunchStrategyStrategyQueryVariables>;
export const LaunchStrategyUpdateOrgDocument = gql`
    mutation LaunchStrategyUpdateOrg($orgKey: String!, $strategyId: ID!) {
  updateOrg(
    input: {orgKeyToUpdate: $orgKey, currentStrategy: {idToSet: $strategyId}}
  ) {
    id
    currentStrategy {
      id
      isLive
    }
  }
}
    `;
export type LaunchStrategyUpdateOrgMutationFn = Apollo.MutationFunction<LaunchStrategyUpdateOrgMutation, LaunchStrategyUpdateOrgMutationVariables>;

/**
 * __useLaunchStrategyUpdateOrgMutation__
 *
 * To run a mutation, you first call `useLaunchStrategyUpdateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLaunchStrategyUpdateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [launchStrategyUpdateOrgMutation, { data, loading, error }] = useLaunchStrategyUpdateOrgMutation({
 *   variables: {
 *      orgKey: // value for 'orgKey'
 *      strategyId: // value for 'strategyId'
 *   },
 * });
 */
export function useLaunchStrategyUpdateOrgMutation(baseOptions?: Apollo.MutationHookOptions<LaunchStrategyUpdateOrgMutation, LaunchStrategyUpdateOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LaunchStrategyUpdateOrgMutation, LaunchStrategyUpdateOrgMutationVariables>(LaunchStrategyUpdateOrgDocument, options);
      }
export type LaunchStrategyUpdateOrgMutationHookResult = ReturnType<typeof useLaunchStrategyUpdateOrgMutation>;
export type LaunchStrategyUpdateOrgMutationResult = Apollo.MutationResult<LaunchStrategyUpdateOrgMutation>;
export type LaunchStrategyUpdateOrgMutationOptions = Apollo.BaseMutationOptions<LaunchStrategyUpdateOrgMutation, LaunchStrategyUpdateOrgMutationVariables>;
export const LaunchStrategyUpdateOrgUnitDocument = gql`
    mutation LaunchStrategyUpdateOrgUnit($orgUnitId: ID!, $strategyId: ID!) {
  updateOrgUnit(
    input: {idToUpdate: $orgUnitId, currentStrategy: {idToSet: $strategyId}}
  ) {
    id
    currentStrategy {
      id
      isLive
    }
  }
}
    `;
export type LaunchStrategyUpdateOrgUnitMutationFn = Apollo.MutationFunction<LaunchStrategyUpdateOrgUnitMutation, LaunchStrategyUpdateOrgUnitMutationVariables>;

/**
 * __useLaunchStrategyUpdateOrgUnitMutation__
 *
 * To run a mutation, you first call `useLaunchStrategyUpdateOrgUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLaunchStrategyUpdateOrgUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [launchStrategyUpdateOrgUnitMutation, { data, loading, error }] = useLaunchStrategyUpdateOrgUnitMutation({
 *   variables: {
 *      orgUnitId: // value for 'orgUnitId'
 *      strategyId: // value for 'strategyId'
 *   },
 * });
 */
export function useLaunchStrategyUpdateOrgUnitMutation(baseOptions?: Apollo.MutationHookOptions<LaunchStrategyUpdateOrgUnitMutation, LaunchStrategyUpdateOrgUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LaunchStrategyUpdateOrgUnitMutation, LaunchStrategyUpdateOrgUnitMutationVariables>(LaunchStrategyUpdateOrgUnitDocument, options);
      }
export type LaunchStrategyUpdateOrgUnitMutationHookResult = ReturnType<typeof useLaunchStrategyUpdateOrgUnitMutation>;
export type LaunchStrategyUpdateOrgUnitMutationResult = Apollo.MutationResult<LaunchStrategyUpdateOrgUnitMutation>;
export type LaunchStrategyUpdateOrgUnitMutationOptions = Apollo.BaseMutationOptions<LaunchStrategyUpdateOrgUnitMutation, LaunchStrategyUpdateOrgUnitMutationVariables>;