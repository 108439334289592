import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UpdateObjectiveStatusFragmentDoc } from '../ObjectiveStatusWizard/ObjectiveStatusWizard.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddObjectiveStatusMutationVariables = Types.Exact<{
  input: Types.ObjectiveStatusInput;
}>;


export type AddObjectiveStatusMutation = { __typename: 'Mutation', addObjectiveStatus: { __typename: 'ObjectiveStatus', id: string, complete: boolean, comment?: string | undefined, statusIndicator: StatusIndicatorClass, statusDateTime: any, actionComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }>, challengeComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }>, successComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }>, auditRecord: { __typename: 'AuditRecord', createDateTime?: any | undefined, updateDateTime?: any | undefined, createBy?: { __typename: 'AuditRecordUser', displayName?: string | undefined, photoUrl?: string | undefined, email?: string | undefined } | undefined, updateBy?: { __typename: 'AuditRecordUser', displayName?: string | undefined, photoUrl?: string | undefined, email?: string | undefined } | undefined }, objective: { __typename: 'Objective', id: string, timeLine: { __typename: 'TimeLine', completeDateTime?: any | undefined }, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string } | undefined, objectiveStatusListAll: Array<{ __typename: 'ObjectiveStatus', id: string }> } } };


export const AddObjectiveStatusDocument = gql`
    mutation AddObjectiveStatus($input: ObjectiveStatusInput!) {
  addObjectiveStatus(input: $input) {
    ...updateObjectiveStatus
  }
}
    ${UpdateObjectiveStatusFragmentDoc}`;
export type AddObjectiveStatusMutationFn = Apollo.MutationFunction<AddObjectiveStatusMutation, AddObjectiveStatusMutationVariables>;

/**
 * __useAddObjectiveStatusMutation__
 *
 * To run a mutation, you first call `useAddObjectiveStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddObjectiveStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addObjectiveStatusMutation, { data, loading, error }] = useAddObjectiveStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddObjectiveStatusMutation(baseOptions?: Apollo.MutationHookOptions<AddObjectiveStatusMutation, AddObjectiveStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddObjectiveStatusMutation, AddObjectiveStatusMutationVariables>(AddObjectiveStatusDocument, options);
      }
export type AddObjectiveStatusMutationHookResult = ReturnType<typeof useAddObjectiveStatusMutation>;
export type AddObjectiveStatusMutationResult = Apollo.MutationResult<AddObjectiveStatusMutation>;
export type AddObjectiveStatusMutationOptions = Apollo.BaseMutationOptions<AddObjectiveStatusMutation, AddObjectiveStatusMutationVariables>;