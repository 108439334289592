import { useTreeState } from 'react-stately';
import { useRef } from 'react';
import type { AriaMenuProps, AriaPopoverProps } from 'react-aria';
import { useMenu } from 'react-aria';

import MenuSection from '../MenuSection';
import MenuRowContainer from '../MenuRowContainer';
import type {
  BaseMenuItem,
  MenuRowComponent,
  MenuTriggerComponent,
} from '../Menu.type';
import styles from './MenuList.module.scss';
import { useMenuCollectionChildren } from '../useMenuCollectionChildren';

type MenuListProps<MenuItem extends BaseMenuItem> = Pick<
  AriaMenuProps<MenuItem>,
  'items'
> &
  Pick<AriaPopoverProps, 'placement'> & {
    Row: MenuRowComponent<MenuItem>;
    Trigger: MenuTriggerComponent;
    onAction: (id: MenuItem['id']) => void;
    triggerClassName?: string;
  };

const MenuList = <MenuItem extends BaseMenuItem>(
  props: MenuListProps<MenuItem>,
) => {
  const { Row, Trigger: _Trigger, ...menuProps } = props;

  const children = useMenuCollectionChildren({ Row });

  const state = useTreeState<MenuItem>({
    ...menuProps,
    children,
    selectionMode: 'single',
  });

  const menuElementRef = useRef<HTMLUListElement>(null);

  const { menuProps: menuElementProps } = useMenu(
    menuProps,
    state,
    menuElementRef,
  );

  return (
    <ul {...menuElementProps} className={styles.menuList} ref={menuElementRef}>
      {[...state.collection].map((item) => {
        if (item.type === 'section') {
          return <MenuSection item={item} state={state} key={item.key} />;
        }
        return <MenuRowContainer item={item} state={state} key={item.key} />;
      })}
    </ul>
  );
};

export default MenuList;
