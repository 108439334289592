import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { useNavigate, useParams } from 'react-router-dom';
import { capitalize } from 'lodash';

import type { MenuItemProps } from 'shared/components/__DEPRECATED__/Menu';
import LayoutHeaderContainer from 'shared/components/Layout/LayoutHeaderContainer';
import type { LinkItem } from 'shared/components/Layout/LayoutHeaderTopSection';
import LayoutHeaderTopSection from 'shared/components/Layout/LayoutHeaderTopSection';
import LayoutHeaderHeading from 'shared/components/Layout/LayoutHeaderHeading';
import LayoutMenuHeader from 'shared/components/Layout/LayoutHeaderMenu';
import { useToasts } from 'shared/toast/useToasts';
import { date } from 'shared/services/date.service';
import LabeledHeadingText from 'shared/components/LabeledHeadingText';
import { useDeleteInitiativesMutation } from 'initiative/initiative.graphql.generated';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import ConfirmationModal from 'shared/components/__DEPRECATED__/ConfirmationModal';
import StatusTag from 'shared/status/StatusTag';
import UserAvatar from 'user/UserAvatar/UserAvatar';
import LinkTabs from 'shared/components/LinkTabs';
import { useTeamAdapter } from 'team/TeamAdapter';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import { useMyTeamRoutePath } from 'team/useMyTeamRoutePath';
import Flex from 'shared/components/Flex';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { useAddInitiativeStatusMutation } from 'initiative/CreateInitiativeStatusDialog/AddInitiativeStatus.graphql.generated';
import CreateInitiativeStatusDialog from 'initiative/CreateInitiativeStatusDialog';
import useDialogState from 'shared/hooks/useDialogState';
import useSendStatusRequest from 'status/useSendStatusRequest';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';

import styles from './InitiativeOverviewLayoutHeader.module.scss';
import { initiativeOverviewRoutesPaths } from '../InitiativeOverview.routing.paths';
import {
  getBreadcrumbsItems,
  getNavigationItems,
} from './InitiativeOverviewLayoutHeader.utils';
import { useInitiativeOverview } from '../InitiativeOverviewProvider';
import { InitiativeOverviewDocument } from '../InitiativeOverviewProvider/InitiativeOverviewProvider.graphql.generated';
import { useOrgUnit } from '../../../orgUnit/OrgUnitProvider';

const InitiativeLayoutHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { teamAdapter } = useTeamAdapter();
  const { initiative } = useInitiativeOverview();
  const { priorities } = useStrategyProfilePriorities();
  const { addToast } = useToasts();
  const { objectiveId, initiativeId } = useParams() as {
    initiativeId: string;
    objectiveId: string;
  };

  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const { routePath: myTeamRoutePath, isMatch: isMyTeamRoutePathMatch } =
    useMyTeamRoutePath();

  const [
    isDeleteConfirmationModalOpened,
    toggleIsDeleteConfirmationModalOpened,
  ] = useToggle(false);

  const [
    isCompleteConfirmationModalOpened,
    toggleIsCompleteConfirmationModalOpened,
  ] = useToggle(false);

  const {
    isOpen: isCompleteStatusWizardOpen,
    onOpen: onOpenCompleteStatusWizard,
    onClose: onCloseCompleteStatusWizard,
  } = useDialogState();

  const onError = useHandleApolloError();

  const { sendStatusRequest } = useSendStatusRequest();

  const [createCompletedStatus] = useAddInitiativeStatusMutation({
    variables: {
      input: {
        orgKey: teamAdapter.orgKey,
        initiativeId: initiativeId,
        complete: true,
      },
    },
    onCompleted: () => {
      addToast({
        id: 'completeSuccessToast',
        variant: 'success',
        children: t('strategy.toasts.completeSuccessToast'),
      });
    },
    onError,
    refetchQueries: [
      {
        query: InitiativeOverviewDocument,
        variables: {
          initiativeId: initiative?.id,
        },
      },
    ],
  });

  const [deleteInitiative] = useDeleteInitiativesMutation({
    variables: { initiativeId: initiativeId },
    update(cache) {
      cache.evict({
        id: `Initiative:${initiativeId}`,
        broadcast: false,
      });
    },
    onCompleted: () =>
      addToast({
        id: 'removeInitiativeSuccessToast',
        variant: 'success',
        children: t('strategy.toasts.removeSuccessToast'),
      }),
    onError,
  });

  const handleDeleteInitiative = () => {
    navigate(
      objectiveRoutesPaths.root({
        params: {
          teamSlug: teamAdapter.toParam(),
          objectiveId,
        },
      }),
    );
    deleteInitiative();
  };

  const [createActiveStatus] = useAddInitiativeStatusMutation({
    variables: {
      input: {
        orgKey: teamAdapter.orgKey,
        initiativeId,
        complete: false,
      },
    },
    onCompleted: () => {
      addToast({
        id: 'reactivateInitiativeSuccessToast',
        variant: 'success',
        children: t('strategy.toasts.reactivateSuccessToast'),
      });
    },
    onError,
    refetchQueries: [
      {
        query: InitiativeOverviewDocument,
        variables: { initiativeId: initiative?.id },
      },
    ],
  });

  const topRightNavItem: Maybe<LinkItem> = !isMyTeamRoutePathMatch
    ? {
        name: t('team.layout.goToTeam'),
        to: myTeamRoutePath,
      }
    : undefined;

  const dates = useMemo(() => {
    const startDate = initiative?.timeLine.startDate
      ? date.format(initiative?.timeLine.startDate, 'dd MMM yyyy')
      : t('notSet');
    const endDate = initiative?.timeLine.endDate
      ? date.format(initiative?.timeLine.endDate, 'dd MMM yyyy')
      : t('notSet');

    return `${startDate} - ${endDate}`;
  }, [initiative, t]);

  const breadcrumbsItems = getBreadcrumbsItems({
    t,
    teamAdapter,
    objectiveId,
    initiativeId,
    orgUnit,
    activeOrg,
  });

  const navigationItems = useMemo<LinkItem[]>(() => getNavigationItems(), []);

  const menuItems = useMemo((): MenuItemProps[] => {
    if (!initiative) return [];

    const canRequestStatus = !initiative.isCurrentUserOwner;

    const canWrite = canPerformStrategyElementAction(initiative, 'WRITE');

    const canReactivate =
      initiative?.currentInitiativeStatus?.complete && canWrite;

    const canComplete =
      !initiative?.currentInitiativeStatus?.complete && canWrite;

    return [
      canRequestStatus && {
        children: t('statusRequest.action'),
        onClick: () => sendStatusRequest(initiative),
      },
      canReactivate && {
        children: t('initiative.initiativeLayout.header.menu.reactivateButton'),
        onClick: createActiveStatus,
      },
      canComplete && {
        children: t('initiative.initiativeLayout.header.menu.completeButton'),
        onClick: toggleIsCompleteConfirmationModalOpened,
      },
      canWrite && {
        children: t('initiative.initiativeLayout.header.menu.editButton'),
        onClick: () => navigate(initiativeOverviewRoutesPaths.edit()),
      },
      canWrite && {
        children: t('initiative.initiativeLayout.header.menu.removeButton'),
        onClick: toggleIsDeleteConfirmationModalOpened,
      },
    ].filter(Boolean);
  }, [
    createActiveStatus,
    initiative,
    navigate,
    sendStatusRequest,
    t,
    toggleIsCompleteConfirmationModalOpened,
    toggleIsDeleteConfirmationModalOpened,
  ]);

  return (
    <>
      <LayoutHeaderContainer>
        <div className={styles.content}>
          <LayoutHeaderTopSection
            breadcrumbs={breadcrumbsItems}
            topRightLinkItem={topRightNavItem}
            className={styles.topSection}
          />
          <div className={styles.mainContainer}>
            <LayoutHeaderHeading
              content={
                <LabeledHeadingText
                  label={capitalize(t('initiative.initiative'))}
                  textContent={initiative?.name}
                />
              }
              isBold={false}
            />
            <div className={styles.headingExtra}>
              <StatusTag
                statusIndicator={
                  initiative?.currentInitiativeStatus?.statusIndicator
                }
              />
              <UserAvatar user={initiative?.owner} />
              <LayoutMenuHeader menuItems={menuItems} testId={'initiative'} />
            </div>
          </div>
          <Flex gap={20}>
            {dates && <p className={styles.description}>{dates}</p>}
            {(priorities || initiative?.priority) && (
              <p className={styles.description}>
                {t('priority.priority')}
                {': '}
                {initiative?.priority?.label || t('priority.noPriority')}
              </p>
            )}
          </Flex>
          <LinkTabs items={navigationItems} />
        </div>
      </LayoutHeaderContainer>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpened}
        onClose={toggleIsDeleteConfirmationModalOpened}
        onConfirm={handleDeleteInitiative}
      >
        {t('initiative.initiativeLayout.deleteConfirmation')}
      </ConfirmationModal>
      <ConfirmationModal
        isOpen={isCompleteConfirmationModalOpened}
        onClose={toggleIsCompleteConfirmationModalOpened}
        onConfirm={onOpenCompleteStatusWizard}
        onDeny={createCompletedStatus}
      >
        {t('initiative.initiativeLayout.completeConfirmation')}
      </ConfirmationModal>

      {initiative && (
        <CreateInitiativeStatusDialog
          initiative={initiative}
          isOpen={isCompleteStatusWizardOpen}
          onClose={onCloseCompleteStatusWizard}
          completeStatus={true}
        />
      )}
    </>
  );
};

export default InitiativeLayoutHeader;
