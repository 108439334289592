import type { ButtonHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import type { Merge } from 'type-fest';
import styled from '@emotion/styled';

import { ReactComponent as MoreIcon } from 'shared/static/icons/icon-more.svg';

import { getResetButtonStyle } from './getResetButtonStyle';

const StyledButton = styled.button<
  Pick<MenuButtonProps, 'hasBackground' | 'hasBorder' | 'size'>
>`
  ${getResetButtonStyle()}

  align-items: center;
  border-radius: 0.75rem;
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  width: 3rem;
  background: ${(props) =>
    props.hasBackground ? props.theme.color.white : 'transparent'};
  border: ${(props) =>
    props.hasBorder ? `1px solid ${props.theme.color.strokeDark}` : 'none'};

  svg {
    display: block;
    height: ${(props) => (props.size === 'small' ? '20px' : '32px')};
    width: ${(props) => (props.size === 'small' ? '20px' : '32px')};
  }

  &:hover {
    opacity: 0.8;
  }
`;

type MenuButtonProps = Merge<
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'>,
  {
    hasBackground?: boolean;
    hasBorder?: boolean;
    size?: 'small' | 'medium';
    testId?: string;
  }
>;

/**
 * @deprecated Use `shared/components/ContextMenu` instead
 */
const MenuButton = forwardRef<HTMLButtonElement, MenuButtonProps>(
  (
    {
      hasBorder = false,
      hasBackground = false,
      className,
      size = 'medium',
      testId,
      ...restProps
    },
    ref,
  ) => (
    <StyledButton
      {...restProps}
      ref={ref}
      hasBorder={hasBorder}
      hasBackground={hasBackground}
      size={size}
      className={className}
      data-context-menu-trigger={testId}
    >
      <MoreIcon />
    </StyledButton>
  ),
);

export default MenuButton;
