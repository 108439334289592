import { useTranslation } from 'react-i18next';

import type { Strategy } from 'types.graphql.generated';
import Space from 'shared/components/Space';
import Text from 'shared/components/Text';
import StrategyStatusTag from 'strategy/StrategyStatusTag';

type PreviewStrategyModalHeadingProps = {
  strategy: Pick<Strategy, 'name' | 'isLive' | 'isComplete'>;
};

const PreviewStrategyModalHeading = ({
  strategy,
}: PreviewStrategyModalHeadingProps) => {
  const { t } = useTranslation();

  return (
    <Space isCentered={true}>
      <Text variant={'strong'}>{t('preview')}</Text>
      <Text variant={'emphasis'}>{strategy.name}</Text>
      <StrategyStatusTag strategy={strategy} />
    </Space>
  );
};

export default PreviewStrategyModalHeading;
