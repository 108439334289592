import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import type { ReactNode } from 'react';
import { useCallback, useMemo } from 'react';

import type { Strategy } from 'types.graphql.generated';
import Text from 'shared/components/Text';
import TextButton from 'shared/components/TextButton';
import Space from 'shared/components/Space';
import { ReactComponent as PublishIcon } from 'shared/static/icons/icon-publish.svg';
import { useLaunchStrategy } from 'strategy/useLaunchStrategy';
import type { LaunchStrategyConfirmationModalProps } from 'strategy/LaunchStrategyConfirmationModal';
import LaunchStrategyConfirmationModal from 'strategy/LaunchStrategyConfirmationModal';
import type { TeamAdapter } from 'team/TeamAdapter';

import styles from './PreviewStrategyModalLaunchBar.module.scss';

export type PreviewStrategyModalLaunchBarProps = {
  onLaunchStrategySuccess?: LaunchStrategyConfirmationModalProps['onSuccess'];
  strategyId: Strategy['id'];
  teamAdapter: TeamAdapter;
};

const PreviewStrategyModalLaunchBar = ({
  strategyId,
  teamAdapter,
  onLaunchStrategySuccess,
}: PreviewStrategyModalLaunchBarProps) => {
  const { t } = useTranslation();

  const [
    isLaunchStrategyConfirmationModalOpen,
    toggleIsLaunchStrategyConfirmationModalOpen,
  ] = useToggle(false);
  const { canLaunchStrategy } = useLaunchStrategy({
    teamAdapter,
    strategyId,
  });

  const handleLaunchStrategy = useCallback(() => {
    toggleIsLaunchStrategyConfirmationModalOpen(true);
  }, [toggleIsLaunchStrategyConfirmationModalOpen]);

  const launchButton = useMemo<ReactNode>(
    () => (
      <TextButton
        variant={'strong'}
        icon={PublishIcon}
        onPress={handleLaunchStrategy}
      >
        {t('strategy.previewStrategyModal.launchBar.launchButton')}
      </TextButton>
    ),
    [handleLaunchStrategy, t],
  );

  if (canLaunchStrategy) {
    return (
      <>
        <Space
          isCentered={true}
          className={styles.previewStrategyModalLaunchBar}
        >
          <div className={styles.previewStrategyModalLaunchBarContent}>
            <Text>{t('strategy.previewStrategyModal.launchBar.text')}</Text>
            {launchButton}
          </div>
        </Space>
        <LaunchStrategyConfirmationModal
          isOpen={isLaunchStrategyConfirmationModalOpen}
          onClose={toggleIsLaunchStrategyConfirmationModalOpen}
          strategyId={strategyId}
          teamAdapter={teamAdapter}
          onSuccess={onLaunchStrategySuccess}
        />
      </>
    );
  }

  return null;
};

export default PreviewStrategyModalLaunchBar;
