import { useNavigate } from 'react-router-dom';

import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';
import ObjectiveInsightsProvider, {
  useObjectiveInsights,
} from 'objective/ObjectiveInsights/ObjectiveInsightsProvider';
import GlobalSpinner from 'shared/spinner/GlobalSpinner';
import CreateObjectiveStatusDialog from 'objective/CreateObjectiveStatusDialog';

const CreateObjectiveStatusPage = () => {
  const navigate = useNavigate();
  const { teamAdapter } = useTeamAdapter();
  const { objective } = useObjectiveInsights();

  if (!objective) return <GlobalSpinner />;

  return (
    <CreateObjectiveStatusDialog
      isOpen={true}
      objective={objective}
      onClose={() =>
        navigate(
          objectiveRoutesPaths.insights.root({
            params: {
              objectiveId: objective.id,
              teamSlug: teamAdapter.toParam(),
            },
          }),
        )
      }
    />
  );
};

export default () => (
  <ObjectiveInsightsProvider>
    <CreateObjectiveStatusPage />
  </ObjectiveInsightsProvider>
);
