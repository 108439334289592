import cn from 'classnames';
import type { ReactNode, ComponentType, SVGProps } from 'react';

import Heading from 'shared/components/Heading';
import type { ButtonProps } from 'shared/components/Button';
import Button from 'shared/components/Button';

import styles from './Result.module.scss';
import Flex from '../Flex';

export type ResultProps = {
  aboveButtonsText?: string;
  after?: ReactNode;
  buttons?: ButtonProps[];
  className?: string;
  description?: ReactNode;
  hasBackground?: boolean;
  heading?: ReactNode;
  illustration?: ComponentType<SVGProps<SVGSVGElement>>;
  image?: ReactNode;
  isGlobal?: boolean;
};

const Result = ({
  image,
  heading,
  description,
  buttons,
  aboveButtonsText,
  illustration: Illustration,
  isGlobal = false,
  hasBackground = false,
  after: afterProp,
  className,
}: ResultProps) => {
  const hasButtonsContainer = buttons || aboveButtonsText;

  return (
    <div
      className={cn(
        styles.result,
        {
          [styles.resultGlobal]: isGlobal,
          [styles.resultWithBackground]: hasBackground,
        },
        className,
      )}
    >
      {Illustration ? (
        <Illustration className={styles.resultIllustration} />
      ) : (
        image && <div className={styles.resultImageContainer}>{image}</div>
      )}

      {heading && <Heading level={2}>{heading}</Heading>}

      {description && <p className={styles.resultDescription}>{description}</p>}

      {afterProp && <div className={styles.resultAfter}>{afterProp}</div>}

      {hasButtonsContainer && (
        <Flex direction={'column'} gap={8}>
          {aboveButtonsText && (
            <p className={styles.resultAboveButtonsText}>{aboveButtonsText}</p>
          )}

          {buttons?.map((button, buttonIndex) => (
            <Button {...button} key={buttonIndex} />
          ))}
        </Flex>
      )}
    </div>
  );
};

export default Result;
