import type { LinkedItem } from 'strategy/StrategyOperationalItemRow';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { teamRoutesPaths } from 'team/team.routing.paths';
import type { Contribution } from 'contribution/ContributionTeamList/ContributionTeamList';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import type { OrgForTeamAdapter } from 'team/TeamAdapter/TeamAdapter.type';
import { TeamAdapter } from 'team/TeamAdapter';
import type { ContributionStrategySectionParentObjectiveFragment } from 'contribution/ContributionStrategySection/ContributionStrategySection.graphql.generated';
import { canPerformOrgUnitAction } from 'user/ability/canPerformOrgUnitAction';

export const getStatusIndicator = (item: Contribution) => {
  switch (item.__typename) {
    case 'Objective':
      return item.currentObjectiveStatus?.statusIndicator;
    case 'Metric':
      return item.currentMetricStatus?.statusIndicator;
    case 'Initiative':
      return item.currentInitiativeStatus?.statusIndicator;
    case 'Action':
      return item.currentStatus?.statusIndicator;
  }
};

const getLinkToItem = (
  org: OrgForTeamAdapter,
  item: Contribution | ContributionStrategySectionParentObjectiveFragment,
) => {
  switch (item.__typename) {
    case 'Objective':
      return objectiveRoutesPaths.root({
        params: {
          teamSlug: TeamAdapter.fromOrgUnitOrOrg(org, item.orgUnit).toParam(),
          objectiveId: item.id,
        },
      });
    case 'Initiative':
      return objectiveRoutesPaths.initiatives.initiative.root({
        params: {
          teamSlug: TeamAdapter.fromOrgUnitOrOrg(
            org,
            item.objective?.orgUnit,
          ).toParam(),
          objectiveId: item.objective?.id || '',
          initiativeId: item.id,
        },
      });
    case 'Metric':
      return objectiveRoutesPaths.metrics.metric.root({
        params: {
          teamSlug: TeamAdapter.fromOrgUnitOrOrg(
            org,
            item.objective?.orgUnit,
          ).toParam(),
          objectiveId: item.objective?.id || '',
          metricId: item.id,
        },
      });
    case 'Action':
      return objectiveRoutesPaths.actions.action.root({
        params: {
          teamSlug: TeamAdapter.fromOrgUnitOrOrg(
            org,
            item.objective?.orgUnit,
          ).toParam(),
          objectiveId: item.objective?.id || '',
          actionId: item.id,
        },
      });
  }
};

export const getTitleItem = (
  activeOrg: OrgForTeamAdapter,
  item: Contribution,
): LinkedItem => ({
  text: item.name,
  isLocked: !canPerformStrategyElementAction(item, 'READ'),
  linkTo: getLinkToItem(activeOrg, item),
});

export const getSubTitleItem = (
  activeOrg: OrgForTeamAdapter,
  item: Contribution,
): LinkedItem | undefined => {
  switch (item.__typename) {
    case 'Objective':
      if (item.objective) {
        return {
          text: item.objective.name,
          isLocked: !canPerformStrategyElementAction(item.objective, 'READ'),
          linkTo: getLinkToItem(activeOrg, item.objective),
        };
      }

      if (item.theme) {
        return {
          text: item.theme.name,
          isLocked:
            !!item.orgUnit && !canPerformOrgUnitAction(item.orgUnit, 'READ'),
          linkTo: teamRoutesPaths.insights.overview({
            params: {
              teamSlug: TeamAdapter.fromOrgUnitOrOrg(
                activeOrg,
                item.orgUnit,
              ).toParam(),
            },
            query: { theme: item.theme ? [item.theme.id] : undefined },
          }),
        };
      }

      return undefined;
    case 'Metric':
    case 'Initiative':
    case 'Action':
      return item.objective
        ? {
            text: item.objective.name,
            isLocked: !canPerformStrategyElementAction(item.objective, 'READ'),
            linkTo: getLinkToItem(activeOrg, item.objective),
          }
        : undefined;
  }
};
