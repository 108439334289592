import { Trans, useTranslation } from 'react-i18next';
import type { FormikProps } from 'formik';
import { Field } from 'formik';

import { yup } from 'shared/services/yup.service';
import Heading from 'shared/components/Heading';
import Text from 'shared/components/Text';
import type { FormProps } from 'shared/form/Form';
import Form from 'shared/form/Form';
import FieldBox from 'shared/form/FieldBox';
import Space from 'shared/components/Space';
import RadioCard from 'shared/components/RadioCard';
import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import InfoCard from 'shared/components/InfoCard';
import PreviousInsightsSidecard from 'status/PreviousInsightsSidecard/PreviousInsightsSidecard';

import type { InitiativeStatusWizardInitiative } from '../InitiativeStatusWizard.type';
import styles from './InitiativeStatusWizardKeyIndicators.module.scss';

export type InitiativeStatusWizardKeyIndicatorsValues = {
  onBudget: string | null;
  onOutcome: string | null;
  onTime: string | null;
};

export type InitiativeStatusWizardKeyIndicatorsSubmitValues = {
  onBudget: boolean;
  onOutcome: boolean;
  onTime: boolean;
};

const createInitialValues = (): InitiativeStatusWizardKeyIndicatorsValues => ({
  onBudget: null,
  onOutcome: null,
  onTime: null,
});

export type InitiativeStatusWizardKeyIndicatorsProps = Pick<
  FormProps<InitiativeStatusWizardKeyIndicatorsValues>,
  'id' | 'initialValues' | 'onChange' | 'onError' | 'onSubmit'
> & {
  initiative: InitiativeStatusWizardInitiative;
};

// todo divide InitiativeStatusWizardKeyIndicators into smaller components
const InitiativeStatusWizardKeyIndicators = ({
  initialValues = createInitialValues(),
  initiative,
  ...restProps
}: InitiativeStatusWizardKeyIndicatorsProps) => {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    onBudget: yup.boolean().nullable().required(),
    onOutcome: yup.boolean().nullable().required(),
    onTime: yup.boolean().nullable().required(),
  });

  const renderKeyIndicatorFields = (
    name: keyof InitiativeStatusWizardKeyIndicatorsValues,
    { touched, errors }: FormikProps<InitiativeStatusWizardKeyIndicatorsValues>,
  ) => (
    <FieldBox
      name={name}
      label={t(`initiative.keyIndicators.${name}.description`)}
    >
      <Space>
        <Field
          name={name}
          isError={touched[name] && errors[name]}
          as={RadioCard}
          type={'radio'}
          value={'true'}
          className={styles.formField}
        >
          {t('yes')}
        </Field>
        <Field
          name={name}
          isError={touched[name] && errors[name]}
          as={RadioCard}
          type={'radio'}
          value={'false'}
          className={styles.formField}
        >
          {t('no')}
        </Field>
      </Space>
    </FieldBox>
  );

  const renderAboveForm = () => (
    <>
      <Heading level={3}>
        {t('initiative.initiativeStatusWizard.steps.keyIndicators.heading')}
      </Heading>
      <Text>
        <Trans
          i18nKey={
            'initiative.initiativeStatusWizard.steps.keyIndicators.description'
          }
          values={{
            initiativeName: initiative.name,
          }}
        >
          <Text variant={'emphasis'} />
        </Trans>
      </Text>
    </>
  );

  const renderForm = () => (
    <>
      {renderAboveForm()}
      <Form<InitiativeStatusWizardKeyIndicatorsValues>
        {...restProps}
        initialValues={initialValues}
        validationSchema={validationSchema}
        className={styles.form}
        enableReinitialize={true}
      >
        {(formikProps) => (
          <>
            {renderKeyIndicatorFields('onTime', formikProps)}
            {renderKeyIndicatorFields('onBudget', formikProps)}
            {renderKeyIndicatorFields('onOutcome', formikProps)}
          </>
        )}
      </Form>
    </>
  );

  const renderInfoCard = () => (
    <InfoCard
      title={t(
        'initiative.initiativeStatusWizard.steps.keyIndicators.infoCard.heading',
      )}
      contentKey={
        'initiative.initiativeStatusWizard.steps.keyIndicators.infoCard.content'
      }
    />
  );

  return (
    <FormWithInfoCardLayout
      renderForm={renderForm}
      renderInfoCard={renderInfoCard}
      renderAfterInfoCard={<PreviousInsightsSidecard />}
    />
  );
};

export default InitiativeStatusWizardKeyIndicators;
