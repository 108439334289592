import ReactSelect, { defaultTheme } from 'react-select';
import type {
  Props as ReactSelectProps,
  GroupBase as ReactSelectGroupBase,
} from 'react-select';
import hexToRgba from 'hex-to-rgba';
import { css, useTheme } from '@emotion/react';

import type { Theme } from 'theme';
import type { Size } from 'shared/types/size.type';

import SelectIndicatorSeparator from './SelectIndicatorSeparator';
import SelectDropdownIndicator from './SelectDropdownIndicator';
import SelectControl from './SelectControl';
import SelectValueContainer from './SelectValueContainer';

// todo confirm Select styles with design

const getReactSelectTheme = (theme: Theme): ReactSelectProps['theme'] => ({
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    primary: theme.color.primary,
    primary75: hexToRgba(theme.color.primary, 0.75),
    primary50: hexToRgba(theme.color.primary, 0.5),
    primary25: hexToRgba(theme.color.primary, 0.25),
    danger: theme.color.error,
    dangerLight: hexToRgba(theme.color.error, 0.5),
  },
});

const selectSizes = {
  tiny: '11rem',
  small: '14rem',
  medium: '20rem',
  big: '28rem',
  full: '100%',
};

const components = {
  Control: SelectControl,
  DropdownIndicator: SelectDropdownIndicator,
  IndicatorSeparator: SelectIndicatorSeparator,
  ValueContainer: SelectValueContainer,
};

/**
 * @deprecated Use `shared/components/Select` instead
 */
export type SelectProps<
  Option extends object | unknown = unknown,
  IsMulti extends boolean = boolean,
  Group extends ReactSelectGroupBase<Option> = ReactSelectGroupBase<Option>,
> = Omit<
  ReactSelectProps<Option, IsMulti, Group>,
  'isRtl' | 'theme' | 'components'
> & {
  containerClassName?: string;
  inlineLabel?: string;
  size?: Extract<Size, 'medium' | 'big' | 'small' | 'tiny'> | 'full';
};

const SelectV2 = <
  Option extends object | unknown = unknown,
  IsMulti extends boolean = boolean,
  Group extends ReactSelectGroupBase<Option> = ReactSelectGroupBase<Option>,
>({
  size = 'medium',
  className,
  containerClassName,
  inlineLabel,
  ...restProps
}: SelectProps<Option, IsMulti, Group>) => {
  const theme = useTheme();

  return (
    <div className={containerClassName}>
      <ReactSelect
        isSearchable={false}
        {...restProps}
        isRtl={false}
        theme={getReactSelectTheme(theme)}
        // @ts-ignore: components prop emits types error because Select generic types are not passed to components.
        // Passing generics to every component will bloat code and with limited functionality of Select it won't be very beneficial
        components={components}
        css={css`
          width: ${selectSizes[size]};

          [class*='singleValue'],
          [id*='placeholder'] {
            &::before {
              color: ${theme.legacyColor.colorDoveGray};
              content: ${inlineLabel ? `"${inlineLabel}"` : 'none'};
              font-size: 0.875rem;
              margin-right: 0.5rem;
            }
          }
        `}
        className={className}
      />
    </div>
  );
};

export default SelectV2;
