import { I18nextProvider as ReactI18nextProvider } from 'react-i18next';
import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { useLocalStorage } from 'react-use';

import { i18n } from 'shared/services/i18n.service';

import { isLanguageValid } from './I18nextProvider.utils';

export const forcedLanguageChangeStorageKey = 'forcedLanguageChange';

type I18nextProviderProps = PropsWithChildren<object>;

const I18nextProvider = ({ children }: I18nextProviderProps) => {
  const params = new URL(window.location as unknown as URL).searchParams;
  const language = params.get('language');

  const [, setForcedLanguageChange] = useLocalStorage<boolean>(
    forcedLanguageChangeStorageKey,
  );

  useEffect(() => {
    if (isLanguageValid(language)) {
      i18n.changeLanguage(language, () => {
        setForcedLanguageChange(true);
      });
    }
  }, [language, setForcedLanguageChange]);

  return <ReactI18nextProvider i18n={i18n}>{children}</ReactI18nextProvider>;
};

export default I18nextProvider;
