import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { useAddMetricStatusMutation } from 'metric/CreateMetricStatusDialog/AddMetricStatus.graphql.generated';
import ContextMenu from 'shared/components/ContextMenu';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import useDialogState from 'shared/hooks/useDialogState';
import { useToasts } from 'shared/toast/useToasts';
import useSendStatusRequest from 'status/useSendStatusRequest';
import type { Metric, Objective } from 'types.graphql.generated';
import {
  canPerformStrategyElementAction,
  type StrategyElementWithAuthorizedActions,
} from 'user/ability/canPerformStrategyElementAction';
import { useDeleteMetricsMutation } from 'metric/metric.graphql.generated';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import ConfirmationModal from 'shared/components/__DEPRECATED__/ConfirmationModal';
import CreateMetricStatusDialog from 'metric/CreateMetricStatusDialog';
import { metricOverviewRoutesPaths } from 'metric/MetricOverview/MetricOverview.routing.paths';
import EditMetric from 'metric/EditMetric';

import { useUnlinkMetricMutation } from './MetricContextMenu.graphql.generated';

type Props = {
  linkParent?: Pick<Objective, 'id'> & StrategyElementWithAuthorizedActions;
  metric: Pick<Metric, '__typename' | 'id' | 'isCurrentUserOwner'> & {
    currentMetricStatus?: { complete: boolean };
  } & StrategyElementWithAuthorizedActions;
  onDelete?: () => void;
};

const MetricContextMenu = ({ metric, onDelete, linkParent }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const { activeOrg } = useActiveOrg();

  const metricId = metric.id;

  const [
    isDeleteConfirmationModalOpened,
    toggleIsDeleteConfirmationModalOpened,
  ] = useToggle(false);

  const [
    isCompleteConfirmationModalOpened,
    toggleIsCompleteConfirmationModalOpened,
  ] = useToggle(false);

  const {
    isOpen: isUnlinkOpen,
    onOpen: onUnlinkOpen,
    onClose: onUnlinkClose,
  } = useDialogState();

  const {
    isOpen: isCompleteStatusWizardOpen,
    onOpen: onOpenCompleteStatusWizard,
    onClose: onCloseCompleteStatusWizard,
  } = useDialogState();

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDialogState();

  const onError = useHandleApolloError();

  const { sendStatusRequest } = useSendStatusRequest();

  const [createCompletedMetricStatus] = useAddMetricStatusMutation({
    variables: {
      input: {
        orgKey: activeOrg.orgKey,
        metricId,
        complete: true,
      },
    },
    onCompleted: () =>
      addToast({
        id: 'completeSuccessToast',
        variant: 'success',
        children: t('strategy.toasts.completeSuccessToast'),
      }),
    onError,
  });

  const [deleteMetric] = useDeleteMetricsMutation({
    variables: { metricId },
    update(cache) {
      cache.evict({ id: `Metric:${metricId}`, broadcast: false });
    },
    onCompleted: () =>
      addToast({
        id: 'removeMetricSuccessToast',
        variant: 'success',
        children: t('strategy.toasts.removeSuccessToast'),
      }),
    onError,
  });

  const [createActiveMetricStatus] = useAddMetricStatusMutation({
    variables: {
      input: {
        orgKey: activeOrg.orgKey,
        metricId,
        complete: false,
      },
    },
    onCompleted: () =>
      addToast({
        id: 'reactivateMetricSuccessToast',
        variant: 'success',
        children: t('strategy.toasts.reactivateSuccessToast'),
      }),
    onError,
  });

  const [unlinkMetric] = useUnlinkMetricMutation({
    variables: {
      metricId: metric.id,
      objectiveId: linkParent?.id || '',
    },
    onCompleted: () =>
      addToast({
        id: 'unlinkMetricSuccessToast',
        variant: 'success',
        children: t('metric.contextMenu.toast.unlink'),
      }),
    onError,
  });

  const handleDeleteMetric = useCallback(
    () => deleteMetric().then(onDelete),
    [deleteMetric, onDelete],
  );

  const items = useMemo(() => {
    if (!metric) return [];

    const canRequestStatus = !metric.isCurrentUserOwner;
    const canWrite = canPerformStrategyElementAction(metric, 'WRITE');
    const canReactivate = metric?.currentMetricStatus?.complete && canWrite;
    const canComplete = !metric?.currentMetricStatus?.complete && canWrite;
    const canDelete = !linkParent && canWrite;
    const canUnlink =
      linkParent && canPerformStrategyElementAction(linkParent, 'WRITE');

    return [
      canRequestStatus && {
        id: 'requestStatus',
        title: t('statusRequest.action'),
      },
      canReactivate && {
        id: 'reactivate',
        title: t('metric.contextMenu.item.reactivate'),
        onClick: createActiveMetricStatus,
      },
      canComplete && {
        id: 'complete',
        title: t('metric.contextMenu.item.complete'),
        onClick: toggleIsCompleteConfirmationModalOpened,
      },
      canWrite && {
        id: 'edit',
        title: t('metric.contextMenu.item.edit'),
        onClick: () => navigate(metricOverviewRoutesPaths.edit()),
      },
      canDelete && {
        id: 'delete',
        title: t('metric.contextMenu.item.delete'),
        onClick: toggleIsDeleteConfirmationModalOpened,
      },
      canUnlink && {
        id: 'unlink',
        title: t('metric.contextMenu.item.unlink'),
        onClick: toggleIsDeleteConfirmationModalOpened,
      },
    ].filter(Boolean);
  }, [
    createActiveMetricStatus,
    linkParent,
    metric,
    navigate,
    t,
    toggleIsCompleteConfirmationModalOpened,
    toggleIsDeleteConfirmationModalOpened,
  ]);

  const handleAction = useCallback(
    (itemId: string) => {
      switch (itemId) {
        case 'requestStatus':
          sendStatusRequest(metric);
          return;
        case 'reactivate':
          createActiveMetricStatus();
          return;
        case 'complete':
          toggleIsCompleteConfirmationModalOpened();
          return;
        case 'edit':
          onEditOpen();
          return;
        case 'delete':
          toggleIsDeleteConfirmationModalOpened();
          return;
        case 'unlink':
          onUnlinkOpen();
      }
    },
    [
      createActiveMetricStatus,
      metric,
      onEditOpen,
      onUnlinkOpen,
      sendStatusRequest,
      toggleIsCompleteConfirmationModalOpened,
      toggleIsDeleteConfirmationModalOpened,
    ],
  );

  return (
    <>
      <ContextMenu items={items} onAction={handleAction} testId={'metric'} />

      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpened}
        onClose={toggleIsDeleteConfirmationModalOpened}
        onConfirm={handleDeleteMetric}
      >
        {t('metric.contextMenu.confirmation.delete')}
      </ConfirmationModal>

      <ConfirmationModal
        isOpen={isCompleteConfirmationModalOpened}
        onClose={toggleIsCompleteConfirmationModalOpened}
        onConfirm={onOpenCompleteStatusWizard}
        onDeny={createCompletedMetricStatus}
      >
        {t('metric.contextMenu.confirmation.complete')}
      </ConfirmationModal>

      <ConfirmationModal
        isOpen={isUnlinkOpen}
        onClose={onUnlinkClose}
        onConfirm={unlinkMetric}
      >
        {t('metric.contextMenu.confirmation.unlink')}
      </ConfirmationModal>

      {metric && (
        <CreateMetricStatusDialog
          metric={metric}
          isOpen={isCompleteStatusWizardOpen}
          onClose={onCloseCompleteStatusWizard}
          completeStatus={true}
        />
      )}

      <EditMetric
        metricId={metric.id}
        isOpen={isEditOpen}
        onClose={onEditClose}
      />
    </>
  );
};

export default MetricContextMenu;
