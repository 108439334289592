import type { PropsWithChildren } from 'react';
import cn from 'classnames';

import Provider from './ListProvider';
import Item from './ListItem';
import styles from './List.module.scss';
import type { ItemSize, GutterSize } from './List.type';
import { getListElement } from './List.utils';

export type ListProps = PropsWithChildren<{
  className?: string;
  gutterSize?: GutterSize;
  isOrdered?: boolean;
  itemSize?: ItemSize;
  variant?: 'regular' | 'emphasis';
}>;

const List = ({
  isOrdered = false,
  variant = 'regular',
  gutterSize = 'small',
  children,
  className,
}: ListProps) => {
  const Element = getListElement(isOrdered);

  return (
    <Element
      className={cn(
        styles.list,
        {
          [styles.listOrdered]: isOrdered && gutterSize !== 'none',
          [styles.listUnordered]: !isOrdered && gutterSize !== 'none',
          [styles.listNoMarker]: gutterSize === 'none',
          [styles.listRegular]: variant === 'regular',
          [styles.listEmphasis]: variant === 'emphasis',
          [styles.listGutterSizeNone]: gutterSize === 'none',
          [styles.listGutterSizeSmall]: gutterSize === 'small',
          [styles.listGutterSizeRegular]: gutterSize === 'regular',
          [styles.listGutterSizeMedium]: gutterSize === 'medium',
          [styles.listGutterSizeLarge]: gutterSize === 'large',
        },
        className,
      )}
    >
      {children}
    </Element>
  );
};

const ListWithProvider = (props: ListProps) => (
  <Provider itemSize={props.itemSize}>
    <List {...props} />
  </Provider>
);

ListWithProvider.Item = Item;

export default ListWithProvider;
