import type { Key } from 'react-aria-components';
import {
  ComboBox,
  Input,
  ListBox,
  ListBoxItem,
  Popover,
} from 'react-aria-components';
import { css, useTheme } from '@emotion/react';
import { useCallback, useMemo, useRef } from 'react';
import { uniqBy } from 'lodash';

import useElementWidth from 'shared/hooks/useElementWidth';

import { getInputStyle } from './getInputStyle';
import { getPopoverStyle } from './getPopoverStyle';

type Option = { label: string; value: string };

type Item = { id: string; label: string };

type Props = {
  filterOptions?: boolean;
  hasError?: boolean;
  name?: string;
  onChange: (value: string) => void;
  options?: (string | Option)[];
  placeholder?: string;
  value?: string;
};

const SuggestionsTextInput = ({
  name,
  value = '',
  options = [],
  onChange,
  placeholder,
  filterOptions = false,
  hasError,
}: Props) => {
  const theme = useTheme();
  const triggerElementRef = useRef<HTMLDivElement>(null);
  const triggerWidth = useElementWidth(triggerElementRef);

  const items: Item[] = useMemo(
    () =>
      options.map((option) =>
        typeof option === 'object'
          ? { id: option.value, label: option.label }
          : { id: option, label: option },
      ),
    [options],
  );

  const filteredItems: Item[] = useMemo(
    () =>
      uniqBy(items, (item) => item.id).filter(
        (item) =>
          !filterOptions ||
          item.label.toLowerCase().includes(value.toLowerCase()) ||
          item.id.toLowerCase().includes(value.toLowerCase()),
      ),
    [filterOptions, items, value],
  );

  const handleSelectionChange = useCallback(
    (key: Key) => {
      if (key && key !== value) {
        onChange(key.toString());
      }
    },
    [onChange, value],
  );

  return (
    <ComboBox<Item>
      inputValue={value}
      onInputChange={onChange}
      onSelectionChange={handleSelectionChange}
      allowsCustomValue={true}
      items={filteredItems}
      menuTrigger={'focus'}
      css={getInputStyle(theme, hasError)}
      ref={triggerElementRef}
    >
      <Input
        id={name}
        name={name}
        placeholder={placeholder}
        autoComplete={'false'}
        aria-invalid={hasError}
      />
      <Popover
        maxHeight={200}
        css={[getPopoverStyle(theme), css({ width: triggerWidth || 'auto' })]}
        shouldFlip={false}
      >
        <ListBox<Item>>
          {(item) => (
            <ListBoxItem value={item} textValue={item.id}>
              {item.label}
            </ListBoxItem>
          )}
        </ListBox>
      </Popover>
    </ComboBox>
  );
};

export default SuggestionsTextInput;
