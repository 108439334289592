import { useTranslation } from 'react-i18next';

import { date } from 'shared/services/date.service';
import StatusCard from 'shared/status/StatusCard';
import { ReactComponent as MilestoneIcon } from 'shared/static/icons/icon-milestone.svg';
import Text from 'shared/components/Text';

import type { MilestonesListMilestone } from './MilestonesList.type';
import styles from './MilestonesList.module.scss';

export type MilestonesListProps = {
  className?: string;
  milestones: MilestonesListMilestone[];
};

const MilestonesList = ({ milestones, className }: MilestonesListProps) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      {milestones.length ? (
        milestones.map((milestone) => {
          const endDate = milestone?.timeLine?.endDate;
          const description = endDate
            ? t(
                'initiative.initiativeMilestones.milestones.milestone.description.deadline',
                {
                  date: date.formatShort(endDate),
                },
              )
            : t(
                'initiative.initiativeMilestones.milestones.milestone.description.noDeadline',
              );
          return (
            <StatusCard
              caption={t(
                'initiative.initiativeMilestones.milestones.milestone.caption',
              )}
              heading={milestone.name || ''}
              comment={description}
              icon={MilestoneIcon}
              statusIndicator={
                milestone?.currentMilestoneStatus?.statusIndicator
              }
              key={milestone.id}
            >
              <Text variant={'emphasis'} className={styles.description}>
                {milestone.comment}
              </Text>
            </StatusCard>
          );
        })
      ) : (
        <Text className={styles.noMilestones}>
          {t('initiative.noMilestones')}
        </Text>
      )}
    </div>
  );
};

export default MilestonesList;
