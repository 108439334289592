import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

import StrategyRoomThemesLayout from 'strategy/StrategyRoomThemesLayout';
import Spinner from 'shared/spinner/Spinner';
import { useTeamAdapter } from 'team/TeamAdapter';
import type { ThemeFilterInput } from 'types.graphql.generated';
import Heading from 'shared/components/Heading';
import InfoCard from 'shared/components/InfoCard';
import Button from 'shared/components/Button';
import CreateThemeModal from 'themes/CreateThemeModal';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import IfCanPerformOrgOrOrgUnitAction from 'user/ability/IfCanPerformOrgOrOrgUnitAction';

import { useStrategyRoomThemesManageThemesQuery } from './StrategyRoomThemesManageThemes.graphql.generated';
import ThemesList from './ThemesList';

const StyledHeading = styled(Heading)`
  margin: 0;
  padding: 12px 24px;
`;

const StrategyRoomThemesManageThemes = () => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();

  const handleApolloError = useHandleApolloError();

  const filter: ThemeFilterInput = teamAdapter.isOrg
    ? { orgUnitIds: { operator: 'IS_NULL' } }
    : { orgUnitIds: { operator: 'IN', values: [teamAdapter.keyArg] } };

  const { data, refetch } = useStrategyRoomThemesManageThemesQuery({
    onError: handleApolloError,
    variables: { filter },
  });

  useEffect(() => {
    // manual refetch on load because cache-and-network policy breaks reordering
    refetch();
  }, [refetch]);

  const [showNewThemeDialog, setShowNewThemeDialog] = useState(false);

  if (!data) return <Spinner.Circle />;

  const allThemes = data.allThemes.edges.map((edge) => edge.node);
  const activeThemes = allThemes.filter((theme) => theme.isActive);
  const archivedThemes = allThemes.filter((theme) => !theme.isActive);

  return (
    <>
      <StrategyRoomThemesLayout
        description={t(
          'strategy.strategyRoom.themes.tabs.manageThemes.description',
        )}
        headerButton={
          <IfCanPerformOrgOrOrgUnitAction
            orgAction={'WRITE_STRATEGY'}
            orgUnitAction={'WRITE'}
          >
            <Button
              onClick={() => setShowNewThemeDialog(true)}
              variant={'outlined'}
              icon={PlusIcon}
              iconPosition={'start'}
            >
              {t('theme.newTheme')}
            </Button>
          </IfCanPerformOrgOrOrgUnitAction>
        }
        infoCard={
          <InfoCard
            title={t(
              'strategy.strategyRoom.themes.tabs.manageThemes.infoCard.heading',
            )}
            contentKey={
              'strategy.strategyRoom.themes.tabs.manageThemes.infoCard.content'
            }
          />
        }
      >
        <StyledHeading level={5}>
          {t('strategy.strategyRoom.themes.activeThemes')}
        </StyledHeading>
        <ThemesList
          themes={activeThemes}
          orderNumberStyle={'number'}
          emptyMessage={t('strategy.strategyRoom.themes.noActiveThemes')}
        />

        <StyledHeading level={5}>
          {t('strategy.strategyRoom.themes.archivedThemes')}
        </StyledHeading>
        <ThemesList
          themes={archivedThemes}
          orderNumberStyle={'dash'}
          emptyMessage={t('strategy.strategyRoom.themes.noArchivedThemes')}
        />
      </StrategyRoomThemesLayout>

      {showNewThemeDialog && (
        <CreateThemeModal
          isOpen={true}
          onClose={() => setShowNewThemeDialog(false)}
        />
      )}
    </>
  );
};

export default StrategyRoomThemesManageThemes;
