import { useDefinedContext } from 'shared/utils/context.utils';

import { ObjectiveInitiativesContext } from './ObjectiveInitiativesProvider.context';
import ObjectiveInitiativesProvider from './ObjectiveInitiativesProvider';

export const useObjectiveInitiatives = () =>
  useDefinedContext(ObjectiveInitiativesContext, {
    hookName: useObjectiveInitiatives.name,
    providerName: ObjectiveInitiativesProvider.name,
  });
