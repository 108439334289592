import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useMemo, useCallback } from 'react';
import { useToggle } from 'react-use';
import { capitalize } from 'lodash';

import { ObjectiveDocument, useObjective } from 'objective/ObjectiveProvider';
import { usePageTitle } from 'shared/hooks/usePageTitle';
import StatusTag from 'shared/status/StatusTag/StatusTag';
import { useDeleteObjectiveMutation } from 'objective/objective.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import { useAddObjectiveStatusMutation } from 'objective/CreateObjectiveStatusDialog/AddObjectiveStatus.graphql.generated';
import ConfirmationModal from 'shared/components/__DEPRECATED__/ConfirmationModal';
import { date } from 'shared/services/date.service';
import type { LinkItem } from 'shared/components/Layout';
import LabeledHeadingText from 'shared/components/LabeledHeadingText';
import UserAvatar from 'user/UserAvatar';
import { strategyRoutesPaths } from 'strategy/strategy.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import { useMyTeamRoutePath } from 'team/useMyTeamRoutePath';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { enumerate } from 'shared/utils/string.utils';
import CreateObjectiveStatusDialog from 'objective/CreateObjectiveStatusDialog';
import useDialogState from 'shared/hooks/useDialogState';
import useSendStatusRequest from 'status/useSendStatusRequest';

import type { ObjectiveLayoutContextMenuProps } from './ObjectiveLayoutContextMenu';
import ObjectiveLayoutContextMenu from './ObjectiveLayoutContextMenu';
import {
  getBreadcrumbItems,
  getNavigationItems,
} from './ObjectiveLayout.utils';
import ObjectiveHeader from './ObjectiveHeader';
import { useOrgUnit } from '../../orgUnit/OrgUnitProvider';

const ObjectiveLayout = () => {
  const location = useLocation();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const onError = useHandleApolloError();

  const { addToast } = useToasts();

  const { teamAdapter } = useTeamAdapter();
  const { objective } = useObjective();
  const { name, currentObjectiveStatus } = objective;

  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const {
    isOpen: isShareStatusOpen,
    onOpen: onShareStatusOpen,
    onClose: onShareStatusClose,
  } = useDialogState();

  const {
    isOpen: isCompleteStatusWizardOpen,
    onOpen: onOpenCompleteStatusWizard,
    onClose: onCloseCompleteStatusWizard,
  } = useDialogState();

  const [
    isDeleteConfirmationModalOpened,
    toggleIsDeleteConfirmationModalOpened,
  ] = useToggle(false);

  const [
    isCompleteConfirmationModalOpened,
    toggleIsCompleteConfirmationModalOpened,
  ] = useToggle(false);

  const { routePath: myTeamRoutePath, isMatch: isMyTeamRoutePathMatch } =
    useMyTeamRoutePath();

  const { sendStatusRequest } = useSendStatusRequest();

  const [createCompletedObjectiveStatus] = useAddObjectiveStatusMutation({
    variables: {
      input: {
        orgKey: teamAdapter.orgKey,
        objectiveId: objective.id,
        complete: true,
      },
    },
    onCompleted: () =>
      addToast({
        variant: 'success',
        children: t('strategy.toasts.completeSuccessToast'),
      }),
    onError,
    refetchQueries: [
      { query: ObjectiveDocument, variables: { objectiveId: objective.id } },
    ],
  });

  const [createActiveObjectiveStatus] = useAddObjectiveStatusMutation({
    variables: {
      input: {
        orgKey: teamAdapter.orgKey,
        objectiveId: objective.id,
        complete: false,
      },
    },
    onCompleted: () =>
      addToast({
        variant: 'success',
        children: t('strategy.toasts.reactivateSuccessToast'),
      }),
    onError,
    refetchQueries: [
      { query: ObjectiveDocument, variables: { objectiveId: objective.id } },
    ],
  });

  const [deleteObjective] = useDeleteObjectiveMutation({
    variables: { objectiveId: objective.id },
    onCompleted: () => {
      addToast({
        id: 'removeObjectiveSuccessToast',
        variant: 'success',
        children: t('strategy.toasts.removeSuccessToast'),
      });
    },
    onError,
  });

  const handleDeleteObjective = async () =>
    deleteObjective({
      onError: () => navigate(location.pathname),
      onCompleted: () =>
        navigate(
          strategyRoutesPaths.objectives.root({
            params: { teamSlug: teamAdapter.toParam() },
          }),
        ),
    });

  usePageTitle(name);

  const breadcrumbsItems = useMemo(
    () =>
      getBreadcrumbItems({
        objectiveId: objective.id,
        teamAdapter,
        activeOrg,
        orgUnit,
      }),
    [objective.id, teamAdapter, activeOrg, orgUnit],
  );

  const navigationItems = useMemo(
    () => getNavigationItems({ objective, teamAdapter }),
    [objective, teamAdapter],
  );

  const description = useMemo(() => {
    const startDate = objective?.timeLine.startDate
      ? date.format(objective?.timeLine.startDate, 'dd MMM yyyy')
      : t('notSet');
    const endDate = objective?.timeLine.endDate
      ? date.format(objective?.timeLine.endDate, 'dd MMM yyyy')
      : t('notSet');

    return `${startDate} - ${endDate}`;
  }, [objective?.timeLine.endDate, objective?.timeLine.startDate, t]);

  const heading = useMemo(
    () => ({
      content: (
        <LabeledHeadingText
          label={capitalize(t('objective.objective'))}
          textContent={name}
        />
      ),
      isBold: false,
    }),
    [name, t],
  );

  const topRightNavItem: Maybe<LinkItem> = !isMyTeamRoutePathMatch
    ? {
        name: t('team.layout.goToTeam'),
        to: myTeamRoutePath,
      }
    : null;

  const onMenuAction = useCallback<ObjectiveLayoutContextMenuProps['onAction']>(
    (id) => {
      switch (id) {
        case 'shareStatus': {
          onShareStatusOpen();
          break;
        }
        case 'requestStatus': {
          sendStatusRequest(objective);
          break;
        }
        case 'reactivate': {
          createActiveObjectiveStatus();
          break;
        }
        case 'complete': {
          toggleIsCompleteConfirmationModalOpened();
          break;
        }
        case 'edit': {
          navigate(
            objectiveRoutesPaths.edit({
              params: {
                teamSlug: teamAdapter.toParam(),
                objectiveId: objective.id,
              },
            }),
          );
          break;
        }
        case 'delete': {
          toggleIsDeleteConfirmationModalOpened();
          break;
        }
      }
    },
    [
      createActiveObjectiveStatus,
      navigate,
      objective,
      onShareStatusOpen,
      sendStatusRequest,
      teamAdapter,
      toggleIsCompleteConfirmationModalOpened,
      toggleIsDeleteConfirmationModalOpened,
    ],
  );

  const hasMetrics = objective.metrics.length > 0;
  const hasInitiatives = objective.initiatives.length > 0;
  const hasActions = objective.actions.length > 0;

  const hasChildItems = hasMetrics || hasInitiatives || hasActions;

  return (
    <>
      <ObjectiveHeader
        heading={heading}
        description={description}
        theme={objective.theme?.name}
        priority={objective.priority?.label}
        breadcrumbsItems={breadcrumbsItems}
        navigationItems={navigationItems}
        contextMenu={<ObjectiveLayoutContextMenu onAction={onMenuAction} />}
        topRightNavItem={topRightNavItem}
      >
        <StatusTag statusIndicator={currentObjectiveStatus?.statusIndicator} />
        <UserAvatar user={objective?.owner} />
      </ObjectiveHeader>

      <Outlet />

      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpened}
        onClose={toggleIsDeleteConfirmationModalOpened}
        onConfirm={handleDeleteObjective}
      >
        {hasChildItems
          ? t('objective.objectiveLayout.deleteConfirmation', {
              childElements: enumerate(t, [
                hasMetrics &&
                  t('count.metric', {
                    count: objective.metrics.length,
                  }),
                hasInitiatives &&
                  t('count.initiative', {
                    count: objective.initiatives.length,
                  }),
                hasActions &&
                  t('count.action', {
                    count: objective.actions.length,
                  }),
              ]).join(''),
            })
          : t('objective.objectiveLayout.deleteConfirmationNoChildElements')}
      </ConfirmationModal>

      <ConfirmationModal
        isOpen={isCompleteConfirmationModalOpened}
        onClose={toggleIsCompleteConfirmationModalOpened}
        onConfirm={onOpenCompleteStatusWizard}
        onDeny={createCompletedObjectiveStatus}
      >
        {t('objective.objectiveLayout.completeConfirmation')}
      </ConfirmationModal>

      <CreateObjectiveStatusDialog
        objective={objective}
        isOpen={isShareStatusOpen}
        onClose={onShareStatusClose}
      />

      <CreateObjectiveStatusDialog
        objective={objective}
        isOpen={isCompleteStatusWizardOpen}
        onClose={onCloseCompleteStatusWizard}
        completeStatus={true}
      />
    </>
  );
};

export default ObjectiveLayout;
