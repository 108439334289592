import { useDefinedContext } from 'shared/utils/context.utils';

import { StrategyRoomProviderContext } from './StrategyRoomProvider.context';
import StrategyRoomProvider from './StrategyRoomProvider';

export const useStrategyRoom = () =>
  useDefinedContext(StrategyRoomProviderContext, {
    hookName: useStrategyRoom.name,
    providerName: StrategyRoomProvider.name,
  });
