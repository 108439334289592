import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import cn from 'classnames';

import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';
import { ReactComponent as ToolcardsIcon } from 'shared/static/icons/icon-toolcards.svg';
import { ReactComponent as InviteUserIcon } from 'shared/static/icons/icon-invite-user.svg';
import { ReactComponent as WalkOfStrategyIcon } from 'shared/static/icons/icon-walk-of-strategy.svg';
import { ReactComponent as ObjectiveIcon } from 'shared/static/icons/icon-flag.svg';
import { ReactComponent as CameraIcon } from 'shared/static/icons/icon-camera.svg';
import type { ContextMenuOnActionFn } from 'shared/components/ContextMenu';
import ContextMenu from 'shared/components/ContextMenu';
import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';
import type { OrgUnit } from 'types.graphql.generated';
import { teamRoutesPaths } from 'team/team.routing.paths';
import EditOrgUnitModal from 'orgUnit/EditOrgUnitModal/EditOrgUnitModal';
import DeleteOrgUnitConfirmationModal from 'orgUnit/DeleteOrgUnitConfirmationModal/DeleteOrgUnitConfirmationModal';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import { TeamAdapter } from 'team/TeamAdapter';
import type { OrgUnitForDeleteFragment } from 'orgUnit/useDeleteOrgUnit/useDeleteOrgUnit.graphql.generated';
import AddMemberToOrgUnitModal from 'orgUnit/AddMemberToOrgUnitModal/AddMemberToOrgUnitModal';
import TeamMembersProvider from 'team/TeamMembers/TeamMembersProvider/TeamMembersProvider';
import { toolkitRoutesPaths } from 'toolkit/toolkit.routing.paths';
import useFileUpload from 'shared/hooks/useFileUpload';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { canPerformOrgUnitAction } from 'user/ability/canPerformOrgUnitAction';

import type {
  OrgUnitContextMenuItem,
  OrgUnitContextMenuItemId,
  OrgUnitContextMenuSize,
} from './OrgUnitContextMenu.type';
import styles from './OrgUnitContextMenu.module.scss';
import { useUpdateOrgUnitBackgroundImageMutation } from './UpdateOrgUnitBackgroundImage.graphql.generated';

export type OrgUnitContextMenuProps = {
  orgUnit: OrgUnitForDeleteFragment;
  size?: OrgUnitContextMenuSize;
};

const OrgUnitContextMenu = ({
  orgUnit,
  size = 'regular',
}: OrgUnitContextMenuProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { activeOrg } = useActiveOrg();

  const handleApolloError = useHandleApolloError();

  const [updateBackgroundImage] = useUpdateOrgUnitBackgroundImageMutation({
    onError: handleApolloError,
  });

  const { openFilePicker } = useFileUpload({
    accept: 'image/*',
    objectType: 'BACKGROUND_IMAGE',
    maxFileSize: 2,
    onFileUploaded: (fileUrl) =>
      updateBackgroundImage({ variables: { fileUrl, orgUnitId: orgUnit.id } }),
  });

  const [isEditOrgUnitModalOpen, toggleIsEditOrgUnitModalOpen] =
    useToggle(false);
  const [isInviteMemberModalOpen, toggleIsInviteMemberModalOpen] =
    useToggle(false);

  const [
    isDeleteOrgUnitComfirmationModalOpen,
    toggleIsDeleteOrgUnitConfirmationModalOpen,
  ] = useToggle(false);

  const handleContextMenuAction = useCallback<
    ContextMenuOnActionFn<OrgUnit['id']>
  >(
    (action) => {
      const teamAdapter = TeamAdapter.fromOrgUnit(
        { id: orgUnit.id },
        activeOrg.orgKey,
      );

      switch (action) {
        case 'editBackgroundImage': {
          openFilePicker();
          break;
        }
        case 'openStrategyRoom': {
          navigate(
            teamRoutesPaths.strategyRoom({
              params: {
                teamSlug: teamAdapter.toParam(),
              },
            }),
          );
          break;
        }
        case 'openWalkOfStrategy':
          {
            navigate(
              toolkitRoutesPaths.walkOfStrategy.overview({
                params: {
                  teamSlug: teamAdapter.toParam(),
                },
              }),
            );
          }
          break;
        case 'openOneThing':
          {
            navigate(
              toolkitRoutesPaths.oneThing.overview({
                params: {
                  teamSlug: teamAdapter.toParam(),
                },
              }),
            );
          }
          break;
        case 'editTeam': {
          toggleIsEditOrgUnitModalOpen(true);
          break;
        }
        case 'inviteMember': {
          toggleIsInviteMemberModalOpen(true);
          break;
        }
        case 'deleteTeam': {
          toggleIsDeleteOrgUnitConfirmationModalOpen(true);
          break;
        }
      }
    },
    [
      activeOrg.orgKey,
      navigate,
      openFilePicker,
      orgUnit.id,
      toggleIsDeleteOrgUnitConfirmationModalOpen,
      toggleIsEditOrgUnitModalOpen,
      toggleIsInviteMemberModalOpen,
    ],
  );

  const items = useMemo<OrgUnitContextMenuItem[]>(
    () => [
      {
        id: 'team',
        title: t('team.team'),
        children: [
          {
            id: 'editTeam',
            title: t('orgUnit.editOrgUnitModal.heading'),
            icon: EditIcon,
          },
          {
            id: 'editBackgroundImage',
            title: t('editBackgroundImage'),
            icon: CameraIcon,
          },
          {
            id: 'inviteMember',
            title: t('team.teamMembers.addMemberContextMenuItem'),
            icon: InviteUserIcon,
          },
          {
            id: 'deleteTeam',
            title: t('team.deleteTeam'),
            icon: TrashIcon,
            variant: 'danger',
          },
        ],
      },
      {
        id: 'strategy',
        title: t('strategy.strategy'),
        children: [
          {
            id: 'openStrategyRoom',
            title: t('strategy.strategyRoom.openStrategyRoom'),
            icon: ToolcardsIcon,
          },
          ...(activeOrg.toolsEnabled.includes('WALK_OF_STRATEGY')
            ? [
                {
                  id: 'openWalkOfStrategy' as OrgUnitContextMenuItemId,
                  title: t(
                    'toolkit.toolPage.walkOfStrategy.button.openWalkOfStrategy',
                  ),
                  icon: WalkOfStrategyIcon,
                },
              ]
            : []),
          ...(activeOrg.toolsEnabled.includes('ONE_THING')
            ? [
                {
                  id: 'openOneThing' as OrgUnitContextMenuItemId,
                  title: t('toolkit.toolPage.oneThing.button.open'),
                  icon: ObjectiveIcon,
                },
              ]
            : []),
        ],
      },
    ],
    [activeOrg.toolsEnabled, t],
  );

  const disabledKeys = useMemo(
    () =>
      canPerformOrgUnitAction(orgUnit, 'WRITE')
        ? []
        : [
            'editBackgroundImage',
            'editTeam',
            'inviteMember',
            'deleteTeam',
            'openStrategyRoom',
            'openOneThing',
          ],
    [orgUnit],
  );

  const teamAdapter = useMemo(
    () => TeamAdapter.fromOrgUnit(orgUnit, activeOrg.orgKey),
    [activeOrg.orgKey, orgUnit],
  );

  return (
    <>
      {disabledKeys.length < items.length && (
        <ContextMenu<OrgUnitContextMenuItem>
          disabledKeys={disabledKeys}
          triggerClassName={cn({ [styles.menuSmall]: size === 'small' })}
          items={items}
          onAction={handleContextMenuAction}
        />
      )}
      <EditOrgUnitModal
        orgKey={activeOrg.orgKey}
        orgUnitId={orgUnit.id}
        isOpen={isEditOrgUnitModalOpen}
        onClose={toggleIsEditOrgUnitModalOpen}
      />
      {isInviteMemberModalOpen && (
        <TeamMembersProvider teamAdapter={teamAdapter}>
          <AddMemberToOrgUnitModal
            teamAdapter={teamAdapter}
            isOpen={isInviteMemberModalOpen}
            onClose={toggleIsInviteMemberModalOpen}
          />
        </TeamMembersProvider>
      )}
      <DeleteOrgUnitConfirmationModal
        orgUnit={orgUnit}
        isOpen={isDeleteOrgUnitComfirmationModalOpen}
        onClose={toggleIsDeleteOrgUnitConfirmationModalOpen}
      />
    </>
  );
};

export default OrgUnitContextMenu;
