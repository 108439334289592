import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CloseOneThingCampaignMutationVariables = Types.Exact<{
  orgKey: Types.Scalars['String'];
  campaignId: Types.Scalars['ID'];
}>;


export type CloseOneThingCampaignMutation = { __typename: 'Mutation', saveOneThingCampaign: { __typename: 'OneThingCampaign', id: string, state: { __typename: 'CampaignState', stage: Types.CampaignStage } } };


export const CloseOneThingCampaignDocument = gql`
    mutation CloseOneThingCampaign($orgKey: String!, $campaignId: ID!) {
  saveOneThingCampaign(
    input: {orgKey: $orgKey, idToUpdate: $campaignId, event: CLOSE_BY_USER}
  ) {
    id
    state {
      stage
    }
  }
}
    `;
export type CloseOneThingCampaignMutationFn = Apollo.MutationFunction<CloseOneThingCampaignMutation, CloseOneThingCampaignMutationVariables>;

/**
 * __useCloseOneThingCampaignMutation__
 *
 * To run a mutation, you first call `useCloseOneThingCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseOneThingCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeOneThingCampaignMutation, { data, loading, error }] = useCloseOneThingCampaignMutation({
 *   variables: {
 *      orgKey: // value for 'orgKey'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useCloseOneThingCampaignMutation(baseOptions?: Apollo.MutationHookOptions<CloseOneThingCampaignMutation, CloseOneThingCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseOneThingCampaignMutation, CloseOneThingCampaignMutationVariables>(CloseOneThingCampaignDocument, options);
      }
export type CloseOneThingCampaignMutationHookResult = ReturnType<typeof useCloseOneThingCampaignMutation>;
export type CloseOneThingCampaignMutationResult = Apollo.MutationResult<CloseOneThingCampaignMutation>;
export type CloseOneThingCampaignMutationOptions = Apollo.BaseMutationOptions<CloseOneThingCampaignMutation, CloseOneThingCampaignMutationVariables>;