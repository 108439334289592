import { createContext } from 'react';

import type { ObjectivesByTheme } from 'objective/objective.utils';
import type { StrategyObjectivesPriorityFilter } from 'strategy/StrategyObjectives/StrategyObjectives.type';

import type {
  TeamInsightsOverviewObjective,
  TeamInsightsOverviewTheme,
  TeamInsightsOverviewDateFilter,
  TeamInsightsOverviewStatusFilter,
  TeamInsightsOverviewThemeFilter,
  TeamInsightsOverviewUserFilter,
  TeamInsightsOverviewUser,
} from './TeamInsightsOverviewProvider.type';

export type TeamInsightsOverviewProviderContextValue = {
  activeTheme?: Maybe<TeamInsightsOverviewTheme>;
  activeThemeObjectives: TeamInsightsOverviewObjective[];
  filteredObjectives: TeamInsightsOverviewObjective[];
  filters: {
    clearFilters: () => void;
    endDateFilter: TeamInsightsOverviewDateFilter;
    priorityFilter: StrategyObjectivesPriorityFilter;
    setEndDateFilter: (value: TeamInsightsOverviewDateFilter) => void;
    setPriorityFilter: (value: StrategyObjectivesPriorityFilter) => void;
    setStartDateFilter: (value: TeamInsightsOverviewDateFilter) => void;
    setStatusFilter: (newFilter: TeamInsightsOverviewStatusFilter) => void;
    setThemeFilter: (value: TeamInsightsOverviewThemeFilter) => void;
    setUserFilter: (value: TeamInsightsOverviewUserFilter) => void;
    startDateFilter: TeamInsightsOverviewDateFilter;
    statusFilter: TeamInsightsOverviewStatusFilter;
    themeFilter: TeamInsightsOverviewThemeFilter;
    userFilter: TeamInsightsOverviewUserFilter;
  };
  objectives: TeamInsightsOverviewObjective[];
  objectivesByTheme: Array<
    ObjectivesByTheme<TeamInsightsOverviewObjective, TeamInsightsOverviewTheme>
  >;
  users: TeamInsightsOverviewUser[];
};

export const TeamInsightsOverviewProviderContext =
  createContext<Maybe<TeamInsightsOverviewProviderContextValue>>(undefined);
