import { useNavigate, useParams } from 'react-router-dom';

import InsightReportSummary from 'report/InsightReportSummary/InsightReportSummary';

const StrategyInsightsReportsViewReport = () => {
  const navigate = useNavigate();
  const { reportId } = useParams() as {
    reportId: string;
  };

  return (
    <InsightReportSummary
      report={{ id: reportId }}
      isOpen={true}
      onClose={() => {
        navigate('../');
      }}
    />
  );
};

export default StrategyInsightsReportsViewReport;
