import { css } from '@emotion/react';

import Flex from 'shared/components/Flex';
import type { StatusComment } from 'types.graphql.generated';
import Caption from 'shared/components/Caption';
import type { CommentsFormValues } from 'shared/components/CommentsForm';

import CommentLine from './CommentLine';

type Comment = Pick<StatusComment, 'id' | 'text'>;

type Props = {
  comments: Comment[];
  formSection: keyof CommentsFormValues;
  title: string;
};

const CommentSection = ({ title, comments, formSection }: Props) => {
  const nonEmptyComments = comments.flatMap((comment) =>
    comment.text?.trim().length ? [{ id: comment.id, text: comment.text }] : [],
  );

  if (nonEmptyComments.length === 0) return null;

  return (
    <Flex direction={'column'} gap={8}>
      <Caption size={'C2'} color={'secondary'}>
        {title}
      </Caption>
      <Flex direction={'column'} gap={8} css={css({ marginLeft: 5 })}>
        {nonEmptyComments.map(({ id, text }) => (
          <CommentLine key={id} content={text} formSection={formSection} />
        ))}
      </Flex>
    </Flex>
  );
};

export default CommentSection;
