import type { FieldArrayRenderProps } from 'formik';
import { Field, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';

import Heading from 'shared/components/Heading';
import AutoFocus from 'shared/form/AutoFocus';
import FieldBox from 'shared/form/FieldBox';
import IconButton from 'shared/components/IconButton';
import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';
import { TextInputField } from 'shared/components/TextInput';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import type { StatusCommentType } from 'types.graphql.generated';
import { useForm } from 'shared/form/Form';

import styles from './StatusCommentsFormCommentsFieldArray.module.scss';
import type { StatusCommentsFormValues } from '../StatusCommentsForm.type';
import { createStatusCommentItem } from '../StatusCommentsForm.utils';

type StatusCommentsFormCommentsFieldArrayProps<Type extends StatusCommentType> =
  {
    hasTypeHeadings?: boolean;
    name: Type;
  };

const StatusCommentsFormCommentsFieldArray = <Type extends StatusCommentType>({
  name,
  hasTypeHeadings,
}: StatusCommentsFormCommentsFieldArrayProps<Type>) => {
  const { t } = useTranslation();

  const { values } = useForm<StatusCommentsFormValues<Type>>();

  const placeholders = t(`status.statusComments.${name}.placeholders`, {
    returnObjects: true,
  }) as Record<number | 'default', string>;

  const getFieldPlaceholder = (index: number) =>
    placeholders[index] || placeholders.default;

  const renderCommentField = (params: {
    fieldArrayProps: FieldArrayRenderProps;
    index: number;
    name: string;
  }) => {
    const { fieldArrayProps, index, name } = params;

    return (
      <AutoFocus key={index}>
        <FieldBox
          name={name}
          key={index}
          renderAfter={() => (
            <IconButton
              icon={CloseIcon}
              variant={'transparent'}
              onClick={() => {
                fieldArrayProps.remove(index);
              }}
            >
              {t('status.statusCommentsForm.removeCommentButton')}
            </IconButton>
          )}
        >
          <Field
            name={name}
            component={TextInputField}
            placeholder={getFieldPlaceholder(index)}
          />
        </FieldBox>
      </AutoFocus>
    );
  };

  return (
    <FieldArray name={name}>
      {(fieldArrayProps) => (
        <div className={styles.statusComments}>
          {hasTypeHeadings && (
            <Heading level={4} as={5}>
              {t(`status.statusComments.${name}.heading`)}
            </Heading>
          )}
          {values[name]?.map((_, valueIndex) => {
            const fieldName = `${name}.${valueIndex}.text`;
            return renderCommentField({
              name: fieldName,
              index: valueIndex,
              fieldArrayProps,
            });
          })}
          <IconButton
            variant={'outlined'}
            icon={PlusIcon}
            onClick={() => {
              fieldArrayProps.push(
                createStatusCommentItem({
                  type: name,
                }),
              );
            }}
            className={styles.addButton}
          >
            {t('status.statusCommentsForm.addCommentButton')}
          </IconButton>
        </div>
      )}
    </FieldArray>
  );
};

export default StatusCommentsFormCommentsFieldArray;
