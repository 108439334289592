import cn from 'classnames';
import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useToggle } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';

import StatusMark from 'shared/status/StatusMark';
import { ReactComponent as MilestoneIcon } from 'shared/static/icons/icon-milestone.svg';
import { ReactComponent as ChevronIcon } from 'shared/static/icons/icon-chevron.svg';
import type { InitiativeOverviewInitiative } from 'initiative/InitiativeOverview/InitiativeOverview.type';
import CompletionRate from 'shared/components/CompletionRate';
import { date } from 'shared/services/date.service';
import { expandChildAnimation } from 'shared/animations/expandChild.animation';
import type { InitiativeOverviewInitiativeMilestoneFragment } from 'initiative/InitiativeOverview/InitiativeOverviewProvider/InitiativeOverviewProvider.graphql.generated';

import styles from './MilestoneInsightCard.module.scss';
import MilestoneInsightContextMenu from './MilestoneInsightCardContextMenu';

export type MilestoneInsightCardProps = PropsWithChildren<{
  className?: string;
  initiative: InitiativeOverviewInitiative;
  milestone: InitiativeOverviewInitiativeMilestoneFragment;
}>;

const MilestoneInsightCard = ({
  className,
  milestone,
  initiative,
}: MilestoneInsightCardProps) => {
  const [isExpanded, toggleIsExpanded] = useToggle(false);

  const { name, comment, description, currentMilestoneStatus, timeLine } =
    milestone;

  const timeline = useMemo(
    () => ({
      ...timeLine,
      __typename: 'TimeLine',
      startDate: timeLine?.startDate ?? initiative.timeLine.startDate,
    }),
    [initiative.timeLine.startDate, timeLine],
  );

  const formatedDate = useMemo(
    () =>
      timeline?.completeDateTime
        ? date.formatShort(timeline.completeDateTime)
        : timeline?.endDate && date.formatShort(timeline.endDate),
    [timeline],
  );

  const descriptionText = description ?? comment;

  const hasDescription = !!descriptionText;

  return (
    <motion.article
      {...expandChildAnimation}
      className={cn(styles.container, className)}
    >
      <section className={styles.mainContainer}>
        <div className={styles.statusContainer}>
          <MilestoneIcon className={styles.milestoneIcon} />
          <StatusMark
            statusIndicator={currentMilestoneStatus?.statusIndicator}
            hasBorder={false}
          />
        </div>
        <div
          className={cn(styles.nameContainer, {
            [styles.isClickable]: hasDescription,
          })}
          onClick={toggleIsExpanded}
        >
          <p className={styles.name}>{name}</p>
          {hasDescription && (
            <ChevronIcon
              className={cn(styles.dropdownIcon, {
                [styles.isExpanded]: isExpanded,
              })}
            />
          )}
        </div>
        <div className={styles.date}>{formatedDate}</div>
        <CompletionRate
          complete={currentMilestoneStatus?.statusIndicator.isCompleted}
          completionRate={currentMilestoneStatus?.completionRate}
        />
        {milestone && initiative && (
          <MilestoneInsightContextMenu
            milestone={milestone}
            initiative={initiative}
          />
        )}
      </section>
      {hasDescription && (
        <AnimatePresence>
          {isExpanded && (
            <motion.section
              {...expandChildAnimation}
              className={styles.descriptionContainer}
            >
              <p className={styles.description}>{descriptionText}</p>
            </motion.section>
          )}
        </AnimatePresence>
      )}
    </motion.article>
  );
};

export default MilestoneInsightCard;
