import styled from '@emotion/styled';

const Icon = styled.div`
  align-items: center;
  background: ${(props) => props.theme.legacyColor.colorLightOragne};
  border-radius: 12px;
  display: flex;
  font-size: 1.571rem;
  height: 40px;
  justify-content: center;
  width: 40px;
`;

export default Icon;
