import cn from 'classnames';
import type { PropsWithChildren } from 'react';

import styles from './ContributionSectionInnerList.module.scss';

type ContributionSectionInnerListProps = PropsWithChildren<{
  className?: string;
}>;

const ContributionSectionInnerList = ({
  children,
  className,
}: ContributionSectionInnerListProps) => (
  <ul className={cn(styles.innerList, className)}>{children}</ul>
);

export default ContributionSectionInnerList;
