import { useCallback } from 'react';
import type { User } from 'firebase/auth';
import { trackPromise } from 'react-promise-tracker';
import {
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';
import { useLocalStorage } from 'react-use';

import { createFinishSignUpUrl } from './useSignUpWithEmail.utils';

const auth = getAuth();

const signUpEmailStorageKey = 'signUpEmail';

export const signUpWithEmailPromiseTrackerArea =
  'signUpWithEmailPromiseTrackerArea';

type UseSignUpWithEmailReturnType = {
  email?: string;
  sendSignUpLink: (email: string) => Promise<void>;
  setEmail: (email: string) => void;
  signUpWithEmailLink: () => Promise<{ accessToken: string }>;
};

export const useSignUpWithEmail = (): UseSignUpWithEmailReturnType => {
  const [email, setEmail, removeEmail] = useLocalStorage<string>(
    signUpEmailStorageKey,
  );

  const sendSignUpLink = useCallback<
    UseSignUpWithEmailReturnType['sendSignUpLink']
  >(
    (email) => {
      const url = createFinishSignUpUrl();

      return trackPromise(
        sendSignInLinkToEmail(auth, email, {
          url,
          handleCodeInApp: true,
        }).then(() => {
          setEmail(email);
        }),
        signUpWithEmailPromiseTrackerArea,
      );
    },
    [setEmail],
  );

  const signUpWithEmailLink = useCallback<
    UseSignUpWithEmailReturnType['signUpWithEmailLink']
  >(() => {
    if (isSignInWithEmailLink(auth, window.location.href) && email) {
      return signInWithEmailLink(auth, email, window.location.href).then(
        (signInResult) => {
          const accessToken = (
            signInResult.user as User & { accessToken: string }
          ).accessToken;
          removeEmail();
          return {
            accessToken,
          };
        },
      );
    } else {
      throw new Error('Incoming link is not suitable for logging in');
    }
  }, [email, removeEmail]);

  return {
    email,
    setEmail,
    sendSignUpLink,
    signUpWithEmailLink,
  };
};
