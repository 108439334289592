import cn from 'classnames';
import type { PropsWithChildren } from 'react';

import styles from './EmojiBadge.module.scss';

type EmojiBadgeProps = PropsWithChildren<{
  className?: string;
}>;

const EmojiBadge = ({ className, children }: EmojiBadgeProps) => (
  <div className={cn(styles.emojiBadge, className)}>
    <span role={'img'} className={styles.emojiBadgeContent}>
      {children}
    </span>
  </div>
);

export default EmojiBadge;
