import cn from 'classnames';
import type { HTMLAttributes } from 'react';
import type { Merge } from 'type-fest';

import styles from './Paragraph.module.scss';

type ParagraphProps = Merge<
  HTMLAttributes<HTMLParagraphElement>,
  {
    hasMargin?: boolean;
  }
>;

const Paragraph = ({
  hasMargin = true,
  className,
  ...restProps
}: ParagraphProps) => (
  <p
    {...restProps}
    className={cn(styles.paragraph, { [styles.paragraphNoMargin]: !hasMargin })}
  />
);

export default Paragraph;
