import { useDefinedContext } from 'shared/utils/context.utils';

import { OrgUnitCardProviderContext } from './OrgUnitCardProvider.context';
import OrgUnitCardProvider from './OrgUnitCardProvider';

export const useOrgUnitCard = () =>
  useDefinedContext(OrgUnitCardProviderContext, {
    providerName: OrgUnitCardProvider.name,
    hookName: useOrgUnitCard.name,
  });
