import type { FieldReadFunction } from '@apollo/client';

import { date } from 'shared/services/date.service';

export const readDateField: FieldReadFunction<unknown, Date | null> = (
  value,
) => {
  if (typeof value === 'string') {
    return date.parse(value, 'yyyy-MM-dd');
  }
  if (date.isDate(value)) {
    return value;
  }
  if (!date.isValid(value)) {
    return null;
  }
  return null;
};

export const readDateTimeField: FieldReadFunction<unknown, Date | null> = (
  value,
) => {
  if (typeof value === 'string') {
    return date.parseISO(value);
  }
  if (date.isDate(value)) {
    return value;
  }
  if (!date.isValid(value)) {
    return null;
  }
  return null;
};
