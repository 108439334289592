import { useTranslation } from 'react-i18next';

import Sidesheets from 'shared/sidesheets/Sidesheets';
import InfoCard from 'shared/components/InfoCard';

import type { InsightReportWizardStepId } from '../InsightReportWizard.type';

type InsightReportWizardInfoCardProps = {
  wizardStep: InsightReportWizardStepId;
};

const InsightReportWizardInfoCard = ({
  wizardStep,
}: InsightReportWizardInfoCardProps) => {
  const { t } = useTranslation();

  return (
    <InfoCard
      title={t(`report.insightReportWizard.${wizardStep}.infoCard.heading`)}
      sideSheetTitle={t('sidesheets.context.objective.heading')}
      contentKey={`report.insightReportWizard.${wizardStep}.infoCard.content`}
      orgTermKey={`strategy.${wizardStep}`}
    >
      <Sidesheets context={'report'} wizardStep={wizardStep} />
    </InfoCard>
  );
};

export default InsightReportWizardInfoCard;
