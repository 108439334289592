import cn from 'classnames';
import type { HTMLAttributes } from 'react';
import type { Merge } from 'type-fest';

import styles from './Heading.module.scss';

type HeadingElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

export type Level = 1 | 2 | 3 | 4 | 5;

const getHeadingElement = (level: Level) => {
  const elements: Record<Level, HeadingElement> = {
    1: 'h1',
    2: 'h2',
    3: 'h3',
    4: 'h4',
    5: 'h5',
  };
  return elements[level];
};

export type HeadingProps = Merge<
  HTMLAttributes<HTMLHeadingElement>,
  {
    as?: Level;
    color?: 'primary' | 'regular';
    ellipsis?: boolean;
    hasMargin?: boolean;
    isBold?: boolean;
    level: Level;
  }
>;

const Heading = ({
  hasMargin = true,
  ellipsis = false,
  level,
  as,
  color = 'regular',
  children,
  className,
  isBold = true,
  ...restProps
}: HeadingProps) => {
  const styleLevel = as || level;
  const Element = getHeadingElement(level);

  return (
    <Element
      {...restProps}
      className={cn(
        styles.heading,
        {
          [styles.headingLevel1]: styleLevel === 1,
          [styles.headingLevel2]: styleLevel === 2,
          [styles.headingLevel3]: styleLevel === 3,
          [styles.headingLevel4]: styleLevel === 4,
          [styles.headingLevel5]: styleLevel === 5,
          [styles.headingBold]: isBold,
          [styles.headingNoMargin]: !hasMargin,
          [styles.headingEllipsis]: ellipsis,
          [styles.headingColorRegular]: color === 'regular',
          [styles.headingColorPrimary]: color === 'primary',
        },
        className,
      )}
    >
      {children}
    </Element>
  );
};

export default Heading;
