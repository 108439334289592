import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { sortBy } from 'lodash';
import type { CellProps } from 'react-table';
import { css, useTheme } from '@emotion/react';

import TableView from 'shared/components/TableView';
import type { Objective } from 'types.graphql.generated';
import { roundPercent } from 'shared/utils/percentage.utils';
import Flex from 'shared/components/Flex';
import Text from 'shared/components/Text';
import type { StatusIndicator } from 'shared/status';
import StatusMark from 'shared/status/StatusMark';
import ProgressBar from 'shared/components/ProgressBar/ProgressBar';

import type {
  OneThingCampaignResultsCampaignFragment,
  OneThingCampaignResultsObjectiveFragment,
  OneThingCampaignResultsTaskFragment,
} from './OneThingCampaignResults.graphql.generated';

const tableStyle = css`
  tr {
    th:last-child {
      display: flex;
      justify-content: flex-end;
      padding-right: 0;
      width: auto !important;
    }
  }
`;

type ObjectiveRow = {
  name?: string;
  progress: {
    completionRate: number;
    taskCount: number;
  };
  rowNumber: number;
  status?: StatusIndicator;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-left: 1.333rem;
  padding-right: 1.333rem;
  padding-bottom: 1.333rem;

  & > div {
    width: 100%;
  }
`;

type Props = {
  campaign: OneThingCampaignResultsCampaignFragment;
};

const ObjectivesSection = ({ campaign }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const items = campaign.tasks.reduce<
    Record<
      Objective['id'],
      {
        objective: OneThingCampaignResultsObjectiveFragment;
        tasks: OneThingCampaignResultsTaskFragment[];
      }
    >
  >((result, task) => {
    const objectiveResult = result[task.objective.id];

    return {
      ...result,
      [task.objective.id]: {
        objective: task.objective,
        tasks: objectiveResult ? [...objectiveResult.tasks, task] : [task],
      },
    };
  }, {});

  const sortedItems = sortBy(
    Object.values(items),
    ({ tasks }) => tasks.length,
  ).reverse();

  const data = sortedItems.map((item, index) => {
    const taskCount = item.tasks.length;

    const completedTasksCount = item.tasks.filter(
      (task) => task.status === 'DONE',
    ).length;

    const completionRate = roundPercent(
      (completedTasksCount / taskCount) * 100,
    );

    return {
      name: item.objective.name,
      rowNumber: index + 1,
      progress: { completionRate, taskCount },
      status: item.objective.currentObjectiveStatus?.statusIndicator,
    };
  });

  if (data.length === 0) {
    return (
      <Container>
        <Text css={css({ padding: '2rem' })}>
          {t('toolkit.toolPage.oneThing.campaignResults.objectives.empty')}
        </Text>
      </Container>
    );
  }

  return (
    <Container>
      <TableView<ObjectiveRow>
        hasBorder={false}
        data={data}
        css={tableStyle}
        columns={[
          {
            Header: t(
              'toolkit.toolPage.oneThing.campaignResults.objectives.table.rowNumber.header',
            ),
            accessor: 'rowNumber',
            Cell: (table: CellProps<ObjectiveRow>) => <>{table.value}</>,
          },
          {
            Header: '',
            accessor: 'status',
            Cell: (table: CellProps<ObjectiveRow>) => (
              <StatusMark statusIndicator={table.value} />
            ),
          },
          {
            Header: t('objective.objective'),
            accessor: 'name',
            Cell: (table: CellProps<ObjectiveRow>) => <>{table.value}</>,
            width: '100%',
          },
          {
            Header: t(
              'toolkit.toolPage.oneThing.campaignResults.objectives.table.progressStats.header',
            ),
            accessor: 'progress',
            Cell: (table: CellProps<ObjectiveRow>) => (
              <Flex justifyContent={'space-between'} alignItems={'center'}>
                <Flex css={css({ width: 200 })}>
                  <ProgressBar
                    ratio={table.value.completionRate}
                    backgroundColor={theme.color.primary}
                    fillColor={theme.color.success}
                    size={'big'}
                  />
                </Flex>
                <Flex justifyContent={'flex-end'} css={css({ minWidth: 100 })}>
                  <Text color={'black'}>{table.value.taskCount}</Text>
                  <Text css={css({ color: theme.color.neutral2 })}>
                    {'  /  '}
                  </Text>
                  <Text color={'black'} css={css({ minWidth: 31 })}>
                    {table.value.completionRate}
                    {'%'}
                  </Text>
                </Flex>
              </Flex>
            ),
          },
        ]}
      />
    </Container>
  );
};

export default ObjectivesSection;
