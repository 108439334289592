import type { ComponentType } from 'react';

import type { InitialStep } from './Steps.type';
import Steps from './Steps';

export const withSteps = <ComponentProps extends object>(
  Component: ComponentType<ComponentProps>,
  initialSteps: InitialStep[],
) => {
  const ComponentWithSteps = (props: ComponentProps) => (
    <Steps initialSteps={initialSteps}>
      <Component {...props} />
    </Steps>
  );
  return ComponentWithSteps;
};
