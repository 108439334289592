import type { MutationUpdaterFunction, ApolloCache } from '@apollo/client';

import type {
  UseDeleteReportDeleteReportMutation,
  UseDeleteReportDeleteReportMutationVariables,
} from './useDeleteReport.graphql.generated';

export const evictReport: MutationUpdaterFunction<
  UseDeleteReportDeleteReportMutation,
  UseDeleteReportDeleteReportMutationVariables,
  unknown,
  ApolloCache<unknown>
> = (cache, _, { variables }) => {
  if (variables) {
    cache.evict({
      id: `Report:${variables.reportId}`,
    });
  }
};
