import { useTheme } from '@emotion/react';
import { intervalToDuration } from 'date-fns';
import { useTranslation } from 'react-i18next';

import Flex from 'shared/components/Flex';
import ProgressBar from 'shared/components/ProgressBar/ProgressBar';
import { ReactComponent as TimeStoperIcon } from 'shared/static/icons/icon-time-stoper.svg';

type Props = {
  deadline: Date;
};

const TimeLeft = ({ deadline }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const duration = intervalToDuration({ start: new Date(), end: deadline });

  return (
    <Flex direction={'column'} gap={'0.4rem'}>
      <Flex gap={'0.2rem'}>
        <div>
          <TimeStoperIcon color={theme.color.neutral2} />
        </div>
        <div>
          {duration.days}
          {t('toolkit.toolPage.oneThing.updateTask.time.days')}
        </div>
        <div>:</div>
        <div>
          {duration.hours}
          {t('toolkit.toolPage.oneThing.updateTask.time.hours')}
        </div>
        <div>{t('toolkit.toolPage.oneThing.updateTask.time.left')}</div>
      </Flex>
      <ProgressBar ratio={50} />
    </Flex>
  );
};

export default TimeLeft;
