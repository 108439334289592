import cn from 'classnames';
import type { HeaderGroup } from 'react-table';

import { ReactComponent as ChevronIcon } from 'shared/static/icons/icon-chevron.svg';

import styles from './TableViewHeader.module.scss';
import type { BaseTableViewItem } from '../TableView.type';

type TableViewHeaderProps<Item extends BaseTableViewItem> = {
  headerGroups: HeaderGroup<Item>[];
  sortable: boolean;
};

const TableViewHeader = <Item extends BaseTableViewItem>({
  sortable,
  headerGroups,
}: TableViewHeaderProps<Item>) => {
  const renderHeader = (
    header: HeaderGroup<Item>,
    headerIndex: number,
    headersArray: HeaderGroup<Item>[],
  ) => {
    const headerProps = header.getHeaderProps(
      sortable ? header.getSortByToggleProps() : undefined,
    );
    const isFirstHeader = headerIndex === 0;
    const isLastHeader = headerIndex === headersArray.length - 1;
    return (
      <th
        {...headerProps}
        className={cn(styles.header, headerProps.className, {
          [styles.headerSortable]: sortable,
          [styles.headerFirst]: isFirstHeader,
          [styles.headerLast]: isLastHeader,
        })}
        style={{
          minWidth: header.minWidth,
          width: header.width,
          maxWidth: header.maxWidth,
        }}
        key={headerProps.key}
      >
        <div className={styles.headerContent}>
          <div>{header.render('Header')}</div>
          <ChevronIcon
            className={cn(styles.headerIcon, {
              [styles.headerIconRotated]: header.isSortedDesc,
              [styles.headerIconHidden]: !header.isSorted,
            })}
          />
        </div>
      </th>
    );
  };

  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr
          {...headerGroup.getHeaderGroupProps()}
          key={headerGroup.getHeaderGroupProps().key}
        >
          {headerGroup.headers.map(renderHeader)}
        </tr>
      ))}
    </thead>
  );
};

export default TableViewHeader;
