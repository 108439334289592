import compact from 'lodash/compact';

import {
  getAllStatusIndicators,
  StatusIndicator,
} from 'shared/status/StatusIndicator';

import type { StatusMultiSelectItem } from '../StatusMultiSelect.type';

export const getItems = (): StatusMultiSelectItem[] => {
  const statusIndicators = getAllStatusIndicators();
  const itemsWithValues = compact(
    statusIndicators.map((statusIndicator) => {
      if (statusIndicator.value) {
        return {
          id: statusIndicator.value,
          statusIndicator,
        };
      }
    }),
  );
  return [
    {
      id: 'ALL',
      statusIndicator: null,
    },
    {
      id: 'UNKNOWN',
      statusIndicator: new StatusIndicator(),
    },
    ...itemsWithValues,
  ];
};
