import { useMemo } from 'react';

import type { Step } from 'shared/components/Steps/Steps.type';

import StepsNavigationButtons from './StepsNavigationButtons';
import StepsNavigationTabs from './StepsNavigationTabs';

export type StepsNavigationProps = {
  className?: string;
  onClickStep?: (step: Step) => void;
  variant?: 'buttons' | 'tabs';
};

const StepsNavigation = ({
  variant = 'buttons',
  ...restProps
}: StepsNavigationProps) => {
  const Component = useMemo(() => {
    switch (variant) {
      case 'buttons': {
        return StepsNavigationButtons;
      }
      case 'tabs': {
        return StepsNavigationTabs;
      }
    }
  }, [variant]);

  return <Component {...restProps} />;
};

export default StepsNavigation;
