import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { ReactComponent as ChevronDownIcon } from 'shared/static/icons/icon-chevron-down.svg';

import type { FollowUpActivityItemStatusRequest } from './ContributionMyFollowUpsViewMore.type';
import TimelineEntryContentSummary from './TimelineEntryContentSummary';
import TimelineEntryContentStatusRequestBody from './TimelineEntryContentStatusRequestBody';

const Items = styled.div`
  color: ${(props) => props.theme.color.neutral1};
  flex-grow: 1;
  font-size: 0.857rem;
  font-weight: 700;
  max-width: 152px;
  text-align: right;
  text-transform: uppercase;
`;

const Chevron = styled.div<{ isExpanded: boolean }>`
  color: ${(props) => props.theme.color.neutral1};
  display: flex;
  svg {
    transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    width: 0.67rem;
    transform: ${(props) => (props.isExpanded ? 'rotateX(180deg)' : 'none')};
  }
`;

const TimelineEntryContentStatusRequest = ({
  item,
}: {
  item: FollowUpActivityItemStatusRequest;
}) => {
  const { t } = useTranslation();
  const [isExpanded, toggleIsExpanded] = useToggle(false);

  const user = item.requestSubmitter.displayName || item.requestSubmitter.email;

  const pendingStatusRequests = item.statusRequests.filter(
    (statusRequest) => !statusRequest.isComplete,
  );

  const totalStatusRequests = item.statusRequests.length;
  const completedStatuRequests =
    totalStatusRequests - pendingStatusRequests.length;

  return (
    <>
      <TimelineEntryContentSummary
        item={item}
        icon={t('followUps.timeline.insightRequest.icon')}
        typeLabel={t('followUps.timeline.insightRequest.type')}
        description={t('followUps.timeline.insightRequest.description', {
          user,
          count: item.statusRequests.length,
        })}
        onClick={toggleIsExpanded}
      >
        <Items>
          {t('followUps.timeline.insightRequest.items', {
            completedCount: completedStatuRequests,
            count: totalStatusRequests,
          })}
        </Items>

        <Chevron isExpanded={isExpanded}>
          <ChevronDownIcon />
        </Chevron>
      </TimelineEntryContentSummary>

      {isExpanded && (
        <TimelineEntryContentStatusRequestBody
          statusRequests={item.statusRequests}
        />
      )}
    </>
  );
};

export default TimelineEntryContentStatusRequest;
