import type { PropsWithChildren, ReactNode } from 'react';
import { useState } from 'react';
import { css } from '@emotion/react';

import { ReactComponent as ChevronDownIcon } from 'shared/static/icons/icon-chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'shared/static/icons/icon-chevron-up.svg';

import FormContainer from './FormContainer';
import FormContainerHeader from './FormContainerHeader';
import FormContainerBody from './FormContainerBody';
import FormContainerSubHeader from './FormContainerSubHeader';

const pointerStyle = css`
  cursor: pointer;
`;

const collapsedStyle = css`
  border-bottom: none;
`;

const iconStyle = css`
  width: 12px;
  height: 12px;
`;

type Props = PropsWithChildren<{
  hasContentPadding?: boolean;
  isExpandable?: boolean;
  isOptional?: boolean;
  subHeader?: ReactNode;
  title: string;
}>;

const ExpandableFormContainer = ({
  title,
  isOptional,
  subHeader,
  children,
  isExpandable = true,
  hasContentPadding = true,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(!isExpandable);

  return (
    <FormContainer>
      <FormContainerHeader
        title={title}
        isOptional={isOptional}
        css={[isExpandable && pointerStyle, !isExpanded && collapsedStyle]}
        onClick={() => isExpandable && setIsExpanded(!isExpanded)}
      >
        {isExpandable ? (
          isExpanded ? (
            <ChevronUpIcon css={iconStyle} />
          ) : (
            <ChevronDownIcon css={iconStyle} />
          )
        ) : null}
      </FormContainerHeader>

      <FormContainerSubHeader>{subHeader}</FormContainerSubHeader>

      {isExpanded && (
        <FormContainerBody $hasContentPadding={hasContentPadding}>
          {children}
        </FormContainerBody>
      )}
    </FormContainer>
  );
};

export default ExpandableFormContainer;
