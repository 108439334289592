import cn from 'classnames';

import type { MenuRowComponentProps } from 'shared/components/Menu';
import Space from 'shared/components/Space';
import Text from 'shared/components/Text';

import styles from './ContextMenuRow.module.scss';
import type { ContextMenuItem } from '../ContextMenu.type';

type ContextMenuRowProps<
  ContextMenuItemParam extends ContextMenuItem = ContextMenuItem,
> = MenuRowComponentProps<ContextMenuItemParam>;

const ContextMenuRow = <
  ContextMenuItemParam extends ContextMenuItem = ContextMenuItem,
>({
  item,
}: ContextMenuRowProps<ContextMenuItemParam>) => {
  const { icon: Icon, variant, title } = item;
  return (
    <Space
      size={'custom'}
      value={'0.75em'}
      className={cn(styles.contextMenuRow, {
        [styles.contextMenuRowVariantDanger]: variant === 'danger',
      })}
    >
      {Icon ? <Icon className={styles.contextMenuRowIcon} /> : null}
      <Text variant={'emphasis'} className={styles.contextMenuRowText}>
        {title}
      </Text>
    </Space>
  );
};

export default ContextMenuRow;
