import { memo } from 'react';
import { Trans } from 'react-i18next';
import cn from 'classnames';

import { date } from 'shared/services/date.service';
import type { StatusIndicator } from 'shared/status/StatusIndicator';
import Space from 'shared/components/Space';
import StatusMark from 'shared/status/StatusMark';
import Text from 'shared/components/Text';
import type { AuditRecordUser } from 'types.graphql.generated';
import AuditRecordUserAvatar from 'user/AuditRecordUserAvatar/AuditRecordUserAvatar';

import styles from './UserUpdateStatus.module.scss';

type UserUpdateStatusProps = {
  auditRecordUser?: Maybe<AuditRecordUser>;
  className?: string;
  hasStatusMark?: boolean;
  statusIndicator?: StatusIndicator;
  updateDateTime?: Date | null;
};

const UserUpdateStatus = ({
  auditRecordUser,
  updateDateTime,
  statusIndicator,
  hasStatusMark = true,
  className,
}: UserUpdateStatusProps) => {
  const updateDate = updateDateTime ? (
    <Text>
      <Trans
        i18nKey={'updatedAt'}
        values={{
          date: date.formatShort(updateDateTime),
        }}
      >
        <Text variant={'emphasis'} />
      </Trans>
    </Text>
  ) : null;

  const statusMark = hasStatusMark ? (
    <StatusMark statusIndicator={statusIndicator} />
  ) : null;

  return (
    <Space className={cn(styles.userUpdateStatus, className)}>
      {auditRecordUser ? (
        <AuditRecordUserAvatar
          auditRecordUser={auditRecordUser}
          hasLabel={true}
        />
      ) : null}
      {updateDate || statusMark ? (
        <Space isCentered={true}>
          {updateDate}
          {statusMark}
        </Space>
      ) : null}
    </Space>
  );
};

const MemoizedUserUpdateStatus = memo(UserUpdateStatus);

export default MemoizedUserUpdateStatus;
