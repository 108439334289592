import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Heading from 'shared/components/Heading';

const HeadingTitle = styled.div`
  text-align: center;
`;

const HeadingSubtitle = styled.div`
  color: ${({ theme }) => theme.color.typoSecondary};
  font-size: 1.142rem;
  margin-top: 10px;
  text-align: center;
`;

const Header = () => {
  const { t } = useTranslation();

  return (
    <div>
      <HeadingTitle>
        <Heading hasMargin={false} level={3}>
          {t('strategy.pyramid.header.title')}
        </Heading>
      </HeadingTitle>
      <HeadingSubtitle>{t('strategy.pyramid.header.subtitle')}</HeadingSubtitle>
    </div>
  );
};

export default Header;
