import ReactSelect, { defaultTheme } from 'react-select';
import type {
  Props as ReactSelectProps,
  GroupBase as ReactSelectGroupBase,
} from 'react-select';
import hexToRgba from 'hex-to-rgba';
import { useTheme, type Theme, css } from '@emotion/react';
import { useState } from 'react';
import { Dialog } from 'react-aria-components';

import type { Size } from 'shared/types/size.type';

import SelectIndicatorSeparator from './SelectIndicatorSeparator';
import SelectDropdownIndicator from './SelectDropdownIndicator';
import SelectControl from './SelectControl';
import SelectValueContainer from './SelectValueContainer';
import SelectNoOptionsMessage from './SelectNoOptionsMessage';
import SelectMenu from './SelectMenu';

// todo confirm Select styles with design

const getReactSelectTheme = (theme: Theme): ReactSelectProps['theme'] => ({
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    primary: theme.color.primary,
    primary75: hexToRgba(theme.color.primary, 0.75),
    primary50: hexToRgba(theme.color.primary, 0.5),
    primary25: hexToRgba(theme.color.primary, 0.25),
    danger: theme.color.error,
    dangerLight: hexToRgba(theme.color.error, 0.5),
  },
});

const selectSizes = {
  tiny: '11rem',
  small: '14rem',
  medium: '20rem',
  big: '28rem',
  full: '100%',
};

const components = {
  Control: SelectControl,
  DropdownIndicator: SelectDropdownIndicator,
  IndicatorSeparator: SelectIndicatorSeparator,
  ValueContainer: SelectValueContainer,
  NoOptionsMessage: SelectNoOptionsMessage,
  Menu: SelectMenu,
};

export type SelectProps<
  Option extends object | unknown = unknown,
  IsMulti extends boolean = boolean,
  Group extends ReactSelectGroupBase<Option> = ReactSelectGroupBase<Option>,
> = Omit<
  ReactSelectProps<Option, IsMulti, Group>,
  'isRtl' | 'theme' | 'components'
> & {
  ariaDialog?: boolean;
  hasError?: boolean;
  inlineLabel?: string;
  size?: Extract<Size, 'medium' | 'big' | 'small' | 'tiny'> | 'full';
};

/**
 * @deprecated Use `shared/components/Select` instead
 */
const Select = <
  Option extends object | unknown = unknown,
  IsMulti extends boolean = boolean,
  Group extends ReactSelectGroupBase<Option> = ReactSelectGroupBase<Option>,
>({
  size = 'medium',
  className,
  inlineLabel,
  hasError,
  ariaDialog = true,
  ...restProps
}: SelectProps<Option, IsMulti, Group>) => {
  const theme = useTheme();
  const [menuContainer, setMenuContainer] = useState<HTMLDivElement | null>(
    null,
  );

  return (
    <>
      <ReactSelect
        isSearchable={false}
        {...restProps}
        isRtl={false}
        theme={getReactSelectTheme(theme)}
        // @ts-ignore: components prop emits types error because Select generic types are not passed to components.
        // Passing generics to every component will bloat code and with limited functionality of Select it won't be very beneficial
        components={components}
        menuPortalTarget={menuContainer}
        css={css`
          width: ${selectSizes[size]};

          & > div:focus-within {
            box-shadow: 0 0 0 2px ${theme.color.primary} !important;
          }

          [class*='singleValue'],
          [id*='placeholder'] {
            &::before {
              color: ${theme.legacyColor.colorDoveGray};
              content: ${inlineLabel ? `"${inlineLabel}"` : 'none'};
              font-size: 0.875rem;
              margin-right: 0.5rem;
            }
          }
        `}
        className={className}
        aria-invalid={hasError}
      />

      {ariaDialog && (
        <Dialog
          css={css`
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            pointer-events: none;

            & > * {
              pointer-events: all;
            }
          `}
        >
          <div ref={setMenuContainer} />
        </Dialog>
      )}
    </>
  );
};

export default Select;
