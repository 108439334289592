import { useActiveOrg } from 'org/ActiveOrgProvider';
import type { OrgAuthorizedAction } from 'types.graphql.generated';

import { canPerformOrgAction } from './canPerformOrgAction';

export default function useCanPerformOrgAction(action: OrgAuthorizedAction) {
  const { activeOrg } = useActiveOrg();

  return canPerformOrgAction(activeOrg, action);
}
