import { useCallback, useMemo } from 'react';

import type { ChipGroupProps } from 'shared/components/ChipGroup';
import ChipGroup from 'shared/components/ChipGroup';

import type { PriorityMultiSelectItem } from '../PriorityMultiSelect.type';
import { usePriorityMultiSelect } from '../PriorityMultiSelectProvider';

type PriorityMultiSelectChipGroupProps = {
  className?: string;
};

const PriorityMultiSelectChipGroup = ({
  className,
}: PriorityMultiSelectChipGroupProps) => {
  const { state, items } = usePriorityMultiSelect();

  const handleRemove = useCallback<
    ChipGroupProps<PriorityMultiSelectItem>['onRemove']
  >(
    (item) => {
      if (state.selectionManager.selectedKeys.size === 1) {
        state.selectionManager.toggleSelection('ALL');
      } else {
        state.selectionManager.toggleSelection(item.id);
      }
    },
    [state],
  );

  const handleRemoveAll = useCallback<
    NonNullable<ChipGroupProps<PriorityMultiSelectItem>['onRemoveAll']>
  >(() => {
    state.selectionManager.replaceSelection('ALL');
  }, [state]);

  const renderLabel = useCallback<
    NonNullable<ChipGroupProps<PriorityMultiSelectItem>['renderLabel']>
  >((item) => item.label, []);

  const chipGroupItems = useMemo(
    () =>
      items.filter(
        (item) => item.label && state.selectionManager.isSelected(item.id),
      ),
    [items, state],
  );

  return (
    <ChipGroup<PriorityMultiSelectItem>
      items={chipGroupItems}
      onRemove={handleRemove}
      onRemoveAll={chipGroupItems.length ? handleRemoveAll : undefined}
      renderLabel={renderLabel}
      className={className}
    />
  );
};

export default PriorityMultiSelectChipGroup;
