import { createContext } from 'react';

import type { ContributionMyFollowUpsOneThingCampaignFragment } from 'contribution/ContributionMyFollowUpsViewMore/ContributionMyFollowUpsViewMore.graphql.generated';

import type { StrategyRoadmapObjectivesByTheme } from '../StrategyRoadmap.type';
import type {
  StrategyGuideFieldsFragment,
  StrategyRoadmapStrategyFieldsFragment,
} from './StrategyRoadmapProvider.graphql.generated';

export type StrategyRoadmapProviderContextValue = {
  objectivesByTheme: StrategyRoadmapObjectivesByTheme[];
  oneThingCampaigns: ContributionMyFollowUpsOneThingCampaignFragment[];
  strategy: StrategyRoadmapStrategyFieldsFragment & StrategyGuideFieldsFragment;
};

export const StrategyRoadmapProviderContext = createContext<
  StrategyRoadmapProviderContextValue | undefined
>(undefined);
