import styled from '@emotion/styled';
import { differenceInDays } from 'date-fns';
import { useTranslation } from 'react-i18next';

import Heading from 'shared/components/Heading';
import Text from 'shared/components/Text';
import { useCampaignResults } from 'toolkit/WalkOfStrategyCampaignResultsLayout/CampaignResultsProvider';
import { roundPercent } from 'shared/utils/percentage.utils';

import OverviewSectionGraph from './OverviewSectionGraph';

const InfoContainer = styled.div`
  display: flex;
  gap: 20px;
  padding-left: 1.333rem;
  padding-right: 1.333rem;
  padding-bottom: 1.333rem;
`;

const InfoBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  background-color: ${(props) => props.theme.color.backgroundLight};
  flex-basis: 0;
  flex-grow: 1;
  padding-top: 1.333rem;
  padding-bottom: 1.333rem;
  border-radius: 1.333rem;
`;

const GraphContainer = styled.div`
  padding: 1.333rem;
`;

const OverviewSection = () => {
  const { t } = useTranslation();
  const { campaign } = useCampaignResults();

  const daysActive = differenceInDays(
    campaign.state.stage === 'ACTIVE' ? new Date() : campaign.timeLine.endDate,
    campaign.timeLine.startDate,
  );

  const percentEngaged = roundPercent(campaign.completionRate.percentEngaged);

  return (
    <>
      <InfoContainer>
        <InfoBox>
          <Heading level={3} hasMargin={false}>
            {daysActive}
          </Heading>
          <Text size={'small'}>
            {t(
              'toolkit.toolPage.walkOfStrategy.results.insights.section.overview.daysActive',
            )}
          </Text>
        </InfoBox>
        <InfoBox>
          <Heading level={3} hasMargin={false}>
            {percentEngaged}
            {'%'}
          </Heading>
          <Text size={'small'}>
            {t(
              'toolkit.toolPage.walkOfStrategy.results.insights.section.overview.engaged',
            )}
          </Text>
        </InfoBox>
        <InfoBox>
          <Heading level={3} hasMargin={false}>
            {campaign.completionRate.countEngaged}
          </Heading>
          <Text size={'small'}>
            {t('answer.answer', {
              count: campaign.completionRate.countEngaged,
            })}
          </Text>
        </InfoBox>
      </InfoContainer>
      <GraphContainer>
        <OverviewSectionGraph />
      </GraphContainer>
    </>
  );
};

export default OverviewSection;
