import { createContext } from 'react';

import type {
  MyTeamsOrgFragment,
  MyTeamsOrgUnitFragment,
} from './MyTeamsProvider.graphql.generated';

export type MyTeamsContextValue = {
  org?: Maybe<MyTeamsOrgFragment>;
  primaryOrgUnit?: Maybe<MyTeamsOrgUnitFragment>;
  refetchMyTeams: () => Promise<unknown>;
};

export const MyTeamsContext =
  createContext<Maybe<MyTeamsContextValue>>(undefined);
