import { getInitiativeIcon } from 'initiative/initiative.utils';
import { getMetricIcon } from 'metric/metric.utils';
import { getObjectiveIcon } from 'objective/objective.utils';
import { getActionIcon } from 'actions/action.utils';

import type { StrategyOperationalItemRowTypename } from './StrategyOperationalItemRow.type';

export const getItemIcon = (typename: StrategyOperationalItemRowTypename) => {
  switch (typename) {
    case 'Objective':
      return getObjectiveIcon();
    case 'Initiative':
      return getInitiativeIcon();
    case 'Metric':
      return getMetricIcon();
    case 'Action':
      return getActionIcon();
  }
};
