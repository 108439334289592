import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import type { CellProps } from 'react-table';
import { css, useTheme } from '@emotion/react';
import { format } from 'date-fns';

import TableView from 'shared/components/TableView';
import type { ProgressStatus, User } from 'types.graphql.generated';
import { ReactComponent as CheckIcon } from 'shared/static/icons/icon-check.svg';
import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';
import Text from 'shared/components/Text';
import UserAvatar from 'user/UserAvatar';

import type { OneThingCampaignResultsCampaignFragment } from './OneThingCampaignResults.graphql.generated';

type TaskRow = {
  completeDate?: Date;
  completionRate: number;
  objectiveName?: string;
  owner: Pick<User, 'id' | 'email' | 'displayName' | 'photoUrl'>;
  startDate: Date;
  status: {
    isClosed: boolean;
    status: ProgressStatus;
  };
  task: string;
};

const StatusIcon = styled.div`
  svg {
    width: 16px;
    height: 16px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-left: 1.333rem;
  padding-right: 1.333rem;
  padding-bottom: 1.333rem;
  max-width: 100%;

  table {
    table-layout: fixed;
    width: 100%;

    td:nth-child(3),
    td:nth-child(4) {
      div {
        width: 100%;
      }
    }
  }
`;

const TextColumn = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {
  campaign: OneThingCampaignResultsCampaignFragment;
  statuses: ProgressStatus[];
};

const TasksSection = ({ campaign, statuses }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const data = campaign.tasks
    .filter((task) => statuses.includes(task.status))
    .map((task) => ({
      status: {
        status: task.status,
        isClosed: campaign.state.stage === 'CLOSED',
      },
      task: task.task,
      objectiveName: task.objective.name,
      completionRate: task.completionRate,
      startDate: task.timeLine.startDate,
      completeDate: task.timeLine.completeDateTime,
      owner: task.owner,
    }));

  if (data.length === 0) {
    return (
      <Container>
        <Text css={css({ padding: '2rem' })}>
          {t('toolkit.toolPage.oneThing.campaignResults.tasks.empty')}
        </Text>
      </Container>
    );
  }

  return (
    <Container>
      <TableView<TaskRow>
        hasBorder={false}
        data={data}
        columns={[
          {
            Header: '',
            accessor: 'status',
            width: 20,
            Cell: (table: CellProps<TaskRow>) => {
              switch (table.value.status as ProgressStatus) {
                case 'NOT_STARTED':
                case 'IN_PROGRESS':
                  return table.value.isClosed ? (
                    <StatusIcon>
                      <CloseIcon color={theme.color.error} />
                    </StatusIcon>
                  ) : (
                    <StatusIcon>
                      <CheckIcon color={theme.color.neutral2} />
                    </StatusIcon>
                  );
                case 'DONE':
                  return (
                    <StatusIcon>
                      <CheckIcon color={theme.color.primary} />
                    </StatusIcon>
                  );
              }
            },
          },
          {
            accessor: 'owner',
            width: 20,
            Cell: (table: CellProps<TaskRow>) => (
              <UserAvatar user={table.value} size={'medium'} />
            ),
          },
          {
            Header: t(
              'toolkit.toolPage.oneThing.campaignResults.tasks.table.task.header',
            ),
            accessor: 'task',
            Cell: (table: CellProps<TaskRow>) => (
              <TextColumn>{table.value}</TextColumn>
            ),
          },
          {
            Header: t('objective.objective'),
            accessor: 'objectiveName',
            Cell: (table: CellProps<TaskRow>) => (
              <TextColumn css={css({ color: theme.color.neutral2 })}>
                {table.value}
              </TextColumn>
            ),
          },

          {
            Header: t(
              'toolkit.toolPage.oneThing.campaignResults.tasks.table.completionRate.header',
            ),
            accessor: 'completionRate',
            width: 60,
            Cell: (table: CellProps<TaskRow>) => (
              <>
                {table.value}
                {'%'}
              </>
            ),
          },
          {
            Header: t(
              'toolkit.toolPage.oneThing.campaignResults.tasks.table.startDate.header',
            ),
            accessor: 'startDate',
            width: 60,
            Cell: (table: CellProps<TaskRow>) => (
              <>{table.value && format(table.value as Date, 'P')}</>
            ),
          },
          {
            Header: t(
              'toolkit.toolPage.oneThing.campaignResults.tasks.table.completeDate.header',
            ),
            accessor: 'completeDate',
            width: 60,
            Cell: (table: CellProps<TaskRow>) => (
              <>{table.value && format(table.value as Date, 'P')}</>
            ),
          },
        ]}
      />
    </Container>
  );
};

export default TasksSection;
