import MetricOverviewActivityTimeline from '../MetricOverviewActivityTimeline';
import MetricOverviewHeader from '../MetricOverviewHeader';

const MetricOverviewInsights = () => (
  <>
    <MetricOverviewHeader />
    <MetricOverviewActivityTimeline />
  </>
);

export default MetricOverviewInsights;
