import { useEffect, useState, useCallback } from 'react';

const useScrollToBottom = () => {
  const [scrollableDiv, setScrollableDiv] = useState<HTMLDivElement | null>(
    null,
  );
  const [growableDiv, setGrowableDiv] = useState<HTMLDivElement | null>(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const scrollToBottom = useCallback(
    (behavior: ScrollBehavior) => {
      scrollableDiv?.scroll({
        top: scrollableDiv.scrollHeight,
        behavior: behavior,
      });
    },
    [scrollableDiv],
  );

  const onScrollChanged = useCallback(
    (shouldScroll: boolean) => {
      if (!scrollableDiv) return;

      const { scrollTop, scrollHeight, clientHeight } = scrollableDiv;

      const wasAtTheBottom = isScrolledToBottom;
      const isAtTheBottom =
        Math.ceil(scrollTop + clientHeight) >= scrollHeight - 1;

      const willScroll = wasAtTheBottom && !isAtTheBottom && shouldScroll;

      if (willScroll) {
        scrollToBottom('instant' as ScrollBehavior);
        setIsScrolledToBottom(true);
      } else {
        setIsScrolledToBottom(isAtTheBottom);
      }
    },
    [isScrolledToBottom, scrollToBottom, scrollableDiv],
  );

  useEffect(() => {
    if (!scrollableDiv) return;

    const onScroll = () => onScrollChanged(false);

    scrollableDiv.addEventListener('scroll', onScroll);

    onScroll();

    return () => {
      scrollableDiv.removeEventListener('scroll', onScroll);
    };
  }, [onScrollChanged, scrollableDiv]);

  useEffect(() => {
    if (!growableDiv) return;

    const onResize = () => onScrollChanged(true);

    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(growableDiv);

    return () => {
      resizeObserver.unobserve(growableDiv);
    };
  }, [onScrollChanged, growableDiv]);

  return {
    isScrolledToBottom,
    scrollToBottom,
    setScrollableDiv,
    setGrowableDiv,
  };
};

export default useScrollToBottom;
