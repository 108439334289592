import { useMenuTriggerState } from 'react-stately';
import type { AriaMenuProps, AriaPopoverProps } from 'react-aria';
import { mergeProps } from 'react-aria';
import type { SerializedStyles } from '@emotion/react';

import MenuTriggerContainer from './MenuTriggerContainer';
import MenuList from './MenuList';
import type {
  BaseMenuItem,
  MenuTriggerComponent,
  MenuRowComponent,
} from './Menu.type';

export type MenuProps<MenuItem extends BaseMenuItem> = Pick<
  AriaMenuProps<MenuItem>,
  'items' | 'disabledKeys'
> &
  Pick<AriaPopoverProps, 'placement'> & {
    Row: MenuRowComponent<MenuItem>;
    Trigger: MenuTriggerComponent;
    onAction: (id: MenuItem['id']) => void;
    testId?: string;
    triggerClassName?: string;
    triggerStyle?: SerializedStyles;
  };

/**
 * Displays a list of actions or options that user can choose.
 */
const Menu = <MenuItem extends BaseMenuItem>(props: MenuProps<MenuItem>) => {
  const { Trigger, placement, triggerClassName, triggerStyle, items, testId } =
    props;

  const state = useMenuTriggerState({});

  return (
    <MenuTriggerContainer<MenuItem>
      state={state}
      Component={Trigger}
      placement={placement}
      triggerClassName={triggerClassName}
      triggerStyle={triggerStyle}
      items={items}
      testId={testId}
    >
      {(menuParams) => (
        <MenuList<MenuItem> {...mergeProps(props, menuParams)} />
      )}
    </MenuTriggerContainer>
  );
};

export default Menu;
