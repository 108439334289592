import type { Node, ListState } from 'react-stately';
import type { ReactNode } from 'react';
import { useMemo, useRef } from 'react';
import { useOption } from 'react-aria';
import cn from 'classnames';

import Checkbox from 'shared/components/Checkbox';

import type { BaseListBoxItem } from '../ListBox.type';
import styles from './ListBoxRowContainer.module.scss';

type ListBoxRowContainerProps<ListBoxItem extends BaseListBoxItem> = {
  item: Node<ListBoxItem>;
  state: ListState<ListBoxItem>;
};

const ListBoxRowContainer = <ListBoxItem extends BaseListBoxItem>({
  item,
  state,
}: ListBoxRowContainerProps<ListBoxItem>) => {
  const listBoxRowContainerElementRef = useRef<HTMLLIElement>(null);

  const { optionProps, isSelected, isDisabled, isFocused } = useOption(
    { key: item.key },
    state,
    listBoxRowContainerElementRef,
  );

  const selectionMode = state.selectionManager.selectionMode;
  const isMultipleSelectionMode = selectionMode === 'multiple';

  const checkbox = useMemo<ReactNode>(() => {
    if (isMultipleSelectionMode) {
      return (
        <Checkbox
          checked={isSelected}
          className={styles.listBoxRowContainerCheckbox}
        />
      );
    }
    return null;
  }, [isMultipleSelectionMode, isSelected]);

  return (
    <li
      {...optionProps}
      className={cn(styles.listBoxRowContainer, {
        [styles.listBoxRowContainerFocused]: isFocused,
        [styles.listBoxRowContainerDisabled]: isDisabled,
        [styles.listBoxRowContainerSelected]:
          !isMultipleSelectionMode && isSelected,
      })}
      ref={listBoxRowContainerElementRef}
    >
      {checkbox}
      {item.rendered}
    </li>
  );
};

export default ListBoxRowContainer;
