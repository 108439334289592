import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { ActionPartsFragmentDoc } from '../actions.fragment.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActionQueryVariables = Types.Exact<{
  actionId: Types.Scalars['ID'];
}>;


export type ActionQuery = { __typename: 'Query', action: { __typename: 'Action', currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, id: string, name?: string | undefined, description?: string | undefined, isCurrentUserOwner: boolean, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, objective?: { __typename: 'Objective', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions> } | undefined, priority?: { __typename: 'Priority', number: number, label: string } | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, currentStatus?: { __typename: 'ActionStatus', id: string, completionRate?: number | undefined, complete: boolean, statusDateTime: any, statusIndicator: StatusIndicatorClass, auditRecord: { __typename: 'AuditRecord', updateDateTime?: any | undefined, createDateTime?: any | undefined, updateBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined, createBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined } } | undefined, auditRecord: { __typename: 'AuditRecord', updateDateTime?: any | undefined, createDateTime?: any | undefined, updateBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined, createBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined }, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined } } };

export type ActionFragment = { __typename: 'Action', currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, id: string, name?: string | undefined, description?: string | undefined, isCurrentUserOwner: boolean, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, objective?: { __typename: 'Objective', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions> } | undefined, priority?: { __typename: 'Priority', number: number, label: string } | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, currentStatus?: { __typename: 'ActionStatus', id: string, completionRate?: number | undefined, complete: boolean, statusDateTime: any, statusIndicator: StatusIndicatorClass, auditRecord: { __typename: 'AuditRecord', updateDateTime?: any | undefined, createDateTime?: any | undefined, updateBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined, createBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined } } | undefined, auditRecord: { __typename: 'AuditRecord', updateDateTime?: any | undefined, createDateTime?: any | undefined, updateBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined, createBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined }, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined } };

export const ActionFragmentDoc = gql`
    fragment action on Action {
  ...actionParts
  currentUserAuthorizedActions
  orgUnit {
    id
  }
  objective {
    id
    name
    currentUserAuthorizedActions
  }
  priority {
    number
    label
  }
}
    ${ActionPartsFragmentDoc}`;
export const ActionDocument = gql`
    query Action($actionId: ID!) {
  action(id: $actionId) {
    ...action
  }
}
    ${ActionFragmentDoc}`;

/**
 * __useActionQuery__
 *
 * To run a query within a React component, call `useActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionQuery({
 *   variables: {
 *      actionId: // value for 'actionId'
 *   },
 * });
 */
export function useActionQuery(baseOptions: Apollo.QueryHookOptions<ActionQuery, ActionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActionQuery, ActionQueryVariables>(ActionDocument, options);
      }
export function useActionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionQuery, ActionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActionQuery, ActionQueryVariables>(ActionDocument, options);
        }
export type ActionQueryHookResult = ReturnType<typeof useActionQuery>;
export type ActionLazyQueryHookResult = ReturnType<typeof useActionLazyQuery>;
export type ActionQueryResult = Apollo.QueryResult<ActionQuery, ActionQueryVariables>;