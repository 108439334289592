import type { TFunction } from 'react-i18next';

import type { Org, OrgUnit, SetRemoveIdInput } from 'types.graphql.generated';
import type { SelectObjectiveInputFieldOption } from 'objective/SelectObjectiveInput';

import type { SelectOrgUnitInputFieldOption } from './SelectOrgUnitInput.type';

export const getOptionLabel =
  (params: { t: TFunction }) =>
  (option: SelectOrgUnitInputFieldOption): string => {
    const { t } = params;
    return option.value?.name || option.label || t('none');
  };

export const resolveOrgUnitInputInitialValue = (
  activeOrg: Pick<Org, 'displayName'>,
  orgUnit?: Maybe<Pick<OrgUnit, 'id' | 'name'>>,
): SelectObjectiveInputFieldOption =>
  orgUnit
    ? {
        value: {
          name: orgUnit.name,
          id: orgUnit.id,
        },
      }
    : { value: null, label: activeOrg.displayName || '' };

export const resolveSetRemoveIdInput = (params: {
  option: SelectObjectiveInputFieldOption | null;
  previousOrgUnit?: Maybe<Pick<OrgUnit, 'id' | 'name'>>;
}): SetRemoveIdInput => {
  const { option, previousOrgUnit } = params;
  if (option?.value?.id) {
    return {
      idToSet: option.value.id,
    };
  }
  if (!option?.value && previousOrgUnit) {
    return {
      idToRemove: previousOrgUnit.id,
    };
  }
  return {};
};
