import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { resolveInitialValue as resolveSelectObjectiveInputInitialValue } from 'objective/SelectObjectiveInput';
import type { InitiativeInput } from 'types.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import { resolvePriorityInitialValue } from 'strategy/PrioritySelect/resolvePriorityInitialValue';
import type { RedirectToMyContributionLocation } from 'contribution';
import { useInitiativeOverview } from 'initiative/InitiativeOverview/InitiativeOverviewProvider';
import Spinner from 'shared/spinner/Spinner';
import { InitiativeOverviewDocument } from 'initiative/InitiativeOverview/InitiativeOverviewProvider/InitiativeOverviewProvider.graphql.generated';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { useTeamSlug } from 'team/TeamAdapter/useTeamSlug';

import { handleOnClose } from './handleOnClose';
import { useUpdateInitiativeMutation } from './UpdateInitiative.graphql.generated';
import type { InitiativeFormValues } from './InitiativeForm';
import InitiativeForm from './InitiativeForm';
import InitiativeFormModal from './InitiativeFormModal/InitiativeFormModal';

const EditInitiative = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation() as RedirectToMyContributionLocation;

  const { orgTeamSlug } = useTeamSlug();
  const { addToast } = useToasts();
  const { initiative } = useInitiativeOverview();

  const handleApolloError = useHandleApolloError();
  const [updateInitiative] = useUpdateInitiativeMutation({
    onError: handleApolloError,
    refetchQueries: [InitiativeOverviewDocument],
  });

  const initialValues: InitiativeFormValues | false = useMemo(
    () =>
      !!initiative && {
        id: initiative.id,
        name: initiative.name || '',
        description: initiative.description || '',
        owner: initiative.owner?.email || '',
        objective: resolveSelectObjectiveInputInitialValue(
          initiative.objective,
        ),
        priority: resolvePriorityInitialValue(initiative.priority),
        timeLine: {
          startDate: initiative.timeLine.startDate,
          endDate: initiative.timeLine.endDate,
        },
        milestones:
          initiative.milestones.map((milestone) => ({
            id: milestone.id,
            name: milestone.name || '',
            comment: milestone.comment || '',
            timeLine: {
              endDate: milestone.timeLine?.endDate,
            },
          })) || [],
        attributes: initiative.attributes.map((attribute) => ({
          id: attribute.id,
          name: attribute.name || '',
          title: attribute.title || '',
          description: attribute.description || '',
        })),
      },
    [initiative],
  );

  const handleClose = useCallback(
    () =>
      handleOnClose({
        options: { state },
        orgTeamSlug,
        navigate,
      }),
    [navigate, orgTeamSlug, state],
  );

  const handleSubmit = useCallback(
    async (input: InitiativeInput) => {
      const result = await updateInitiative({
        variables: { input },
      });

      const isSuccess = result.data?.updateInitiative.id && !result.errors;

      if (isSuccess) {
        addToast({
          variant: 'success',
          children: t('initiative.editInitiative.successToast'),
        });

        handleClose();
      }
    },
    [updateInitiative, addToast, t, handleClose],
  );

  if (!initialValues) return <Spinner.Circle />;

  return (
    <InitiativeForm initialValues={initialValues} onSubmit={handleSubmit}>
      <InitiativeFormModal
        headerTitle={t('initiative.editInitiative.heading')}
        confirmLabel={t('update')}
        isOpen={true}
        onClose={handleClose}
      />
    </InitiativeForm>
  );
};

export default EditInitiative;
