import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useToggle } from 'react-use';
import { Field, FieldArray } from 'formik';
import { useState } from 'react';
import isNil from 'lodash/isNil';
import type { SetOptional } from 'type-fest';

import Text from 'shared/components/Text';
import IconButton from 'shared/components/IconButton';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';
import Form from 'shared/form/Form';
import Modal from 'shared/components/__DEPRECATED__/Modal';
import Space from 'shared/components/Space';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import FieldBox from 'shared/form/FieldBox';
import { TextInputField } from 'shared/components/TextInput';
import type { FormChildrenRenderProps, FormProps } from 'shared/form/Form';
import { TextAreaField } from 'shared/components/TextArea';
import type { ContextMenuItem } from 'shared/components/ContextMenu';
import ContextMenu from 'shared/components/ContextMenu';

import type { StrategyBehaviorGroupFormValues } from './StrategyBehaviorGroupForm';
import StrategyBehaviorGroupForm from './StrategyBehaviorGroupForm';
import styles from './StrategyBehaviorsForm.module.scss';

const createGroupFormId = 'createGroupForm';
const editGroupFormId = 'editGroupForm';

type StrategyBehaviorsFormValuesItem = {
  description: string;
  id?: string;
  name: string;
};

const createItemInitialValues = (): StrategyBehaviorsFormValuesItem => ({
  description: '',
  name: '',
});

export type StrategyBehaviorsFormValuesGroup = {
  description: string;
  id?: string;
  items: StrategyBehaviorsFormValuesItem[];
  title: string;
};

const createGroupInitialValues = (
  title?: string,
  description?: string,
): StrategyBehaviorsFormValuesGroup => ({
  items: [createItemInitialValues()],
  title: title || '',
  description: description || '',
});

export type StrategyBehaviorsFormValues = {
  groups: StrategyBehaviorsFormValuesGroup[];
};

const createInitialValues = (): StrategyBehaviorsFormValues => ({
  groups: [],
});

export type StrategyBehaviorsFormProps = SetOptional<
  Pick<
    FormProps<StrategyBehaviorsFormValues>,
    'id' | 'className' | 'initialValues' | 'onChange'
  >,
  'initialValues'
>;

const StrategyBehaviorsForm = ({
  onChange,
  initialValues = createInitialValues(),
  className,
  id,
}: StrategyBehaviorsFormProps) => {
  const { t } = useTranslation();

  const [currentGroupIndex, setCurrentGroupIndex] = useState<
    number | undefined
  >();
  const [isCreateGroupModalDisplayed, toggleIsCreateGroupModalDisplayed] =
    useToggle(false);
  const [editingGroupIndex, setEditingGroupIndex] = useState<
    number | undefined
  >();

  const isEditGroupModalDisplayed = !isNil(editingGroupIndex);
  const closeEditGroupModal = () => setEditingGroupIndex(undefined);

  const renderCreateGroupModal = (
    onSuccess: (behaviorTitle: string, behaviorDescription: string) => void,
  ) => (
    <Modal
      heading={t(
        'strategy.strategyBehaviorsForm.strategyBehaviorGroupForm.create.heading',
      )}
      isOpen={isCreateGroupModalDisplayed}
      onClose={toggleIsCreateGroupModalDisplayed}
      hasBackground={false}
      renderFooterContent={() => (
        <Space className={styles.groupModalControls}>
          <Button
            variant={'outlined'}
            onClick={() => {
              toggleIsCreateGroupModalDisplayed(false);
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            variant={'contained'}
            type={'submit'}
            form={createGroupFormId}
          >
            {t('confirm')}
          </Button>
        </Space>
      )}
      size={'small'}
      className={styles.groupModal}
    >
      <StrategyBehaviorGroupForm
        onSubmit={(values) => {
          onSuccess(values.title, values.description);
          toggleIsCreateGroupModalDisplayed(false);
        }}
        id={createGroupFormId}
      />
    </Modal>
  );

  const renderEditGroupModal = (params: {
    initialValues?: StrategyBehaviorGroupFormValues;
    onSuccess: (behaviorTitle: string, behaviorDescription: string) => void;
  }) => {
    const { onSuccess, initialValues } = params;
    return (
      <Modal
        heading={t(
          'strategy.strategyBehaviorsForm.strategyBehaviorGroupForm.edit.heading',
        )}
        isOpen={isEditGroupModalDisplayed}
        onClose={() => {
          closeEditGroupModal();
        }}
        hasBackground={false}
        renderFooterContent={() => (
          <Space className={styles.groupModalControls}>
            <Button
              variant={'outlined'}
              onClick={() => {
                closeEditGroupModal();
              }}
            >
              {t('cancel')}
            </Button>
            <Button
              variant={'contained'}
              type={'submit'}
              form={editGroupFormId}
            >
              {t('confirm')}
            </Button>
          </Space>
        )}
        size={'small'}
        className={styles.groupModal}
      >
        {isEditGroupModalDisplayed && (
          <StrategyBehaviorGroupForm
            initialValues={initialValues}
            onSubmit={(values) => {
              onSuccess(values.title, values.description);
              closeEditGroupModal();
            }}
            id={editGroupFormId}
          />
        )}
      </Modal>
    );
  };

  const renderItemFields = (getFieldName: (name: string) => string) => (
    <>
      <FieldBox
        name={getFieldName('name')}
        label={t('strategy.strategyBehaviorsForm.items.name.label')}
      >
        <Field name={getFieldName('name')} component={TextInputField} />
      </FieldBox>
      <FieldBox
        name={getFieldName('description')}
        label={t('strategy.strategyBehaviorsForm.items.description.label')}
      >
        <Field name={getFieldName('description')} component={TextAreaField} />
      </FieldBox>
    </>
  );

  const renderItems = (
    formRenderProps: FormChildrenRenderProps<StrategyBehaviorsFormValues>,
  ) => {
    const { values } = formRenderProps;
    if (!isNil(currentGroupIndex) && values.groups[currentGroupIndex]?.items) {
      return (
        <FieldArray name={`groups.${currentGroupIndex}.items`}>
          {(arrayRenderProps) => (
            <>
              {values.groups[currentGroupIndex].items.map((item, itemIndex) => {
                const getFieldName = (name: string) =>
                  `groups.${currentGroupIndex}.items.${itemIndex}.${name}`;
                return (
                  <div className={styles.item} key={itemIndex}>
                    <IconButton
                      variant={'transparent'}
                      icon={CloseIcon}
                      onClick={() => {
                        arrayRenderProps.remove(itemIndex);
                      }}
                      className={styles.itemRemoveButton}
                    >
                      {t('remove')}
                    </IconButton>
                    {renderItemFields(getFieldName)}
                  </div>
                );
              })}
              {values.groups.length ? (
                <div className={styles.itemsControls}>
                  <IconButton
                    variant={'outlined'}
                    icon={PlusIcon}
                    onClick={() => {
                      arrayRenderProps.push(createItemInitialValues());
                    }}
                    className={styles.createButton}
                  >
                    {t('create')}
                  </IconButton>
                </div>
              ) : null}
            </>
          )}
        </FieldArray>
      );
    } else if (!values.groups.length) {
      return (
        <div className={styles.itemsMessageContainer}>
          <Text variant={'emphasis'} className={styles.itemsMessage}>
            {t('strategy.strategyBehaviorsForm.groups.noGroups')}
          </Text>
        </div>
      );
    }
    return null;
  };

  const renderGroups = (
    formRenderProps: FormChildrenRenderProps<StrategyBehaviorsFormValues>,
  ) => {
    const { values, setFieldValue } = formRenderProps;
    return (
      <FieldArray name={'groups'}>
        {(arrayProps) => (
          <>
            <div className={styles.groups}>
              <Text className={cn(styles.heading)}>
                {t('strategy.strategyBehaviorsForm.groups.heading')}
              </Text>
              <div className={styles.list}>
                {values.groups.map((group, groupIndex) => (
                  <Card
                    isActive={currentGroupIndex === groupIndex}
                    onClick={() => {
                      setCurrentGroupIndex(groupIndex);
                    }}
                    className={styles.group}
                    key={groupIndex}
                    controls={
                      <ContextMenu<ContextMenuItem<'edit' | 'remove'>>
                        items={[
                          { id: 'edit', title: t('edit') },
                          { id: 'remove', title: t('remove') },
                        ]}
                        onAction={(action) => {
                          switch (action) {
                            case 'edit':
                              setEditingGroupIndex(groupIndex);
                              return;
                            case 'remove':
                              arrayProps.remove(groupIndex);
                          }
                        }}
                      />
                    }
                  >
                    <Card.Heading>{group.title}</Card.Heading>
                  </Card>
                ))}
                <div className={styles.groupsControls}>
                  <IconButton
                    variant={'outlined'}
                    icon={PlusIcon}
                    onClick={() => {
                      toggleIsCreateGroupModalDisplayed(true);
                    }}
                    className={styles.createButton}
                  >
                    {t('create')}
                  </IconButton>
                </div>
              </div>
            </div>
            <div className={styles.items}>
              <Text className={cn(styles.heading, styles.itemsHeading)}>
                {t('strategy.strategyBehaviorsForm.items.heading')}
              </Text>
              <div className={styles.itemsList}>
                {renderItems(formRenderProps)}
              </div>
            </div>
            {renderCreateGroupModal((title, description) => {
              arrayProps.push(createGroupInitialValues(title, description));
              setCurrentGroupIndex(values.groups.length);
            })}
            {renderEditGroupModal({
              initialValues: values.groups.find(
                (_, groupIndex) => groupIndex === editingGroupIndex,
              ),
              onSuccess: (behaviorTitle, behaviorDescription) => {
                setFieldValue(
                  `groups.${editingGroupIndex!}.title`,
                  behaviorTitle,
                );
                setFieldValue(
                  `groups.${editingGroupIndex!}.description`,
                  behaviorDescription,
                );
              },
            })}
          </>
        )}
      </FieldArray>
    );
  };

  return (
    <>
      <Form
        initialValues={initialValues}
        onChange={onChange}
        id={id}
        className={className}
      >
        {(formRenderProps) => (
          <>
            <div className={styles.container}>
              {renderGroups(formRenderProps)}
            </div>
          </>
        )}
      </Form>
    </>
  );
};

export default StrategyBehaviorsForm;
