import { useCallback } from 'react';

import { useSubmissionContextValue } from './useSubmissionContextValue';

type Result = [boolean, (isSubmissing: boolean) => void];

export default function useSubmission(id: string): Result {
  const { submissions, setSubmissions } = useSubmissionContextValue();

  const setSubmission = useCallback(
    (isSubmitting: boolean) =>
      setSubmissions({ ...submissions, [id]: isSubmitting }),
    [id, setSubmissions, submissions],
  );

  return [submissions[id], setSubmission];
}
