import type { ReactElement } from 'react';
import { cloneElement } from 'react';
import styled from '@emotion/styled';

import type { CalendarItem } from '../Calendar.type';
import {
  getLeftPosition,
  getRightPosition,
  isOutOfRange,
} from './CalendarRow.utils';

const CalendarRowContainer = styled.div`
  align-items: center;
  display: flex;
  height: 81px;
  margin-bottom: 19px;
  position: relative;
`;

const CalendarRowDiv = styled.div<{
  left: string;
  right: string;
}>`
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.strokeMedium};
  border-radius: 12px;
  cursor: pointer;
  height: 81px;
  padding: 12px 19px 16px;
  position: absolute;
  transition: box-shadow 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  &:hover {
    box-shadow: 0 1px 8px 0 ${({ theme }) => theme.color.strokeMedium};
  }
`;

type CalendarRowProps = {
  currentYearEndDateTime: Date;
  currentYearStartDateTime: Date;
  item: CalendarItem;
};

const CalendarRow = ({
  item,
  currentYearStartDateTime,
  currentYearEndDateTime,
}: CalendarRowProps) => {
  const calendarDuration =
    currentYearEndDateTime.getTime() - currentYearStartDateTime.getTime();

  const leftPosition = getLeftPosition(
    item,
    currentYearStartDateTime,
    calendarDuration,
  );
  const rightPosition = getRightPosition(
    item,
    currentYearEndDateTime,
    calendarDuration,
  );

  if (isOutOfRange(item, currentYearStartDateTime, currentYearEndDateTime)) {
    return null;
  }

  const rowItem = {
    ...item,
    children: cloneElement(
      item.children as ReactElement<{ left: number; right: number }>,
      {
        left: leftPosition,
        right: rightPosition,
      },
    ),
  };

  const rowHorizontalPosition = {
    left: `${leftPosition}%`,
    right: `${rightPosition}%`,
  };

  return (
    <CalendarRowContainer>
      <CalendarRowDiv
        left={rowHorizontalPosition.left}
        right={rowHorizontalPosition.right}
        style={rowHorizontalPosition}
      />
      {rowItem.children}
    </CalendarRowContainer>
  );
};
export default CalendarRow;
