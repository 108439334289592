import { date } from 'shared/services/date.service';
import type { Strategy } from 'types.graphql.generated';

export const createReportName = (params: {
  creationDate: Date;
  strategy?: Pick<Strategy, 'name'>;
}) => {
  const { creationDate, strategy } = params;

  if (strategy) {
    return `${strategy.name} - ${date.format(creationDate, 'yyyy-MM-dd')}`;
  } else {
    return date.format(creationDate, 'yyyy-MM-dd');
  }
};
