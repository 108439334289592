import type { TFunction } from 'i18next';

import type { LinkTabsProps } from 'shared/components/LinkTabs';
import type { TeamAdapter } from 'team/TeamAdapter';
import { toolkitRoutesPaths } from 'toolkit/toolkit.routing.paths';
import { ReactComponent as IdeaIcon } from 'shared/static/icons/icon-idea.svg';
import { ReactComponent as DocumentIcon } from 'shared/static/icons/icon-document.svg';

export const getBreadcrumbItems = (t: TFunction, teamAdapter: TeamAdapter) => [
  {
    name: t('toolkit.toolkit'),
    to: toolkitRoutesPaths.root({
      params: {
        teamSlug: teamAdapter.toParam(),
      },
    }),
  },
  {
    name: t('toolkit.tools.walkOfStrategy.name'),
    to: toolkitRoutesPaths.walkOfStrategy.root({
      params: {
        teamSlug: teamAdapter.toParam(),
      },
    }),
  },
  {
    name: t('campaign.campaign_one'),
  },
];

export const getLinkTabsItems = (
  t: TFunction,
  teamAdapter: TeamAdapter,
  campaignId: string,
): LinkTabsProps['items'] => [
  {
    name: t('insight.insight_other'),
    icon: IdeaIcon,
    to: toolkitRoutesPaths.walkOfStrategy.campaigns.results.insights({
      params: {
        teamSlug: teamAdapter.toParam(),
        campaignId,
      },
    }),
  },
  {
    name: t('comment.comment_other'),
    icon: DocumentIcon,
    to: toolkitRoutesPaths.walkOfStrategy.campaigns.results.comments({
      params: {
        teamSlug: teamAdapter.toParam(),
        campaignId,
      },
    }),
  },
];
