import { Link } from 'react-router-dom';
import { Item, useMenuTriggerState } from 'react-stately';
import { useMenuTrigger } from 'react-aria';
import { useRef } from 'react';
import type { CollectionElement } from '@react-types/shared';
import type { PropsWithChildren } from 'react';
import cn from 'classnames';

import { routesPaths } from 'App.routing.paths';
import Logo from 'shared/components/Logo';

import NavbarLink from './NavbarLink';
import type { NavbarLinkType } from './Navbar.type';
import styles from './Navbar.module.scss';
import NavbarHamburgerButton from './NavbarHamburgerButton/NavbarHamburgerButton';
import NavbarMobileMenu from './NavbarMobileMenu';
import Popover from '../Popover';

type NavbarProps = PropsWithChildren<{
  navigationLinks: Maybe<NavbarLinkType[]>;
}>;

const Navbar = ({ navigationLinks, children }: NavbarProps) => {
  const buttonRef = useRef(null);
  const state = useMenuTriggerState({});
  const { menuTriggerProps, menuProps } = useMenuTrigger<NavbarLinkType>(
    {},
    state,
    buttonRef,
  );

  return (
    <nav className={styles.container}>
      <div className={styles.innerContainer}>
        <NavbarHamburgerButton
          {...menuTriggerProps}
          buttonRef={buttonRef}
          className={styles.hamburgerMenuButton}
          isOpen={state.isOpen}
        />
        <Link to={routesPaths.root()}>
          <Logo />
        </Link>
        <ul className={cn(styles.linkList)}>
          {navigationLinks?.map((link) => (
            <li key={link.to.toString()}>
              <NavbarLink {...link} />
            </li>
          ))}
        </ul>
        {children}
      </div>
      {state.isOpen && navigationLinks && (
        <Popover
          state={state}
          triggerRef={buttonRef}
          placement={'bottom'}
          offset={13}
          containerPadding={0}
        >
          <NavbarMobileMenu
            {...menuProps}
            autoFocus={state.focusStrategy || true}
            onClose={() => state.close()}
          >
            <Item key={'children'}>{children}</Item>
            {
              navigationLinks.map((link) => (
                <Item key={link.to.toString()}>
                  <NavbarLink {...link} onClick={() => state.close()} />
                </Item>
              )) as unknown as CollectionElement<object>
            }
          </NavbarMobileMenu>
        </Popover>
      )}
    </nav>
  );
};

export default Navbar;
