import { useTranslation } from 'react-i18next';

import { useTeamAdapter } from 'team/TeamAdapter';

import type {
  StrategyOperationalItem,
  StrategyOperationalItemType,
} from './StrategyBoardList.type';
import StrategyBoardListRow from '../StrategyBoardListRow';
import styles from './StrategyBoardList.module.scss';
import { getStrategyOperationalItemLink } from './StrategyBoardList.utils';

type StrategyBoardListProps = {
  isDisabled?: boolean;
  items: StrategyOperationalItem[];
  operationalItemType: StrategyOperationalItemType;
};

const StrategyBoardList = ({
  items,
  operationalItemType,
  isDisabled,
}: StrategyBoardListProps) => {
  const { t } = useTranslation();

  const { teamAdapter } = useTeamAdapter();

  return (
    <section className={styles.operationalItemsListContainer}>
      <ul className={styles.operationalItemsList}>
        {items.length ? (
          items.map((item) => {
            const linkTo = getStrategyOperationalItemLink(item, {
              teamAdapter,
            });
            return (
              <StrategyBoardListRow
                owner={item.owner}
                statusIndicator={item.status?.statusIndicator}
                linkTo={linkTo}
                textContent={item.name}
                key={item.id}
                isDisabled={isDisabled}
              />
            );
          })
        ) : (
          <StrategyBoardListRow
            textContent={t(`strategy.strategyBoard.no${operationalItemType}`)}
          />
        )}
      </ul>
    </section>
  );
};

export default StrategyBoardList;
