import { useTranslation } from 'react-i18next';

import Tag from 'shared/components/Tag';
import type { Strategy } from 'types.graphql.generated';

type StrategyStatusTagProps = {
  className?: string;
  strategy: Pick<Strategy, 'isLive' | 'isComplete'>;
};

const StrategyStatusTag = ({ strategy, className }: StrategyStatusTagProps) => {
  const { t } = useTranslation();

  if (strategy.isComplete) {
    return (
      <Tag variant={'primary'} className={className}>
        {t('strategy.archived')}
      </Tag>
    );
  }

  if (strategy.isLive) {
    return <Tag variant={'success'}>{t('strategy.published')}</Tag>;
  }

  return (
    <Tag variant={'ghost'} className={className}>
      {t('strategy.draft')}
    </Tag>
  );
};

export default StrategyStatusTag;
