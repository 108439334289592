import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import type { SetOptional } from 'type-fest';

import type { CompactModalProps } from 'shared/components/__DEPRECATED__/CompactModal';
import type { CreateOrgUnitFormProps } from 'orgUnit/CreateOrgUnitForm';
import CreateOrgUnitForm from 'orgUnit/CreateOrgUnitForm';
import { useToasts } from 'shared/toast/useToasts';
import Dialog from 'shared/components/Dialog';

const createOrgUnitFormId = 'createOrgUnitForm';

type CreateOrgUnitModalProps = SetOptional<
  CreateOrgUnitFormProps,
  'onSuccess'
> &
  Pick<CompactModalProps, 'isOpen' | 'onClose'>;

const CreateOrgUnitModal = ({
  isOpen,
  onClose,
  onSuccess,
  ...restProps
}: CreateOrgUnitModalProps) => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const handleSuccess = useCallback<CreateOrgUnitFormProps['onSuccess']>(
    (...params) => {
      onSuccess?.(...params);
      addToast({
        children: t('orgUnit.createOrgUnitModal.successToast'),
        variant: 'success',
      });
      onClose();
    },
    [t, onClose, addToast, onSuccess],
  );

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t('orgUnit.createOrgUnitModal.heading')}
      confirmLabel={t('create')}
      cancelLabel={t('cancel')}
      formId={createOrgUnitFormId}
    >
      <CreateOrgUnitForm
        {...restProps}
        id={createOrgUnitFormId}
        initialErrors={{
          lead: 'tst',
        }}
        onSuccess={handleSuccess}
      />
    </Dialog>
  );
};

export default CreateOrgUnitModal;
