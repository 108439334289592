import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import GlobalSpinner from 'shared/spinner/GlobalSpinner';
import { forcedLanguageChangeStorageKey } from 'shared/providers/I18nextProvider';

type SetActiveOrgLanguageProps = PropsWithChildren<object>;

const SetActiveOrgLanguage = ({ children }: SetActiveOrgLanguageProps) => {
  const [searchParams] = useSearchParams();
  const languageFromSearchParams = searchParams.get('language');

  const [isForcedLanguageChange] = useLocalStorage<boolean>(
    forcedLanguageChangeStorageKey,
  );

  const { i18n } = useTranslation();

  const {
    activeOrg: { languageLocaleCode },
  } = useActiveOrg();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!languageFromSearchParams && !isForcedLanguageChange) {
      setIsLoading(true);
      i18n.changeLanguage(languageLocaleCode).then(() => {
        setIsLoading(false);
      });
    }
  }, [
    languageLocaleCode,
    languageFromSearchParams,
    i18n,
    isForcedLanguageChange,
  ]);

  if (isLoading) {
    return <GlobalSpinner />;
  }

  return <>{children}</>;
};

export default SetActiveOrgLanguage;
