import cn from 'classnames';
import type { ComponentType, PropsWithChildren, SVGProps } from 'react';

import StatusMark from 'shared/status/StatusMark';
import type { ButtonProps } from 'shared/components/Button';
import Button from 'shared/components/Button';
import Space from 'shared/components/Space';
import type { StatusIndicator } from 'shared/status/StatusIndicator';
import Caption from 'shared/components/Caption';

import styles from './StatusCard.module.scss';

export type StatusCardProps = PropsWithChildren<{
  alwaysShowButtons?: boolean;
  backgroundOnHover?: boolean;
  buttons?: ButtonProps[];
  caption?: string;
  className?: string;
  comment?: string;
  heading?: string;
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
  onClick?: () => void;
  statusIndicator?: StatusIndicator;
}>;

const StatusCard = ({
  icon: Icon,
  statusIndicator,
  backgroundOnHover = true,
  caption,
  buttons,
  heading,
  comment,
  children,
  onClick,
  alwaysShowButtons = false,
  className,
}: StatusCardProps) => (
  <div
    className={cn(styles.container, className, {
      [styles.containerBackground]: backgroundOnHover,
    })}
    onClick={onClick}
  >
    <div className={styles.milestoneContainer}>
      <div className={styles.content}>
        {caption && (
          <div className={styles.captionContainer}>
            {Icon && <Icon className={styles.icon} />}
            <Caption size={'C2'} color={'secondary'}>
              {caption}
            </Caption>
          </div>
        )}
        <div className={styles.contentContainer}>
          <StatusMark statusIndicator={statusIndicator} hasBorder={false} />
          <div className={styles.textContainer}>
            {heading ? <p className={styles.heading}>{heading}</p> : null}
            {comment && <p className={styles.description}>{comment}</p>}
          </div>
        </div>
      </div>
      {children ? <div className={styles.children}>{children}</div> : null}
      {buttons?.length ? (
        <Space
          className={cn(styles.buttons, {
            [styles.buttonsAlwaysShow]: alwaysShowButtons,
          })}
        >
          {buttons.map((button, buttonIndex) => (
            <Button {...button} key={buttonIndex} />
          ))}
        </Space>
      ) : null}
    </div>
  </div>
);

export default StatusCard;
