import type { ButtonProps } from 'shared/components/Button';
import Button from 'shared/components/Button';
import IfCanPerformOrgOrOrgUnitAction from 'user/ability/IfCanPerformOrgOrOrgUnitAction';

export type CreateStrategyButtonProps = ButtonProps;

const CreateStrategyButton = ({
  children,
  className,
  variant = 'outlined',
  ...restProps
}: CreateStrategyButtonProps) => (
  <IfCanPerformOrgOrOrgUnitAction
    orgAction={'WRITE_STRATEGY'}
    orgUnitAction={'WRITE'}
  >
    <Button variant={variant} className={className} {...restProps}>
      {children}
    </Button>
  </IfCanPerformOrgOrOrgUnitAction>
);

export default CreateStrategyButton;
