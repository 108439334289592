import { AnimatePresence, motion } from 'framer-motion';
import cn from 'classnames';
import type { ReactNode } from 'react';
import { useCallback } from 'react';

import { useTreeRow } from 'shared/hooks/useTree';
import type { GetChildTreeItemsFn, BaseTreeItem } from 'shared/hooks/useTree';
import DropdownButton from 'shared/components/DropdownButton';

import type { RenderTreeListRowContentParams } from '../TreeList.type';
import { directChildItemsContainerAnimation } from './TreeListRow.animation';
import styles from './TreeListRow.module.scss';

type TreeListRowProps<TreeItemParam extends BaseTreeItem> = {
  allExpanded?: boolean;
  childItems: BaseTreeItem[];
  expandedItemsIds?: BaseTreeItem['id'][];
  getChildItems: GetChildTreeItemsFn<TreeItemParam>;
  isExpanded: boolean;
  isSelected?: boolean;
  level: number;
  onToggleIsExpanded: () => void;
  parentItem: BaseTreeItem;
  renderRowContent: (
    params: RenderTreeListRowContentParams<TreeItemParam>,
  ) => ReactNode;
};

const TreeListRow = <TreeItemParam extends BaseTreeItem>({
  parentItem,
  childItems,
  getChildItems,
  isSelected = false,
  level,
  isExpanded: isRowExpanded,
  onToggleIsExpanded: onToggleIsRowExpanded,
  allExpanded,
  expandedItemsIds,
  renderRowContent,
}: TreeListRowProps<TreeItemParam>) => {
  const {
    directChildItems,
    nonDirectChildItems,
    toggleIsItemExpanded,
    isItemExpanded,
    hasDirectChildItems,
  } = useTreeRow<TreeItemParam>({
    parentItem: parentItem as TreeItemParam,
    childItems: childItems as TreeItemParam[],
    getChildItems,
    allExpanded,
    expandedItemsIds,
  });

  const onContentContainerClick = useCallback(() => {
    if (hasDirectChildItems) {
      onToggleIsRowExpanded();
    }
  }, [hasDirectChildItems, onToggleIsRowExpanded]);

  const childItemsRows = (
    <AnimatePresence initial={false}>
      {isRowExpanded ? (
        <motion.div
          {...directChildItemsContainerAnimation}
          className={styles.treeListRowDirectChildItemsContainer}
        >
          {directChildItems.map((directChildItem) => (
            <TreeListRow<TreeItemParam>
              parentItem={directChildItem}
              childItems={nonDirectChildItems}
              getChildItems={getChildItems}
              isExpanded={isItemExpanded(directChildItem.id)}
              expandedItemsIds={expandedItemsIds}
              onToggleIsExpanded={() =>
                toggleIsItemExpanded(directChildItem.id)
              }
              level={level + 1}
              allExpanded={allExpanded}
              key={directChildItem.id}
              renderRowContent={renderRowContent}
            />
          ))}
        </motion.div>
      ) : null}
    </AnimatePresence>
  );

  const dropdownButton = hasDirectChildItems ? (
    <DropdownButton
      isActive={isRowExpanded}
      onClick={(event) => {
        event.stopPropagation();
        onToggleIsRowExpanded();
      }}
    />
  ) : null;

  const rowContent = renderRowContent({
    item: parentItem as any,
    dropdownButton,
  });

  return (
    <div
      className={cn(styles.treeListRow, {
        [styles.treeListRowTopLevel]: level === 1,
        [styles.treeListRowExpanded]: isRowExpanded,
        [styles.treeListRowSelected]: isSelected,
      })}
    >
      <div
        onClick={onContentContainerClick}
        className={cn(styles.treeListRowRowContentContainer, {
          [styles.treeListRowClickable]: hasDirectChildItems,
        })}
      >
        {rowContent}
      </div>
      {childItemsRows}
    </div>
  );
};

export default TreeListRow;
