import type { PropsWithChildren } from 'react';
import { useCallback, useState } from 'react';
import { css } from '@emotion/react';

import type { ButtonVariant } from 'shared/components/Button';
import Button from 'shared/components/Button';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import type { StrategyElementWithAuthorizedActions } from 'user/ability/canPerformStrategyElementAction';
import { canCreateStrategyElement } from 'user/ability/canPerformStrategyElementAction';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useActiveOrg } from 'org/ActiveOrgProvider';

import type { CreateActionProps } from './CreateAction/CreateAction';
import CreateAction from './CreateAction';

type Props = PropsWithChildren<{
  className?: string;
  objective?: CreateActionProps['objective'] &
    StrategyElementWithAuthorizedActions;
  showSuccessDialog?: boolean;
  variant?: ButtonVariant;
}>;

const CreateActionButton = ({
  className,
  variant,
  objective,
  showSuccessDialog,
  children,
}: Props) => {
  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);

  const isAllowed = canCreateStrategyElement(activeOrg, orgUnit, objective);

  if (!isAllowed) return null;

  return (
    <>
      <Button
        onClick={handleOpen}
        variant={variant}
        icon={PlusIcon}
        iconPosition={'start'}
        className={className}
      >
        {children}
      </Button>

      <div css={css({ display: 'none' })}>
        <CreateAction
          isOpen={isOpen}
          onClose={handleClose}
          objective={objective}
          showSuccessDialog={showSuccessDialog}
        />
      </div>
    </>
  );
};

export default CreateActionButton;
