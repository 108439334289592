import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'shared/components/Button';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import useCanPerformOrgOrOrgUnitAction from 'user/ability/useCanPerformOrgOrOrgUnitAction';

import { teamMembersRoutesPaths } from '../TeamMembers.routing.paths';

type TeamMembersAddMemberButtonProps = {
  className?: string;
};

const TeamMembersAddMemberButton = ({
  className,
}: TeamMembersAddMemberButtonProps) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleAddMember = useCallback(() => {
    navigate(teamMembersRoutesPaths.addMember(), { replace: true });
  }, [navigate]);

  const canAddMember = useCanPerformOrgOrOrgUnitAction(
    'WRITE_ACCOUNT',
    'WRITE',
  );

  if (!canAddMember) return null;

  return (
    <Button
      onClick={handleAddMember}
      icon={PlusIcon}
      iconPosition={'start'}
      className={className}
    >
      {t('team.teamMembers.addMemberButton')}
    </Button>
  );
};

export default TeamMembersAddMemberButton;
