import { useMemo } from 'react';

import { useActiveOrg } from 'org/ActiveOrgProvider';

import { TeamAdapter } from './TeamAdapter';
import { useTeamAdapter } from './useTeamAdapter';

export const useTeamSlug = () => {
  const { activeOrg } = useActiveOrg();
  const { teamAdapter } = useTeamAdapter();

  return useMemo(
    () => ({
      teamSlug: teamAdapter.toParam(),
      orgTeamSlug: TeamAdapter.fromOrg(activeOrg).toParam(),
    }),
    [activeOrg, teamAdapter],
  );
};
