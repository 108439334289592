import cn from 'classnames';
import type { PropsWithChildren, ReactNode } from 'react';
import { useMemo, useRef } from 'react';
import { useToggle } from 'react-use';

import Button from 'shared/components/Button';
import type { ButtonProps } from 'shared/components/Button';
import type { LinkTabsProps } from 'shared/components/LinkTabs';
import LinkTabs from 'shared/components/LinkTabs';
import type { MenuItemProps } from 'shared/components/__DEPRECATED__/Menu';
import Menu from 'shared/components/__DEPRECATED__/Menu';
import LayoutHeaderContainer from 'shared/components/Layout/LayoutHeaderContainer';
import type { LayoutHeaderTopSectionProps } from 'shared/components/Layout';
import LayoutHeaderTopSection from 'shared/components/Layout/LayoutHeaderTopSection';
import LayoutHeaderHeading from 'shared/components/Layout/LayoutHeaderHeading';
import type { BreadcrumbsProps } from 'shared/components/Breadcrumbs';

import styles from './LayoutHeader.module.scss';

export type LayoutHeaderProps = PropsWithChildren<{
  aboveHeading?: ReactNode;
  breadcrumbsItems?: BreadcrumbsProps['items'];
  buttons?: ButtonProps[];
  description?: string | ReactNode;
  // todo drops support for arbitrary object for `heading` prop and use only `string | ReactNode` instead
  heading?:
    | string
    | { content: string | ReactNode; isBold: boolean }
    | ReactNode;
  menuItems?: MenuItemProps[];
  navigationItems?: LinkTabsProps['items'];
  topRightNavItem?: Maybe<LayoutHeaderTopSectionProps['topRightLinkItem']>;
}>;

const LayoutHeader = ({
  heading: headingProp,
  buttons,
  breadcrumbsItems,
  navigationItems,
  topRightNavItem,
  description,
  menuItems,
  children,
  aboveHeading: aboveHeadingProp,
}: LayoutHeaderProps) => {
  const menuButtonElementRef = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, toggleIsMenuOpen] = useToggle(false);

  const { isBold, heading } = useMemo(() => {
    if (typeof headingProp === 'string')
      return { heading: headingProp, isBold: true };

    if (
      headingProp &&
      typeof headingProp === 'object' &&
      'content' in headingProp
    )
      return { heading: headingProp?.content, isBold: false };

    return { heading: headingProp };
  }, [headingProp]);

  return (
    <LayoutHeaderContainer>
      <div className={styles.content}>
        {(breadcrumbsItems || topRightNavItem) && (
          <LayoutHeaderTopSection
            breadcrumbs={breadcrumbsItems}
            topRightLinkItem={topRightNavItem}
            className={styles.topSection}
          />
        )}
        {aboveHeadingProp && (
          <div className={styles.aboveHeadingContainer}>{aboveHeadingProp}</div>
        )}
        <div className={styles.mainContainer}>
          {heading && <LayoutHeaderHeading content={heading} isBold={isBold} />}
          <div className={styles.headingExtra}>
            {children}
            {buttons && (
              <div className={styles.buttons}>
                {buttons.map(
                  (
                    { className: buttonClassName, ...restButton },
                    buttonIndex,
                  ) => (
                    <Button
                      {...restButton}
                      key={buttonIndex}
                      className={cn(buttonClassName, styles.button)}
                    />
                  ),
                )}
              </div>
            )}
            {menuItems?.length ? (
              <div className={styles.menuContainer}>
                <Menu.Button
                  onClick={toggleIsMenuOpen}
                  ref={menuButtonElementRef}
                  className={styles.buttonMenu}
                />
                <Menu
                  referenceElement={menuButtonElementRef.current}
                  isOpen={isMenuOpen}
                  onClose={() => toggleIsMenuOpen(false)}
                >
                  {menuItems?.map((menuItem, menuItemIndex) => (
                    <Menu.Item {...menuItem} key={menuItemIndex} />
                  ))}
                </Menu>
              </div>
            ) : null}
          </div>
        </div>
        {description && <p className={styles.description}>{description}</p>}
        {navigationItems ? <LinkTabs items={navigationItems} /> : null}
      </div>
    </LayoutHeaderContainer>
  );
};

export default LayoutHeader;
