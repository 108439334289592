import { useCallback } from 'react';

import type { SelectUserTenantInputProps } from 'user/SelectUserTenantInput/SelectUserTenantInput';
import SelectUserTenantInput from 'user/SelectUserTenantInput/SelectUserTenantInput';
import { useActiveOrg } from 'org/ActiveOrgProvider';

const UserNavbarSelectUserTenantInput = () => {
  const { activeOrg, setActiveOrg } = useActiveOrg();

  const handleGetSelectInitialValue = useCallback<
    NonNullable<SelectUserTenantInputProps['getInitialValue']>
  >(
    (options) =>
      options.find((option) => option.value.orgKey === activeOrg.orgKey),
    [activeOrg.orgKey],
  );

  const handleSelectChange = useCallback<
    NonNullable<SelectUserTenantInputProps['onChange']>
  >(
    (option) => {
      if (option) {
        setActiveOrg(option.value.orgKey);
      }
    },
    [setActiveOrg],
  );

  return (
    <SelectUserTenantInput
      getInitialValue={handleGetSelectInitialValue}
      onChange={handleSelectChange}
    />
  );
};

export default UserNavbarSelectUserTenantInput;
