import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteStatusRequestMutationVariables = Types.Exact<{
  statusRequestId: Types.Scalars['ID'];
}>;


export type DeleteStatusRequestMutation = { __typename: 'Mutation', deleteStatusRequests: boolean };


export const DeleteStatusRequestDocument = gql`
    mutation DeleteStatusRequest($statusRequestId: ID!) {
  deleteStatusRequests(idsToDelete: [$statusRequestId])
}
    `;
export type DeleteStatusRequestMutationFn = Apollo.MutationFunction<DeleteStatusRequestMutation, DeleteStatusRequestMutationVariables>;

/**
 * __useDeleteStatusRequestMutation__
 *
 * To run a mutation, you first call `useDeleteStatusRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStatusRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStatusRequestMutation, { data, loading, error }] = useDeleteStatusRequestMutation({
 *   variables: {
 *      statusRequestId: // value for 'statusRequestId'
 *   },
 * });
 */
export function useDeleteStatusRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStatusRequestMutation, DeleteStatusRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStatusRequestMutation, DeleteStatusRequestMutationVariables>(DeleteStatusRequestDocument, options);
      }
export type DeleteStatusRequestMutationHookResult = ReturnType<typeof useDeleteStatusRequestMutation>;
export type DeleteStatusRequestMutationResult = Apollo.MutationResult<DeleteStatusRequestMutation>;
export type DeleteStatusRequestMutationOptions = Apollo.BaseMutationOptions<DeleteStatusRequestMutation, DeleteStatusRequestMutationVariables>;