import type { TFuncKey, TFunction } from 'react-i18next';

import type { TipsAndTricksSegment } from 'shared/sidesheets/SidesheetsTipsAndTricksContent';

export type PyramidItem = {
  description: string;
  i18nKey: TFuncKey;
  sidesheetSegments: TipsAndTricksSegment[];
  step: number;
  title: string;
};

const getPyramidItems = (t: TFunction): PyramidItem[] => {
  const themeSidesheetSegments = getThemeSidesheetSegments(t);

  return [
    {
      title: t('strategy.pyramid.items.1.title'),
      i18nKey: 'strategy.mission',
      description: t('strategy.pyramid.items.1.description'),
      step: 1,
      sidesheetSegments: t(
        'sidesheets.context.strategy.tipsAndTricks.mission.content',
        { returnObjects: true },
      ),
    },
    {
      title: t('strategy.pyramid.items.2.title'),
      i18nKey: 'strategy.vision',
      description: t('strategy.pyramid.items.2.description'),
      step: 1,
      sidesheetSegments: t(
        'sidesheets.context.strategy.tipsAndTricks.vision.content',
        { returnObjects: true },
      ),
    },
    {
      title: t('strategy.pyramid.items.3.title'),
      i18nKey: 'strategy.goal',
      description: t('strategy.pyramid.items.3.description'),
      step: 1,
      sidesheetSegments: t(
        'sidesheets.context.strategy.tipsAndTricks.goals.content',
        { returnObjects: true },
      ),
    },
    {
      title: t('strategy.pyramid.items.4.title'),
      i18nKey: 'strategy.choice',
      description: t('strategy.pyramid.items.4.description'),
      step: 1,
      sidesheetSegments: t(
        'sidesheets.context.strategy.tipsAndTricks.choices.content',
        { returnObjects: true },
      ),
    },
    {
      title: t('strategy.pyramid.items.5.title'),
      i18nKey: 'theme.theme',
      description: t('strategy.pyramid.items.5.description'),
      step: 2,
      sidesheetSegments: themeSidesheetSegments,
    },
    {
      title: t('strategy.pyramid.items.6.title'),
      i18nKey: 'objective.objective',
      description: t('strategy.pyramid.items.6.description'),
      step: 2,
      sidesheetSegments: t(
        'sidesheets.context.objective.tipsAndTricks.content',
        {
          returnObjects: true,
        },
      ),
    },
    {
      title: t('strategy.pyramid.items.7.title'),
      i18nKey: 'metric.metric',
      description: t('strategy.pyramid.items.7.description'),
      step: 2,
      sidesheetSegments: t('sidesheets.context.metric.tipsAndTricks.content', {
        returnObjects: true,
      }),
    },
    {
      title: t('strategy.pyramid.items.8.title'),
      i18nKey: 'initiative.initiative',
      description: t('strategy.pyramid.items.8.description'),
      step: 2,
      sidesheetSegments: t(
        'sidesheets.context.initiative.tipsAndTricks.content',
        { returnObjects: true },
      ),
    },
    {
      title: t('strategy.pyramid.items.9.title'),
      i18nKey: 'strategy.value',
      description: t('strategy.pyramid.items.9.description'),
      step: 1,
      sidesheetSegments: t(
        'sidesheets.context.strategy.tipsAndTricks.values.content',
        { returnObjects: true },
      ),
    },
    {
      title: t('strategy.pyramid.items.10.title'),
      i18nKey: 'strategy.behavior',
      description: t('strategy.pyramid.items.10.description'),
      step: 1,
      sidesheetSegments: t(
        'sidesheets.context.strategy.tipsAndTricks.behaviors.content',
        { returnObjects: true },
      ),
    },
  ];
};

export default getPyramidItems;

const getThemeSidesheetSegments = (t: TFunction) => {
  const rows = t(
    'strategy.strategyRoom.themes.tabs.manageThemes.infoCard.content',
    { returnObjects: true },
  ) as string[];

  const segments: TipsAndTricksSegment[] = [];

  let segmentLines: string[] = [];

  const addSegment = () => {
    const heading = segmentLines[0];

    if (heading) {
      segments.push({
        heading,
        description: '',
        article: segmentLines.slice(1, segmentLines.length).join(''),
      });

      segmentLines = [];
    }
  };

  rows.forEach((row) => {
    if (row.startsWith('<h3>')) {
      addSegment();
    }

    segmentLines.push(row.replaceAll('<h3>', '').replaceAll('</h3>', ''));
  });

  addSegment();

  return segments.slice(1, segments.length);
};
