import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { StrategyRoomManageThemesThemeFragmentDoc } from './StrategyRoomThemesManageThemes.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StrategyRoomUpdateThemeMutationVariables = Types.Exact<{
  input: Types.ThemeInput;
}>;


export type StrategyRoomUpdateThemeMutation = { __typename: 'Mutation', updateTheme: { __typename: 'Theme', id: string, name?: string | undefined, isActive: boolean, description?: string | undefined, orderNumber?: number | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, themeCategory?: { __typename: 'ThemeCategory', id: string, name?: string | undefined } | undefined, objectives: Array<{ __typename: 'Objective', id: string, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string, complete: boolean } | undefined, theme?: { __typename: 'Theme', id: string } | undefined }> } };


export const StrategyRoomUpdateThemeDocument = gql`
    mutation StrategyRoomUpdateTheme($input: ThemeInput!) {
  updateTheme(input: $input) {
    ...strategyRoomManageThemesTheme
  }
}
    ${StrategyRoomManageThemesThemeFragmentDoc}`;
export type StrategyRoomUpdateThemeMutationFn = Apollo.MutationFunction<StrategyRoomUpdateThemeMutation, StrategyRoomUpdateThemeMutationVariables>;

/**
 * __useStrategyRoomUpdateThemeMutation__
 *
 * To run a mutation, you first call `useStrategyRoomUpdateThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStrategyRoomUpdateThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [strategyRoomUpdateThemeMutation, { data, loading, error }] = useStrategyRoomUpdateThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStrategyRoomUpdateThemeMutation(baseOptions?: Apollo.MutationHookOptions<StrategyRoomUpdateThemeMutation, StrategyRoomUpdateThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StrategyRoomUpdateThemeMutation, StrategyRoomUpdateThemeMutationVariables>(StrategyRoomUpdateThemeDocument, options);
      }
export type StrategyRoomUpdateThemeMutationHookResult = ReturnType<typeof useStrategyRoomUpdateThemeMutation>;
export type StrategyRoomUpdateThemeMutationResult = Apollo.MutationResult<StrategyRoomUpdateThemeMutation>;
export type StrategyRoomUpdateThemeMutationOptions = Apollo.BaseMutationOptions<StrategyRoomUpdateThemeMutation, StrategyRoomUpdateThemeMutationVariables>;