import { i18n } from 'shared/services/i18n.service';
import type { SetRemoveIdInput, Theme } from 'types.graphql.generated';

import type { SelectThemeInputOption } from './SelectThemeInput.type';

export const getOptionLabel = (option: SelectThemeInputOption): string =>
  option.value?.name ?? i18n.t('theme.noTheme');

export const resolveInitialValue = (
  theme?: Maybe<Pick<Theme, 'id' | 'name'>>,
): SelectThemeInputOption => {
  if (theme) {
    return {
      value: {
        id: theme.id,
        name: theme.name,
      },
    };
  }
  return {
    value: null,
  };
};

export const resolveSetRemoveIdInput = (params: {
  option: SelectThemeInputOption;
  previousTheme?: Maybe<Pick<Theme, 'id' | 'name'>>;
}): SetRemoveIdInput => {
  const { option, previousTheme } = params;
  if (option.value) {
    return {
      idToSet: option.value.id,
    };
  }
  if (!option.value && previousTheme) {
    return {
      idToRemove: previousTheme.id,
    };
  }
  return {};
};
