import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import styled from '@emotion/styled';

import Space from 'shared/components/Space';
import LinkTabs from 'shared/components/LinkTabs';
import { useTeamAdapter } from 'team/TeamAdapter';

import Category from './ToolkitOverviewCategory';
import RedirectToDefaultCategory from './ToolkitOverviewRedirectToDefaultCategory';
import { getLinkTabsItems } from './ToolkitOverview.utils';

const OutledContainer = styled.div`
  margin-top: 1rem;
`;

const ToolkitOverview = () => {
  const { t } = useTranslation();

  const { category } = useParams() as {
    category?: string;
  };

  const { teamAdapter } = useTeamAdapter();

  const linkTabsItems = useMemo(
    () => getLinkTabsItems(teamAdapter, t),
    [t, teamAdapter],
  );

  return (
    <Space direction={'vertical'} size={'big'}>
      <LinkTabs items={linkTabsItems} />
      <OutledContainer>
        <Outlet key={category} />
      </OutledContainer>
    </Space>
  );
};

ToolkitOverview.Category = Category;
ToolkitOverview.RedirectToDefaultCategory = RedirectToDefaultCategory;

export default ToolkitOverview;
