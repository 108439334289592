import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import StatusMark from 'shared/status/StatusMark/StatusMark';
import Tag from 'shared/components/Tag';
import { ReactComponent as CheckIcon } from 'shared/static/icons/icon-check.svg';

import {
  getIcon,
  getStatusIndicator,
} from './TimelineEntryContentStatusRequestBodyItem.utils';
import StatusRequestSubjectLink from './StatusRequestSubjectLink';
import ShareProgressButton from './ShareProgressButton';
import type { ContributionMyFollowUpsStatusRequestFragment } from '../ContributionMyFollowUpsViewMore.graphql.generated';
import DeleteStatusRequestButton from './DeleteStatusRequestButton';

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
  height: 40px;
`;

const IconContainer = styled.div`
  color: ${(props) => props.theme.color.neutral2};
  line-height: 100%;
  width: 14px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;

  button {
    display: inline-block;
    height: 40px;
  }
`;

const DoneButton = styled(Tag)`
  font-size: 0.571rem;
`;

type Props = {
  statusRequest: ContributionMyFollowUpsStatusRequestFragment;
};

const TimelineEntryContentStatusRequestBodyItem = ({
  statusRequest,
}: Props) => {
  const { t } = useTranslation();

  const Icon = getIcon(statusRequest);

  return (
    <Container>
      <IconContainer>{Icon && <Icon />}</IconContainer>

      <StatusMark statusIndicator={getStatusIndicator(statusRequest)} />

      <StatusRequestSubjectLink statusRequest={statusRequest} />

      <ButtonContainer>
        {statusRequest.isComplete ? (
          <DoneButton icon={CheckIcon}>
            {t('followUps.timeline.insightRequest.button.done')}
          </DoneButton>
        ) : (
          <>
            <ShareProgressButton statusRequest={statusRequest} />
            <DeleteStatusRequestButton statusRequestId={statusRequest.id} />
          </>
        )}
      </ButtonContainer>
    </Container>
  );
};

export default TimelineEntryContentStatusRequestBodyItem;
