import type { PropsWithChildren, ReactNode } from 'react';
import styled from '@emotion/styled';
import hexToRgba from 'hex-to-rgba';
import { css, type SerializedStyles } from '@emotion/react';

import type { Size } from 'shared/types/size.type';

import Heading from './CardHeading';
import type { CardBorder, CardVariant } from './Card.type';
import CardProvider from './CardProvider';
import Flex from '../Flex';

const CardSection = styled.section<CardProps>`
  border-left: 3px solid transparent;
  position: relative;

  ${(props) =>
    props.hasBackground &&
    `
      background-color: ${props.theme.color.white};
      border: 0;
      border-radius: 1.25rem;
    `}

  ${(props) =>
    props.hasShadow &&
    `
      box-shadow: 0 .25rem 2rem ${hexToRgba(props.theme.color.black, 0.15)};
    `}

  ${(props) =>
    props.isActive &&
    `
      background-color: ${props.theme.color.backgroundDark};
      border-left: 3px solid ${props.theme.color.primary};
    `}

  ${(props) =>
    props.variant === 'info' &&
    `
      background-color: ${props.theme.color.primaryVariant};
    `}

  ${(props) =>
    props.padding === 'none' &&
    `
      padding: 0;
    `}

  ${(props) =>
    props.padding === 'small' &&
    `
      padding: 1.429rem 1.429rem;
    `}

  ${(props) =>
    props.padding === 'medium' &&
    `
      padding: 2rem 2.5rem;
    `}

  ${(props) =>
    props.border === 'top' &&
    `
      & ~ & {
        border-top: 1px solid ${props.theme.color.strokeLight};
      }
    `}

  ${(props) =>
    props.border === 'full' &&
    `
      border: 1px solid ${props.theme.color.strokeLight};
    `}
`;

const CardContent = styled.div`
  margin-top: 0.25rem;
`;

type CardProps = PropsWithChildren<{
  border?: CardBorder;
  className?: string;
  contentClassName?: string;
  contentStyle?: SerializedStyles;
  controls?: ReactNode;
  hasBackground?: boolean;
  hasShadow?: boolean;
  isActive?: boolean;
  onClick?: () => void;
  padding?: Extract<Size, 'small' | 'medium'> | 'none';
  variant?: CardVariant;
}>;

const Card = ({
  variant,
  controls,
  border = 'full',
  hasBackground = false,
  hasShadow = false,
  isActive = false,
  padding = 'medium',
  children,
  className,
  contentClassName,
  contentStyle,
  onClick,
}: CardProps) => {
  return (
    <CardSection
      variant={variant}
      border={border}
      hasBackground={hasBackground}
      hasShadow={hasShadow}
      isActive={isActive}
      padding={padding}
      className={className}
      onClick={onClick}
    >
      <Flex gap={8}>
        <div css={css({ flexGrow: '1', overflow: 'hidden' })}>
          {children && (
            <CardContent className={contentClassName} css={contentStyle}>
              {children}
            </CardContent>
          )}
        </div>

        {controls}
      </Flex>
    </CardSection>
  );
};

const CardWithProvider = ({ variant = 'default', ...restProps }: CardProps) => (
  <CardProvider variant={variant}>
    <Card variant={variant} {...restProps} />
  </CardProvider>
);

CardWithProvider.Heading = Heading;

export default CardWithProvider;
