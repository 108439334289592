import type { ListBoxRowComponentProps } from 'shared/components/ListBox';
import Text from 'shared/components/Text';
import Space from 'shared/components/Space';
import UserAvatar from 'user/UserAvatar';

import styles from './UserMultiSelectListBoxRow.module.scss';
import type { UserMultiSelectItem } from '../UserMultiSelect.type';

type UserMultiSelectListBoxRowProps =
  ListBoxRowComponentProps<UserMultiSelectItem>;

const UserMultiSelectListBoxRow = ({
  item,
}: UserMultiSelectListBoxRowProps) => (
  <Space size={'small'} isCentered={true}>
    <UserAvatar user={item} className={styles.userAvatar} showTooltip={false} />
    <Text variant={'emphasis'}>{item.displayName || item.email}</Text>
  </Space>
);

export default UserMultiSelectListBoxRow;
