import cn from 'classnames';
import { useMatch } from 'react-router-dom';

import type { Objective } from 'types.graphql.generated';
import { ReactComponent as LinkIcon } from 'shared/static/icons/icon-link.svg';
import Text from 'shared/components/Text';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';
import OptionalLink from 'shared/components/OptionalLink';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import Flex from 'shared/components/Flex';

import styles from './ObjectiveLink.module.scss';

type ObjectiveLinkProps = {
  className?: string;
  iconClassName?: string;
  objective: Pick<Objective, 'id' | 'name' | 'currentUserAuthorizedActions'>;
  variant?: 'primary' | 'secondary';
};

const ObjectiveLink = ({
  objective,
  variant = 'primary',
  className,
  iconClassName,
}: ObjectiveLinkProps) => {
  const { teamAdapter } = useTeamAdapter();

  const isCurrentObjective = !!useMatch(objectiveRoutesPaths.root());

  const to = objectiveRoutesPaths.root({
    params: {
      objectiveId: objective.id,
      teamSlug: teamAdapter.toParam(),
    },
  });

  return (
    <div
      className={cn(
        styles.objectiveLink,
        {
          [styles.objectiveLinkPrimary]: variant === 'primary',
          [styles.objectiveLinkSecondary]: variant === 'secondary',
          [styles.objectiveLinkNotClickable]: isCurrentObjective,
        },
        className,
      )}
    >
      <OptionalLink
        to={to}
        isActive={canPerformStrategyElementAction(objective, 'READ')}
      >
        <Flex alignItems={'center'}>
          <LinkIcon className={cn(styles.objectiveLinkIcon, iconClassName)} />
          <Text
            variant={'regular'}
            ellipsis={true}
            className={styles.objectiveLinkContent}
          >
            {objective.name}
          </Text>
        </Flex>
      </OptionalLink>
    </div>
  );
};

export default ObjectiveLink;
