import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { ActionPartsFragmentDoc } from '../../actions.fragment.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddActionMutationVariables = Types.Exact<{
  input: Types.ActionInput;
}>;


export type AddActionMutation = { __typename: 'Mutation', addAction: { __typename: 'Action', id: string, name?: string | undefined, description?: string | undefined, isCurrentUserOwner: boolean, objective?: { __typename: 'Objective', id: string } | undefined, priority?: { __typename: 'Priority', number: number, label: string } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, currentStatus?: { __typename: 'ActionStatus', id: string, completionRate?: number | undefined, complete: boolean, statusDateTime: any, statusIndicator: StatusIndicatorClass, auditRecord: { __typename: 'AuditRecord', updateDateTime?: any | undefined, createDateTime?: any | undefined, updateBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined, createBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined } } | undefined, auditRecord: { __typename: 'AuditRecord', updateDateTime?: any | undefined, createDateTime?: any | undefined, updateBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined, createBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined }, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined } } };


export const AddActionDocument = gql`
    mutation AddAction($input: ActionInput!) {
  addAction(input: $input) {
    ...actionParts
  }
}
    ${ActionPartsFragmentDoc}`;
export type AddActionMutationFn = Apollo.MutationFunction<AddActionMutation, AddActionMutationVariables>;

/**
 * __useAddActionMutation__
 *
 * To run a mutation, you first call `useAddActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActionMutation, { data, loading, error }] = useAddActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddActionMutation(baseOptions?: Apollo.MutationHookOptions<AddActionMutation, AddActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddActionMutation, AddActionMutationVariables>(AddActionDocument, options);
      }
export type AddActionMutationHookResult = ReturnType<typeof useAddActionMutation>;
export type AddActionMutationResult = Apollo.MutationResult<AddActionMutation>;
export type AddActionMutationOptions = Apollo.BaseMutationOptions<AddActionMutation, AddActionMutationVariables>;