import { useTranslation } from 'react-i18next';

import type { ListBoxRowComponentProps } from 'shared/components/ListBox';
import Text from 'shared/components/Text';

import type { ActionStatusMultiSelectItem } from '../ActionStatusMultiSelect.type';
import { getActionMultiSelectItemLabel } from '../ActionStatusMultiSelect.utils';

type ActionStatusMultiSelectListBoxRowProps =
  ListBoxRowComponentProps<ActionStatusMultiSelectItem>;

const ActionStatusMultiSelectListBoxRow = ({
  item,
}: ActionStatusMultiSelectListBoxRowProps) => {
  const { t } = useTranslation();

  return (
    <Text variant={'emphasis'}>
      {getActionMultiSelectItemLabel(item.id, t)}
    </Text>
  );
};

export default ActionStatusMultiSelectListBoxRow;
