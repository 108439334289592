import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';

const getCalendarStyle = (theme: Theme) => css`
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  header {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 8px;
    justify-content: space-between;

    h2 {
      font-size: 1.143rem;
      margin: 0;
      padding: 0;
    }

    button {
      cursor: pointer;
    }
  }

  tbody {
    td {
      cursor: pointer;
      text-align: center;

      &[aria-disabled='true'] {
        cursor: not-allowed;
      }
    }

    [aria-disabled='true'] {
      color: ${theme.color.strokeMedium};
    }

    [aria-label*='Today'] {
      font-weight: 700;
      border-radius: 4px;
      background: ${theme.color.backgroundDark};
    }

    [data-outside-month] {
      display: none;
    }

    [data-pressed],
    [data-selected] {
      background: ${theme.color.primary};
      color: ${theme.color.white};
      border-radius: 4px;
    }
  }
`;

export default getCalendarStyle;
