import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import GlobalSpinner from 'shared/spinner/GlobalSpinner';

import {
  loadSource,
  emitLoadOrgTermSourceEvent,
  isTerminologyFileUrlValid,
} from './OrgTermsProvider.utils';

const terminologyFileUrlStorageKey = 'terminologyFileUrl';
const terminologyFileUrlSearchParam = 'terminologyFileUrl';

type OrgTermsProviderProps = PropsWithChildren<object>;

const OrgTermsProvider = ({ children }: OrgTermsProviderProps) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const {
    activeOrg: { orgKey, terminologyFileUrl },
  } = useActiveOrg();

  const [storedTerminologyFileUrl, setStoredTerminologyFileUrl] =
    useLocalStorage<string>(terminologyFileUrlStorageKey);

  const [searchParams] = useSearchParams();
  const paramTerminologyFileUrl = searchParams.get(
    terminologyFileUrlSearchParam,
  );

  const suppliedTerminologyFileUrl =
    paramTerminologyFileUrl || storedTerminologyFileUrl;

  useEffect(() => {
    if (isTerminologyFileUrlValid(suppliedTerminologyFileUrl)) {
      loadSource(suppliedTerminologyFileUrl)
        .then((source) => {
          emitLoadOrgTermSourceEvent(orgKey, source);
          setStoredTerminologyFileUrl(suppliedTerminologyFileUrl);
        })
        .finally(() => setIsInitialized(true));
    } else if (isTerminologyFileUrlValid(terminologyFileUrl)) {
      loadSource(terminologyFileUrl)
        .then((source) => {
          emitLoadOrgTermSourceEvent(orgKey, source);
        })
        .finally(() => setIsInitialized(true));
    } else {
      setIsInitialized(true);
    }
  }, [
    orgKey,
    setStoredTerminologyFileUrl,
    suppliedTerminologyFileUrl,
    terminologyFileUrl,
  ]);

  if (!isInitialized) return <GlobalSpinner />;

  return <>{children}</>;
};

export default OrgTermsProvider;
