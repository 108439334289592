import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserLimitedProfileFragmentDoc } from '../../user/userProfile.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveOneThingCampaignMutationVariables = Types.Exact<{
  input: Types.OneThingCampaignInput;
}>;


export type SaveOneThingCampaignMutation = { __typename: 'Mutation', saveOneThingCampaign: { __typename: 'OneThingCampaign', id: string, name: string, deadline?: any | undefined, state: { __typename: 'CampaignState', stage: Types.CampaignStage }, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined }, owner: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean }, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined } };


export const SaveOneThingCampaignDocument = gql`
    mutation SaveOneThingCampaign($input: OneThingCampaignInput!) {
  saveOneThingCampaign(input: $input) {
    id
    name
    deadline
    state {
      stage
    }
    timeLine {
      startDate
      endDate
    }
    owner {
      ...userLimitedProfile
    }
    orgUnit {
      id
      name
    }
  }
}
    ${UserLimitedProfileFragmentDoc}`;
export type SaveOneThingCampaignMutationFn = Apollo.MutationFunction<SaveOneThingCampaignMutation, SaveOneThingCampaignMutationVariables>;

/**
 * __useSaveOneThingCampaignMutation__
 *
 * To run a mutation, you first call `useSaveOneThingCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveOneThingCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveOneThingCampaignMutation, { data, loading, error }] = useSaveOneThingCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveOneThingCampaignMutation(baseOptions?: Apollo.MutationHookOptions<SaveOneThingCampaignMutation, SaveOneThingCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveOneThingCampaignMutation, SaveOneThingCampaignMutationVariables>(SaveOneThingCampaignDocument, options);
      }
export type SaveOneThingCampaignMutationHookResult = ReturnType<typeof useSaveOneThingCampaignMutation>;
export type SaveOneThingCampaignMutationResult = Apollo.MutationResult<SaveOneThingCampaignMutation>;
export type SaveOneThingCampaignMutationOptions = Apollo.BaseMutationOptions<SaveOneThingCampaignMutation, SaveOneThingCampaignMutationVariables>;