import type { HTMLAttributes, MouseEvent } from 'react';
import { forwardRef, useCallback } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import type { AvatarSize } from './AvatarImage.type';

const ImageContainer = styled.div<
  Pick<AvatarImageProps, 'size' | 'photoUrl' | 'isDisabled'>
>`
  align-items: center;
  background-color: ${(props) => props.theme.color.neutral2};
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  border: 2px solid ${(props) => props.theme.color.backgroundDark};
  border-radius: 50%;
  color: ${(props) => props.theme.color.white};
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  line-height: normal;
  overflow: hidden;
  user-select: none;
  opacity: ${(props) => (props.isDisabled ? '0.25' : '1')};

  ${(props) =>
    props.photoUrl &&
    css`
      background-image: url(${props.photoUrl});
    `}

  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: 0.857rem;
      height: 20px;
      width: 20px;
    `}

  ${(props) =>
    props.size === 'medium' &&
    css`
      height: 28px;
      width: 28px;
    `}

  ${(props) =>
    props.size === 'big' &&
    css`
      height: 35px;
      width: 35px;
    `}

  ${(props) =>
    props.size === 'large' &&
    css`
      font-size: 3rem;
      height: 80px;
      width: 80px;
    `}

  ${(props) =>
    props.size === 'huge' &&
    css`
      font-size: 6.75rem;
      height: 180px;
      width: 180px;
    `}
`;

export type AvatarImageProps = HTMLAttributes<HTMLDivElement> & {
  isDisabled?: boolean;
  photoUrl?: Maybe<string>;
  size?: AvatarSize;
};

export const AvatarImage = forwardRef<HTMLDivElement, AvatarImageProps>(
  (
    { photoUrl, size = 'medium', children, onClick, isDisabled, ...restProps },
    ref,
  ) => {
    const handleClick = useCallback(
      (event: MouseEvent<HTMLDivElement>) => {
        if (onClick) {
          event.preventDefault();
          event.stopPropagation();
          onClick(event);
        }
      },
      [onClick],
    );

    return (
      <ImageContainer
        {...restProps}
        onClick={handleClick}
        ref={ref}
        photoUrl={photoUrl}
        size={size}
        isDisabled={isDisabled}
        role={'img'}
      >
        {photoUrl ? null : children}
      </ImageContainer>
    );
  },
);

export default AvatarImage;
