import { useDefinedContext } from 'shared/utils/context.utils';

import SubmissionContext from './SubmissionContext';
import SubmissionProvider from './SubmissionProvider';

export const useSubmissionContextValue = () =>
  useDefinedContext(SubmissionContext, {
    hookName: useSubmissionContextValue.name,
    providerName: SubmissionProvider.name,
  });
