import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import TeamImage from 'team/TeamImage';
import Text from 'shared/components/Text';
import { ReactComponent as LinkIcon } from 'shared/static/icons/icon-link.svg';
import type { TeamAdapter } from 'team/TeamAdapter';
import { teamRoutesPaths } from 'team/team.routing.paths';
import OrgUnitContextMenu from 'orgUnit/OrgUnitContextMenu/OrgUnitContextMenu';
import type { OrgUnitForDeleteFragment } from 'orgUnit/useDeleteOrgUnit/useDeleteOrgUnit.graphql.generated';
import { canPerformOrgUnitAction } from 'user/ability/canPerformOrgUnitAction';
import OptionalLink from 'shared/components/OptionalLink';
import Flex from 'shared/components/Flex';

import styles from './TeamsTreeListRowContent.module.scss';

export type TeamsTreeListRowContentProps = {
  childTeamsCount: number;
  dropdownButton: ReactNode;
  membersCount: number;
  orgUnit: OrgUnitForDeleteFragment;
  renderRowEnd?: (orgUnit: { id: string }) => ReactNode;
  teamAdapter: TeamAdapter;
};

const TeamsTreeListRowContent = ({
  orgUnit,
  membersCount,
  childTeamsCount,
  teamAdapter,
  dropdownButton,
  renderRowEnd,
}: TeamsTreeListRowContentProps) => {
  const { t } = useTranslation();

  const orgUnitLink = teamRoutesPaths.root({
    params: {
      teamSlug: teamAdapter.toParam(),
    },
  });

  const canReadOrgUnit = canPerformOrgUnitAction(orgUnit, 'READ');

  return (
    <Flex alignItems={'center'} gap={'1rem'}>
      <TeamImage orgUnit={orgUnit} size={'small'} />

      <Flex direction={'column'}>
        <Flex alignItems={'center'} gap={8}>
          <OptionalLink to={orgUnitLink} isActive={canReadOrgUnit}>
            {orgUnit.name}
          </OptionalLink>

          {dropdownButton}
        </Flex>

        <Flex gap={7}>
          <Text className={styles.teamStats}>
            <LinkIcon className={styles.linkIcon} />
            {childTeamsCount} {t('team.team', { count: childTeamsCount })}
          </Text>
          <Text className={styles.bullet}>{`•`}</Text>
          <Text className={styles.teamStats}>
            {membersCount} {t('member', { count: membersCount })}
          </Text>
        </Flex>
      </Flex>

      <div className={styles.menu}>
        {renderRowEnd ? (
          renderRowEnd(orgUnit)
        ) : (
          <OrgUnitContextMenu orgUnit={orgUnit} size={'small'} />
        )}
      </div>
    </Flex>
  );
};

export default TeamsTreeListRowContent;
