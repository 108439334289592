import { createContext } from 'react';

import type { OrgUnitQuery } from './OrgUnitProvider.graphql.generated';

export type OrgUnitProviderContextValue = {
  orgUnit: Maybe<OrgUnitQuery['orgUnit']>;
};

export const OrgUnitProviderContext =
  createContext<OrgUnitProviderContextValue>({
    orgUnit: undefined,
  });
