import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

type MessageSource = 'local' | 'remote';

const Bubble = styled.div<{ source: MessageSource }>`
  color: ${(props) => props.theme.color.black};
  background-color: ${(props) =>
    props.source === 'local'
      ? props.theme.color.primaryVariant
      : props.theme.color.backgroundLight};
  border-radius: 12px;
  padding: 12px;
  white-space: pre-wrap;
`;

const Grow = styled.div`
  flex-grow: 1;
`;

type Props = PropsWithChildren<{
  source: MessageSource;
}>;

const MessageBubble = ({ source, children }: Props) => (
  <>
    {source === 'local' && <Grow />}
    <Bubble source={source}>{children}</Bubble>
  </>
);

export default MessageBubble;
