import styled from '@emotion/styled';
import type {
  ComponentType,
  SVGProps,
  PropsWithChildren,
  ReactNode,
} from 'react';

import Label from './Label';

const Container = styled.div<{ hasMargin: boolean }>(({ hasMargin }) => ({
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  '& + &': {
    marginTop: hasMargin ? '1rem' : undefined,
  },
}));

const Message = styled.span<{
  Icon?: InputBoxProps['icon'];
  hasError: boolean;
}>(({ hasError, theme, Icon }) => ({
  color: hasError ? theme.color.error : theme.legacyColor.colorDoveGray,
  display: 'block',
  fontSize: '.875rem',
  height: '1.625rem',
  overflow: 'visible',
  paddingTop: '.1rem',
  textOverflow: 'ellipsis',
  paddingLeft: Icon ? '1.5rem' : undefined,
}));

const ContentContainer = styled.div({
  alignItems: 'stretch',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '.25rem',
});

const Content = styled.div<{ Icon?: InputBoxProps['icon'] }>(({ Icon }) => ({
  flex: 1,
  paddingLeft: Icon ? '1.5rem' : undefined,
}));

const AfterContent = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
});

const BelowContent = styled.div`
  margin-top: 0.3rem;
`;

export type InputBoxProps = PropsWithChildren<{
  className?: string;
  contentClassName?: string;
  hasError?: boolean;
  hasMargin?: boolean;
  help?: string | ReactNode;
  htmlFor?: string;
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
  isLabelVisible?: boolean;
  isOptional?: boolean;
  label?: string;
  message?: string;
  name: string;
  renderAfter?: () => ReactNode;
  renderBelow?: () => ReactNode;
}>;

const InputBox = ({
  name,
  label,
  htmlFor,
  help,
  message,
  hasError = false,
  children,
  className,
  hasMargin = true,
  isOptional = false,
  icon: IconComponent,
  isLabelVisible = true,
  renderAfter,
  renderBelow,
  contentClassName,
}: InputBoxProps) => {
  const messageText = hasError ? message : help;

  return (
    <Container hasMargin={hasMargin} className={className}>
      <Label
        htmlFor={htmlFor || name}
        isLabelVisible={isLabelVisible}
        icon={IconComponent}
        isOptional={isOptional}
      >
        {label}
      </Label>
      <ContentContainer>
        <Content Icon={IconComponent} className={contentClassName}>
          {children}
        </Content>
        {renderAfter && <AfterContent>{renderAfter()}</AfterContent>}
      </ContentContainer>
      {renderBelow ? <BelowContent>{renderBelow()}</BelowContent> : null}
      <Message hasError={hasError} Icon={IconComponent}>
        {messageText}
      </Message>
    </Container>
  );
};

export default InputBox;
