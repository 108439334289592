import { useMemo } from 'react';

import type { TimeLine } from 'types.graphql.generated';
import Progress from 'shared/components/Progress';
import { calculateTimelineCompletionPercentage } from 'shared/components/TimelineProgress/TimelineProgress.utils';
import { ReactComponent as TimeStoperIcon } from 'shared/static/icons/icon-time-stoper.svg';
import { date } from 'shared/services/date.service';

type TimelineProgressProps = {
  className?: string;
  label: string;
  timeline: TimeLine;
};

const TimelineProgress = ({
  timeline,
  label,
  className,
}: TimelineProgressProps) => {
  const value = useMemo(
    () => calculateTimelineCompletionPercentage(timeline),
    [timeline],
  );

  const progressDate = useMemo(() => {
    if (timeline.completeDateTime) {
      return date.formatShort(timeline.completeDateTime);
    } else if (timeline?.startDate && timeline?.endDate) {
      return date.formatShort(timeline.endDate);
    }
  }, [timeline]);

  return progressDate ? (
    <Progress
      label={label}
      icon={TimeStoperIcon}
      value={value}
      className={className}
    >
      {progressDate}
    </Progress>
  ) : null;
};

export default TimelineProgress;
