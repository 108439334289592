import { useState } from 'react';

import Calendar from 'shared/components/Calendar';
import StrategyInsightsCalendarView from 'strategy/StrategyInsights/StrategyInsightsCalendarView';
import { date } from 'shared/services/date.service';

import { useInitiativesListObjectives } from '../InitiativesContentProvider/useInitiativesContent';

const InitiativesContentCalendar = () => {
  const [year, setYear] = useState(date.getYear(new Date()));

  const { allObjectives } = useInitiativesListObjectives();

  const initiatives = allObjectives?.flatMap(
    (objective) => objective.initiatives,
  );

  if (!initiatives) return null;

  return (
    <>
      <Calendar.Controls year={year} onYearChange={setYear} />
      <StrategyInsightsCalendarView year={year} initiatives={initiatives} />
    </>
  );
};

export default InitiativesContentCalendar;
