import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { OrgUnitForTeamImageFragmentDoc } from '../../../orgUnit/OrgUnit.graphql.generated';
import { UserProfileFragmentDoc, UserLimitedProfileFragmentDoc } from '../../../user/userProfile.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TeamMembersFromOrgQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TeamMembersFromOrgQuery = { __typename: 'Query', allUsers: { __typename: 'UserConnection', edges: Array<{ __typename: 'UserEdge', node: { __typename: 'User', authorities: Array<Types.Authority>, id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, orgUnitMemberships: { __typename: 'OrgUnitMembershipList', memberships: Array<{ __typename: 'OrgUnitMembership', roles: Array<Types.OrgUnitRole>, orgUnit: { __typename: 'OrgUnit', currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined } }> } } }> } };

export type TeamMembersFromOrgUnitQueryVariables = Types.Exact<{
  orgUnitId: Types.Scalars['ID'];
}>;


export type TeamMembersFromOrgUnitQuery = { __typename: 'Query', allUsers: { __typename: 'UserConnection', edges: Array<{ __typename: 'UserEdge', node: { __typename: 'User', authorities: Array<Types.Authority>, id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, orgUnitMemberships: { __typename: 'OrgUnitMembershipList', memberships: Array<{ __typename: 'OrgUnitMembership', roles: Array<Types.OrgUnitRole>, orgUnit: { __typename: 'OrgUnit', currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined } }> } } }> }, orgUnit: { __typename: 'OrgUnit', id: string, childOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string }> } };

export type TeamMembersMemberhipFragment = { __typename: 'OrgUnitMembership', roles: Array<Types.OrgUnitRole>, orgUnit: { __typename: 'OrgUnit', currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined } };

export type TeamMembersUserFragment = { __typename: 'User', authorities: Array<Types.Authority>, id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, orgUnitMemberships: { __typename: 'OrgUnitMembershipList', memberships: Array<{ __typename: 'OrgUnitMembership', roles: Array<Types.OrgUnitRole>, orgUnit: { __typename: 'OrgUnit', currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction>, id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined } }> } };

export type TeamMembersOrgUnitFragment = { __typename: 'OrgUnit', id: string, childOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string }> };

export const TeamMembersMemberhipFragmentDoc = gql`
    fragment teamMembersMemberhip on OrgUnitMembership {
  roles
  orgUnit {
    ...orgUnitForTeamImage
    currentUserAuthorizedActions
  }
}
    ${OrgUnitForTeamImageFragmentDoc}`;
export const TeamMembersUserFragmentDoc = gql`
    fragment teamMembersUser on User {
  ...userProfile
  authorities
  orgUnit {
    id
  }
  orgUnitMemberships {
    memberships {
      ...teamMembersMemberhip
    }
  }
}
    ${UserProfileFragmentDoc}
${TeamMembersMemberhipFragmentDoc}`;
export const TeamMembersOrgUnitFragmentDoc = gql`
    fragment teamMembersOrgUnit on OrgUnit {
  id
  childOrgUnitTree {
    id
  }
}
    `;
export const TeamMembersFromOrgDocument = gql`
    query TeamMembersFromOrg {
  allUsers {
    edges {
      node {
        ...teamMembersUser
      }
    }
  }
}
    ${TeamMembersUserFragmentDoc}`;

/**
 * __useTeamMembersFromOrgQuery__
 *
 * To run a query within a React component, call `useTeamMembersFromOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersFromOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersFromOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamMembersFromOrgQuery(baseOptions?: Apollo.QueryHookOptions<TeamMembersFromOrgQuery, TeamMembersFromOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamMembersFromOrgQuery, TeamMembersFromOrgQueryVariables>(TeamMembersFromOrgDocument, options);
      }
export function useTeamMembersFromOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamMembersFromOrgQuery, TeamMembersFromOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamMembersFromOrgQuery, TeamMembersFromOrgQueryVariables>(TeamMembersFromOrgDocument, options);
        }
export type TeamMembersFromOrgQueryHookResult = ReturnType<typeof useTeamMembersFromOrgQuery>;
export type TeamMembersFromOrgLazyQueryHookResult = ReturnType<typeof useTeamMembersFromOrgLazyQuery>;
export type TeamMembersFromOrgQueryResult = Apollo.QueryResult<TeamMembersFromOrgQuery, TeamMembersFromOrgQueryVariables>;
export const TeamMembersFromOrgUnitDocument = gql`
    query TeamMembersFromOrgUnit($orgUnitId: ID!) {
  allUsers {
    edges {
      node {
        ...teamMembersUser
      }
    }
  }
  orgUnit(id: $orgUnitId) {
    ...teamMembersOrgUnit
  }
}
    ${TeamMembersUserFragmentDoc}
${TeamMembersOrgUnitFragmentDoc}`;

/**
 * __useTeamMembersFromOrgUnitQuery__
 *
 * To run a query within a React component, call `useTeamMembersFromOrgUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersFromOrgUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersFromOrgUnitQuery({
 *   variables: {
 *      orgUnitId: // value for 'orgUnitId'
 *   },
 * });
 */
export function useTeamMembersFromOrgUnitQuery(baseOptions: Apollo.QueryHookOptions<TeamMembersFromOrgUnitQuery, TeamMembersFromOrgUnitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamMembersFromOrgUnitQuery, TeamMembersFromOrgUnitQueryVariables>(TeamMembersFromOrgUnitDocument, options);
      }
export function useTeamMembersFromOrgUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamMembersFromOrgUnitQuery, TeamMembersFromOrgUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamMembersFromOrgUnitQuery, TeamMembersFromOrgUnitQueryVariables>(TeamMembersFromOrgUnitDocument, options);
        }
export type TeamMembersFromOrgUnitQueryHookResult = ReturnType<typeof useTeamMembersFromOrgUnitQuery>;
export type TeamMembersFromOrgUnitLazyQueryHookResult = ReturnType<typeof useTeamMembersFromOrgUnitLazyQuery>;
export type TeamMembersFromOrgUnitQueryResult = Apollo.QueryResult<TeamMembersFromOrgUnitQuery, TeamMembersFromOrgUnitQueryVariables>;