import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import type { TreeListProps } from 'shared/components/TreeList';
import TreeList from 'shared/components/TreeList';
import Space from 'shared/components/Space';
import Result from 'shared/components/Result';
import { ReactComponent as EmptyIllustration } from 'shared/static/illustrations/illustration-empty-state.svg';
import { useActiveOrg } from 'org/ActiveOrgProvider';

import ObjectiveRelatedObjectivesRow from '../ObjectiveRelatedObjectivesRow';
import { useRelatedObjectives } from '../ObjectiveRelatedObjectivesProvider';
import type { ObjectiveRelatedObjectivesObjectiveFragment } from '../ObjectiveRelatedObjectivesProvider/ObjectiveRelatedObjectivesProvider.graphql.generated';

const ObjectiveRelatedObjectivesContent = () => {
  const { t } = useTranslation();

  const { objective, relatedObjectives } = useRelatedObjectives();

  const { activeOrg } = useActiveOrg();

  const renderRowContent = useCallback<
    TreeListProps<ObjectiveRelatedObjectivesObjectiveFragment>['renderRowContent']
  >(
    ({ item, dropdownButton }) => (
      <ObjectiveRelatedObjectivesRow
        objective={item}
        dropdownButton={dropdownButton}
        isHighlighted={objective?.id === item.id}
        orgKey={activeOrg.orgKey}
      />
    ),
    [objective, activeOrg.orgKey],
  );

  const expandedItemsIds = [
    objective?.parentObjective?.id,
    objective?.id,
  ].filter(Boolean);

  return relatedObjectives?.length ? (
    <TreeList<ObjectiveRelatedObjectivesObjectiveFragment>
      items={relatedObjectives}
      getChildItems={(item) => item.childObjectives}
      getParentItem={(item) => item.parentObjective}
      expandedItemsIds={expandedItemsIds}
      renderRowContent={renderRowContent}
    />
  ) : (
    <Space isCentered={true}>
      <Result
        description={t(
          'objective.relatedObjectives.noRelatedObjectivesDescription',
        )}
        illustration={EmptyIllustration}
        hasBackground={true}
      />
    </Space>
  );
};

export default ObjectiveRelatedObjectivesContent;
