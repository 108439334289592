import { Fragment } from 'react';

import { useInitiativesListObjectives } from '../InitiativesContentProvider/useInitiativesContent';
import InitiativesListSection from '../InitiativesContentListSection';

type InitiativesContentListProps = {
  areAllSectionsExpanded?: boolean;
  hasHeaders?: boolean;
};

const InitiativesContentList = ({
  hasHeaders,
  areAllSectionsExpanded,
}: InitiativesContentListProps) => {
  const { allObjectives } = useInitiativesListObjectives();

  return (
    <>
      {allObjectives?.map(({ id, name, initiatives }) => (
        <InitiativesListSection
          hasHeader={hasHeaders}
          title={name}
          initiatives={initiatives}
          objectiveId={id}
          key={id}
          isExpanded={areAllSectionsExpanded}
        />
      ))}
    </>
  );
};

export default InitiativesContentList;
