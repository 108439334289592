import { useDefinedContext } from 'shared/utils/context.utils';

import StrategyProfileAttributesProvider from './StrategyProfileAttributesProvider';
import { StrategyProfileAttributesContext } from './StrategyProfileAttributesProvider.context';

export const useStrategyProfileAttributes = () =>
  useDefinedContext(StrategyProfileAttributesContext, {
    hookName: useStrategyProfileAttributes.name,
    providerName: StrategyProfileAttributesProvider.name,
  });
