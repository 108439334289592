import { useCallback } from 'react';

import Space from 'shared/components/Space';
import TeamImage from 'team/TeamImage';
import Heading from 'shared/components/Heading';
import type { HeadingProps } from 'shared/components/Heading';
import type { OrgUnit } from 'types.graphql.generated';
import { useActiveOrg } from 'org/ActiveOrgProvider';

import type { TeamCardDetailsAction } from './TeamCardDetails.type';
import TeamCardDescriptions from '../TeamCardDescriptions';
import styles from './TeamCardDetails.module.scss';

export type TeamCardDetailsProps = {
  childTeamsCount: number;
  headingLevel?: HeadingProps['level'];
  membersCascadingCount: number;
  onAction?: (action: TeamCardDetailsAction) => void;
  orgUnit?: Pick<
    OrgUnit,
    'id' | 'name' | 'nameAbbreviated' | 'backgroundColorAsHex'
  >;
  strategyName?: string;
};

const TeamCardDetails = ({
  orgUnit,
  childTeamsCount,
  membersCascadingCount,
  headingLevel = 3,
  onAction,
  strategyName,
}: TeamCardDetailsProps) => {
  const { activeOrg } = useActiveOrg();

  const handleHeadingClick = useCallback(() => {
    onAction?.('name');
  }, [onAction]);

  const name = orgUnit
    ? orgUnit.name || orgUnit.nameAbbreviated || orgUnit.id
    : activeOrg.displayName || activeOrg.domainNames[0] || activeOrg.id;

  return (
    <Space>
      <TeamImage orgUnit={orgUnit} />
      <Space
        direction={'vertical'}
        size={'tiny'}
        className={styles.teamCardDetailsContent}
      >
        <Heading
          level={headingLevel}
          as={3}
          hasMargin={false}
          className={styles.teamCardDetailsName}
          ellipsis={true}
          onClick={handleHeadingClick}
        >
          {name}
          {strategyName ? ` / ${strategyName}` : ''}
        </Heading>
        <TeamCardDescriptions>
          <TeamCardDescriptions.Item
            variant={'childTeamsCount'}
            value={childTeamsCount}
            onAction={onAction}
          />
          <TeamCardDescriptions.Item
            variant={'membersCount'}
            value={membersCascadingCount}
            onAction={onAction}
          />
        </TeamCardDescriptions>
      </Space>
    </Space>
  );
};

export default TeamCardDetails;
