import { useContext } from 'react';

import { ToastContext } from 'shared/toast/Toast.context';

export const useToasts = () => {
  const toastsContext = useContext(ToastContext);
  if (!toastsContext) {
    throw new Error('useToasts should not be used outside ToastsProvider');
  }
  return toastsContext;
};
