import { ReactComponent as FlagIcon } from 'shared/static/icons/icon-flag.svg';
import CollapsibleList from 'shared/components/CollapsibleList';

import MetricRow from '../MetricRow';
import type { MetricsListObjectiveFragment } from '../MetricsListProvider/MetricsListProvider.graphql.generated';

type MetricsListSectionProps = {
  hasHeader?: boolean;
  isExpanded?: boolean;
  objective: MetricsListObjectiveFragment;
};

const MetricsListSection = ({
  hasHeader = true,
  objective,
  isExpanded,
}: MetricsListSectionProps) => {
  if (objective.metrics.length && objective.metrics.length > 0) {
    return (
      <CollapsibleList
        title={objective.name}
        titleIcon={FlagIcon}
        type={'metric'}
        hasHeader={hasHeader}
        expanded={isExpanded}
      >
        {objective.metrics.map((metric) => (
          <MetricRow metric={metric} objective={objective} key={metric.id} />
        ))}
      </CollapsibleList>
    );
  }

  return null;
};

export default MetricsListSection;
