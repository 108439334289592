import type { TableOptions } from 'react-table';
import { useTable, useSortBy } from 'react-table';
import cn from 'classnames';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

import TableViewHeader from './TableViewHeader';
import TableViewRow from './TableViewRow';
import styles from './TableView.module.scss';
import type { BaseTableViewItem } from './TableView.type';

export type TableViewProps<Item extends BaseTableViewItem> = Pick<
  TableOptions<Item>,
  'data' | 'columns'
> & {
  after?: ReactNode;
  before?: ReactNode;
  className?: string;
  hasBorder?: boolean;
  hasHeader?: boolean;
  sortable?: boolean;
};

/**
 * Allows user to quickly scan, sort, compare, and take action on large amounts of data.
 */
const TableView = <Item extends BaseTableViewItem>({
  data,
  columns,
  className,
  hasHeader = true,
  sortable = false,
  hasBorder = true,
  after: afterProp,
  before: beforeProp,
}: TableViewProps<Item>) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<Item>(
      {
        data,
        columns,
      },
      useSortBy,
    );

  const tableProps = getTableProps();
  const tableBodyProps = getTableBodyProps();

  const before = useMemo<ReactNode>(() => {
    if (beforeProp) {
      return (
        <div className={styles.tableViewBeforeContainer}>{beforeProp}</div>
      );
    }
    return null;
  }, [beforeProp]);

  const after = useMemo<ReactNode>(() => {
    if (afterProp) {
      return <div className={styles.tableViewAfterContainer}>{afterProp}</div>;
    }
    return null;
  }, [afterProp]);

  return (
    <div
      className={cn(styles.tableView, {
        [styles.tableViewWithBorder]: hasBorder,
        [styles.tableViewWithTopPadding]: !hasHeader && !before && hasBorder,
        [styles.tableViewWithBottomPadding]: !after && hasBorder,
      })}
    >
      {before}
      <table
        {...tableProps}
        className={cn(styles.tableViewTable, tableProps.className, className)}
      >
        {hasHeader && (
          <TableViewHeader headerGroups={headerGroups} sortable={sortable} />
        )}
        <tbody {...tableBodyProps}>
          {rows.map((row) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            return <TableViewRow row={row} {...rowProps} key={row.id} />;
          })}
        </tbody>
      </table>
      {after}
    </div>
  );
};

export default TableView;
