import { date } from 'shared/services/date.service';

import type { CalendarIntervalUnit } from './Calendar.type';

export const getColumnFormat = (intervalUnit: CalendarIntervalUnit) =>
  ({ month: 'LLL' }[intervalUnit]);

export const getIsCurrentMonth = (column: Date) =>
  date.isSameUnit(column, Date.now(), 'month');

export const getCalendarHeight = (childrenCount: Maybe<number>) => {
  const HEADER_HEIGHT = 60;
  const ROW_HEIGHT = 100;
  const MARGIN_BOTTOM = 40;

  const height = childrenCount ? childrenCount * ROW_HEIGHT : 0;

  return `calc(${height}px + ${HEADER_HEIGHT}px + ${MARGIN_BOTTOM}px)`;
};
