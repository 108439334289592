import compact from 'lodash/compact';

import type { ObjectiveDetailsObjective } from '../ObjectiveDetails.type';

export const getUsersToExclude = (
  objective: Pick<ObjectiveDetailsObjective, 'owner' | 'teamMembers'>,
) => {
  const ownerUser = objective.owner ? { id: objective.owner.id } : undefined;
  return compact([
    ownerUser,
    ...objective.teamMembers.map((teamMember) => {
      const userId = teamMember?.user?.id;
      if (userId) {
        return { id: userId };
      }
      return undefined;
    }),
  ]);
};
