import cn from 'classnames';
import type { CSSProperties } from 'react';
import { useMemo } from 'react';

import type { ItemColumn } from '../Roadmap.type';
import styles from './RoadmapItemBackground.module.scss';
import { getTilesLayout } from './RoadmapItemBackground.static';

type RoadmapItemBackgroundProps = {
  className?: string;
  column: ItemColumn;
  isFirst?: boolean;
  isLast?: boolean;
  width: CSSProperties['width'];
};

const RoadmapItemBackground = ({
  isFirst = false,
  isLast = false,
  column,
  width,
  className,
}: RoadmapItemBackgroundProps) => {
  const tilesLayout = useMemo(
    () =>
      getTilesLayout({
        column,
        isLast,
        isFirst,
      }),
    [column, isLast, isFirst],
  );

  return (
    <div
      style={{
        width,
      }}
      className={cn(styles.container, className)}
    >
      {tilesLayout}
    </div>
  );
};

export default RoadmapItemBackground;
