import type { ActionsLayoutType } from 'actions/ActionsLayout/ActionsLayoutProvider';
import { createRoutePath } from 'shared/utils/router';

export const objectiveRoutesPaths = {
  root: createRoutePath<{
    objectiveId: string;
    teamSlug: string;
  }>(`/teams/:teamSlug/objectives/:objectiveId`),
  details: createRoutePath<{
    objectiveId: string;
    teamSlug: string;
  }>(`/teams/:teamSlug/objectives/:objectiveId/details`),
  edit: createRoutePath<{
    objectiveId: string;
    teamSlug: string;
  }>(`/teams/:teamSlug/objectives/:objectiveId/edit`),
  actions: {
    root: createRoutePath<
      {
        objectiveId: string;
        teamSlug: string;
      },
      { view: ActionsLayoutType }
    >(`/teams/:teamSlug/objectives/:objectiveId/actions`),
    action: {
      root: createRoutePath<
        {
          actionId: string;
          objectiveId: string;
          teamSlug: string;
        },
        { view: ActionsLayoutType }
      >(`/teams/:teamSlug/objectives/:objectiveId/actions/:actionId`),
      edit: createRoutePath<
        {
          actionId: string;
          objectiveId: string;
          teamSlug: string;
        },
        { view: ActionsLayoutType }
      >(`/teams/:teamSlug/objectives/:objectiveId/actions/:actionId/edit`),
    },
  },
  relatedObjectives: createRoutePath<{
    objectiveId: string;
    teamSlug: string;
  }>(`related-objectives`),
  insights: {
    root: createRoutePath<{
      objectiveId: string;
      teamSlug: string;
    }>(`/teams/:teamSlug/objectives/:objectiveId/insights`),
    submit: createRoutePath<{
      objectiveId: string;
      teamSlug: string;
    }>(`/teams/:teamSlug/objectives/:objectiveId/insights/submit`),
  },
  metrics: {
    root: createRoutePath<{
      objectiveId: string;
      teamSlug: string;
    }>(`metrics`),
    metric: {
      root: createRoutePath<{
        metricId: string;
        objectiveId: string;
        teamSlug: string;
      }>(`/teams/:teamSlug/objectives/:objectiveId/metrics/:metricId`),
      attributes: createRoutePath<{
        metricId: string;
        objectiveId: string;
        teamSlug: string;
      }>(
        `/teams/:teamSlug/objectives/:objectiveId/metrics/:metricId/attributes`,
      ),
      edit: createRoutePath<{
        metricId: string;
        objectiveId: string;
        teamSlug: string;
      }>(`/teams/:teamSlug/objectives/:objectiveId/metrics/:metricId/edit`),
      insights: {
        root: createRoutePath<{
          metricId: string;
          objectiveId: string;
          teamSlug: string;
        }>(
          `/teams/:teamSlug/objectives/:objectiveId/metrics/:metricId/insights`,
        ),
        submit: createRoutePath<{
          metricId: string;
          objectiveId: string;
          teamSlug: string;
        }>(`/teams/:teamSlug/objectives/:objectiveId/metrics/:metricId/submit`),
      },
    },
  },
  initiatives: {
    root: createRoutePath<{
      objectiveId: string;
      teamSlug: string;
    }>(`initiatives`),
    initiative: {
      calendar: createRoutePath<{
        objectiveId: string;
        teamSlug: string;
      }>(`/teams/:teamSlug/objectives/:objectiveId/initiatives/calendar`),
      list: createRoutePath<{
        objectiveId: string;
        teamSlug: string;
      }>(`/teams/:teamSlug/objectives/:objectiveId/initiatives/list`),
      root: createRoutePath<{
        initiativeId: string;
        objectiveId: string;
        teamSlug: string;
      }>(`/teams/:teamSlug/objectives/:objectiveId/initiatives/:initiativeId`),
      attributes: createRoutePath<{
        initiativeId: string;
        objectiveId: string;
        teamSlug: string;
      }>(
        `/teams/:teamSlug/objectives/:objectiveId/initiatives/:initiativeId/attributes`,
      ),
      edit: createRoutePath<{
        initiativeId: string;
        objectiveId: string;
        teamSlug: string;
      }>(
        `/teams/:teamSlug/objectives/:objectiveId/initiatives/:initiativeId/edit`,
      ),
      insights: {
        root: createRoutePath<{
          initiativeId: string;
          objectiveId: string;
          teamSlug: string;
        }>(
          `/teams/:teamSlug/objectives/:objectiveId/initiatives/:initiativeId/insights`,
        ),
        submit: createRoutePath<{
          initiativeId: string;
          objectiveId: string;
          teamSlug: string;
        }>(
          `/teams/:teamSlug/objectives/:objectiveId/initiatives/:initiativeId/submit`,
        ),
      },
    },
  },
};
