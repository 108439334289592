import type { Metric, Objective } from 'types.graphql.generated';
import { testStatusIndicatorAgainstId } from 'shared/status/StatusMultiSelect';
import { testStrategyItemAgainstInterval } from 'strategy/strategy.utils';
import { testPriorityAgainstId } from 'shared/priority/PriorityMultiSelect';
import type { CalendarCheck } from 'shared/components/CalendarControls/CalendarControls.context';

import type { StrategyMetricsObjective } from './StrategyMetricsProvider.type';
import type {
  StrategyMetricsStatusFilter,
  StrategyMetricsUser,
  StrategyMetricsUserFilter,
  StrategyMetricsEndDateFilter,
  StrategyMetricsStartDateFilter,
  StrategyMetricsPriorityFilter,
} from '../StrategyMetrics.type';

export const getObjectivesWithMetrics = <
  TObjective extends Maybe<
    Pick<Objective, 'id'> & { metrics: Pick<Metric, 'id'>[] }
  >,
>(
  objectives: Maybe<TObjective[]>,
) => objectives?.filter((objective) => !!objective?.metrics?.length);

export const filterObjectivesByStatusIndicator = (
  objectives: StrategyMetricsObjective[],
  filter: StrategyMetricsStatusFilter,
) =>
  objectives.map((objective) => ({
    ...objective,
    metrics: objective.metrics.filter((metric) =>
      filter.some((statusMultiSelectItemId) =>
        testStatusIndicatorAgainstId(
          statusMultiSelectItemId,
          metric.currentMetricStatus?.statusIndicator,
        ),
      ),
    ),
  }));

export const filterObjectiveMetricsByPriority = (
  objectives: StrategyMetricsObjective[],
  filter: StrategyMetricsPriorityFilter,
) =>
  objectives.map((objective) => ({
    ...objective,
    metrics: objective.metrics.filter((metric) =>
      filter.some((prioritiesMultiSelectItemId) =>
        testPriorityAgainstId(prioritiesMultiSelectItemId, metric.priority),
      ),
    ),
  }));

export const filterObjectivesByUser = (
  objectives: StrategyMetricsObjective[],
  filter: StrategyMetricsUserFilter,
) => {
  if (!filter.length) {
    return objectives;
  }
  return objectives
    .map((objective) => ({
      ...objective,
      metrics: objective.metrics.filter((metric) =>
        filter.some((value) => value === metric.owner?.id),
      ),
    }))
    .filter((objective) => objective.metrics.length);
};

export const filterObjectivesByDates = (
  objectives: StrategyMetricsObjective[],
  start: StrategyMetricsStartDateFilter,
  end: StrategyMetricsEndDateFilter,
  check?: CalendarCheck,
) => {
  if (!start && !end) {
    return objectives;
  }

  return objectives
    .map((objective) => ({
      ...objective,
      metrics: objective.metrics.filter((metric) =>
        testStrategyItemAgainstInterval(metric, { start, end }, check),
      ),
    }))
    .filter((objective) => objective.metrics.length);
};

export const getObjectivesOwners = (
  objectives: StrategyMetricsObjective[],
): StrategyMetricsUser[] =>
  Object.values(
    objectives.reduce<Record<StrategyMetricsUser['id'], StrategyMetricsUser>>(
      (accumulator, objective) => {
        objective.metrics.forEach((metric) => {
          const user = metric.owner;
          if (user && !accumulator[user.id]) {
            accumulator[user.id] = user;
          }
        });
        return accumulator;
      },
      {},
    ),
  );
