import { css } from '@emotion/react';
import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { ReactComponent as FilterIcon } from 'shared/static/icons/icon-filter.svg';
import useDialogState from 'shared/hooks/useDialogState';

import Button from '../Button';
import SideModal from '../SideModal';
import Flex from '../Flex';
import ClearAllButton from '../ClearAllButton';

const SelectionCount = styled.div`
  top: 5.5px;
  right: 5.5px;
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.white};
  font-size: 12px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
`;

type Props = PropsWithChildren<{
  clearFilters: () => void;
  selectionCount: number;
}>;

const FiltersButton = ({ children, selectionCount, clearFilters }: Props) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDialogState();

  return (
    <>
      <div css={css({ position: 'relative' })}>
        <Button
          icon={FilterIcon}
          variant={'icon'}
          css={css({ width: 42, height: 42, boxSizing: 'border-box' })}
          onClick={onOpen}
        />

        {selectionCount > 0 && (
          <SelectionCount>{selectionCount}</SelectionCount>
        )}
      </div>

      <SideModal
        headerTitle={t('filters.title')}
        headerControls={
          selectionCount > 0 && <ClearAllButton onClear={clearFilters} />
        }
        isOpen={isOpen}
        onClose={onClose}
      >
        <Flex direction={'column'} gap={'1rem'} css={css({ margin: '1rem' })}>
          {children}
        </Flex>
      </SideModal>
    </>
  );
};

export default FiltersButton;
