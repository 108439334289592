import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { range } from 'lodash';

import { ReactComponent as AiWandIcon } from 'shared/static/icons/icon-ai-wand.svg';
import { ReactComponent as ChevronUpIcon } from 'shared/static/icons/icon-chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from 'shared/static/icons/icon-chevron-down.svg';
import Button from 'shared/components/Button';
import Flex from 'shared/components/Flex';
import type {
  AiSuggestionElement,
  AiSuggestionElementInput,
  AiSuggestionResponseInput,
} from 'types.graphql.generated';
import useAiAssistentActive from 'ai/useAiAssistentActive';
import AiLoadingButton from 'ai/AiLoadingButton';

import ResultsPage from './ResultsPage';
import Pagination from './Pagination';

const Container = styled.div`
  background-color: ${(props) => props.theme.color.backgroundLight};
`;

const Bottom = styled.div`
  padding: 0 12px 12px 12px;
`;

const ControlLink = styled.span<{ color: string; isDisabled?: boolean }>`
  user-select: none;
  color: ${(props) =>
    props.isDisabled ? props.theme.color.neutral2 : props.color};
  cursor: pointer;
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
`;

const ChevronButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;
  }
`;

const maxPageCount = 5;

export type Suggestion = Pick<AiSuggestionElement, 'fields'>;

type Props = {
  elements: AiSuggestionElementInput[];
  hints?: string[];
  isDisabled: boolean;
  onSuggestionSelected: (suggestion: Suggestion) => void;
  response: AiSuggestionResponseInput;
};

const MultiAiSuggestions = ({
  elements,
  response,
  onSuggestionSelected,
  isDisabled,
}: Props) => {
  const { isAiAssistantActive } = useAiAssistentActive();
  const { t } = useTranslation();
  const theme = useTheme();
  const [pageCount, setPageCount] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  if (!isAiAssistantActive) return null;

  const isTriggered = pageCount > 0;
  const lastPageIndex = pageCount - 1;

  const handleLoadMore = () => {
    const newPageCount = pageCount + 1;
    setPageCount(newPageCount);
    setCurrentPageIndex(newPageCount - 1);
  };

  const handleReset = () => {
    setPageCount(0);
  };

  const toggleExpanded = () => isTriggered && setIsExpanded(!isExpanded);

  const pages = range(0, pageCount);

  return (
    <Container>
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        css={css({
          height: '66px',
          padding: '0 20px',
          cursor: isTriggered ? 'pointer' : 'default',
        })}
        onClick={toggleExpanded}
      >
        <Flex gap={8}>
          <AiWandIcon />
          {isTriggered
            ? t('ai.askSophie.title')
            : t(
                'initiative.initiativeForm.milestones.aiSuggestions.request.title',
              )}
        </Flex>

        {isTriggered ? (
          <ChevronButton>
            {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </ChevronButton>
        ) : (
          <Button
            variant={'outlined'}
            css={css({ color: theme.legacyColor.colorAiPurple })}
            onClick={handleLoadMore}
            disabled={isDisabled}
          >
            {t(
              'initiative.initiativeForm.milestones.aiSuggestions.request.button.start',
            )}
          </Button>
        )}
      </Flex>

      {isTriggered && (
        <Bottom css={css({ display: isExpanded ? 'block' : 'none' })}>
          {pages.map((pageIndex) => (
            <div
              key={pageIndex}
              css={css({
                display: pageIndex === currentPageIndex ? 'block' : 'none',
              })}
            >
              <ResultsPage
                elements={elements}
                response={response}
                temperature={0.2 + pageIndex * 0.3}
                onSuggestionSelected={onSuggestionSelected}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />

              {isLoading && pageIndex === lastPageIndex && <AiLoadingButton />}

              <Flex
                direction={'column'}
                gap={12}
                css={css({ padding: '12px 0 8px 10px' })}
              >
                <Flex gap={20}>
                  <ControlLink
                    onClick={handleLoadMore}
                    color={theme.legacyColor.colorAiPurple}
                    isDisabled={
                      isDisabled || isLoading || pageCount == maxPageCount
                    }
                  >
                    {t(
                      'initiative.initiativeForm.milestones.aiSuggestions.request.button.more',
                    )}
                  </ControlLink>
                  <ControlLink onClick={handleReset} color={theme.color.error}>
                    {t(
                      'initiative.initiativeForm.milestones.aiSuggestions.request.button.reset',
                    )}
                  </ControlLink>
                  <Flex grow={1} />
                  <Pagination
                    currentPageIndex={pageIndex}
                    setCurrentPageIndex={setCurrentPageIndex}
                    pageCount={pageCount}
                  />
                </Flex>
              </Flex>
            </div>
          ))}
        </Bottom>
      )}
    </Container>
  );
};

export default MultiAiSuggestions;
