import type * as Types from '../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserLimitedProfileFragmentDoc } from '../user/userProfile.graphql.generated';
export type ActionPartsFragment = { __typename: 'Action', id: string, name?: string | undefined, description?: string | undefined, isCurrentUserOwner: boolean, objective?: { __typename: 'Objective', id: string } | undefined, priority?: { __typename: 'Priority', number: number, label: string } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, currentStatus?: { __typename: 'ActionStatus', id: string, completionRate?: number | undefined, complete: boolean, statusDateTime: any, statusIndicator: StatusIndicatorClass, auditRecord: { __typename: 'AuditRecord', updateDateTime?: any | undefined, createDateTime?: any | undefined, updateBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined, createBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined } } | undefined, auditRecord: { __typename: 'AuditRecord', updateDateTime?: any | undefined, createDateTime?: any | undefined, updateBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined, createBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined }, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined } };

export type ActionsObjectiveFragment = { __typename: 'Objective', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined }, actions: Array<{ __typename: 'Action', id: string, name?: string | undefined, description?: string | undefined, isCurrentUserOwner: boolean, objective?: { __typename: 'Objective', id: string } | undefined, priority?: { __typename: 'Priority', number: number, label: string } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, currentStatus?: { __typename: 'ActionStatus', id: string, completionRate?: number | undefined, complete: boolean, statusDateTime: any, statusIndicator: StatusIndicatorClass, auditRecord: { __typename: 'AuditRecord', updateDateTime?: any | undefined, createDateTime?: any | undefined, updateBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined, createBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined } } | undefined, auditRecord: { __typename: 'AuditRecord', updateDateTime?: any | undefined, createDateTime?: any | undefined, updateBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined, createBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined }, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined } }> };

export type ActionsAuditRecordFragment = { __typename: 'AuditRecord', updateDateTime?: any | undefined, createDateTime?: any | undefined, updateBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined, createBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined };

export const ActionsAuditRecordFragmentDoc = gql`
    fragment actionsAuditRecord on AuditRecord {
  updateDateTime
  createDateTime
  updateBy {
    email
    displayName
    photoUrl
  }
  createBy {
    email
    displayName
    photoUrl
  }
}
    `;
export const ActionPartsFragmentDoc = gql`
    fragment actionParts on Action {
  id
  name
  description
  isCurrentUserOwner
  objective {
    id
  }
  priority {
    number
    label
  }
  orgUnit {
    id
    name
  }
  owner {
    ...userLimitedProfile
  }
  currentStatus {
    id
    completionRate
    complete
    statusDateTime
    auditRecord {
      ...actionsAuditRecord
    }
    statusIndicator
  }
  auditRecord {
    ...actionsAuditRecord
  }
  timeLine {
    startDate
    endDate
  }
}
    ${UserLimitedProfileFragmentDoc}
${ActionsAuditRecordFragmentDoc}`;
export const ActionsObjectiveFragmentDoc = gql`
    fragment actionsObjective on Objective {
  id
  name
  currentUserAuthorizedActions
  timeLine {
    startDate
    endDate
  }
  actions {
    ...actionParts
  }
}
    ${ActionPartsFragmentDoc}`;