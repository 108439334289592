import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename: 'Query', currentUser: { __typename: 'User', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, authorities: Array<Types.Authority>, isActive: boolean, isInActiveOrg: boolean, accessControl: { __typename: 'AccessControl', orgUnitAccessList: Array<{ __typename: 'OrgUnitAccess', orgUnit: { __typename: 'OrgUnit', id: string, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction> } }> }, org?: { __typename: 'Org', id: string, orgKey: string } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined, childOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string, name?: string | undefined }>, parentOrgUnitTree: Array<{ __typename: 'OrgUnit', id: string, name?: string | undefined }> } | undefined, notifications: { __typename: 'Notifications', statusRequests: Array<{ __typename: 'StatusRequest', id: string, isComplete: boolean }> } } };


export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    id
    email
    displayName
    photoUrl
    authorities
    isActive
    accessControl {
      orgUnitAccessList {
        orgUnit {
          id
          currentUserAuthorizedActions
        }
      }
    }
    org {
      id
      orgKey
    }
    orgUnit {
      id
      name
      childOrgUnitTree {
        id
        name
      }
      parentOrgUnitTree {
        id
        name
      }
    }
    notifications {
      statusRequests {
        id
        isComplete
      }
    }
    isInActiveOrg
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;