import type { MetricStatus } from 'types.graphql.generated';

import type {
  MetricsListMetric,
  MetricsListObjective,
} from './MetricsListProvider.type';

export const getObjectiveMetrics = (
  objectives: Maybe<MetricsListObjective[]>,
) =>
  objectives?.reduce<MetricsListMetric[]>(
    (acc, objective) =>
      objective?.metrics ? [...acc, ...objective.metrics] : acc,
    [],
  );

export const getMetricsActive = <
  TMetric extends { currentMetricStatus?: Pick<MetricStatus, 'complete'> },
>(
  metrics: Maybe<TMetric[]>,
) =>
  metrics?.filter(({ currentMetricStatus }) => !currentMetricStatus?.complete);

export const getObjectivesWithMetricsActive = (
  objectives: Maybe<MetricsListObjective[]>,
) =>
  objectives?.reduce<MetricsListObjective[]>((acc, cur) => {
    const metrics = getMetricsActive<MetricsListMetric>(cur?.metrics) ?? [];

    const objective = { ...cur, metrics };

    return metrics?.length ? [...acc, objective] : acc;
  }, []);
