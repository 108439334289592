import type { ListState } from 'react-stately';
import { Trans, useTranslation } from 'react-i18next';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

import Space from 'shared/components/Space';
import Text from 'shared/components/Text';

import type {
  PriorityMultiSelectItem,
  PriorityMultiSelectItemId,
} from '../PriorityMultiSelect.type';

type PriorityMultiSelectLabelProps = {
  state: ListState<PriorityMultiSelectItem>;
};

const PriorityMultiSelectLabel = ({ state }: PriorityMultiSelectLabelProps) => {
  const { t } = useTranslation();

  const content = useMemo<ReactNode>(() => {
    const selectedKeys = [
      ...state.selectionManager.selectedKeys,
    ] as PriorityMultiSelectItemId[];
    if (selectedKeys.length === 1 && selectedKeys.at(0) === 'ALL') {
      return (
        <Trans
          i18nKey={'priority.priorityMultiSelect.labelWithValue'}
          values={{ value: t('priority.allPriority') }}
        >
          <Text variant={'emphasis'} />
        </Trans>
      );
    }
    if (selectedKeys.length === 0) {
      return (
        <Trans
          i18nKey={'priority.priorityMultiSelect.labelWithValue'}
          values={{ value: t('none') }}
        >
          <Text variant={'emphasis'} />
        </Trans>
      );
    }
    return (
      <Trans
        i18nKey={'priority.priorityMultiSelect.labelWithPriorityCount'}
        values={{ priorityCount: selectedKeys.length }}
      >
        <Text variant={'emphasis'} />
      </Trans>
    );
  }, [state, t]);

  return (
    <Space size={'small'}>
      <Text>{content}</Text>
    </Space>
  );
};

export default PriorityMultiSelectLabel;
