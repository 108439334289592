import { useTranslation } from 'react-i18next';

import Anchor from 'shared/components/Anchor/Anchor';

import styles from './LayoutFooter.module.scss';

const supportEmailAddress = 'support@bluejam.io';

const LayoutFooter = () => {
  const { t } = useTranslation();

  const contactUs = t('layout.footer.contactUs');
  const emailSubject = t('layout.footer.emailSubject');

  const href = `mailto:${supportEmailAddress}?subject=${emailSubject}`;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {contactUs}{' '}
        <Anchor href={href} variant={'secondary'}>
          {supportEmailAddress}
        </Anchor>
      </div>
    </div>
  );
};

export default LayoutFooter;
