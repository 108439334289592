import Layout from './TeamInsightsLayout';
import Overview from './TeamInsightsOverview';
import NavigateToTeamInsightsOverview from './NavigateToTeamInsightsOverview';

const TeamInsights = {
  Layout,
  Overview,
  NavigateToTeamInsightsOverview,
};

export default TeamInsights;
