import { useTranslation } from 'react-i18next';

import Tag from 'shared/components/Tag';
import type { TagVariant } from 'shared/components/Tag/Tag.type';
import type { CampaignStage } from 'types.graphql.generated';

type Props = {
  stage: CampaignStage;
};

const CampaignStageTag = ({ stage }: Props) => {
  const { t } = useTranslation();

  const variant = {
    ACTIVE: 'primary',
    INACTIVE: 'secondary',
    CLOSED: 'success',
    ABORTED: 'danger',
  }[stage] as TagVariant;

  const label = t(`campaign.stage.${stage}`);

  return <Tag variant={variant}>{label}</Tag>;
};

export default CampaignStageTag;
