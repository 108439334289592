import type { Animation } from 'shared/types/animation.type';

export const directChildItemsContainerAnimation: Animation = {
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: {
      opacity: 1,
      height: 'auto',
      transition: {
        height: {
          duration: 0.2,
        },
        opacity: {
          delay: 0.2,
          duration: 0.2,
        },
      },
    },
    collapsed: {
      opacity: 0,
      height: 0,
      transition: {
        height: {
          delay: 0.2,
          duration: 0.2,
        },
        opacity: {
          duration: 0.2,
        },
      },
    },
  },
};
