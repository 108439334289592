import { useDefinedContext } from 'shared/utils/context.utils';

import { TeamTeamHierarchyProviderContext } from './TeamTeamHierarchyProvider.context';
import TeamTeamHierarchyProvider from './TeamTeamHierarchyProvider';

export const useTeamTeamHierarchy = () =>
  useDefinedContext(TeamTeamHierarchyProviderContext, {
    providerName: TeamTeamHierarchyProvider.name,
    hookName: useTeamTeamHierarchy.name,
  });
