import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Descriptions from 'shared/components/Descriptions';
import { date } from 'shared/services/date.service';

import styles from './DesciptionDates.module.scss';
type DescriptionDatesProps = {
  className?: string;
  completeDateTime?: Date | null;
  endDate?: Date | null;
  startDate?: Date | null;
};

const DescriptionDates = ({
  startDate,
  endDate,
  completeDateTime,
  className,
}: DescriptionDatesProps) => {
  const { t } = useTranslation();

  return (
    <Descriptions className={cn(styles.container, className)}>
      <Descriptions.Item label={t('timeline.startDate')}>
        {startDate ? date.formatShort(startDate) : t('timeline.noDate')}
      </Descriptions.Item>
      <Descriptions.Item label={t('timeline.endDate')}>
        {endDate ? date.formatShort(endDate) : t('timeline.noDate')}
      </Descriptions.Item>
      {completeDateTime ? (
        <Descriptions.Item label={t('timeline.completeDateTime')}>
          {date.formatShort(completeDateTime)}
        </Descriptions.Item>
      ) : null}
    </Descriptions>
  );
};

export default DescriptionDates;
