import type { PropsWithChildren } from 'react';
import { useLayoutEffect, useRef } from 'react';

export type AutoFocusProps = PropsWithChildren<{
  className?: string;
  inputName?: string;
  isEnabled?: boolean;
  preventScroll?: boolean;
}>;

const AutoFocus = ({
  preventScroll = true,
  className,
  children,
  inputName,
  isEnabled = true,
}: AutoFocusProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (isEnabled) {
      // use setTimout to focus element on the next cycle and avoid conflicts with FocusTrap component
      setTimeout(() => {
        const focusElementSelector = inputName
          ? `input[name="${inputName}"]`
          : 'input[type="text"]';

        const focusElement = ref.current?.querySelector(
          focusElementSelector,
        ) as HTMLInputElement | undefined;

        focusElement?.focus({ preventScroll });
      }, 250);
    }
  }, [inputName, preventScroll, isEnabled]);

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
};

export default AutoFocus;
