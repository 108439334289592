import cn from 'classnames';
import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useToggle } from 'react-use';
import { css, useTheme } from '@emotion/react';

import { ReactComponent as ChevronDownIcon } from 'shared/static/icons/icon-chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'shared/static/icons/icon-chevron-up.svg';
import IconButton from 'shared/components/IconButton';
import StatusMark from 'shared/status/StatusMark';
import type { User } from 'types.graphql.generated';
import UserAvatar from 'user/UserAvatar/UserAvatar';
import type { StatusIndicator } from 'shared/status/StatusIndicator';
import { ReactComponent as ChevronRightIcon } from 'shared/static/icons/icon-chevron-right.svg';
import { date } from 'shared/services/date.service';
import type {
  ContextMenuItem,
  ContextMenuOnActionFn,
} from 'shared/components/ContextMenu';
import ContextMenu from 'shared/components/ContextMenu';
import Tooltip from 'shared/components/Tooltip';
import Flex from 'shared/components/Flex';

import { getItemIcon } from './StrategyOperationalItemRow.utils';
import StrategyOperationalItemRowLink from './StrategyOperationalItemRowLink';
import type {
  LinkedItem,
  StrategyOperationalItemRowTypename,
} from './StrategyOperationalItemRow.type';
import styles from './StrategyOperationalItemRow.module.scss';

export type StrategyOperationalItemRowProps = PropsWithChildren<{
  className?: string;
  contextMenu?: {
    disabledKeys: string[];
    items: ContextMenuItem<string>[];
    onAction: ContextMenuOnActionFn<ContextMenuItem<string>['id']>;
  };
  date: any;
  dateTooltip: string;
  expandable?: boolean;
  isDisabled?: boolean;
  statusIndicator: Maybe<StatusIndicator>;
  subTitle?: LinkedItem;
  title: LinkedItem;
  typename: StrategyOperationalItemRowTypename;
  user: Maybe<
    Pick<User, 'id' | 'displayName' | 'photoUrl' | 'email' | 'isActive'>
  >;
}>;

const StrategyOperationalItemRow = ({
  statusIndicator,
  typename,
  title,
  date: dateTime,
  dateTooltip,
  subTitle,
  user,
  className,
  children,
  contextMenu: contextMenuProp,
  isDisabled,
  expandable = true,
}: StrategyOperationalItemRowProps) => {
  const theme = useTheme();
  const [isExpanded, toggleIsExpanded] = useToggle(false);

  const Icon = useMemo(() => getItemIcon(typename), [typename]);

  const contextMenuVisible =
    contextMenuProp &&
    contextMenuProp.disabledKeys.length < contextMenuProp.items.length;

  return (
    <>
      <li
        className={cn(
          styles.row,
          { [styles.rowClickable]: !!children },
          className,
        )}
        onClick={toggleIsExpanded}
        css={
          isDisabled &&
          css`
            opacity: 0.5;

            &:hover {
              opacity: 1;
            }
          `
        }
      >
        <Icon className={styles.icon} />
        <StatusMark
          statusIndicator={statusIndicator}
          className={styles.statusMark}
        />
        <Flex grow={1}>
          <StrategyOperationalItemRowLink linkedItem={title} />
          {subTitle?.text ? (
            <span className={styles.subTitle}>
              <ChevronRightIcon className={styles.chevronIcon} />
              <StrategyOperationalItemRowLink
                linkedItem={subTitle}
                color={theme.color.neutral2}
              />
            </span>
          ) : null}
        </Flex>
        <Flex alignItems={'center'} gap={12}>
          {dateTime ? (
            <Tooltip content={dateTooltip}>
              <span className={styles.date}>{date.formatShort(dateTime)}</span>
            </Tooltip>
          ) : null}

          <UserAvatar user={user} containerClassName={styles.avatarContainer} />

          {contextMenuProp && (
            <div css={css({ width: 18 })}>
              {contextMenuVisible && (
                <ContextMenu
                  items={contextMenuProp.items}
                  disabledKeys={contextMenuProp.disabledKeys}
                  onAction={contextMenuProp.onAction}
                  triggerClassName={styles.trigger}
                />
              )}
            </div>
          )}

          {expandable && (
            <div css={css({ width: 14 })}>
              {children && (
                <IconButton
                  size={'small'}
                  icon={isExpanded ? ChevronUpIcon : ChevronDownIcon}
                  className={styles.toggleIcon}
                />
              )}
            </div>
          )}
        </Flex>
      </li>

      {isExpanded && children}
    </>
  );
};

export default StrategyOperationalItemRow;
