import OptionalLink from 'shared/components/OptionalLink';
import Flex from 'shared/components/Flex';

import type { LinkedItem } from '../StrategyOperationalItemRow.type';

type StrategyOperationalItemRowLinkProps = {
  color?: string;
  linkedItem: LinkedItem;
};

const StrategyOperationalItemRowLink = ({
  linkedItem,
  color,
}: StrategyOperationalItemRowLinkProps) => {
  return (
    <Flex gap={8}>
      <OptionalLink
        to={linkedItem.linkTo}
        color={color}
        isActive={!linkedItem.isLocked}
      >
        {linkedItem.text}
      </OptionalLink>
    </Flex>
  );
};

export default StrategyOperationalItemRowLink;
