import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import type {
  CommentsSection,
  SidesheetsContext,
  SidesheetsWizardStep,
} from '../Sidesheets.type';
import { getOrgTermAlsoKnownAsI18nKey } from './SideSheetsTipsAndTricks.utils';
import type { TipsAndTricksSegment } from '../SidesheetsTipsAndTricksContent';
import SidesheetsTipsAndTricksContent from '../SidesheetsTipsAndTricksContent';

type SidesheetsTipsAndTricksProps = {
  commentsSection: CommentsSection;
  context: SidesheetsContext;
  wizardStep?: SidesheetsWizardStep;
};

const SidesheetsTipsAndTricks = ({
  context,
  commentsSection,
  wizardStep,
}: SidesheetsTipsAndTricksProps) => {
  const { t } = useTranslation();

  const i18nKey = useMemo(() => {
    switch (context) {
      case 'report':
      case 'strategy':
        return `sidesheets.context.${context}.tipsAndTricks.${wizardStep}.content`;
      case 'objective':
      case 'metric':
      case 'initiative':
        return `sidesheets.context.${context}.tipsAndTricks.content`;
      case 'status':
        return `sidesheets.context.${context}.tipsAndTricks.${commentsSection}.content`;
    }
  }, [commentsSection, context, wizardStep]);

  const tipsAndTricksSegments: Array<TipsAndTricksSegment> = t(i18nKey, {
    returnObjects: true,
  });

  const orgTermAlsoKnownAsI18nKey = getOrgTermAlsoKnownAsI18nKey({
    context,
    wizardStep,
  });

  return (
    <SidesheetsTipsAndTricksContent
      orgTermAlsoKnownAsI18nKey={orgTermAlsoKnownAsI18nKey}
      segments={tipsAndTricksSegments}
    />
  );
};

export default SidesheetsTipsAndTricks;
