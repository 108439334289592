import type { ReactNode } from 'react';
import cn from 'classnames';
import styled from '@emotion/styled';

import Card from 'shared/components/Card';
import Space from 'shared/components/Space';

import styles from './FormWithInfoCardLayout.module.scss';

const SidePanel = styled.div`
  max-width: 24rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export type FormWithInfoCardLayoutProps = {
  className?: string;
  formCardClassName?: string;
  formCardContentClassName?: string;
  renderAfterInfoCard?: ReactNode;
  renderForm: () => ReactNode;
  renderInfoCard?: () => ReactNode;
};

const FormWithInfoCardLayout = ({
  className,
  renderForm,
  renderInfoCard,
  renderAfterInfoCard,
  formCardContentClassName,
  formCardClassName,
}: FormWithInfoCardLayoutProps) => {
  return (
    <Space
      size={'big'}
      direction={'horizontal'}
      className={cn(styles.container, className)}
    >
      <Card
        hasBackground={true}
        className={cn(styles.formCard, formCardClassName)}
        contentClassName={formCardContentClassName}
      >
        {renderForm()}
      </Card>
      <SidePanel>
        {renderInfoCard?.()}
        {renderAfterInfoCard}
      </SidePanel>
    </Space>
  );
};

export default FormWithInfoCardLayout;
