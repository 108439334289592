import { createContext } from 'react';

import type {
  StrategyInitiativesStatusFilter,
  StrategyInitiativesUser,
  StrategyInitiativesEndDateFilter,
  StrategyInitiativesStartDateFilter,
  StrategyInitiativesUserFilter,
  StrategyInitiativesPriorityFilter,
} from '../StrategyInitiatives.type';
import type { StrategyInitiativesObjectiveFragment } from './StrategyInitiativesProvider.graphql.generated';
import type { StrategyInitiativesObjectivesByTheme } from './StrategyInitiativesProvider.type';

export type StrategyObjectivesInitiativesContextValue = {
  filters: {
    clearFilters: () => void;
    endDateFilter: StrategyInitiativesEndDateFilter;
    priorityFilter: StrategyInitiativesPriorityFilter;
    setEndDateFilter: (value: StrategyInitiativesEndDateFilter) => void;
    setPriorityFilter: (value: StrategyInitiativesPriorityFilter) => void;
    setStartDateFilter: (value: StrategyInitiativesStartDateFilter) => void;
    setStatusIndicatorFilter: (value: StrategyInitiativesStatusFilter) => void;
    setUserFilter: (value: StrategyInitiativesUserFilter) => void;
    startDateFilter: StrategyInitiativesStartDateFilter;
    statusIndicatorFilter: StrategyInitiativesStatusFilter;
    userFilter: StrategyInitiativesUserFilter;
  };
  isStrategyInitiativesLoading: boolean;
  objectives: Maybe<StrategyInitiativesObjectiveFragment[]>;
  objectivesByTheme: StrategyInitiativesObjectivesByTheme[];
  users: StrategyInitiativesUser[];
};

export const StrategyObjectivesInitiativesContext = createContext<
  StrategyObjectivesInitiativesContextValue | undefined
>(undefined);
