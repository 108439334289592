import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useHandleApolloError from 'shared/errors/useHandleApolloError';

import type { ActionContextValue } from './ActionProvider.context';
import { ActionContext } from './ActionProvider.context';
import { useActionQuery } from './ActionProvider.graphql.generated';

type ActionsProviderProps = PropsWithChildren<object>;

const ActionProvider = ({ children }: ActionsProviderProps) => {
  const { actionId } = useParams<{ actionId: string }>();

  const handleApolloError = useHandleApolloError();

  const { data } = useActionQuery({
    variables: { actionId: actionId! },
    onError: handleApolloError,
    skip: !actionId,
  });

  const contextValue = useMemo<ActionContextValue | undefined>(() => {
    if (data?.action) {
      return {
        action: data?.action,
      };
    }
  }, [data?.action]);

  if (!contextValue) {
    return null;
  }

  return (
    <ActionContext.Provider value={contextValue}>
      {children}
    </ActionContext.Provider>
  );
};

export default ActionProvider;
