import { contributionRoutesPaths } from 'contribution/contribution.routing.paths';
import { i18n } from 'shared/services/i18n.service';
import type { TeamAdapter } from 'team/TeamAdapter';

export const getBreadcrumbItems = (teamAdapter: TeamAdapter) => [
  {
    name: i18n.t('contribution.myContribution.heading'),
    to: contributionRoutesPaths.root({
      params: {
        teamSlug: teamAdapter.toParam(),
      },
    }),
  },
  {
    name: i18n.t('followUps.followUps'),
  },
];
