import { Navigate } from 'react-router-dom';

import { strategyRoutesPaths } from 'strategy/strategy.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';

const NavigateToStrategyOverview = () => {
  const { teamAdapter } = useTeamAdapter();

  const to = strategyRoutesPaths.overview.root({
    params: {
      teamSlug: teamAdapter.toParam(),
    },
  });

  return <Navigate to={to} replace={true} />;
};

export default NavigateToStrategyOverview;
