import { useTranslation } from 'react-i18next';

import { useObjective } from 'objective/ObjectiveProvider';
import Heading from 'shared/components/Heading';
import CreateObjectiveButton from 'objective/CreateObjectiveButton';

import styles from './ObjectiveRelatedObjectivesHeader.module.scss';

const ObjectiveRelatedObjectivesHeader = () => {
  const { t } = useTranslation();

  const { objective } = useObjective();

  return (
    <header className={styles.header}>
      <div className={styles.headingSection}>
        <Heading level={3} className={styles.heading}>
          {t('objective.relatedObjectives.relatedObjectives_other')}
        </Heading>

        <CreateObjectiveButton parentObjective={objective}>
          {t('objective.addChildObjective')}
        </CreateObjectiveButton>
      </div>
    </header>
  );
};

export default ObjectiveRelatedObjectivesHeader;
