import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { ActionStatusMultiSelectItemId } from 'actions/ActionStatusMultiSelect';
import { isActionMultiSelectId } from 'actions/ActionStatusMultiSelect';

type StatusFilterParams = {
  paramName?: string;
};

type StatusFilterReturnType = {
  setStatusFilter: (value: ActionStatusMultiSelectItemId[]) => void;
  statusFilter: ActionStatusMultiSelectItemId[];
};

export const useActionStatusFilter = ({
  paramName = 'status',
}: StatusFilterParams = {}): StatusFilterReturnType => {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultValue = useMemo<ActionStatusMultiSelectItemId[]>(
    () => ['ALL'],
    [],
  );

  const statusFilter = useMemo<StatusFilterReturnType['statusFilter']>(() => {
    const params = searchParams.getAll(paramName);
    if (params.length && params.every(isActionMultiSelectId)) {
      return params;
    }
    return defaultValue;
  }, [searchParams, paramName, defaultValue]);

  const setStatusFilter = useCallback<
    StatusFilterReturnType['setStatusFilter']
  >(
    (value) => {
      setSearchParams((searchParams) => {
        searchParams.delete(paramName);
        value.forEach((id) => {
          searchParams.append(paramName, id);
        });
        return searchParams;
      });
    },
    [setSearchParams, paramName],
  );

  return {
    statusFilter,
    setStatusFilter,
  };
};
