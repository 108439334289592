import type { PropsWithChildren } from 'react';
import { createContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useDefinedContext } from 'shared/utils/context.utils';
import useHandleApolloError from 'shared/errors/useHandleApolloError';

import type { CampaignResultsQuery } from './CampaignResults.graphql.generated';
import { useCampaignResultsQuery } from './CampaignResults.graphql.generated';

type CampaignResultsValue = {
  campaign: CampaignResultsQuery['campaign'];
};

export const CampaignResultsContext =
  createContext<Maybe<CampaignResultsValue>>(undefined);

export const useCampaignResults = () =>
  useDefinedContext(CampaignResultsContext);

const CampaignResultsProvider = ({ children }: PropsWithChildren) => {
  const { campaignId } = useParams<{ campaignId: string }>();

  if (!campaignId) throw new Error('Campaign ID is missing');

  const handleApolloError = useHandleApolloError();

  const { data } = useCampaignResultsQuery({
    variables: { campaignId },
    onError: handleApolloError,
  });

  const value = useMemo(() => {
    if (!data) return;

    const { campaign } = data;

    return { campaign };
  }, [data]);

  if (!data) return null;

  return (
    <CampaignResultsContext.Provider value={value}>
      {children}
    </CampaignResultsContext.Provider>
  );
};

export default CampaignResultsProvider;
