import type { TFunction } from 'i18next';

import type { LinkTabsProps } from 'shared/components/LinkTabs';
import type { TeamAdapter } from 'team/TeamAdapter';
import { toolkitRoutesPaths } from 'toolkit/toolkit.routing.paths';

export const getBreadcrumbItems = (teamAdapter: TeamAdapter, t: TFunction) => [
  {
    name: t('toolkit.toolkit'),
    to: toolkitRoutesPaths.root({
      params: {
        teamSlug: teamAdapter.toParam(),
      },
    }),
  },
  {
    name: t('toolkit.tools.oneThing.name'),
    to: toolkitRoutesPaths.oneThing.root({
      params: {
        teamSlug: teamAdapter.toParam(),
      },
    }),
  },
];

export const getLinkTabsItems = (
  teamAdapter: TeamAdapter,
  t: TFunction,
  isEnabled: boolean,
): LinkTabsProps['items'] =>
  [
    {
      name: t('overview'),
      to: toolkitRoutesPaths.oneThing.overview({
        params: {
          teamSlug: teamAdapter.toParam(),
        },
      }),
    },
    isEnabled && {
      name: t('toolkit.toolPage.walkOfStrategy.campaigns.title'),
      to: toolkitRoutesPaths.oneThing.campaigns.root({
        params: {
          teamSlug: teamAdapter.toParam(),
        },
      }),
    },
  ].filter(Boolean);
