import { Navigate, useSearchParams, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import PreviewStrategyModal from 'strategy/PreviewStrategyModal';
import { useTeamAdapter } from 'team/TeamAdapter';
import ThemesProvider from 'themes/ThemesProvider/ThemesProvider';

import { useStrategyRoom } from '../StrategyRoomProvider';

const StrategyRoomPreviewStrategy = () => {
  const navigate = useNavigate();

  const { teamAdapter } = useTeamAdapter();

  const { refetchStrategies } = useStrategyRoom();

  const [searchParams] = useSearchParams();
  const strategyId = searchParams.get('strategyId');

  const handleClose = useCallback(() => {
    navigate('../');
  }, [navigate]);

  const handleLaunchStrategySuccess = useCallback(() => {
    refetchStrategies();
  }, [refetchStrategies]);

  if (strategyId) {
    return (
      <ThemesProvider>
        <PreviewStrategyModal
          strategyId={strategyId}
          onClose={handleClose}
          teamAdapter={teamAdapter}
          isOpen={true}
          onLaunchStrategySuccess={handleLaunchStrategySuccess}
        />
      </ThemesProvider>
    );
  }

  return <Navigate to={'../'} />;
};

export default StrategyRoomPreviewStrategy;
