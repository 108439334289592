import cn from 'classnames';
import type { RefObject } from 'react';
import { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';

import { useSteps } from 'shared/components/Steps/useSteps';
import type { StepsNavigationProps } from 'shared/components/Steps/StepsNavigation/StepsNavigation';

import styles from './StepsNavigationTabs.module.scss';

type StepsNavigationTabsProps = Pick<StepsNavigationProps, 'onClickStep'> & {
  className?: string;
};

const StepsNavigationTabs = ({
  onClickStep,
  className,
}: StepsNavigationTabsProps) => {
  const { t } = useTranslation();

  const { steps, setActiveStep } = useSteps();

  const [hasActiveIndicatorTransition, setHasActiveIndicatorTransition] =
    useState(false);
  const [referenceElement, setReferenceElement] = useState<
    HTMLButtonElement | undefined
  >();

  const popperElementRef = useRef<HTMLDivElement>(null);
  const tabsElementsRefs = useMemo<RefObject<HTMLButtonElement>[]>(
    () => steps.map(() => createRef<HTMLButtonElement>()),
    [steps],
  );

  useEffect(() => {
    steps.forEach((step, stepIndex) => {
      if (step.isActive) {
        setReferenceElement(tabsElementsRefs[stepIndex].current!);
      }
    });
  }, [steps, tabsElementsRefs]);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElementRef.current,
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [6, -3],
          },
        },
        {
          name: 'computeStyles',
          options: {
            adaptive: false,
          },
        },
      ],
    },
  );

  return (
    <div className={cn(styles.container, className)}>
      <ul className={styles.list}>
        {steps.map((step, stepIndex) => (
          <li className={styles.listItem} key={step.id}>
            <button
              type={'button'}
              disabled={step.isDisabled}
              onClick={() => {
                setActiveStep(step.id);
                setHasActiveIndicatorTransition(true);
                onClickStep?.(step);
              }}
              ref={tabsElementsRefs[stepIndex]}
              className={cn(styles.tab, { [styles.tabActive]: step.isActive })}
            >
              {capitalize(
                step.nameTranslationKey
                  ? t(step.nameTranslationKey)
                  : step.name,
              )}
            </button>
          </li>
        ))}
        <div
          {...popperAttributes.popper}
          ref={popperElementRef}
          style={popperStyles.popper}
          className={cn(styles.activeIndicator, {
            [styles.activeIndicatorTransition]: hasActiveIndicatorTransition,
          })}
        />
      </ul>
    </div>
  );
};

export default StepsNavigationTabs;
