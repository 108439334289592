import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

import { isThemeFilterValue } from './useThemeFilter.utils';
import type { ThemeFilterValue } from './useThemeFilter.type';

type ThemeFilterParams = {
  paramName?: string;
};

type ThemeFilterReturnType = {
  setThemeFilter: (value: ThemeFilterValue) => void;
  themeFilter: ThemeFilterValue;
};

export const useThemeFilter = ({
  paramName = 'theme',
}: ThemeFilterParams = {}): ThemeFilterReturnType => {
  const defaultValue = useMemo(() => [], []);

  const [searchParams, setSearchParams] = useSearchParams();

  const themeFilter = useMemo<ThemeFilterReturnType['themeFilter']>(() => {
    const params = searchParams.getAll(paramName);
    if (params.every(isThemeFilterValue)) {
      return params;
    }
    return defaultValue;
  }, [searchParams, paramName, defaultValue]);

  const setThemeFilter = useCallback<ThemeFilterReturnType['setThemeFilter']>(
    (value) => {
      setSearchParams((searchParams) => {
        searchParams.delete(paramName);
        value.forEach((id) => {
          searchParams.append(paramName, id);
        });
        return searchParams;
      });
    },
    [setSearchParams, paramName],
  );

  return {
    themeFilter,
    setThemeFilter,
  };
};
