import { useCallback, useState } from 'react';
import { Routes, Route, Outlet, useNavigate } from 'react-router-dom';
import { css, useTheme } from '@emotion/react';

import Layout from 'shared/components/Layout';
import DeleteStrategyConfirmationModal from 'strategy/DeleteStrategyConfirmationModal';
import LaunchStrategyConfirmationModal from 'strategy/LaunchStrategyConfirmationModal';
import UnlaunchStrategyConfirmationModal from 'strategy/UnlaunchStrategyConfirmationModal';
import { useTeamAdapter } from 'team/TeamAdapter';
import StrategyPyramid from 'strategy/StrategyPyramid/StrategyPyramid';
import StrategyRoomGuide from 'strategy/StrategyRoadmap/StrategyRoomGuide/StrategyRoomGuide';
import ArchiveStrategyConfirmationModal from 'strategy/ArchiveStrategyConfirmationModal';
import UnarchiveStrategyConfirmationModal from 'strategy/UnarchiveStrategyConfirmationModal';

import StrategyRoomTableViewBefore from './StrategyRoomTableViewBefore';
import type { StrategyRoomStrategy } from './StrategyRoom.type';
import type { StrategyRoomTableViewProps } from './StrategyRoomTableView';
import StrategyRoomEditStrategy from './StrategyRoomEditStrategy';
import StrategyRoomTableView from './StrategyRoomTableView';
import StrategyRoomProvider, { useStrategyRoom } from './StrategyRoomProvider';
import StrategyRoomPreviewStrategy from './StrategyRoomPreviewStrategy';
import StrategyRoomNoStrategyCard from './StrategyRoomNoStrategyCard';
import StrategyRoomCreateStrategy from './StrategyRoomCreateStrategy';
import { strategyRoomRoutesPaths } from '../StrategyRoom/StrategyRoom.routing.paths';

const StrategyRoom = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { teamAdapter } = useTeamAdapter();

  const { strategies, refetchStrategies } = useStrategyRoom();
  const hasStrategies = strategies.length > 0;

  const [strategyToDelete, setStrategyToDelete] =
    useState<Maybe<StrategyRoomStrategy>>();
  const isDeleteStrategyConfirmationModalOpen = !!strategyToDelete;
  const closeDeleteStrategyConfirmationModal = () =>
    setStrategyToDelete(undefined);

  const [strategyToLaunch, setStrategyToLaunch] =
    useState<Maybe<StrategyRoomStrategy>>();
  const isLaunchStrategyConfirmationModalOpen = !!strategyToLaunch;
  const closeLaunchStrategyConfirmationModal = () =>
    setStrategyToLaunch(undefined);

  const [strategyToUnlaunch, setStrategyToUnlaunch] =
    useState<Maybe<StrategyRoomStrategy>>();
  const isUnlaunchStrategyConfirmationModalOpen = !!strategyToUnlaunch;
  const closeUnlaunchStrategyConfirmationModal = () =>
    setStrategyToUnlaunch(undefined);

  const [strategyToArchive, setStrategyToArchive] =
    useState<Maybe<StrategyRoomStrategy>>();
  const isArchiveStrategyConfirmationModalOpen = !!strategyToArchive;
  const closeArchiveStrategyConfirmationModal = () =>
    setStrategyToArchive(undefined);

  const [strategyToUnarchive, setStrategyToUnarchive] =
    useState<Maybe<StrategyRoomStrategy>>();
  const isUnarchiveStrategyConfirmationModalOpen = !!strategyToUnarchive;
  const closeUnarchiveStrategyConfirmationModal = () =>
    setStrategyToUnarchive(undefined);

  const handleOnTableViewAction = useCallback<
    StrategyRoomTableViewProps['onAction']
  >(
    (action, strategy) => {
      switch (action) {
        case 'preview': {
          navigate(
            strategyRoomRoutesPaths.preview({
              query: { strategyId: strategy.id },
            }),
          );
          break;
        }
        case 'launch': {
          setStrategyToLaunch(strategy);
          break;
        }
        case 'unlaunch': {
          setStrategyToUnlaunch(strategy);
          break;
        }
        case 'archive': {
          setStrategyToArchive(strategy);
          break;
        }
        case 'unarchive': {
          setStrategyToUnarchive(strategy);
          break;
        }
        case 'edit': {
          navigate(
            strategyRoomRoutesPaths.edit({
              query: { strategyId: strategy.id },
            }),
          );
          break;
        }
        case 'delete':
          setStrategyToDelete(strategy);
          break;
      }
    },
    [navigate],
  );

  return (
    <>
      <Layout.Content hasPadding={false}>
        <div
          css={css`
            border: 1px solid ${theme.color.strokeMedium};
            border-radius: 20px;
            overflow: hidden;
          `}
        >
          {hasStrategies ? (
            <StrategyRoomTableView
              strategies={strategies}
              onAction={handleOnTableViewAction}
              before={<StrategyRoomTableViewBefore />}
            />
          ) : (
            <StrategyRoomNoStrategyCard />
          )}
        </div>

        <StrategyRoomGuide />
      </Layout.Content>

      {strategyToDelete && (
        <DeleteStrategyConfirmationModal
          strategy={strategyToDelete}
          isOpen={isDeleteStrategyConfirmationModalOpen}
          onClose={closeDeleteStrategyConfirmationModal}
        />
      )}
      {strategyToLaunch && (
        <LaunchStrategyConfirmationModal
          teamAdapter={teamAdapter}
          strategyId={strategyToLaunch.id}
          isOpen={isLaunchStrategyConfirmationModalOpen}
          onClose={closeLaunchStrategyConfirmationModal}
          onSuccess={refetchStrategies}
        />
      )}
      {strategyToUnlaunch && (
        <UnlaunchStrategyConfirmationModal
          teamAdapter={teamAdapter}
          strategyId={strategyToUnlaunch.id}
          isOpen={isUnlaunchStrategyConfirmationModalOpen}
          onClose={closeUnlaunchStrategyConfirmationModal}
        />
      )}
      {strategyToArchive && (
        <ArchiveStrategyConfirmationModal
          strategy={strategyToArchive}
          isOpen={isArchiveStrategyConfirmationModalOpen}
          onClose={closeArchiveStrategyConfirmationModal}
        />
      )}
      {strategyToUnarchive && (
        <UnarchiveStrategyConfirmationModal
          strategy={strategyToUnarchive}
          isOpen={isUnarchiveStrategyConfirmationModalOpen}
          onClose={closeUnarchiveStrategyConfirmationModal}
        />
      )}
      <Outlet />
    </>
  );
};

const StrategyRoomWithProvider = () => (
  <StrategyRoomProvider>
    <StrategyRoom />
  </StrategyRoomProvider>
);

const StrategyRoomWithRouting = () => (
  <Routes>
    <Route
      path={strategyRoomRoutesPaths.root()}
      element={<StrategyRoomWithProvider />}
    >
      <Route
        path={strategyRoomRoutesPaths.strategy.pyramid()}
        element={<StrategyPyramid />}
      />
      <Route
        path={strategyRoomRoutesPaths.preview()}
        element={<StrategyRoomPreviewStrategy />}
      />
      <Route
        path={strategyRoomRoutesPaths.edit()}
        element={<StrategyRoomEditStrategy />}
      />
      <Route
        path={strategyRoomRoutesPaths.create()}
        element={<StrategyRoomCreateStrategy />}
      />
    </Route>
  </Routes>
);

export default StrategyRoomWithRouting;
