import type { ListProps, ListState } from 'react-stately';
import { useListState, useOverlayTriggerState } from 'react-stately';
import type { ReactNode } from 'react';
import { useMemo, useRef } from 'react';
import { useOverlayTrigger, Overlay, usePopover } from 'react-aria';

import ListBox, {
  useListBoxCollectionChildren,
} from 'shared/components/ListBox';
import type { ListBoxRowComponent } from 'shared/components/ListBox';
import SelectOverlayTrigger from 'shared/components/SelectOverlayTrigger';

import type { BaseMultiSelectItem, RenderLabelFn } from './MultiSelect.type';

export type MultiSelectProps<
  MultiSelectItem extends BaseMultiSelectItem = BaseMultiSelectItem,
> = Pick<
  ListProps<MultiSelectItem>,
  'items' | 'onSelectionChange' | 'selectedKeys'
> & {
  ListBoxRow?: ListBoxRowComponent<MultiSelectItem>;
  label?: ReactNode | RenderLabelFn<MultiSelectItem>;
  selectedKeys?: ListProps<MultiSelectItem>['selectedKeys'];
  state?: ListState<MultiSelectItem>;
  triggerClassName?: string;
};

/**
 * Allow a user to choose one or more options from a collapsible list.
 */
const MultiSelect = <
  ChipInputItem extends BaseMultiSelectItem = BaseMultiSelectItem,
>({
  items,
  ListBoxRow,
  state: stateProp,
  label: labelProp,
  onSelectionChange,
  selectedKeys,
  triggerClassName,
}: MultiSelectProps<ChipInputItem>) => {
  const triggerElementRef = useRef<HTMLButtonElement>(null);
  const popoverElementRef = useRef<HTMLDivElement>(null);

  const _ref = useRef<any>(null);

  const overlayTriggerState = useOverlayTriggerState({});
  const { triggerProps } = useOverlayTrigger(
    {
      type: 'listbox',
    },
    overlayTriggerState,
    triggerElementRef,
  );

  const collectionChildren = useListBoxCollectionChildren({ Row: ListBoxRow });
  const internalState = useListState({
    items,
    selectionMode: 'multiple',
    children: collectionChildren,
    selectedKeys,
    onSelectionChange,
  });
  const state = stateProp ?? internalState;

  const { popoverProps, underlayProps } = usePopover(
    {
      triggerRef: triggerElementRef,
      popoverRef: popoverElementRef,
      offset: 5,
    },
    overlayTriggerState,
  );

  const label = useMemo<ReactNode>(() => {
    if (typeof labelProp === 'function') {
      return labelProp(state);
    }
    return labelProp;
  }, [labelProp, state]);

  return (
    <>
      <SelectOverlayTrigger
        {...triggerProps}
        ref={triggerElementRef}
        className={triggerClassName}
      >
        {label}
      </SelectOverlayTrigger>
      {overlayTriggerState.isOpen && (
        <Overlay {...underlayProps}>
          <div {...popoverProps} ref={popoverElementRef}>
            <ListBox
              items={items}
              state={state}
              Row={ListBoxRow}
              selectionMode={'multiple'}
              innerRef={_ref}
            />
          </div>
        </Overlay>
      )}
    </>
  );
};

export default MultiSelect;
