import { useMemo } from 'react';
import partition from 'lodash/partition';

import type {
  UseExpandableTreeItemsParams,
  UseExpandableTreeItemsReturnType,
} from './useExpandableTreeItems';
import { useExpandableTreeItems } from './useExpandableTreeItems';
import type { BaseTreeItem, GetChildTreeItemsFn } from './useTree.type';

type UseTreeRowParams<TreeItemParam extends BaseTreeItem> = {
  childItems: TreeItemParam[];
  getChildItems: GetChildTreeItemsFn<TreeItemParam>;
  parentItem: TreeItemParam;
} & UseExpandableTreeItemsParams;

export type UseTreeRowReturnType<TreeItemParam extends BaseTreeItem> = {
  directChildItems: TreeItemParam[];
  hasDirectChildItems: boolean;
  nonDirectChildItems: TreeItemParam[];
} & UseExpandableTreeItemsReturnType<TreeItemParam>;

export const useTreeRow = <TreeItemParam extends BaseTreeItem>(
  params: UseTreeRowParams<TreeItemParam>,
): UseTreeRowReturnType<TreeItemParam> => {
  const {
    childItems,
    parentItem,
    getChildItems,
    allExpanded,
    expandedItemsIds,
  } = params;

  const [directChildItems, nonDirectChildItems] = useMemo(
    () =>
      partition(childItems, (childItem) =>
        (getChildItems(parentItem) || []).find(
          (parentItemChildItem) => parentItemChildItem.id === childItem.id,
        ),
      ),
    [childItems, parentItem, getChildItems],
  );
  const hasDirectChildItems = useMemo(
    () => directChildItems.length > 0,
    [directChildItems],
  );

  const { toggleIsItemExpanded, isItemExpanded } = useExpandableTreeItems({
    allExpanded,
    expandedItemsIds,
  });

  return {
    directChildItems,
    nonDirectChildItems,
    isItemExpanded,
    toggleIsItemExpanded,
    hasDirectChildItems,
  };
};
