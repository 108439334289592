import type { ButtonProps } from 'shared/components/Button';
import Button from 'shared/components/Button';

type ToolkitOverviewButtonProps = ButtonProps;

const ToolkitOverviewButton = (props: ToolkitOverviewButtonProps) => (
  <Button {...props} className={props.className} />
);

export default ToolkitOverviewButton;
