import type { PropsWithChildren } from 'react';
import { useListState } from 'react-stately';
import { useMemo } from 'react';
import { sortBy } from 'lodash';

import type { MultiSelectProps } from 'shared/components/MultiSelect';
import { useListBoxCollectionChildren } from 'shared/components/ListBox';

import type { UserMultiSelectProviderContextValue } from './UserMultiSelectProvider.context';
import { UserMultiSelectProviderContext } from './UserMultiSelectProvider.context';
import UserMultiSelectListBoxRow from '../UserMultiSelectListBoxRow';
import type { UserMultiSelectItem } from '../UserMultiSelect.type';

type UserMultiSelectProviderProps = PropsWithChildren<
  Pick<
    MultiSelectProps<UserMultiSelectItem>,
    'onSelectionChange' | 'selectedKeys'
  > & {
    users: UserMultiSelectItem[];
  }
>;

const UserMultiSelectProvider = ({
  children,
  onSelectionChange,
  selectedKeys,
  users,
}: UserMultiSelectProviderProps) => {
  const items = useMemo(
    () =>
      sortBy(users, (user) =>
        user.displayName && user.displayName !== ''
          ? user.displayName
          : user.email,
      ),
    [users],
  );

  const collectionChildren = useListBoxCollectionChildren({
    Row: UserMultiSelectListBoxRow,
  });

  const listState = useListState<UserMultiSelectItem>({
    items,
    selectionMode: 'multiple',
    children: collectionChildren,
    selectedKeys,
    onSelectionChange,
  });

  const contextValue = useMemo<UserMultiSelectProviderContextValue>(
    () => ({
      state: listState,
      items,
    }),
    [listState, items],
  );

  return (
    <UserMultiSelectProviderContext.Provider value={contextValue}>
      {children}
    </UserMultiSelectProviderContext.Provider>
  );
};

export default UserMultiSelectProvider;
