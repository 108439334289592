import { useTranslation } from 'react-i18next';

import AiSuggestions from 'ai/AiSuggestions';
import AiSuggestionsInputField from 'ai/AiSuggestions/AiSuggestionsInputField';
import AiSuggestionsVariableField from 'ai/AiSuggestions/AiSuggestionsVariableField';
import SuggestionsProvider from 'ai/AiSuggestions/SuggestionsProvider';

import {
  InitiativeSuggestionsDocument,
  type InitiativeSuggestionsSubscription,
  type InitiativeSuggestionsSubscriptionVariables,
} from './InitiativeSuggestions.graphql.generated';

type Props = {
  shouldReinitialize: boolean;
};

const InitiativeSuggestions = ({ shouldReinitialize }: Props) => {
  const { t } = useTranslation();

  return (
    <SuggestionsProvider<
      InitiativeSuggestionsSubscription,
      InitiativeSuggestionsSubscriptionVariables
    >
      subscriptionDocument={InitiativeSuggestionsDocument}
      hasVariables={(variables) => !!variables?.objectiveName}
      shouldReinitialize={shouldReinitialize}
    >
      <AiSuggestions
        title={t('initiative.askSophie.title')}
        buttonLabel={t('initiative.askSophie.suggestButton')}
        buttonLabelAfterFirstTrigger={t(
          'initiative.askSophie.suggestMoreButton',
        )}
        suggestionAction={'copy'}
        titleFieldName={'name'}
      />
      <AiSuggestionsVariableField<InitiativeSuggestionsSubscriptionVariables>
        variableName={'objectiveName'}
        fieldName={'objective'}
        fieldProperty={'value.name'}
      />
      <AiSuggestionsInputField suggestionField={'name'} />
      <AiSuggestionsInputField suggestionField={'description'} />
    </SuggestionsProvider>
  );
};

export default InitiativeSuggestions;
