import { groupObjectivesByTheme } from 'objective/objective.utils';

import type {
  StrategyRoadmapObjectiveFragment,
  StrategyRoadmapThemeFragment,
} from './StrategyRoadmapProvider.graphql.generated';

export const getObjectivesByTheme = (
  objectives: StrategyRoadmapObjectiveFragment[],
  themes: StrategyRoadmapThemeFragment[],
) =>
  groupObjectivesByTheme(objectives, false, themes)
    .filter(({ theme }) => !theme || theme.isActive)
    .filter(
      ({ objectives }) =>
        !objectives.length ||
        objectives.some(
          (objective) =>
            !objective.currentObjectiveStatus?.statusIndicator.isCompleted,
        ),
    );
