import { useMemo } from 'react';
import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import { determineIfIsOverdue } from 'actions/action.utils';
import ActionChipOverdue from 'actions/ActionChipOverdue';
import { ReactComponent as DateIcon } from 'shared/static/icons/icon-date.svg';
import BaseModal from 'shared/components/__DEPRECATED__/BaseModal';
import Heading from 'shared/components/Heading';
import IconButton from 'shared/components/IconButton';
import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';
import ObjectiveLink from 'objective/ObjectiveLink';
import UserAvatar from 'user/UserAvatar';
import { date } from 'shared/services/date.service';
import CompletionRate from 'shared/components/CompletionRate';
import { usePageTitle } from 'shared/hooks/usePageTitle';
import ActionProvider, { useAction } from 'actions/ActionProvider';
import { useActionsLayout } from 'actions/ActionsLayout/ActionsLayoutProvider';
import { useTeamSlug } from 'team/TeamAdapter/useTeamSlug';

import ActionStatusForm from './ActionStatusForm';
import styles from './ActionStatusModal.module.scss';
import type { ActionStatusLocation } from './ActionStatusModal.type';
import { handleOnClose } from './ActionStatusModal.utils';

const ActionStatusModal = () => {
  const { teamSlug, orgTeamSlug } = useTeamSlug();
  const { action } = useAction();
  const { layout } = useActionsLayout();

  const {
    name,
    description,
    objective: parentObjective,
    owner,
    timeLine,
    currentStatus,
  } = action;

  const { state, pathname } = useLocation() as ActionStatusLocation;

  const navigate = useNavigate();

  const isActionOverdue = useMemo(() => determineIfIsOverdue(action), [action]);

  usePageTitle(action.name);

  const onClose = () => {
    if (action.objective) {
      handleOnClose({
        options: { state },
        teamSlug,
        orgTeamSlug,
        navigate,
        layout,
        pathname,
        objectiveId: action.objective?.id,
      });
    }
  };

  return (
    <BaseModal
      isOpen={true}
      onClose={onClose}
      className={styles.modal}
      containerClassName={styles.container}
    >
      <header className={styles.header}>
        <Heading level={3} className={styles.heading}>
          {name}
        </Heading>
        <p className={styles.description}>{description}</p>
        {parentObjective && <ObjectiveLink objective={parentObjective} />}
        <div className={styles.metadata}>
          <div className={styles.timeline}>
            <DateIcon className={styles.timelineIcon} />
            <div
              className={cn(styles.timelineText, {
                [styles.overdue]: isActionOverdue,
              })}
            >
              {date.format(timeLine.endDate, 'dd MMM')}
            </div>
            {isActionOverdue && <ActionChipOverdue />}
            <CompletionRate
              complete={currentStatus?.complete}
              completionRate={currentStatus?.completionRate}
            />
          </div>
          <UserAvatar
            user={owner}
            hasLabel={true}
            containerClassName={styles.user}
          />
        </div>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          icon={CloseIcon}
          color={'black'}
          className={styles.icon}
        />
      </header>
      <ActionStatusForm action={action} onClose={onClose} />
    </BaseModal>
  );
};

export default () => (
  <ActionProvider>
    <ActionStatusModal />
  </ActionProvider>
);
