import { useTranslation, Trans } from 'react-i18next';
import { useState } from 'react';

import type { CommentsFormProps } from 'shared/components/CommentsForm';
import CommentsForm from 'shared/components/CommentsForm';
import type { Objective, ObjectiveStatus } from 'types.graphql.generated';
import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import Heading from 'shared/components/Heading';
import Text from 'shared/components/Text';
import Space from 'shared/components/Space';
import StatusMark from 'shared/status/StatusMark';
import StatusCommentsInfoCard from 'shared/status/StatusCommentsInfoCard';
import PreviousInsightsSidecard from 'status/PreviousInsightsSidecard/PreviousInsightsSidecard';
import { usePreviousInsights } from 'status/PreviousInsightsSidecard/usePreviousInsights';

import styles from './ObjectiveStatusWizardComments.module.scss';

type ObjectiveStatusWizardCommentsProps = Pick<
  CommentsFormProps,
  'id' | 'initialValues' | 'onSubmit' | 'onError' | 'onChange'
> & {
  objective: Pick<Objective, 'id' | 'name'> & {
    currentObjectiveStatus?: Pick<ObjectiveStatus, 'statusIndicator'>;
  };
};

const ObjectiveStatusWizardComments = ({
  objective,
  ...restProps
}: ObjectiveStatusWizardCommentsProps) => {
  const { t } = useTranslation();

  const [isSidesheetOpen, setIsSidesheetOpen] = useState(false);
  const { onOpenPreviousInsights } = usePreviousInsights();

  const renderForm = () => (
    <>
      <Heading level={3}>
        {t('objective.objectiveStatusWizard.steps.comments.heading')}
      </Heading>

      <Text className={styles.description}>
        <Trans
          i18nKey={'objective.objectiveStatusWizard.steps.comments.description'}
          values={{
            objectiveName: objective.name,
          }}
        >
          <Text variant={'emphasis'} />
        </Trans>
      </Text>

      {objective.currentObjectiveStatus ? (
        <Space className={styles.objectiveStatus}>
          <Text variant={'emphasis'}>
            {t(
              'objective.objectiveStatusWizard.steps.comments.objectiveStatusLabel',
            )}
          </Text>
          <StatusMark
            statusIndicator={objective.currentObjectiveStatus?.statusIndicator}
          />
        </Space>
      ) : null}

      <CommentsForm
        {...restProps}
        onShowPreviousInsights={onOpenPreviousInsights}
      />
    </>
  );

  const renderInfoCard = () => (
    <StatusCommentsInfoCard
      isSidesheetOpen={isSidesheetOpen}
      setIsSidesheetOpen={setIsSidesheetOpen}
    />
  );

  return (
    <FormWithInfoCardLayout
      renderForm={renderForm}
      renderInfoCard={renderInfoCard}
      renderAfterInfoCard={<PreviousInsightsSidecard />}
    />
  );
};

export default ObjectiveStatusWizardComments;
