import type * as Types from '../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateReportMutationVariables = Types.Exact<{
  input: Types.ReportInput;
}>;


export type CreateReportMutation = { __typename: 'Mutation', addReport: { __typename: 'Report', id: string, name?: string | undefined } };

export type EditReportMutationVariables = Types.Exact<{
  input: Types.ReportInput;
}>;


export type EditReportMutation = { __typename: 'Mutation', updateReport: { __typename: 'Report', id: string, name?: string | undefined } };

export type ExportReportMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ExportReportMutation = { __typename: 'Mutation', updateReport: { __typename: 'Report', id: string, name?: string | undefined, reportExports: Array<{ __typename: 'ReportExport', id: string, emailSentTime?: any | undefined, emailSentTo?: string | undefined, exportFormat?: Types.ExportFormat | undefined, fileName?: string | undefined }> } };


export const CreateReportDocument = gql`
    mutation CreateReport($input: ReportInput!) {
  addReport(input: $input) {
    id
    name
  }
}
    `;
export type CreateReportMutationFn = Apollo.MutationFunction<CreateReportMutation, CreateReportMutationVariables>;

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportMutation, CreateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportMutation, CreateReportMutationVariables>(CreateReportDocument, options);
      }
export type CreateReportMutationHookResult = ReturnType<typeof useCreateReportMutation>;
export type CreateReportMutationResult = Apollo.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = Apollo.BaseMutationOptions<CreateReportMutation, CreateReportMutationVariables>;
export const EditReportDocument = gql`
    mutation EditReport($input: ReportInput!) {
  updateReport(input: $input) {
    id
    name
  }
}
    `;
export type EditReportMutationFn = Apollo.MutationFunction<EditReportMutation, EditReportMutationVariables>;

/**
 * __useEditReportMutation__
 *
 * To run a mutation, you first call `useEditReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReportMutation, { data, loading, error }] = useEditReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditReportMutation(baseOptions?: Apollo.MutationHookOptions<EditReportMutation, EditReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditReportMutation, EditReportMutationVariables>(EditReportDocument, options);
      }
export type EditReportMutationHookResult = ReturnType<typeof useEditReportMutation>;
export type EditReportMutationResult = Apollo.MutationResult<EditReportMutation>;
export type EditReportMutationOptions = Apollo.BaseMutationOptions<EditReportMutation, EditReportMutationVariables>;
export const ExportReportDocument = gql`
    mutation ExportReport($id: ID!) {
  updateReport(input: {idToUpdate: $id, exportReport: {exportFormat: PPTX}}) {
    id
    name
    reportExports {
      id
      emailSentTime
      emailSentTo
      exportFormat
      fileName
    }
  }
}
    `;
export type ExportReportMutationFn = Apollo.MutationFunction<ExportReportMutation, ExportReportMutationVariables>;

/**
 * __useExportReportMutation__
 *
 * To run a mutation, you first call `useExportReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportReportMutation, { data, loading, error }] = useExportReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportReportMutation(baseOptions?: Apollo.MutationHookOptions<ExportReportMutation, ExportReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportReportMutation, ExportReportMutationVariables>(ExportReportDocument, options);
      }
export type ExportReportMutationHookResult = ReturnType<typeof useExportReportMutation>;
export type ExportReportMutationResult = Apollo.MutationResult<ExportReportMutation>;
export type ExportReportMutationOptions = Apollo.BaseMutationOptions<ExportReportMutation, ExportReportMutationVariables>;