import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import CollapsibleList from 'shared/components/CollapsibleList';
import { ReactComponent as ThemeIcon } from 'shared/static/icons/icon-theme.svg';

import type { StrategyMetricsObjectivesByTheme } from '../StrategyMetricsProvider/StrategyMetricsProvider.type';
import MetricsListThemesRow from './MetricsListThemesRow';

type Props = {
  isExpanded: boolean;
  objectivesByTheme: StrategyMetricsObjectivesByTheme;
};

const MetricsListThemesSection = ({ objectivesByTheme, isExpanded }: Props) => {
  const { t } = useTranslation();
  const { theme, objectives } = objectivesByTheme;

  const metrics = objectives.flatMap((objective) => objective.metrics);

  return (
    <CollapsibleList
      key={theme?.id || '-1'}
      title={theme?.name || t('theme.noTheme')}
      type={'metric'}
      childrenCount={metrics.length}
      titleIcon={ThemeIcon}
      expanded={isExpanded}
    >
      <div css={css({ margin: '0 24px' })}>
        {objectives.flatMap((objective) =>
          objective.metrics.map((metric) => (
            <MetricsListThemesRow
              key={metric.id}
              objective={objective}
              metric={metric}
            />
          )),
        )}
      </div>
    </CollapsibleList>
  );
};

export default MetricsListThemesSection;
