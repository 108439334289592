import { Trans } from 'react-i18next';
import { useMemo } from 'react';
import cn from 'classnames';

import { date } from 'shared/services/date.service';
import AuditRecordUserAvatar from 'user/AuditRecordUserAvatar/AuditRecordUserAvatar';

import styles from './InitiativeOverviewInsightsFirstRow.module.scss';
import type { InitiativeOverviewInitiative } from '../InitiativeOverview.type';

type InitiativeInsightsFirstRowProps = {
  initiative: Maybe<
    Pick<
      InitiativeOverviewInitiative,
      'id' | 'auditRecord' | 'initiativeStatusListAll'
    >
  >;
  isLastRow: boolean;
};

const InitiativeInsightsFirstRow = ({
  initiative,
  isLastRow,
}: InitiativeInsightsFirstRowProps) => {
  const createDateTime = useMemo(
    () =>
      initiative?.auditRecord.createDateTime
        ? date.format(initiative?.auditRecord.createDateTime, 'dd MMM yyyy')
        : '',
    [initiative?.auditRecord.createDateTime],
  );

  const isOnlyItem = useMemo(
    () => !initiative?.initiativeStatusListAll.length,
    [initiative?.initiativeStatusListAll],
  );

  return (
    <li
      key={initiative?.id}
      className={cn(styles.item, {
        [styles.lastRowItem]: isLastRow,
        [styles.onlyItem]: isOnlyItem,
      })}
    >
      <div className={styles.header}>
        <AuditRecordUserAvatar
          auditRecordUser={initiative?.auditRecord.createBy}
          size={'small'}
        />
        <div className={styles.shortText}>
          <Trans
            i18nKey={
              'initiative.initiativeInsights.recentInsights.initiativeCreated'
            }
            values={{
              user:
                initiative?.auditRecord.createBy?.displayName ??
                initiative?.auditRecord.createBy?.email,
              date: createDateTime,
            }}
            components={{
              emphasis: <span className={styles.emphasis} />,
            }}
          />
        </div>
      </div>
    </li>
  );
};

export default InitiativeInsightsFirstRow;
