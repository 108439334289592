import styled from '@emotion/styled';

const MultiFieldRow = styled.div`
  display: flex;
  gap: 20px;

  > div {
    flex-grow: 1;
    flex-basis: 0;
  }
`;

export default MultiFieldRow;
