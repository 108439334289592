import type { ComponentType, SVGProps, PropsWithChildren } from 'react';
import cn from 'classnames';

import styles from './Progress.module.scss';

type ProgressProps = PropsWithChildren<{
  className?: string;
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
  label: string;
  value: Maybe<number>;
}>;

const Progress = ({
  value,
  icon: Icon,
  children,
  label,
  className,
}: ProgressProps) => (
  <div className={cn(styles.progress, className)} title={label}>
    <div className={styles.container}>
      <p className={styles.progressLabel}>{label}</p>
      <div className={styles.progressContent}>
        {Icon && <Icon className={styles.progressIcon} />}
        <div className={styles.progressText}>{children}</div>
      </div>
      <div className={styles.progressBarContainer} role={'img'}>
        <div
          className={styles.progressBar}
          style={{
            width: `${value ?? 0}%`,
          }}
        />
      </div>
    </div>
  </div>
);

export default Progress;
