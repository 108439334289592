import type { FormikValues } from 'formik';

import type { FormRef } from './FormRefsProvider.type';
import { useFormRefs } from './useFormRefs';

export const useFormRef = <Values extends FormikValues>(
  formId?: Maybe<string>,
): Maybe<FormRef<Values>> => {
  const { formRefs } = useFormRefs();
  return formId ? (formRefs[formId] as unknown as FormRef<Values>) : undefined;
};
