import FiltersButton from 'shared/components/FiltersButton';

import ActionsLayoutFilters from '../ActionsLayoutFilters';
import { useChipGroupItems } from '../ActionsLayoutFiltersChipGroup/useChipGroupItems';

type Props = {
  clearFilters: () => void;
};

const ActionsFiltersButton = ({ clearFilters }: Props) => {
  const { length: selectionCount } = useChipGroupItems();

  return (
    <FiltersButton clearFilters={clearFilters} selectionCount={selectionCount}>
      <ActionsLayoutFilters />
    </FiltersButton>
  );
};

export default ActionsFiltersButton;
