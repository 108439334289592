import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import AiSuggestions from 'ai/AiSuggestions';
import AiSuggestionsInputField from 'ai/AiSuggestions/AiSuggestionsInputField';
import AiSuggestionsVariableField from 'ai/AiSuggestions/AiSuggestionsVariableField';
import SuggestionsProvider from 'ai/AiSuggestions/SuggestionsProvider';
import {
  ObjectiveSuggestionsDocument,
  type ObjectiveSuggestionsSubscription,
  type ObjectiveSuggestionsSubscriptionVariables,
} from 'objective/EditObjective/ObjectiveFormModal/ObjectiveSuggestions.graphql.generated';

import type { ObjectiveFormValues } from '../ObjectiveForm';

type Props = {
  shouldReinitialize: boolean;
};

const ObjectiveSuggestions = ({ shouldReinitialize }: Props) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<ObjectiveFormValues>();

  const orgUnitName = values.orgUnit.value?.name;

  return (
    <SuggestionsProvider<
      ObjectiveSuggestionsSubscription,
      ObjectiveSuggestionsSubscriptionVariables
    >
      subscriptionDocument={ObjectiveSuggestionsDocument}
      hasVariables={(variables) => !!variables?.parentObjectiveName}
      shouldReinitialize={shouldReinitialize}
      hints={[
        'The suggestion should be a child objective for the specified objective',
        orgUnitName
          ? `The suggestion should be for the org unit of ${orgUnitName}`
          : '',
      ]}
    >
      <AiSuggestions
        title={t('objective.askSophie.title')}
        buttonLabel={t('objective.askSophie.suggestButton')}
        buttonLabelAfterFirstTrigger={t(
          'objective.askSophie.suggestMoreButton',
        )}
        suggestionAction={'copy'}
        titleFieldName={'name'}
      />
      <AiSuggestionsVariableField<ObjectiveSuggestionsSubscriptionVariables>
        variableName={'parentObjectiveName'}
        fieldName={'parentObjective'}
        fieldProperty={'value.name'}
      />
      <AiSuggestionsInputField suggestionField={'name'} />
      <AiSuggestionsInputField suggestionField={'description'} />
    </SuggestionsProvider>
  );
};

export default ObjectiveSuggestions;
