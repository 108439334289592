import { useTranslation } from 'react-i18next';
import type { FormikProps } from 'formik';
import { Field, FieldArray } from 'formik';
import type { RefObject } from 'react';
import { useMemo } from 'react';
import { css } from '@emotion/react';

import Heading from 'shared/components/Heading';
import IconButton from 'shared/components/IconButton';
import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';
import { TextInputField } from 'shared/components/TextInput';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import Form from 'shared/form/Form';
import AutoFocus from 'shared/form/AutoFocus';
import type { FormProps } from 'shared/form/Form/Form';

import { createInitialValues, resolveSubmitValues } from './CommentsForm.utils';
import styles from './CommentsForm.module.scss';
import Flex from '../Flex';
import Button from '../Button';

export type CommentsFormValues = {
  actions: string[];
  challenges: string[];
  successes: string[];
};

const commentsFormId = 'commentsForm';

export type CommentsFormProps = Pick<
  FormProps<CommentsFormValues>,
  'onError' | 'onChange' | 'className' | 'id' | 'onSubmit'
> & {
  hasHeadings?: boolean;
  initialValues?: Partial<CommentsFormValues>;
  innerRef?: RefObject<FormikProps<CommentsFormValues>>;
  onFieldBlur?: () => void;
  onShowPreviousInsights: () => void;
};

// @deprecated: use StatusCommentsForm instead
const CommentsForm = ({
  onChange,
  onSubmit,
  onShowPreviousInsights,
  initialValues: initialValuesProp,
  id = commentsFormId,
  hasHeadings = true,
  onFieldBlur,
  className,
  innerRef,
}: CommentsFormProps) => {
  const { t } = useTranslation();

  const initialValues = useMemo<CommentsFormValues>(
    () => createInitialValues(initialValuesProp),
    [initialValuesProp],
  );

  const renderCommentsFieldArray = (
    name: keyof CommentsFormValues,
    formikProps: FormikProps<CommentsFormValues>,
  ) => {
    return (
      <FieldArray name={name}>
        {(arrayHelpers) => {
          const placeholders = Array.from({ length: 3 }).map((_, index) =>
            t(`comments.form.${name}.input.${index}.placeholder`),
          );
          return (
            <div className={styles.comments}>
              {hasHeadings && (
                <Heading level={4} as={5} hasMargin={false}>
                  {t(`comments.form.${name}.heading`)}
                </Heading>
              )}

              {formikProps.values[name].map((_, valueIndex) => {
                const fieldName = `${name}.${valueIndex}`;

                return (
                  <Flex key={fieldName} alignItems={'center'}>
                    <AutoFocus key={valueIndex} css={css({ flexGrow: 1 })}>
                      <Field
                        name={fieldName}
                        component={TextInputField}
                        placeholder={
                          placeholders[valueIndex] ||
                          t(`comments.form.${name}.placeholder`)
                        }
                        onBlur={() => {
                          formikProps.handleBlur(fieldName);
                          onFieldBlur?.();
                        }}
                      />
                    </AutoFocus>
                    <IconButton
                      icon={CloseIcon}
                      variant={'transparent'}
                      onClick={() => {
                        arrayHelpers.remove(valueIndex);
                      }}
                    >
                      {t('comments.form.removeCommentButton')}
                    </IconButton>
                  </Flex>
                );
              })}

              <Flex justifyContent={'space-between'} alignItems={'center'}>
                <IconButton
                  variant={'outlined'}
                  icon={PlusIcon}
                  onClick={() => {
                    arrayHelpers.push('');
                  }}
                >
                  {t(`comments.form.${name}.addCommentButton`)}
                </IconButton>

                <Button
                  variant={'simple'}
                  css={css({ fontWeight: 400 })}
                  onClick={onShowPreviousInsights}
                >
                  {t(`comments.form.${name}.linkToPrevious`)}
                </Button>
              </Flex>
            </div>
          );
        }}
      </FieldArray>
    );
  };

  return (
    <Form<CommentsFormValues>
      initialValues={initialValues}
      id={id}
      onSubmit={(values, ...restArgs) => {
        onSubmit?.(resolveSubmitValues(values), ...restArgs);
      }}
      onChange={onChange}
      className={className}
      innerRef={innerRef}
      enableReinitialize={true}
    >
      {(formikProps) => (
        <Flex direction={'column'} gap={'1.2rem'}>
          {renderCommentsFieldArray('successes', formikProps)}
          {renderCommentsFieldArray('challenges', formikProps)}
          {renderCommentsFieldArray('actions', formikProps)}
        </Flex>
      )}
    </Form>
  );
};

export default CommentsForm;
