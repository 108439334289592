import type { TypePolicies } from '@apollo/client';

import { getNameAbbreviation } from 'team/team.utils';

export const orgTypePolicies: TypePolicies = {
  Org: {
    fields: {
      orgUnits: {
        merge: (_existing, incoming) => incoming,
      },
      displayNameAbbreviated: {
        read: (value, { readField }) => {
          if (!value) {
            return getNameAbbreviation(readField('displayName'));
          }
          return value;
        },
      },
    },
  },
};
