import { capitalize } from 'lodash';

import type { StatusIndicator as StatusIndicatorEnum } from 'types.graphql.generated';
import { i18n } from 'shared/services/i18n.service';

import styles from './StatusIndicator.module.scss';

type StatusIndicatorOptions = {
  isCompleted?: boolean;
  name?: string;
};

export class StatusIndicator {
  value?: StatusIndicatorEnum;
  isCompleted?: boolean;
  name?: string;

  constructor(
    value?: StatusIndicatorEnum,
    options: StatusIndicatorOptions = {},
  ) {
    const { isCompleted, name } = options;
    this.value = value;
    this.isCompleted = isCompleted;
    this.name = name;
  }

  getColor() {
    if (this.isCompleted) {
      return styles.COMPLETED;
    }
    if (this.value) {
      return styles[this.value];
    }
    return styles.UNKNOWN;
  }

  getName() {
    if (this.name) {
      return this.name;
    }
    return capitalize(i18n.t(`${this.getI18nKey()}.name`));
  }

  getColorName() {
    return i18n.t(`${this.getI18nKey()}.colorName`);
  }

  private getI18nKey() {
    if (this.isCompleted) {
      return `statusIndicator.COMPLETED`;
    }
    if (this.value) {
      return `statusIndicator.${this.value}`;
    }
    return 'statusIndicator.UNKNOWN';
  }
}
