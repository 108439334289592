import { createContext } from 'react';

export type InsightsReportWizardContextValue = {
  hasSelection: boolean;
  hasStatusRequests: boolean;
  setHasSelection: (hasSelection: boolean) => void;
  setHasStatusRequests: (hasStatusRequests: boolean) => void;
};

export const InsightsReportWizardContext =
  createContext<Maybe<InsightsReportWizardContextValue>>(undefined);
