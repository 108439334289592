import { createContext } from 'react';

export type ShowChildrenFilterValue = {
  showChildren: boolean;
  toggleShowChildren: () => void;
};

const ShowChildrenFilterContext = createContext<
  ShowChildrenFilterValue | undefined
>(undefined);

export default ShowChildrenFilterContext;
