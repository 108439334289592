import cn from 'classnames';

import type { Size } from 'shared/types/size.type';

import styles from './EmojiIcon.module.scss';
import Emoji from '../Emoji';

type EmojiIconProps = {
  className?: string;
  emoji: string;
  size?: Extract<Size, 'tiny' | 'small' | 'medium'>;
};

const EmojiIcon = ({ emoji, size = 'medium', className }: EmojiIconProps) => (
  <div
    className={cn(
      styles.emojiIcon,
      {
        [styles.emojiIconTiny]: size === 'tiny',
        [styles.emojiIconSmall]: size === 'small',
        [styles.emojiIconMedium]: size === 'medium',
      },
      className,
    )}
  >
    <span className={styles.emoji}>
      <Emoji icon={emoji} />
    </span>
  </div>
);

export default EmojiIcon;
