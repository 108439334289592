import styled from '@emotion/styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import Text from 'shared/components/Text';
import { ReactComponent as ChevronDownIcon } from 'shared/static/icons/icon-chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'shared/static/icons/icon-chevron-up.svg';
import { ReactComponent as CopyIcon } from 'shared/static/icons/icon-copy.svg';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import type { AiSuggestionElement } from 'types.graphql.generated';
import { getAiSuggestionField } from 'ai/getAiSuggestionField';
import SideCardItemButton from 'shared/components/SideCardItemButton';

import { useSuggestions } from './SuggestionsProvider';

const Name = styled.div`
  display: flex;
  gap: 6px;
  justify-content: space-between;
`;

const Controls = styled.div`
  flex-shrink: 0;
  display: flex;
  gap: 2px;
  align-items: flex-start;
`;

const Chevron = styled.span`
  margin-left: 5px;

  svg {
    margin-top: -2px;
    width: 10px;
    height: 10px;
  }
`;

const Description = styled.div`
  margin-top: 4px;
  margin-bottom: 10px;
`;

export type SuggestionAction = 'copy' | 'add';

type Props = {
  index: number;
  suggestion: AiSuggestionElement;
  suggestionAction: SuggestionAction;
  titleFieldName: string;
};

const SuggestedItem = ({
  index,
  suggestion,
  titleFieldName,
  suggestionAction,
}: Props) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const { setSelectedSuggestion } = useSuggestions();

  const toggleIsExpanded = () => setIsExpanded(!isExpanded);

  const position = index + 1;

  const title = getAiSuggestionField(suggestion, titleFieldName);
  const description = getAiSuggestionField(suggestion, 'description');

  return (
    <div>
      <Name
        onClick={description ? toggleIsExpanded : undefined}
        css={css({ cursor: description ? 'pointer' : 'default' })}
      >
        {position}
        {`.`} {title}
        <Controls>
          <SideCardItemButton
            tooltip={t(`ai.askSophie.${suggestionAction}`)}
            onClick={() => setSelectedSuggestion(suggestion)}
          >
            {suggestionAction === 'copy' && <CopyIcon />}
            {suggestionAction === 'add' && <PlusIcon />}
          </SideCardItemButton>

          {description && (
            <Chevron>
              {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </Chevron>
          )}
        </Controls>
      </Name>

      {isExpanded && (
        <Description>
          <Text>{description}</Text>
        </Description>
      )}
    </div>
  );
};

export default SuggestedItem;
