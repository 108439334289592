import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';

const getPopoverStyle = (theme: Theme) => css`
  background-color: ${theme.color.white};
  border-radius: 6px;
  padding: 1.25rem;
  border: 1px solid ${theme.color.strokeMedium};
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
`;

export default getPopoverStyle;
