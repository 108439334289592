import type { Theme } from '@emotion/react';

import { getObjectivesStatsActive } from 'objective/objective.utils';
import { i18n } from 'shared/services/i18n.service';

import type { StrategyObjectivesObjective } from '../StrategyObjectives.type';

export const getObjectivesStats = (
  theme: Theme,
  activeObjectives: Maybe<StrategyObjectivesObjective[]>,
  completedObjectivesCount: number,
) => {
  const statDoneValue = getObjectivesStatDone(
    activeObjectives,
    completedObjectivesCount,
  );

  const objectivesStatsActive = getObjectivesStatsActive(activeObjectives);

  const statsActive = [
    {
      name: i18n.t('statusIndicator.UNKNOWN.name'),
      value: objectivesStatsActive.noStatus,
      color: theme.color.neutral2,
    },
    {
      name: i18n.t('statusIndicator.BLOCKED.name'),
      value: objectivesStatsActive.blocked,
      color: theme.color.error,
    },
    {
      name: i18n.t('statusIndicator.AT_RISK.name'),
      value: objectivesStatsActive.atRisk,
      color: theme.color.alert,
    },
    {
      name: i18n.t('statusIndicator.ON_TRACK.name'),
      value: objectivesStatsActive.onTrack,
      color: theme.color.success,
    },
  ];

  const statDone = {
    name: i18n.t('statusIndicator.COMPLETED.name'),
    value: statDoneValue,
    color: theme.color.primary,
  };

  const hasStats = !!activeObjectives?.length;

  return {
    hasStats,
    statsActive,
    statDone,
  };
};

export const getObjectivesStatDone = (
  activeObjectives: Maybe<StrategyObjectivesObjective[]>,
  completedObjectivesCount: number,
) => {
  if (activeObjectives) {
    const statDone = (completedObjectivesCount / activeObjectives.length) * 100;

    return Math.round(statDone);
  } else {
    return 0;
  }
};

export const getObjectivesCompletedCount = (
  activeObjectives: Maybe<StrategyObjectivesObjective[]>,
) => {
  if (activeObjectives) {
    return activeObjectives.reduce<number>(
      (acc, cur) => (cur.currentObjectiveStatus?.complete ? acc + 1 : acc),
      0,
    );
  } else {
    return 0;
  }
};
