import { createContext } from 'react';

import type {
  MetricsListObjective,
  MetricsListMetric,
} from './MetricsListProvider.type';

export type MetricsListObjectivesContextValue = {
  allMetrics: Maybe<MetricsListMetric[]>;
  allMetricsActive: Maybe<MetricsListMetric[]>;
  allObjectives: Maybe<MetricsListObjective[]>;
  allObjectivesActiveWithMetricsActive: Maybe<MetricsListObjective[]>;
  filteredMetricsActive: Maybe<MetricsListMetric[]>;
  isMetricsListObjectivesLoading: boolean;
};

export const MetricsListObjectivesContext = createContext<
  MetricsListObjectivesContextValue | undefined
>(undefined);
