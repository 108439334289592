import cn from 'classnames';
import { memo } from 'react';

import styles from '../Stats.module.scss';
import type { Stat as StatType } from '../Stats.type';
import StatLine from '../StatLine';
import StatText from '../StatText';

type StatProps = {
  className?: string;
  stat: StatType;
};

const Stat = ({ stat, className }: StatProps) => (
  <div className={cn(styles.container, className)}>
    <div className={styles.statsTextContainer}>
      <StatText label={stat?.name} value={stat?.value} />
    </div>
    <div
      className={styles.statsLineChartContainer}
      title={`${stat.name}: ${stat.value}%`}
    >
      <StatLine
        title={stat.name}
        value={stat.value}
        color={stat.color}
        variant={'standalone'}
        key={`${stat.name}${stat.value}`}
      />
    </div>
  </div>
);

export default memo(Stat);
