import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import type { OrgInput } from 'types.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import Spinner from 'shared/spinner/Spinner';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import Dialog from 'shared/components/Dialog';

import { useUpdateOrgMutation } from './UpdateOrg.graphql.generated';
import type { OrgFormValues } from './OrgForm';
import OrgForm, { orgFormId } from './OrgForm';
import { useGetOrgForEditQuery } from './GetOrgForEdit.graphql.generated';
import OrgQuickFormFields from './OrgQuickFormFields';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const EditOrg = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const handleApolloError = useHandleApolloError();

  const { data } = useGetOrgForEditQuery({
    onError: handleApolloError,
    skip: !isOpen,
  });

  const [updateOrg] = useUpdateOrgMutation({ onError: handleApolloError });

  const org = data?.activeOrg;

  const initialValues: OrgFormValues | false = useMemo(
    () =>
      !!org && {
        name: org.displayName || '',
        acronym: org.displayNameAbbreviated || '',
        color: org.backgroundColorAsHex || '',
        lead: org.orgLead.email,
        industryName: org.industry?.name || '',
        industryDescription: org.industry?.description || '',
      },
    [org],
  );

  const handleSubmit = useCallback(
    async (input: OrgInput) => {
      const result = await updateOrg({ variables: { input } });

      const isSuccess = result.data?.updateOrg.id && !result.errors;

      if (isSuccess) {
        addToast({
          variant: 'success',
          children: t('org.edit.toast.success'),
        });

        onClose();
      }
    },
    [addToast, onClose, t, updateOrg],
  );

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t('org.edit.title')}
      confirmLabel={t('update')}
      cancelLabel={t('cancel')}
      formId={orgFormId}
    >
      {initialValues ? (
        <OrgForm initialValues={initialValues} onSubmit={handleSubmit}>
          <OrgQuickFormFields />
        </OrgForm>
      ) : (
        <Spinner.Circle />
      )}
    </Dialog>
  );
};

export default EditOrg;
