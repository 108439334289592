import type { AriaListBoxProps } from 'react-aria';
import { useListState } from 'react-stately';
import { useRef } from 'react';
import { useListBox } from 'react-aria';
import cn from 'classnames';
import type { ListState } from 'react-stately';
import type { SerializedStyles } from '@emotion/react';

import type { BaseListBoxItem, ListBoxRowComponent } from './ListBox.type';
import ListBoxSection from './ListBoxSection';
import ListBoxRowContainer from './ListBoxRowContainer';
import ListBoxRow from './ListBoxRow';
import styles from './ListBox.module.scss';
import { useListBoxCollectionChildren } from './useListBoxCollectionChildren';

type ListBoxProps<ListBoxItem extends BaseListBoxItem> = Pick<
  AriaListBoxProps<ListBoxItem>,
  'items' | 'disabledKeys' | 'selectionMode'
> & {
  Row?: ListBoxRowComponent<ListBoxItem>;
  className?: string;
  innerRef?: any;
  state?: ListState<ListBoxItem>;
  style?: SerializedStyles;
};

/**
 * Displays a list of options and allows a user to select one or more of them. Commonly used as a [selection component](https://react-spectrum.adobe.com/react-stately/selection.html) overlay.
 */
const ListBox = <ListBoxItem extends BaseListBoxItem>({
  items,
  disabledKeys,
  selectionMode = 'single',
  Row = ListBoxRow,
  state: stateProp,
  className,
  style,
}: ListBoxProps<ListBoxItem>) => {
  const collectionChildren = useListBoxCollectionChildren({ Row });

  const internalState = useListState({
    items,
    disabledKeys,
    selectionMode,
    children: collectionChildren,
  });
  const state = stateProp ?? internalState;

  const listElementRef = useRef<HTMLUListElement>(null);

  const { listBoxProps } = useListBox(
    {
      items,
      selectionMode,
      disabledKeys,
    },
    state,
    listElementRef,
  );

  return (
    <div className={cn(styles.listBox, className)} css={style}>
      <ul {...listBoxProps} className={styles.listBoxList} ref={listElementRef}>
        {[...state.collection].map((item) => {
          if (item.type === 'section') {
            return <ListBoxSection item={item} state={state} key={item.key} />;
          }
          return (
            <ListBoxRowContainer item={item} state={state} key={item.key} />
          );
        })}
      </ul>
    </div>
  );
};

export default ListBox;
