import type { ApolloError } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { datadogRum } from '@datadog/browser-rum';

import { useToasts } from 'shared/toast/useToasts';
import {
  getCurrentGraphQLError,
  getGraphQLErrorCode,
  getGraphqlErrorMessage,
} from 'shared/graphql/utils';

import { useHandleUnknownError } from './unknown.error';
import { useErrorPage } from './ErrorPage/useErrorPage';

const errorPageErrorCodes = ['NOT_FOUND', 'ACCESS_DENIED'];

const useHandleApolloError = () => {
  const { onError } = useErrorPage();
  const handleUnknownError = useHandleUnknownError();
  const { t } = useTranslation();
  const { addToast } = useToasts();

  return useCallback(
    (error: ApolloError) => {
      datadogRum.addError(error);

      if (error.networkError) {
        return handleUnknownError(error.networkError);
      }

      const graphqlError = getCurrentGraphQLError(error);
      const errorCode = getGraphQLErrorCode(graphqlError);

      if (!errorCode) {
        return handleUnknownError(error);
      }

      if (graphqlError && errorPageErrorCodes.includes(errorCode)) {
        onError(graphqlError);
        return;
      }

      const errorMessage = getGraphqlErrorMessage(t, graphqlError);

      addToast({
        variant: 'error',
        children: errorMessage,
      });
    },
    [addToast, handleUnknownError, onError, t],
  );
};

export default useHandleApolloError;
