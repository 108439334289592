import type { Stat } from './Stats.type';

export const calculateStatsSum = (stats: Array<Stat>) =>
  stats.reduce(
    (acc, { value }) => (Number.isInteger(value) ? acc + value : acc),
    0,
  );

export const getStatsPercentages = (stats: Array<Stat>) => {
  const statsWithPercentages = stats.map((stat) => ({
    ...stat,
    value: Math.round((stat.value / calculateStatsSum(stats)) * 100),
  }));

  // if statement below deals with a situation when summing rounded percentage numbers would yield less or more than 100% (eg. 47% + 26% + 26%)

  const percentagesSum = calculateStatsSum(statsWithPercentages);

  if (percentagesSum === 100) {
    return statsWithPercentages;
  } else {
    if (percentagesSum < 100) {
      const difference = 100 - percentagesSum;
      const values = statsWithPercentages.map(({ value }) => value);
      const maxValue = Math.max(...values);
      const index = values.indexOf(maxValue);
      const max = statsWithPercentages[index];

      return statsWithPercentages.map((stat) =>
        stat === max ? { ...stat, value: maxValue + difference } : stat,
      );
    } else if (percentagesSum > 100) {
      const difference = percentagesSum - 100;
      const values = statsWithPercentages.map(({ value }) => value);
      const maxValue = Math.max(...values);
      const index = values.indexOf(maxValue);
      const max = statsWithPercentages[index];

      return statsWithPercentages.map((stat) =>
        stat === max ? { ...stat, value: maxValue - difference } : stat,
      );
    } else {
      return statsWithPercentages;
    }
  }
};
