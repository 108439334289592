import type { TeamAdapter } from 'team/TeamAdapter';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';

import type { StrategyOperationalItem } from './StrategyBoardList.type';

export const getStrategyOperationalItemLink = (
  item: StrategyOperationalItem,
  params: { teamAdapter: TeamAdapter },
) => {
  const { teamAdapter } = params;

  switch (item.__typename) {
    case 'Objective':
      return objectiveRoutesPaths.root({
        params: {
          teamSlug: teamAdapter.toParam(),
          objectiveId: item.objectiveId,
        },
      });
    case 'Metric':
      return objectiveRoutesPaths.metrics.metric.root({
        params: {
          teamSlug: teamAdapter.toParam(),
          objectiveId: item.objectiveId,
          metricId: item.id,
        },
      });
    case 'Initiative':
      return objectiveRoutesPaths.initiatives.initiative.root({
        params: {
          teamSlug: teamAdapter.toParam(),
          objectiveId: item.objectiveId,
          initiativeId: item.id,
        },
      });
    default:
      return undefined;
  }
};
