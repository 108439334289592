import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSessionStorage } from 'react-use';

export default function useSearchParamState<T>(
  paramName: string,
  initialValue: T,
  storeToSession?: boolean,
  parseValue?: (serializedValue: string) => T,
  serializeValue?: (value: T) => string,
): [T, (value: T) => void] {
  const [sessionValue, setSessionValue] = useSessionStorage<string | undefined>(
    paramName,
    undefined,
  );

  const [isChanged, setIsChanged] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamValue = searchParams.get(paramName);

  const [value, setValue] = useState(() => {
    if (searchParamValue !== null) {
      return parseValue
        ? parseValue(searchParamValue)
        : (searchParamValue as T);
    }

    if (storeToSession && sessionValue !== undefined) {
      return parseValue ? parseValue(sessionValue) : (sessionValue as T);
    }

    return initialValue;
  });

  const onChange = useCallback((newValue: T) => {
    setValue(newValue);
    setIsChanged(true);
  }, []);

  const setSearhParamValue = useCallback(
    (serializedValue: string) => {
      if (value === undefined) {
        searchParams.delete(paramName);
      } else {
        searchParams.set(paramName, serializedValue);
      }

      setSearchParams(searchParams);
    },
    [paramName, searchParams, setSearchParams, value],
  );

  useEffect(() => {
    const serializedValue = serializeValue
      ? serializeValue(value)
      : (value as string);

    if (isChanged && serializedValue !== searchParamValue) {
      setSearhParamValue(serializedValue);
    }

    if (isChanged && storeToSession && serializedValue !== sessionValue) {
      setSessionValue(serializedValue);
    }
  }, [
    isChanged,
    paramName,
    searchParamValue,
    searchParams,
    serializeValue,
    sessionValue,
    setSearchParams,
    setSearhParamValue,
    setSessionValue,
    storeToSession,
    value,
  ]);

  return [value, onChange];
}
