import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';
import hexToRgba from 'hex-to-rgba';

import type { Size } from 'shared/types/size.type';
import { layoutContentMaxWidth } from 'shared/styles/sizes';

type ModalFooterProps = PropsWithChildren<{
  hasShadow: boolean;
  paddingSize: Extract<Size, 'small' | 'medium'>;
}>;

const StyledFooter = styled.footer<
  Pick<ModalFooterProps, 'paddingSize' | 'hasShadow'>
>`
  border-top: 1px solid ${(props) => props.theme.legacyColor.colorGallery};
  flex-shrink: 0;
  height: 5.5rem;
  inset: auto 0 0 0;
  padding: ${({ paddingSize }) =>
    paddingSize === 'small' ? '0 1.25em' : '0 2rem'};
  box-shadow: ${({ hasShadow, theme }) =>
    hasShadow
      ? `0 0 1.5rem .5rem ${hexToRgba(theme.color.black, 0.06)}`
      : 'none'};
`;

const StyledFooterContent = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  max-width: ${layoutContentMaxWidth};
`;

const ModalFooter = ({
  paddingSize,
  hasShadow,
  children,
}: ModalFooterProps) => (
  <StyledFooter paddingSize={paddingSize} hasShadow={hasShadow}>
    <StyledFooterContent>{children}</StyledFooterContent>
  </StyledFooter>
);

export default ModalFooter;
