import { useTranslation } from 'react-i18next';

import RespondCampaignButton from 'contribution/ContributionMyFollowUpsViewMore/RespondCampaignButton';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import Emoji from 'shared/components/Emoji';

import Description from './Description';
import Header from './Header';
import Icon from './Icon';
import Title from './Title';
import type { StrategyGuideFieldsFragment } from '../StrategyRoadmapProvider/StrategyRoadmapProvider.graphql.generated';

type Props = {
  campaign: StrategyGuideFieldsFragment['activeCampaigns'][0];
};

const CampaignGuide = ({ campaign }: Props) => {
  const { t } = useTranslation();

  const { activeOrg } = useActiveOrg();

  const team =
    campaign.orgUnit?.name || activeOrg.displayName || activeOrg.domainNames[0];

  const status = campaign.currentUserResponse?.status || 'NOT_STARTED';
  const description = t(`toolkit.toolPage.walkOfStrategy.invite.${status}`, {
    team,
  });

  return (
    <>
      <Header>
        <Icon>
          <Emoji icon={t('toolkit.tools.walkOfStrategy.symbol')} />
        </Icon>
        <Title>{t('toolkit.tools.walkOfStrategy.name')}</Title>
      </Header>
      <Description>{description}</Description>
      <RespondCampaignButton
        campaignId={campaign.id}
        variant={'outlined'}
        stage={campaign.state.stage}
        status={campaign.currentUserResponse?.status}
      />
    </>
  );
};

export default CampaignGuide;
