import styled from '@emotion/styled';

import type { User } from 'types.graphql.generated';
import UserAvatar from 'user/UserAvatar';
import Text from 'shared/components/Text';

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

type ContributionOthersCommitmentsListHeadingProps = {
  owner: Pick<User, 'id' | 'email' | 'displayName' | 'photoUrl' | 'isActive'>;
};

const ContributionOthersCommitmentsListHeading = ({
  owner,
}: ContributionOthersCommitmentsListHeadingProps) => (
  <Container>
    <UserAvatar user={owner} showTooltip={false} />
    <Text variant={'emphasis'}>{owner.displayName ?? owner.email}</Text>
  </Container>
);

export default ContributionOthersCommitmentsListHeading;
