import type { ListState } from 'react-stately';
import { createContext } from 'react';

import type { ActionStatusMultiSelectItem } from '../ActionStatusMultiSelect.type';

export type ActionStatusMultiSelectProviderContextValue = {
  items: ActionStatusMultiSelectItem[];
  state: ListState<ActionStatusMultiSelectItem>;
};

export const ActionStatusMultiSelectProviderContext =
  createContext<Maybe<ActionStatusMultiSelectProviderContextValue>>(undefined);
