import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import UserAvatar from 'user/UserAvatar/UserAvatar';
import Flex from 'shared/components/Flex';
import Tag from 'shared/components/Tag';
import UserActiveTag from 'user/UserActiveTag';

import type { ContributionUserContributionQuery } from '../ContributionUserContribution.graphql.generated';

const Name = styled.div`
  font-size: 1.571rem;
`;

const Email = styled.div`
  font-size: 0.857rem;
  color: ${(props) => props.theme.color.typoSecondary};
`;

type Props = {
  user: ContributionUserContributionQuery['user'];
};

const ContributionUserContributionUserDetails = ({ user }: Props) => {
  const { t } = useTranslation();

  return (
    <Flex alignItems={'center'} gap={8}>
      <UserAvatar user={user} size={'large'} showTooltip={false} />

      <Flex direction={'column'} gap={4}>
        <Flex alignItems={'center'} gap={12}>
          {user.displayName && user.displayName !== '' && (
            <Name>{user.displayName}</Name>
          )}

          <Flex gap={8}>
            {user.authorities
              .map((role) => t(`role.${role}`))
              .sort()
              .map((role) => (
                <Tag key={role}>{role}</Tag>
              ))}
            <UserActiveTag user={user} size={'regular'} />
          </Flex>
        </Flex>

        <Email>{user.email}</Email>
      </Flex>
    </Flex>
  );
};

export default ContributionUserContributionUserDetails;
