import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { trackPromise } from 'react-promise-tracker';

import type { StrategyWizardProps } from 'strategy/StrategyWizard';
import StrategyWizard, {
  strategyWizardPromiseTrackerArea,
} from 'strategy/StrategyWizard';
import Space from 'shared/components/Space';
import styles from 'strategy/EditStrategyWizard/EditStrategyWizard.module.scss';
import Tag from 'shared/components/__DEPRECATED__/Tag';
import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';
import type { Strategy } from 'types.graphql.generated';
import type { TeamAdapter } from 'team/TeamAdapter';
import { useToasts } from 'shared/toast/useToasts';
import useHandleApolloError from 'shared/errors/useHandleApolloError';

import { useCreateStrategyMutation } from './CreateStrategyWizard.graphql.generated';
import { resolveCreateStrategyInput } from './CreateStrategyWizard.utils';

export type CreateStrategyWizardProps = Pick<
  StrategyWizardProps,
  'isOpen' | 'onClose' | 'org'
> & {
  onSuccess: (strategyId: Strategy['id']) => void;
  teamAdapter: TeamAdapter;
};

const CreateStrategyWizard = ({
  isOpen,
  onClose,
  onSuccess,
  org,
  teamAdapter,
}: CreateStrategyWizardProps) => {
  const { t } = useTranslation();

  const { addToast } = useToasts();
  const handleApolloError = useHandleApolloError();

  const [createStrategy] = useCreateStrategyMutation({
    onError: handleApolloError,
  });

  const renderHeading: StrategyWizardProps['renderHeading'] = ({ name }) => (
    <Space isCentered={true} className={styles.heading}>
      {name
        ? `${t('strategy.strategyRoom.createStrategy')}: ${name}`
        : t('strategy.strategyRoom.createStrategy')}
      <Tag variant={'regular'}>{t('strategy.draft')}</Tag>
      <EditIcon
        title={'Unsaved changes'}
        className={cn(styles.headingIcon, {
          [styles.headingIconVisible]: true,
        })}
      />
    </Space>
  );

  const handleSubmit = useCallback<StrategyWizardProps['onSubmit']>(
    (values) => {
      trackPromise(
        createStrategy({
          variables: {
            input: resolveCreateStrategyInput({
              values,
              teamAdapter,
            }),
          },
          onCompleted: (data) => {
            addToast({
              id: 'createStrategyWizardSuccessToast',
              children: t('strategy.createSuccessToast'),
              variant: 'success',
            });
            onSuccess(data.addStrategy.id);
          },
        }),
        strategyWizardPromiseTrackerArea,
      );
    },
    [createStrategy, teamAdapter, addToast, t, onSuccess],
  );

  return (
    <StrategyWizard
      onSubmit={handleSubmit}
      isOpen={isOpen}
      onClose={onClose}
      org={org}
      onChangeStep={handleSubmit}
      renderHeading={renderHeading}
      triggerChangeStepOnlyWithValidData={true}
      triggerSubmitOnlyWithValidData={true}
      hasActiveCampaign={false}
    />
  );
};

export default CreateStrategyWizard;
