import cn from 'classnames';
import type { PropsWithChildren } from 'react';

import Text from 'shared/components/Text';
import Space from 'shared/components/Space';

import styles from './ContentPlaceholder.module.scss';

type ContentPlaceholderProps = PropsWithChildren<{
  className?: string;
}>;

const ContentPlaceholder = ({
  children,
  className,
}: ContentPlaceholderProps) => (
  <Space isCentered={true} className={cn(styles.contentPlaceholder, className)}>
    <Text isCentered={true} className={styles.contentPlaceholderText}>
      {children}
    </Text>
  </Space>
);

export default ContentPlaceholder;
