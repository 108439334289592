import { css, useTheme } from '@emotion/react';

import StatusMark from 'shared/status/StatusMark';
import type { Theme } from 'types.graphql.generated';
import Text from 'shared/components/Text';
import Flex from 'shared/components/Flex';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import { ReactComponent as ChevronRightIcon } from 'shared/static/icons/icon-chevron-right.svg';
import RadioIcon from 'shared/components/RadioIcon';

import type {
  LinkMetricDialogMetricFragment,
  LinkMetricDialogObjectiveFragment,
  LinkMetricDialogOrgUnitFragment,
} from './LinkMetricDialog.graphql.generated';

type Props = {
  objectives: LinkMetricDialogObjectiveFragment[];
  orgUnit?: Maybe<LinkMetricDialogOrgUnitFragment>;
  selectedMetric?: LinkMetricDialogMetricFragment;
  setSelectedMetric: (metric?: LinkMetricDialogMetricFragment) => void;
  theme?: Pick<Theme, 'id' | 'name'>;
};

const MetricSelectionSection = ({
  objectives,
  orgUnit,
  theme,
  selectedMetric,
  setSelectedMetric,
}: Props) => {
  const emotionTheme = useTheme();
  const { activeOrg } = useActiveOrg();

  const filteredObjectives = objectives.filter(
    (objective) =>
      objective.orgUnit?.id === orgUnit?.id &&
      objective.theme?.id === theme?.id &&
      objective.metrics.length > 0,
  );

  if (filteredObjectives.length === 0) return null;

  const orgUnitName = orgUnit ? orgUnit.name : activeOrg.displayName;
  const themeName = theme ? theme.name : 'no theme';

  return (
    <Flex direction={'column'} gap={'0.5rem'}>
      <Text isUppercase={true} size={'tiny'} isBold={true}>
        {orgUnitName}
        {' / '}
        {themeName}
      </Text>

      {filteredObjectives.flatMap((objective) =>
        objective.metrics.map((metric) => (
          <Flex
            key={metric.id}
            gap={'0.5rem'}
            css={css``}
            onClick={() => setSelectedMetric(metric)}
          >
            <div css={css({ marginTop: -1.5 })}>
              <RadioIcon value={metric === selectedMetric} />
            </div>
            <div css={css({ marginTop: 6 })}>
              <StatusMark
                statusIndicator={metric.currentMetricStatus?.statusIndicator}
              />
            </div>
            {metric.name}
            <div css={css({ color: emotionTheme.color.neutral2 })}>
              <ChevronRightIcon width={12} css={css({ marginTop: -2 })} />
            </div>
            <div
              css={css({ color: emotionTheme.color.neutral2, minWidth: 100 })}
            >
              {objective.name}
            </div>
          </Flex>
        )),
      )}
    </Flex>
  );
};

export default MetricSelectionSection;
