import { memo, useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Space from 'shared/components/Space';
import Text from 'shared/components/Text';
import StatusMarks from 'shared/status/StatusMarks';

import type {
  ThemeChipObjectiveStatus,
  ThemeChipTheme,
} from './ThemeChip.type';
import styles from './ThemeChip.module.scss';

export type ThemeChipProps = {
  className?: string;
  objectiveStatuses?: ThemeChipObjectiveStatus[];
  onClick?: (theme?: ThemeChipTheme) => void;
  theme?: ThemeChipTheme;
};

const ThemeChip = ({
  className,
  objectiveStatuses = [],
  onClick,
  theme,
}: ThemeChipProps) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    onClick?.(theme);
  }, [onClick, theme]);

  return (
    <Space onClick={handleClick} className={cn(styles.themeChip, className)}>
      <Text ellipsis={true} className={styles.themeChipName}>
        {theme ? theme.name : t('theme.noTheme')}
      </Text>
      {objectiveStatuses?.length ? (
        <StatusMarks
          items={objectiveStatuses.map((objectiveStatus) => ({
            statusIndicator: objectiveStatus.statusIndicator,
            updateDateTime: objectiveStatus.auditRecord?.updateDateTime,
          }))}
        />
      ) : null}
    </Space>
  );
};

export default memo(ThemeChip);
