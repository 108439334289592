import { useDefinedContext } from 'shared/utils/context.utils';

import EditStrategyWizardProvider from './EditStrategyWizardProvider';
import { EditStrategyWizardProviderContext } from './EditStrategyWizardProvider.context';

export const useEditStrategyWizard = () =>
  useDefinedContext(EditStrategyWizardProviderContext, {
    hookName: useEditStrategyWizard.name,
    providerName: EditStrategyWizardProvider.name,
  });
