import { createContext } from 'react';

import type { InsightReportSummaryProviderReportQuery } from './InsightReportSummaryProvider.graphql.generated';

export type InsightReportSummaryProviderContextValue = {
  report: InsightReportSummaryProviderReportQuery['report'];
};

export const InsightReportSummaryProviderContext = createContext<
  InsightReportSummaryProviderContextValue | undefined
>(undefined);
