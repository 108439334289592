import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';

import { ReactComponent as FlagIconBase } from 'shared/static/icons/icon-flag.svg';
import Text from 'shared/components/Text';
import SpaceBase from 'shared/components/Space';
import StatusMark from 'shared/status/StatusMark';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import UserAvatar from 'user/UserAvatar';
import Checkbox from 'shared/components/Checkbox';
import { useTeamAdapter } from 'team/TeamAdapter';

import type { StrategyRoomManageObjectivesObjectiveFragment } from './StrategyRoomThemesManageObjectives.graphql.generated';
import { useObjectiveSelection } from './ObjectiveSelctionProvider';

type StrategyRoomThemesListObjectiveRowProps = {
  objective: StrategyRoomManageObjectivesObjectiveFragment;
};

const ObjectiveSpace = styled(SpaceBase)`
  align-items: center;
`;

const ObjectiveCardSpace = styled(SpaceBase)`
  align-items: center;
  background: ${(props) => props.theme.color.white};
  border: 1px solid ${(props) => props.theme.color.strokeMedium};
  border-radius: 8px;
  display: flex;
  flex: 1;
  height: 100%;
  padding: 8px 16px;
`;

const NameSpace = styled(SpaceBase)`
  flex: 1;
`;

const FlagIcon = styled(FlagIconBase)`
  color: ${(props) => props.theme.color.neutral2};
  width: 16px;
`;

const Link = styled(RouterLink)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ObjectiveRow = ({
  objective,
}: StrategyRoomThemesListObjectiveRowProps) => {
  const { teamAdapter } = useTeamAdapter();
  const { isSelected, toggleSelection } = useObjectiveSelection();

  return (
    <ObjectiveSpace key={objective.id}>
      <Checkbox
        checked={isSelected(objective)}
        onChange={() => toggleSelection(objective)}
      />
      <ObjectiveCardSpace>
        <FlagIcon />
        <StatusMark
          statusIndicator={objective.currentObjectiveStatus?.statusIndicator}
          hasBorder={false}
        />
        <NameSpace size={'small'}>
          <Link
            to={objectiveRoutesPaths.root({
              params: {
                teamSlug: teamAdapter.toParam(),
                objectiveId: objective?.id,
              },
            })}
          >
            <Text variant={'emphasis'} ellipsis={true}>
              {objective.name}
            </Text>
          </Link>
        </NameSpace>
        <SpaceBase>
          <UserAvatar user={objective.owner} />
        </SpaceBase>
      </ObjectiveCardSpace>
    </ObjectiveSpace>
  );
};

export default ObjectiveRow;
