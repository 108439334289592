import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Markdown from 'markdown-to-jsx';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import AiLoadingIcon from 'ai/AiLoadingIcon';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import AiSophieIcon from 'ai/AiSophieIcon';

import { useStrategySummarySubscription } from './StrategySummary.graphql.generated';
import type { SummaryMessage } from './StrategySummarySidePanel';
import ChatBubble from './ChatBubble';

type Props = {
  setIsLoading: (isLoading: boolean) => void;
  summary: SummaryMessage;
};

const IconContainer = styled.div`
  align-self: flex-start;
  margin-top: 14px;
  width: 28px;
`;

export const Spacer = styled.div`
  flex-shrink: 0;
  width: 28px;
`;

const SummaryMessageItem = ({ summary, setIsLoading }: Props) => {
  const { activeOrg } = useActiveOrg();
  const [content, setContent] = useState('');
  const [isComplete, setIsComplete] = useState(false);

  const handleApolloError = useHandleApolloError();

  useEffect(() => {
    setIsLoading(true);
  }, [setIsLoading]);

  const { error } = useStrategySummarySubscription({
    variables: {
      languageLocaleCode: activeOrg.languageLocaleCode,
      numberOfCharacters: summary.numberOfCharacters,
      temperature: summary.temperature,
      objectiveIds: summary.objectiveIds,
      metricIds: summary.metricIds,
      initiativeIds: summary.initiativeIds,
      actionIds: summary.actionIds,
      strategyIds: summary.strategyId ? [summary.strategyId] : undefined,
      hints: [...(summary.hints || []), 'Use markdown to format your response'],
    },
    onSubscriptionData: (result) => {
      setContent(
        (prevData) => prevData + result.subscriptionData.data?.aiSummaryStream,
      );
    },
    onSubscriptionComplete: () => {
      setIsComplete(true);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (error) {
      handleApolloError(error);
      setIsLoading(false);
    }
  }, [error, handleApolloError, setIsLoading]);

  const hasContent = content !== '';
  const shouldShow = !error && (!isComplete || hasContent);

  if (!shouldShow) return null;

  return (
    <>
      <IconContainer>
        <AiSophieIcon />
      </IconContainer>
      <ChatBubble messageType={'SummaryMessage'}>
        {hasContent ? <Markdown>{content}</Markdown> : <AiLoadingIcon />}
      </ChatBubble>
      <Spacer />
    </>
  );
};

export default SummaryMessageItem;
