import { useTranslation } from 'react-i18next';

import Caption from 'shared/components/Caption/Caption';
import Flex from 'shared/components/Flex';

import type { InitiativeOverviewInitiativeStatusFragment } from '../InitiativeOverviewProvider/InitiativeOverviewProvider.graphql.generated';
import InitiativeOverviewMilestoneStatusSectionItem from './InitiativeOverviewMilestoneStatusSectionItem/InitiativeOverviewMilestoneStatusSectionItem';

type InitiativeOverviewMilestoneStatusSectionProps = {
  milestoneStatuses: InitiativeOverviewInitiativeStatusFragment['milestoneStatuses'];
};

const InitiativeOverviewMilestoneStatusSection = ({
  milestoneStatuses,
}: InitiativeOverviewMilestoneStatusSectionProps) => {
  const { t } = useTranslation();

  if (milestoneStatuses.length === 0) return null;

  return (
    <Flex direction={'column'} gap={8}>
      <Caption size={'C2'} color={'secondary'}>
        {t('initiative.initiativeInsights.recentInsights.milestones.heading')}
      </Caption>
      {milestoneStatuses.map((milestoneStatus) => (
        <InitiativeOverviewMilestoneStatusSectionItem
          key={milestoneStatus.id}
          milestoneStatus={milestoneStatus}
        />
      ))}
    </Flex>
  );
};

export default InitiativeOverviewMilestoneStatusSection;
