import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserLimitedProfileFragmentDoc } from '../../user/userProfile.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WalkOfStrategyCampaignsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type WalkOfStrategyCampaignsQuery = { __typename: 'Query', allCampaigns: { __typename: 'CampaignConnection', edges: Array<{ __typename: 'CampaignEdge', node: { __typename: 'Campaign', id: string, name: string, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction> } | undefined, completionRate: { __typename: 'CampaignCompletionRate', percentDone: number }, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined }, state: { __typename: 'CampaignState', stage: Types.CampaignStage }, owner: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } } }> } };


export const WalkOfStrategyCampaignsDocument = gql`
    query WalkOfStrategyCampaigns {
  allCampaigns {
    edges {
      node {
        id
        name
        orgUnit {
          id
          name
          currentUserAuthorizedActions
        }
        completionRate {
          percentDone
        }
        timeLine {
          startDate
          endDate
        }
        state {
          stage
        }
        owner {
          ...userLimitedProfile
        }
      }
    }
  }
}
    ${UserLimitedProfileFragmentDoc}`;

/**
 * __useWalkOfStrategyCampaignsQuery__
 *
 * To run a query within a React component, call `useWalkOfStrategyCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalkOfStrategyCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalkOfStrategyCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWalkOfStrategyCampaignsQuery(baseOptions?: Apollo.QueryHookOptions<WalkOfStrategyCampaignsQuery, WalkOfStrategyCampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WalkOfStrategyCampaignsQuery, WalkOfStrategyCampaignsQueryVariables>(WalkOfStrategyCampaignsDocument, options);
      }
export function useWalkOfStrategyCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WalkOfStrategyCampaignsQuery, WalkOfStrategyCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WalkOfStrategyCampaignsQuery, WalkOfStrategyCampaignsQueryVariables>(WalkOfStrategyCampaignsDocument, options);
        }
export type WalkOfStrategyCampaignsQueryHookResult = ReturnType<typeof useWalkOfStrategyCampaignsQuery>;
export type WalkOfStrategyCampaignsLazyQueryHookResult = ReturnType<typeof useWalkOfStrategyCampaignsLazyQuery>;
export type WalkOfStrategyCampaignsQueryResult = Apollo.QueryResult<WalkOfStrategyCampaignsQuery, WalkOfStrategyCampaignsQueryVariables>;