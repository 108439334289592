import type { MenuProps } from 'react-select';
import { components } from 'react-select';
import { css } from '@emotion/react';

type SelectMenuProps = MenuProps;

const SelectMenu = ({ ...props }: SelectMenuProps) => (
  <components.Menu {...props} css={css({ zIndex: '2 !important' })} />
);

export default SelectMenu;
