import { Waypoint } from 'react-waypoint';
import type { PropsWithChildren } from 'react';

import { useScrollspy } from '../ScrollspyProvider';

type ScrollspyWaypointProps = PropsWithChildren<{
  bottomOffset?: number | string;
  className?: string;
  id: string;
  topOffset?: number | string;
}>;

const ScrollspyWaypoint = ({
  id,
  topOffset,
  bottomOffset = '50%',
  children,
  className,
}: ScrollspyWaypointProps) => {
  const { setActiveWaypointId } = useScrollspy();

  return (
    <Waypoint
      onEnter={() => {
        setActiveWaypointId(id);
      }}
      bottomOffset={bottomOffset}
      topOffset={topOffset}
      fireOnRapidScroll={true}
    >
      <div id={id} className={className}>
        {children}
      </div>
    </Waypoint>
  );
};

export default ScrollspyWaypoint;
