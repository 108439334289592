import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SelectObjectiveInputProviderQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SelectObjectiveInputProviderQuery = { __typename: 'Query', allOrgUnits: { __typename: 'OrgUnitConnection', edges: Array<{ __typename: 'OrgUnitEdge', node: { __typename: 'OrgUnit', id: string, name?: string | undefined, parentOrgUnit?: { __typename: 'OrgUnit', id: string } | undefined } }> }, allObjectives: { __typename: 'ObjectiveConnection', edges: Array<{ __typename: 'ObjectiveEdge', node: { __typename: 'Objective', id: string, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, name?: string | undefined, theme?: { __typename: 'Theme', id: string, name?: string | undefined, orderNumber?: number | undefined } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined } }> } };

export type ObjectiveSelectObjectiveFragment = { __typename: 'Objective', id: string, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, name?: string | undefined, theme?: { __typename: 'Theme', id: string, name?: string | undefined, orderNumber?: number | undefined } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined };

export type ObjectiveSelectOrgUnitFragment = { __typename: 'OrgUnit', id: string, name?: string | undefined, parentOrgUnit?: { __typename: 'OrgUnit', id: string } | undefined };

export const ObjectiveSelectObjectiveFragmentDoc = gql`
    fragment objectiveSelectObjective on Objective {
  id
  currentUserAuthorizedActions
  name
  theme {
    id
    name
    orderNumber
  }
  orgUnit {
    id
  }
}
    `;
export const ObjectiveSelectOrgUnitFragmentDoc = gql`
    fragment objectiveSelectOrgUnit on OrgUnit {
  id
  name
  parentOrgUnit {
    id
  }
}
    `;
export const SelectObjectiveInputProviderDocument = gql`
    query SelectObjectiveInputProvider {
  allOrgUnits {
    edges {
      node {
        ...objectiveSelectOrgUnit
      }
    }
  }
  allObjectives(filter: {timeLine: {completeDateTime: {operator: IS_NULL}}}) {
    edges {
      node {
        ...objectiveSelectObjective
      }
    }
  }
}
    ${ObjectiveSelectOrgUnitFragmentDoc}
${ObjectiveSelectObjectiveFragmentDoc}`;

/**
 * __useSelectObjectiveInputProviderQuery__
 *
 * To run a query within a React component, call `useSelectObjectiveInputProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectObjectiveInputProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectObjectiveInputProviderQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectObjectiveInputProviderQuery(baseOptions?: Apollo.QueryHookOptions<SelectObjectiveInputProviderQuery, SelectObjectiveInputProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectObjectiveInputProviderQuery, SelectObjectiveInputProviderQueryVariables>(SelectObjectiveInputProviderDocument, options);
      }
export function useSelectObjectiveInputProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectObjectiveInputProviderQuery, SelectObjectiveInputProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectObjectiveInputProviderQuery, SelectObjectiveInputProviderQueryVariables>(SelectObjectiveInputProviderDocument, options);
        }
export type SelectObjectiveInputProviderQueryHookResult = ReturnType<typeof useSelectObjectiveInputProviderQuery>;
export type SelectObjectiveInputProviderLazyQueryHookResult = ReturnType<typeof useSelectObjectiveInputProviderLazyQuery>;
export type SelectObjectiveInputProviderQueryResult = Apollo.QueryResult<SelectObjectiveInputProviderQuery, SelectObjectiveInputProviderQueryVariables>;