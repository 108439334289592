import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash';

import { date } from 'shared/services/date.service';
import Card from 'shared/components/Card';
import Text from 'shared/components/Text';
import Space from 'shared/components/Space';
import { useTeamAdapter } from 'team/TeamAdapter';

import type { StrategyRoadmapSideModalProps } from '../StrategyRoadmapSideModal';
import { illustrations, backgroundColors } from '../StrategyRoadmap.static';
import StrategyRoadmapSideModal from '../StrategyRoadmapSideModal';
import type { StrategyRoadmapStrategyFieldsFragment } from '../StrategyRoadmapProvider/StrategyRoadmapProvider.graphql.generated';
import { getEditStrategyPath } from '../StrategyRoadmap.utils';

type StrategyRoadmapChoicesModalProps = {
  showEditButton: boolean;
  strategy: StrategyRoadmapStrategyFieldsFragment;
} & Pick<StrategyRoadmapSideModalProps, 'isOpen' | 'onClose'>;

const StrategyRoadmapChoicesModal = ({
  strategy,
  isOpen,
  onClose,
  showEditButton,
}: StrategyRoadmapChoicesModalProps) => {
  const { choices } = strategy;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { teamAdapter } = useTeamAdapter();

  const content = useMemo(
    () => (
      <Space direction={'vertical'}>
        {choices.map((choice) => (
          <Card
            border={'full'}
            padding={'small'}
            hasBackground={true}
            key={choice.id}
          >
            <Card.Heading>{choice.name}</Card.Heading>
            <Text variant={'emphasis'} whiteSpace={'preWrap'}>
              {choice.description}
            </Text>
          </Card>
        ))}
      </Space>
    ),
    [choices],
  );

  const updateDateTime = date.max(
    choices.map((choice) => choice.auditRecord.updateDateTime),
  );

  const handleEdit = useCallback(() => {
    onClose();
    navigate(getEditStrategyPath(teamAdapter, strategy, 'choices'));
  }, [onClose, navigate, teamAdapter, strategy]);

  return (
    <>
      <StrategyRoadmapSideModal
        top={{
          illustration: illustrations.choices,
          backgroundColor: backgroundColors.choices,
        }}
        heading={capitalize(t('strategy.choice_other'))}
        description={t('strategy.strategyRoadmap.choices.modal.description')}
        updateDateTime={updateDateTime}
        onEdit={showEditButton ? handleEdit : undefined}
        isOpen={isOpen}
        onClose={onClose}
      >
        {content}
      </StrategyRoadmapSideModal>
    </>
  );
};

export default StrategyRoadmapChoicesModal;
