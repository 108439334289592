import { useDefinedContext } from 'shared/utils/context.utils';

import { LayoutProviderContext } from './LayoutProvider.context';
import LayoutProvider from './LayoutProvider';

export const useLayout = () =>
  useDefinedContext(LayoutProviderContext, {
    hookName: useLayout.name,
    providerName: LayoutProvider.name,
  });
