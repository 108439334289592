import styled from '@emotion/styled';
import type {
  ComponentType,
  CSSProperties,
  PropsWithChildren,
  SVGProps,
} from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import Card from 'shared/components/Card';
import Space from 'shared/components/Space';
import { ReactComponent as MaximizeIcon } from 'shared/static/icons/icon-maximize.svg';
import IconButton from 'shared/components/IconButton';
import type { CardBorder } from 'shared/components/Card/Card.type';

type StrategyRoadmapCardProps = PropsWithChildren<{
  border?: CardBorder;
  className?: string;
  hasShadow?: boolean;
  heading: string;
  illustration?: Maybe<ComponentType<SVGProps<SVGSVGElement>>>;
  illustrationBackgroundColor: CSSProperties['backgroundColor'];
  onMaximize?: () => void;
}>;

const StyledCard = styled(Card)`
  max-height: 35rem;
  min-height: 11.25rem;
  position: relative;
`;

const ContentContainer = styled(Space)`
  width: 100%;
`;

const Content = styled(Space)`
  overflow: hidden;
`;

const IllustrationContainer = styled.div<{ backgroundColor?: string }>`
  align-self: flex-start;
  aspect-ratio: 1/1;
  border-radius: 12px;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.color.white};

  svg {
    padding: 10px;
    width: 140px;
    height: 140px;
  }
`;

const ChildrenContainer = styled(Space)`
  align-items: flex-start;
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 1.2rem;
  top: 1.2rem;
`;

const StrategyRoadmapCard = ({
  illustrationBackgroundColor,
  illustration: Illustration,
  className,
  heading,
  onMaximize,
  children,
  hasShadow,
  border,
}: StrategyRoadmapCardProps) => {
  const { t } = useTranslation();

  return (
    <StyledCard
      hasBackground={true}
      hasShadow={hasShadow}
      border={border}
      padding={'small'}
      className={className}
      contentStyle={css({ width: '100%', height: '100%' })}
    >
      <ContentContainer size={'custom'} value={'1.5rem'}>
        {Illustration ? (
          <IllustrationContainer backgroundColor={illustrationBackgroundColor}>
            <Illustration />
          </IllustrationContainer>
        ) : null}
        <Content direction={'vertical'} size={'medium'}>
          <Card.Heading
            hasMargin={false}
            ellipsis={true}
            containerCss={css`
              padding-right: 2rem;
            `}
          >
            {heading}
          </Card.Heading>
          <ChildrenContainer direction={'vertical'} size={'small'}>
            {children}
          </ChildrenContainer>
        </Content>
      </ContentContainer>
      {onMaximize ? (
        <StyledIconButton icon={MaximizeIcon} onClick={onMaximize}>
          {t('maximize')}
        </StyledIconButton>
      ) : null}
    </StyledCard>
  );
};

export default StrategyRoadmapCard;
