import { createContext } from 'react';

import type { ObjectiveOverviewQuery } from './ObjectiveOverviewProvider.graphql.generated';

export type ObjectiveOverviewContextValue = {
  isObjectiveOverviewLoading: boolean;
  objective: Maybe<ObjectiveOverviewQuery['objective']>;
};

export const ObjectiveOverviewContext = createContext<
  ObjectiveOverviewContextValue | undefined
>(undefined);
