import { AnimatePresence, motion } from 'framer-motion';

import type { ToastProps } from 'shared/toast/Toast';
import Toast from 'shared/toast/Toast';
import { slideFromLeftAnimation } from 'shared/animations/slide.animation';
import Space from 'shared/components/Space';

import styles from './ToastsLayout.module.scss';

type ToastsLayoutProps = {
  toasts: Array<ToastProps & { id: string }>;
};

const ToastsLayout = ({ toasts }: ToastsLayoutProps) => (
  <Space className={styles.container} direction={'vertical'} size={'medium'}>
    <AnimatePresence>
      {toasts.map(({ id, ...toast }) => (
        <motion.div {...slideFromLeftAnimation} key={id}>
          <Toast {...toast} />
        </motion.div>
      ))}
    </AnimatePresence>
  </Space>
);

export default ToastsLayout;
