import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';
import type { ContextMenuOnActionFn } from 'shared/components/ContextMenu';
import ContextMenu from 'shared/components/ContextMenu';
import { useRemoveInitiativeMilestone } from 'initiative/useRemoveInitiativeMilestone';
import type { Initiative, Milestone } from 'types.graphql.generated';
import type { StrategyElementWithAuthorizedActions } from 'user/ability/canPerformStrategyElementAction';

import styles from './MilestoneInsightContextMenu.module.scss';
import type { MilestoneInsightContextMenuItem } from './MilestoneInsightContextMenu.type';

type MilestoneInsightContextMenuProps = {
  initiative: Pick<Initiative, 'id'> & StrategyElementWithAuthorizedActions;
  milestone: Pick<Milestone, 'id'>;
};

const MilestoneInsightContextMenu = ({
  initiative,
  milestone,
}: MilestoneInsightContextMenuProps) => {
  const { t } = useTranslation();

  const { removeInitiativeMilestone, canRemoveInitiativeMilestone } =
    useRemoveInitiativeMilestone({
      milestone,
      initiative,
    });

  const items = useMemo<MilestoneInsightContextMenuItem[]>(
    () => [
      {
        icon: TrashIcon,
        id: 'remove',
        variant: 'danger',
        title: t('remove'),
      },
    ],
    [t],
  );

  const onAction = useCallback<
    ContextMenuOnActionFn<MilestoneInsightContextMenuItem['id']>
  >(
    (id) => {
      switch (id) {
        case 'remove': {
          removeInitiativeMilestone();
          break;
        }
      }
    },
    [removeInitiativeMilestone],
  );

  return canRemoveInitiativeMilestone ? (
    <ContextMenu<MilestoneInsightContextMenuItem>
      items={items}
      onAction={onAction}
      triggerClassName={styles.trigger}
    />
  ) : null;
};

export default MilestoneInsightContextMenu;
