import compact from 'lodash/compact';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';
import { ReactComponent as IconEdit } from 'shared/static/icons/icon-edit.svg';
import { ReactComponent as IconCheck } from 'shared/static/icons/icon-check.svg';
import { ReactComponent as IconBlock } from 'shared/static/icons/icon-block.svg';
import type { ContextMenuOnActionFn } from 'shared/components/ContextMenu';
import ContextMenu from 'shared/components/ContextMenu';
import type { User } from 'types.graphql.generated';
import { useUser } from 'user/UserProvider';
import useCanPerformOrgAction from 'user/ability/useCanPerformOrgAction';
import useCanPerformOrgUnitAction from 'user/ability/useCanPerformOrgUnitAction';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';

import type { TeamMembersListViewContextMenuItem } from './TeamMembersListViewContextMenu.type';

type TeamMembersListViewContextMenuProps = {
  onAction: ContextMenuOnActionFn<TeamMembersListViewContextMenuItem['id']>;
  showRemove: boolean;
  user: Pick<User, 'id' | 'isActive'>;
};

const TeamMembersListViewContextMenu = ({
  user,
  showRemove,
  onAction,
}: TeamMembersListViewContextMenuProps) => {
  const { t } = useTranslation();
  const { user: currentUser } = useUser();
  const { orgUnit } = useOrgUnit();

  const isOrgUnit = !!orgUnit;

  const canManageOrg = useCanPerformOrgAction('WRITE_ACCOUNT');
  const isCurrentUser = user.id === currentUser.id;
  const canManageUser = canManageOrg || isCurrentUser;
  const canManageOrgUnit = useCanPerformOrgUnitAction('WRITE');

  const items = useMemo<TeamMembersListViewContextMenuItem[]>(
    () =>
      compact([
        canManageUser && {
          icon: IconEdit,
          id: 'editProfile',
          title: t('userMenu.editProfile'),
        },
        canManageUser &&
          !isCurrentUser &&
          user.isActive &&
          !isOrgUnit && {
            icon: IconBlock,
            id: 'deactivate',
            title: t('userMenu.deactivate.menuItem'),
          },
        canManageUser &&
          !user.isActive && {
            icon: IconCheck,
            id: 'activate',
            title: t('userMenu.activate.menuItem'),
          },
        canManageOrgUnit &&
          isOrgUnit &&
          showRemove && {
            id: 'remove',
            title: t('remove'),
            variant: 'danger',
            icon: TrashIcon,
          },
      ]),
    [
      canManageOrgUnit,
      canManageUser,
      isCurrentUser,
      isOrgUnit,
      showRemove,
      t,
      user.isActive,
    ],
  );

  return (
    <ContextMenu<TeamMembersListViewContextMenuItem>
      onAction={onAction}
      items={items}
    />
  );
};

export default TeamMembersListViewContextMenu;
