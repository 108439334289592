import styled from '@emotion/styled';
import { useState, type FocusEventHandler } from 'react';
import { TextArea as AriaTextArea } from 'react-aria-components';

const Container = styled.div<{ $hasError: boolean; $isFocused: boolean }>`
  border: 1px solid
    ${(props) =>
      props.$isFocused
        ? props.theme.color.black
        : props.$hasError
        ? props.theme.color.error
        : props.theme.color.strokeDark};
  border-radius: 12px;
  overflow: hidden;
  box-shadow: ${(props) =>
    props.$isFocused ? `0 0 0 2px ${props.theme.color.primary}` : 'none'};

  &[aria-invalid='true'] {
    background-color: ${(props) => props.theme.color.errorLight};
  }
`;

const StyledTextArea = styled(AriaTextArea)`
  border: none;
  width: 100%;
  padding: 0.75rem;
  resize: none;
  margin-bottom: -6px;

  &:focus-visible {
    outline: none;
  }
`;

type Props = {
  hasError?: boolean;
  id?: string;
  maxLength?: number;
  name?: string;
  onBlur?: FocusEventHandler;
  onChange: (value: string) => void;
  placeholder?: string;
  value?: string;
};

const TextArea = ({
  id,
  name,
  value,
  placeholder,
  maxLength,
  hasError = false,
  onChange,
  onBlur,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Container $isFocused={isFocused} $hasError={hasError}>
      <StyledTextArea
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        aria-invalid={!!hasError}
        onChange={(event) => onChange(event.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={(event) => {
          setIsFocused(false);
          onBlur?.(event);
        }}
      />
    </Container>
  );
};

export default TextArea;
