import { createContext } from 'react';

import type { FormRef } from './FormRefsProvider.type';

export type FormRefsProviderContextValue = {
  addFormRef: (formId: string, formRef: any) => void;
  deleteFormRef: (formId: string) => void;
  formRefs: Record<string, FormRef>;
};

export const FormRefsProviderContext = createContext<
  FormRefsProviderContextValue | undefined
>(undefined);
