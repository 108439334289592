import { Navigate } from 'react-router-dom';

import { teamRoutesPaths } from 'team/team.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';

const NavigateToTeamInsightsOverview = () => {
  const { teamAdapter } = useTeamAdapter();

  const to = teamRoutesPaths.insights.overview({
    params: {
      teamSlug: teamAdapter.toParam(),
    },
  });

  return <Navigate to={to} replace={true} />;
};

export default NavigateToTeamInsightsOverview;
