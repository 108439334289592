import type { PartialDeep } from 'type-fest';
import type { ApolloCache, MutationUpdaterFunction } from '@apollo/client';

import { date } from 'shared/services/date.service';
import type { StrategyWizardValues } from 'strategy/StrategyWizard';
import { resolveInitialValue as resolveTimelineFieldInitialValue } from 'shared/components/TimelineField';
import type {
  StrategyBehaviorSetGroupInput,
  StrategyBehaviorSetInput,
  StrategyItem,
  StrategyItemInput,
  StrategyItemListInput,
} from 'types.graphql.generated';
import type { StrategyItemFormValues } from 'strategy/StrategyItemForm';
import type { StrategyItemListFormValues } from 'strategy/StrategyItemListForm';
import { resolveListInput } from 'shared/graphql/utils';

import type { EditStrategyWizardProviderContextValue } from './EditStrategyWizardProvider';
import type {
  EditStrategyMutation,
  EditStrategyMutationVariables,
} from './EditStrategyWizard.graphql.generated';

const resolveStrategyItemInitialValues = (
  strategyItem?: PartialDeep<StrategyItem>,
): StrategyItemFormValues => ({
  id: strategyItem?.id,
  name: strategyItem?.name || '',
  description: strategyItem?.description || '',
  owner: strategyItem?.owner?.email || '',
});

const resolveStrategyItemListInitialValues = (
  strategyItemList: Array<PartialDeep<StrategyItem>>,
): StrategyItemListFormValues => ({
  items: strategyItemList.map((item) => ({
    id: item.id,
    name: item.name || '',
    description: item.description || '',
    owner: item.owner?.email || '',
    symbol: item.symbol || '',
  })),
});

export const resolveInitialValues = (
  strategy: EditStrategyWizardProviderContextValue['strategy'],
): StrategyWizardValues => ({
  name: {
    name: strategy.name || '',
    timeline: resolveTimelineFieldInitialValue(strategy.timeLine),
  },
  mission: resolveStrategyItemInitialValues(strategy.mission),
  vision: resolveStrategyItemInitialValues(strategy.vision),
  goals: resolveStrategyItemListInitialValues(strategy.goals),
  choices: resolveStrategyItemListInitialValues(strategy.choices),
  values: resolveStrategyItemListInitialValues(strategy.values),
  behaviors: {
    groups: strategy.behaviors.map((behavior) => ({
      id: behavior.id,
      title: behavior.name || '',
      description: behavior.description || '',
      items: behavior.behaviorSetGroups.map((value) => ({
        id: value.id,
        name: value.name || '',
        description: value.description || '',
      })),
    })),
  },
});

const resolveStrategyItemInput = (
  values: Maybe<StrategyItemFormValues>,
  previousItem: Maybe<PartialDeep<StrategyItem>>,
): StrategyItemInput => ({
  name: values?.name,
  description: values?.description,
  owner: values?.owner
    ? { emailToSet: values.owner }
    : previousItem?.owner
    ? { idToRemove: previousItem.owner.id }
    : undefined,
});

// todo use resolveListInput from graphql.utils for resolving list input
const resolveStrategyItemListInput = (params: {
  previousStrategyItems: Array<PartialDeep<StrategyItem>>;
  values: StrategyItemListFormValues;
}): StrategyItemListInput => {
  const { values, previousStrategyItems } = params;
  const idsToDelete = previousStrategyItems
    .filter(
      (previousStrategyItem) =>
        !values.items.some(
          (valuesStrategyItem) =>
            valuesStrategyItem.id === previousStrategyItem.id,
        ),
    )
    .map((item) => item.id!);
  const { add, update } = values.items.reduce<{
    add: StrategyItemInput[];
    update: StrategyItemInput[];
  }>(
    (accumulator, item) => {
      const previousOwnerId = item.id
        ? previousStrategyItems.find(
            (previousItem) => previousItem.id === item.id,
          )?.owner?.id
        : undefined;

      const baseInput = {
        name: item.name,
        description: item.description,
        owner: item.owner
          ? { emailToSet: item.owner }
          : previousOwnerId
          ? { idToRemove: previousOwnerId }
          : undefined,
        ...(item.symbol && {
          symbol: item.symbol,
        }),
      };

      return item.id
        ? {
            ...accumulator,
            update: [
              ...accumulator.update,
              { idToUpdate: item.id, ...baseInput },
            ],
          }
        : { ...accumulator, add: [...accumulator.add, baseInput] };
    },
    { add: [], update: [] },
  );
  return {
    idsToDelete,
    update,
    add,
  };
};

export const resolveEditStrategyInput = (params: {
  strategy: EditStrategyWizardProviderContextValue['strategy'];
  values: StrategyWizardValues;
}): {
  behaviors: EditStrategyMutationVariables['input']['behaviors'];
  choices: StrategyItemListInput;
  goals: StrategyItemListInput;
  idToUpdate: string;
  mission: StrategyItemInput;
  name: string | undefined;
  timeLine: { endDate: string | any; startDate: string | any } | undefined;
  values: StrategyItemListInput;
  vision: StrategyItemInput;
} => {
  const { strategy, values } = params;
  return {
    idToUpdate: strategy.id,
    name: values.name?.name,
    timeLine: {
      startDate: values.name?.timeline.startDate
        ? date.format(values.name.timeline.startDate, 'yyyy-MM-dd')
        : null,
      endDate: values.name?.timeline.endDate
        ? date.format(values.name.timeline.endDate, 'yyyy-MM-dd')
        : null,
    },
    mission: resolveStrategyItemInput(values.mission, strategy.mission),
    vision: resolveStrategyItemInput(values.vision, strategy.vision),
    goals: resolveStrategyItemListInput({
      previousStrategyItems: strategy.goals,
      values: values.goals!,
    }),
    choices: resolveStrategyItemListInput({
      previousStrategyItems: strategy.choices,
      values: values.choices!,
    }),
    values: resolveStrategyItemListInput({
      previousStrategyItems: strategy.values,
      values: values.values!,
    }),
    behaviors: resolveListInput({
      previousItems: strategy.behaviors,
      newItems: values.behaviors?.groups || [],
      resolveInput: (group): StrategyBehaviorSetInput => {
        const currentBehavior = strategy.behaviors.find(
          (behavior) => behavior.id === group.id,
        );
        const behaviorSetGroupsInput = resolveListInput({
          previousItems: currentBehavior?.behaviorSetGroups || [],
          newItems: group.items,
          resolveInput: (groupItem): StrategyBehaviorSetGroupInput => ({
            name: groupItem.name,
            description: groupItem.description,
          }),
        });
        return {
          name: group.title,
          description: group.description,
          behaviorSetGroupsInput,
        };
      },
    }),
  };
};

export const modifyStrategyCache: MutationUpdaterFunction<
  EditStrategyMutation,
  EditStrategyMutationVariables,
  unknown,
  ApolloCache<unknown>
> = (cache, { data }, { variables }) => {
  if (data && variables) {
    cache.modify({
      id: cache.identify({
        __typename: 'Strategy',
        id: variables!.input.idToUpdate,
      }),
      fields: {
        name: () => data.updateStrategy.name,
        mission: () => data.updateStrategy.mission,
        vision: () => data.updateStrategy.vision,
        goals: () => data.updateStrategy.goals,
        choices: () => data.updateStrategy.choices,
        behaviors: () => data.updateStrategy.behaviors,
        values: () => data.updateStrategy.values,
      },
    });
  }
};
