import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddMetricMutationVariables = Types.Exact<{
  input: Types.MetricInput;
}>;


export type AddMetricMutation = { __typename: 'Mutation', addMetric: { __typename: 'Metric', id: string, name?: string | undefined, objective?: { __typename: 'Objective', id: string } | undefined } };


export const AddMetricDocument = gql`
    mutation AddMetric($input: MetricInput!) {
  addMetric(input: $input) {
    id
    name
    objective {
      id
    }
  }
}
    `;
export type AddMetricMutationFn = Apollo.MutationFunction<AddMetricMutation, AddMetricMutationVariables>;

/**
 * __useAddMetricMutation__
 *
 * To run a mutation, you first call `useAddMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMetricMutation, { data, loading, error }] = useAddMetricMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMetricMutation(baseOptions?: Apollo.MutationHookOptions<AddMetricMutation, AddMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMetricMutation, AddMetricMutationVariables>(AddMetricDocument, options);
      }
export type AddMetricMutationHookResult = ReturnType<typeof useAddMetricMutation>;
export type AddMetricMutationResult = Apollo.MutationResult<AddMetricMutation>;
export type AddMetricMutationOptions = Apollo.BaseMutationOptions<AddMetricMutation, AddMetricMutationVariables>;