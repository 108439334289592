import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useListState } from 'react-stately';
import { useTranslation } from 'react-i18next';

import { useListBoxCollectionChildren } from 'shared/components/ListBox';
import type { MultiSelectProps } from 'shared/components/MultiSelect';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';

import PriorityMultiSelectListBoxRow from '../PriorityMultiSelectListBoxRow';
import type { PriorityMultiSelectProviderContextValue } from './PriorityMultiSelectProvider.context';
import { PriorityMultiSelectProviderContext } from './PriorityMultiSelectProvider.context';
import { getItems } from './PriorityMultiSelectProvider.utils';

type PriorityMultiSelectProviderProps = PropsWithChildren<
  Pick<MultiSelectProps, 'onSelectionChange' | 'selectedKeys'>
>;

const PriorityMultiSelectProvider = ({
  children,
  onSelectionChange,
  selectedKeys,
}: PriorityMultiSelectProviderProps) => {
  const { t } = useTranslation();
  const { priorities, isPrioritiesLoading } = useStrategyProfilePriorities();

  const items = useMemo(() => getItems(t, priorities), [priorities, t]);

  const collectionChildren = useListBoxCollectionChildren({
    Row: PriorityMultiSelectListBoxRow,
  });
  const state = useListState({
    items,
    selectionMode: 'multiple',
    children: collectionChildren,
    selectedKeys,
    onSelectionChange,
  });

  const contextValue = useMemo<PriorityMultiSelectProviderContextValue>(
    () => ({ state, items, hasPriorities: !!priorities }),
    [state, items, priorities],
  );

  if (isPrioritiesLoading) return null;

  return (
    <PriorityMultiSelectProviderContext.Provider value={contextValue}>
      {children}
    </PriorityMultiSelectProviderContext.Provider>
  );
};

export default PriorityMultiSelectProvider;
