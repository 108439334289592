import styles from './MetricInsightsChartChip.module.scss';

type MetricInsightsChartChipProps = {
  label: string;
  value: Maybe<number>;
};

const MetricInsightsChartChip = ({
  label,
  value,
}: MetricInsightsChartChipProps) => (
  <div className={styles.container}>
    <span className={styles.label}>{label}</span>
    <span className={styles.value}>{value}</span>
  </div>
);

export default MetricInsightsChartChip;
