import type { Duration as DurationType } from 'date-fns';
import { Trans } from 'react-i18next';
import type { Merge } from 'type-fest';

import type { TextProps } from 'shared/components/Text';
import Text from 'shared/components/Text';

type DurationProps = Merge<
  TextProps,
  {
    duration: DurationType;
  }
>;

const Duration = ({ duration, className, ...restProps }: DurationProps) => (
  <Text variant={'light'} className={className} whiteSpace={'noWrap'}>
    <Trans i18nKey={'duration.value'} values={duration}>
      <Text variant={'emphasis'} {...restProps} />
    </Trans>
  </Text>
);

export default Duration;
