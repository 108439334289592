import styled from '@emotion/styled';
import type { MouseEvent, PropsWithChildren } from 'react';
import { useCallback } from 'react';

import Tooltip from '../Tooltip';

const Button = styled.span`
  margin-left: 3px;

  padding: 2px 5px;
  border-radius: 5px;

  cursor: pointer;

  svg {
    margin-top: -2px;
    width: 10px;
    height: 10px;
  }

  :hover {
    background-color: ${(props) => props.theme.color.white};
  }
`;

type Props = PropsWithChildren<{
  onClick: () => void;
  tooltip?: string;
}>;

const SideCardItemButton = ({ tooltip, onClick, children }: Props) => {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLSpanElement>) => {
      event.stopPropagation();
      onClick();
    },
    [onClick],
  );

  if (tooltip) {
    return (
      <Tooltip content={tooltip}>
        <Button onClick={handleClick}>{children}</Button>
      </Tooltip>
    );
  }

  return <Button onClick={handleClick}>{children}</Button>;
};

export default SideCardItemButton;
