import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UseDeleteReportDeleteReportMutationVariables = Types.Exact<{
  reportId: Types.Scalars['ID'];
}>;


export type UseDeleteReportDeleteReportMutation = { __typename: 'Mutation', deleteReports: boolean };


export const UseDeleteReportDeleteReportDocument = gql`
    mutation UseDeleteReportDeleteReport($reportId: ID!) {
  deleteReports(idsToDelete: [$reportId])
}
    `;
export type UseDeleteReportDeleteReportMutationFn = Apollo.MutationFunction<UseDeleteReportDeleteReportMutation, UseDeleteReportDeleteReportMutationVariables>;

/**
 * __useUseDeleteReportDeleteReportMutation__
 *
 * To run a mutation, you first call `useUseDeleteReportDeleteReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUseDeleteReportDeleteReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [useDeleteReportDeleteReportMutation, { data, loading, error }] = useUseDeleteReportDeleteReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useUseDeleteReportDeleteReportMutation(baseOptions?: Apollo.MutationHookOptions<UseDeleteReportDeleteReportMutation, UseDeleteReportDeleteReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UseDeleteReportDeleteReportMutation, UseDeleteReportDeleteReportMutationVariables>(UseDeleteReportDeleteReportDocument, options);
      }
export type UseDeleteReportDeleteReportMutationHookResult = ReturnType<typeof useUseDeleteReportDeleteReportMutation>;
export type UseDeleteReportDeleteReportMutationResult = Apollo.MutationResult<UseDeleteReportDeleteReportMutation>;
export type UseDeleteReportDeleteReportMutationOptions = Apollo.BaseMutationOptions<UseDeleteReportDeleteReportMutation, UseDeleteReportDeleteReportMutationVariables>;