import type { TypePolicies } from '@apollo/client';

import { readDateField } from 'shared/graphql/utils';

export const toolkitTypePolicies: TypePolicies = {
  Campaign: {
    merge: true,
    fields: {
      deadline: {
        read: readDateField,
      },
    },
  },
  OneThingCampaign: {
    merge: true,
    fields: {
      deadline: {
        read: readDateField,
      },
    },
  },
};
