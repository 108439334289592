import { useEffect, type PropsWithChildren } from 'react';

import useTimeout from 'shared/hooks/useTimeout';
import { ReactComponent as IconLoad } from 'shared/static/icons/icon-load.svg';

import { useChat } from './ChatProvider';
import MessageBubble from './MessageBubble';
import { useQuestions } from './QuestionsProvider.context';

type Props = PropsWithChildren<{
  bubbleWrap?: boolean;
  isChatSkipActive: boolean;
  waitForInput?: boolean;
}>;

const Message = ({
  waitForInput,
  bubbleWrap,
  isChatSkipActive,
  children,
}: Props) => {
  const { isReadOnly } = useQuestions();
  const { continueChat } = useChat();
  const { isReady: isTypingReady } = useTimeout(isChatSkipActive ? 100 : 500);
  const { isReady: isChatReady } = useTimeout(isChatSkipActive ? 300 : 1500);

  useEffect(() => {
    if (!isChatReady || waitForInput || isReadOnly) return;

    continueChat();
  }, [continueChat, isChatReady, isReadOnly, waitForInput]);

  if (!isTypingReady && !isReadOnly) {
    return (
      <MessageBubble source={'remote'}>
        <IconLoad />
      </MessageBubble>
    );
  }

  const shouldBubbleWrap =
    bubbleWrap === undefined ? typeof children === 'string' : bubbleWrap;

  if (shouldBubbleWrap) {
    return <MessageBubble source={'remote'}>{children}</MessageBubble>;
  } else {
    return <>{children}</>;
  }
};

export default Message;
