import { useTranslation } from 'react-i18next';

import Space from 'shared/components/Space';
import { usePageTitle } from 'shared/hooks/usePageTitle';
import { useUser } from 'user/UserProvider';
import Layout from 'shared/components/Layout';

import styles from './MyTeams.module.scss';
import MyTeamsHeader from './MyTeamsHeader';
import MyTeamsProvider, { useMyTeams } from './MyTeamsProvider';
import MyTeamsList from './MyTeamsList';

const MyTeams = () => {
  const { t } = useTranslation();

  const {
    user: { isInActiveOrg },
  } = useUser();

  const { org } = useMyTeams();

  const heading = t(isInActiveOrg ? 'team.myTeams.title' : 'team.team_other');

  usePageTitle(heading);

  return (
    <>
      <Layout.Header heading={heading} />
      <Layout.Content hasPadding={false} className={styles.layoutContent}>
        <Space direction={'vertical'}>
          <MyTeamsHeader />
          {org && <MyTeamsList orgKey={org.orgKey} />}
        </Space>
      </Layout.Content>
    </>
  );
};

const MyTeamsWithProvider = () => (
  <MyTeamsProvider>
    <MyTeams />
  </MyTeamsProvider>
);

export default MyTeamsWithProvider;
