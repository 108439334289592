import type {
  Action,
  Initiative,
  Metric,
  Objective,
} from 'types.graphql.generated';

type StrategyElement =
  | Pick<Objective, '__typename'>
  | Pick<Metric, '__typename'>
  | Pick<Initiative, '__typename'>
  | Pick<Action, '__typename'>;

const filterByType = <T extends StrategyElement>(
  items: T[],
  type: StrategyElement['__typename'],
) => items.filter((item) => item.__typename === type) as T[];

export const getObjectives = <T extends StrategyElement>(
  strategyElements: T[],
) => filterByType<T>(strategyElements, 'Objective');

export const getMetrics = <T extends StrategyElement>(strategyElements: T[]) =>
  filterByType<T>(strategyElements, 'Metric');

export const getInitiatives = <T extends StrategyElement>(
  strategyElements: T[],
) => filterByType<T>(strategyElements, 'Initiative');

export const getActions = <T extends StrategyElement>(strategyElements: T[]) =>
  filterByType<T>(strategyElements, 'Action');
