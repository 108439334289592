import { createContext } from 'react';

import type { ObjectiveInsightsObjective } from '../ObjectiveInsights.type';

export type ObjectiveInsightsContextValue = {
  isObjectiveLoading: boolean;
  objective: Maybe<ObjectiveInsightsObjective>;
};

export const ObjectiveInsightsContext = createContext<
  ObjectiveInsightsContextValue | undefined
>(undefined);
