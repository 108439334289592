import { useTranslation } from 'react-i18next';
import { isBefore } from 'date-fns';
import type { Duration as DurationType } from 'date-fns';
import { useInterval } from 'react-use';
import { useEffect, useState } from 'react';
import ms from 'ms';

import { date } from 'shared/services/date.service';
import Text from 'shared/components/Text/Text';
import type { ReportStage } from 'types.graphql.generated';
import Duration from 'shared/components/Duration/Duration';
import { ReactComponent as TimeStoperIcon } from 'shared/static/icons/icon-time-stoper.svg';
import { useInsightsReportWizard } from 'report/InsightReportWizard/InsightReportWizardProvider/useInsightsReportWizard';

import styles from './RequestStatusUpdatePreview.module.scss';
import type { RequestStatusUpdateFormReportQuery } from '../RequestStatusUpdateForm.graphql.generated';
import type { ReportPreviewItem } from './RequestStatusUpdatePreviewItem/RequestStatusUpdatePreviewItem';
import RequestStatusUpdatePreviewItem from './RequestStatusUpdatePreviewItem/RequestStatusUpdatePreviewItem';

type RequestStatusUpdatePreviewProps = {
  report: RequestStatusUpdateFormReportQuery['report'];
};

const RequestStatusUpdatePreview = ({
  report,
}: RequestStatusUpdatePreviewProps) => {
  const { t } = useTranslation();
  const [durationLeft, setDurationLeft] = useState<DurationType>(
    date.getDurationLeft(report.statusDeadlineTime),
  );

  useInterval(() => {
    setDurationLeft(date.getDurationLeft(report.statusDeadlineTime));
  }, ms('1 minute'));

  const cutoffTime = report.statusCutoffTime as Date;
  const reportStage = report.reportStage;

  const items = report.reportSelection.reportObjectives.reduce<
    ReportPreviewItem[]
  >(
    (result, reportObjective) => [
      ...result,
      reportObjective,
      ...reportObjective.reportMetrics,
      ...reportObjective.reportInitiatives,
      ...reportObjective.reportActions,
    ],
    [],
  );

  const includedItems = items.filter((item) =>
    isIncluded(item, cutoffTime, reportStage),
  );

  const staleItems = includedItems.filter((item) =>
    isStale(item, cutoffTime, reportStage),
  );

  const staleItemsCount = staleItems.length;
  const ownerCount = new Set(
    staleItems
      .map(getOwner)
      .filter(Boolean)
      .map((owner) => owner.id),
  ).size;

  const completedItemsCount = includedItems.length - staleItems.length;
  const completedItemsPercentage = Math.round(
    (completedItemsCount / includedItems.length) * 100,
  );

  const { setHasStatusRequests } = useInsightsReportWizard();

  const hasStatusRequests = includedItems.length > 0;

  useEffect(() => {
    setHasStatusRequests(hasStatusRequests);
  }, [hasStatusRequests, setHasStatusRequests]);

  return (
    <>
      {reportStage === 'STATUS_REQUESTED' && (
        <div className={styles.monitor}>
          <div className={styles.title}>
            <Text variant={'strong'}>
              {t('report.insightReportWizard.statusUpdate.monitor.title')}
            </Text>
            <span>{`${completedItemsCount}/${includedItems.length}`}</span>
            <span>{`(${completedItemsPercentage}%)`}</span>
          </div>
          <div className={styles.time}>
            <TimeStoperIcon />
            <Text className={styles.label}>
              {t('report.requestStatusUpdateForm.timeLeft')}
            </Text>
            <Duration duration={durationLeft} />
          </div>
        </div>
      )}

      <div className={styles.preview}>
        {includedItems.length === 0 ? (
          <>{t('report.requestStatusUpdateForm.emptyStatusRequests')}</>
        ) : (
          <>
            {report.reportStage !== 'STATUS_REQUESTED' && (
              <Text variant={'emphasis'} className={styles.notifiedMessage}>
                {t('report.requestStatusUpdateForm.requestOwners', {
                  count: ownerCount,
                })}{' '}
                {t('report.requestStatusUpdateForm.requestItems', {
                  count: staleItemsCount,
                })}
                {':'}
              </Text>
            )}

            <div className={styles.table}>
              {includedItems.map((reportItem) => (
                <RequestStatusUpdatePreviewItem
                  key={`${reportItem.__typename}:${reportItem.id}`}
                  reportStage={report.reportStage}
                  reportItem={reportItem}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RequestStatusUpdatePreview;

const isIncluded = (
  reportItem: ReportPreviewItem,
  cuttoffTime: Date,
  reportStage?: ReportStage,
) => {
  if (reportStage === 'STATUS_REQUESTED') {
    return !!reportItem.statusRequest;
  }

  return isStale(reportItem, cuttoffTime, reportStage);
};

const isStale = (
  reportItem: ReportPreviewItem,
  cuttoffTime: Date,
  reportStage?: ReportStage,
) => {
  if (reportStage === 'STATUS_REQUESTED') {
    return !reportItem.statusRequest?.isComplete;
  }

  const isComplete = getIsCompleted(reportItem);
  if (isComplete) return false;

  const statusDateTime = getCurrentStatusDateTime(reportItem);
  if (!statusDateTime) return true;

  return isBefore(statusDateTime, cuttoffTime);
};

const getIsCompleted = (reportItem: ReportPreviewItem) => {
  switch (reportItem.__typename) {
    case 'ReportObjective':
      return reportItem.objective.currentObjectiveStatus?.complete;
    case 'ReportMetric':
      return reportItem.metric.currentMetricStatus?.complete;
    case 'ReportInitiative':
      return reportItem.initiative.currentInitiativeStatus?.complete;
    case 'ReportAction':
      return reportItem.action.currentStatus?.complete;
  }
};

const getCurrentStatusDateTime = (reportItem: ReportPreviewItem) => {
  switch (reportItem.__typename) {
    case 'ReportObjective':
      return reportItem.objective.currentObjectiveStatus?.statusDateTime;
    case 'ReportMetric':
      return reportItem.metric.currentMetricStatus?.statusDateTime;
    case 'ReportInitiative':
      return reportItem.initiative.currentInitiativeStatus?.statusDateTime;
    case 'ReportAction':
      return reportItem.action.currentStatus?.statusDateTime;
  }
};

const getOwner = (reportItem: ReportPreviewItem) => {
  switch (reportItem.__typename) {
    case 'ReportObjective':
      return reportItem.objective.owner;
    case 'ReportMetric':
      return reportItem.metric.owner;
    case 'ReportInitiative':
      return reportItem.initiative.owner;
    case 'ReportAction':
      return reportItem.action.owner;
  }
};
