import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import InitiativeStatusWizard from 'initiative/InitiativeStatusWizard';
import type { InitiativeStatusInput } from 'types.graphql.generated';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { ContributionMyContributionDocument } from 'contribution/ContributionMyContribution/ContributionMyContribution.graphql.generated';
import type { InitiativeStatusWizardInitiative } from 'initiative/InitiativeStatusWizard/InitiativeStatusWizard.type';

import { useAddInitiativeStatusMutation } from './AddInitiativeStatus.graphql.generated';

type Props = {
  completeStatus?: boolean;
  initiative: InitiativeStatusWizardInitiative;
  isOpen: boolean;
  onClose: () => void;
};

const CreateInitiativeStatusDialog = ({
  initiative,
  completeStatus,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const onError = useHandleApolloError();

  const [addInitiativeStatus] = useAddInitiativeStatusMutation({
    onCompleted: onClose,
    onError,
    refetchQueries: [ContributionMyContributionDocument],
  });

  const onSubmit = useCallback(
    (input: InitiativeStatusInput) =>
      addInitiativeStatus({ variables: { input } }),
    [addInitiativeStatus],
  );

  return (
    <InitiativeStatusWizard
      headerTitle={t('initiative.createInitiativeStatus.heading')}
      buttonLabel={t('submit')}
      initiative={initiative}
      completeStatus={completeStatus}
      onSubmit={onSubmit}
      onClose={onClose}
      isOpen={isOpen}
    />
  );
};

export default CreateInitiativeStatusDialog;
