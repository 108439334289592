import { Field, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import type { Location } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { Merge } from 'type-fest';
import { css, useTheme } from '@emotion/react';

import Heading from 'shared/components/Heading';
import IconButton from 'shared/components/IconButton';
import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';
import AutoFocus from 'shared/form/AutoFocus';
import FieldBox from 'shared/form/FieldBox';
import { TextInputField } from 'shared/components/TextInput';
import type { TimelineFieldValue } from 'shared/components/TimelineField';
import TimelineField from 'shared/components/TimelineField';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import { useForm } from 'shared/form/Form';
import Button from 'shared/components/Button';
import { TextAreaField } from 'shared/components/TextArea';

import styles from './InitiativeFormMilestonesFields.module.scss';
import type { InitiativeFormValues } from '../InitiativeForm';

const createMilestoneInitialValues = (
  timeLine?: TimelineFieldValue,
): InitiativeFormValues['milestones'][0] => ({
  name: '',
  comment: '',
  timeLine: timeLine ?? { endDate: null },
});

type InitiativeFormMilestonesFieldsLocationState = Merge<
  Location,
  { state: { scrollIntoView: boolean } }
>;

const InitiativeFormMilestonesFields = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { state } =
    useLocation() as InitiativeFormMilestonesFieldsLocationState;
  const [hasScrolled, setHasScrolled] = useState(false);

  const milestonesSectionRef = useRef<HTMLElement | null>(null);

  const { values, initialValues, hasError } = useForm<InitiativeFormValues>();

  useEffect(() => {
    if (state?.scrollIntoView && !hasScrolled) {
      const scrollTimeout = setTimeout(() => {
        milestonesSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
        setHasScrolled(true);
      }, 0);

      return () => clearTimeout(scrollTimeout);
    }
  }, [hasScrolled, state]);

  const initialTimelineValues = useMemo(
    () => values.timeLine ?? initialValues.timeLine,
    [initialValues.timeLine, values.timeLine],
  );

  return (
    <FieldArray name={'milestones'}>
      {(arrayRenderProps) => (
        <section className={styles.milestone} ref={milestonesSectionRef}>
          {values.milestones.map((_, milestoneIndex) => {
            const getFieldName = (name: string) =>
              `milestones.${milestoneIndex}.${name}`;

            return (
              <div
                key={milestoneIndex}
                css={css({
                  padding: '20px',
                  borderBottom: `1px solid ${theme.color.strokeMedium}`,
                })}
              >
                <header className={styles.milestoneHeader}>
                  <Heading
                    level={4}
                    as={5}
                    hasMargin={false}
                    css={css({ marginBottom: '1.5rem' })}
                  >
                    {t(
                      'initiative.initiativeForm.milestones.milestone.heading',
                      { index: milestoneIndex + 1 },
                    )}
                  </Heading>
                  <IconButton
                    icon={CloseIcon}
                    onClick={() => {
                      arrayRenderProps.remove(milestoneIndex);
                    }}
                  >
                    {t(
                      'initiative.initiativeForm.milestones.milestone.removeMilestoneButton',
                    )}
                  </IconButton>
                </header>
                <AutoFocus>
                  <FieldBox
                    name={getFieldName('name')}
                    label={t(
                      'initiative.initiativeForm.milestones.milestone.name.label',
                    )}
                    hasError={hasError(getFieldName('name'))}
                    hasMargin={false}
                  >
                    <Field
                      name={getFieldName('name')}
                      component={TextInputField}
                      maxLength={100}
                      hasError={hasError(getFieldName('name'))}
                    />
                  </FieldBox>
                  <FieldBox
                    name={getFieldName('comment')}
                    label={t(
                      'initiative.initiativeForm.milestones.milestone.comment.label',
                    )}
                  >
                    <Field
                      name={getFieldName('comment')}
                      component={TextAreaField}
                      maxLength={2000}
                      hasMargin={false}
                    />
                  </FieldBox>
                  <TimelineField
                    name={getFieldName('timeLine')}
                    className={styles.milestoneTimeline}
                    showField={{ startDate: false, endDate: true }}
                  />
                </AutoFocus>
              </div>
            );
          })}

          <div css={css({ padding: 20 })}>
            <Button
              variant={'outlined'}
              icon={PlusIcon}
              iconPosition={'start'}
              onClick={() => {
                arrayRenderProps.push(
                  createMilestoneInitialValues(initialTimelineValues),
                );
              }}
            >
              {t(
                'initiative.initiativeForm.milestones.milestone.addMilestoneButton',
              )}
            </Button>
          </div>
        </section>
      )}
    </FieldArray>
  );
};

export default InitiativeFormMilestonesFields;
