import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import Flex from 'shared/components/Flex';
import ProgressBar from 'shared/components/ProgressBar/ProgressBar';
import Text from 'shared/components/Text';
import { useCampaignResults } from 'toolkit/WalkOfStrategyCampaignResultsLayout/CampaignResultsProvider';

type Props = {
  orgUnitId: string;
};

const EngagementBar = ({ orgUnitId }: Props) => {
  const { t } = useTranslation();
  const { campaign } = useCampaignResults();

  const orgUnit = campaign.targetOrgUnitTree.find(
    (orgUnit) => orgUnit.id === orgUnitId,
  );
  const orgUnitResponses = campaign.allUserResponses.filter((response) =>
    response.user.orgUnit
      ? [
          response.user.orgUnit.id,
          ...response.user.orgUnit.parentOrgUnitTree.map(
            (orgUnit) => orgUnit.id,
          ),
        ].includes(orgUnitId)
      : false,
  );

  if (
    !orgUnit ||
    orgUnit.orgUnitMembersCascadingTotalCount === undefined ||
    orgUnit.orgUnitMembersCascadingTotalCount === 0
  )
    return null;

  const engagementRatio = Math.round(
    (orgUnitResponses.length / orgUnit.orgUnitMembersCascadingTotalCount) * 100,
  );

  const label = t(
    'toolkit.toolPage.walkOfStrategy.results.insights.section.overview.engaged',
  );

  return (
    <Flex direction={'column'} css={css({ width: 120 })} gap={'0.4rem'}>
      <div>
        <strong>
          {engagementRatio}
          {'%'}
        </strong>{' '}
        <Text size={'small'}>{label}</Text>
      </div>
      <ProgressBar ratio={engagementRatio} />
    </Flex>
  );
};

export default EngagementBar;
