import { memo, useId, useMemo } from 'react';

import { date } from 'shared/services/date.service';
import type { StatusIndicator } from 'shared/status/StatusIndicator';
import Space from 'shared/components/Space';
import Text from 'shared/components/Text';
import type { StatusMarkProps } from 'shared/status/StatusMark';
import StatusMark from 'shared/status/StatusMark';

import styles from './StatusMarks.module.scss';

export type StatusMarkItem = {
  statusIndicator?: StatusIndicator;
  updateDateTime?: Date;
};

export type StatusMarksProps = Pick<StatusMarkProps, 'size' | 'hasBorder'> & {
  className?: string;
  items: StatusMarkItem[];
  maxStatusMarksCount?: number;
};

const StatusMarks = ({
  items,
  maxStatusMarksCount = 8,
  size,
  hasBorder,
  className,
}: StatusMarksProps) => {
  const keyId = useId();

  const statusIndicators = useMemo(
    () =>
      items
        .sort((previousItem, nextItem) => {
          if (previousItem.updateDateTime && nextItem.updateDateTime) {
            return date.isAfter(
              previousItem.updateDateTime,
              nextItem.updateDateTime,
            )
              ? -1
              : 1;
          }
          return 0;
        })
        .map((item) => item.statusIndicator)
        .slice(-maxStatusMarksCount),
    [items, maxStatusMarksCount],
  );

  const statusIndicatorsLeft = items.length - maxStatusMarksCount;
  const statusIndicatorLeftText =
    statusIndicatorsLeft > 0 ? `+ ${statusIndicatorsLeft}` : null;

  return (
    <Space className={className} size={size === 'small' ? 'small' : undefined}>
      <Space
        className={styles.statusIndicators}
        size={size === 'small' ? 'tiny' : undefined}
      >
        {statusIndicators.map((statusIndicator, statusIndicatorIndex) => (
          <StatusMark
            statusIndicator={statusIndicator}
            size={size}
            hasBorder={hasBorder}
            key={`${keyId}${statusIndicatorIndex}`}
          />
        ))}
      </Space>
      {statusIndicatorLeftText ? (
        <Text className={styles.text}>{statusIndicatorLeftText}</Text>
      ) : null}
    </Space>
  );
};

export default memo(StatusMarks);
