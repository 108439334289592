import { i18n } from 'shared/services/i18n.service';
import { yup } from 'shared/services/yup.service';

import type { SelectObjectiveInputFieldOption } from './SelectObjectiveInput.type';

export const hasObjective = () =>
  yup
    .object()
    .test(
      'hasObjective',
      i18n.t('selectObjectiveInput.missingObjective'),
      (option: SelectObjectiveInputFieldOption) => !!option.value,
    );
