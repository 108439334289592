import cn from 'classnames';

import Heading from 'shared/components/Heading';

import styles from './ContributionSectionHeader.module.scss';

type ContributionSectionHeaderProps = {
  className?: string;
  description?: string;
  heading: string;
};

const ContributionSectionHeader = ({
  heading,
  description,
  className,
}: ContributionSectionHeaderProps) => (
  <section className={cn(styles.header, className)}>
    <Heading level={4} hasMargin={false}>
      {heading}
    </Heading>
    <p className={styles.description}>{description}</p>
  </section>
);

export default ContributionSectionHeader;
