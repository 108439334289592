import { useMemo } from 'react';

import Space from 'shared/components/Space';
import ToolCard from 'toolkit/ToolCard';
import NoMatchingResultsResult from 'shared/components/NoMatchingResultsResult';
import type { ToolCategoryId, ToolGroup } from 'toolkit/toolkit.static';
import { tools } from 'toolkit/toolkit.static';

import styles from './ToolkitOverviewToolsGrid.module.scss';

type ToolkitOverviewToolsGridProps = {
  categoryId: ToolCategoryId;
  selectedGroupsIds: Set<ToolGroup>;
};

const ToolkitOverviewToolsGrid = ({
  categoryId,
  selectedGroupsIds,
}: ToolkitOverviewToolsGridProps) => {
  const filteredTools = useMemo(
    () =>
      tools.filter(
        (tool) =>
          tool.categories.includes(categoryId) &&
          (selectedGroupsIds.size === 0 ||
            tool.groups.some((groupId) => selectedGroupsIds.has(groupId))),
      ),
    [categoryId, selectedGroupsIds],
  );

  if (filteredTools.length) {
    return (
      <div className={styles.container}>
        {filteredTools.map((tool) => (
          <ToolCard tool={tool} key={tool.id} />
        ))}
      </div>
    );
  }
  return (
    <Space isCentered={true}>
      <NoMatchingResultsResult />
    </Space>
  );
};

export default ToolkitOverviewToolsGrid;
