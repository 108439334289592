import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateIsStrategyCompleteMutationVariables = Types.Exact<{
  strategyId: Types.Scalars['ID'];
  isComplete: Types.Scalars['Boolean'];
}>;


export type UpdateIsStrategyCompleteMutation = { __typename: 'Mutation', updateStrategy: { __typename: 'Strategy', id: string, isComplete: boolean, timeLine?: { __typename: 'TimeLine', completeDateTime?: any | undefined } | undefined } };


export const UpdateIsStrategyCompleteDocument = gql`
    mutation UpdateIsStrategyComplete($strategyId: ID!, $isComplete: Boolean!) {
  updateStrategy(input: {isComplete: $isComplete, idToUpdate: $strategyId}) {
    id
    isComplete
    timeLine {
      completeDateTime
    }
  }
}
    `;
export type UpdateIsStrategyCompleteMutationFn = Apollo.MutationFunction<UpdateIsStrategyCompleteMutation, UpdateIsStrategyCompleteMutationVariables>;

/**
 * __useUpdateIsStrategyCompleteMutation__
 *
 * To run a mutation, you first call `useUpdateIsStrategyCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIsStrategyCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIsStrategyCompleteMutation, { data, loading, error }] = useUpdateIsStrategyCompleteMutation({
 *   variables: {
 *      strategyId: // value for 'strategyId'
 *      isComplete: // value for 'isComplete'
 *   },
 * });
 */
export function useUpdateIsStrategyCompleteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIsStrategyCompleteMutation, UpdateIsStrategyCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIsStrategyCompleteMutation, UpdateIsStrategyCompleteMutationVariables>(UpdateIsStrategyCompleteDocument, options);
      }
export type UpdateIsStrategyCompleteMutationHookResult = ReturnType<typeof useUpdateIsStrategyCompleteMutation>;
export type UpdateIsStrategyCompleteMutationResult = Apollo.MutationResult<UpdateIsStrategyCompleteMutation>;
export type UpdateIsStrategyCompleteMutationOptions = Apollo.BaseMutationOptions<UpdateIsStrategyCompleteMutation, UpdateIsStrategyCompleteMutationVariables>;