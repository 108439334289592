import { useTranslation } from 'react-i18next';

import OneThingCampaignRequestButton from 'toolkit/OneThingCampaignRequestButton';
import OneThingCampaignRequestNotice from 'toolkit/OneThingCampaignRequestNotice';
import DeleteOneThingTaskButton from 'contribution/ContributionMyFollowUpsViewMore/DeleteOneThingTaskButton';

import type { FollowUpActivityItemOneThing } from './ContributionMyFollowUpsViewMore.type';
import TimelineEntryContentSummary from './TimelineEntryContentSummary';

const TimelineEntryContentOneThingCampaign = ({
  item,
}: {
  item: FollowUpActivityItemOneThing;
}) => {
  const { t } = useTranslation();

  const campaign = item.campaign;
  const response = campaign.currentUserTask;

  const isCampaignActive = campaign.state.stage === 'ACTIVE';
  const isTaskCreated = !!response;
  const isTaskDone = response?.status === 'DONE';

  return (
    <TimelineEntryContentSummary
      item={item}
      icon={t('toolkit.tools.oneThing.symbol')}
      description={<OneThingCampaignRequestNotice campaign={item.campaign} />}
      typeLabel={t('toolkit.tools.oneThing.name')}
    >
      <OneThingCampaignRequestButton
        campaign={item.campaign}
        variant={'contained'}
      />

      {isCampaignActive && isTaskCreated && !isTaskDone && (
        <DeleteOneThingTaskButton taskId={response.id} />
      )}
    </TimelineEntryContentSummary>
  );
};

export default TimelineEntryContentOneThingCampaign;
