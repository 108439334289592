import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

import CreateOrgUnitModal from 'orgUnit/CreateOrgUnitModal';
import { useActiveOrg } from 'org/ActiveOrgProvider';

import { TeamTeamHierarchyDocument } from '../TeamTeamHierarchyProvider';

const TeamTeamHierarchyCreateOrgUnitModal = () => {
  const navigate = useNavigate();

  const apolloClient = useApolloClient();

  const { activeOrg } = useActiveOrg();

  const handleClose = useCallback(() => navigate('../'), [navigate]);

  const handleSuccess = useCallback(
    () => apolloClient.refetchQueries({ include: [TeamTeamHierarchyDocument] }),
    [apolloClient],
  );

  return (
    <CreateOrgUnitModal
      orgKey={activeOrg.orgKey}
      isOpen={true}
      onClose={handleClose}
      onSuccess={handleSuccess}
    />
  );
};

export default TeamTeamHierarchyCreateOrgUnitModal;
