import type { UserFilterInput } from 'types.graphql.generated';

export const resolveUserFilterInput = (email: string): UserFilterInput => {
  return {
    email: {
      operator: 'EQUALS',
      value: email,
    },
  };
};
