import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SelectUserTenantInputQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SelectUserTenantInputQuery = { __typename: 'Query', allTenantsForCurrentUser: Array<{ __typename: 'Tenant', id: string, orgKey: string, domainNames: Array<string>, displayName?: string | undefined, isLive: boolean }> };


export const SelectUserTenantInputDocument = gql`
    query SelectUserTenantInput {
  allTenantsForCurrentUser {
    id
    orgKey
    domainNames
    displayName
    isLive
  }
}
    `;

/**
 * __useSelectUserTenantInputQuery__
 *
 * To run a query within a React component, call `useSelectUserTenantInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectUserTenantInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectUserTenantInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectUserTenantInputQuery(baseOptions?: Apollo.QueryHookOptions<SelectUserTenantInputQuery, SelectUserTenantInputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectUserTenantInputQuery, SelectUserTenantInputQueryVariables>(SelectUserTenantInputDocument, options);
      }
export function useSelectUserTenantInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectUserTenantInputQuery, SelectUserTenantInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectUserTenantInputQuery, SelectUserTenantInputQueryVariables>(SelectUserTenantInputDocument, options);
        }
export type SelectUserTenantInputQueryHookResult = ReturnType<typeof useSelectUserTenantInputQuery>;
export type SelectUserTenantInputLazyQueryHookResult = ReturnType<typeof useSelectUserTenantInputLazyQuery>;
export type SelectUserTenantInputQueryResult = Apollo.QueryResult<SelectUserTenantInputQuery, SelectUserTenantInputQueryVariables>;