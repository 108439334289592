import type { Animation } from 'shared/types/animation.type';

export const popAnimation: Animation = {
  animate: {
    opacity: 1,
    scale: 1,
    y: 0,
    x: 0,
  },
  initial: {
    opacity: 0,
    scale: 0.75,
    x: 5,
    y: -10,
  },
  exit: {
    opacity: 0,
    scale: 0.75,
    x: 5,
    y: -10,
  },
  transition: {
    duration: 0.2,
  },
};
