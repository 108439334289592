import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { isUserThemeValue } from './useUserFilter.utils';
import type { UserFilterValue } from './useUserFilter.type';

type UserFilterParams = {
  paramName?: string;
};

type UserFilterReturnType = {
  setUserFilter: (value: UserFilterValue) => void;
  userFilter: UserFilterValue;
};

export const useUserFilter = ({
  paramName = 'user',
}: UserFilterParams = {}): UserFilterReturnType => {
  const defaultValue = useMemo(() => [], []);

  const [searchParams, setSearchParams] = useSearchParams();

  const userFilter = useMemo<UserFilterReturnType['userFilter']>(() => {
    const params = searchParams.getAll(paramName);
    if (params.every(isUserThemeValue)) {
      return params;
    }
    return defaultValue;
  }, [searchParams, paramName, defaultValue]);

  const setUserFilter = useCallback<UserFilterReturnType['setUserFilter']>(
    (value) => {
      setSearchParams((searchParams) => {
        searchParams.delete(paramName);
        value.forEach((id) => {
          searchParams.append(paramName, id);
        });
        return searchParams;
      });
    },
    [setSearchParams, paramName],
  );

  return {
    userFilter,
    setUserFilter,
  };
};
