import cn from 'classnames';
import type { SetOptional } from 'type-fest';

import type { CollapsibleListProps } from 'shared/components/CollapsibleList';
import CollapsibleList from 'shared/components/CollapsibleList';

import styles from './ContributionSectionCollapsibleList.module.scss';

type ContributionSectionListProps = SetOptional<CollapsibleListProps, 'type'>;

const ContributionSectionCollapsibleList = ({
  type,
  children,
  hasHeaderPadding = false,
  headerClassName,
  listClassName,
  ...restProps
}: ContributionSectionListProps) => (
  <CollapsibleList
    type={type ?? 'item'}
    hasHeaderPadding={hasHeaderPadding}
    headerClassName={cn(styles.listHeader, headerClassName)}
    listClassName={cn(styles.list, listClassName)}
    {...restProps}
  >
    {children}
  </CollapsibleList>
);

export default ContributionSectionCollapsibleList;
