import { Navigate, useMatch } from 'react-router-dom';

import { routesPaths } from 'App.routing.paths';
import { teamRoutesPaths } from 'team/team.routing.paths';
import { useActiveOrg } from 'org/ActiveOrgProvider';

const NavigateToTeamRoot = () => {
  const isMatch = useMatch(routesPaths.teams.root());

  // temporary redirect to org root, since org unit pages do not have much data to show right now
  const { activeOrg } = useActiveOrg();

  const toOrgRoot = teamRoutesPaths.root({
    params: {
      teamSlug: activeOrg.orgKey,
    },
  });

  if (isMatch) {
    return <Navigate to={toOrgRoot} replace={true} />;
  }

  return null;
};

export default NavigateToTeamRoot;
