import { spring } from 'shared/animations/spring.animation';
import type { Animation } from 'shared/types/animation.type';

export const expandChildAnimation: Animation = {
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  variants: {
    open: {
      opacity: 1,
      maxHeight: 1500,
      transition: spring,
      y: 0,
    },
    collapsed: {
      opacity: 0,
      maxHeight: 0,
      transition: spring,
      y: 5,
    },
  },
};
