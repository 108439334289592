import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import Select from 'shared/components/__DEPRECATED__/Select';
import type { SelectProps } from 'shared/components/__DEPRECATED__/Select';

import { getOptionLabel } from './SelectOrgUnitInput.utils';
import { useSelectOrgUnitInput } from './SelectOrgUnitInputProvider';
import type { SelectOrgUnitInputFieldOption } from './SelectOrgUnitInput.type';

type SelectOrgUnitInputProps = Pick<
  SelectProps<SelectOrgUnitInputFieldOption, false>,
  'value' | 'isSearchable' | 'isDisabled'
> & { onChange: (newValue: SelectOrgUnitInputFieldOption | null) => void };

const SelectOrgUnitInput = ({
  isSearchable = true,
  isDisabled: isDisabledProp,
  onChange,
  ...restProps
}: SelectOrgUnitInputProps) => {
  const value = restProps.value as SelectOrgUnitInputFieldOption | null;

  const { t } = useTranslation();

  const { options } = useSelectOrgUnitInput();

  const isSingleOption =
    options.length === 1 &&
    (!options[0].options || options[0].options.length === 1);

  useEffect(() => {
    if (isSingleOption && !value) {
      const firstOption = options[0];
      if (firstOption.options) {
        onChange(firstOption.options[0]);
      } else {
        onChange(firstOption);
      }
    }
  }, [isSingleOption, onChange, options, value]);

  const isDisabled = isDisabledProp ?? isSingleOption;

  const isCurrentValueInOptions = hasOption(value, options);

  const optionsWithInitialValue = [
    isCurrentValueInOptions ? undefined : value,
    ...options,
  ].filter(Boolean);

  return (
    <Select<SelectOrgUnitInputFieldOption, false>
      {...restProps}
      onChange={onChange}
      value={value}
      options={optionsWithInitialValue}
      getOptionLabel={getOptionLabel({ t })}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
    />
  );
};

export default SelectOrgUnitInput;

function hasOption(
  value: SelectOrgUnitInputFieldOption | null,
  options: SelectOrgUnitInputFieldOption[],
): boolean {
  return !!options.find(
    (option) =>
      (value?.value
        ? value.value.id === option.value?.id
        : option.value === null) ||
      (option.options && hasOption(value, option.options)),
  );
}
