import cn from 'classnames';

import Space from 'shared/components/Space';
import type { Size } from 'shared/types/size.type';

import styles from './CircleSpinner.module.scss';

type CircleSpinnerProps = {
  className?: string;
  containerClassName?: string;
  containerSize?: Extract<Size, 'small' | 'medium'>;
  isCentered?: boolean;
  isDisplayed?: boolean;
  size?: Extract<Size, 'small' | 'medium'>;
};

const CircleSpinner = ({
  isCentered = true,
  isDisplayed = true,
  size = 'medium',
  className,
  containerClassName,
  containerSize,
}: CircleSpinnerProps) => (
  <Space
    className={cn('circle-spinner', containerClassName, {
      [styles.containerSizeMedium]: containerSize === 'medium',
      [styles.containerSizeSmall]: containerSize === 'small',
    })}
    isCentered={true}
  >
    <div
      role={'status'}
      className={cn(
        styles.circleSpinner,
        {
          [styles.circleSpinnerCentered]: isCentered,
          [styles.circleSpinnerSizeMedium]: size === 'medium',
          [styles.circleSpinnerSizeSmall]: size === 'small',
          [styles.circleSpinnerDisplayed]: isDisplayed,
        },
        className,
      )}
    >
      <div className={styles.circleSpinnerCircle} />
    </div>
  </Space>
);

export default CircleSpinner;
