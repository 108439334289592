import { useSet } from 'react-use';

import type { BaseTreeItem } from './useTree.type';

export type UseSelectableTreeItemsParams<TreeItemParam extends BaseTreeItem> = {
  selectedItemsIds?: TreeItemParam['id'][];
};

export type UseSelectableTreeItemsReturnType<
  TreeItemParam extends BaseTreeItem,
> = {
  isItemSelected: (itemId: TreeItemParam['id']) => boolean;
  toggleIsItemSelected: (itemId: TreeItemParam['id']) => void;
};

export const useSelectableTreeItems = <TreeItemParam extends BaseTreeItem>(
  params: UseSelectableTreeItemsParams<TreeItemParam> = {},
): UseSelectableTreeItemsReturnType<TreeItemParam> => {
  const { selectedItemsIds } = params;

  const [, { has: isItemSelected, toggle: toggleIsItemSelected }] = useSet<
    TreeItemParam['id']
  >(new Set(selectedItemsIds));

  return {
    isItemSelected,
    toggleIsItemSelected,
  };
};
