import type { TFunction } from 'i18next';

import type { PriorityFragment } from 'strategy/useStrategyProfilePriorities/StrategyProfileFilters.graphql.generated';

import type { PriorityMultiSelectItem } from '../PriorityMultiSelect.type';

export const getItems = (
  t: TFunction,
  priorities?: PriorityFragment[],
): PriorityMultiSelectItem[] => {
  const itemsWithValues = priorities
    ? priorities.map((priority) => ({
        id: priority.number,
        label: priority.label,
      }))
    : [];

  return [
    { id: 'ALL' },
    { id: 'UNKNOWN', label: t('priority.noPriority') },
    ...itemsWithValues,
  ];
};
