import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import type { CommentsFormValues } from 'shared/components/CommentsForm';
import Flex from 'shared/components/Flex';
import { useFormRef } from 'shared/form/Form';

import { usePreviousInsights } from './usePreviousInsights';

const ButtonContainer = styled.div`
  margin-top: -4px;
  cursor: pointer;
  padding: 0.2rem;
  border-radius: 0.2rem;

  &:hover {
    background-color: ${(props) => props.theme.color.backgroundLight};
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

type Props = {
  content: string;
  formSection: keyof CommentsFormValues;
};

const CommentLine = ({ content, formSection }: Props) => {
  const { t } = useTranslation();
  const { formId } = usePreviousInsights();

  const formRef = useFormRef<CommentsFormValues>(formId);

  const handleAddComment = () => {
    const fieldValues = formRef?.current?.values[formSection] || [];

    // if the last value of the form field is an empty string,
    // remove that value before adding a new value to the array
    if (fieldValues.at(fieldValues.length - 1) === '') {
      formRef?.current
        ?.getFieldHelpers(formSection)
        .setValue([...fieldValues.slice(0, -1), content]);
    } else {
      formRef?.current
        ?.getFieldHelpers(formSection)
        .setValue([...fieldValues, content]);
    }
  };

  return (
    <Flex alignItems={'flex-start'} gap={8}>
      {'•'}

      <div css={css({ flexGrow: 1 })}>{content}</div>

      {formRef && (
        <ButtonContainer>
          <PlusIcon
            onClick={handleAddComment}
            title={t('sidesheets.buttons.addComment')}
          />
        </ButtonContainer>
      )}
    </Flex>
  );
};
export default CommentLine;
