import { createContext } from 'react';

import type { OrgUnitCardOrgUnitFragment } from './OrgUnitCardProvider.graphql.generated';

export type OrgUnitCardProviderContextValue = {
  orgUnit: OrgUnitCardOrgUnitFragment;
};

export const OrgUnitCardProviderContext =
  createContext<Maybe<OrgUnitCardProviderContextValue>>(undefined);
