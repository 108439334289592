import { useDefinedContext } from 'shared/utils/context.utils';

import MyTeamsProvider from './MyTeamsProvider';
import { MyTeamsContext } from './MyTeamsProvider.context';

export const useMyTeams = () =>
  useDefinedContext(MyTeamsContext, {
    hookName: useMyTeams.name,
    providerName: MyTeamsProvider.name,
  });
