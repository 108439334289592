import { useCallback, useMemo } from 'react';
import { useSet, useToggle } from 'react-use';

import Heading from 'shared/components/Heading';
import Text from 'shared/components/Text';
import { useInsightReportSummaryReport } from 'report/InsightReportSummary/InsightReportSummaryProvider';
import styles from 'report/InsightReportSummary/InsightReportSummaryStrategicInitiatives/InsightReportSummaryStrategicInitiatives.module.scss';
import StrategyInsightsObjective from 'strategy/StrategyInsights/StrategyInsightsObjective';
import type { Objective } from 'types.graphql.generated';
import InitiativeSummary from 'initiative/InitiativeSummary';
import Collapse from 'shared/components/Collapse';
import { date } from 'shared/services/date.service';

type InsightReportSummaryObjectivesProps = {
  defaultCollapsed?: boolean;
  heading: string;
  showOnlyCompleted?: boolean;
};

const InsightReportSummaryObjectives = ({
  heading,
  showOnlyCompleted = false,
  defaultCollapsed = false,
}: InsightReportSummaryObjectivesProps) => {
  const [isObjectiveDetailsCollapsed, toggleIsObjectiveDetailsCollapsed] =
    useToggle(defaultCollapsed);

  const {
    report: {
      strategy,
      reportStatus: { reportThemeCategories },
    },
  } = useInsightReportSummaryReport();

  const reportActiveObjectivesData = useMemo(
    () =>
      reportThemeCategories.flatMap(({ reportThemes }) =>
        reportThemes
          .flatMap(({ reportObjectives }) => reportObjectives)
          .filter(({ status }) => status?.complete === false || !status)
          .map(({ objective, status, reportInitiatives, reportMetrics }) => ({
            objective: {
              currentObjectiveStatus: status,
              ...objective,
            },
            initiatives: reportInitiatives.map(({ initiative }) => initiative),
            metrics: reportMetrics.map(({ metric }) => metric),
          })),
      ),
    [reportThemeCategories],
  );

  const reportCompletedObjectivesData = useMemo(
    () =>
      reportThemeCategories.flatMap(({ reportThemes }) =>
        reportThemes
          .flatMap(({ reportObjectives }) => reportObjectives)
          .filter(({ status }) => status?.complete === true)
          .map(({ objective, status, reportInitiatives, reportMetrics }) => ({
            objective: {
              currentObjectiveStatus: status,
              ...objective,
            },
            initiatives: reportInitiatives.map(({ initiative }) => initiative),
            metrics: reportMetrics.map(({ metric }) => metric),
          }))
          .sort(({ objective: objectiveA }, { objective: objectiveB }) => {
            const dateA =
              objectiveA.currentObjectiveStatus?.auditRecord.updateDateTime ||
              objectiveA.auditRecord.createDateTime;
            const dateB =
              objectiveB.currentObjectiveStatus?.auditRecord.updateDateTime ||
              objectiveB.auditRecord.createDateTime;

            return date.isAfter(dateA, dateB) ? -1 : 1;
          }),
      ),
    [reportThemeCategories],
  );

  const reportObjectivesData = showOnlyCompleted
    ? reportCompletedObjectivesData
    : reportActiveObjectivesData;

  const [set, { has: isObjectiveExpanded, toggle: toggleIsObjectiveExpanded }] =
    useSet<Objective['id']>();

  const renderObjectiveInitiatives = useCallback(
    (objectiveId: Objective['id']) => (
      <>
        {reportObjectivesData
          .find(({ objective }) => objective.id === objectiveId)
          ?.initiatives.map((initiative) => (
            <InitiativeSummary initiative={initiative} key={initiative.id} />
          ))}
      </>
    ),
    [reportObjectivesData],
  );

  const reportObjectives = useMemo(
    () => (
      <>
        {reportObjectivesData.map(({ objective, metrics }) => (
          <StrategyInsightsObjective
            objective={
              {
                ...objective,
                metrics,
              } as any
            }
            isExpanded={isObjectiveExpanded(objective.id)}
            onToggleExpand={() => {
              toggleIsObjectiveExpanded(objective.id);
            }}
            key={objective.id}
            summaryBackground={true}
            renderInitiatives={() => renderObjectiveInitiatives(objective.id)}
          />
        ))}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportObjectivesData, set],
  );

  return (
    <Collapse
      isCollapsed={isObjectiveDetailsCollapsed}
      onToggleIsCollapsed={toggleIsObjectiveDetailsCollapsed}
      renderHeading={() => <Heading level={3}>{heading}</Heading>}
    >
      {strategy?.vision?.name ? (
        <Text className={styles.visionName}>{strategy?.vision?.name}</Text>
      ) : null}
      {reportObjectives}
    </Collapse>
  );
};

export default InsightReportSummaryObjectives;
