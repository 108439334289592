import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { yup } from 'shared/services/yup.service';
import Form from 'shared/form/Form';
import FieldBox from 'shared/form/FieldBox';
import { TextInputField } from 'shared/components/TextInput';
import type { FormProps } from 'shared/form/Form/Form';
import { TextAreaField } from 'shared/components/TextArea';

export type StrategyBehaviorGroupFormValues = {
  description: string;
  id?: string;
  title: string;
};

const createInitialValues = (): StrategyBehaviorGroupFormValues => ({
  title: '',
  description: '',
});

type StrategyBehaviorGroupFormProps = {
  className?: string;
  id?: string;
  initialValues?: StrategyBehaviorGroupFormValues;
  onSubmit: FormProps<StrategyBehaviorGroupFormValues>['onSubmit'];
};

const StrategyBehaviorGroupForm = ({
  initialValues = createInitialValues(),
  id,
  className,
  onSubmit,
}: StrategyBehaviorGroupFormProps) => {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    title: yup.string().required(),
  });

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      id={id}
      className={className}
    >
      {({ hasError }) => (
        <>
          <FieldBox
            name={'title'}
            hasError={hasError('title')}
            label={t(
              'strategy.strategyBehaviorsForm.strategyBehaviorGroupForm.title.label',
            )}
          >
            <Field
              name={'title'}
              component={TextInputField}
              hasError={hasError('title')}
            />
          </FieldBox>
          <FieldBox
            name={'description'}
            label={t(
              'strategy.strategyBehaviorsForm.strategyBehaviorGroupForm.description.label',
            )}
            isOptional={true}
          >
            <Field name={'description'} component={TextAreaField} />
          </FieldBox>
        </>
      )}
    </Form>
  );
};

export default StrategyBehaviorGroupForm;
