import type { PropsWithChildren } from 'react';
import { css } from '@emotion/react';

import { ReactComponent as InfoIcon } from 'shared/static/icons/icon-info.svg';
import Tooltip from 'shared/components/Tooltip';

import FormContainer from './FormContainer';
import FormContainerHeader from './FormContainerHeader';
import FormContainerBody from './FormContainerBody';

const iconStyle = css`
  width: 20px;
  height: 20px;
`;

type Props = PropsWithChildren<{
  infoContent: string;
  isOptional?: boolean;
  title: string;
}>;

const InfoFormContainer = ({
  title,
  infoContent,
  isOptional,
  children,
}: Props) => {
  return (
    <FormContainer>
      <FormContainerHeader title={title} isOptional={isOptional}>
        <Tooltip content={infoContent} delay={0}>
          <InfoIcon css={iconStyle} />
        </Tooltip>
      </FormContainerHeader>

      <FormContainerBody $hasContentPadding={true}>
        {children}
      </FormContainerBody>
    </FormContainer>
  );
};

export default InfoFormContainer;
