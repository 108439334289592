import { motion } from 'framer-motion';
import type {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  RefObject,
} from 'react';
import { useRef } from 'react';
import { useScroll } from 'react-use';
import styled from '@emotion/styled';

import Heading from 'shared/components/Heading';
import { layoutContentMaxWidth } from 'shared/styles/sizes';

const collapsedSubheadingHeight = '4.5rem';
const expandedSubheadingHeight = '6.625rem';

const Subheading = styled(motion.div)<{ isScrolledToTop: boolean }>`
  border-bottom: 1px solid ${(props) => props.theme.legacyColor.colorGallery};
  padding: 0 2rem;
  height: ${(props) =>
    props.isScrolledToTop
      ? expandedSubheadingHeight
      : collapsedSubheadingHeight};
`;

const SubheadingContent = styled.div`
  align-items: flex-end;
  display: flex;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 1.25rem;
`;

const Container = styled.div<{
  hasBackground?: boolean;
  padding?: 'regular' | 'none';
  scrollbarGutter?: CSSProperties['scrollbarGutter'];
}>`
  display: flex;
  flex: 1;
  overflow-y: auto;
  position: relative;
  background-color: ${(props) =>
    props.hasBackground
      ? props.theme.legacyColor.colorAlabaster
      : 'transparent'};
  box-shadow: ${(props) =>
    props.hasBackground
      ? 'inset 0 -2.5rem 2.5rem -2.5rem rgba(0, 0, 0, 0.12)'
      : 'none'};
  padding: ${(props) => (props.padding === 'regular' ? '2rem' : '0')};
  scrollbar-gutter: ${(props) =>
    props.scrollbarGutter === 'stable' ? 'stable' : 'auto'};
`;

const Content = styled.div<{
  contentScrollbarGutter?: CSSProperties['scrollbarGutter'];
  padding?: 'regular' | 'none';
  scrollType?: 'inner' | 'outer';
}>`
  flex: 1;
  margin: 0 auto;
  max-width: ${layoutContentMaxWidth};
  overflow-x: clip;
  overflow-y: ${(props) => (props.scrollType === 'inner' ? 'auto' : 'unset')};
  padding: ${(props) => (props.padding === 'regular' ? '0.325rem 0' : '0')};
  scrollbar-gutter: ${(props) =>
    props.contentScrollbarGutter === 'stable' ? 'stable' : 'auto'};
`;

export type ModalContentProps = PropsWithChildren<{
  className?: string;
  contentElementRef?: RefObject<HTMLDivElement>;
  contentScrollbarGutter?: CSSProperties['scrollbarGutter'];
  hasBackground?: boolean;
  padding?: 'regular' | 'none';
  scrollType?: 'inner' | 'outer';
  scrollbarGutter?: CSSProperties['scrollbarGutter'];
  subheading?: string | ReactNode;
}>;

const ModalContent = ({
  hasBackground,
  subheading,
  children,
  scrollType = 'inner',
  contentElementRef: contentElementRefProp,
  className,
  padding,
  scrollbarGutter,
  contentScrollbarGutter,
}: ModalContentProps) => {
  const contentElementRefHookResult = useRef<HTMLDivElement>(null);

  const contentElementRef =
    contentElementRefProp || contentElementRefHookResult;

  const contentScrollPosition = useScroll(contentElementRef);
  const isScrolledToTop = contentScrollPosition.y === 0;

  return (
    <>
      {subheading && (
        <Subheading
          variants={{
            expanded: { height: expandedSubheadingHeight },
            collapsed: { height: collapsedSubheadingHeight },
          }}
          animate={isScrolledToTop ? 'expanded' : 'collapsed'}
          isScrolledToTop={isScrolledToTop}
          transition={{ duration: 0.3, ease: 'backOut' }}
        >
          <SubheadingContent>
            <Heading level={3} hasMargin={false}>
              {subheading}
            </Heading>
          </SubheadingContent>
        </Subheading>
      )}
      <Container
        hasBackground={hasBackground}
        padding={padding}
        scrollbarGutter={scrollbarGutter}
        className={className}
        ref={contentElementRef}
      >
        <Content
          scrollType={scrollType}
          padding={padding}
          contentScrollbarGutter={contentScrollbarGutter}
        >
          {children}
        </Content>
      </Container>
    </>
  );
};

export default ModalContent;
