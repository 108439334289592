import { createContext } from 'react';

import type { Theme } from 'types.graphql.generated';
import type { UserFilterValue } from 'user/useUserFilter';
import type { UserLimitedProfileFragment } from 'user/userProfile.graphql.generated';
import type { StatusMultiSelectItemId } from 'shared/status/StatusMultiSelect';
import type { PriorityFilterValue } from 'shared/priority/usePriorityFilter/usePriorityFilter';
import type { ThemeFilterValue } from 'themes/useThemeFilter';
import type { ObjectivesByTheme } from 'objective/objective.utils';

import type { SelectableStrategyItemsObjectiveFragment } from './SelectableStrategyItemsProvider.graphql.generated';

type SelectableTheme = Pick<Theme, 'id' | 'name' | 'orderNumber'>;

export type SelectableStrategyItemsProviderContextValue = {
  filter: {
    clearFilters: () => void;
    endDateFilter: Maybe<Date>;
    priorityFilter: PriorityFilterValue;
    setEndDateFilter: (value: Maybe<Date>) => void;
    setPriorityFilter: (value: PriorityFilterValue) => void;
    setStartDateFilter: (value: Maybe<Date>) => void;
    setStatusFilter: (value: StatusMultiSelectItemId[]) => void;
    setThemeFilter: (value: ThemeFilterValue) => void;
    setUserFilter: (value: UserFilterValue) => void;
    startDateFilter: Maybe<Date>;
    statusFilter: StatusMultiSelectItemId[];
    themeFilter: ThemeFilterValue;
    userFilter: UserFilterValue;
  };
  filteredObjectives: SelectableStrategyItemsObjectiveFragment[];
  filteredObjectivesByThemes: ObjectivesByTheme<
    SelectableStrategyItemsObjectiveFragment,
    SelectableTheme
  >[];
  objectives: SelectableStrategyItemsObjectiveFragment[];
  themes: SelectableTheme[];
  users: UserLimitedProfileFragment[];
};

export const SelectableStrategyItemsProviderContext = createContext<
  SelectableStrategyItemsProviderContextValue | undefined
>(undefined);
