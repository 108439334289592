import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import type {
  UserFilter,
  ActionsSort,
  StatusFilter,
  StartDateFilter,
  EndDateFilter,
} from 'actions/actions.type';
import type { StrategyObjectivesPriorityFilter } from 'strategy/StrategyObjectives/StrategyObjectives.type';

import type { ActionsLayoutType } from './ActionsLayoutProvider.type';
import type { ActionsLayoutUser } from '../ActionsLayout.type';

export type ActionsLayoutContextValue = {
  actionsSort: ActionsSort;
  clearFilters: () => void;
  endDateFilter: EndDateFilter;
  layout: ActionsLayoutType;
  priorityFilter: StrategyObjectivesPriorityFilter;
  setActionsSort: Dispatch<SetStateAction<ActionsSort>>;
  setEndDateFilter: (value: EndDateFilter) => void;
  setPriorityFilter: (value: StrategyObjectivesPriorityFilter) => void;
  setStartDateFilter: (value: StartDateFilter) => void;
  setStatusFilter: (value: StatusFilter) => void;
  setUserFilter: (value: UserFilter) => void;
  startDateFilter: StartDateFilter;
  statusFilter: StatusFilter;
  toggleLayout: () => void;
  userFilter: UserFilter;
  users: ActionsLayoutUser[];
};

export const ActionsLayoutContext = createContext<
  ActionsLayoutContextValue | undefined
>(undefined);
