import { date } from 'shared/services/date.service';

import type { PreviousInsightsCardData } from './PreviousInsightsSidecard.type';
import type {
  PreviousInsightsChildObjectiveFragment,
  PreviousInsightsInitiativeFragment,
  PreviousInsightsInitiativeStatusFragment,
  PreviousInsightsMetricFragment,
  PreviousInsightsMetricStatusFragment,
  PreviousInsightsObjectiveFragment,
  PreviousInsightsObjectiveStatusFragment,
} from './PreviousInsights.graphql.generated';

const getStrategyItemStatusList = (
  strategyItem:
    | PreviousInsightsObjectiveFragment
    | PreviousInsightsChildObjectiveFragment
    | PreviousInsightsMetricFragment
    | PreviousInsightsInitiativeFragment,
) => {
  switch (strategyItem.__typename) {
    case 'Objective':
      return strategyItem.objectiveStatusListAll;
    case 'Metric':
      return strategyItem.metricStatusListAll;
    case 'Initiative':
      return strategyItem.initiativeStatusListAll;
  }
};

const getStrategyItemData = (
  strategyItem:
    | PreviousInsightsObjectiveFragment
    | PreviousInsightsChildObjectiveFragment
    | PreviousInsightsMetricFragment
    | PreviousInsightsInitiativeFragment,
  limit?: number,
): [PreviousInsightsCardData[], PreviousInsightsCardData[]] => {
  const statusList = getStrategyItemStatusList(strategyItem);

  const breakpoint = limit || statusList.length;

  const recentStatusList = statusList.slice(0, breakpoint);
  const oldStatusList = statusList.slice(breakpoint, statusList.length);

  return [
    recentStatusList.map((status) => getStatusData(strategyItem, status)),
    oldStatusList.map((status) => getStatusData(strategyItem, status)),
  ];
};

const getStatusData = (
  strategyItem:
    | PreviousInsightsObjectiveFragment
    | PreviousInsightsChildObjectiveFragment
    | PreviousInsightsMetricFragment
    | PreviousInsightsInitiativeFragment,
  status:
    | PreviousInsightsObjectiveStatusFragment
    | PreviousInsightsMetricStatusFragment
    | PreviousInsightsInitiativeStatusFragment,
): PreviousInsightsCardData => ({
  statusId: status.id,
  strategyElementId: strategyItem.id,
  typename: strategyItem.__typename,
  name: strategyItem.name,
  author: status.auditRecord.createBy,
  statusDateTime: status.statusDateTime,
  statusIndicator: status.statusIndicator,
  orgUnit: strategyItem.orgUnit,
  objectiveStatus: status.__typename === 'ObjectiveStatus' ? status : undefined,
  metricStatus: status.__typename === 'MetricStatus' ? status : undefined,
  initiativeStatus:
    status.__typename === 'InitiativeStatus' ? status : undefined,
});

const sortChildObjectivesByStatusCreateDate = (
  objectives: Array<PreviousInsightsChildObjectiveFragment>,
) =>
  objectives.sort((previous, next) =>
    date.isAfter(
      previous.currentObjectiveStatus?.statusDateTime,
      next.currentObjectiveStatus?.statusDateTime,
    )
      ? -1
      : 1,
  );

const sortInitiativesByStatusCreateDate = (
  initiatives: Array<PreviousInsightsInitiativeFragment>,
) =>
  initiatives.sort((previousStatus, nextStatus) =>
    date.isAfter(
      previousStatus.currentInitiativeStatus?.statusDateTime,
      nextStatus.currentInitiativeStatus?.statusDateTime,
    )
      ? -1
      : 1,
  );

const sortMetricsByStatusCreateDate = (
  metrics: Array<PreviousInsightsMetricFragment>,
) =>
  metrics.sort((previousStatus, nextStatus) =>
    date.isAfter(
      previousStatus.currentMetricStatus?.statusDateTime,
      nextStatus.currentMetricStatus?.statusDateTime,
    )
      ? 1
      : -1,
  );

export const sortStatusListByStatusDate = (
  statusList?: Array<PreviousInsightsCardData>,
) =>
  statusList?.sort((previousStatus, nextStatus) =>
    date.isAfter(previousStatus.statusDateTime, nextStatus.statusDateTime)
      ? -1
      : 1,
  );

export const flattenData = (objective: PreviousInsightsObjectiveFragment) => {
  const [objectives] = getStrategyItemData(objective);

  const sortedMetrics = sortMetricsByStatusCreateDate([...objective.metrics]);

  const sortedInitiatives = sortInitiativesByStatusCreateDate([
    ...objective.initiatives,
  ]);

  const sortedChildren = sortChildObjectivesByStatusCreateDate([
    ...objective.childObjectives,
  ]);

  const sortedChildrenMetrics = sortMetricsByStatusCreateDate([
    ...objective.childObjectives.flatMap((objective) => objective.metrics),
  ]);
  const sortedChildrenInitiatives = sortInitiativesByStatusCreateDate([
    ...objective.childObjectives.flatMap((objective) => objective.initiatives),
  ]);

  const metricsData = sortedMetrics.map((item) => getStrategyItemData(item, 1));
  const metrics = metricsData.flatMap((data) => data[0]);
  const metricsOlder = metricsData.flatMap((data) => data[1]);

  const initiativesData = sortedInitiatives.map((item) =>
    getStrategyItemData(item, 1),
  );
  const initiatives = initiativesData.flatMap((data) => data[0]);
  const initiativesOlder = initiativesData.flatMap((data) => data[1]);

  const childrenData = sortedChildren.map((item) =>
    getStrategyItemData(item, 1),
  );
  const childObjectives = childrenData.flatMap((data) => data[0]);
  const childObjectivesOlder = childrenData.flatMap((data) => data[1]);

  const childrenMetricsData = sortedChildrenMetrics.map((item) =>
    getStrategyItemData(item, 1),
  );
  const childrenMetrics = childrenMetricsData.flatMap((data) => data[0]);
  const childrenMetricsOlder = childrenMetricsData.flatMap((data) => data[1]);

  const childrenInitiativesData = sortedChildrenInitiatives.map((item) =>
    getStrategyItemData(item, 1),
  );
  const childrenInitiatives = childrenInitiativesData.flatMap(
    (data) => data[0],
  );
  const childrenInitiativesOlder = childrenInitiativesData.flatMap(
    (data) => data[1],
  );

  return {
    objectives,
    metrics,
    metricsOlder,
    initiatives,
    initiativesOlder,
    childObjectives,
    childObjectivesOlder,
    childrenMetrics,
    childrenMetricsOlder,
    childrenInitiatives,
    childrenInitiativesOlder,
  };
};
