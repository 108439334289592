import { createContext } from 'react';

import type { ToastProps } from 'shared/toast/Toast';

export type ToastContextValue = {
  addToast: (props: ToastProps & { id?: string }) => void;
};

export const ToastContext = createContext<ToastContextValue | undefined>(
  undefined,
);
