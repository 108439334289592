import { useTranslation } from 'react-i18next';

import CreateThemeForm from 'themes/CreateThemeModal/CreateThemeForm/CreateThemeForm';
import Dialog from 'shared/components/Dialog';

import ThemeProvider from '../ThemeProvider';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const createThemeFormId = 'createThemeForm';

const CreateThemeModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <ThemeProvider>
      <Dialog
        headerTitle={t('theme.newTheme')}
        isOpen={isOpen}
        onClose={onClose}
        cancelLabel={t('cancel')}
        confirmLabel={t('create')}
        formId={createThemeFormId}
      >
        <CreateThemeForm id={createThemeFormId} onClose={onClose} />
      </Dialog>
    </ThemeProvider>
  );
};

export default CreateThemeModal;
