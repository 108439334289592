import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import sample from 'lodash/sample';
import { trackPromise } from 'react-promise-tracker';

import type { StrategyWizardProps } from 'strategy/StrategyWizard';
import StrategyWizard, {
  strategyWizardPromiseTrackerArea,
} from 'strategy/StrategyWizard';
import { useToasts } from 'shared/toast/useToasts';
import Tag from 'shared/components/__DEPRECATED__/Tag';
import Space from 'shared/components/Space';
import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';
import ConfirmationModal from 'shared/components/__DEPRECATED__/ConfirmationModal';
import type { Strategy } from 'types.graphql.generated';
import useHandleApolloError from 'shared/errors/useHandleApolloError';

import EditStrategyWizardProvider, {
  useEditStrategyWizard,
} from './EditStrategyWizardProvider';
import styles from './EditStrategyWizard.module.scss';
import { useEditStrategyMutation } from './EditStrategyWizard.graphql.generated';
import {
  resolveInitialValues,
  resolveEditStrategyInput,
  modifyStrategyCache,
} from './EditStrategyWizard.utils';

export type EditStrategyWizardProps = Omit<
  StrategyWizardProps,
  'onSubmit' | 'renderHeading' | 'hasActiveCampaign'
> & {
  onSuccess: () => void;
  strategy: Pick<Strategy, 'id'>;
};

const EditStrategyWizard = ({
  onSuccess,
  onClose,
  ...restProps
}: EditStrategyWizardProps) => {
  const { isOpen } = restProps;

  const { t } = useTranslation();

  const { addToast } = useToasts();

  const { strategy } = useEditStrategyWizard();
  const handleApolloError = useHandleApolloError();

  const [editStrategy] = useEditStrategyMutation({
    update: modifyStrategyCache,
    onError: handleApolloError,
  });

  const initialValues = useMemo<StrategyWizardProps['initialValues']>(
    () => resolveInitialValues(strategy),
    [strategy],
  );

  const [isCloseConfirmationModalOpened, setIsCloseConfirmationModalOpened] =
    useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const handleSubmit: StrategyWizardProps['onSubmit'] = async (values) => {
    if (hasUnsavedChanges) {
      await trackPromise(
        editStrategy({
          variables: {
            input: resolveEditStrategyInput({
              values,
              strategy,
            }),
          },
          onCompleted: () => {
            addToast({
              variant: 'success',
              children: sample(
                t('strategy.editStrategyWizard.successToast', {
                  returnObjects: true,
                }),
              ),
            });
            onSuccess();
          },
        }),
        strategyWizardPromiseTrackerArea,
      );
    } else {
      onSuccess();
    }
  };

  const handleChangeStep = useCallback<
    NonNullable<StrategyWizardProps['onChangeStep']>
  >(
    async (values) => {
      if (hasUnsavedChanges) {
        await editStrategy({
          variables: {
            input: resolveEditStrategyInput({
              values,
              strategy,
            }),
          },
          onCompleted: () => {
            setHasUnsavedChanges(false);
          },
        });
      }
    },
    [editStrategy, strategy, hasUnsavedChanges],
  );

  const handleOnUnsavedChanges = useCallback<
    NonNullable<StrategyWizardProps['onUnsavedChanges']>
  >((hasUnsavedChanges) => {
    setHasUnsavedChanges(hasUnsavedChanges);
  }, []);

  const tagContent = strategy.isComplete
    ? 'completed'
    : strategy.isLive
    ? 'live'
    : 'draft';
  const tagVariant =
    strategy.isComplete || strategy.isLive ? 'primary' : 'regular';

  const renderHeading: StrategyWizardProps['renderHeading'] = ({ name }) => (
    <Space isCentered={true} className={styles.heading}>
      {t('strategy.editStrategyWizard.heading', {
        name: name || strategy.name,
      })}
      <Tag variant={tagVariant}>{t(`strategy.${tagContent}`)}</Tag>
      <EditIcon
        title={'Unsaved changes'}
        className={cn(styles.headingIcon, {
          [styles.headingIconVisible]: hasUnsavedChanges,
        })}
      />
    </Space>
  );

  const hasActiveCampaign = strategy.campaigns.some(
    (campaign) => campaign.state.stage === 'ACTIVE',
  );

  return (
    <>
      <StrategyWizard
        {...restProps}
        onClose={() => {
          if (hasUnsavedChanges) {
            setIsCloseConfirmationModalOpened(true);
          } else {
            onClose();
          }
        }}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onChangeStep={handleChangeStep}
        onUnsavedChanges={handleOnUnsavedChanges}
        renderHeading={renderHeading}
        hasActiveCampaign={hasActiveCampaign}
      />
      <ConfirmationModal
        isOpen={isOpen && isCloseConfirmationModalOpened}
        onClose={() => {
          setIsCloseConfirmationModalOpened(false);
        }}
        onConfirm={() => {
          onClose();
        }}
      >
        {t('strategy.editStrategyWizard.confirmationModal.content')}
      </ConfirmationModal>
    </>
  );
};

const EditStrategyWizardWithProvider = (props: EditStrategyWizardProps) => (
  <EditStrategyWizardProvider strategy={props.strategy}>
    <EditStrategyWizard {...props} />
  </EditStrategyWizardProvider>
);

export default EditStrategyWizardWithProvider;
