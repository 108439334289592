import { useDefinedContext } from 'shared/utils/context.utils';

import { FormRefsProviderContext } from './FormRefsProvider.context';
import FormRefsProvider from './FormRefsProvider';

export const useFormRefs = () =>
  useDefinedContext(FormRefsProviderContext, {
    hookName: useFormRefs.name,
    providerName: FormRefsProvider.name,
  });
