import { Trans, useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import type { Metric, MetricStatus } from 'types.graphql.generated';
import Space from 'shared/components/Space';
import Text from 'shared/components/Text';

type MetricStatusWizardScoreSummaryProps = {
  metric: Pick<Metric, 'targetValue' | 'unitOfMeasure'> & {
    currentMetricStatus?: Pick<MetricStatus, 'statusValue' | 'statusDateTime'>;
  };
};

const MetricStatusWizardScoreSummary = ({
  metric,
}: MetricStatusWizardScoreSummaryProps) => {
  const { t } = useTranslation();
  const { targetValue, currentMetricStatus } = metric;
  const statusDate = currentMetricStatus?.statusDateTime;
  const statusValue = currentMetricStatus?.statusValue;

  return (
    <Space>
      {statusDate && statusValue && (
        <Text variant={'emphasis'}>
          <Trans
            i18nKey={
              'metric.metricStatusWizard.steps.score.scoreSummary.previous'
            }
            values={{
              date: format(statusDate, 'P'),
              value: statusValue,
            }}
          >
            <Text variant={'strong'} />
          </Trans>
        </Text>
      )}
      {targetValue != null && (
        <Text variant={'emphasis'}>
          <Trans
            i18nKey={
              'metric.metricStatusWizard.steps.score.scoreSummary.target'
            }
            values={{
              value: targetValue,
            }}
          >
            <Text variant={'strong'} />
          </Trans>
        </Text>
      )}
      {metric.unitOfMeasure && metric.unitOfMeasure !== '' && (
        <Space size={'small'}>
          <Text variant={'emphasis'}>{t('metric.unitOfMeasure')}:</Text>
          <Text variant={'strong'}>{metric.unitOfMeasure}</Text>
        </Space>
      )}
    </Space>
  );
};

export default MetricStatusWizardScoreSummary;
