import { isAfter, startOfDay } from 'date-fns';

import { useStrategy } from 'strategy/StrategyProvider';
import type { Objective } from 'types.graphql.generated';

const useDefaultEndDate = (objective?: Pick<Objective, 'timeLine'>) => {
  const { strategy } = useStrategy();

  const today = startOfDay(new Date());

  if (
    objective?.timeLine.endDate &&
    isAfter(objective.timeLine.endDate, today)
  ) {
    return objective.timeLine.endDate;
  }

  if (
    strategy?.timeLine?.endDate &&
    isAfter(strategy.timeLine.endDate, today)
  ) {
    return strategy.timeLine.endDate;
  }
};

export default useDefaultEndDate;
