import { useTranslation } from 'react-i18next';

import Flex from 'shared/components/Flex';
import Text from 'shared/components/Text';
import {
  groupObjectivesByTheme,
  sortObjectivesByTheme,
} from 'objective/objective.utils';

import MetricSelectionSection from './MetricSelectionSection';
import type {
  LinkMetricDialogMetricFragment,
  LinkMetricDialogObjectiveFragment,
  LinkMetricDialogOrgUnitFragment,
  LinkMetricDialogThemeFragment,
} from './LinkMetricDialog.graphql.generated';

type Props = {
  objectives: LinkMetricDialogObjectiveFragment[];
  orgUnits: LinkMetricDialogOrgUnitFragment[];
  selectedMetric?: LinkMetricDialogMetricFragment;
  setSelectedMetric: (metric?: LinkMetricDialogMetricFragment) => void;
};

const MetricSelection = ({
  objectives,
  orgUnits,
  selectedMetric,
  setSelectedMetric,
}: Props) => {
  const { t } = useTranslation();

  const objectivesByTheme = groupObjectivesByTheme<
    LinkMetricDialogObjectiveFragment,
    LinkMetricDialogThemeFragment
  >(objectives);

  const sortedObjectivesByTheme = sortObjectivesByTheme(
    objectivesByTheme,
    orgUnits.reverse(),
  );

  if (objectivesByTheme.length === 0)
    return (
      <Text isCentered={true}>
        {t('metric.linkMetric.dialog.selection.empty')}
      </Text>
    );

  return (
    <Flex direction={'column'} gap={'2rem'}>
      {[undefined, ...orgUnits].flatMap((orgUnit) =>
        sortedObjectivesByTheme.map(({ theme, objectives }) => (
          <MetricSelectionSection
            key={`${orgUnit?.id}-${theme?.id}`}
            orgUnit={orgUnit}
            theme={theme}
            objectives={objectives}
            selectedMetric={selectedMetric}
            setSelectedMetric={setSelectedMetric}
          />
        )),
      )}
    </Flex>
  );
};

export default MetricSelection;
