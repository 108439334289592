import { i18n } from 'shared/services/i18n.service';
import type { InitiativeStatus, MetricStatus } from 'types.graphql.generated';
import {
  getInitiativesStatsActive,
  getInitiativessCompletedCount,
} from 'initiative/InitiativesContent/InitiativesContentHeader/InitiativesContentHeader.utils';
import {
  getMetricsCompletedCount,
  getMetricsStatsActive,
} from 'metric/MetricsList/MetricsListHeader/MetricsListHeader.utils';

import styles from './ObjectiveOverviewHeader.module.scss';
import type { OverviewObjective } from '../ObjectiveOverview.type';

export const getStats = (objective: Maybe<OverviewObjective>) => {
  const metricsStatsActive = getMetricsStatsActive(objective?.metrics);

  const metricsAndInitiativesStatsDone = getMetricsAndInitiativesDone(
    objective?.metrics,
    objective?.initiatives,
  );

  const initiativesStatsActive = getInitiativesStatsActive(
    objective?.initiatives,
  );

  const statsActive = [
    {
      name: i18n.t('statusIndicator.UNKNOWN.name'),
      value: metricsStatsActive.noStatus + initiativesStatsActive.noStatus,
      color: styles.noStatusColor,
    },
    {
      name: i18n.t('statusIndicator.BLOCKED.name'),
      value: metricsStatsActive.blocked + initiativesStatsActive.blocked,
      color: styles.blockedColor,
    },
    {
      name: i18n.t('statusIndicator.AT_RISK.name'),
      value: metricsStatsActive.atRisk + initiativesStatsActive.atRisk,
      color: styles.atRiskColor,
    },
    {
      name: i18n.t('statusIndicator.ON_TRACK.name'),
      value: metricsStatsActive.onTrack + initiativesStatsActive.onTrack,
      color: styles.onTrackColor,
    },
  ];

  const statDone = {
    name: i18n.t('statusIndicator.COMPLETED.name'),
    value: metricsAndInitiativesStatsDone,
    color: styles.doneColor,
  };

  const hasMetricsOrInitiatives =
    !!objective?.metrics.length || !!objective?.initiatives.length;

  return { hasMetricsOrInitiatives, statsActive, statDone };
};

export const getMetricsAndInitiativesDone = <
  TMetric extends {
    currentMetricStatus?: Pick<MetricStatus, 'statusIndicator'>;
  },
  TInitiative extends {
    currentInitiativeStatus?: Pick<InitiativeStatus, 'statusIndicator'>;
  },
>(
  metrics: Maybe<TMetric[]>,
  initiatives: Maybe<TInitiative[]>,
) => {
  const metricsDoneCount = metrics ? getMetricsCompletedCount(metrics) : 0;
  const initiativesDoneCount = initiatives
    ? getInitiativessCompletedCount(initiatives)
    : 0;
  const metricsAndInitiativesCount = metricsDoneCount + initiativesDoneCount;
  const metricsAndInitiativesLength =
    (metrics?.length ?? 0) + (initiatives?.length ?? 0);

  const statDone =
    (metricsAndInitiativesCount / metricsAndInitiativesLength) * 100;

  return Math.round(statDone);
};
