import { createContext } from 'react';

import type { StrategyObjectivesPriorityFilter } from 'strategy/StrategyObjectives/StrategyObjectives.type';

import type {
  StrategyMetricsObjective,
  StrategyMetricsObjectivesByTheme,
} from './StrategyMetricsProvider.type';
import type {
  StrategyMetricsStatusFilter,
  StrategyMetricsEndDateFilter,
  StrategyMetricsStartDateFilter,
  StrategyMetricsUser,
  StrategyMetricsUserFilter,
} from '../StrategyMetrics.type';

export type StrategyObjectivesMetricsContextValue = {
  filters: {
    clearFilters: () => void;
    endDateFilter: StrategyMetricsEndDateFilter;
    priorityFilter: StrategyObjectivesPriorityFilter;
    setEndDateFilter: (value: StrategyMetricsEndDateFilter) => void;
    setPriorityFilter: (value: StrategyObjectivesPriorityFilter) => void;
    setStartDateFilter: (value: StrategyMetricsStartDateFilter) => void;
    setStatusIndicatorFilter: (value: StrategyMetricsStatusFilter) => void;
    setUserFilter: (value: StrategyMetricsUserFilter) => void;
    startDateFilter: StrategyMetricsStartDateFilter;
    statusIndicatorFilter: StrategyMetricsStatusFilter;
    userFilter: StrategyMetricsUserFilter;
  };
  isStrategyMetricsLoading: boolean;
  objectives: Maybe<StrategyMetricsObjective[]>;
  objectivesByTheme: StrategyMetricsObjectivesByTheme[];
  users: StrategyMetricsUser[];
};

export const StrategyObjectivesMetricsContext = createContext<
  StrategyObjectivesMetricsContextValue | undefined
>(undefined);
