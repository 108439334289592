import type { PropsWithChildren } from 'react';
import { useState, useMemo } from 'react';
import { useToggle } from 'react-use';

import type { ObjectivesSort } from 'actions/actions.type';
import type { Theme, User } from 'types.graphql.generated';
import useHandleApolloError from 'shared/errors/useHandleApolloError';

import type { StrategyObjectivesActionsContextValue } from './StrategyActionsProvider.context';
import { StrategyObjectivesActionsContext } from './StrategyActionsProvider.context';
import {
  useStrategyActionsOrgQuery,
  useStrategyActionsOrgUnitQuery,
} from './StrategyActionsProvider.graphql.generated';
import { getObjectivesWithActions } from './StrategyActions.utils';
import { useTeamAdapter } from '../../team/TeamAdapter';

type StrategyActionsProviderProps = PropsWithChildren<object>;

const StrategyActionsProvider = ({
  children,
}: StrategyActionsProviderProps) => {
  const { teamAdapter } = useTeamAdapter();
  const [showCompletedObjectives, toggleShowCompletedObjectives] =
    useToggle(false);

  const handleApolloError = useHandleApolloError();

  const { data: dataOrg, loading: isDataLoadingOrg } =
    useStrategyActionsOrgQuery({
      variables: {
        filter: {
          orgUnitIds: { operator: 'IS_NULL' },
          timeLine: {
            completeDateTime: {
              operator: !showCompletedObjectives ? 'IS_NULL' : 'IS_NOT_NULL',
            },
          },
        },
      },
      fetchPolicy: 'cache-and-network',
      skip: !teamAdapter.isOrg,
      onError: handleApolloError,
    });

  const { data: dataOrgUnit, loading: isDataLoadingOrgUnit } =
    useStrategyActionsOrgUnitQuery({
      variables: {
        orgUnitId: teamAdapter.keyArg,
        filter: {
          timeLine: {
            completeDateTime: {
              operator: !showCompletedObjectives ? 'IS_NULL' : 'IS_NOT_NULL',
            },
          },
        },
      },
      fetchPolicy: 'cache-and-network',
      skip: teamAdapter.isOrg,
      onError: handleApolloError,
    });

  const [selectedThemes, setSelectedThemes] = useState<
    Array<Theme['id'] | null>
  >([]);

  const [selectedUsers, setSelectedUsers] = useState<
    Array<Pick<User, 'id' | 'displayName' | 'email'> | null>
  >([]);

  const [objectivesSort, setObjectivesSort] =
    useState<ObjectivesSort>('deadlineAsc');

  const objectives = useMemo(
    () =>
      (teamAdapter.isOrg
        ? dataOrg?.activeOrg.objectives
        : dataOrgUnit?.orgUnit.objectives
      )?.edges.map((n) => n.node) || [],
    [teamAdapter.isOrg, dataOrg, dataOrgUnit],
  );
  const isLoading = teamAdapter.isOrg ? isDataLoadingOrg : isDataLoadingOrgUnit;
  const objectivesWithActions = useMemo(
    () => getObjectivesWithActions(objectives),
    [objectives],
  );

  const contextValue = useMemo<StrategyObjectivesActionsContextValue>(
    () => ({
      objectives,
      objectivesWithActions,
      selectedThemes,
      setSelectedThemes,
      selectedUsers,
      setSelectedUsers,
      objectivesSort,
      setObjectivesSort,
      showCompletedObjectives,
      toggleShowCompletedObjectives,
      isStrategyActionsLoading: isLoading && !objectives,
    }),
    [
      objectives,
      isLoading,
      objectivesWithActions,
      selectedThemes,
      selectedUsers,
      objectivesSort,
      showCompletedObjectives,
      toggleShowCompletedObjectives,
    ],
  );

  return (
    <StrategyObjectivesActionsContext.Provider value={contextValue}>
      {children}
    </StrategyObjectivesActionsContext.Provider>
  );
};

export default StrategyActionsProvider;
