import type { PropsWithChildren } from 'react';
import { useCallback, useState } from 'react';
import { css } from '@emotion/react';

import type { ButtonVariant } from 'shared/components/Button';
import Button from 'shared/components/Button';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import type { CreateObjectiveProps } from 'objective/CreateObjectiveButton/CreateObjective/CreateObjective';
import CreateObjective from 'objective/CreateObjectiveButton/CreateObjective/CreateObjective';
import type { StrategyElementWithAuthorizedActions } from 'user/ability/canPerformStrategyElementAction';
import { canCreateStrategyElement } from 'user/ability/canPerformStrategyElementAction';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useActiveOrg } from 'org/ActiveOrgProvider';

type Props = PropsWithChildren<{
  className?: string;
  isHidden?: boolean;
  parentObjective?: CreateObjectiveProps['parentObjective'] &
    StrategyElementWithAuthorizedActions;
  showSuccessDialog?: boolean;
  theme?: CreateObjectiveProps['theme'];
  variant?: ButtonVariant;
}>;

const CreateObjectiveButton = ({
  className,
  variant,
  parentObjective,
  theme,
  children,
  isHidden,
  showSuccessDialog,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);

  const isAllowed = canCreateStrategyElement(
    activeOrg,
    orgUnit,
    parentObjective,
  );

  const isVisible = isAllowed && !isHidden;

  if (!isVisible) return null;

  return (
    <>
      <Button
        onClick={handleOpen}
        variant={variant}
        icon={PlusIcon}
        iconPosition={'start'}
        className={className}
      >
        {children}
      </Button>

      <div css={css({ display: 'none' })}>
        <CreateObjective
          isOpen={isOpen}
          onClose={handleClose}
          parentObjective={parentObjective}
          theme={theme}
          showSuccessDialog={showSuccessDialog}
        />
      </div>
    </>
  );
};

export default CreateObjectiveButton;
