import { format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { css, useTheme } from '@emotion/react';

import { ReactComponent as CalendarIcon } from 'shared/static/icons/icon-calendar.svg';
import type { TimeLine } from 'types.graphql.generated';

import Flex from '../Flex';

type Props = {
  timeLine?: TimeLine;
};

const TimelineRange = ({ timeLine }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const timelineRangeText = useMemo(() => {
    const dateEndTime = timeLine?.endDate ?? timeLine?.completeDateTime;

    const hasDates = !!(timeLine?.startDate || dateEndTime);

    const startDateText = timeLine?.startDate
      ? format(timeLine?.startDate, 'MMM yyyy')
      : t('notSet');

    const endDateText = dateEndTime
      ? format(timeLine?.endDate ?? timeLine?.completeDateTime, 'MMM yyyy')
      : t('notSet');

    return hasDates ? `${startDateText} - ${endDateText}` : null;
  }, [t, timeLine?.completeDateTime, timeLine?.endDate, timeLine?.startDate]);

  if (!timelineRangeText) return null;

  return (
    <Flex gap={6} css={css({ color: theme.color.typoSecondary })} shrink={0}>
      <CalendarIcon width={14} />
      {timelineRangeText}
    </Flex>
  );
};

export default TimelineRange;
