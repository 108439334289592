import type { PropsWithChildren } from 'react';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { range } from 'lodash';

import { useUser } from 'user/UserProvider';
import Spinner from 'shared/spinner/Spinner';
import type { CampaignQuestionType } from 'types.graphql.generated';
import useHandleApolloError from 'shared/errors/useHandleApolloError';

import { useChat } from './ChatProvider';
import getQuestions from './getQuestions';
import { useCampaignQuestionsQuery } from './CampaignQuestions.graphql.generated';
import { useCampaignResponsesQuery } from './CampaignResponses.graphql.generated';
import type { SurveyQuestion } from './QuestionsProvider.context';
import QuestionsContext from './QuestionsProvider.context';

const QuestionsProvider = ({ children }: PropsWithChildren) => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const { setMessages } = useChat();
  const [latestVisitedQuestionIndex, setLatestVisitedQuestionIndex] =
    useState<number>();
  const [isReadOnly, setIsReadOnly] = useState<boolean>();
  const [isReadOnlyInitialized, setIsReadOnlyInitialized] = useState(false);

  if (!campaignId) throw new Error('campaignId param missing');

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useUser();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleApolloError = useHandleApolloError();

  const { data: responsesData } = useCampaignResponsesQuery({
    variables: { campaignId },
    onError: handleApolloError,
  });
  const { data: questionsData } = useCampaignQuestionsQuery({
    variables: { campaignId },
    onError: handleApolloError,
  });

  const isCampaignActive = questionsData
    ? questionsData.campaign.state.stage === 'ACTIVE'
    : undefined;

  useEffect(() => {
    if (
      !responsesData ||
      isCampaignActive === undefined ||
      isReadOnlyInitialized
    )
      return;

    const isUserDone =
      responsesData.campaign.currentUserResponse?.status === 'DONE';

    setIsReadOnly(!isCampaignActive || isUserDone);
    setIsReadOnlyInitialized(true);
  }, [isCampaignActive, isReadOnlyInitialized, questionsData, responsesData]);

  const goToNextQuestion = useCallback(() => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (
      latestVisitedQuestionIndex !== undefined &&
      latestVisitedQuestionIndex > currentQuestionIndex
    ) {
      return;
    }

    setLatestVisitedQuestionIndex(currentQuestionIndex);
  }, [currentQuestionIndex, latestVisitedQuestionIndex]);

  const allQuestions = useMemo(() => {
    if (!questionsData) return null;

    const close = () => navigate(-1);

    return getQuestions({
      campaignId,
      goToNextQuestion,
      campaignOwnerName: questionsData.campaign.owner.displayName,
      campaignOwnerEmail: questionsData.campaign.owner.email,
      campaignOwnerPhotoUrl: questionsData.campaign.owner.photoUrl,
      org:
        questionsData.campaign.orgUnit?.name ||
        questionsData.activeOrg.displayName,
      strategy: questionsData.campaign.strategy,
      objectives:
        questionsData.campaign.orgUnit?.objectivesTopLevel ||
        questionsData.activeOrg.objectivesTopLevel,
      themes: questionsData.campaign.strategy.themes,
      orgUnitTree: questionsData.campaign.orgUnit
        ? [
            ...questionsData.campaign.orgUnit.parentOrgUnitTree,
            questionsData.campaign.orgUnit,
          ].reverse()
        : [],
      close,
      t,
      user: user.displayName || user.email,
    });
  }, [
    campaignId,
    goToNextQuestion,
    navigate,
    questionsData,
    t,
    user.displayName,
    user.email,
  ]);

  const questions = useMemo(() => {
    if (!allQuestions || !questionsData || !responsesData) return null;

    return allQuestions.filter((question) =>
      !question.type
        ? true
        : isReadOnly
        ? hasAnswer(
            responsesData?.campaign.currentUserResponse?.answers,
            question.type,
          )
        : hasQuestionType(questionsData.campaign.questions, question.type),
    );
  }, [allQuestions, isReadOnly, questionsData, responsesData]);

  useEffect(() => {
    if (!responsesData || !isReadOnly || !questions) return;
    const lastQuestionIndex = questions.length - 1;
    setLatestVisitedQuestionIndex(lastQuestionIndex);
  }, [isReadOnly, questions, responsesData]);

  const setCurrentQuestion = useCallback(
    (question: SurveyQuestion) => {
      if (!questions) return;

      const index = (questions as SurveyQuestion[]).indexOf(question);

      if (index === undefined) return;

      setCurrentQuestionIndex(index);
    },
    [questions],
  );

  const currentQuestion = questions?.[currentQuestionIndex];

  useEffect(() => {
    if (currentQuestion) {
      setMessages(currentQuestion.messages);
    }
  }, [currentQuestion, setMessages]);

  const visitedQuestions = useMemo(() => {
    if (!questions || latestVisitedQuestionIndex === undefined) return [];

    return range(0, latestVisitedQuestionIndex + 1).map(
      (index) => questions[index],
    );
  }, [latestVisitedQuestionIndex, questions]);

  const value = useMemo(
    () =>
      questionsData &&
      questions &&
      currentQuestion &&
      isCampaignActive !== undefined &&
      isReadOnly !== undefined && {
        isCampaignActive,
        isReadOnly,
        questions,
        currentQuestion,
        currentQuestionIndex,
        setCurrentQuestion,
        visitedQuestions,
      },
    [
      questionsData,
      questions,
      currentQuestion,
      isCampaignActive,
      isReadOnly,
      currentQuestionIndex,
      setCurrentQuestion,
      visitedQuestions,
    ],
  );

  if (!value) {
    return <Spinner.Circle />;
  }

  return (
    <QuestionsContext.Provider value={value}>
      {children}
    </QuestionsContext.Provider>
  );
};

export default QuestionsProvider;

type Question = { questionType: CampaignQuestionType };

const hasQuestionType = (
  questions: Question[],
  questionType: CampaignQuestionType,
) => questions.some((question) => question.questionType === questionType);

type Answer = { question: Question };

const hasAnswer = (
  answers: Answer[] | undefined = [],
  questionType: CampaignQuestionType,
) => answers.some((answer) => answer.question.questionType === questionType);
