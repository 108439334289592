import type { PropsWithChildren } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

import GlobalSpinner from 'shared/spinner/GlobalSpinner';
import { teamRoutesPaths } from 'team/team.routing.paths';
import { TeamAdapter } from 'team/TeamAdapter';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { ActingOrg } from 'org/ActingOrg';

import { ActiveOrgProviderContext } from './ActiveOrgProvider.context';
import type { ActiveOrgProviderContextValue } from './ActiveOrgProvider.context';
import { useActiveOrgQuery } from './ActiveOrgProvider.graphql.generated';

type ActiveOrgProviderProps = PropsWithChildren<object>;

const ActiveOrgProvider = ({ children }: ActiveOrgProviderProps) => {
  const navigate = useNavigate();

  const apolloClient = useApolloClient();
  const onError = useHandleApolloError();

  const [isResetingCache, setIsResetingCache] = useState(false);

  const { data, loading } = useActiveOrgQuery({
    notifyOnNetworkStatusChange: true,
    onError,
  });

  const setActiveOrg = useCallback<
    ActiveOrgProviderContextValue['setActiveOrg']
  >(
    (orgKey) => {
      setIsResetingCache(true);

      setTimeout(async () => {
        ActingOrg.setActingOrg(orgKey);

        await apolloClient.cache.reset();

        const teamSlug = TeamAdapter.fromOrg({ orgKey }).toParam();
        const orgRootPath = teamRoutesPaths.root({ params: { teamSlug } });
        navigate(orgRootPath);

        setIsResetingCache(false);
      }, 100);
    },
    [apolloClient.cache, navigate],
  );

  const contextValue = useMemo<Maybe<ActiveOrgProviderContextValue>>(
    () => (data ? { activeOrg: data.activeOrg, setActiveOrg } : undefined),
    [data, setActiveOrg],
  );

  if (isResetingCache) return null;

  if (loading || !contextValue) return <GlobalSpinner />;

  return (
    <ActiveOrgProviderContext.Provider value={contextValue}>
      {children}
    </ActiveOrgProviderContext.Provider>
  );
};

export default ActiveOrgProvider;
