import cn from 'classnames';

import Chip from 'shared/components/Chip';

import type {
  BaseChipGroupItem,
  ChipGroupOnRemoveFn,
  RenderLabelFn,
} from './ChipGroup.type';
import styles from './ChipGroup.module.scss';
import ClearAllButton from '../ClearAllButton';

export type ChipGroupProps<
  ChipGroupItem extends BaseChipGroupItem = BaseChipGroupItem,
> = {
  className?: string;
  items: ChipGroupItem[];
  onRemove: ChipGroupOnRemoveFn<ChipGroupItem>;
  onRemoveAll?: () => void;
  renderLabel?: RenderLabelFn<ChipGroupItem>;
};

/**
 * Allow a user to add or remove items described by keywords, within a group.
 */
const ChipGroup = <
  ChipGroupItem extends BaseChipGroupItem = BaseChipGroupItem,
>({
  items,
  onRemove,
  onRemoveAll,
  className,
  renderLabel,
}: ChipGroupProps<ChipGroupItem>) => (
  <div className={cn(styles.chipGroup, className)}>
    {items.map((item) => {
      const label = renderLabel ? renderLabel(item) : item.title;
      return (
        <Chip
          key={item.id}
          onRemove={() => {
            onRemove(item);
          }}
        >
          {label}
        </Chip>
      );
    })}
    <ClearAllButton onClear={onRemoveAll} />
  </div>
);

export default ChipGroup;
