import compact from 'lodash/compact';

import type { UserProfileFragment } from 'user/userProfile.graphql.generated';
import type { User } from 'types.graphql.generated';

import type { UserEmailInputProviderProps } from './UserEmailInputProvider';
import type { UserEmailInputItem } from '../UserEmailInput.type';

export const getUserEmailInputItems = (params: {
  users?: Pick<
    User,
    'id' | 'email' | 'displayName' | 'photoUrl' | 'isActive'
  >[];
  usersToExclude?: UserEmailInputProviderProps['usersToExclude'];
}): UserEmailInputItem[] => {
  const { users, usersToExclude = [] } = params;
  return users
    ? compact(
        users.map((user) => {
          const isUserExcluded = usersToExclude.some(
            (userToExclude) => userToExclude.id === user.id,
          );
          if (user.email && !isUserExcluded) {
            return {
              id: user.id,
              email: user.email,
              photoUrl: user.photoUrl,
              displayName: user.displayName,
              isActive: user.isActive,
            };
          }
          return undefined;
        }),
      )
    : [];
};

export const sortUsersAlphabetically = (
  users: UserProfileFragment[],
): UserProfileFragment[] =>
  [...users].sort((previousUser, nextUser) => {
    if (previousUser.displayName && nextUser.displayName) {
      return previousUser.displayName.localeCompare(nextUser.displayName);
    } else if (previousUser.email && nextUser.email) {
      return previousUser.email.localeCompare(nextUser.email);
    }
    return -1;
  });
