import { createContext } from 'react';

import type { StrategyRoomStrategyFragment } from './StrategyRoomProvider.graphql.generated';

export type StrategyRoomProviderContextValue = {
  refetchStrategies: () => Promise<unknown>;
  strategies: StrategyRoomStrategyFragment[];
};

export const StrategyRoomProviderContext =
  createContext<Maybe<StrategyRoomProviderContextValue>>(undefined);
