import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import type { ReactNode } from 'react';
import { useMemo, useState } from 'react';

import ReportsTable from 'report/ReportsTable';
import Spinner from 'shared/spinner/Spinner';
import Text from 'shared/components/Text';
import DeleteReportConfirmationModal from 'report/DeleteReportConfirmationModal';
import type { Report } from 'types.graphql.generated';
import { teamRoutesPaths } from 'team/team.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';
import useCanPerformOrgOrOrgUnitAction from 'user/ability/useCanPerformOrgOrOrgUnitAction';

import StrategyInsightsReportsProvider, {
  useStrategyInsightsReports,
} from './StrategyInsightsReportsProvider';
import EditReport from './StrategyInsightsReportsEditReport';
import ViewReport from './StrategyInsightsReportsViewReport';
import CreateReport from './StrategyInsightsReportsCreateReport';

const StrategyInsightsReports = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { teamAdapter } = useTeamAdapter();
  const { reports, areReportsLoading } = useStrategyInsightsReports();

  const [reportIdToDelete, setReportIdToDelete] =
    useState<Maybe<Report['id']>>();
  const isDeleteReportConfirmationModalOpen = !!reportIdToDelete;
  const closeDeleteReportConfirmationModal = () => {
    setReportIdToDelete(undefined);
  };

  const canManage = useCanPerformOrgOrOrgUnitAction('WRITE_STRATEGY', 'WRITE');

  const table = useMemo<ReactNode>(() => {
    if (reports?.length) {
      return (
        <ReportsTable
          reports={reports}
          onDeleteReport={(reportId) => {
            setReportIdToDelete(reportId);
          }}
          onEditReport={(reportId, step) => {
            navigate({
              pathname: teamRoutesPaths.insights.reports.edit({
                params: {
                  teamSlug: teamAdapter.toParam(),
                  reportId,
                },
              }),
              search: step ? `?step=${step}` : '',
            });
          }}
          onViewReport={(reportId) => {
            navigate(
              teamRoutesPaths.insights.reports.view({
                params: {
                  teamSlug: teamAdapter.toParam(),
                  reportId,
                },
              }),
            );
          }}
          onClickReportName={(report) => {
            if (report.reportStage === 'PUBLISHED') {
              navigate(
                teamRoutesPaths.insights.reports.view({
                  params: {
                    teamSlug: teamAdapter.toParam(),
                    reportId: report.id,
                  },
                }),
              );
            } else if (canManage) {
              navigate(
                teamRoutesPaths.insights.reports.edit({
                  params: {
                    teamSlug: teamAdapter.toParam(),
                    reportId: report.id,
                  },
                }),
              );
            }
          }}
        />
      );
    }

    if (areReportsLoading) {
      return <Spinner.Circle />;
    }

    return (
      <Text variant={'strong'}>
        {t('strategy.strategyInsights.strategyInsightsReports.noReports')}
      </Text>
    );
  }, [reports, areReportsLoading, t, navigate, teamAdapter, canManage]);

  return (
    <>
      {table}
      <Outlet />
      {reportIdToDelete && (
        <DeleteReportConfirmationModal
          report={{ id: reportIdToDelete }}
          isOpen={isDeleteReportConfirmationModalOpen}
          onClose={closeDeleteReportConfirmationModal}
        />
      )}
    </>
  );
};

const StrategyInsightsReportsWithProvider = () => (
  <StrategyInsightsReportsProvider>
    <StrategyInsightsReports />
  </StrategyInsightsReportsProvider>
);

StrategyInsightsReportsWithProvider.EditReport = EditReport;
StrategyInsightsReportsWithProvider.ViewReport = ViewReport;
StrategyInsightsReportsWithProvider.CreateReport = CreateReport;

export default StrategyInsightsReportsWithProvider;
