import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { useToasts } from 'shared/toast/useToasts';
import { StrategyRoomThemesManageObjectivesDocument } from 'strategy/StrategyRoomThemesManageObjectives/StrategyRoomThemesManageObjectives.graphql.generated';
import { StrategyRoomThemesManageThemesDocument } from 'strategy/StrategyRoomThemesManageThemes/StrategyRoomThemesManageThemes.graphql.generated';
import { useTeamAdapter } from 'team/TeamAdapter';
import ThemeForm from 'themes/ThemeForm/ThemeForm';
import type { ThemeFormValues } from 'themes/ThemeForm/ThemeForm.type';
import { useAddThemeMutation } from 'themes/ThemeProvider/ThemeProvider.graphql.generated';

export type CreateThemeFormProps = {
  id: string;
  onClose: () => void;
};

const CreateThemeForm = ({ id, onClose }: CreateThemeFormProps) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { teamAdapter } = useTeamAdapter();

  const handleApolloError = useHandleApolloError();

  const [addTheme] = useAddThemeMutation({
    onError: handleApolloError,
    refetchQueries: [
      StrategyRoomThemesManageObjectivesDocument,
      StrategyRoomThemesManageThemesDocument,
    ],
  });

  const handleSubmit = useCallback(
    async (values: ThemeFormValues) => {
      const themeCategory = values.themeCategory
        ? { idToSet: values.themeCategory.id }
        : null;

      await addTheme({
        variables: {
          input: { ...teamAdapter.toInput(), ...values, themeCategory },
        },
      });

      addToast({
        children: t('theme.toast.create.success', { themeName: values.name }),
        variant: 'success',
      });

      onClose();
    },
    [addTheme, teamAdapter, addToast, t, onClose],
  );

  return <ThemeForm id={id} onSubmit={handleSubmit} />;
};

export default CreateThemeForm;
