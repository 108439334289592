import { useTranslation } from 'react-i18next';

import AiSuggestions from 'ai/AiSuggestions';
import AiSuggestionsInputField from 'ai/AiSuggestions/AiSuggestionsInputField';
import AiSuggestionsVariableField from 'ai/AiSuggestions/AiSuggestionsVariableField';
import SuggestionsProvider from 'ai/AiSuggestions/SuggestionsProvider';

import {
  MetricSuggestionsDocument,
  type MetricSuggestionsSubscription,
  type MetricSuggestionsSubscriptionVariables,
} from './MetricSuggestions.graphql.generated';

type Props = {
  shouldReinitialize: boolean;
};

const MetricSuggestions = ({ shouldReinitialize }: Props) => {
  const { t } = useTranslation();

  return (
    <SuggestionsProvider<
      MetricSuggestionsSubscription,
      MetricSuggestionsSubscriptionVariables
    >
      subscriptionDocument={MetricSuggestionsDocument}
      hasVariables={(variables) => !!variables?.objectiveName}
      shouldReinitialize={shouldReinitialize}
    >
      <AiSuggestions
        title={t('metric.askSophie.title')}
        buttonLabel={t('metric.askSophie.suggestButton')}
        buttonLabelAfterFirstTrigger={t('metric.askSophie.suggestMoreButton')}
        suggestionAction={'copy'}
        titleFieldName={'name'}
      />
      <AiSuggestionsVariableField<MetricSuggestionsSubscriptionVariables>
        variableName={'objectiveName'}
        fieldName={'objective'}
        fieldProperty={'value.name'}
      />
      <AiSuggestionsInputField suggestionField={'name'} />
      <AiSuggestionsInputField suggestionField={'description'} />
    </SuggestionsProvider>
  );
};

export default MetricSuggestions;
