import { css } from '@emotion/react';

import Flex from 'shared/components/Flex';
import StrategyElementIcon from 'shared/components/StrategyElementIcon';
import StrategyElementStatusMark from 'shared/components/StrategyElementStatusMark/StrategyElementStatusMark';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import SideCardItemButton from 'shared/components/SideCardItemButton';
import useAddValueToArrayField from 'shared/hooks/useAddValueToArrayField';
import type { StatusCommentType } from 'types.graphql.generated';
import type { StatusCommentItem } from 'shared/status/StatusCommentsForm/StatusCommentsForm.type';
import { createStatusCommentItem } from 'shared/status/StatusCommentsForm/StatusCommentsForm.utils';
import Tooltip from 'shared/components/Tooltip';
import type { UserProfile } from 'user/user.type';
import UserAvatar from 'user/UserAvatar';

import type {
  ViewReportCommenstActionFragment,
  ViewReportCommenstInitiativeFragment,
  ViewReportCommenstMetricFragment,
  ViewReportCommentsObjectiveFragment,
} from './AllReportComments.graphql.generated';

const strategyElementNameStyle = css`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {
  author?: UserProfile;
  commentType: StatusCommentType;
  comments: string[];
  strategyElement:
    | ViewReportCommentsObjectiveFragment
    | ViewReportCommenstMetricFragment
    | ViewReportCommenstInitiativeFragment
    | ViewReportCommenstActionFragment;
};

const StrategyItem = ({
  strategyElement,
  commentType,
  comments,
  author,
}: Props) => {
  const { addValue } = useAddValueToArrayField(
    commentType,
    (value) => createStatusCommentItem({ text: value, type: commentType }),
    (item: StatusCommentItem) => item.text,
  );

  if (!comments.length) return null;

  return (
    <Flex direction={'column'} gap={'0.5rem'}>
      <Flex alignItems={'center'} gap={'0.5rem'}>
        <StrategyElementIcon type={strategyElement.__typename} />
        <StrategyElementStatusMark strategyElement={strategyElement} />
        <Tooltip content={strategyElement.name} css={strategyElementNameStyle}>
          {strategyElement.name}
        </Tooltip>
      </Flex>
      <Flex
        direction={'column'}
        gap={'0.25rem'}
        css={css({ marginLeft: '0.1rem' })}
      >
        {comments.map((comment, index) => (
          <Flex key={`${index}-${comment}`} alignItems={'flex-start'} gap={8}>
            <UserAvatar user={author} size={'small'} showTooltip={true} />
            <div css={css({ flexGrow: 1 })}>{comment}</div>
            <SideCardItemButton onClick={() => addValue(comment)}>
              <PlusIcon />
            </SideCardItemButton>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default StrategyItem;
