import type {
  StrategyElement,
  StrategyElementAuthorizedActions,
} from 'types.graphql.generated';

import {
  canPerformOrgUnitAction,
  type OrgUnitWithAuthorizedActions,
} from './canPerformOrgUnitAction';
import {
  canPerformOrgAction,
  type OrgWithAuthorizedActions,
} from './canPerformOrgAction';

export type StrategyElementWithAuthorizedActions = Pick<
  StrategyElement,
  'currentUserAuthorizedActions'
>;

export function canPerformStrategyElementAction(
  strategyElement: StrategyElementWithAuthorizedActions,
  action: StrategyElementAuthorizedActions,
) {
  return strategyElement.currentUserAuthorizedActions.includes(action);
}

export function canCreateStrategyElement(
  org: OrgWithAuthorizedActions,
  orgUnit?: OrgUnitWithAuthorizedActions | null,
  objective?: StrategyElementWithAuthorizedActions | null,
) {
  return objective
    ? canPerformStrategyElementAction(objective, 'WRITE')
    : orgUnit
    ? canPerformOrgUnitAction(orgUnit, 'WRITE_STRATEGY_ELEMENT')
    : canPerformOrgAction(org, 'WRITE_STRATEGY_ELEMENT');
}
