import { Link } from 'react-router-dom';
import cn from 'classnames';

import { ReactComponent as ArrowRightIcon } from 'shared/static/icons/icon-arrow-right.svg';
import type { BreadcrumbsItem } from 'shared/components/Breadcrumbs';
import Breadcrumbs from 'shared/components/Breadcrumbs';

import styles from './LayoutHeaderTopSection.module.scss';
import type { LinkItem } from './LayoutHeaderTopSection.type';

export type LayoutHeaderTopSectionProps = {
  breadcrumbs?: BreadcrumbsItem[];
  className?: string;
  topRightLinkItem: Maybe<LinkItem>;
};

const LayoutHeaderTopSection = ({
  breadcrumbs,
  topRightLinkItem,
  className,
}: LayoutHeaderTopSectionProps) => (
  <div className={cn(styles.topSection, className)}>
    {breadcrumbs?.length ? <Breadcrumbs items={breadcrumbs} /> : null}
    {topRightLinkItem ? (
      <nav className={styles.navLink}>
        <Link to={topRightLinkItem.to} className={styles.link}>
          {topRightLinkItem.name}
          <div className={styles.navLinkIconContainer}>
            <ArrowRightIcon className={styles.navLinkIcon} />
          </div>
        </Link>
      </nav>
    ) : null}
  </div>
);

export default LayoutHeaderTopSection;
