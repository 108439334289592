import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import { useUser } from 'user/UserProvider';
import Spinner from 'shared/spinner/Spinner';
import useHandleApolloError from 'shared/errors/useHandleApolloError';

import {
  useMyTeamsFromCurrentUserQuery,
  useMyTeamsFromActiveOrgQuery,
} from './MyTeamsProvider.graphql.generated';
import type { MyTeamsContextValue } from './MyTeamsProvider.context';
import { MyTeamsContext } from './MyTeamsProvider.context';

type MyTeamsProviderProps = PropsWithChildren<object>;

const MyTeamsProvider = ({ children }: MyTeamsProviderProps) => {
  const {
    user: { isInActiveOrg },
  } = useUser();

  const handleApolloError = useHandleApolloError();

  const {
    data: myTeamsFromCurrentUserData,
    loading: myTeamsFromCurrentUserLoading,
    refetch: refetchMyTeamsFromCurrentUser,
  } = useMyTeamsFromCurrentUserQuery({
    skip: !isInActiveOrg,
    onError: handleApolloError,
  });

  const {
    data: myTeamsFromActiveOrgData,
    loading: myTeamsFromActiveOrgLoading,
    refetch: refetchMyTeamsFromActiveOrg,
  } = useMyTeamsFromActiveOrgQuery({
    skip: isInActiveOrg,
    onError: handleApolloError,
  });

  const loading = myTeamsFromCurrentUserLoading || myTeamsFromActiveOrgLoading;

  const contextValue = useMemo<Maybe<MyTeamsContextValue>>(() => {
    if (isInActiveOrg && myTeamsFromCurrentUserData) {
      const { org, orgUnit } = myTeamsFromCurrentUserData.currentUser;
      return {
        org: org,
        refetchMyTeams: refetchMyTeamsFromCurrentUser,
        ...(orgUnit && {
          primaryOrgUnit: orgUnit,
        }),
      };
    } else if (!isInActiveOrg && myTeamsFromActiveOrgData) {
      return {
        org: myTeamsFromActiveOrgData.activeOrg,
        refetchMyTeams: refetchMyTeamsFromActiveOrg,
      };
    }
    return undefined;
  }, [
    isInActiveOrg,
    myTeamsFromCurrentUserData,
    myTeamsFromActiveOrgData,
    refetchMyTeamsFromActiveOrg,
    refetchMyTeamsFromCurrentUser,
  ]);

  if (contextValue) {
    return (
      <MyTeamsContext.Provider value={contextValue}>
        {children}
      </MyTeamsContext.Provider>
    );
  }

  if (loading) {
    return <Spinner.Circle />;
  }

  return null;
};

export default MyTeamsProvider;
