import type {
  StatusCommentInput,
  StatusCommentListInput,
  StatusCommentType,
} from 'types.graphql.generated';
import { resolveListInput } from 'shared/graphql/utils';
import type {
  StatusCommentItem,
  StatusCommentsFormValues,
} from 'shared/status/StatusCommentsForm';
import type { InsightReportWizardValues } from 'report/InsightReportWizard/InsightReportWizard';
import type { SelectableStrategyItemsValues } from 'strategy/SelectableStrategyItems';

import type { InsightReportWizardReportQuery } from './InsightReportWizard.graphql.generated';

export const resolveStatusCommentListInput = (params: {
  newComments: StatusCommentsFormValues;
  previousComments: InsightReportWizardReportQuery['report']['reportStatus']['statusComments'];
  type: StatusCommentType;
}): StatusCommentListInput => {
  const { type, newComments, previousComments } = params;
  return resolveListInput({
    previousItems: previousComments,
    newItems: newComments[type] || [],
    resolveInput: (statusCommentItem): StatusCommentInput => ({
      type: statusCommentItem.type,
      text: statusCommentItem.text,
    }),
    includeInput: (input) => !!input.text,
  });
};

const resolveObjectivesInitialValues = (
  report: InsightReportWizardReportQuery['report'],
): SelectableStrategyItemsValues =>
  report.reportSelection.reportObjectives.reduce<SelectableStrategyItemsValues>(
    (accumulator, reportObjective) => ({
      objectiveIds: [...accumulator.objectiveIds, reportObjective.objective.id],
      metricIds: [
        ...accumulator.metricIds,
        ...reportObjective.reportMetrics.map(
          (reportMetric) => reportMetric.metric.id,
        ),
      ],
      initiativeIds: [
        ...accumulator.initiativeIds,
        ...reportObjective.reportInitiatives.map(
          (reportInitiative) => reportInitiative.initiative.id,
        ),
      ],
    }),
    { objectiveIds: [], metricIds: [], initiativeIds: [] },
  );

export const resolveStatusCommentsInitialValues = (
  report: NonNullable<InsightReportWizardReportQuery['report']>,
): Pick<InsightReportWizardValues, 'successes' | 'challenges' | 'actions'> => {
  const statusComments = report.reportStatus.statusComments;
  const { ACTION_COMMENT, CHALLENGE_COMMENT, SUCCESS_COMMENT } =
    Object.fromEntries(
      ['ACTION_COMMENT', 'CHALLENGE_COMMENT', 'SUCCESS_COMMENT'].map(
        (statusCommentType) => {
          const commentsOfStatusType = statusComments.filter(
            (statusComment) => statusComment.type === statusCommentType,
          );
          return [statusCommentType, commentsOfStatusType];
        },
      ),
    ) as unknown as Record<StatusCommentType, StatusCommentItem[]>;
  return {
    actions: {
      ACTION_COMMENT,
    },
    challenges: {
      CHALLENGE_COMMENT,
    },
    successes: {
      SUCCESS_COMMENT,
    },
  };
};

export const resolveInitialValues = (
  report: NonNullable<InsightReportWizardReportQuery['report']>,
): InsightReportWizardValues => ({
  ...resolveObjectivesInitialValues(report),
  ...resolveStatusCommentsInitialValues(report),
});
