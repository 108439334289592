import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Layout from 'shared/components/Layout';
import { useTeamAdapter } from 'team/TeamAdapter';
import CampaignContextMenu from 'toolkit/WalkOfStrategyCampaigns/CampaignContextMenu';
import CampaignStageTag from 'toolkit/CampaignStageTag/CampaignStageTag';
import CampaignDetailsSubheader from 'toolkit/CampaignDetailsSubheader';

import {
  getBreadcrumbItems,
  getLinkTabsItems,
} from './WalkOfStrategyCampaignResultsLayout.utils';
import { useCampaignResults } from './CampaignResultsProvider';

const WalkOfStrategyCampaignResultsLayout = () => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();
  const { campaign } = useCampaignResults();

  return (
    <>
      <Layout.Header
        heading={campaign.name}
        description={
          <CampaignDetailsSubheader
            orgUnitName={campaign.orgUnit?.name}
            startDate={campaign.timeLine.startDate}
            deadline={campaign.deadline}
            endDate={campaign.timeLine.endDate}
          />
        }
        breadcrumbsItems={getBreadcrumbItems(t, teamAdapter)}
        navigationItems={getLinkTabsItems(t, teamAdapter, campaign.id)}
      >
        <CampaignStageTag stage={campaign.state.stage} />
        <CampaignContextMenu
          campaignId={campaign.id}
          campaignStage={campaign.state.stage}
        />
      </Layout.Header>
      <Outlet />
    </>
  );
};

export default WalkOfStrategyCampaignResultsLayout;
