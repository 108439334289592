import type { AnchorProps } from 'shared/components/Anchor';
import Anchor from 'shared/components/Anchor';
import Space from 'shared/components/Space';

import Provider, { useScrollspy } from './ScrollspyProvider';
import Waypoint from './ScrollspyWaypoint';

type ScrollspyAnchor = AnchorProps & {
  waypointId: string;
};

export type ScrollspyProps = {
  anchors: ScrollspyAnchor[];
  className?: string;
};

const Scrollspy = ({ anchors, className }: ScrollspyProps) => {
  const { activeWaypointId, setActiveWaypointId, setIsChangeBlocked } =
    useScrollspy();

  const handleAnchorClick = (waypointId: string) => {
    document.getElementById(waypointId)?.scrollIntoView({
      block: 'start',
      behavior: 'auto',
    });
    setTimeout(() => {
      setIsChangeBlocked(false);
    });
  };

  return (
    <Space direction={'vertical'} className={className}>
      {anchors.map(({ waypointId, onClick, ...restAnchor }) => {
        const isActive = waypointId === activeWaypointId;
        return (
          <Anchor
            variant={'secondary'}
            isStrong={isActive}
            onClick={(event) => {
              setActiveWaypointId(waypointId);
              setIsChangeBlocked(true);
              handleAnchorClick(waypointId);
              onClick?.(event);
            }}
            {...restAnchor}
            key={waypointId}
          />
        );
      })}
    </Space>
  );
};

Scrollspy.Provider = Provider;
Scrollspy.Waypoint = Waypoint;

export default Scrollspy;
