import type { StrategyWizardValues } from 'strategy/StrategyWizard';
import type { StrategyInput, StrategyItemInput } from 'types.graphql.generated';
import { date } from 'shared/services/date.service';
import type { TeamAdapter } from 'team/TeamAdapter';
import type { StrategyItemFormValues } from 'strategy/StrategyItemForm';

const resolveStrategyItemInput = (
  values: Maybe<StrategyItemFormValues>,
): StrategyItemInput => ({
  name: values?.name,
  description: values?.description,
  owner:
    values?.owner && values.owner !== ''
      ? { emailToSet: values.owner }
      : undefined,
});

export const resolveCreateStrategyInput = (params: {
  teamAdapter: TeamAdapter;
  values: StrategyWizardValues;
}): StrategyInput => {
  const { teamAdapter, values } = params;
  return {
    ...teamAdapter.toInput(),
    name: values.name?.name,
    timeLine: {
      startDate: values.name?.timeline.startDate
        ? date.format(values.name.timeline.startDate, 'yyyy-MM-dd')
        : null,
      endDate: values.name?.timeline.endDate
        ? date.format(values.name.timeline.endDate, 'yyyy-MM-dd')
        : null,
    },
    mission: resolveStrategyItemInput(values.mission),
    vision: resolveStrategyItemInput(values.vision),
  };
};
