import type { TFunction } from 'react-i18next';
import type { Theme } from '@emotion/react';

import type { MetricStatus } from 'types.graphql.generated';
import type { MetricsStatsActive } from 'metric/metric.type';

export const getMetricsStats = <
  TStatus extends Pick<MetricStatus, 'statusIndicator'>,
  TMetric extends { currentMetricStatus?: TStatus },
>(
  metricsAll: Maybe<TMetric[]>,
  metricsActive: Maybe<TMetric[]>,
  t: TFunction,
  theme: Theme,
) => {
  const { blocked, atRisk, onTrack, noStatus } =
    getMetricsStatsActive(metricsActive);

  return {
    statDone: {
      name: t('statusIndicator.COMPLETED.name'),
      value: getMetricsStatsDone(metricsAll),
      color: theme.color.primary,
    },
    statsActive: [
      {
        name: t('statusIndicator.UNKNOWN.name'),
        value: noStatus,
        color: theme.color.neutral2,
      },
      {
        name: t('statusIndicator.BLOCKED.name'),
        value: blocked,
        color: theme.color.error,
      },
      {
        name: t('statusIndicator.AT_RISK.name'),
        value: atRisk,
        color: theme.color.alert,
      },
      {
        name: t('statusIndicator.ON_TRACK.name'),
        value: onTrack,
        color: theme.color.success,
      },
    ],
  };
};

export const getMetricsStatsActive = <
  TStatus extends Pick<MetricStatus, 'statusIndicator'>,
  TMetric extends { currentMetricStatus?: TStatus },
>(
  metrics: Maybe<TMetric[]>,
) => {
  const initialStats = {
    blocked: 0,
    atRisk: 0,
    onTrack: 0,
    noStatus: 0,
  };

  return metrics
    ? metrics.reduce<MetricsStatsActive>(
        (acc, { currentMetricStatus }) => ({
          noStatus: currentMetricStatus?.statusIndicator.value
            ? acc.noStatus
            : acc.noStatus + 1,
          blocked:
            currentMetricStatus?.statusIndicator.value === 'BLOCKED'
              ? acc.blocked + 1
              : acc.blocked,
          atRisk:
            currentMetricStatus?.statusIndicator.value === 'AT_RISK'
              ? acc.atRisk + 1
              : acc.atRisk,
          onTrack:
            currentMetricStatus?.statusIndicator.value === 'ON_TRACK'
              ? acc.onTrack + 1
              : acc.onTrack,
        }),
        initialStats,
      )
    : initialStats;
};

export const getMetricsStatsDone = <
  TMetric extends {
    currentMetricStatus?: Pick<MetricStatus, 'statusIndicator'>;
  },
>(
  metrics: Maybe<TMetric[]>,
) => {
  if (metrics) {
    const metricsDoneCount = getMetricsCompletedCount(metrics);

    const statDone = (metricsDoneCount / metrics.length) * 100;

    return Math.round(statDone);
  } else {
    return 0;
  }
};

export const getMetricsCompletedCount = <
  TMetric extends {
    currentMetricStatus?: Pick<MetricStatus, 'statusIndicator'>;
  },
>(
  metrics: Maybe<TMetric[]>,
) => {
  if (metrics) {
    return metrics.reduce<number>(
      (acc, cur) =>
        cur.currentMetricStatus?.statusIndicator.isCompleted ? acc + 1 : acc,
      0,
    );
  } else {
    return 0;
  }
};
