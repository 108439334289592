import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export const usePageTitle = (pageTitle?: string) => {
  const { t } = useTranslation();

  const appTitle = t('appTitle');
  const documentTitle = pageTitle ? `${pageTitle} • ${appTitle}` : appTitle;

  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);
};
