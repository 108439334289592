import { createContext } from 'react';

import type { Org } from 'types.graphql.generated';

export type InitialActiveOrgKeyProviderContextValue = {
  initialActiveOrgKey: Org['orgKey'];
};

export const InitialActiveOrgKeyProviderContext =
  createContext<Maybe<InitialActiveOrgKeyProviderContextValue>>(undefined);
