import type {
  StrategyWizardStepId,
  StrategyWizardValues,
} from './StrategyWizard.type';

export const isStrategyWizardStepId = (
  value: unknown,
): value is StrategyWizardStepId => {
  const values: StrategyWizardStepId[] = [
    'name',
    'mission',
    'vision',
    'goals',
    'choices',
    'behaviors',
    'values',
  ];
  if (typeof value !== 'string') {
    return false;
  }
  return values.includes(value);
};

export const areValuesValidForCreatingStrategy = (
  values: StrategyWizardValues,
): boolean => !!(values.name?.name && values.name.name.length > 0);
