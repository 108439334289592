import type { Animation } from 'shared/types/animation.type';

export const slideFromLeftAnimation: Animation = {
  animate: { x: 0, opacity: 1 },
  exit: { x: '-20vw', opacity: 0 },
  initial: { x: '-20vw', opacity: 0 },
  transition: {
    duration: 0.2,
  },
};

export const slideFromRightAnimation: Animation = {
  animate: { x: 0, opacity: 1 },
  exit: { x: '20vw', opacity: 0 },
  initial: { x: '20vw', opacity: 0 },
  transition: {
    duration: 0.2,
  },
};

export const slideFromBottomAnimation: Animation = {
  animate: { y: 0, opacity: 1 },
  exit: { y: '20vw', opacity: 0 },
  initial: { y: '20vw', opacity: 0 },
  transition: {
    duration: 0.2,
  },
};
