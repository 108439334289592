import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Flex from 'shared/components/Flex';
import SideModal from 'shared/components/SideModal';
import Tabs from 'shared/components/Tabs';
import Text from 'shared/components/Text';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px;
  gap: 20px;
  overflow-y: auto;
`;

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 120px;
`;

type TabContent = {
  sections: { items: string[]; sectionTitle: string }[];
  subtitle: string;
  tabTitle: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ObjectiveNameCheatSheet = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  const tabs = t('objective.objectiveNameCheatSheet.tabs', {
    returnObjects: true,
  }) as TabContent[];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <SideModal
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t('objective.objectiveNameCheatSheet.title')}
    >
      <Flex direction={'column'} css={css({ height: '100%' })}>
        <Tabs css={css({ margin: '0 20px 0 20px' })}>
          {tabs.map((tab) => (
            <Tabs.Item key={tab.tabTitle} onClick={() => setActiveTab(tab)}>
              {tab.tabTitle}
            </Tabs.Item>
          ))}
        </Tabs>

        <Content>
          {activeTab.subtitle}

          <Columns>
            {activeTab.sections.map((section) => (
              <Column key={section.sectionTitle}>
                <Text>{section.sectionTitle}</Text>
                <Flex direction={'column'} gap={'0.2rem'}>
                  {section.items.map((item) => (
                    <div key={item}>{item}</div>
                  ))}
                </Flex>
              </Column>
            ))}
          </Columns>
        </Content>
      </Flex>
    </SideModal>
  );
};

export default ObjectiveNameCheatSheet;
