import type { TypePolicies } from '@apollo/client';

import { readStatusIndicatorField } from 'shared/status/StatusIndicator';
import { readDateTimeField } from 'shared/graphql/utils';

export const metricTypePolicies: TypePolicies = {
  MetricStatus: {
    fields: {
      statusIndicator: {
        read: readStatusIndicatorField,
      },
      statusDateTime: {
        read: readDateTimeField,
      },
    },
  },
};
