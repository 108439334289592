import { format } from 'date-fns';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Text from 'shared/components/Text';
import Flex from 'shared/components/Flex';
import UserAvatar from 'user/UserAvatar';
import StrategyElementIcon from 'shared/components/StrategyElementIcon';
import StatusMark from 'shared/status/StatusMark';
import TeamImage from 'team/TeamImage';
import MetricOverviewInsightsRowScoreComment from 'metric/MetricOverview/MetricOverviewInsightsRowScoreComment';
import InsightsComment from 'shared/components/InsightsComment';
import InitiativeOverviewMilestoneStatusSection from 'initiative/InitiativeOverview/InitiativeOverviewMilestoneStatusSection';
import InitiativeInsightsRowScoreComment from 'initiative/InitiativeOverview/InitiativeOverviewInsightsRowScoreComment';

import type { PreviousInsightsCardData } from './PreviousInsightsSidecard.type';
import CommentSection from './CommentSection';

const Container = styled.div`
  border-radius: 1rem;
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.color.strokeLight};

  svg {
    color: ${(props) => props.theme.color.strokeDark};
    width: 1rem;
  }
`;

type Props = {
  cardData: PreviousInsightsCardData;
  showOrgUnit: boolean;
};

const Card = ({ cardData, showOrgUnit }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Flex direction={'column'} gap={16}>
        <Flex alignItems={'flex-start'} gap={8}>
          <Flex alignItems={'center'} gap={8} css={css({ height: 28 })}>
            <StrategyElementIcon type={cardData.typename} />

            <StatusMark statusIndicator={cardData.statusIndicator} />
          </Flex>

          <Text
            color={'regular'}
            isBold={true}
            css={css({ flexGrow: '1', marginTop: 3.5 })}
          >
            {cardData.name}
          </Text>

          <Flex alignItems={'center'} css={css({ height: 28 })} gap={8}>
            <Text>
              {cardData.statusDateTime
                ? format(cardData.statusDateTime, 'P')
                : ''}
            </Text>

            {showOrgUnit && (
              <TeamImage orgUnit={cardData.orgUnit} size={'tiny'} />
            )}

            <UserAvatar
              user={cardData.author}
              size={'medium'}
              showTooltip={true}
            />
          </Flex>
        </Flex>

        {cardData.objectiveStatus && (
          <>
            <InsightsComment
              title={t('objective.insights.recentInsights.comment.heading')}
              comment={cardData.objectiveStatus.comment}
            />
            <CommentSection
              title={t('objective.insights.recentInsights.successes.heading')}
              comments={cardData.objectiveStatus.successComments}
              formSection={'successes'}
            />
            <CommentSection
              title={t('objective.insights.recentInsights.challenges.heading')}
              comments={cardData.objectiveStatus.challengeComments}
              formSection={'challenges'}
            />
            <CommentSection
              title={t('objective.insights.recentInsights.actions.heading')}
              comments={cardData.objectiveStatus.actionComments}
              formSection={'actions'}
            />
          </>
        )}

        {cardData.metricStatus && (
          <>
            <InsightsComment
              title={t('objective.insights.recentInsights.score.heading')}
              comment={
                <MetricOverviewInsightsRowScoreComment
                  status={cardData.metricStatus}
                />
              }
            />
            <InsightsComment
              title={t('objective.insights.recentInsights.comment.heading')}
              comment={cardData.metricStatus.comment}
            />
            <CommentSection
              title={t('objective.insights.recentInsights.successes.heading')}
              comments={cardData.metricStatus.successComments}
              formSection={'successes'}
            />
            <CommentSection
              title={t('objective.insights.recentInsights.challenges.heading')}
              comments={cardData.metricStatus.challengeComments}
              formSection={'challenges'}
            />
            <CommentSection
              title={t('objective.insights.recentInsights.actions.heading')}
              comments={cardData.metricStatus.actionComments}
              formSection={'actions'}
            />
          </>
        )}

        {cardData.initiativeStatus && (
          <>
            <InsightsComment
              title={t('objective.insights.recentInsights.score.heading')}
              comment={
                <InitiativeInsightsRowScoreComment
                  status={cardData.initiativeStatus}
                />
              }
            />
            <InitiativeOverviewMilestoneStatusSection
              milestoneStatuses={cardData.initiativeStatus.milestoneStatuses}
            />
            <InsightsComment
              title={t('objective.insights.recentInsights.comment.heading')}
              comment={cardData.initiativeStatus.comment}
            />
            <CommentSection
              title={t('objective.insights.recentInsights.successes.heading')}
              comments={cardData.initiativeStatus.successComments}
              formSection={'successes'}
            />
            <CommentSection
              title={t('objective.insights.recentInsights.challenges.heading')}
              comments={cardData.initiativeStatus.challengeComments}
              formSection={'challenges'}
            />
            <CommentSection
              title={t('objective.insights.recentInsights.actions.heading')}
              comments={cardData.initiativeStatus.actionComments}
              formSection={'actions'}
            />
          </>
        )}
      </Flex>
    </Container>
  );
};

export default Card;
