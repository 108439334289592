import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import compact from 'lodash/compact';

import { date } from 'shared/services/date.service';
import Text from 'shared/components/Text';
import Space from 'shared/components/Space';
import Heading from 'shared/components/Heading';
import ObjectiveChip from 'objective/ObjectiveChip';
import { useTeamAdapter } from 'team/TeamAdapter';

import type { StrategyRoadmapObjectivesByTheme } from '../StrategyRoadmap.type';
import type { StrategyRoadmapSideModalProps } from '../StrategyRoadmapSideModal';
import { illustrations, backgroundColors } from '../StrategyRoadmap.static';
import StrategyRoadmapSideModal from '../StrategyRoadmapSideModal';
import { getManageThemesPath } from '../StrategyRoadmap.utils';

type StrategyRoadmapThemesModalProps = {
  objectivesByTheme: StrategyRoadmapObjectivesByTheme[];
  showEditButton: boolean;
} & Pick<StrategyRoadmapSideModalProps, 'isOpen' | 'onClose'>;

const StrategyRoadmapThemesModal = ({
  isOpen,
  objectivesByTheme,
  onClose,
  showEditButton,
}: StrategyRoadmapThemesModalProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { teamAdapter } = useTeamAdapter();

  const content = useMemo(
    () => (
      <Space direction={'vertical'} size={'large'}>
        {objectivesByTheme.map(({ theme, objectives }) => {
          if (theme) {
            return (
              <Space direction={'vertical'} key={theme.id}>
                <Heading level={3} hasMargin={false}>
                  {theme.name}
                </Heading>
                <Text>{theme.description}</Text>
                <Space direction={'vertical'}>
                  {objectives.length > 0 ? (
                    objectives.map((objective) => (
                      <ObjectiveChip objective={objective} key={objective.id} />
                    ))
                  ) : (
                    <Text>{t('theme.noObjectives')}</Text>
                  )}
                </Space>
              </Space>
            );
          }
        })}
      </Space>
    ),
    [objectivesByTheme, t],
  );

  const updateDateTime = date.max(
    compact(
      objectivesByTheme.map(({ theme }) => theme?.auditRecord.updateDateTime),
    ),
  );

  const handleEdit = useCallback(() => {
    onClose();
    navigate(getManageThemesPath(teamAdapter));
  }, [onClose, navigate, teamAdapter]);

  return (
    <>
      <StrategyRoadmapSideModal
        top={{
          illustration: illustrations.themes,
          backgroundColor: backgroundColors.themes,
        }}
        heading={t('strategy.strategyRoadmap.themes.modal.heading')}
        description={t('strategy.strategyRoadmap.themes.modal.description')}
        updateDateTime={updateDateTime}
        onEdit={showEditButton ? handleEdit : undefined}
        isOpen={isOpen}
        onClose={onClose}
      >
        {content}
      </StrategyRoadmapSideModal>
    </>
  );
};

export default StrategyRoadmapThemesModal;
