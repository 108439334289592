import { useNavigate } from 'react-router-dom';
import type { To } from 'react-router-dom';
import { useCallback } from 'react';
import styled from '@emotion/styled';

import StatusMark from 'shared/status/StatusMark';
import type { StatusIndicator } from 'shared/status/StatusIndicator';
import UserAvatar from 'user/UserAvatar';
import type { UserProfile } from 'user/user.type';

type OverviewRowProps = {
  className?: string;
  linkTo?: To;
  statusIndicator?: Maybe<StatusIndicator>;
  textContent: Maybe<string>;
  user?: Maybe<UserProfile>;
};

const Row = styled.li<{ hasContent: boolean }>`
  border: 1px solid ${(props) => props.theme.color.strokeMedium};
  border-radius: 8px;
  display: flex;
  height: 45px;
  overflow: hidden;
  padding: 7.5px 10px 7.5px 15px;
  align-items: center;
  gap: 14px;
  cursor: ${(props) => (props.hasContent ? 'pointer' : 'default')};

  &:hover {
    background-color: ${({ hasContent, theme }) =>
      hasContent ? theme.color.hoverLight : theme.color.white};
    text-decoration: ${(props) => (props.hasContent ? 'underline' : 'none')};
  }
`;

const NameContainer = styled.div`
  flex-grow: 1;
  align-items: center;
  display: flex;
  gap: 14px;
  min-width: 0; /* Ensure flex items can shrink */
`;

const NameText = styled.div<{ hasContent: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ hasContent, theme }) =>
    hasContent ? 'inherit' : theme.color.neutral1};
`;

const OverviewRow = ({
  user,
  linkTo,
  textContent,
  statusIndicator,
}: OverviewRowProps) => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    () => linkTo && navigate(linkTo),
    [linkTo, navigate],
  );

  const hasContent = !!linkTo;

  return (
    <Row onClick={handleClick} hasContent={hasContent}>
      {linkTo && <StatusMark statusIndicator={statusIndicator} />}

      <NameContainer>
        <NameText hasContent={hasContent}>{textContent}</NameText>
      </NameContainer>

      {user && <UserAvatar user={user} />}
    </Row>
  );
};

export default OverviewRow;
