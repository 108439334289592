import type { CSSProperties, PropsWithChildren, RefCallback } from 'react';
import cn from 'classnames';

import {
  getCutStyles,
  getColumnClassName,
  getContainerMinHeightForSeamlessBackground,
} from './RoadmapItemBackgroundTileFlexible.utils';
import type { FlexibleTileColumn } from './RoadmapitemBackgroundTileFlexible.type';
import styles from './RoadmapItemBackgroundTileFlexible.module.scss';

type RoadmapItemBackgroundTileFlexibleProps = PropsWithChildren<{
  column?: FlexibleTileColumn;
  cutSize?: CSSProperties['height'];
  isCutOnBottom?: boolean;
  isCutOnTop?: boolean;
  isHidden?: boolean;
  maxHeight?: CSSProperties['maxHeight'];
  minHeight?: CSSProperties['minHeight'];
}>;

const RoadmapItemBackgroundTileFlexible = ({
  isHidden = false,
  isCutOnTop = false,
  isCutOnBottom = false,
  column,
  cutSize,
  minHeight,
  maxHeight,
  children,
}: RoadmapItemBackgroundTileFlexibleProps) => {
  const setContainerElementMinHeightForSeamlessBackground: RefCallback<
    HTMLDivElement
  > = (element) => {
    if (element) {
      const minHeight = getContainerMinHeightForSeamlessBackground(
        element.clientHeight,
      );
      if (minHeight) {
        element.style.minHeight = minHeight;
      }
    }
  };

  const columnClassName = getColumnClassName(column);
  const cutStyles = getCutStyles({
    cutSize,
    isCutOnTop,
    isCutOnBottom,
  });

  return (
    <div
      className={cn(styles.container, columnClassName, {
        [styles.containerCutOnTop]: isCutOnTop,
        [styles.containerCutOnBottom]: isCutOnBottom,
        [styles.containerHidden]: isHidden,
      })}
      style={{
        minHeight,
        maxHeight,
        ...cutStyles,
      }}
      ref={setContainerElementMinHeightForSeamlessBackground}
    >
      <div
        className={cn(styles.content, {
          [styles.contentCutOnTop]: isCutOnTop,
          [styles.contentCutOnBottom]: isCutOnBottom,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default RoadmapItemBackgroundTileFlexible;
