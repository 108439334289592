import type { Theme } from 'theme';

import type { OrgUnitFormValues } from './OrgUnitForm.type';

export const createInitialValues = (theme: Theme): OrgUnitFormValues => ({
  name: '',
  nameAbbreviated: '',
  description: '',
  lead: '',
  admins: [],
  parent: null,
  backgroundColor: theme.legacyColor.colorAshGray,
});
