import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import { generatePath } from '@remix-run/router';

import type {
  RoutePathParams,
  RoutePathQuery,
  RoutePathOptions,
} from './RoutePath.type';

export class RoutePath<
  Params extends RoutePathParams = never,
  Query extends RoutePathQuery = never,
> {
  private readonly path: string;
  private readonly options: RoutePathOptions<Params, Query>;

  constructor(path: string, options: RoutePathOptions<Params, Query> = {}) {
    this.path = path;
    this.options = options;
  }

  toString() {
    const parametrizedPath = this.parametrizePath(this.path);
    return this.addQueryToPath(
      this.addTrailingAsteriskToPath(parametrizedPath),
    );
  }

  private addTrailingAsteriskToPath(path: string) {
    const { allowNestedRoutes } = this.options;
    if (allowNestedRoutes) {
      return `${path}/*`;
    }
    return path;
  }

  private addQueryToPath(path: string) {
    const { query } = this.options;
    if (query && !isEmpty(query)) {
      return `${path}?${qs.stringify(query, {
        arrayFormat: 'repeat',
      })}`;
    }
    return path;
  }

  private parametrizePath(path: string) {
    const { params } = this.options;
    if (params) {
      return generatePath(path, params);
    }
    return path;
  }
}
