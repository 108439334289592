import type { OrgUnit } from 'types.graphql.generated';

import type {
  AddMemberToOrgUnitFormValues,
  AddMemberToOrgUnitOrgUnitOption,
} from './AddMemberToOrgUnitForm.type';

export const createInitialValues = (params: {
  orgUnitOptions: AddMemberToOrgUnitOrgUnitOption[];
}): AddMemberToOrgUnitFormValues => {
  const { orgUnitOptions } = params;
  return {
    user: '',
    orgUnit: orgUnitOptions.at(0)!,
  };
};

export const getOrgUnitOptions = (params: {
  orgUnit: Pick<OrgUnit, 'id' | 'name'>;
}): AddMemberToOrgUnitOrgUnitOption[] => {
  const { orgUnit } = params;

  return [
    {
      value: orgUnit,
    },
  ];
};

export const getOrgUnitOptionLabel = (
  option: AddMemberToOrgUnitOrgUnitOption,
) => option.value?.name;
