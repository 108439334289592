import { i18n } from 'shared/services/i18n.service';
import { strategyRoutesPaths } from 'strategy/strategy.routing.paths';
import type { TeamAdapter } from 'team/TeamAdapter';

import type { ContributionUserContributionQuery } from './ContributionUserContribution.graphql.generated';

export const getBreadcrumbItems = (
  teamAdapter: TeamAdapter,
  user: ContributionUserContributionQuery['user'],
) => [
  {
    name: i18n.t('strategy.strategy'),
    to: strategyRoutesPaths.root({
      params: { teamSlug: teamAdapter.toParam() },
    }),
  },
  {
    name: user.org?.displayName || i18n.t('team.organization') || '',
    to: strategyRoutesPaths.overview.root({
      params: { teamSlug: teamAdapter.toParam() },
    }),
  },
  {
    name: user.displayName || user.email || user.id,
    to: '',
  },
];
