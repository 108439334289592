import { useCallback, useMemo, useState, type PropsWithChildren } from 'react';
import { GraphQLError } from 'graphql';

import type { ErrorCode } from './ErrorPage.context';
import { ErrorPageContext } from './ErrorPage.context';
import ErrorPage from './ErrorPage';

const ErrorPageProvider = ({ children }: PropsWithChildren) => {
  const [error, setError] = useState<Error>();

  const clearError = useCallback(() => setError(undefined), []);

  const onErrorCode = useCallback(
    (errorCode: ErrorCode) =>
      setError(
        new GraphQLError('Synthetic Error', { extensions: { errorCode } }),
      ),
    [],
  );

  const value = useMemo(
    () => ({ error, onError: setError, onErrorCode, clearError }),
    [clearError, error, onErrorCode],
  );

  return (
    <ErrorPageContext.Provider value={value}>
      {error ? <ErrorPage /> : children}
    </ErrorPageContext.Provider>
  );
};

export default ErrorPageProvider;
