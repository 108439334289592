import { Navigate } from 'react-router-dom';

import { strategyRoomRoutesPaths } from './StrategyRoom.routing.paths';

const NavigateToStrategyRoomStrategy = () => {
  const to = strategyRoomRoutesPaths.strategy.root();

  return <Navigate to={to} replace={true} />;
};

export default NavigateToStrategyRoomStrategy;
