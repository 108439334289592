import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActiveOrgStrategyProfileAttributesQueryVariables = Types.Exact<{
  types?: Types.InputMaybe<Array<Types.StrategyAttributeDefinitionType> | Types.StrategyAttributeDefinitionType>;
}>;


export type ActiveOrgStrategyProfileAttributesQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, currentStrategyProfile?: { __typename: 'StrategyProfile', id: string, strategyAttributeDefinitions: Array<{ __typename: 'StrategyAttributeDefinition', id: string, name?: string | undefined, orderNumber?: number | undefined, title?: string | undefined }> } | undefined } };

export type StrategyProfileAttributesOrganizationFragment = { __typename: 'Org', id: string, currentStrategyProfile?: { __typename: 'StrategyProfile', id: string, strategyAttributeDefinitions: Array<{ __typename: 'StrategyAttributeDefinition', id: string, name?: string | undefined, orderNumber?: number | undefined, title?: string | undefined }> } | undefined };

export const StrategyProfileAttributesOrganizationFragmentDoc = gql`
    fragment strategyProfileAttributesOrganization on Org {
  id
  currentStrategyProfile {
    id
    strategyAttributeDefinitions(types: $types) {
      id
      name
      orderNumber
      title
    }
  }
}
    `;
export const ActiveOrgStrategyProfileAttributesDocument = gql`
    query ActiveOrgStrategyProfileAttributes($types: [StrategyAttributeDefinitionType!]) {
  activeOrg {
    ...strategyProfileAttributesOrganization
  }
}
    ${StrategyProfileAttributesOrganizationFragmentDoc}`;

/**
 * __useActiveOrgStrategyProfileAttributesQuery__
 *
 * To run a query within a React component, call `useActiveOrgStrategyProfileAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveOrgStrategyProfileAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveOrgStrategyProfileAttributesQuery({
 *   variables: {
 *      types: // value for 'types'
 *   },
 * });
 */
export function useActiveOrgStrategyProfileAttributesQuery(baseOptions?: Apollo.QueryHookOptions<ActiveOrgStrategyProfileAttributesQuery, ActiveOrgStrategyProfileAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveOrgStrategyProfileAttributesQuery, ActiveOrgStrategyProfileAttributesQueryVariables>(ActiveOrgStrategyProfileAttributesDocument, options);
      }
export function useActiveOrgStrategyProfileAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveOrgStrategyProfileAttributesQuery, ActiveOrgStrategyProfileAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveOrgStrategyProfileAttributesQuery, ActiveOrgStrategyProfileAttributesQueryVariables>(ActiveOrgStrategyProfileAttributesDocument, options);
        }
export type ActiveOrgStrategyProfileAttributesQueryHookResult = ReturnType<typeof useActiveOrgStrategyProfileAttributesQuery>;
export type ActiveOrgStrategyProfileAttributesLazyQueryHookResult = ReturnType<typeof useActiveOrgStrategyProfileAttributesLazyQuery>;
export type ActiveOrgStrategyProfileAttributesQueryResult = Apollo.QueryResult<ActiveOrgStrategyProfileAttributesQuery, ActiveOrgStrategyProfileAttributesQueryVariables>;