import type { TypePolicies } from '@apollo/client';

import { readDateTimeField } from 'shared/graphql/utils';

export const userTypePolicies: TypePolicies = {
  AuditRecord: {
    merge: true,
    fields: {
      createDateTime: {
        read: readDateTimeField,
      },
      updateDateTime: {
        read: readDateTimeField,
      },
    },
  },
  AuditRecordUser: {
    keyFields: ['email'],
  },
};
