import type { PropsWithChildren } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';

import type { ScrollspyProviderContextValue } from './ScrollspyProvider.context';
import { ScrollspyProviderContext } from './ScrollspyProvider.context';

type ScrollspyProviderProps = PropsWithChildren<object>;

const ScrollspyProvider = ({ children }: ScrollspyProviderProps) => {
  const [activeWaypointId, setActiveWaypointId] =
    useState<ScrollspyProviderContextValue['activeWaypointId']>(undefined);
  const isChangeBlocked = useRef(false);

  const handleSetActiveWaypointId = useCallback<
    ScrollspyProviderContextValue['setActiveWaypointId']
  >((waypointId) => {
    if (!isChangeBlocked.current) {
      setActiveWaypointId(waypointId);
    }
  }, []);

  const setIsChangeBlocked = useCallback((value: boolean) => {
    isChangeBlocked.current = value;
  }, []);

  const contextValue = useMemo<ScrollspyProviderContextValue>(
    () => ({
      setActiveWaypointId: handleSetActiveWaypointId,
      activeWaypointId,
      setIsChangeBlocked,
    }),
    [activeWaypointId, handleSetActiveWaypointId, setIsChangeBlocked],
  );

  return (
    <ScrollspyProviderContext.Provider value={contextValue}>
      {children}
    </ScrollspyProviderContext.Provider>
  );
};

export default ScrollspyProvider;
