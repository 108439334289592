import type { TFunction } from 'react-i18next';

import { yup } from 'shared/services/yup.service';
import { dateBefore1900Validation } from 'shared/utils/timeLine.utils';

export const createValidationSchema = (params: { t: TFunction }) => {
  const { t } = params;
  return yup.object({
    date: dateBefore1900Validation(),
    statusIndicator: yup
      .string()
      .required(t('status.form.statusIndicator.missing')),
  });
};
