import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import Tabs from 'shared/components/Tabs';

import styles from './Sidesheets.module.scss';
import SidesheetsTipsAndTricks from './SidesheetsTipsAndTricks';
import type {
  CommentsSection,
  SidesheetsWizardStep,
  StrategyItemType,
} from './Sidesheets.type';

type Props = {
  context: 'report' | 'status' | 'strategy' | StrategyItemType;
  wizardStep?: SidesheetsWizardStep;
};

const Sidesheets = ({ context, wizardStep }: Props) => {
  const { t } = useTranslation();

  const [commentsSection, setCommentsSection] =
    useState<CommentsSection>('successComments');

  return (
    <article>
      {context === 'status' ? (
        <nav className={styles.nav}>
          <Tabs
            className={styles.tabs}
            tabIds={['successComments', 'challengeComments', 'actionComments']}
            activeTabId={commentsSection}
          >
            <Tabs.Item
              onClick={() => setCommentsSection('successComments')}
              isActive={commentsSection === 'successComments'}
            >
              {t(`sidesheets.nav.successComments.heading`)}
            </Tabs.Item>
            <Tabs.Item
              onClick={() => setCommentsSection('challengeComments')}
              isActive={commentsSection === 'challengeComments'}
            >
              {t(`sidesheets.nav.challengeComments.heading`)}
            </Tabs.Item>
            <Tabs.Item
              onClick={() => setCommentsSection('actionComments')}
              isActive={commentsSection === 'actionComments'}
            >
              {t(`sidesheets.nav.actionComments.heading`)}
            </Tabs.Item>
          </Tabs>
        </nav>
      ) : null}

      <div className={styles.content}>
        <SidesheetsTipsAndTricks
          context={context}
          wizardStep={wizardStep}
          commentsSection={commentsSection}
        />
      </div>
    </article>
  );
};

export default Sidesheets;
