import type { TableRowProps, Row } from 'react-table';
import cn from 'classnames';

import styles from './TableViewRow.module.scss';
import type { BaseTableViewItem } from '../TableView.type';

type TableViewRowProps<Item extends BaseTableViewItem> = TableRowProps & {
  row: Row<Item>;
};

const TableViewRow = <Item extends BaseTableViewItem>({
  row,
  ...rowProps
}: TableViewRowProps<Item>) => (
  <tr
    {...rowProps}
    className={cn(styles.tableViewRow, rowProps.className)}
    key={rowProps.key}
  >
    {row.cells.map((cell, cellIndex, cellsArray) => {
      const cellProps = cell.getCellProps();
      const isFirstCell = cellIndex === 0;
      const isLastCell = cellIndex === cellsArray.length - 1;
      return (
        <td {...cellProps} className={cellProps.className} key={cellProps.key}>
          <div className={styles.tableViewRowCellContentContainer}>
            {isFirstCell ? (
              <div className={styles.tableViewRowCellSpacer} />
            ) : null}
            <div className={styles.tableViewRowCellContent}>
              {cell.render('Cell')}
            </div>
            {isLastCell ? (
              <div className={styles.tableViewRowCellSpacer} />
            ) : null}
          </div>
        </td>
      );
    })}
  </tr>
);

export default TableViewRow;
