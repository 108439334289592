import { useDefinedContext } from 'shared/utils/context.utils';

import { ActiveOrgProviderContext } from './ActiveOrgProvider.context';
import ActiveOrgProvider from './ActiveOrgProvider';

export const useActiveOrg = () =>
  useDefinedContext(ActiveOrgProviderContext, {
    hookName: useActiveOrg.name,
    providerName: ActiveOrgProvider.name,
  });
