import { useTranslation } from 'react-i18next';

import Select from 'shared/components/__DEPRECATED__/Select';
import type { SelectProps } from 'shared/components/__DEPRECATED__/Select';

import { getOptionLabel } from './SelectObjectiveInput.utils';
import type { SelectObjectiveInputFieldOption } from './SelectObjectiveInput.type';
import { useSelectObjectiveInput } from './useSelectObjectiveInput';

type SelectObjectiveInputProps = Pick<
  SelectProps<SelectObjectiveInputFieldOption, false>,
  'value' | 'onChange' | 'isSearchable'
>;

const SelectObjectiveInput = ({
  isSearchable = true,
  ...restProps
}: SelectObjectiveInputProps) => {
  const { t } = useTranslation();

  const { options } = useSelectObjectiveInput();

  return (
    <Select<SelectObjectiveInputFieldOption, false>
      {...restProps}
      options={options}
      getOptionLabel={getOptionLabel}
      isSearchable={isSearchable}
      placeholder={t('selectObjectiveInput.placeholder')}
    />
  );
};

export default SelectObjectiveInput;
