import { useTranslation } from 'react-i18next';

import { useActiveOrg } from 'org/ActiveOrgProvider';

import type { FollowUpActivityItemWalkOfStrategy } from './ContributionMyFollowUpsViewMore.type';
import TimelineEntryContentSummary from './TimelineEntryContentSummary';
import RespondCampaignButton from './RespondCampaignButton';

const TimelineEntryContentCampaign = ({
  item,
}: {
  item: FollowUpActivityItemWalkOfStrategy;
}) => {
  const { t } = useTranslation();

  const { activeOrg } = useActiveOrg();

  const team =
    item.campaignRequest.campaign.orgUnit?.name ||
    activeOrg.displayName ||
    activeOrg.domainNames[0];

  const status = item.campaignRequest.response?.status || 'NOT_STARTED';
  const isNotStarted = status === 'NOT_STARTED';
  const isClosed = item.campaignRequest.campaign.state.stage === 'CLOSED';

  const description =
    isClosed && isNotStarted
      ? t(`toolkit.toolPage.walkOfStrategy.invite.NOT_STARTED_CLOSED`, {
          team,
        })
      : t(`toolkit.toolPage.walkOfStrategy.invite.${status}`, {
          team,
        });

  return (
    <TimelineEntryContentSummary
      item={item}
      icon={t('toolkit.tools.walkOfStrategy.symbol')}
      description={description}
      typeLabel={t('toolkit.tools.walkOfStrategy.name')}
    >
      <RespondCampaignButton
        campaignId={item.campaignRequest.campaign.id}
        stage={item.campaignRequest.campaign.state.stage}
        status={item.campaignRequest.response?.status}
      />
    </TimelineEntryContentSummary>
  );
};

export default TimelineEntryContentCampaign;
