import type { AriaToggleButtonProps } from 'react-aria';
import { useButton } from 'react-aria';
import type { RefObject } from 'react';
import cn from 'classnames';

import { ReactComponent as HamburgerIcon } from 'shared/static/icons/icon-hamburger.svg';

import styles from './NavbarHamburgerButton.module.scss';

type NavbarHamburgerButtonProps = Omit<AriaToggleButtonProps, 'children'> & {
  buttonRef: RefObject<HTMLButtonElement>;
  className?: string;
  isOpen: boolean;
};

/**
 * Hamburger button for tablet/mobile navigation
 */
const NavbarHamburgerButton = ({
  isOpen,
  buttonRef,
  ...props
}: NavbarHamburgerButtonProps) => {
  const { buttonProps, isPressed } = useButton(props, buttonRef);

  return (
    <button
      {...buttonProps}
      ref={buttonRef}
      className={cn(styles.hamburgerButton, props.className, {
        [styles.hamburgerButtonPressed]: isPressed,
      })}
    >
      <HamburgerIcon
        className={cn(styles.hamburgerButtonIcon, {
          [styles.hamburgerButtonIconSelected]: isOpen,
        })}
      />
    </button>
  );
};
export default NavbarHamburgerButton;
