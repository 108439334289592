import type { PropsWithChildren } from 'react';
import { createContext } from 'react';

import { useDefinedContext } from 'shared/utils/context.utils';

type Value = {
  actionIds: string[];
  initiativeIds: string[];
  metricIds: string[];
  objectiveIds: string[];
  strategyId?: string;
};

const AiSummaryContext = createContext<Value | undefined>(undefined);

export const useAiSummary = () =>
  useDefinedContext(AiSummaryContext, {
    hookName: useAiSummary.name,
    providerName: AiSummaryContext.Provider.name,
  });

type Props = PropsWithChildren<{
  actionIds: string[];
  initiativeIds: string[];
  metricIds: string[];
  objectiveIds: string[];
  strategyId?: string;
}>;

const AiSummaryProvider = ({
  objectiveIds,
  metricIds,
  initiativeIds,
  actionIds,
  strategyId,
  children,
}: Props) => (
  <AiSummaryContext.Provider
    value={{ objectiveIds, metricIds, initiativeIds, actionIds, strategyId }}
  >
    {children}
  </AiSummaryContext.Provider>
);

export default AiSummaryProvider;
