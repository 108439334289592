import type { Provider } from 'auth/useSignUpWithProvider';
import { ReactComponent as MicrosoftIcon } from 'shared/static/icons/icon-microsoft.svg';
import { ReactComponent as GoogleIcon } from 'shared/static/icons/icon-google.svg';

export const getIcon = (provider: Provider) => {
  switch (provider) {
    case 'microsoft': {
      return MicrosoftIcon;
    }
    case 'google': {
      return GoogleIcon;
    }
  }
};

export const getProviderName = (provider: Provider) => {
  switch (provider) {
    case 'microsoft': {
      return 'Microsoft';
    }
    case 'google': {
      return 'Google';
    }
  }
};
