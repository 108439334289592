import { useActiveOrg } from 'org/ActiveOrgProvider';

const useAiAssistentActive = () => {
  const { activeOrg } = useActiveOrg();

  const isAiAssistantActive = activeOrg.toolsEnabled.includes('AI_ASSISTANT');

  return { isAiAssistantActive };
};

export default useAiAssistentActive;
