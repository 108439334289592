import { useEffect } from 'react';
import type { Entries } from 'type-fest';

import { useForm } from 'shared/form/Form';
import type { StatusCommentType } from 'types.graphql.generated';

import type {
  StatusCommentItem,
  StatusCommentsFormValues,
} from './StatusCommentsForm.type';

export const createStatusCommentItem = (params: {
  text?: string;
  type: StatusCommentType;
}): StatusCommentItem => {
  const { text, type } = params;
  return {
    text: text || '',
    type,
  };
};

export const createInitialValues = <
  Type extends StatusCommentType = StatusCommentType,
>(
  types: StatusCommentType[],
) =>
  Object.fromEntries(
    types.map((type) => [type, [createStatusCommentItem({ type })]]),
  ) as StatusCommentsFormValues<Type>;

export const AddCommentWhenEmpty = <Type extends StatusCommentType>() => {
  const { values, setFieldValue } = useForm<StatusCommentsFormValues<Type>>();

  useEffect(() => {
    (Object.entries(values) as Entries<StatusCommentsFormValues>).map(
      ([statusCommentType, values]) => {
        if (!values?.length) {
          setFieldValue(statusCommentType, [
            createStatusCommentItem({ type: statusCommentType }),
          ]);
        }
      },
    );
  }, [values, setFieldValue]);

  return null;
};
