import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import type { CampaignResultsQuery } from 'toolkit/WalkOfStrategyCampaignResultsLayout/CampaignResults.graphql.generated';
import { useCampaignResults } from 'toolkit/WalkOfStrategyCampaignResultsLayout/CampaignResultsProvider';
import type { CampaignQuestionType } from 'types.graphql.generated';
import Text from 'shared/components/Text';

import OverviewSectionGraphItem from './OverviewSectionGraphItem';

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Line = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.color.strokeLight};
  width: 100%;
  margin-top: -1px;
  margin-bottom: 12px;
  z-index: 1;
`;

const LegendItem = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
`;

const OverviewSectionGraph = () => {
  const { t } = useTranslation();
  const { campaign } = useCampaignResults();

  return (
    <>
      <Container>
        {campaign.questions.map(({ questionType }) => (
          <OverviewSectionGraphItem
            key={questionType}
            score={getScore(campaign, questionType)}
          />
        ))}
      </Container>
      <Line />
      <Container>
        {campaign.questions.map(({ questionType }) => (
          <LegendItem key={questionType}>
            <Text size={'small'} color={'black'}>
              {t(
                `toolkit.toolPage.walkOfStrategy.results.questionType.${questionType}`,
              )}
            </Text>
          </LegendItem>
        ))}
      </Container>
    </>
  );
};

export default OverviewSectionGraph;

const getScore = (
  campaign: CampaignResultsQuery['campaign'],
  questionType: CampaignQuestionType,
) => {
  const answers = campaign.allUserResponses
    .map((response) =>
      response.answers.find(
        (answer) => answer.question.questionType === questionType,
      ),
    )
    .filter(Boolean);

  if (answers.length === 0) return;

  const ratingNumber = {
    STRONGLY_AGREE: 5,
    AGREE: 4,
    NEUTRAL: 3,
    DISAGREE: 2,
    STRONGLY_DISAGREE: 1,
  };

  const ratingSum = answers.reduce(
    (result, answer) => result + ratingNumber[answer.rating],
    0,
  );

  const average = ratingSum / answers.length;

  return average;
};
