import { useTranslation } from 'react-i18next';

import type {
  StrategyItemFormProps,
  StrategyItemFormValues,
} from 'strategy/StrategyItemForm';
import StrategyItemForm from 'strategy/StrategyItemForm';
import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import Heading from 'shared/components/Heading';
import InfoCard from 'shared/components/InfoCard';
import Sidesheets from 'shared/sidesheets';

export type StrategyWizardVisionValues = StrategyItemFormValues;

type StrategyWizardVisionProps = Pick<
  StrategyItemFormProps,
  'org' | 'id' | 'initialValues' | 'onChange'
>;

const StrategyWizardVision = (props: StrategyWizardVisionProps) => {
  const { t } = useTranslation();

  const renderForm = () => (
    <>
      <Heading level={4} as={5}>
        {t('whatIs', {
          term: t('strategy.vision_specific'),
        })}{' '}
      </Heading>
      <StrategyItemForm {...props} />
    </>
  );

  const renderInfoCard = () => (
    <InfoCard
      title={t('strategy.strategyWizard.steps.vision.infoCard.heading')}
      contentKey={'strategy.strategyWizard.steps.vision.infoCard.content'}
      sideSheetTitle={t('sidesheets.context.strategy.heading')}
      orgTermKey={'strategy.vision'}
    >
      <Sidesheets context={'strategy'} wizardStep={'vision'} />
    </InfoCard>
  );

  return (
    <FormWithInfoCardLayout
      renderForm={renderForm}
      renderInfoCard={renderInfoCard}
    />
  );
};

export default StrategyWizardVision;
