import { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EmptyIllustration } from 'shared/static/illustrations/illustration-empty-state.svg';
import Layout from 'shared/components/Layout';
import Spinner from 'shared/spinner/Spinner';
import { lazyRetry } from 'shared/utils/lazy.utils';
import Result from 'shared/components/Result';
import Space from 'shared/components/Space';
import useCanPerformOrgOrOrgUnitAction from 'user/ability/useCanPerformOrgOrOrgUnitAction';

const NoStrategyCreateStrategyCard = lazy(() =>
  lazyRetry(() => import('./NoStrategyCreateStrategyCard')),
);

const NoStrategyContent = () => {
  const { t } = useTranslation();

  const canManage = useCanPerformOrgOrOrgUnitAction('WRITE_STRATEGY', 'WRITE');

  return (
    <Layout.Content variant={'contained'} hasPadding={false}>
      <Suspense fallback={<Spinner.Circle />}>
        {canManage ? (
          <NoStrategyCreateStrategyCard />
        ) : (
          <Space isCentered={true}>
            <Result
              description={t('strategy.strategyRoadmap.emptyMessage')}
              illustration={EmptyIllustration}
              hasBackground={true}
            />
          </Space>
        )}
      </Suspense>
    </Layout.Content>
  );
};

export default NoStrategyContent;
