import type { ForwardedRef, PropsWithChildren } from 'react';

import ContributionSectionHeader from './ContributionSectionHeader/ContributionSectionHeader';
import ContributionSectionInnerContainer from './ContributionSectionInnerContainer';
import ContributionSectionListRow from './ContributionSectionListRow';
import ContributionSectionInnerList from './ContributionSectionInnerList';
import ContributionSectionCollapsibleList from './ContributionSectionCollapsibleList/ContributionSectionCollapsibleList';
import styles from './ContributionSection.module.scss';

type ContributionSectionProps = PropsWithChildren<{
  innerRef?: ForwardedRef<HTMLElement | null>;
  roman?: string;
}>;

const ContributionSection = ({
  innerRef,
  children,
}: ContributionSectionProps) => (
  <article ref={innerRef} className={styles.container}>
    {children}
  </article>
);

ContributionSection.Header = ContributionSectionHeader;
ContributionSection.CollapsibleList = ContributionSectionCollapsibleList;
ContributionSection.ListRow = ContributionSectionListRow;
ContributionSection.InnerList = ContributionSectionInnerList;
ContributionSection.InnerContainer = ContributionSectionInnerContainer;

export default ContributionSection;
