import { useDefinedContext } from 'shared/utils/context.utils';

import { StrategyBoardContext } from './StrategyBoardProvider.context';
import StrategyBoardProviderProvider from './StrategyBoardProvider';

export const useStrategyBoard = () =>
  useDefinedContext(StrategyBoardContext, {
    hookName: useStrategyBoard.name,
    providerName: StrategyBoardProviderProvider.name,
  });
