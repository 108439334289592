import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LinkInitiativeToObjectiveMutationVariables = Types.Exact<{
  objectiveId: Types.Scalars['ID'];
  initiativeId: Types.Scalars['ID'];
}>;


export type LinkInitiativeToObjectiveMutation = { __typename: 'Mutation', updateObjective: { __typename: 'Objective', id: string, initiatives: Array<{ __typename: 'Initiative', id: string }> } };

export type LinkInitiativeDialogQueryVariables = Types.Exact<{
  objectiveId: Types.Scalars['ID'];
}>;


export type LinkInitiativeDialogQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, orgUnits: Array<{ __typename: 'OrgUnit', id: string, name?: string | undefined }> }, objective: { __typename: 'Objective', id: string, childObjectives: Array<{ __typename: 'Objective', id: string, name?: string | undefined, theme?: { __typename: 'Theme', id: string, name?: string | undefined, orderNumber?: number | undefined, isActive: boolean } | undefined, initiatives: Array<{ __typename: 'Initiative', id: string, name?: string | undefined, currentInitiativeStatus?: { __typename: 'InitiativeStatus', id: string, statusIndicator: StatusIndicatorClass } | undefined }>, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined }> } };

export type LinkInitiativeDialogObjectiveFragment = { __typename: 'Objective', id: string, name?: string | undefined, theme?: { __typename: 'Theme', id: string, name?: string | undefined, orderNumber?: number | undefined, isActive: boolean } | undefined, initiatives: Array<{ __typename: 'Initiative', id: string, name?: string | undefined, currentInitiativeStatus?: { __typename: 'InitiativeStatus', id: string, statusIndicator: StatusIndicatorClass } | undefined }>, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined };

export type LinkInitiativeDialogOrgUnitFragment = { __typename: 'OrgUnit', id: string, name?: string | undefined };

export type LinkInitiativeDialogInitiativeFragment = { __typename: 'Initiative', id: string, name?: string | undefined, currentInitiativeStatus?: { __typename: 'InitiativeStatus', id: string, statusIndicator: StatusIndicatorClass } | undefined };

export type LinkInitiativeDialogThemeFragment = { __typename: 'Theme', id: string, name?: string | undefined, orderNumber?: number | undefined, isActive: boolean };

export const LinkInitiativeDialogThemeFragmentDoc = gql`
    fragment linkInitiativeDialogTheme on Theme {
  id
  name
  orderNumber
  isActive
}
    `;
export const LinkInitiativeDialogInitiativeFragmentDoc = gql`
    fragment linkInitiativeDialogInitiative on Initiative {
  id
  name
  currentInitiativeStatus {
    id
    statusIndicator
  }
}
    `;
export const LinkInitiativeDialogObjectiveFragmentDoc = gql`
    fragment linkInitiativeDialogObjective on Objective {
  id
  name
  theme {
    ...linkInitiativeDialogTheme
  }
  initiatives {
    ...linkInitiativeDialogInitiative
  }
  orgUnit {
    id
  }
}
    ${LinkInitiativeDialogThemeFragmentDoc}
${LinkInitiativeDialogInitiativeFragmentDoc}`;
export const LinkInitiativeDialogOrgUnitFragmentDoc = gql`
    fragment linkInitiativeDialogOrgUnit on OrgUnit {
  id
  name
}
    `;
export const LinkInitiativeToObjectiveDocument = gql`
    mutation LinkInitiativeToObjective($objectiveId: ID!, $initiativeId: ID!) {
  updateObjective(
    input: {idToUpdate: $objectiveId, initiativeLinks: {idsToSet: [$initiativeId]}}
  ) {
    id
    initiatives {
      id
    }
  }
}
    `;
export type LinkInitiativeToObjectiveMutationFn = Apollo.MutationFunction<LinkInitiativeToObjectiveMutation, LinkInitiativeToObjectiveMutationVariables>;

/**
 * __useLinkInitiativeToObjectiveMutation__
 *
 * To run a mutation, you first call `useLinkInitiativeToObjectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkInitiativeToObjectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkInitiativeToObjectiveMutation, { data, loading, error }] = useLinkInitiativeToObjectiveMutation({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *      initiativeId: // value for 'initiativeId'
 *   },
 * });
 */
export function useLinkInitiativeToObjectiveMutation(baseOptions?: Apollo.MutationHookOptions<LinkInitiativeToObjectiveMutation, LinkInitiativeToObjectiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LinkInitiativeToObjectiveMutation, LinkInitiativeToObjectiveMutationVariables>(LinkInitiativeToObjectiveDocument, options);
      }
export type LinkInitiativeToObjectiveMutationHookResult = ReturnType<typeof useLinkInitiativeToObjectiveMutation>;
export type LinkInitiativeToObjectiveMutationResult = Apollo.MutationResult<LinkInitiativeToObjectiveMutation>;
export type LinkInitiativeToObjectiveMutationOptions = Apollo.BaseMutationOptions<LinkInitiativeToObjectiveMutation, LinkInitiativeToObjectiveMutationVariables>;
export const LinkInitiativeDialogDocument = gql`
    query LinkInitiativeDialog($objectiveId: ID!) {
  activeOrg {
    id
    orgUnits {
      ...linkInitiativeDialogOrgUnit
    }
  }
  objective(id: $objectiveId) {
    id
    childObjectives {
      ...linkInitiativeDialogObjective
    }
  }
}
    ${LinkInitiativeDialogOrgUnitFragmentDoc}
${LinkInitiativeDialogObjectiveFragmentDoc}`;

/**
 * __useLinkInitiativeDialogQuery__
 *
 * To run a query within a React component, call `useLinkInitiativeDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkInitiativeDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkInitiativeDialogQuery({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *   },
 * });
 */
export function useLinkInitiativeDialogQuery(baseOptions: Apollo.QueryHookOptions<LinkInitiativeDialogQuery, LinkInitiativeDialogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LinkInitiativeDialogQuery, LinkInitiativeDialogQueryVariables>(LinkInitiativeDialogDocument, options);
      }
export function useLinkInitiativeDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinkInitiativeDialogQuery, LinkInitiativeDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LinkInitiativeDialogQuery, LinkInitiativeDialogQueryVariables>(LinkInitiativeDialogDocument, options);
        }
export type LinkInitiativeDialogQueryHookResult = ReturnType<typeof useLinkInitiativeDialogQuery>;
export type LinkInitiativeDialogLazyQueryHookResult = ReturnType<typeof useLinkInitiativeDialogLazyQuery>;
export type LinkInitiativeDialogQueryResult = Apollo.QueryResult<LinkInitiativeDialogQuery, LinkInitiativeDialogQueryVariables>;