import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

const Container = styled.div`
  border-top: 1px solid ${(props) => props.theme.color.strokeMedium};
`;

type Props = PropsWithChildren;

const FormContainerSubHeader = ({ children }: Props) => {
  if (children) {
    return <Container>{children}</Container>;
  } else {
    return null;
  }
};

export default FormContainerSubHeader;
