import { joinPaths } from '@remix-run/router';

import { RoutePath } from './RoutePath';
import type {
  RoutePathOptions,
  RoutePathParams,
  RoutePathQuery,
} from './RoutePath';

export const createRoutePath =
  <
    Params extends RoutePathParams = never,
    Query extends RoutePathQuery = never,
  >(
    path: string,
    createOptions: RoutePathOptions<Params, Query> = {},
  ) =>
  (options: RoutePathOptions<Params, Query> = {}) => {
    const routePath = new RoutePath(path, {
      ...createOptions,
      ...options,
    });
    return routePath.toString();
  };

export const joinRoutesPaths = (...paths: string[]) => joinPaths(paths);
