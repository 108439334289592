import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useSessionStorage } from 'react-use';

const redirectToAfterSignUpPathnameStorageKey = 'redirectToAfterSignUpPathname';

type UseRedirectAfterSignUpReturnType = {
  redirectAfterSignup: () => void;
  setRedirectAfterSignupPathname: (pathname: string) => void;
};

export const useRedirectAfterSignUp = (): UseRedirectAfterSignUpReturnType => {
  const navigate = useNavigate();

  const [pathname, setPathname] = useSessionStorage<string | null>(
    redirectToAfterSignUpPathnameStorageKey,
  );

  const redirectAfterSignup = useCallback(() => {
    if (pathname) {
      setPathname(null);
      navigate(pathname);
    }
  }, [pathname, setPathname, navigate]);

  return {
    setRedirectAfterSignupPathname: setPathname,
    redirectAfterSignup,
  };
};
