import type { TFunction } from 'react-i18next';

import { yup } from 'shared/services/yup.service';

export const createValidationSchema = (params: { t: TFunction }) => {
  const { t } = params;
  return yup.object({
    email: yup.string().email(t('signUpForm.email.invalidEmail')).required(),
  });
};
