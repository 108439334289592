import { createContext } from 'react';

import type { Org } from 'types.graphql.generated';

import type { ActiveOrg } from './ActiveOrgProvider.type';

export type ActiveOrgProviderContextValue = {
  activeOrg: ActiveOrg;
  setActiveOrg: (orgKey: Org['orgKey']) => void;
};

export const ActiveOrgProviderContext =
  createContext<Maybe<ActiveOrgProviderContextValue>>(undefined);
