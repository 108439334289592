import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import type { Selection } from 'react-stately';
import { useListState } from 'react-stately';

import Flex from 'shared/components/Flex';
import { useListBoxCollectionChildren } from 'shared/components/ListBox';
import MultiSelect from 'shared/components/MultiSelect';
import Text from 'shared/components/Text';
import type { ProgressStatus } from 'types.graphql.generated';

const Container = styled.span`
  div {
    width: 240px;
  }
`;

type Item = {
  id: ProgressStatus;
};

const items: Item[] = [
  { id: 'NOT_STARTED' },
  { id: 'IN_PROGRESS' },
  { id: 'DONE' },
];

type Props = {
  setValues: (values: ProgressStatus[]) => void;
  values: ProgressStatus[];
};

const TasksSectionStatusFilter = ({ values, setValues }: Props) => {
  const { t } = useTranslation();

  const collectionChildren = useListBoxCollectionChildren({
    Row: ({ item }) => (
      <Text variant={'emphasis'}>
        {t(
          `toolkit.toolPage.oneThing.campaignResults.tasks.statusFilter.label.${item.id}`,
        )}
      </Text>
    ),
  });

  const state = useListState<Item>({
    items,
    selectionMode: 'multiple',
    selectedKeys: values,
    children: collectionChildren,
    onSelectionChange: (keys: Selection) =>
      setValues(Array.from(keys) as ProgressStatus[]),
  });

  return (
    <Container>
      <MultiSelect<Item>
        state={state}
        items={items}
        label={({ selectionManager: { selectedKeys } }) => {
          const prefix = t(
            'toolkit.toolPage.oneThing.campaignResults.tasks.statusFilter.prefix',
          );

          const valuesLabel =
            selectedKeys.size === 0
              ? t('status.noStatus')
              : selectedKeys.size === 1
              ? t(
                  `toolkit.toolPage.oneThing.campaignResults.tasks.statusFilter.label.${
                    Array.from(selectedKeys)[0]
                  }`,
                )
              : selectedKeys.size === items.length
              ? t('status.allStatus')
              : t(
                  'toolkit.toolPage.oneThing.campaignResults.tasks.statusFilter.count',
                  {
                    count: selectedKeys.size,
                  },
                );

          return (
            <Flex>
              <Text>
                {prefix}
                {': '}
              </Text>
              {valuesLabel}
            </Flex>
          );
        }}
      />
    </Container>
  );
};

export default TasksSectionStatusFilter;
