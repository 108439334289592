import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import OpenTextInputField from 'shared/components/OpenTextInput/OpenTextInput.field';
import FieldBox from 'shared/form/FieldBox';
import { DatePickerField } from 'shared/components/DatePicker';
import Flex from 'shared/components/Flex';
import {
  UserEmailInputField,
  UserEmailInputProvider,
} from 'user/UserEmailInput';
import { useTeamAdapter } from 'team/TeamAdapter';
import {
  SelectObjectiveInputField,
  SelectObjectiveInputProvider,
} from 'objective/SelectObjectiveInput';

import type { MetricFormValues } from '../../EditMetric/MetricForm';

type Props = {
  showObjectiveField: boolean;
};

const MetricQuickFormFields = ({ showObjectiveField }: Props) => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();

  const { values, getFieldMeta } = useFormikContext<MetricFormValues>();

  const hasError = (field: string) => getFieldMeta(field).error !== undefined;

  return (
    <Flex direction={'column'} gap={20}>
      <Field
        component={OpenTextInputField}
        name={'name'}
        hasError={hasError('name')}
        placeholder={t('metric.metricForm.name.placeholder')}
        fontSize={'big'}
        maxLength={100}
        autoFocus={true}
      />

      <Field
        component={OpenTextInputField}
        name={'description'}
        hasError={hasError('description')}
        placeholder={t('metric.metricForm.description.placeholder')}
        maxLength={1000}
        inputType={'textarea'}
      />

      <div>
        <Flex gap={20}>
          <Flex gap={12}>
            <FieldBox
              name={'timeLine.startDate'}
              label={t('metric.metricForm.timeline.startDate.label')}
              hasError={hasError('startDate')}
              hasMargin={false}
            >
              <Field
                name={'timeLine.startDate'}
                component={DatePickerField}
                maxDate={values.timeLine.endDate}
                triggerFullWidth={true}
              />
            </FieldBox>
            <FieldBox
              name={'timeLine.endDate'}
              label={t('metric.metricForm.timeline.endDate.label')}
              hasError={hasError('timeLine.endDate')}
              hasMargin={false}
            >
              <Field
                name={'timeLine.endDate'}
                component={DatePickerField}
                minDate={values.timeLine.startDate}
                triggerFullWidth={true}
              />
            </FieldBox>
          </Flex>

          <Flex grow={1} direction={'column'}>
            <FieldBox
              name={'owner'}
              label={t('metric.metricForm.owner.label')}
              hasError={hasError('owner')}
            >
              <UserEmailInputProvider teamAdapter={teamAdapter}>
                <Field
                  component={UserEmailInputField}
                  name={'owner'}
                  hasError={hasError('owner')}
                  autoComplete={'false'}
                  placeholder={t('actions.form.owner.placeholder')}
                />
              </UserEmailInputProvider>
            </FieldBox>
          </Flex>
        </Flex>

        {showObjectiveField && (
          <FieldBox
            name={'objective'}
            label={t('metric.metricForm.objective.label')}
            hasMargin={false}
          >
            <SelectObjectiveInputProvider
              teamAdapter={teamAdapter}
              filterByAction={'WRITE'}
            >
              <Field
                name={'objective'}
                component={SelectObjectiveInputField}
                size={'full'}
              />
            </SelectObjectiveInputProvider>
          </FieldBox>
        )}
      </div>
    </Flex>
  );
};

export default MetricQuickFormFields;
