import type { ListState } from 'react-stately';
import { Trans, useTranslation } from 'react-i18next';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

import Space from 'shared/components/Space';
import Text from 'shared/components/Text';

import type { UserMultiSelectItem } from '../UserMultiSelect.type';

type UserMultiSelectLabelProps = {
  state: ListState<UserMultiSelectItem>;
};

const UserMultiSelectLabel = ({ state }: UserMultiSelectLabelProps) => {
  const { t } = useTranslation();

  const selectedKeysCount = state.selectionManager.selectedKeys.size;

  const content = useMemo<ReactNode>(() => {
    const value = selectedKeysCount
      ? `${selectedKeysCount} ${t('user.user', { count: selectedKeysCount })}`
      : t('user.allUsers');
    return (
      <Trans i18nKey={'user.userMultiSelect.label'} values={{ value }}>
        <Text variant={'emphasis'} />
      </Trans>
    );
  }, [selectedKeysCount, t]);

  return (
    <Space size={'small'}>
      <Text>{content}</Text>
    </Space>
  );
};

export default UserMultiSelectLabel;
