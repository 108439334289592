import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'shared/components/Button';
import AiSophieIcon from 'ai/AiSophieIcon';
import useAiAssistentActive from 'ai/useAiAssistentActive';

import StrategySummarySidePanel from './StrategySummarySidePanel';
import AiSummaryProvider from './AiSummaryProvider';
import type { PromptMessage } from './StrategySummarySidePanel/Prompt';

type Props = {
  actionIds?: string[];
  infoText: string;
  initiativeIds?: string[];
  metricIds?: string[];
  objectiveIds?: string[];
  promptMessages: PromptMessage[];
  strategyId?: string;
};

const StrategySummaryButton = ({
  promptMessages,
  objectiveIds = [],
  metricIds = [],
  initiativeIds = [],
  actionIds = [],
  strategyId,
  infoText,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { isAiAssistantActive } = useAiAssistentActive();

  if (!isAiAssistantActive) return null;

  return (
    <>
      <Button
        variant={'outlined'}
        onClick={() => setIsOpen(true)}
        icon={AiSophieIcon}
        iconPosition={'start'}
      >
        {t('ai.strategySummary.title')}
      </Button>

      <AiSummaryProvider
        objectiveIds={objectiveIds}
        metricIds={metricIds}
        initiativeIds={initiativeIds}
        actionIds={actionIds}
        strategyId={strategyId}
      >
        <StrategySummarySidePanel
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          promptMessages={promptMessages}
          infoText={infoText}
        />
      </AiSummaryProvider>
    </>
  );
};

export default StrategySummaryButton;
