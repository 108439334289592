import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import useDialogState from 'shared/hooks/useDialogState';

import { PreviousInsightsContext } from './PreviousInsightsContext';

type Props = PropsWithChildren<{
  formId?: string;
  initiativeId?: string;
  metricId?: string;
  objectiveId?: string;
}>;

const PreviousInsightsProvider = ({
  objectiveId,
  metricId,
  initiativeId,
  formId,
  children,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDialogState();

  const value = useMemo(
    () => ({
      objectiveId,
      metricId,
      initiativeId,
      formId,
      isPreviousInsightsOpen: isOpen,
      onOpenPreviousInsights: onOpen,
      onClosePreviousInsights: onClose,
    }),
    [formId, initiativeId, isOpen, metricId, objectiveId, onClose, onOpen],
  );

  return (
    <PreviousInsightsContext.Provider value={value}>
      {children}
    </PreviousInsightsContext.Provider>
  );
};

export default PreviousInsightsProvider;
