import type { NavigateFunction } from 'react-router-dom';

import type {
  ContributionSectionRowItem,
  LinkParams,
} from 'contribution/ContributionSection';
import type { RedirectToMyContributionLocationStateOption } from 'contribution/ContributionMyContribution';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import type { StrategyElement } from 'status/useSendStatusRequest';
import { TeamAdapter } from 'team/TeamAdapter';
import type { OrgForTeamAdapter } from 'team/TeamAdapter/TeamAdapter.type';

export const getEditLink = ({ item, parentItem, teamSlug }: LinkParams) => {
  const goToMyContributionStateOption = {
    state: {
      goToMyContribution: true,
      scrollToMyStrategy: true,
    } satisfies RedirectToMyContributionLocationStateOption,
  };

  const { __typename: typename, id: itemId } = item;
  const objectiveId = parentItem?.id ?? '';

  switch (typename) {
    case 'Objective':
    case 'ChildObjective':
      return {
        editLink: objectiveRoutesPaths.edit({
          params: {
            objectiveId: itemId,
            teamSlug,
          },
        }),
        editLinkOptions: goToMyContributionStateOption,
      };

    case 'Initiative':
      return {
        editLink: objectiveRoutesPaths.initiatives.initiative.edit({
          params: {
            initiativeId: itemId,
            objectiveId,
            teamSlug,
          },
        }),
        editLinkOptions: goToMyContributionStateOption,
      };
    case 'Metric':
      return {
        editLink: objectiveRoutesPaths.metrics.metric.edit({
          params: {
            metricId: itemId,
            objectiveId,
            teamSlug,
          },
        }),
        editLinkOptions: goToMyContributionStateOption,
      };
    case 'Action':
      return {
        editLink: objectiveRoutesPaths.actions.action.edit({
          params: {
            actionId: itemId,
            objectiveId,
            teamSlug,
          },
        }),
        editLinkOptions: goToMyContributionStateOption,
      };
  }
};

export const getShareProgressLink = ({
  item,
  parentItem,
  teamSlug,
}: LinkParams) => {
  const goToMyContributionStateOption = {
    state: {
      goToMyContribution: true,
      scrollToMyStrategy: true,
    } satisfies RedirectToMyContributionLocationStateOption,
  };

  const { __typename: typename, id: itemId } = item;
  const objectiveId = parentItem?.id ?? '';

  switch (typename) {
    case 'Objective':
    case 'ChildObjective':
      return {
        shareProgressLink: objectiveRoutesPaths.insights.submit({
          params: {
            objectiveId: itemId,
            teamSlug,
          },
        }),
        shareProgressLinkOptions: goToMyContributionStateOption,
      };

    case 'Initiative':
      return {
        shareProgressLink:
          objectiveRoutesPaths.initiatives.initiative.insights.submit({
            params: {
              initiativeId: itemId,
              objectiveId,
              teamSlug,
            },
          }),
        shareProgressLinkOptions: goToMyContributionStateOption,
      };

    case 'Metric':
      return {
        shareProgressLink: objectiveRoutesPaths.metrics.metric.insights.submit({
          params: {
            metricId: itemId,
            objectiveId,
            teamSlug,
          },
        }),
        shareProgressLinkOptions: goToMyContributionStateOption,
      };

    case 'Action':
      return {
        shareProgressLink: objectiveRoutesPaths.actions.action.root({
          params: {
            objectiveId,
            teamSlug,
            actionId: itemId,
          },
        }),
        shareProgressLinkOptions: goToMyContributionStateOption,
      };
  }
};

export const getOnAction =
  (
    navigate: NavigateFunction,
    sendRequestStatus: (strategyElement: StrategyElement) => void,
    activeOrg: OrgForTeamAdapter,
  ) =>
  (
    item: ContributionSectionRowItem['item'] & { isCurrentUserOwner: boolean },
    parentItem: ContributionSectionRowItem['parentItem'],
  ) =>
  (id: string) => {
    switch (id) {
      case 'requestStatus': {
        sendRequestStatus(item);
        break;
      }
      case 'edit': {
        const { editLink, editLinkOptions } = getEditLink({
          item,
          parentItem,
          teamSlug: TeamAdapter.fromOrgUnitOrOrg(
            activeOrg,
            item.orgUnit,
          ).toParam(),
        });

        navigate(editLink, editLinkOptions);
        break;
      }
      case 'shareProgress': {
        const { shareProgressLink, shareProgressLinkOptions } =
          getShareProgressLink({
            item,
            parentItem,
            teamSlug: TeamAdapter.fromOrgUnitOrOrg(
              activeOrg,
              item.orgUnit,
            ).toParam(),
          });

        navigate(shareProgressLink, shareProgressLinkOptions);
        break;
      }
    }
  };
