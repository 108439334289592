import { useDefinedContext } from 'shared/utils/context.utils';

import { ActionContext } from './ActionProvider.context';
import ActionProvider from './ActionProvider';

export const useAction = () =>
  useDefinedContext(ActionContext, {
    hookName: useAction.name,
    providerName: ActionProvider.name,
  });
