import { createContext } from 'react';

import type { StrategyProviderStrategyFragment } from './StrategyProvider.graphql.generated';

export type StrategyProviderContextValue = {
  strategy?: StrategyProviderStrategyFragment;
};

export const StrategyProviderContext = createContext<
  StrategyProviderContextValue | undefined
>(undefined);
