import type {
  CSSProperties,
  MouseEventHandler,
  PropsWithChildren,
} from 'react';
import { forwardRef } from 'react';
import cn from 'classnames';

import type { Size } from 'shared/types/size.type';

import type { SpaceDirection } from './Space.type';
import styles from './Space.module.scss';

export type SpaceProps = PropsWithChildren<{
  className?: string;
  direction?: SpaceDirection;
  gap?: CSSProperties['gap'];
  isCentered?: boolean;
  isWrap?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  size?:
    | Extract<Size, 'large' | 'big' | 'medium' | 'small' | 'tiny'>
    | 'custom';
  value?: CSSProperties['gap'];
}>;

/**
 * Sets unified space between interface elements.
 */
const Space = forwardRef<HTMLDivElement, SpaceProps>(
  (
    {
      size = 'medium',
      isWrap = false,
      direction = 'horizontal',
      isCentered = false,
      children,
      value,
      className,
      gap,
      ...restProps
    },
    ref,
  ) => (
    <div
      ref={ref}
      className={cn(
        styles.space,
        {
          [styles.spaceLarge]: size === 'large',
          [styles.spaceBig]: size === 'big',
          [styles.spaceMedium]: size === 'medium',
          [styles.spaceSmall]: size === 'small',
          [styles.spaceTiny]: size === 'tiny',
          [styles.spaceWrap]: isWrap,
          [styles.spaceCentered]: isCentered,
          [styles.spaceHorizontal]: direction === 'horizontal',
          [styles.spaceVertical]: direction === 'vertical',
        },
        className,
      )}
      style={{
        gap: gap ? gap : size === 'custom' ? value : undefined,
      }}
      {...restProps}
    >
      {children}
    </div>
  ),
);

export default Space;
