import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserLimitedProfileFragmentDoc } from '../../../user/userProfile.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ObjectiveInitiativesQueryVariables = Types.Exact<{
  objectiveId: Types.Scalars['ID'];
}>;


export type ObjectiveInitiativesQuery = { __typename: 'Query', objective: { __typename: 'Objective', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, isCurrentUserOwner: boolean, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, initiatives: Array<{ __typename: 'Initiative', id: string, name?: string | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined }, currentInitiativeStatus?: { __typename: 'InitiativeStatus', id: string, complete: boolean, statusIndicator: StatusIndicatorClass } | undefined, objective?: { __typename: 'Objective', id: string, name?: string | undefined } | undefined }>, owner?: { __typename: 'UserLimited', id: string } | undefined, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined } } };

export type ObjectiveInitiativesObjectiveFragment = { __typename: 'Objective', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, isCurrentUserOwner: boolean, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, initiatives: Array<{ __typename: 'Initiative', id: string, name?: string | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined }, currentInitiativeStatus?: { __typename: 'InitiativeStatus', id: string, complete: boolean, statusIndicator: StatusIndicatorClass } | undefined, objective?: { __typename: 'Objective', id: string, name?: string | undefined } | undefined }>, owner?: { __typename: 'UserLimited', id: string } | undefined, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined } };

export const ObjectiveInitiativesObjectiveFragmentDoc = gql`
    fragment objectiveInitiativesObjective on Objective {
  id
  name
  currentUserAuthorizedActions
  isCurrentUserOwner
  orgUnit {
    id
  }
  initiatives {
    id
    name
    owner {
      ...userLimitedProfile
    }
    timeLine {
      startDate
      endDate
    }
    currentInitiativeStatus {
      id
      complete
      statusIndicator
    }
    objective {
      id
      name
    }
  }
  owner {
    id
  }
  timeLine {
    startDate
    endDate
  }
}
    ${UserLimitedProfileFragmentDoc}`;
export const ObjectiveInitiativesDocument = gql`
    query ObjectiveInitiatives($objectiveId: ID!) {
  objective(id: $objectiveId) {
    ...objectiveInitiativesObjective
  }
}
    ${ObjectiveInitiativesObjectiveFragmentDoc}`;

/**
 * __useObjectiveInitiativesQuery__
 *
 * To run a query within a React component, call `useObjectiveInitiativesQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectiveInitiativesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectiveInitiativesQuery({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *   },
 * });
 */
export function useObjectiveInitiativesQuery(baseOptions: Apollo.QueryHookOptions<ObjectiveInitiativesQuery, ObjectiveInitiativesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectiveInitiativesQuery, ObjectiveInitiativesQueryVariables>(ObjectiveInitiativesDocument, options);
      }
export function useObjectiveInitiativesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectiveInitiativesQuery, ObjectiveInitiativesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectiveInitiativesQuery, ObjectiveInitiativesQueryVariables>(ObjectiveInitiativesDocument, options);
        }
export type ObjectiveInitiativesQueryHookResult = ReturnType<typeof useObjectiveInitiativesQuery>;
export type ObjectiveInitiativesLazyQueryHookResult = ReturnType<typeof useObjectiveInitiativesLazyQuery>;
export type ObjectiveInitiativesQueryResult = Apollo.QueryResult<ObjectiveInitiativesQuery, ObjectiveInitiativesQueryVariables>;