import { createContext } from 'react';

import type { StrategyObjectivesPriorityFilter } from 'strategy/StrategyObjectives/StrategyObjectives.type';

import type {
  StrategyBoardObjective,
  StrategyBoardTheme,
  StrategyBoardStatusFilter,
  StrategyBoardUserFilter,
  StrategyBoardUser,
  StrategyBoardStartDateFilter,
  StrategyBoardEndDateFilter,
} from '../StrategyBoard.type';

export type StrategyBoardContextValue = {
  filters: {
    clearFilters: () => void;
    endDateFilter: StrategyBoardEndDateFilter;
    priorityFilter: StrategyObjectivesPriorityFilter;
    setEndDateFilter: (value: StrategyBoardEndDateFilter) => void;
    setPriorityFilter: (value: StrategyObjectivesPriorityFilter) => void;
    setStartDateFilter: (value: StrategyBoardStartDateFilter) => void;
    setStatusIndicatorFilter: (newFilter: StrategyBoardStatusFilter) => void;
    setUserFilter: (value: StrategyBoardUserFilter) => void;
    startDateFilter: StrategyBoardStartDateFilter;
    statusIndicatorFilter: StrategyBoardStatusFilter;
    userFilter: StrategyBoardUserFilter;
  };
  isStrategyBoardLoading: boolean;
  objectives: StrategyBoardObjective[];
  themes: StrategyBoardTheme[];
  users: StrategyBoardUser[];
};

export const StrategyBoardContext = createContext<
  StrategyBoardContextValue | undefined
>(undefined);
