import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCreateOneThingTaskMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.OneThingTaskInput>;
}>;


export type UpdateCreateOneThingTaskMutation = { __typename: 'Mutation', saveOneThingTask: { __typename: 'OneThingTask', id: string, completionRate: number, status: Types.ProgressStatus } };


export const UpdateCreateOneThingTaskDocument = gql`
    mutation UpdateCreateOneThingTask($input: OneThingTaskInput) {
  saveOneThingTask(input: $input) {
    id
    completionRate
    status
  }
}
    `;
export type UpdateCreateOneThingTaskMutationFn = Apollo.MutationFunction<UpdateCreateOneThingTaskMutation, UpdateCreateOneThingTaskMutationVariables>;

/**
 * __useUpdateCreateOneThingTaskMutation__
 *
 * To run a mutation, you first call `useUpdateCreateOneThingTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreateOneThingTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreateOneThingTaskMutation, { data, loading, error }] = useUpdateCreateOneThingTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreateOneThingTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCreateOneThingTaskMutation, UpdateCreateOneThingTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCreateOneThingTaskMutation, UpdateCreateOneThingTaskMutationVariables>(UpdateCreateOneThingTaskDocument, options);
      }
export type UpdateCreateOneThingTaskMutationHookResult = ReturnType<typeof useUpdateCreateOneThingTaskMutation>;
export type UpdateCreateOneThingTaskMutationResult = Apollo.MutationResult<UpdateCreateOneThingTaskMutation>;
export type UpdateCreateOneThingTaskMutationOptions = Apollo.BaseMutationOptions<UpdateCreateOneThingTaskMutation, UpdateCreateOneThingTaskMutationVariables>;