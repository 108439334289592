import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateOrgUnitBackgroundImageMutationVariables = Types.Exact<{
  orgUnitId: Types.Scalars['ID'];
  fileUrl: Types.Scalars['String'];
}>;


export type UpdateOrgUnitBackgroundImageMutation = { __typename: 'Mutation', updateOrgUnit: { __typename: 'OrgUnit', id: string, backgroundPhotoUrl?: string | undefined } };


export const UpdateOrgUnitBackgroundImageDocument = gql`
    mutation UpdateOrgUnitBackgroundImage($orgUnitId: ID!, $fileUrl: String!) {
  updateOrgUnit(input: {idToUpdate: $orgUnitId, backgroundPhotoUrl: $fileUrl}) {
    id
    backgroundPhotoUrl
  }
}
    `;
export type UpdateOrgUnitBackgroundImageMutationFn = Apollo.MutationFunction<UpdateOrgUnitBackgroundImageMutation, UpdateOrgUnitBackgroundImageMutationVariables>;

/**
 * __useUpdateOrgUnitBackgroundImageMutation__
 *
 * To run a mutation, you first call `useUpdateOrgUnitBackgroundImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgUnitBackgroundImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgUnitBackgroundImageMutation, { data, loading, error }] = useUpdateOrgUnitBackgroundImageMutation({
 *   variables: {
 *      orgUnitId: // value for 'orgUnitId'
 *      fileUrl: // value for 'fileUrl'
 *   },
 * });
 */
export function useUpdateOrgUnitBackgroundImageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrgUnitBackgroundImageMutation, UpdateOrgUnitBackgroundImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrgUnitBackgroundImageMutation, UpdateOrgUnitBackgroundImageMutationVariables>(UpdateOrgUnitBackgroundImageDocument, options);
      }
export type UpdateOrgUnitBackgroundImageMutationHookResult = ReturnType<typeof useUpdateOrgUnitBackgroundImageMutation>;
export type UpdateOrgUnitBackgroundImageMutationResult = Apollo.MutationResult<UpdateOrgUnitBackgroundImageMutation>;
export type UpdateOrgUnitBackgroundImageMutationOptions = Apollo.BaseMutationOptions<UpdateOrgUnitBackgroundImageMutation, UpdateOrgUnitBackgroundImageMutationVariables>;