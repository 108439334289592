import { useDefinedContext } from 'shared/utils/context.utils';

import { StrategyRoadmapProviderContext } from './StrategyRoadmapProvider.context';
import StrategyRoadmapProvider from './StrategyRoadmapProvider';

export const useStrategyRoadmap = () =>
  useDefinedContext(StrategyRoadmapProviderContext, {
    providerName: StrategyRoadmapProvider.name,
    hookName: useStrategyRoadmap.name,
  });
