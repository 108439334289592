import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';

import InitiativesContentHeader from 'initiative/InitiativesContent/InitiativesContentHeader';
import Spinner from 'shared/spinner/Spinner';
import InitiativesContentProvider from 'initiative/InitiativesContent/InitiativesContentProvider';
import Layout from 'shared/components/Layout';
import type { UserMultiSelectItemId } from 'user/UserMultiSelect';
import UserMultiSelect from 'user/UserMultiSelect';
import type { StatusMultiSelectItemId } from 'shared/status/StatusMultiSelect';
import StatusMultiSelect from 'shared/status/StatusMultiSelect';
import type { PriorityMultiSelectItemId } from 'shared/priority/PriorityMultiSelect';
import PriorityMultiSelect from 'shared/priority/PriorityMultiSelect';
import ToggleExpandIconButton from 'shared/components/ToggleExpandIconButton';
import InitiativesContentLayoutProvider from 'initiative/InitiativesContent/InitiativesContentLayoutProvider';
import { useInitiativesContentLayout } from 'initiative/InitiativesContent/InitiativesContentLayoutProvider/useInitiativesContentLayout';
import CalendarControls, {
  CalendarControlsProvider,
} from 'shared/components/CalendarControls';
import CompletedFilterProvider from 'shared/components/CompletedFilterButton/CompletedFilterProvider';
import CompletedFilterButton from 'shared/components/CompletedFilterButton';
import ShowChildrenFilterButton from 'shared/components/ShowChildrenFilterButton';
import ShowChildrenFilterProvider from 'shared/components/ShowChildrenFilterButton/CompletedFilterProvider';
import InitiativesContent from 'initiative/InitiativesContent';

import styles from './StrategyInitiatives.module.scss';
import StrategyInitiativesProvider from './StrategyInitiativesProvider/StrategyInitiativesProvider';
import { useStrategyInitiatives } from './StrategyInitiativesProvider/useStrategyInitiatives';
import StrategyInitiativesFilterButton from './StrategyInitiativesFilterButton';
import StrategyInitiativesFiltersChipGroup from './StrategyInitiativesFiltersChipGroup';

const StrategyInitiatives = () => {
  const { t } = useTranslation();

  const {
    users,
    objectives,
    isStrategyInitiativesLoading,
    filters: {
      statusIndicatorFilter,
      setStatusIndicatorFilter,
      userFilter,
      setUserFilter,
      priorityFilter,
      setPriorityFilter,
      clearFilters,
    },
  } = useStrategyInitiatives();

  const { layout } = useInitiativesContentLayout();

  const [areAllSectionsExpanded, toggleAreAllSectionsExpanded] =
    useToggle(true);

  return (
    <UserMultiSelect.Provider
      selectedKeys={userFilter}
      onSelectionChange={(selection) => {
        setUserFilter([...selection] as UserMultiSelectItemId[]);
      }}
      users={users}
    >
      <StatusMultiSelect.Provider
        selectedKeys={statusIndicatorFilter}
        onSelectionChange={(selection) => {
          setStatusIndicatorFilter([...selection] as StatusMultiSelectItemId[]);
        }}
      >
        <PriorityMultiSelect.Provider
          selectedKeys={priorityFilter}
          onSelectionChange={(selection) => {
            setPriorityFilter([...selection] as PriorityMultiSelectItemId[]);
          }}
        >
          <InitiativesContentProvider objectives={objectives}>
            <Layout.Content
              hasPadding={false}
              className={styles.layout}
              variant={'contained'}
            >
              {isStrategyInitiativesLoading ? (
                <div className={styles.spinnerContainer}>
                  <Spinner.Circle />
                </div>
              ) : (
                <>
                  <InitiativesContentHeader
                    afterStats={<StrategyInitiativesFiltersChipGroup />}
                    controls={<CalendarControls />}
                    filters={
                      <>
                        <CompletedFilterButton />
                        <ShowChildrenFilterButton
                          activeTooltip={t(
                            'filters.showChildrenFilter.tooltip.hide.initiative',
                          )}
                          inactiveTooltip={t(
                            'filters.showChildrenFilter.tooltip.show.initiative',
                          )}
                        />
                        <StrategyInitiativesFilterButton
                          clearFilters={clearFilters}
                        />
                        <ToggleExpandIconButton
                          isExpanded={areAllSectionsExpanded}
                          onToggle={toggleAreAllSectionsExpanded}
                          isDisabled={layout !== 'list'}
                        />
                      </>
                    }
                  />
                  <InitiativesContent
                    areAllSectionsExpanded={areAllSectionsExpanded}
                    sortListByTheme={true}
                  />
                </>
              )}
            </Layout.Content>
          </InitiativesContentProvider>
        </PriorityMultiSelect.Provider>
      </StatusMultiSelect.Provider>
    </UserMultiSelect.Provider>
  );
};

export default () => (
  <CalendarControlsProvider storeToSession={true}>
    <CompletedFilterProvider storeToSession={true}>
      <ShowChildrenFilterProvider storeToSession={true}>
        <StrategyInitiativesProvider>
          <InitiativesContentLayoutProvider>
            <StrategyInitiatives />
          </InitiativesContentLayoutProvider>
        </StrategyInitiativesProvider>
      </ShowChildrenFilterProvider>
    </CompletedFilterProvider>
  </CalendarControlsProvider>
);
