import { useDefinedContext } from 'shared/utils/context.utils';

import { ThemeMultiSelectProviderContext } from './ThemeMultiSelectProvider.context';
import ThemeMultiSelectProvider from './ThemeMultiSelectProvider';

export const useThemeMultiSelect = () =>
  useDefinedContext(ThemeMultiSelectProviderContext, {
    providerName: ThemeMultiSelectProvider.name,
    hookName: useThemeMultiSelect.name,
  });
