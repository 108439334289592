import {
  createContext,
  useState,
  type PropsWithChildren,
  type ReactNode,
  useMemo,
  useCallback,
} from 'react';

import { useDefinedContext } from 'shared/utils/context.utils';

export type ChatMessageContent = string | Exclude<ReactNode, null | undefined>;
export type ChatMessageObject = {
  avatar?: ReactNode;
  bubbleWrap?: boolean;
  content: ChatMessageContent;
  key: string;
  waitForInput?: boolean;
};
export type ChatMessage = ChatMessageContent | ChatMessageObject;

type ChatContextValue = {
  allMessages: ChatMessage[];
  continueChat: () => void;
  setMessages: (messages: ChatMessage[]) => void;
  setVisibleMessages: (messages: ChatMessage[]) => void;
  visibleMessages: ChatMessage[];
};

const ChatContext = createContext<ChatContextValue | undefined>(undefined);

export const useChat = () => useDefinedContext(ChatContext);

const ChatProvider = ({ children }: PropsWithChildren) => {
  const [allMessages, setAllMessages] = useState<ChatMessage[]>([]);
  const [visibleMessages, setVisibleMessages] = useState<ChatMessage[]>([]);

  const continueChat = useCallback(() => {
    setVisibleMessages((currentVisibleMessages) => {
      const nextMessage = allMessages[currentVisibleMessages.length];
      if (!nextMessage) return currentVisibleMessages;
      return [...currentVisibleMessages, nextMessage];
    });
  }, [allMessages]);

  const setMessages = useCallback((newMessages: ChatMessage[]) => {
    setAllMessages(newMessages);
    setVisibleMessages([newMessages[0]]);
  }, []);

  const value = useMemo(
    () => ({
      allMessages,
      setMessages,
      visibleMessages,
      setVisibleMessages,
      continueChat,
    }),
    [allMessages, continueChat, setMessages, visibleMessages],
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export default ChatProvider;
