import type { Entries } from 'type-fest';

import sizes from './Breakpoints.module.scss';

type BreakpointKey = 'phone' | 'tablet' | 'desktop';

type Breakpoints = Record<BreakpointKey, number>;

export const breakpoints = Object.fromEntries(
  Object.entries(sizes).map(([key, value]) => [
    key,
    parseInt(value),
  ]) as Entries<Breakpoints>,
) as Breakpoints;
