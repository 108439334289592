import { useRouteError } from 'react-router-dom';
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

import { useErrorPage } from 'shared/errors/ErrorPage/useErrorPage';

const AppErrorBoundary = () => {
  const { onError } = useErrorPage();
  const error = useRouteError() as Maybe<Error>;

  useEffect(() => {
    if (error) {
      datadogRum.addError(error);
      onError(error);
    }
  }, [error, onError]);

  return null;
};

export default AppErrorBoundary;
