import { useField } from 'formik';
import { useCallback } from 'react';

export default function useAddValueToArrayField<Item>(
  fieldName: string,
  createItem: (value: string) => Item,
  getItemValue: (item: Item) => string,
) {
  const [field, _meta, helpers] = useField<Item[]>(fieldName);

  const addValue = useCallback(
    (newValue?: string) => {
      if (!newValue || newValue === '') return;

      const currentValue = field.value;
      const lastItem = currentValue.at(-1);
      const lastItemValue = lastItem ? getItemValue(lastItem) : undefined;

      if (lastItemValue === newValue) return;

      const isLastItemEmpty = !lastItemValue || lastItemValue === '';
      const previousItems = isLastItemEmpty
        ? currentValue.slice(0, currentValue.length - 1)
        : currentValue;

      const newItem = createItem(newValue);

      helpers.setValue([...previousItems, newItem]).then(() => {
        helpers.setTouched(true);
      });
    },
    [createItem, field.value, getItemValue, helpers],
  );

  return { addValue };
}
