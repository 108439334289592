import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import Heading from 'shared/components/Heading';
import Text from 'shared/components/Text';
import type {
  StatusCommentsFormProps,
  StatusCommentsFormValues,
} from 'shared/status/StatusCommentsForm';
import StatusCommentsForm from 'shared/status/StatusCommentsForm';
import AiSuggestions from 'ai/AiSuggestions';
import type { Report } from 'types.graphql.generated';
import SuggestionsProvider from 'ai/AiSuggestions/SuggestionsProvider';
import Form from 'shared/form/Form';
import { createInitialValues } from 'shared/status/StatusCommentsForm/StatusCommentsForm.utils';
import Emoji from 'shared/components/Emoji';

import InsightReportWizardInfoCard from '../InsightReportWizardInfoCard';
import type {
  InsightsReportSuccessSuggestionsSubscription,
  InsightsReportSuccessSuggestionsSubscriptionVariables,
} from './InsightsReportSuccessSuggestions.graphql.generated';
import { InsightsReportSuccessSuggestionsDocument } from './InsightsReportSuccessSuggestions.graphql.generated';
import AllCommentsSideCard from '../AllCommentsSideCard';

export type InsightReportWizardSuccessesValues =
  StatusCommentsFormValues<'SUCCESS_COMMENT'>;

export type InsightReportWizardSuccessesProps = Pick<
  StatusCommentsFormProps<'SUCCESS_COMMENT'>,
  'initialValues' | 'onChange' | 'onSubmit' | 'id'
> & { reportId?: Report['id'] };

const InsightReportWizardSuccesses = (
  props: InsightReportWizardSuccessesProps,
) => {
  const { t } = useTranslation();

  const initialValues = useMemo(
    () => props.initialValues || createInitialValues(['SUCCESS_COMMENT']),
    [props.initialValues],
  );

  const renderForm = () => (
    <>
      <Heading level={3}>
        {t('report.insightReportWizard.successes.heading')}
      </Heading>
      <Text>{t('report.insightReportWizard.successes.description')}</Text>
      <StatusCommentsForm
        type={'SUCCESS_COMMENT'}
        suggestionField={'success comment'}
        hasTypeHeadings={false}
        enableReinitialize={true}
        {...props}
      />
    </>
  );

  return (
    <SuggestionsProvider<
      InsightsReportSuccessSuggestionsSubscription,
      InsightsReportSuccessSuggestionsSubscriptionVariables
    >
      subscriptionDocument={InsightsReportSuccessSuggestionsDocument}
      hasVariables={() => props.reportId !== undefined}
      elementIds={{ reportIds: props.reportId ? [props.reportId] : [] }}
    >
      <Form {...props} initialValues={initialValues}>
        <FormWithInfoCardLayout
          renderForm={renderForm}
          renderAfterInfoCard={
            <>
              <InsightReportWizardInfoCard wizardStep={'successes'} />

              <AllCommentsSideCard
                reportId={props.reportId}
                commentType={'SUCCESS_COMMENT'}
                icon={<Emoji icon={'🎉'} />}
                title={t('report.insightReportWizard.successes.viewAll.title')}
                showButtonLabel={t(
                  'report.insightReportWizard.successes.viewAll.button.show',
                )}
                hideButtonLabel={t(
                  'report.insightReportWizard.successes.viewAll.button.hide',
                )}
                emptyMessage={t(
                  'report.insightReportWizard.successes.viewAll.empty',
                )}
              />

              <AiSuggestions
                title={t(
                  'report.insightReportWizard.successes.askSophie.title',
                )}
                buttonLabel={t(
                  'report.insightReportWizard.successes.askSophie.button.label',
                )}
                buttonLabelAfterFirstTrigger={t(
                  'report.insightReportWizard.successes.askSophie.button.triggeredLabel',
                )}
                titleFieldName={'success comment'}
                suggestionAction={'add'}
              />
            </>
          }
        />
      </Form>
    </SuggestionsProvider>
  );
};

export default InsightReportWizardSuccesses;
