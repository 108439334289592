import { useTranslation } from 'react-i18next';

import { ReactComponent as ObjectiveIcon } from 'shared/static/icons/icon-flag.svg';
import { ReactComponent as MetricIcon } from 'shared/static/icons/icon-chart.svg';
import { ReactComponent as InitiativeIcon } from 'shared/static/icons/icon-initiative.svg';
import { ReactComponent as ActionIcon } from 'shared/static/icons/icon-check-square.svg';
import { ReactComponent as CheckIcon } from 'shared/static/icons/icon-check.svg';
import type { RequestStatusUpdateFormReportQuery } from 'report/RequestStatusUpdateForm/RequestStatusUpdateForm.graphql.generated';
import UserAvatar from 'user/UserAvatar/UserAvatar';
import StatusMark from 'shared/status/StatusMark';
import { date } from 'shared/services/date.service';
import Tooltip from 'shared/components/Tooltip';
import Text from 'shared/components/Text/Text';
import type { ReportStage } from 'types.graphql.generated';

import styles from './RequestStatusUpdatePreviewItem.module.scss';

export type ReportPreviewItem =
  | RequestStatusUpdateFormReportQuery['report']['reportSelection']['reportObjectives'][0]
  | RequestStatusUpdateFormReportQuery['report']['reportSelection']['reportObjectives'][0]['reportMetrics']['0']
  | RequestStatusUpdateFormReportQuery['report']['reportSelection']['reportObjectives'][0]['reportInitiatives']['0']
  | RequestStatusUpdateFormReportQuery['report']['reportSelection']['reportObjectives'][0]['reportActions']['0'];

type RequestStatusUpdatePreviewItemProps = {
  reportItem: ReportPreviewItem;
  reportStage?: ReportStage;
};

const RequestStatusUpdatePreviewItem = ({
  reportItem,
  reportStage,
}: RequestStatusUpdatePreviewItemProps) => {
  const { t } = useTranslation();
  const owner = getOwner(reportItem);
  const statusDateTime = getStatusDateTime(reportItem);
  const statusUpdatedAt = getStatusUpdatedAt(reportItem);

  const isStatusRequestComplete = reportItem.statusRequest?.isComplete;

  return (
    <>
      <div className={styles.nameCol}>
        {getIcon(reportItem)}
        <StatusMark statusIndicator={getStatusIndicator(reportItem)} />
        <div>{getName(reportItem)}</div>
      </div>
      <div className={styles.ownerCol}>
        <UserAvatar user={owner} size={'small'} showTooltip={false} />
        {owner?.displayName || owner?.email}
      </div>
      <div className={styles.statusCol}>
        <Tooltip
          placement={'right'}
          delay={0}
          content={
            statusUpdatedAt && (
              <>
                <Text variant={'light'}>{t('updated')}</Text>{' '}
                <Text>{date.format(statusUpdatedAt, 'dd.MM.yyyy')}</Text>
              </>
            )
          }
        >
          <Text variant={isStatusRequestComplete ? 'regular' : 'light'}>
            {t('status.status')}
            {': '}
            {statusDateTime ? date.format(statusDateTime, 'dd.MM.yyyy') : ''}
          </Text>
        </Tooltip>
      </div>
      <div className={styles.updatedCol}>
        {reportStage !== 'STATUS_REQUESTED' ? (
          ''
        ) : getIsCompleted(reportItem) ? (
          <div className={styles.completed}>{t('completed')}</div>
        ) : reportItem.statusRequest?.isComplete ? (
          <div className={styles.updated}>
            <CheckIcon />
            {t('updated')}
          </div>
        ) : (
          <div className={styles.pending}>{t('pending')}</div>
        )}
      </div>
    </>
  );
};

export default RequestStatusUpdatePreviewItem;

const getIcon = (reportItem: ReportPreviewItem) => {
  switch (reportItem.__typename) {
    case 'ReportObjective':
      return <ObjectiveIcon />;
    case 'ReportMetric':
      return <MetricIcon />;
    case 'ReportInitiative':
      return <InitiativeIcon />;
    case 'ReportAction':
      return <ActionIcon />;
  }
};

const getStatusIndicator = (reportItem: ReportPreviewItem) => {
  switch (reportItem.__typename) {
    case 'ReportObjective':
      return reportItem.objective.currentObjectiveStatus?.statusIndicator;
    case 'ReportMetric':
      return reportItem.metric.currentMetricStatus?.statusIndicator;
    case 'ReportInitiative':
      return reportItem.initiative.currentInitiativeStatus?.statusIndicator;
    case 'ReportAction':
      return reportItem.action.currentStatus?.statusIndicator;
  }
};

const getName = (reportItem: ReportPreviewItem) => {
  switch (reportItem.__typename) {
    case 'ReportObjective':
      return reportItem.objective.name;
    case 'ReportMetric':
      return reportItem.metric.name;
    case 'ReportInitiative':
      return reportItem.initiative.name;
    case 'ReportAction':
      return reportItem.action.name;
  }
};

const getOwner = (reportItem: ReportPreviewItem) => {
  switch (reportItem.__typename) {
    case 'ReportObjective':
      return reportItem.objective.owner;
    case 'ReportMetric':
      return reportItem.metric.owner;
    case 'ReportInitiative':
      return reportItem.initiative.owner;
    case 'ReportAction':
      return reportItem.action.owner;
  }
};

const getStatusDateTime = (reportItem: ReportPreviewItem): Date | undefined => {
  switch (reportItem.__typename) {
    case 'ReportObjective':
      return reportItem.objective.currentObjectiveStatus?.statusDateTime;
    case 'ReportMetric':
      return reportItem.metric.currentMetricStatus?.statusDateTime;
    case 'ReportInitiative':
      return reportItem.initiative.currentInitiativeStatus?.statusDateTime;
    case 'ReportAction':
      return reportItem.action.currentStatus?.statusDateTime;
  }
};

const getStatusUpdatedAt = (
  reportItem: ReportPreviewItem,
): Date | undefined => {
  switch (reportItem.__typename) {
    case 'ReportObjective':
      return reportItem.objective.currentObjectiveStatus?.auditRecord
        .updateDateTime;
    case 'ReportMetric':
      return reportItem.metric.currentMetricStatus?.auditRecord.updateDateTime;
    case 'ReportInitiative':
      return reportItem.initiative.currentInitiativeStatus?.auditRecord
        .updateDateTime;
    case 'ReportAction':
      return reportItem.action.currentStatus?.auditRecord.updateDateTime;
  }
};

const getIsCompleted = (reportItem: ReportPreviewItem) => {
  switch (reportItem.__typename) {
    case 'ReportObjective':
      return reportItem.objective.currentObjectiveStatus?.complete;
    case 'ReportMetric':
      return reportItem.metric.currentMetricStatus?.complete;
    case 'ReportInitiative':
      return reportItem.initiative.currentInitiativeStatus?.complete;
    case 'ReportAction':
      return reportItem.action.currentStatus?.complete;
  }
};
