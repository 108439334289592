import type { DropdownIndicatorProps } from 'react-select';
import { components } from 'react-select';
import { css, useTheme } from '@emotion/react';

import { ReactComponent as Triangle } from './static/triangle.svg';

type SelectDropdownIndicatorProps = DropdownIndicatorProps;

const SelectDropdownIndicator = ({
  isFocused,
  className,
  ...restProps
}: SelectDropdownIndicatorProps) => {
  const theme = useTheme();

  return (
    <components.DropdownIndicator
      isFocused={isFocused}
      {...restProps}
      className={className}
      css={css`
        padding: 1rem !important;
      `}
    >
      <Triangle
        css={css`
          color: ${theme.color.black};
          transform: matrix(1, 0, 0, -1, 0, 0);

          ${isFocused &&
          css`
            transform: none;
          `}
        `}
      />
    </components.DropdownIndicator>
  );
};

export default SelectDropdownIndicator;
