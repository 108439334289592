import type { ReactNode } from 'react';

import ContributionSection from 'contribution/ContributionSection';
import type { ContextMenuItem } from 'shared/components/ContextMenu';
import type {
  ContributionStrategySectionActionFragment,
  ContributionStrategySectionInitiativeFragment,
  ContributionStrategySectionMetricFragment,
  ContributionStrategySectionObjectiveFragment,
} from 'contribution/ContributionStrategySection/ContributionStrategySection.graphql.generated';
import { getObjectives } from 'contribution/contribution.utils';

export type StrategyOperationalItemRowContextMenuItem = ContextMenuItem<string>;

export type Contribution =
  | ContributionStrategySectionObjectiveFragment
  | ContributionStrategySectionMetricFragment
  | ContributionStrategySectionInitiativeFragment
  | ContributionStrategySectionActionFragment;

type ContributionTeamListProps = {
  contributions: Contribution[];
  expanded?: boolean;
  heading: ReactNode;
};

const ContributionTeamList = ({
  heading,
  expanded,
  contributions,
}: ContributionTeamListProps) => {
  if (contributions.length === 0) return null;

  const objectives = getObjectives(
    contributions,
  ) as ContributionStrategySectionObjectiveFragment[];

  const orphanContributions = getOrphanContributions(contributions);

  return (
    <ContributionSection.InnerContainer>
      <ContributionSection.CollapsibleList
        title={heading}
        childrenCount={contributions.length}
        expanded={expanded}
      >
        {objectives.map((objective) => (
          <>
            <ContributionSection.ListRow
              key={objective.id}
              strategyElement={objective}
              showSubtitle={true}
            />
            <ContributionSection.InnerList>
              {contributions
                .filter(
                  (contribution) => contribution.__typename !== 'Objective',
                )
                .filter(
                  (contribution) => contribution.objective?.id === objective.id,
                )
                .map((childContribution) => (
                  <ContributionSection.ListRow
                    key={childContribution.id}
                    strategyElement={childContribution}
                    showSubtitle={false}
                  />
                ))}
            </ContributionSection.InnerList>
          </>
        ))}
        {orphanContributions.map((strategyElement) => (
          <ContributionSection.ListRow
            key={strategyElement.id}
            strategyElement={strategyElement}
            showSubtitle={true}
          />
        ))}
      </ContributionSection.CollapsibleList>
    </ContributionSection.InnerContainer>
  );
};

export default ContributionTeamList;

const getOrphanContributions = (contributions: Contribution[]) => {
  const objectiveIds = contributions
    .filter((contribution) => contribution.__typename === 'Objective')
    .map((objective) => objective.id);

  return contributions
    .filter((contribution) => contribution.__typename !== 'Objective')
    .filter(
      (contribution) =>
        !contribution.objective ||
        !objectiveIds.includes(contribution.objective.id),
    );
};
