import type { FormikConfig, FormikErrors, FormikValues } from 'formik';
import { Form as FormikForm, Formik } from 'formik';
import { useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';

import type { AutoFocusProps } from 'shared/form/AutoFocus';
import AutoFocus from 'shared/form/AutoFocus';

import { useFormRefs } from './FormRefsProvider';
import { createFormUtils } from './Form.utils';
import FormikEffect from './FormikEffect';
import ScrollToError from './ScrollToError';
import type { FormChildren, OnSubmitFn } from './Form.type';

export type FormProps<Values extends FormikValues> = Omit<
  FormikConfig<Values>,
  'children' | 'onSubmit'
> & {
  autoFocus?: Omit<AutoFocusProps, 'children' | 'className'> | false;
  children?: FormChildren<Values>;
  className?: string;
  id?: string;
  isScrollingToError?: boolean;
  onChange?: (values: Values) => void;
  onError?: (errors: FormikErrors<Values>) => void;
  onSubmit?: OnSubmitFn<Values>;
};

const Form = <Values extends FormikValues>({
  id,
  children,
  onChange,
  onError,
  onSubmit,
  autoFocus,
  className,
  isScrollingToError = true,
  ...restProps
}: FormProps<Values>) => {
  const formRef = useRef<any>();

  const { addFormRef, deleteFormRef } = useFormRefs();

  const handleSubmit = useCallback<FormikConfig<Values>['onSubmit']>(
    (...args) => {
      onSubmit?.(...args, structuredClone(window.submitOptions) || {});
      delete window.submitOptions;
    },
    [onSubmit],
  );

  useEffect(() => {
    if (id) {
      addFormRef(id, formRef);

      return () => deleteFormRef(id);
    }
    // References to addFormRef and deleteFormRef functions have no importance
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Formik
      validateOnBlur={true}
      validateOnChange={true}
      {...restProps}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {(formikProps) => {
        const formUtils = createFormUtils<Values>(formikProps);

        const childrenProps = { ...formUtils, ...formikProps };

        return (
          <>
            <AutoFocus {...autoFocus}>
              <FormikForm id={id} className={cn(className)}>
                {typeof children === 'function'
                  ? children(childrenProps)
                  : children}
              </FormikForm>
            </AutoFocus>

            <FormikEffect onChange={onChange} onError={onError} />

            {isScrollingToError && <ScrollToError />}
          </>
        );
      }}
    </Formik>
  );
};

export default Form;
