import styled from '@emotion/styled';

import AiLoadingIcon from './AiLoadingIcon';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  color: ${(props) => props.theme.legacyColor.colorAiPurple};
  background-color: ${(props) => props.theme.color.backgroundDark};
  border-radius: 8px;
`;

const AiLoadingButton = () => (
  <Container>
    <AiLoadingIcon />
  </Container>
);

export default AiLoadingButton;
