import { useDefinedContext } from 'shared/utils/context.utils';

import ObjectiveActionsProvider from './ObjectiveActionsProvider';
import { ActionsContext } from './ObjectiveActionsProvider.context';

export const useObjectiveActions = () =>
  useDefinedContext(ActionsContext, {
    hookName: useObjectiveActions.name,
    providerName: ObjectiveActionsProvider.name,
  });
