import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

import Space from 'shared/components/Space';

const Container = styled(Space)`
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
`;

type CompactModalControlsProps = PropsWithChildren<{
  className?: string;
}>;

const CompactModalControls = ({
  children,
  className,
}: CompactModalControlsProps) => (
  <Container className={className}>{children}</Container>
);

export default CompactModalControls;
