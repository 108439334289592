import type { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import type { Org } from 'types.graphql.generated';

export class ActingOrg {
  private static actingOrgKeyHeader = 'X-ACTING_ORG_KEY';
  private static actingOrgKeyStorageKey = 'actingOrgKey';

  static setActingOrg(orgKey: Org['orgKey']): void {
    window.sessionStorage.setItem(this.actingOrgKeyStorageKey, orgKey);
  }

  static removeActingOrg(): void {
    window.sessionStorage.removeItem(this.actingOrgKeyStorageKey);
  }

  static getActingOrgLink(): ApolloLink {
    return setContext((_, previousContext) => ({
      ...previousContext,
      headers: {
        ...previousContext.headers,
        ...this.getHeaders(),
      },
    }));
  }

  static getHeaders(): Record<string, string> {
    const actingOrgKey = this.getActingOrgKey();

    if (actingOrgKey) {
      return {
        [this.actingOrgKeyHeader]: actingOrgKey,
      };
    } else {
      return {};
    }
  }

  static getActingOrgKey(): Maybe<Org['orgKey']> {
    return window.sessionStorage.getItem(this.actingOrgKeyStorageKey);
  }
}
