import { useContext } from 'react';

import { StepsContext } from './Steps.context';

export const useSteps = () => {
  const stepsContext = useContext(StepsContext);
  if (!stepsContext) {
    throw new Error(`${useSteps.name} should be used inside step`);
  }
  return stepsContext;
};
