import { createContext } from 'react';

import type { TeamTeamHierarchyOrgUnitFragment } from './TeamTeamHierarchyProvider.graphql.generated';

export type TeamTeamHierarchyProviderContextValue = {
  orgUnits: TeamTeamHierarchyOrgUnitFragment[];
};

export const TeamTeamHierarchyProviderContext =
  createContext<Maybe<TeamTeamHierarchyProviderContextValue>>(undefined);
