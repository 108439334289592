import { useDefinedContext } from 'shared/utils/context.utils';

import { OrgCardContext } from './OrgCardProvider.context';
import OrgCardProvider from './OrgCardProvider';

export const useOrgCard = () =>
  useDefinedContext(OrgCardContext, {
    hookName: useOrgCard.name,
    providerName: OrgCardProvider.name,
  });
