import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import StrategyOperationalItemRow from 'strategy/StrategyOperationalItemRow';
import type { Contribution } from 'contribution/ContributionTeamList/ContributionTeamList';
import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import { ReactComponent as SendIcon } from 'shared/static/icons/icon-send.svg';
import { getOnAction } from 'contribution/ContributionStrategySection/ContributionStrategySectionProvider/ContributionStrategySectionProvider.utils';
import useSendStatusRequest from 'status/useSendStatusRequest';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import { useActiveOrg } from 'org/ActiveOrgProvider';

import {
  getStatusIndicator,
  getSubTitleItem,
  getTitleItem,
} from './ContributionSectionListRow.utils';

export type Props = {
  showSubtitle: boolean;
  strategyElement: Contribution;
};

const ContributionSectionListRow = ({
  strategyElement,
  showSubtitle,
}: Props) => {
  const { activeOrg } = useActiveOrg();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendStatusRequest } = useSendStatusRequest();

  const onAction = useMemo(
    () =>
      getOnAction(
        navigate,
        sendStatusRequest,
        activeOrg,
      )(strategyElement, strategyElement.objective),
    [activeOrg, navigate, sendStatusRequest, strategyElement],
  );

  const statusIndicator = useMemo(
    () => getStatusIndicator(strategyElement),
    [strategyElement],
  );

  const title = useMemo(
    () => getTitleItem(activeOrg, strategyElement),
    [activeOrg, strategyElement],
  );

  const subTitle = useMemo(
    () =>
      showSubtitle ? getSubTitleItem(activeOrg, strategyElement) : undefined,
    [activeOrg, showSubtitle, strategyElement],
  );

  const canRequestStatus = !strategyElement.isCurrentUserOwner;
  const canWrite = canPerformStrategyElementAction(strategyElement, 'WRITE');

  const contextMenu = {
    items: [
      canRequestStatus && {
        id: 'requestStatus',
        icon: SendIcon,
        title: t('statusRequest.action'),
      },
      canWrite && {
        id: 'shareProgress',
        icon: PlusIcon,
        title: t('contribution.buttons.shareProgress'),
      },
      canWrite && {
        id: 'edit',
        icon: EditIcon,
        title: t('edit'),
      },
    ].filter(Boolean),
    onAction,
    disabledKeys: [],
  };

  return (
    <StrategyOperationalItemRow
      title={title}
      subTitle={subTitle}
      user={strategyElement.owner}
      typename={strategyElement.__typename}
      date={strategyElement.timeLine.endDate}
      dateTooltip={t('timeline.endDate')}
      statusIndicator={statusIndicator}
      contextMenu={contextMenu}
      expandable={false}
    />
  );
};

export default ContributionSectionListRow;
