import { Field, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';

import FieldBox from 'shared/form/FieldBox';
import { useForm } from 'shared/form/Form';

import { TextAreaField } from '../TextArea';
import Text from '../Text';

export type AttributeFieldValue = {
  description: string;
  id?: string;
  name: string;
  title: string;
};

type FormWithAttributesValues<Name extends string> = Record<
  Name,
  AttributeFieldValue[]
>;

type AttributesFieldsProps<Name extends string> = {
  className?: string;
  name: Name;
};

const AttributesFields = <Name extends string>({
  name,
  className,
}: AttributesFieldsProps<Name>) => {
  const { t } = useTranslation();
  const { values } = useForm<FormWithAttributesValues<Name>>();

  const renderAttributeFields = (attributeIndex: number) => {
    const getFieldName = (key: string) => `attributes.${attributeIndex}.${key}`;
    const getFieldValue = (key: keyof AttributeFieldValue) =>
      values[name][attributeIndex][key];

    return (
      <>
        <FieldBox
          name={getFieldName('description')}
          label={getFieldValue('title')}
          hasMargin={false}
          renderBelow={() => (
            <Text size={'small'}>{getFieldValue('name')}</Text>
          )}
        >
          <Field
            name={getFieldName('description')}
            maxLength={1000}
            component={TextAreaField}
            placeholder={t('attributes.form.placeholder')}
          />
        </FieldBox>
      </>
    );
  };

  return (
    <div className={className}>
      <FieldArray name={name}>
        {() =>
          values[name].map((_attribute, attributeIndex) => (
            <section key={attributeIndex}>
              {renderAttributeFields(attributeIndex)}
            </section>
          ))
        }
      </FieldArray>
    </div>
  );
};

export default AttributesFields;
