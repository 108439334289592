import { useDefinedContext } from 'shared/utils/context.utils';

import { ObjectiveMetricsContext } from './ObjectiveMetricsProvider.context';
import ObjectiveMetricsProvider from './ObjectiveMetricsProvider';

export const useObjectiveMetrics = () =>
  useDefinedContext(ObjectiveMetricsContext, {
    hookName: useObjectiveMetrics.name,
    providerName: ObjectiveMetricsProvider.name,
  });
