import { Trans } from 'react-i18next';
import type { MouseEventHandler } from 'react';
import { useMemo } from 'react';

import StatusMark from 'shared/status/StatusMark';
import DropdownButton from 'shared/components/DropdownButton';
import { date } from 'shared/services/date.service';
import type { MetricOverviewMetricMetricStatus } from 'metric/MetricOverview/MetricOverview.type';
import type { InitiativeOverviewInitiativeStatus } from 'initiative/InitiativeOverview/InitiativeOverview.type';
import { shortenEmail } from 'shared/utils/string.utils';
import AuditRecordUserAvatar from 'user/AuditRecordUserAvatar/AuditRecordUserAvatar';

import type { ObjectiveInsightsStatus } from '../ObjectiveInsights.type';
import styles from './ObjectiveInsightsActivityTimelineRowHeader.module.scss';

type ObjectiveInsightsActivityTimelineRowProps = {
  isRowExpanded: boolean;
  onClick: () => void;
  status:
    | ObjectiveInsightsStatus
    | MetricOverviewMetricMetricStatus
    | InitiativeOverviewInitiativeStatus;
};

const ObjectiveInsightsActivityTimelineRow = ({
  status,
  isRowExpanded,
  onClick,
}: ObjectiveInsightsActivityTimelineRowProps) => {
  const statusDateTime = useMemo(
    () =>
      status.statusDateTime
        ? date.format(status.statusDateTime, 'dd MMM yyyy')
        : '',
    [status.statusDateTime],
  );

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    onClick();
  };

  return (
    <div className={styles.header} onClick={handleClick}>
      <AuditRecordUserAvatar
        auditRecordUser={status.auditRecord.createBy}
        size={'small'}
      />
      <div className={styles.shortText}>
        <Trans
          i18nKey={'objective.insights.progressShared'}
          values={{
            user:
              status.auditRecord.createBy?.displayName ??
              shortenEmail(status.auditRecord.createBy?.email, 50),
            date: statusDateTime,
          }}
          components={{
            emphasis: <span className={styles.emphasis} />,
          }}
        />
      </div>

      <div className={styles.statusIndicator}>
        <StatusMark
          hasBorder={false}
          statusIndicator={status.statusIndicator}
        />
        <span className={styles.statusIndicatorName}>
          {status.statusIndicator.getName()}
        </span>
      </div>
      <DropdownButton
        className={styles.dropdownButton}
        isActive={isRowExpanded}
      />
    </div>
  );
};

export default ObjectiveInsightsActivityTimelineRow;
