import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import {
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  isWithinInterval,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns';

import useSearchParamState from 'shared/hooks/useSearchParamState';
import useDateSearchParamState from 'shared/hooks/useDateSearchParamState';

import type {
  CalendarCheck,
  CalendarControlsValue,
  CalendarInterval,
} from './CalendarControls.context';
import CalendarControlsContext from './CalendarControls.context';

const startByCalendarInterval = {
  year: startOfYear,
  quarter: startOfQuarter,
  month: startOfMonth,
  week: startOfWeek,
};

const endByCalendarInterval = {
  year: endOfYear,
  quarter: endOfQuarter,
  month: endOfMonth,
  week: endOfWeek,
};

const addByCalendarInterval = {
  year: addYears,
  quarter: addQuarters,
  month: addMonths,
  week: addWeeks,
};

const subByCalendarInterval = {
  year: subYears,
  quarter: subQuarters,
  month: subMonths,
  week: subWeeks,
};

type Props = PropsWithChildren<{
  initialCalendarInterval?: CalendarInterval;
  storeToSession: boolean;
}>;

const CalendarControlsProvider = ({
  initialCalendarInterval = 'year',
  storeToSession,
  children,
}: Props) => {
  const [calendarInterval, setCalendarInterval] =
    useSearchParamState<CalendarInterval>(
      'calendarInterval',
      initialCalendarInterval,
      storeToSession,
    );

  const [calendarCheck, setCalendarCheck] = useSearchParamState<
    CalendarCheck | undefined
  >('calendarCheck', 'overlap', storeToSession);

  const [calendarDate, setCalendarDate] = useDateSearchParamState(
    'calendarDate',
    new Date(),
    storeToSession,
  );

  const onCalendarIntervalChange = useCallback(
    (calendarInterval: CalendarInterval, calendarCheck?: CalendarCheck) => {
      setCalendarInterval(calendarInterval);
      setCalendarCheck(calendarCheck);

      if (calendarInterval !== 'all')
        setCalendarDate(
          startByCalendarInterval[calendarInterval](calendarDate),
        );
    },
    [calendarDate, setCalendarCheck, setCalendarDate, setCalendarInterval],
  );

  const value = useMemo<CalendarControlsValue>(
    () =>
      calendarInterval === 'all'
        ? {
            calendarDate,
            calendarInterval,
            onCalendarIntervalChange,
            isCurrentDate: true,
          }
        : {
            calendarDate,
            calendarInterval,
            calendarCheck,
            calendarIntervalStartDate:
              startByCalendarInterval[calendarInterval](calendarDate),
            calendarIntervalEndDate:
              endByCalendarInterval[calendarInterval](calendarDate),
            isCurrentDate: isWithinInterval(new Date(), {
              start: startByCalendarInterval[calendarInterval](calendarDate),
              end: endByCalendarInterval[calendarInterval](calendarDate),
            }),
            onCalendarIntervalChange,
            onCurrentDate: () => setCalendarDate(new Date()),
            onNextDate: () =>
              setCalendarDate(
                addByCalendarInterval[calendarInterval](calendarDate, 1),
              ),
            onPreviousDate: () =>
              setCalendarDate(
                subByCalendarInterval[calendarInterval](calendarDate, 1),
              ),
          },
    [
      calendarCheck,
      calendarDate,
      calendarInterval,
      onCalendarIntervalChange,
      setCalendarDate,
    ],
  );

  return (
    <CalendarControlsContext.Provider value={value}>
      {children}
    </CalendarControlsContext.Provider>
  );
};

export default CalendarControlsProvider;
