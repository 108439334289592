import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Layout from 'shared/components/Layout';
import { useTeamAdapter } from 'team/TeamAdapter';
import { usePageTitle } from 'shared/hooks/usePageTitle';

import { getBreadcrumbItems } from './ContributionMyFollowUpsViewMore.utils';
import Content from './Content';

const ContributionMyFollowUpsViewMore = () => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();

  usePageTitle(t('followUps.myFollowUps'));

  const breadCrumbItems = useMemo(
    () => getBreadcrumbItems(teamAdapter),
    [teamAdapter],
  );

  return (
    <>
      <Layout.Header
        heading={t('followUps.followUps')}
        breadcrumbsItems={breadCrumbItems}
      />
      <Layout.Content>
        <Content />
      </Layout.Content>
    </>
  );
};

export default ContributionMyFollowUpsViewMore;
