import type { StatusIndicator as StatusIndicatorEnum } from 'types.graphql.generated';

import { StatusIndicator } from './StatusIndicator';

export const getStatusIndicatorEnumValues = (): StatusIndicatorEnum[] => [
  'AT_RISK',
  'BLOCKED',
  'ON_TRACK',
];

export const isStatusIndicatorEnum = (
  value: unknown,
): value is StatusIndicatorEnum =>
  typeof value === 'string' && getStatusIndicatorEnumValues().includes(value);

export const getAllStatusIndicators = (): StatusIndicator[] => {
  const statusIndicatorsFromEnum = getStatusIndicatorEnumValues().map(
    (value) => new StatusIndicator(value),
  );
  const additionalStatusIndicators = [
    new StatusIndicator(undefined, {
      isCompleted: true,
    }),
  ];
  return [...statusIndicatorsFromEnum, ...additionalStatusIndicators];
};
