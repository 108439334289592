import { useDefinedContext } from 'shared/utils/context.utils';

import CalendarControlsContext from './CompletedFilter.context';
import CompletedFilterProvider from './CompletedFilterProvider';

export const useCompletedFilter = () =>
  useDefinedContext(CalendarControlsContext, {
    hookName: useCompletedFilter.name,
    providerName: CompletedFilterProvider.name,
  });
