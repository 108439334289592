import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { css } from '@emotion/react';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useTeamAdapter } from 'team/TeamAdapter';
import Layout from 'shared/components/Layout/Layout';
import OrgUnitCard from 'orgUnit/OrgUnitCard';
import OrgCard from 'org/OrgCard';

import { getBreadcrumbsItems } from './TeamMembersLayout.utils';

type TeamMembersLayoutProps = PropsWithChildren<object>;

const TeamMembersLayout = ({ children }: TeamMembersLayoutProps) => {
  const { activeOrg } = useActiveOrg();
  const { teamAdapter } = useTeamAdapter();
  const { orgUnit } = useOrgUnit();

  const breadcrumbsItems = useMemo(
    () =>
      getBreadcrumbsItems({
        teamAdapter,
        orgUnit,
        activeOrg,
      }),
    [activeOrg, orgUnit, teamAdapter],
  );

  return (
    <>
      <Layout.Header
        aboveHeading={
          orgUnit ? (
            <OrgUnitCard orgKey={activeOrg.orgKey} orgUnitId={orgUnit.id} />
          ) : (
            <OrgCard />
          )
        }
        breadcrumbsItems={breadcrumbsItems}
      />
      <Layout.Content
        hasPadding={false}
        css={css`
          margin-top: -22px;
        `}
      >
        {children}
      </Layout.Content>
    </>
  );
};

export default TeamMembersLayout;
