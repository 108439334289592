import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StrategyProfilePrioritiesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type StrategyProfilePrioritiesQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, currentStrategyProfile?: { __typename: 'StrategyProfile', id: string, priorities?: Array<{ __typename: 'Priority', number: number, label: string }> | undefined } | undefined } };

export type PriorityFragment = { __typename: 'Priority', number: number, label: string };

export const PriorityFragmentDoc = gql`
    fragment priority on Priority {
  number
  label
}
    `;
export const StrategyProfilePrioritiesDocument = gql`
    query StrategyProfilePriorities {
  activeOrg {
    id
    currentStrategyProfile {
      id
      priorities {
        ...priority
      }
    }
  }
}
    ${PriorityFragmentDoc}`;

/**
 * __useStrategyProfilePrioritiesQuery__
 *
 * To run a query within a React component, call `useStrategyProfilePrioritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStrategyProfilePrioritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStrategyProfilePrioritiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStrategyProfilePrioritiesQuery(baseOptions?: Apollo.QueryHookOptions<StrategyProfilePrioritiesQuery, StrategyProfilePrioritiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StrategyProfilePrioritiesQuery, StrategyProfilePrioritiesQueryVariables>(StrategyProfilePrioritiesDocument, options);
      }
export function useStrategyProfilePrioritiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StrategyProfilePrioritiesQuery, StrategyProfilePrioritiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StrategyProfilePrioritiesQuery, StrategyProfilePrioritiesQueryVariables>(StrategyProfilePrioritiesDocument, options);
        }
export type StrategyProfilePrioritiesQueryHookResult = ReturnType<typeof useStrategyProfilePrioritiesQuery>;
export type StrategyProfilePrioritiesLazyQueryHookResult = ReturnType<typeof useStrategyProfilePrioritiesLazyQuery>;
export type StrategyProfilePrioritiesQueryResult = Apollo.QueryResult<StrategyProfilePrioritiesQuery, StrategyProfilePrioritiesQueryVariables>;