import { setContext } from '@apollo/client/link/context';
import pThrottle from 'p-throttle';
import ms from 'ms';

import { withBearerToken } from 'shared/utils/apollo.utils';
import {
  getTokenExpirationDate,
  getTokenFromBearerHeader,
} from 'shared/utils/jwt.utils';
import { date } from 'shared/services/date.service';
import { getIdToken } from 'auth/auth.utils';

const throttledGetIdToken = pThrottle({
  limit: 1,
  interval: ms('0.5 seconds'),
})<Parameters<typeof getIdToken>, ReturnType<typeof getIdToken>>(getIdToken);

export const idTokenLink = setContext(async (_, context) => {
  const bearerHeader = context?.headers?.authorization;
  if (bearerHeader) {
    const idToken = getTokenFromBearerHeader(bearerHeader);
    const expirationDate = getTokenExpirationDate(idToken);
    const now = new Date();
    const secondsToExpire = date.getDifference(expirationDate, now, 'second');
    if (secondsToExpire < 60) {
      const idToken = await throttledGetIdToken(true);
      if (idToken) {
        return withBearerToken(context, idToken);
      }
      return context;
    }
  }
  return context;
});
