import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useValidateLoggedInUser } from 'user/useValidateLoggedInUser';
import { isAuthError } from 'auth/auth.utils';
import { useToasts } from 'shared/toast/useToasts';
import GlobalSpinner from 'shared/spinner/GlobalSpinner';
import { useSignUpWithEmail } from 'auth/useSignUpWithEmail';
import { useHandleUnknownError } from 'shared/errors/unknown.error';
import { authRoutesPaths } from 'auth/auth.routing.paths';
import { useRedirectAfterSignUp } from 'auth/useRedirectAfterSignUp';
import { useUserValidation } from 'user/UserValidationProvider/useUserValidation';

const FinishSignUpWithEmail = () => {
  const handleUnknownError = useHandleUnknownError();

  const navigate = useNavigate();

  const { addToast } = useToasts();

  const { onInvalidated } = useUserValidation();
  const { email, signUpWithEmailLink } = useSignUpWithEmail();
  const validateLoggedInUser = useValidateLoggedInUser();
  const { redirectAfterSignup } = useRedirectAfterSignUp();

  useEffect(() => {
    if (!email) {
      navigate(authRoutesPaths.finishSignUpWithoutEmail(), {
        state: { returnTo: window.location.href },
      });
      return;
    }

    onInvalidated();

    signUpWithEmailLink()
      .then(({ accessToken }) => accessToken)
      .then(validateLoggedInUser)
      .then(redirectAfterSignup)
      .catch((error) => {
        navigate(authRoutesPaths.signUp());

        if (isAuthError(error)) {
          addToast({
            id: 'signInWithEmailLinkErrorToast',
            variant: 'error',
            children: error.message,
          });
        } else {
          handleUnknownError(error);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <GlobalSpinner />;
};

export default FinishSignUpWithEmail;
