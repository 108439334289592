import type { PropsWithChildren } from 'react';

import Form from 'shared/form/Form';
import { useTeamAdapter } from 'team/TeamAdapter';
import type { InitiativeInput, Milestone } from 'types.graphql.generated';
import { yup } from 'shared/services/yup.service';
import type { SelectObjectiveInputFieldOption } from 'objective/SelectObjectiveInput';
import { date } from 'shared/services/date.service';
import type { AttributeFieldValue } from 'shared/components/AttributesFields';
import type { PriorityFieldValue } from 'strategy/PrioritySelect/PrioritySelect';
import { hasObjective } from 'objective/SelectObjectiveInput/SelectObjectiveInput.schema';
import { timeLineValidations } from 'shared/utils/timeLine.utils';

export type InitiativeFormValues = {
  attributes: AttributeFieldValue[];
  description: string;
  id: string | null;
  milestones: Array<{
    comment: string;
    id?: Milestone['id'];
    name: string;
    timeLine: {
      endDate: Date | null;
    };
  }>;
  name: string;
  objective: SelectObjectiveInputFieldOption;
  owner: string;
  priority: PriorityFieldValue | null;
  timeLine: {
    endDate: Date | null;
    startDate: Date | null;
  };
};

type Props = PropsWithChildren<{
  initialValues: InitiativeFormValues;
  onSubmit: (input: InitiativeInput) => Promise<unknown>;
}>;

export const initiativeFormId = 'initiative';

const InitiativeForm = ({ initialValues, onSubmit, children }: Props) => {
  const { teamAdapter } = useTeamAdapter();

  const validationSchema = yup.object({
    name: yup.string().required(),
    owner: yup.string().email().required(),
    timeLine: timeLineValidations(),
    objective: yup.object().concat(hasObjective()),
    milestones: yup.array(
      yup.object({
        name: yup.string().required(),
        timeLine: yup.object({
          endDate: yup
            .date()
            .required()
            .nullable()
            .transform((curr, orig) => (orig === '' ? null : curr)),
        }),
      }),
    ),
  });

  const handleSubmit = (values: InitiativeFormValues) => {
    onSubmit({
      idToUpdate: values.id,
      orgKey: teamAdapter.orgKey,
      name: values.name,
      description: values.description,
      owner: {
        emailToSet: values.owner,
      },
      timeLine: {
        startDate: values.timeLine.startDate
          ? date.format(values.timeLine.startDate, 'yyyy-MM-dd')
          : null,
        endDate: values.timeLine.endDate
          ? date.format(values.timeLine.endDate, 'yyyy-MM-dd')
          : null,
      },
      objective: values.objective.value
        ? { idToSet: values.objective.value.id }
        : values.id
        ? { idToRemove: initialValues.objective.value?.id }
        : undefined,
      priority: values.priority,
      milestones: {
        add: values.milestones
          .filter((milestone) => !milestone.id)
          .map((milestone) => ({
            name: milestone.name,
            comment: milestone.comment,
            timeLine: {
              endDate: milestone.timeLine.endDate
                ? date.format(milestone.timeLine.endDate, 'yyyy-MM-dd')
                : null,
            },
          })),
        update: values.milestones
          .filter((milestone) => milestone.id)
          .map((milestone) => ({
            idToUpdate: milestone.id,
            name: milestone.name,
            comment: milestone.comment,
            timeLine: {
              endDate: milestone.timeLine.endDate
                ? date.format(milestone.timeLine.endDate, 'yyyy-MM-dd')
                : null,
            },
          })),
        idsToDelete: initialValues.milestones
          .map((initialMilestone) => initialMilestone.id)
          .filter(Boolean)
          .filter(
            (initialMilestoneId) =>
              !values.milestones
                .map((milestone) => milestone.id)
                .filter(Boolean)
                .includes(initialMilestoneId),
          ),
      },
      attributes: {
        ...(values.id
          ? {
              update: values.attributes.map((attribute) => ({
                idToUpdate: attribute.id,
                description: attribute.description,
              })),
            }
          : {
              add: values.attributes.map((attribute, orderNumber) => ({
                ...attribute,
                orderNumber,
              })),
            }),
      },
    });
  };

  return (
    <Form<InitiativeFormValues>
      id={initiativeFormId}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

export default InitiativeForm;
