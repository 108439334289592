import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActiveOrgQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ActiveOrgQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, orgKey: string, domainNames: Array<string>, displayName?: string | undefined, displayNameAbbreviated?: string | undefined, languageLocaleCode: string, toolsEnabled: Array<Types.Tool>, backgroundColorAsHex?: string | undefined, terminologyFileUrl?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgAuthorizedAction>, currentStrategyProfile?: { __typename: 'StrategyProfile', id: string, name?: string | undefined, themeCategories: Array<{ __typename: 'ThemeCategory', id: string, name?: string | undefined, orderNumber?: number | undefined }> } | undefined, currentStrategy?: { __typename: 'Strategy', id: string, name?: string | undefined } | undefined } };


export const ActiveOrgDocument = gql`
    query ActiveOrg {
  activeOrg {
    id
    orgKey
    domainNames
    displayName
    displayNameAbbreviated
    languageLocaleCode
    toolsEnabled
    backgroundColorAsHex
    terminologyFileUrl
    currentUserAuthorizedActions
    currentStrategyProfile {
      id
      name
      themeCategories {
        id
        name
        orderNumber
      }
    }
    currentStrategy {
      id
      name
    }
  }
}
    `;

/**
 * __useActiveOrgQuery__
 *
 * To run a query within a React component, call `useActiveOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveOrgQuery(baseOptions?: Apollo.QueryHookOptions<ActiveOrgQuery, ActiveOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveOrgQuery, ActiveOrgQueryVariables>(ActiveOrgDocument, options);
      }
export function useActiveOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveOrgQuery, ActiveOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveOrgQuery, ActiveOrgQueryVariables>(ActiveOrgDocument, options);
        }
export type ActiveOrgQueryHookResult = ReturnType<typeof useActiveOrgQuery>;
export type ActiveOrgLazyQueryHookResult = ReturnType<typeof useActiveOrgLazyQuery>;
export type ActiveOrgQueryResult = Apollo.QueryResult<ActiveOrgQuery, ActiveOrgQueryVariables>;