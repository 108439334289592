import type { TFunction } from 'react-i18next';

import type { LinkTabsProps } from 'shared/components/LinkTabs';
import type { TeamAdapter } from 'team/TeamAdapter';
import { toolkitRoutesPaths } from 'toolkit/toolkit.routing.paths';

export const getLinkTabsItems = (
  teamAdapter: TeamAdapter,
  t: TFunction,
): LinkTabsProps['items'] => [
  {
    name: t('toolkit.categories.strategyDevelopment.shortName'),
    to: toolkitRoutesPaths.category({
      params: {
        teamSlug: teamAdapter.toParam(),
        category: 'strategyDevelopment',
      },
    }),
  },
  {
    name: t('toolkit.categories.strategyEngagement.shortName'),
    to: toolkitRoutesPaths.category({
      params: {
        teamSlug: teamAdapter.toParam(),
        category: 'strategyEngagement',
      },
    }),
  },
  {
    name: t('toolkit.categories.strategyInsights.shortName'),
    to: toolkitRoutesPaths.category({
      params: {
        teamSlug: teamAdapter.toParam(),
        category: 'strategyInsights',
      },
    }),
  },
];
