import type { PropsWithChildren } from 'react';

import DescriptionsItem from './DescriptionsItem';

type DescriptionsProps = PropsWithChildren<{
  className?: string;
}>;

const Descriptions = ({ children, className }: DescriptionsProps) => (
  <div className={className}>{children}</div>
);

Descriptions.Item = DescriptionsItem;

export default Descriptions;
