import { useToggle } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { Trans } from 'react-i18next';
import { Fragment, useMemo } from 'react';
import cn from 'classnames';
import styled from '@emotion/styled';

import { date } from 'shared/services/date.service';
import { expandChildAnimation } from 'shared/animations/expandChild.animation';
import InsightsComment from 'shared/components/InsightsComment';
import InsightsCommentSection from 'shared/components/InsightsCommentSection/InsightsCommentSection';
import ObjectiveStatusContextMenu from 'objective/ObjectiveStatusContextMenu';
import type { ObjeciveForStatusContextMenu } from 'objective/ObjectiveStatusContextMenu/ObjectiveStatusContextMenu';

import styles from './ObjectiveInsightsActivityTimelineRow.module.scss';
import type { ObjectiveInsightsStatus } from '../ObjectiveInsights.type';
import ObjectiveInsightsActivityTimelineRowHeader from '../ObjectiveInsightsActivityTimelineRowHeader';

const ContextMenuContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

type ObjectiveInsightsActivityTimelineRowProps = {
  isLastRow?: boolean;
  isRowExpanded?: boolean;
  objective: ObjeciveForStatusContextMenu;
  status: ObjectiveInsightsStatus;
};

const ObjectiveInsightsActivityTimelineRow = ({
  objective,
  status,
  isRowExpanded: isRowExpandedProp = false,
  isLastRow = false,
}: ObjectiveInsightsActivityTimelineRowProps) => {
  const [isRowExpanded, toggleIsRowExpanded] = useToggle(isRowExpandedProp);

  const updateDateTime = useMemo(
    () =>
      status.auditRecord.updateDateTime
        ? date.format(status.auditRecord.updateDateTime, 'dd MMM yyyy')
        : '',
    [status.auditRecord.updateDateTime],
  );

  const hasComments = !!(
    status.actionComments.length ||
    status.challengeComments.length ||
    status.successComments.length
  );

  return (
    <motion.li
      key={status.id}
      className={cn(styles.item, { [styles.lastRowItem]: isLastRow })}
      {...expandChildAnimation}
    >
      <AnimatePresence initial={false}>
        <Fragment key={status.id + status.statusIndicator.value}>
          <ObjectiveInsightsActivityTimelineRowHeader
            status={status}
            isRowExpanded={isRowExpanded}
            onClick={toggleIsRowExpanded}
          />
          {isRowExpanded ? (
            <motion.div {...expandChildAnimation} className={styles.container}>
              <ContextMenuContainer>
                <ObjectiveStatusContextMenu
                  objective={objective}
                  status={status}
                />
              </ContextMenuContainer>
              <InsightsComment comment={status.comment} />
              {hasComments ? (
                <>
                  <InsightsCommentSection
                    context={'successes'}
                    comments={status.successComments}
                  />
                  <InsightsCommentSection
                    context={'challenges'}
                    comments={status.challengeComments}
                  />
                  <InsightsCommentSection
                    context={'actions'}
                    comments={status.actionComments}
                  />
                </>
              ) : null}
              <div className={styles.updateDate}>
                <Trans
                  i18nKey={'objective.insights.recentInsights.updated'}
                  values={{
                    date: updateDateTime,
                  }}
                />
              </div>
            </motion.div>
          ) : null}
        </Fragment>
      </AnimatePresence>
    </motion.li>
  );
};

export default ObjectiveInsightsActivityTimelineRow;
