import { useDefinedContext } from 'shared/utils/context.utils';

import CalendarControlsContext from './CalendarControls.context';
import CalendarControlsProvider from './CalendarControlsProvider';

export const useCalendarControls = () =>
  useDefinedContext(CalendarControlsContext, {
    hookName: useCalendarControls.name,
    providerName: CalendarControlsProvider.name,
  });
