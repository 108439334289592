import { useTranslation } from 'react-i18next';
import { css, useTheme } from '@emotion/react';
import type { CellProps } from 'react-table';
import { useNavigate } from 'react-router-dom';

import { date } from 'shared/services/date.service';
import TableView from 'shared/components/TableView';
import type { CampaignStage } from 'types.graphql.generated';
import UserAvatar from 'user/UserAvatar';
import Text from 'shared/components/Text';
import { toolkitRoutesPaths } from 'toolkit/toolkit.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';
import CampaignStageTag from 'toolkit/CampaignStageTag/CampaignStageTag';

import CampaignContextMenu from './CampaignContextMenu';
import type { OneThingCampaignsQuery } from './OneThingCampaigns.graphql.generated';

type Campaign =
  OneThingCampaignsQuery['allOneThingCampaigns']['edges'][0]['node'];

type Props = {
  campaigns: Campaign[];
};

type CampaignRow = {
  completion: {
    completionRate?: number;
    stage?: CampaignStage;
  };
  endDate?: Date;
  identification: {
    id: string;
    name?: string;
    orgUnit?: Campaign['orgUnit'];
  };
  menu: {
    id: string;
    stage: CampaignStage;
  };
  owner: Campaign['owner'];
  stage?: CampaignStage;
  startDate: Date;
  tasks: {
    count: number;
    stage?: CampaignStage;
  };
};

const CampaignsTable = ({ campaigns }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { teamAdapter } = useTeamAdapter();
  const navigate = useNavigate();

  const data: CampaignRow[] = campaigns.map((campaign) => ({
    menu: {
      id: campaign.id,
      stage: campaign.state.stage,
    },
    identification: {
      id: campaign.id,
      name: campaign.name,
      orgUnit: campaign.orgUnit,
    },
    startDate: campaign.timeLine.startDate,
    endDate: campaign.timeLine.endDate,
    stage: campaign.state.stage,
    owner: campaign.owner,
    completion: {
      completionRate: campaign.completionRate.percentDone,
      stage: campaign.state.stage,
    },
    tasks: {
      count: campaign.tasks.length,
      stage: campaign.state.stage,
    },
  }));

  return (
    <div css={css({ flexGrow: 1 })}>
      <TableView<CampaignRow>
        hasBorder={false}
        data={data}
        columns={[
          {
            Header: t('toolkit.toolPage.oneThing.campaigns.table.name'),
            accessor: 'identification',
            Cell: (table: CellProps<CampaignRow>) => (
              <div
                css={css({ display: 'flex', gap: '8px', cursor: 'pointer' })}
                onClick={() =>
                  navigate(
                    toolkitRoutesPaths.oneThing.campaigns.results({
                      params: {
                        teamSlug: teamAdapter.toParam(),
                        campaignId: table.value.id,
                      },
                    }),
                  )
                }
              >
                <Text css={css({ color: theme.color.black })}>
                  {table.value.name}
                </Text>
                <Text css={css({ color: theme.color.neutral2 })}>
                  {table.value.orgUnit?.name && '>'}
                </Text>
                <Text css={css({ color: theme.color.neutral2 })}>
                  {table.value.orgUnit?.name}
                </Text>
              </div>
            ),
            width: '100%',
          },
          {
            Header: t('toolkit.toolPage.oneThing.campaigns.table.tasks'),
            accessor: 'tasks',
            Cell: (table: CellProps<CampaignRow>) => (
              <Text
                color={table.value.stage === 'ACTIVE' ? 'primary' : 'regular'}
              >
                {table.value.count}
              </Text>
            ),
          },
          {
            Header: t('toolkit.toolPage.oneThing.campaigns.table.completion'),
            accessor: 'completion',
            Cell: (table: CellProps<CampaignRow>) => (
              <Text
                color={table.value.stage === 'ACTIVE' ? 'primary' : 'regular'}
              >
                {Math.round(table.value.completionRate)}
                {'%'}
              </Text>
            ),
          },
          {
            Header: t('toolkit.toolPage.oneThing.campaigns.table.start'),
            accessor: 'startDate',
            Cell: (table: CellProps<CampaignRow>) => (
              <>{table.value && date.formatShort(table.value)}</>
            ),
          },
          {
            Header: t('toolkit.toolPage.oneThing.campaigns.table.end'),
            accessor: 'endDate',
            Cell: (table: CellProps<CampaignRow>) => (
              <>{table.value && date.formatShort(table.value)}</>
            ),
          },
          {
            Header: t('toolkit.toolPage.oneThing.campaigns.table.status'),
            accessor: 'stage',
            Cell: (table: CellProps<CampaignRow>) => (
              <CampaignStageTag stage={table.value} />
            ),
          },
          {
            accessor: 'owner',
            Cell: (table: CellProps<CampaignRow>) => (
              <>{table.value && <UserAvatar user={table.value} />}</>
            ),
          },
          {
            accessor: 'menu',
            Cell: (table: CellProps<CampaignRow>) => (
              <CampaignContextMenu
                campaignId={table.value.id}
                campaignStage={table.value.stage}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default CampaignsTable;
