import { useTranslation } from 'react-i18next';

import type { ConfirmationModalProps } from 'shared/components/__DEPRECATED__/ConfirmationModal';
import ConfirmationModal from 'shared/components/__DEPRECATED__/ConfirmationModal';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { useToasts } from 'shared/toast/useToasts';

import { useDeactivateUserMutation } from './DeactivateUser.graphql.generated';

type RemoveOrgUnitMemberConfirmationModalProps = Pick<
  ConfirmationModalProps,
  'isOpen' | 'onClose'
> & { userId: string };

const DeactivateUserDialog = ({
  isOpen,
  onClose,
  userId,
}: RemoveOrgUnitMemberConfirmationModalProps) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const handleApolloError = useHandleApolloError();

  const [deactivateUser] = useDeactivateUserMutation({
    variables: { userId },
    onError: handleApolloError,
  });

  const onConfirm = async () => {
    const result = await deactivateUser();

    if (result.errors) {
      addToast({ variant: 'error', children: t('userMenu.deactivate.error') });
    } else {
      addToast({
        variant: 'success',
        children: t('userMenu.deactivate.success'),
      });
    }
  };

  return (
    <ConfirmationModal isOpen={isOpen} onClose={onClose} onConfirm={onConfirm}>
      {t('userMenu.deactivate.cofirmation')}
    </ConfirmationModal>
  );
};

export default DeactivateUserDialog;
