import { StatusIndicator } from 'shared/status/StatusIndicator';

import type {
  ObjectiveInsightsObjective,
  ObjectiveInsightsStatus,
} from '../ObjectiveInsights.type';

export const getObjectiveStatusListWithObjectiveCreationDate = (
  objective: ObjectiveInsightsObjective,
  objectiveStatusList: ObjectiveInsightsStatus[],
) => [
  ...objectiveStatusList,
  castObjectiveCreationDateToObjectiveStatus(objective),
];

const castObjectiveCreationDateToObjectiveStatus = (
  objective: Pick<ObjectiveInsightsObjective, 'id' | 'auditRecord'>,
): ObjectiveInsightsStatus => ({
  id: objective!.id,
  context: 'objectiveCreated',
  complete: false,
  statusIndicator: new StatusIndicator(),
  statusDateTime: objective!.auditRecord.createDateTime, // the magic happens here, we assign an object creation date to status date
  auditRecord: objective!.auditRecord,
  actionComments: [],
  challengeComments: [],
  successComments: [],
});
