import { useTranslation } from 'react-i18next';

import type { ReportStage } from 'types.graphql.generated';
import type { TagProps } from 'shared/components/__DEPRECATED__/Tag';
import Tag from 'shared/components/__DEPRECATED__/Tag';

type ReportStageTagProps = {
  className?: string;
  reportStage?: ReportStage | null;
  variant?: TagProps['variant'];
};

const ReportStageTag = ({
  reportStage,
  variant: variantProp,
  className,
}: ReportStageTagProps) => {
  const { t } = useTranslation();

  // extract logic translating ReportStage enum into separate class
  const text = reportStage
    ? {
        ['CANCELLED']: t('report.reportStage.cancelled'),
        ['DRAFT']: t('report.reportStage.draft'),
        ['PUBLISHED']: t('report.reportStage.published'),
        ['STATUS_REQUESTED']: t('report.reportStage.statusRequested'),
      }[reportStage]
    : null;

  const variant = reportStage === 'PUBLISHED' ? 'primary' : 'regular';

  return (
    <Tag variant={variantProp || variant} className={className}>
      {text}
    </Tag>
  );
};

export default ReportStageTag;
