import { createContext } from 'react';

import type { UserEmailInputItem } from '../UserEmailInput.type';

export type UserEmailInputProviderContextValue = {
  items: UserEmailInputItem[];
};

export const UserEmailInputProviderContext =
  createContext<UserEmailInputProviderContextValue>({
    items: [],
  });
