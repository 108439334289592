import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InitiativeSuggestionsSubscriptionVariables = Types.Exact<{
  objectiveName: Types.Scalars['String'];
  numberOfSuggestions: Types.Scalars['Int'];
  languageLocaleCode: Types.Scalars['String'];
}>;


export type InitiativeSuggestionsSubscription = { __typename: 'Subscription', suggestions: Array<{ __typename: 'AiSuggestionElement', typeAsText: string, fields: Array<{ __typename: 'KeyValue', key: string, value: string }> }> };


export const InitiativeSuggestionsDocument = gql`
    subscription InitiativeSuggestions($objectiveName: String!, $numberOfSuggestions: Int!, $languageLocaleCode: String!) {
  suggestions: aiSuggestionStream(
    input: {languageLocaleCode: $languageLocaleCode, elements: [{typeAsText: "Objective", fields: [{key: "name", value: $objectiveName}]}], response: {typeAsText: "Initiative", fields: ["name", "description"]}, numberOfSuggestions: $numberOfSuggestions}
  ) {
    typeAsText
    fields {
      key
      value
    }
  }
}
    `;

/**
 * __useInitiativeSuggestionsSubscription__
 *
 * To run a query within a React component, call `useInitiativeSuggestionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInitiativeSuggestionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitiativeSuggestionsSubscription({
 *   variables: {
 *      objectiveName: // value for 'objectiveName'
 *      numberOfSuggestions: // value for 'numberOfSuggestions'
 *      languageLocaleCode: // value for 'languageLocaleCode'
 *   },
 * });
 */
export function useInitiativeSuggestionsSubscription(baseOptions: Apollo.SubscriptionHookOptions<InitiativeSuggestionsSubscription, InitiativeSuggestionsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<InitiativeSuggestionsSubscription, InitiativeSuggestionsSubscriptionVariables>(InitiativeSuggestionsDocument, options);
      }
export type InitiativeSuggestionsSubscriptionHookResult = ReturnType<typeof useInitiativeSuggestionsSubscription>;
export type InitiativeSuggestionsSubscriptionResult = Apollo.SubscriptionResult<InitiativeSuggestionsSubscription>;