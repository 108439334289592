import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateOrgUnitMutationVariables = Types.Exact<{
  input: Types.OrgUnitInput;
}>;


export type CreateOrgUnitMutation = { __typename: 'Mutation', addOrgUnit: { __typename: 'OrgUnit', id: string, name?: string | undefined } };


export const CreateOrgUnitDocument = gql`
    mutation CreateOrgUnit($input: OrgUnitInput!) {
  addOrgUnit(input: $input) {
    id
    name
  }
}
    `;
export type CreateOrgUnitMutationFn = Apollo.MutationFunction<CreateOrgUnitMutation, CreateOrgUnitMutationVariables>;

/**
 * __useCreateOrgUnitMutation__
 *
 * To run a mutation, you first call `useCreateOrgUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgUnitMutation, { data, loading, error }] = useCreateOrgUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgUnitMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrgUnitMutation, CreateOrgUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrgUnitMutation, CreateOrgUnitMutationVariables>(CreateOrgUnitDocument, options);
      }
export type CreateOrgUnitMutationHookResult = ReturnType<typeof useCreateOrgUnitMutation>;
export type CreateOrgUnitMutationResult = Apollo.MutationResult<CreateOrgUnitMutation>;
export type CreateOrgUnitMutationOptions = Apollo.BaseMutationOptions<CreateOrgUnitMutation, CreateOrgUnitMutationVariables>;