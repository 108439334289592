import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import type { ContextMenuOnActionFn } from 'shared/components/ContextMenu';
import ContextMenu from 'shared/components/ContextMenu';
import { useObjective } from 'objective/ObjectiveProvider';
import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';
import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';
import { ReactComponent as CheckmarkDoneIcon } from 'shared/static/icons/icon-checkmark-done.svg';
import { ReactComponent as RefreshIcon } from 'shared/static/icons/icon-refresh.svg';
import { ReactComponent as SendIcon } from 'shared/static/icons/icon-send.svg';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';

import type {
  ObjectiveLayoutContextMenuItem,
  ObjectiveLayoutContextMenuItemId,
} from './ObjectiveLayoutContextMenu.type';

export type ObjectiveLayoutContextMenuProps = {
  onAction: ContextMenuOnActionFn<ObjectiveLayoutContextMenuItem['id']>;
};

const ObjectiveLayoutContextMenu = ({
  onAction,
}: ObjectiveLayoutContextMenuProps) => {
  const { t } = useTranslation();

  const { objective } = useObjective();

  const canRequestStatus = !objective.isCurrentUserOwner;
  const canWrite = canPerformStrategyElementAction(objective, 'WRITE');
  const canReactivate = canWrite && objective.currentObjectiveStatus?.complete;
  const canComplete = canWrite && !objective.currentObjectiveStatus?.complete;

  const items = useMemo(
    (): ObjectiveLayoutContextMenuItem[] =>
      [
        canWrite && {
          id: 'shareStatus' as ObjectiveLayoutContextMenuItemId,
          title: t('objective.insights.actions.addButton'),
          icon: PlusIcon,
        },
        canRequestStatus && {
          id: 'requestStatus' as ObjectiveLayoutContextMenuItemId,
          title: t('statusRequest.action'),
          icon: SendIcon,
        },
        canReactivate && {
          id: 'reactivate' as ObjectiveLayoutContextMenuItemId,
          title: t('objective.objectiveLayout.header.menu.reactivateButton'),
          icon: RefreshIcon,
        },
        canComplete && {
          id: 'complete' as ObjectiveLayoutContextMenuItemId,
          title: t('objective.objectiveLayout.header.menu.completeButton'),
          icon: CheckmarkDoneIcon,
        },
        canWrite && {
          id: 'edit' as ObjectiveLayoutContextMenuItemId,
          title: t('objective.objectiveLayout.header.menu.editButton'),
          icon: EditIcon,
        },
        canWrite && {
          id: 'delete' as ObjectiveLayoutContextMenuItemId,
          title: t('objective.objectiveLayout.header.menu.removeButton'),
          icon: TrashIcon,
        },
      ].filter(Boolean),
    [canComplete, canReactivate, canRequestStatus, canWrite, t],
  );

  return (
    <ContextMenu<ObjectiveLayoutContextMenuItem>
      items={items}
      onAction={onAction}
      testId={'objective'}
    />
  );
};

export default ObjectiveLayoutContextMenu;
