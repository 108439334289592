import { motion } from 'framer-motion';
import { memo, useMemo } from 'react';
import cn from 'classnames';

import { useMotionNumberValue } from 'shared/hooks/useMotionNumberValue';

import styles from './StatText.module.scss';

type StatTextProps = {
  label: Maybe<string>;
  value: Maybe<number>;
};

const StatText = ({ label, value }: StatTextProps) => {
  const { containerProps, textValue, isNumber } = useMotionNumberValue(value);

  const { isDoubleDigit, isTripleDigit } = useMemo(
    () => ({
      isDoubleDigit: textValue.length === 2,
      isTripleDigit: textValue.length === 3,
    }),
    [textValue],
  );

  return (
    <div className={styles.container}>
      <motion.div className={styles.label}>{`${label}: `}</motion.div>
      <motion.strong
        className={cn(styles.value, {
          [styles.valueSingleDigit]: !isDoubleDigit && !isTripleDigit,
          [styles.valueDoubleDigit]: isDoubleDigit,
          [styles.valueTripleDigit]: isTripleDigit,
        })}
        {...containerProps}
      >
        {textValue}
      </motion.strong>
      {isNumber && <span>%</span>}
    </div>
  );
};

export default memo(StatText);
