import { useTranslation } from 'react-i18next';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

import Space from 'shared/components/Space';
import ContentPlaceholder from 'shared/components/ContentPlaceholder';
import OrgCard from 'org/OrgCard';
import OrgUnitCard from 'orgUnit/OrgUnitCard';
import { useViewport } from 'shared/hooks/useViewport';

import { useMyTeams } from '../MyTeamsProvider';
import styles from './MyTeamsHeader.module.scss';

const MyTeamsHeader = () => {
  const { t } = useTranslation();

  const { isTabletOrBigger } = useViewport();

  const { org, primaryOrgUnit } = useMyTeams();

  const orgCard = useMemo<ReactNode>(() => {
    if (org) {
      return (
        <OrgCard
          withLabel={true}
          withMenu={false}
          className={styles.teamCard}
        />
      );
    }
    return null;
  }, [org]);

  const orgUnitCard = useMemo<ReactNode>(() => {
    if (org && primaryOrgUnit) {
      return (
        <OrgUnitCard
          orgKey={org.orgKey}
          orgUnitId={primaryOrgUnit.id}
          withLabel={true}
          withMenu={false}
          className={styles.teamCard}
        />
      );
    }
    return (
      <ContentPlaceholder className={styles.teamCard}>
        {t('team.myTeams.primaryTeamPlaceholder')}
      </ContentPlaceholder>
    );
  }, [org, primaryOrgUnit, t]);

  return (
    <>
      <Space direction={isTabletOrBigger ? 'horizontal' : 'vertical'}>
        {orgCard}
        {orgUnitCard}
      </Space>
    </>
  );
};

export default MyTeamsHeader;
