import { useTranslation } from 'react-i18next';

import ContributionInfoContainer from 'contribution/ContributionInfoContainer/ContributionInfoContainer';
import { useUser } from 'user/UserProvider';

type ContributionMyFollowUpsViewMoreNoFollowUpsProps = {
  hasCompletedItems: boolean;
};

const NoFollowUps = ({
  hasCompletedItems,
}: ContributionMyFollowUpsViewMoreNoFollowUpsProps) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const emptyStateDescription = !hasCompletedItems
    ? t('contribution.myFollowUps.empty.noItems')
    : user.displayName
    ? t('contribution.myFollowUps.empty.completed.emptyDescription', {
        user: user.displayName,
      })
    : t('contribution.myFollowUps.empty.completed.emptyDescriptionNoUserName');

  return (
    <ContributionInfoContainer icon={'🎉'}>
      {emptyStateDescription}
    </ContributionInfoContainer>
  );
};

export default NoFollowUps;
