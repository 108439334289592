import { createContext } from 'react';

export type CalendarInterval = 'all' | 'year' | 'quarter' | 'month' | 'week';
export type CalendarCheck = 'include' | 'overlap';

export type CalendarControlsValue = {
  calendarCheck?: CalendarCheck;
  calendarDate: Date;
  calendarInterval: CalendarInterval;
  calendarIntervalEndDate?: Date;
  calendarIntervalStartDate?: Date;
  isCurrentDate: boolean;
  onCalendarIntervalChange: (
    calendarInterval: CalendarInterval,
    check?: CalendarCheck,
  ) => void;
  onCurrentDate?: () => void;
  onNextDate?: () => void;
  onPreviousDate?: () => void;
};

const CalendarControlsContext = createContext<
  CalendarControlsValue | undefined
>(undefined);

export default CalendarControlsContext;
