import { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { uniq } from 'lodash';

import { yup } from 'shared/services/yup.service';
import UserEmailInput from 'user/UserEmailInput';
import Button from 'shared/components/Button';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';

import Flex from '../Flex';
import Chip from '../Chip';

type Value = string[];

type Props = {
  onChange: (value: Value) => void;
  value: Value;
};

const MultiUserInput = ({ value: values, onChange }: Props) => {
  const [inputValue, setInputValue] = useState('');

  const onAdd = useCallback(() => {
    onChange(uniq([...values, inputValue]));
    setInputValue('');
  }, [inputValue, onChange, values]);

  const onRemove = useCallback(
    (email: string) => {
      onChange(values.filter((eachEmail) => eachEmail !== email));
    },
    [onChange, values],
  );

  const isInputValueValid = yup
    .string()
    .email()
    .required()
    .isValidSync(inputValue);

  return (
    <Flex direction={'column'} gap={8}>
      <Flex alignItems={'center'} gap={8}>
        <div css={css({ flexGrow: '1', width: '100%' })}>
          <UserEmailInput value={inputValue} onChange={setInputValue} />
        </div>
        <Button
          icon={PlusIcon}
          variant={'icon'}
          color={'mono'}
          onClick={onAdd}
          disabled={!isInputValueValid}
        />
      </Flex>
      <Flex gap={8} wrap={'wrap'}>
        {values.map((email) => (
          <Chip key={email} onRemove={() => onRemove(email)}>
            {email}
          </Chip>
        ))}
      </Flex>
    </Flex>
  );
};

export default MultiUserInput;
