import type { TFunction } from 'react-i18next';

import type {
  ActionStatusMultiSelectItemId,
  ActionStatusMultiSelectActionStatus,
} from './ActionStatusMultiSelect.type';

export const getActionMultiSelectIds = (): ActionStatusMultiSelectItemId[] => [
  'ALL',
  'INACTIVE',
  'ACTIVE',
];

export const isActionMultiSelectId = (
  value: unknown,
): value is ActionStatusMultiSelectItemId => {
  if (typeof value === 'string') {
    return getActionMultiSelectIds().includes(value);
  }
  return false;
};

export const testActionStatusAgainstId = (
  id: ActionStatusMultiSelectItemId,
  actionStatus?: Maybe<ActionStatusMultiSelectActionStatus>,
) => {
  switch (id) {
    case 'ALL': {
      return !actionStatus?.complete;
    }
    case 'ACTIVE': {
      return !!(
        !actionStatus?.complete &&
        actionStatus?.completionRate &&
        actionStatus?.completionRate > 0
      );
    }
    case 'INACTIVE': {
      return !!(
        !actionStatus?.completionRate || actionStatus.completionRate === 0
      );
    }
    case 'DONE': {
      return !!actionStatus?.complete;
    }
  }
};

export const getActionMultiSelectItemLabel = (
  id: ActionStatusMultiSelectItemId,
  t: TFunction,
) => t(`actions.actionStatusMultiSelect.labels.${id}`);
