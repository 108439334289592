import type { PropsWithChildren } from 'react';

import type { TeamAdapter } from 'team/TeamAdapter';

import SelectThemeInputForOrgProvider from '../SelectThemeInputForOrgProvider';
import SelectThemeInputForOrgUnitProvider from '../SelectThemeInputForOrgUnitProvider';

type SelectThemeInputProviderProps = PropsWithChildren<{
  teamAdapter: TeamAdapter;
}>;

const SelectThemeInputProvider = ({
  children,
  teamAdapter,
}: SelectThemeInputProviderProps) => {
  if (teamAdapter.isOrgUnit) {
    return (
      <SelectThemeInputForOrgUnitProvider orgUnitId={teamAdapter.keyArg}>
        {children}
      </SelectThemeInputForOrgUnitProvider>
    );
  }
  return (
    <SelectThemeInputForOrgProvider>{children}</SelectThemeInputForOrgProvider>
  );
};

export default SelectThemeInputProvider;
