import type { PropsWithChildren } from 'react';
import { useMemo, useState } from 'react';

import type { Submissions } from './SubmissionContext';
import SubmissionContext from './SubmissionContext';

const SubmissionProvider = ({ children }: PropsWithChildren) => {
  const [submissions, setSubmissions] = useState<Submissions>({});

  const value = useMemo(() => ({ submissions, setSubmissions }), [submissions]);

  return (
    <SubmissionContext.Provider value={value}>
      {children}
    </SubmissionContext.Provider>
  );
};

export default SubmissionProvider;
