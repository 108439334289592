import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import Layout from 'shared/components/Layout';

import styles from './ContributionLayout.module.scss';

const ContributionLayout = () => {
  const { t } = useTranslation();

  return (
    <>
      <Layout.Header heading={t('contribution.myContribution.heading')} />
      <Layout.Content hasPadding={false} className={styles.layout}>
        <Outlet />
      </Layout.Content>
    </>
  );
};

export default ContributionLayout;
