import { useTeamAdapter } from 'team/TeamAdapter';
import TeamsTreeList from 'team/TeamsTreeList';
import { useCampaignResults } from 'toolkit/WalkOfStrategyCampaignResultsLayout/CampaignResultsProvider';

import EngagementBar from './EngagementBar';

const EngagementSection = () => {
  const { campaign } = useCampaignResults();
  const { teamAdapter } = useTeamAdapter();

  return (
    <TeamsTreeList
      orgUnits={campaign.targetOrgUnitTree}
      orgKey={teamAdapter.orgKey}
      allExpanded={false}
      selectedItemsIds={[teamAdapter.keyArg]}
      renderRowEnd={(orgUnit) => <EngagementBar orgUnitId={orgUnit.id} />}
    />
  );
};

export default EngagementSection;
