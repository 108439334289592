import { Trans, useTranslation } from 'react-i18next';

import type { ModalProps } from 'shared/components/__DEPRECATED__/Modal';
import Modal from 'shared/components/__DEPRECATED__/Modal';
import Text from 'shared/components/Text';
import { useUser } from 'user/UserProvider';

import styles from './InsightReportSummaryExportReportModal.module.scss';

type InsightReportSummaryExportReportModalProps = Pick<
  ModalProps,
  'isOpen' | 'onClose'
> & {
  isError: boolean;
  isLoading: boolean;
  reportName?: string;
};

const InsightReportSummaryExportReportModal = ({
  isOpen,
  onClose,
  isError,
  isLoading,
  reportName = '',
}: InsightReportSummaryExportReportModalProps) => {
  const { t } = useTranslation();

  const { user } = useUser();

  const renderContent = () => {
    if (isLoading) {
      return (
        <Text variant={'emphasis'}>
          {t('report.insightReportSummary.exportReportModal.loadingText')}
        </Text>
      );
    } else if (isError) {
      return (
        <Text variant={'emphasis'}>
          {t('report.insightReportSummary.exportReportModal.errorText')}
        </Text>
      );
    }
    return (
      <Text variant={'emphasis'}>
        <Trans
          i18nKey={'report.insightReportSummary.exportReportModal.successText'}
          values={{
            name: reportName,
            email: user.email,
            format: 'pptx',
          }}
        >
          <Text variant={'strong'} />
          <Text variant={'strong'} color={'primary'} />
        </Trans>
      </Text>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      heading={t('report.insightReportSummary.exportReportModal.heading')}
      className={styles.modal}
    >
      {renderContent()}
    </Modal>
  );
};

export default InsightReportSummaryExportReportModal;
