import type { Scalars } from 'types.graphql.generated';

import type { ThemeFragment } from '../../themes/ThemesProvider/ThemesProvider.graphql.generated';

export const getThemesByCategory = (themes: ThemeFragment[]) => {
  const base = themes.reduce<
    Record<
      Scalars['ID'],
      {
        name?: string;
        themes: ThemeFragment[];
      }
    >
  >(
    (accumulator, theme) => {
      const { themeCategory } = theme;
      if (themeCategory) {
        if (accumulator[themeCategory.id]) {
          accumulator[themeCategory.id].themes.push(theme);
        } else {
          accumulator[themeCategory.id] = {
            name: themeCategory.name,
            themes: [theme],
          };
        }
      } else {
        accumulator.noCategory.themes.push(theme);
      }
      return accumulator;
    },
    {
      noCategory: {
        themes: [],
      },
    },
  );
  return Object.entries(base)
    .map(([key, value]) => ({
      ...(key !== 'noCategory' && {
        category: {
          id: key,
          name: value.name,
        },
      }),
      themes: value.themes,
    }))
    .filter((value) => value.themes?.length);
};
