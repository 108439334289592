import { createContext } from 'react';

import type { LayoutBackground } from '../Layout.type';

export type LayoutProviderContextValue = {
  background?: LayoutBackground;
  clearBackground: () => void;
  setBackground: (newBackground: LayoutBackground) => void;
};

export const LayoutProviderContext = createContext<
  LayoutProviderContextValue | undefined
>(undefined);
