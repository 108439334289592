import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { capitalize } from 'lodash';

type OrgTermAlsoKnownAsProps = {
  className?: string;
  i18nKey: string;
  isItalic?: boolean;
};

const OrgTermAlsoKnownAs = ({
  i18nKey,
  isItalic = true,
  className,
}: OrgTermAlsoKnownAsProps) => {
  const { i18n, t } = useTranslation();

  const isOrgSpecificTerm =
    i18n.modules.orgTermsPostProcessor.hasKeyInSource(i18nKey);
  const originalTerm = t(i18nKey, {
    postProcess: [],
  });
  const orgSpecificTerm = t(i18nKey);

  if (isOrgSpecificTerm) {
    return (
      <span
        css={
          isItalic
            ? css`
                font-style: italic;
              `
            : undefined
        }
        className={className}
      >
        {t('isAlsoKnownAs', {
          leftTerm: capitalize(orgSpecificTerm),
          rightTerm: originalTerm,
        })}
      </span>
    );
  }

  return null;
};

export default OrgTermAlsoKnownAs;
