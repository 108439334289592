import { useTranslation } from 'react-i18next';

import type { ContributionMyFollowUpsOneThingCampaignFragment } from 'contribution/ContributionMyFollowUpsViewMore/ContributionMyFollowUpsViewMore.graphql.generated';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import Flex from 'shared/components/Flex';
import Text from 'shared/components/Text';

type Props = {
  campaign: ContributionMyFollowUpsOneThingCampaignFragment;
};

const OneThingCampaignRequestNotice = ({ campaign }: Props) => {
  const { t } = useTranslation();

  const { activeOrg } = useActiveOrg();

  const response = campaign.currentUserTask;

  const team =
    campaign.orgUnit?.name || activeOrg.displayName || activeOrg.domainNames[0];

  const isCampaignClosed = campaign.state.stage === 'CLOSED';

  const isTaskCreated = !!response;
  const isTaskDone = isTaskCreated && response.status === 'DONE';
  const requester = campaign.owner.displayName || campaign.owner.email;

  const taskWithCompletion = isTaskCreated && (
    <Flex gap={'0.5rem'}>
      {response.task}
      <Text>({response.completionRate}%)</Text>
    </Flex>
  );

  const notice = isCampaignClosed
    ? isTaskCreated
      ? isTaskDone
        ? t(`toolkit.toolPage.oneThing.invite.DONE`)
        : t(`toolkit.toolPage.oneThing.invite.STARTED_CLOSED`, {
            completionRate: response.completionRate,
            task: response.task,
          })
      : t(`toolkit.toolPage.oneThing.invite.NOT_STARTED_CLOSED`, { team })
    : isTaskCreated
    ? isTaskDone
      ? t(`toolkit.toolPage.oneThing.invite.DONE`)
      : taskWithCompletion
    : t(`toolkit.toolPage.oneThing.invite.NOT_STARTED`, { requester });

  return <>{notice}</>;
};

export default OneThingCampaignRequestNotice;
