import { useDefinedContext } from 'shared/utils/context.utils';

import { MetricOverviewContext } from './MetricOverviewProvider.context';
import MetricOverviewProvider from './MetricOverviewProvider';

export const useMetricOverview = () =>
  useDefinedContext(MetricOverviewContext, {
    hookName: useMetricOverview.name,
    providerName: MetricOverviewProvider.name,
  });
