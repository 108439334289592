import { createRoutePath } from 'shared/utils/router';

export const initiativeOverviewRoutesPaths = {
  root: createRoutePath('/'),
  insights: {
    root: createRoutePath('insights'),
    submit: createRoutePath<never, { completeStatus?: string }>('submit'),
  },
  attributes: createRoutePath('attributes'),
  edit: createRoutePath('edit'),
};
