import { Navigate } from 'react-router-dom';

import { useTeamAdapter } from 'team/TeamAdapter';
import { toolkitRoutesPaths } from 'toolkit/toolkit.routing.paths';

const ToolkitOverviewRedirectToDefaultCategory = () => {
  const { teamAdapter } = useTeamAdapter();

  return (
    <Navigate
      to={toolkitRoutesPaths.category({
        params: {
          teamSlug: teamAdapter.toParam(),
          category: 'strategyDevelopment',
        },
      })}
      replace={true}
    />
  );
};

export default ToolkitOverviewRedirectToDefaultCategory;
