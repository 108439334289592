import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import ActivityTimeline from 'shared/components/ActivityTimeline';
import Heading from 'shared/components/Heading';
import Layout from 'shared/components/Layout';
import Text from 'shared/components/Text';
import { useCampaignResults } from 'toolkit/WalkOfStrategyCampaignResultsLayout/CampaignResultsProvider';

import TimelineEntry from './TimelineEntry';

const NoCommentsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px;
`;

const WalkOfStrategyCampaignResultsComments = () => {
  const { t } = useTranslation();
  const { campaign } = useCampaignResults();

  const comments = campaign.allUserResponses.flatMap(({ comments, user }) =>
    comments.map((comment) => ({
      id: comment.id,
      content: comment.text,
      commentDate: comment.auditRecord?.createDateTime || new Date(0),
      user,
    })),
  );

  return (
    <Layout.Content hasBorder={true} css={css(`padding-bottom: 0 !important`)}>
      <Heading level={4} hasMargin={false}>
        {t('comment.comment_other')}
      </Heading>
      {comments.length === 0 ? (
        <NoCommentsContainer>
          <Text>
            {t('toolkit.toolPage.walkOfStrategy.results.comments.empty')}
          </Text>
        </NoCommentsContainer>
      ) : (
        <ActivityTimeline
          itemDateKey={'commentDate'}
          items={comments}
          renderRowContent={(row) => <TimelineEntry comment={row.item} />}
        />
      )}
    </Layout.Content>
  );
};

export default WalkOfStrategyCampaignResultsComments;
