import { css } from '@emotion/react';

import FiltersButton from 'shared/components/FiltersButton';
import CalendarControls from 'shared/components/CalendarControls';
import Flex from 'shared/components/Flex';
import CompletedFilterButton from 'shared/components/CompletedFilterButton';

import useChipGroupItems from '../TeamInsightsOverviewFiltersChipGroup/useChipGroupItems';
import TeamInsightsCreateReportButton from '../TeamInsightsCreateReportButton';
import TeamInsightsOverviewFilters from '../TeamInsightsOverviewFilters';
import TeamInsightsOverviewSummaryButton from './TeamInsightsOverviewSummaryButton';
import { useTeamInsightsOverview } from '../TeamInsightsOverviewProvider';

const TeamInsightsOverviewControls = () => {
  const {
    filters: { clearFilters },
  } = useTeamInsightsOverview();
  const { length: selectionCount } = useChipGroupItems();

  return (
    <>
      <CalendarControls />

      <Flex gap={8} css={css({ flex: '1', justifyContent: 'flex-end' })}>
        <CompletedFilterButton />

        <FiltersButton
          clearFilters={clearFilters}
          selectionCount={selectionCount}
        >
          <TeamInsightsOverviewFilters />
        </FiltersButton>

        <TeamInsightsOverviewSummaryButton />
        <TeamInsightsCreateReportButton />
      </Flex>
    </>
  );
};

export default TeamInsightsOverviewControls;
