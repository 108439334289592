import type { PropsWithChildren } from 'react';
import { useListState } from 'react-stately';
import { useMemo } from 'react';

import type { MultiSelectProps } from 'shared/components/MultiSelect';
import { useListBoxCollectionChildren } from 'shared/components/ListBox';

import type { ThemeMultiSelectProviderContextValue } from './ThemeMultiSelectProvider.context';
import { ThemeMultiSelectProviderContext } from './ThemeMultiSelectProvider.context';
import type { ThemeMultiSelectItem } from '../ThemeMultiSelect.type';
import ThemeMultiSelectListBoxRow from '../ThemeMultiSelectListBoxRow';

export type ThemeMultiSelectProviderProps = PropsWithChildren<
  Pick<MultiSelectProps, 'onSelectionChange' | 'selectedKeys'> & {
    themes: ThemeMultiSelectItem[];
  }
>;

const ThemeMultiSelectProvider = ({
  children,
  themes,
  onSelectionChange,
  selectedKeys,
}: ThemeMultiSelectProviderProps) => {
  const collectionChildren = useListBoxCollectionChildren({
    Row: ThemeMultiSelectListBoxRow,
  });
  const listState = useListState<ThemeMultiSelectItem>({
    items: themes,
    selectionMode: 'multiple',
    children: collectionChildren,
    selectedKeys,
    onSelectionChange,
  });

  const contextValue = useMemo<ThemeMultiSelectProviderContextValue>(
    () => ({
      state: listState,
      items: themes,
    }),
    [themes, listState],
  );

  return (
    <ThemeMultiSelectProviderContext.Provider value={contextValue}>
      {children}
    </ThemeMultiSelectProviderContext.Provider>
  );
};

export default ThemeMultiSelectProvider;
