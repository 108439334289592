import { useDefinedContext } from 'shared/utils/context.utils';

import CalendarControlsContext from './ShowChildrenFilter.context';
import CompletedFilterProvider from './CompletedFilterProvider';

export const useShowChildrenFilter = () =>
  useDefinedContext(CalendarControlsContext, {
    hookName: useShowChildrenFilter.name,
    providerName: CompletedFilterProvider.name,
  });
