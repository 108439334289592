import { Navigate } from 'react-router-dom';
import { useMemo } from 'react';

import { initiativeOverviewRoutesPaths } from '../InitiativeOverview.routing.paths';

const InitiativeOverviewNavigateToInsights = () => {
  const to = useMemo(() => initiativeOverviewRoutesPaths.insights.root(), []);

  return <Navigate to={to} replace={true} />;
};

export default InitiativeOverviewNavigateToInsights;
