import type { ConfirmationModalProps } from 'shared/components/__DEPRECATED__/ConfirmationModal';
import ConfirmationModal from 'shared/components/__DEPRECATED__/ConfirmationModal';
import type { UseRemoveOrgUnitMemberParams } from 'orgUnit/useRemoveOrgUnitMember';
import { useRemoveOrgUnitMember } from 'orgUnit/useRemoveOrgUnitMember';

type RemoveOrgUnitMemberConfirmationModalProps = Pick<
  ConfirmationModalProps,
  'isOpen' | 'onClose'
> &
  UseRemoveOrgUnitMemberParams;

const RemoveOrgUnitMemberConfirmationModal = ({
  isOpen,
  onClose,
  orgUnitId,
  orgUnit,
  userId,
}: RemoveOrgUnitMemberConfirmationModalProps) => {
  const { removeOrgUnitMember, canRemoveOrgUnitMember } =
    useRemoveOrgUnitMember({ orgUnitId, orgUnit, userId });

  const handleConfirm = () => removeOrgUnitMember({ onSuccess: onClose });

  if (canRemoveOrgUnitMember) {
    return (
      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleConfirm}
      />
    );
  }

  return null;
};

export default RemoveOrgUnitMemberConfirmationModal;
