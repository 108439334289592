import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import Heading from 'shared/components/Heading';
import Button from 'shared/components/Button';
import MetricInsightsChart from 'metric/MetricInsightsChart';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import MetricInsightsChartChip from 'metric/MetricInsightsChart/MetricInsightsChartChip/MetricInsightsChartChip';
import Spinner from 'shared/spinner/Spinner';
import CreateMetricStatusDialog from 'metric/CreateMetricStatusDialog';
import useDialogState from 'shared/hooks/useDialogState';
import SendStatusRequestButton from 'status/SendStatusRequestButton';
import Flex from 'shared/components/Flex';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';

import { useMetricOverview } from '../MetricOverviewProvider';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
`;

const MetricOverviewHeader = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDialogState();

  const { metric } = useMetricOverview();

  if (!metric) return <Spinner.Circle />;

  const canAddStatus = canPerformStrategyElementAction(metric, 'WRITE');

  return (
    <>
      <Container>
        <Flex justifyContent={'space-between'} alignItems={'center'} gap={8}>
          <Heading level={3} style={{ margin: 0 }}>
            {t('metric.metricLayout.heading')}
          </Heading>

          <Flex gap={8}>
            <SendStatusRequestButton strategyElement={metric} />

            {canAddStatus && (
              <Button icon={PlusIcon} iconPosition={'start'} onClick={onOpen}>
                {t('metric.insights.actions.shareButton')}
              </Button>
            )}
          </Flex>
        </Flex>

        <Flex gap={20}>
          <MetricInsightsChartChip
            label={t('metric.timelineAndValues.startValue')}
            value={metric?.startValue}
          />
          <MetricInsightsChartChip
            label={t('metric.timelineAndValues.targetValue')}
            value={metric?.targetValue}
          />
        </Flex>

        <MetricInsightsChart metric={metric} hasControls={true} />
      </Container>

      <CreateMetricStatusDialog
        isOpen={isOpen}
        metric={metric}
        onClose={onClose}
      />
    </>
  );
};

export default MetricOverviewHeader;
