import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import type { Strategy } from 'types.graphql.generated';
import Spinner from 'shared/spinner/Spinner';
import type { TeamAdapter } from 'team/TeamAdapter';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { sortObjectivesByTheme } from 'objective/objective.utils';

import { getObjectivesByTheme } from './StrategyRoadmapProvider.utils';
import {
  useStrategyRoadmapOrgQuery,
  useStrategyRoadmapOrgUnitQuery,
} from './StrategyRoadmapProvider.graphql.generated';
import type { StrategyRoadmapProviderContextValue } from './StrategyRoadmapProvider.context';
import { StrategyRoadmapProviderContext } from './StrategyRoadmapProvider.context';

type StrategyRoadmapProviderProps = PropsWithChildren<{
  strategyId: Strategy['id'];
  teamAdapter: TeamAdapter;
}>;

const StrategyRoadmapProvider = ({
  children,
  teamAdapter,
  strategyId,
}: StrategyRoadmapProviderProps) => {
  const handleApolloError = useHandleApolloError();

  const activeQuery = teamAdapter.isOrgUnit
    ? useStrategyRoadmapOrgUnitQuery
    : useStrategyRoadmapOrgQuery;

  const { data: orgData, loading: orgLoading } = useStrategyRoadmapOrgQuery({
    variables: {
      strategyId,
    },
    fetchPolicy: 'cache-and-network',
    skip: activeQuery !== useStrategyRoadmapOrgQuery,
    onError: handleApolloError,
  });

  const { data: orgUnitData, loading: orgUnitLoading } =
    useStrategyRoadmapOrgUnitQuery({
      variables: {
        orgUnitId: teamAdapter.keyArg,
        strategyId,
      },
      fetchPolicy: 'cache-and-network',
      skip: activeQuery !== useStrategyRoadmapOrgUnitQuery,
      onError: handleApolloError,
    });

  const loading = orgLoading || orgUnitLoading;

  const contextValue = useMemo<
    Maybe<StrategyRoadmapProviderContextValue>
  >(() => {
    if (orgData && activeQuery === useStrategyRoadmapOrgQuery) {
      return {
        strategy: orgData.strategy,
        objectivesByTheme: sortObjectivesByTheme(
          getObjectivesByTheme(
            orgData.activeOrg.objectivesTopLevel,
            orgData.strategy.themes,
          ),
          [],
        ),
        oneThingCampaigns: orgData.activeOrg.activeOneThingCampaigns,
      };
    } else if (orgUnitData && activeQuery === useStrategyRoadmapOrgUnitQuery) {
      return {
        strategy: orgUnitData.strategy,
        objectivesByTheme: sortObjectivesByTheme(
          getObjectivesByTheme(
            orgUnitData.orgUnit.objectivesTopLevel,
            orgUnitData.strategy.themes,
          ),
          orgUnitData
            ? [
                ...orgUnitData.orgUnit.parentOrgUnitTree,
                orgUnitData.orgUnit,
              ].reverse()
            : [],
        ),
        oneThingCampaigns: orgUnitData.orgUnit.activeOneThingCampaigns,
      };
    }
    return undefined;
  }, [orgData, orgUnitData, activeQuery]);

  if (contextValue) {
    return (
      <StrategyRoadmapProviderContext.Provider value={contextValue}>
        {children}
      </StrategyRoadmapProviderContext.Provider>
    );
  }

  if (loading) {
    return <Spinner.Circle />;
  }

  return null;
};

export default StrategyRoadmapProvider;
