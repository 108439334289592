import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { css, useTheme } from '@emotion/react';

import Card from 'shared/components/Card';
import Text from 'shared/components/Text';
import { ReactComponent as EmptyIllustration } from 'shared/static/illustrations/illustration-empty-state.svg';
import Space from 'shared/components/Space';
import Result from 'shared/components/Result';
import Heading from 'shared/components/Heading/Heading';

import type { AttributesAttribute } from './Attributes.type';

export type AttributesProps = {
  attributes: Maybe<AttributesAttribute[]>;
  className?: string;
};

const Attributes = ({
  attributes: attributesProp,
  className,
}: AttributesProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const attributesWithDescriptions = useMemo(
    () => attributesProp?.filter((attribute) => attribute.description),
    [attributesProp],
  );

  const attributes = useMemo(() => {
    if (attributesWithDescriptions?.length) {
      const attributesByOrderNumber = [...attributesWithDescriptions].sort(
        (previousAttribute, nextAttribute) =>
          (previousAttribute?.orderNumber || 0) >
          (nextAttribute?.orderNumber || 0)
            ? 1
            : -1,
      );
      return (
        <>
          {attributesByOrderNumber.map((attribute) => (
            <Card
              key={attribute.id}
              padding={'none'}
              css={css`
                border-left: none;
                font-size: 1.063rem;
                padding: 20px 24px;
              `}
            >
              <Heading
                level={3}
                as={5}
                hasMargin={false}
                css={css`
                  font-size: 1.063rem;
                `}
              >
                {attribute.title}
              </Heading>
              <Heading
                level={5}
                isBold={false}
                hasMargin={false}
                css={css`
                  color: ${theme.color.typoSecondary};
                  font-size: 1rem;
                  margin-bottom: 12px;
                `}
              >
                {attribute.name}
              </Heading>
              <Text
                variant={'emphasis'}
                css={css`
                  white-space: pre-wrap;
                `}
              >
                {attribute.description}
              </Text>
            </Card>
          ))}
        </>
      );
    }
    return null;
  }, [attributesWithDescriptions, theme.color.typoSecondary]);

  if (attributesWithDescriptions?.length) {
    return <div className={className}>{attributes}</div>;
  }

  return (
    <Space isCentered={true}>
      <Result
        description={t('attributes.noAttributes')}
        illustration={EmptyIllustration}
        hasBackground={true}
      />
    </Space>
  );
};

export default Attributes;
