import { createContext } from 'react';

export type ScrollspyProviderContextValue = {
  activeWaypointId: string | undefined;
  setActiveWaypointId: (value: string) => void;
  setIsChangeBlocked: (value: boolean) => void;
};

export const ScrollspyProviderContext = createContext<
  ScrollspyProviderContextValue | undefined
>(undefined);
