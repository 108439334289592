import { motion } from 'framer-motion';
import cn from 'classnames';
import type { PropsWithChildren } from 'react';

import { fadeAnimation } from 'shared/animations/fade.animation';

import styles from './LayoutHeaderContainer.module.scss';

type LayoutHeaderContainerProps = PropsWithChildren<{
  className?: string;
}>;

const LayoutHeaderContainer = ({
  className,
  children,
}: LayoutHeaderContainerProps) => (
  <motion.header {...fadeAnimation} className={cn(styles.container, className)}>
    <div className={styles.innerContainer}>{children}</div>
  </motion.header>
);

export default LayoutHeaderContainer;
