import { useCallback, useEffect } from 'react';
import last from 'lodash/last';
import first from 'lodash/first';

import type {
  MultiSelectProps,
  RenderLabelFn,
} from 'shared/components/MultiSelect';
import MultiSelect from 'shared/components/MultiSelect';

import StatusMultiSelectLabel from './StatusMultiSelectLabel';
import StatusMultiSelectListBoxRow from './StatusMultiSelectListBoxRow';
import type {
  StatusMultiSelectItem,
  StatusMultiSelectItemId,
} from './StatusMultiSelect.type';
import StatusMultiSelectProvider, {
  useStatusMultiSelect,
} from './StatusMultiSelectProvider';
import StatusMultiSelectChipGroup from './StatusMultiSelectChipGroup';

export type StatusMultiSelectProps = Pick<MultiSelectProps, 'triggerClassName'>;

const StatusMultiSelect = ({ triggerClassName }: StatusMultiSelectProps) => {
  const { state, items } = useStatusMultiSelect();

  const renderLabel = useCallback<RenderLabelFn<StatusMultiSelectItem>>(
    () => <StatusMultiSelectLabel state={state} />,
    [state],
  );

  useEffect(() => {
    const selectedKeys = [
      ...state.selectionManager.selectedKeys,
    ] as StatusMultiSelectItemId[];
    if (selectedKeys.length > 1 && last(selectedKeys) === 'ALL') {
      state.selectionManager.replaceSelection('ALL');
    }
    if (selectedKeys.length > 1 && first(selectedKeys) === 'ALL') {
      state.selectionManager.toggleSelection('ALL');
    }
  }, [state.selectionManager]);

  return (
    <MultiSelect<StatusMultiSelectItem>
      state={state}
      items={items}
      ListBoxRow={StatusMultiSelectListBoxRow}
      label={renderLabel}
      triggerClassName={triggerClassName}
    />
  );
};

StatusMultiSelect.Provider = StatusMultiSelectProvider;
StatusMultiSelect.ChipGroup = StatusMultiSelectChipGroup;

export default StatusMultiSelect;
