type LabeledHeadingTextProps = {
  label: string;
  textContent: Maybe<string>;
};

const LabeledHeadingText = ({
  label,
  textContent,
}: LabeledHeadingTextProps) => (
  <span>
    <strong>{`${label}:`}</strong> {textContent}
  </span>
);

export default LabeledHeadingText;
