import type { StrategyWizardStepId } from 'strategy/StrategyWizard/StrategyWizard.type';
import type { InitialStep } from 'shared/components/Steps';

export const steps: Record<StrategyWizardStepId, InitialStep> = {
  name: {
    id: 'name',
    nameTranslationKey: 'strategy.strategyWizard.steps.name.name',
  },
  mission: {
    id: 'mission',
    nameTranslationKey: 'strategy.mission',
  },
  vision: {
    id: 'vision',
    nameTranslationKey: 'strategy.strategyWizard.steps.vision.name',
  },
  goals: {
    id: 'goals',
    nameTranslationKey: 'strategy.goal_other',
  },
  choices: {
    id: 'choices',
    nameTranslationKey: 'strategy.strategyWizard.steps.choices.name',
  },
  behaviors: {
    id: 'behaviors',
    nameTranslationKey: 'strategy.strategyWizard.steps.behaviors.name',
  },
  values: {
    id: 'values',
    nameTranslationKey: 'strategy.strategyWizard.steps.values.name',
  },
};
