import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import CreateObjectiveButton from 'objective/CreateObjectiveButton';

import ObjectiveRow from './ObjectiveRow';
import type {
  StrategyRoomManageObjectivesObjectiveFragment,
  StrategyRoomManageObjectivesThemeFragment,
} from './StrategyRoomThemesManageObjectives.graphql.generated';

const ObjectivesContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
`;

type Props = {
  objectives: StrategyRoomManageObjectivesObjectiveFragment[];
  theme?: StrategyRoomManageObjectivesThemeFragment;
};

const ThemeObjectives = ({ objectives, theme }: Props) => {
  const { t } = useTranslation();

  const themeObjectives = objectives.filter(
    (objective) => objective.theme?.id === theme?.id,
  );

  return (
    <ObjectivesContainer>
      {themeObjectives.map((objective) => (
        <ObjectiveRow key={objective.id} objective={objective} />
      ))}

      <CreateObjectiveButton
        variant={'outlined'}
        theme={theme}
        css={css({ alignSelf: 'flex-start' })}
        isHidden={!theme?.isActive}
      >
        {t('strategy.strategyRoom.themes.newObjective')}
      </CreateObjectiveButton>
    </ObjectivesContainer>
  );
};

export default ThemeObjectives;
