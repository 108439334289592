import { useCallback, useMemo, useState, type PropsWithChildren } from 'react';

import { UserValidationContext } from './UserValidation.context';

const UserValidationProvider = ({ children }: PropsWithChildren) => {
  const [isValidated, setIsValidated] = useState(true);
  const onValidated = useCallback(() => setIsValidated(true), []);
  const onInvalidated = useCallback(() => setIsValidated(false), []);

  const value = useMemo(
    () => ({ isValidated, onValidated, onInvalidated }),
    [isValidated, onInvalidated, onValidated],
  );

  return (
    <UserValidationContext.Provider value={value}>
      {children}
    </UserValidationContext.Provider>
  );
};

export default UserValidationProvider;
