import { useTranslation } from 'react-i18next';

import Tag from 'shared/components/Tag';
import type { TagSize } from 'shared/components/Tag/Tag';
import type { User } from 'types.graphql.generated';

type Props = {
  size?: TagSize;
  user: Pick<User, 'isActive'>;
};

const UserActiveTag = ({ user, size = 'tiny' }: Props) => {
  const { t } = useTranslation();

  if (user.isActive) return null;

  return <Tag size={size}>{t('user.inactive')}</Tag>;
};

export default UserActiveTag;
