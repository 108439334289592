import type { StatusIndicator } from 'shared/status';
import StatusMark from 'shared/status/StatusMark';

type Props = {
  strategyElement: {
    currentInitiativeStatus?: { statusIndicator: StatusIndicator };
    currentMetricStatus?: { statusIndicator: StatusIndicator };
    currentObjectiveStatus?: { statusIndicator: StatusIndicator };
    currentStatus?: { statusIndicator: StatusIndicator };
  };
};

const StrategyElementStatusMark = ({ strategyElement }: Props) => {
  const statusIndicator =
    strategyElement.currentObjectiveStatus?.statusIndicator ||
    strategyElement.currentMetricStatus?.statusIndicator ||
    strategyElement.currentInitiativeStatus?.statusIndicator ||
    strategyElement.currentStatus?.statusIndicator;

  return <StatusMark statusIndicator={statusIndicator} />;
};

export default StrategyElementStatusMark;
