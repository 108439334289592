import type { Node, ListState } from 'react-stately';
import { useRef } from 'react';
import { useFocusRing, useGridListItem, mergeProps } from 'react-aria';
import cn from 'classnames';

import type { BaseListViewItem } from '../ListView.type';
import styles from './ListViewRowContainer.module.scss';

type ListViewRowContainerProps<Item extends BaseListViewItem> = {
  hasTopBorder?: boolean;
  item: Node<Item>;
  state: ListState<Item>;
};

const ListViewRowContainer = <Item extends BaseListViewItem>({
  item,
  state,
  hasTopBorder = true,
}: ListViewRowContainerProps<Item>) => {
  const listItemElementRef = useRef<HTMLLIElement>(null);
  const { rowProps, gridCellProps } = useGridListItem(
    {
      node: item,
    },
    state,
    listItemElementRef,
  );

  const { focusProps } = useFocusRing();

  return (
    <li
      {...mergeProps(rowProps, focusProps)}
      ref={listItemElementRef}
      className={cn(styles.listViewRowContainer, {
        [styles.listViewRowContainerWithNoBorderTop]: !hasTopBorder,
      })}
    >
      <div {...gridCellProps} className={styles.listViewRowContainerContent}>
        {item.rendered}
      </div>
    </li>
  );
};

export default ListViewRowContainer;
