import { createContext } from 'react';

import type { ObjectiveDetailsObjective } from '../ObjectiveDetails.type';

export type ObjectiveDetailsContextValue = {
  isObjectiveLoading: boolean;
  objective: Maybe<ObjectiveDetailsObjective>;
};

export const ObjectiveDetailsContext = createContext<
  ObjectiveDetailsContextValue | undefined
>(undefined);
