import { AnimatePresence, motion } from 'framer-motion';
import type { PropsWithChildren } from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Caption from 'shared/components/Caption';
import type { TextItem } from 'types.graphql.generated';
import { directChildItemsContainerAnimation } from 'shared/components/TreeList/TreeListRow/TreeListRow.animation';
import Flex from 'shared/components/Flex';

import styles from './ObjectiveOverviewInsightsSection.module.scss';
import type { OverviewInsightComments } from './ObjectiveOverviewInsightsSections.type';

type ObjectiveOverviewInsightsSectionProps<
  TComment extends Pick<TextItem, 'id' | 'text'>,
> = PropsWithChildren<{
  comments: Maybe<TComment[]>;
  context: OverviewInsightComments;
}>;

const COMMENTS_LIMIT = 3;

const ObjectiveOverviewInsightsSection = <
  TComment extends Pick<TextItem, 'id' | 'text'>,
>({
  context,
  comments,
  children,
}: ObjectiveOverviewInsightsSectionProps<TComment>) => {
  const { t } = useTranslation();

  const [commentsLimit, setCommentsLimit] = useState(COMMENTS_LIMIT);

  const {
    hasComments,
    isMoreCommentsExpanded,
    isMoreCommentsToLoad,
    moreCommentsToLoadCount,
  } = useMemo(() => {
    const hasComments = comments?.find(({ text }) => !!text);
    const isMoreCommentsExpanded = commentsLimit > COMMENTS_LIMIT;
    const isMoreCommentsToLoad = comments && comments.length > COMMENTS_LIMIT;
    const moreCommentsToLoadCount =
      (comments?.length ?? commentsLimit) - commentsLimit;

    return {
      hasComments,
      isMoreCommentsExpanded,
      isMoreCommentsToLoad,
      moreCommentsToLoadCount,
    };
  }, [comments, commentsLimit]);

  const handleOnClick = () => {
    if (isMoreCommentsExpanded) {
      setCommentsLimit(COMMENTS_LIMIT);
    } else {
      setCommentsLimit(comments?.length ?? COMMENTS_LIMIT);
    }
  };

  return (
    <section className={styles.section}>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Caption size={'C2'} className={styles.caption}>
          {t(`objective.overview.insights.${context}.heading`)}
        </Caption>
        {children}
      </Flex>

      <div className={styles.listContainer}>
        {hasComments ? (
          <>
            <ul className={styles.list}>
              <AnimatePresence>
                {comments?.slice(0, commentsLimit).map((comment) => (
                  <motion.li
                    key={comment.id}
                    className={styles.listItem}
                    {...directChildItemsContainerAnimation}
                  >
                    {comment.text}
                  </motion.li>
                ))}
              </AnimatePresence>
            </ul>
            {isMoreCommentsToLoad && (
              <button onClick={handleOnClick} className={styles.button}>
                {isMoreCommentsExpanded
                  ? t('lessItems')
                  : t('moreItems', { count: moreCommentsToLoadCount })}
              </button>
            )}
          </>
        ) : (
          <span className={styles.noComments}>
            {t(`objective.insights.recentInsights.${context}.noComments`)}
          </span>
        )}
      </div>
    </section>
  );
};

export default ObjectiveOverviewInsightsSection;
