import type { PropsWithChildren, ReactNode } from 'react';
import { createRef, useMemo, useRef } from 'react';
import { useScroll } from 'react-use';
import styled from '@emotion/styled';

import type { BaseModalProps } from 'shared/components/__DEPRECATED__/BaseModal';
import BaseModal from 'shared/components/__DEPRECATED__/BaseModal';
import type { IconButtonProps } from 'shared/components/IconButton';
import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';

import Content from './SideModalContent';
import Jumbotron from './SideModalJumbotron';
import Footer from './SideModalFooter';
import TopBar from './SideModalTopBar';

export type SideModalProps = PropsWithChildren<
  Pick<BaseModalProps, 'onClose' | 'isOpen'> & {
    buttons?: IconButtonProps[];
    className?: string;
    scrollOffsetAfterTopBarIsVisible?: number;
    title?: ReactNode;
  }
>;

const Container = styled(BaseModal)`
  border-radius: 1.25rem;
  bottom: 1.25rem;
  overflow: auto;
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  width: 40rem;
`;

const ContentContainer = styled.div`
  margin-bottom: 4.25rem;
  margin-top: -4.25rem;
`;

const SideModal = ({
  isOpen,
  title,
  onClose,
  buttons: buttonsProp,
  children,
  scrollOffsetAfterTopBarIsVisible,
  className,
}: SideModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const modalScrollPosition = useScroll(isOpen ? modalRef : createRef());

  const buttons = useMemo<IconButtonProps[]>(
    () => [
      ...(buttonsProp || []),
      {
        icon: CloseIcon,
        onClick: () => {
          onClose();
        },
      },
    ],
    [buttonsProp, onClose],
  );

  const isTopBarVisible = scrollOffsetAfterTopBarIsVisible
    ? modalScrollPosition.y > scrollOffsetAfterTopBarIsVisible
    : modalScrollPosition.y > 0;

  return (
    <Container
      isOpen={isOpen}
      onClose={onClose}
      className={className}
      ref={modalRef}
    >
      <TopBar isVisible={isTopBarVisible} buttons={buttons}>
        {title}
      </TopBar>
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};

SideModal.Jumbotron = Jumbotron;
SideModal.Content = Content;
SideModal.Footer = Footer;

export default SideModal;
