import { useTranslation } from 'react-i18next';
import type { NoticeProps } from 'react-select';
import { components } from 'react-select';

const SelectNoOptionsMessage = ({ ...props }: NoticeProps) => {
  const { t } = useTranslation();

  return (
    <components.NoOptionsMessage {...props}>
      {t('noOptions')}
    </components.NoOptionsMessage>
  );
};

export default SelectNoOptionsMessage;
