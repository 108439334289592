import Select from 'shared/components/__DEPRECATED__/Select';
import type { SelectProps } from 'shared/components/__DEPRECATED__/Select';

import { getOptionLabel } from './SelectThemeInput.utils';
import { useSelectThemeInput } from './useSelectThemeInput';
import type { SelectThemeInputOption } from './SelectThemeInput.type';

type SelectThemeInputProps = Pick<
  SelectProps<SelectThemeInputOption, false>,
  'value' | 'onChange' | 'isSearchable'
>;

const SelectThemeInput = ({
  isSearchable = true,
  ...restProps
}: SelectThemeInputProps) => {
  const { options } = useSelectThemeInput();

  return (
    <Select<SelectThemeInputOption, false>
      isSearchable={isSearchable}
      options={options}
      getOptionLabel={getOptionLabel}
      {...restProps}
    />
  );
};

export default SelectThemeInput;
