import { useTranslation } from 'react-i18next';

import SideModal from 'shared/components/SideModal';

import SideModalContent from './SideModalContent';
import { usePreviousInsights } from './usePreviousInsights';

const PreviousInsightsSideModal = () => {
  const { t } = useTranslation();

  const { isPreviousInsightsOpen, onClosePreviousInsights } =
    usePreviousInsights();

  return (
    <SideModal
      headerTitle={t('status.sideCard.title')}
      isOpen={isPreviousInsightsOpen}
      onClose={onClosePreviousInsights}
    >
      <SideModalContent />
    </SideModal>
  );
};

export default PreviousInsightsSideModal;
