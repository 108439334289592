import { useTranslation } from 'react-i18next';

import UserMultiSelect from 'user/UserMultiSelect';
import ActionStatusMultiSelect from 'actions/ActionStatusMultiSelect';
import { useActionsLayout } from 'actions/ActionsLayout/ActionsLayoutProvider';
import DatePicker from 'shared/components/DatePicker';
import PriorityMultiSelect from 'shared/priority/PriorityMultiSelect';
import Flex from 'shared/components/Flex';

import Provider from './ActionsLayoutFiltersProvider';

const ActionsLayoutFilters = () => {
  const { t } = useTranslation();

  const {
    startDateFilter,
    endDateFilter,
    setStartDateFilter,
    setEndDateFilter,
  } = useActionsLayout();

  return (
    <>
      <ActionStatusMultiSelect />
      <UserMultiSelect />
      <PriorityMultiSelect />
      <Flex gap={8}>
        <DatePicker
          value={startDateFilter}
          onChange={setStartDateFilter}
          labelPrefix={t('from')}
          placeholder={t('allDates')}
          maxDate={endDateFilter}
          grow={true}
        />
        <DatePicker
          value={endDateFilter}
          onChange={setEndDateFilter}
          labelPrefix={t('to')}
          placeholder={t('allDates')}
          minDate={startDateFilter}
          grow={true}
        />
      </Flex>
    </>
  );
};

ActionsLayoutFilters.Provider = Provider;

export default ActionsLayoutFilters;
