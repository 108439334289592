import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { canPerformOrgUnitAction } from 'user/ability/canPerformOrgUnitAction';
import type {
  OrgAuthorizedAction,
  OrgUnitAuthorizedAction,
} from 'types.graphql.generated';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import { canPerformOrgAction } from 'user/ability/canPerformOrgAction';

import type { SelectOrgUnitInputProviderContextValue } from './SelectOrgUnitInputProvider.context';
import { SelectOrgUnitInputProviderContext } from './SelectOrgUnitInputProvider.context';
import { useSelectOrgUnitInputQuery } from './SelectOrgUnitInputProvider.graphql.generated';
import type { OrgUnitsLevel } from './SelectOrgUnitInputProvider.utils';
import { getOrgUnitsLevels } from './SelectOrgUnitInputProvider.utils';
import type { SelectOrgUnitInputFieldOption } from '../SelectOrgUnitInput.type';

type Props = PropsWithChildren<{
  excludeOrgUnitId?: string;
  filterOrgByAction?: OrgAuthorizedAction;
  filterOrgUnitsByAction?: OrgUnitAuthorizedAction;
}>;

const SelectOrgUnitInputProvider = ({
  excludeOrgUnitId,
  filterOrgByAction,
  filterOrgUnitsByAction,
  children,
}: Props) => {
  const { t } = useTranslation();
  const { activeOrg } = useActiveOrg();

  const handleApolloError = useHandleApolloError();
  const { data } = useSelectOrgUnitInputQuery({ onError: handleApolloError });

  const orgUnitLevels = useMemo<OrgUnitsLevel[]>(() => {
    if (!data) return [];

    return getOrgUnitsLevels(data.activeOrg.orgUnits)
      .map((level) => ({
        ...level,
        orgUnits: level.orgUnits.filter(
          (orgUnit) =>
            (!excludeOrgUnitId || orgUnit.id !== excludeOrgUnitId) &&
            (!filterOrgUnitsByAction ||
              canPerformOrgUnitAction(orgUnit, filterOrgUnitsByAction)),
        ),
      }))
      .filter((level) => level.orgUnits.length > 0);
  }, [data, excludeOrgUnitId, filterOrgUnitsByAction]);

  const orgOption = useMemo<SelectOrgUnitInputFieldOption | undefined>(() => {
    const canSelectOrg =
      !filterOrgByAction || canPerformOrgAction(activeOrg, filterOrgByAction);

    if (!canSelectOrg) return;

    return { value: null, label: activeOrg.displayName };
  }, [activeOrg, filterOrgByAction]);

  const contextValue = useMemo<SelectOrgUnitInputProviderContextValue>(
    () => ({
      options: [
        ...[orgOption].filter(Boolean),
        ...orgUnitLevels.map(({ orgUnits, level }) => ({
          label: t('orgUnit.level', { level }),
          options: orgUnits.map((orgUnit) => ({ value: orgUnit })),
        })),
      ],
    }),
    [orgOption, orgUnitLevels, t],
  );

  return (
    <SelectOrgUnitInputProviderContext.Provider value={contextValue}>
      {children}
    </SelectOrgUnitInputProviderContext.Provider>
  );
};

export default SelectOrgUnitInputProvider;
