import { createContext } from 'react';

import type { InitiativesLayoutContent } from './InitiativesContentLayoutProvider.type';

export type InitiativesContentLayoutContextValue = {
  layout: InitiativesLayoutContent | string;
  toggleLayout: () => void;
};

export const InitiativesContentLayoutContext = createContext<
  InitiativesContentLayoutContextValue | undefined
>(undefined);
