import { useRef } from 'react';
import type { AriaMenuItemProps } from 'react-aria';
import type { Node } from 'react-stately';

import styles from './NavbarMenuItem.module.scss';

type NavbarMenuItemProps = AriaMenuItemProps & {
  item: Node<object>;
  onClose?: () => void;
};

const NavbarMenuItem = ({ item, onClose }: NavbarMenuItemProps) => {
  const ref = useRef(null);

  return (
    <li ref={ref} className={styles.listItem} onClick={onClose}>
      {item.rendered}
    </li>
  );
};

export default NavbarMenuItem;
