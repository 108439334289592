import type { PropsWithChildren } from 'react';

import { useQuestions } from './QuestionsProvider.context';

const ReadOnlyHidden = ({ children }: PropsWithChildren) => {
  const { isReadOnly } = useQuestions();

  if (isReadOnly) return null;

  return <>{children}</>;
};

export default ReadOnlyHidden;
