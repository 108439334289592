import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import Flex from 'shared/components/Flex';
import Text from 'shared/components/Text';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  user-select: none;
`;

type Props = PropsWithChildren<{
  className?: string;
  isOptional?: boolean;
  onClick?: () => void;
  title: string;
}>;

const FormContainerHeader = ({
  className,
  title,
  isOptional,
  onClick,
  children,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Container className={className} onClick={onClick}>
      <Flex gap={6}>
        <Text size={'large'} color={'black'}>
          {title}
        </Text>
        {isOptional && <Text size={'large'}>({t('optional')})</Text>}
      </Flex>

      {children}
    </Container>
  );
};

export default FormContainerHeader;
