import type { OrgUnit, OrgUnitAuthorizedAction } from 'types.graphql.generated';

import {
  canPerformOrgAction,
  type OrgWithAuthorizedActions,
} from './canPerformOrgAction';

export type OrgUnitWithAuthorizedActions = Pick<
  OrgUnit,
  'currentUserAuthorizedActions'
>;

export function canPerformOrgUnitAction(
  orgUnit: OrgUnitWithAuthorizedActions,
  action: OrgUnitAuthorizedAction,
) {
  return orgUnit.currentUserAuthorizedActions.includes(action);
}

export function canWriteOrgStrategyOrWriteAnyOrgUnit(
  org: OrgWithAuthorizedActions,
  orgUnits: OrgUnitWithAuthorizedActions[],
) {
  return (
    canPerformOrgAction(org, 'WRITE_STRATEGY') ||
    orgUnits.some((orgUnit) => canPerformOrgUnitAction(orgUnit, 'WRITE'))
  );
}
