import type {
  i18n as I18nType,
  Interpolator,
  Formatter,
  ReadCallback,
} from 'i18next';
import merge from 'lodash/merge';
import { unflatten } from 'flat';

// Modifies i18n.interpolator.interpolate to enable post processing of nested keys.
// i18next assumes that nested keys are not post processed and adds applyPostProcessor: false to the options.
// This function modifies the interpolate function to always set applyPostProcessor: true, so we don't have to write:
// "This objective has {{metricsCount}} $t(strategy.mission, {'applyPostProcessor': true})."
// every time in the JSON translation files, but only:
// "This objective has {{metricsCount}} $t(strategy.mission)."
// Found on: https://stackoverflow.com/questions/63176820/is-there-an-option-to-use-postprocessor-in-nesting-translations-in-i18next
// Source code of Interpolator: https://github.com/i18next/i18next/blob/b8b014f99a16af73f458c32f167e485447d76f92/src/Interpolator.js#L153
// Read more about nesting: https://www.i18next.com/translation-function/nesting
export const enablePostProcessingNestedKeysByDefault = (i18n: I18nType) => {
  const originalFn = i18n.services.interpolator.interpolate;
  const proxyFn: Interpolator['interpolate'] = function (
    string,
    fn,
    language,
    options = {},
  ) {
    options.applyPostProcessor = true;
    return originalFn.bind(i18n.services.interpolator)(
      string,
      fn,
      language,
      options,
    );
  };
  i18n.services.interpolator.interpolate = proxyFn;
};

export const lowercaseFormatter: Parameters<Formatter['add']>[1] = (value) => {
  if (typeof value === 'string') {
    return value.toLowerCase();
  }
  return value;
};

export const capitalizeFormatter: Parameters<Formatter['add']>[1] = (value) => {
  if (typeof value === 'string') {
    return `${value.substring(0, 1).toUpperCase()}${value.substring(1)}`;
  }
  return value;
};

export const resourcesToBackendImportFn = (
  language: string,
  namespace: string,
  callback: ReadCallback,
) => {
  import(`../../${namespace}/locales/${language}.${namespace}.locale.json`)
    .then((namespaceResource) => {
      import(
        `../../${namespace}/locales/${language}.orgTerms.locale.json`
      ).then((orgTermsResource) => {
        const result = merge(namespaceResource, unflatten(orgTermsResource));
        callback(null, result);
      });
    })
    .catch((error) => {
      callback(error, null);
    });
};
