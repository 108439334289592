import { useFormikContext } from 'formik';
import type { FormikValues } from 'formik';

import { createFormUtils } from './Form.utils';

export const useForm = <Values extends FormikValues>() => {
  const formikContext = useFormikContext<Values>();

  const formUtils = createFormUtils<Values>(formikContext);

  return {
    ...formikContext,
    ...formUtils,
  };
};
