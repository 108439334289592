import styled from '@emotion/styled';
import { useContext } from 'react';
import { Button, DatePickerStateContext } from 'react-aria-components';

import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';

const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.color.strokeLight};
  width: 1.143rem;
  height: 1.143rem;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
  border-radius: 1.143rem;
  vertical-align: middle;
  text-align: center;
  font-size: 0.857rem;
  line-height: 0.857rem;

  svg {
    width: 14px;
    height: 14px;
    color: ${(props) => props.theme.color.typoSecondary};
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px ${(props) => props.theme.color.primary};
  }

  &:hover {
    background-color: ${(props) => props.theme.color.strokeMedium};

    svg {
      color: ${(props) => props.theme.color.typoPrimary};
    }
  }

  &[data-pressed] {
    background-color: ${(props) => props.theme.color.strokeDark};
  }
`;

const ClearButton = () => {
  const state = useContext(DatePickerStateContext)!;

  return (
    <StyledButton
      // Don't inherit default Button behavior from DatePicker.
      slot={null}
      aria-label={'Clear'}
      onPress={() => state.setValue(null)}
    >
      <CloseIcon />
    </StyledButton>
  );
};

export default ClearButton;
