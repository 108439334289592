import type { InputHTMLAttributes } from 'react';
import cn from 'classnames';
import type { Merge } from 'type-fest';

import styles from './RadioCard.module.scss';

export type RadioCardProps = Merge<
  Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>,
  {
    isError?: boolean;
  }
>;

const RadioCard = ({
  isError,
  checked,
  children,
  className,
  ...restProps
}: RadioCardProps) => (
  <label
    className={cn(
      styles.radioCard,
      {
        [styles.radioCardError]: isError,
        [styles.radioCardChecked]: checked,
      },
      className,
    )}
  >
    {children}
    <input {...restProps} type={'radio'} className={styles.radioCardInput} />
  </label>
);

export default RadioCard;
