import cn from 'classnames';
import type { PropsWithChildren, ReactNode } from 'react';

import { ReactComponent as ChevronIconUp } from 'shared/static/icons/icon-chevron-up.svg';
import { ReactComponent as ChevronIconDown } from 'shared/static/icons/icon-chevron-down.svg';
import IconButton from 'shared/components/IconButton';

import styles from './Collapse.module.scss';

type CollapseProps = PropsWithChildren<{
  className?: string;
  hasBackground?: boolean;
  hasChevron?: boolean;
  isCollapsed: boolean;
  onToggleIsCollapsed?: (isCollapsed: boolean) => void;
  renderHeading?: () => ReactNode;
}>;

const Collapse = ({
  renderHeading,
  isCollapsed,
  onToggleIsCollapsed,
  hasBackground,
  hasChevron = true,
  children,
  className,
}: CollapseProps) => {
  const Icon = isCollapsed ? ChevronIconUp : ChevronIconDown;

  return (
    <section
      className={cn(
        styles.collapse,
        { [styles.collapseBackground]: hasBackground },
        className,
      )}
    >
      <header
        className={styles.collapseHeader}
        onClick={() => {
          onToggleIsCollapsed?.(!isCollapsed);
        }}
      >
        {renderHeading?.()}
        {hasChevron && <IconButton size={'small'} icon={Icon} />}
      </header>
      {!isCollapsed ? (
        <div className={styles.collapseContent}>{children}</div>
      ) : null}
    </section>
  );
};

export default Collapse;
