import { Outlet } from 'react-router-dom';

import ActionsLayout from 'actions/ActionsLayout';
import Spinner from 'shared/spinner/Spinner';

import { useStrategyActions } from './useStrategyActions';
import StrategyActionsProvider from './StrategyActionsProvider';

const StrategyActions = () => {
  const { objectivesWithActions, isStrategyActionsLoading } =
    useStrategyActions();

  return (
    <>
      {isStrategyActionsLoading ? (
        <Spinner.Circle />
      ) : (
        <ActionsLayout
          objectives={objectivesWithActions}
          context={'strategy'}
          showCalendarControls={true}
        />
      )}
      <Outlet />
    </>
  );
};

export default () => (
  <StrategyActionsProvider>
    <StrategyActions />
  </StrategyActionsProvider>
);
