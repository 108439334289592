import styled from '@emotion/styled';
import { useState } from 'react';

import { desktopOrBigger } from 'shared/styles/utils';
import type { ContributionMyFollowUpsOneThingCampaignFragment } from 'contribution/ContributionMyFollowUpsViewMore/ContributionMyFollowUpsViewMore.graphql.generated';

import PyramidGuide from './PyramidGuide';
import type { StrategyGuideFieldsFragment } from '../StrategyRoadmapProvider/StrategyRoadmapProvider.graphql.generated';
import Navigation from './Navigation';
import CampaignGuide from './CampaignGuide';
import OneThingCampaignGuide from './OneThingCampaignGuide';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 0px;

  @media (${desktopOrBigger}) {
    min-width: 380px;
    max-width: 380px;
    position: absolute;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 8px;

  padding: 20px;
  border-radius: 20px;

  box-shadow: 0 0 12px 8px rgb(65 67 80 / 8%);
  background: ${(props) => props.theme.color.white};
`;

type Props = {
  oneThingCampaigns: ContributionMyFollowUpsOneThingCampaignFragment[];
  strategy: StrategyGuideFieldsFragment;
};

const StrategyRoadmapGuide = ({ oneThingCampaigns, strategy }: Props) => {
  const items = [
    ...oneThingCampaigns
      .filter((campaign) => campaign.currentUserTask?.status !== 'DONE')
      .map((campaign) => (
        <OneThingCampaignGuide
          key={`one-thing-campaign-${campaign.id}`}
          campaign={campaign}
        />
      )),
    ...strategy.activeCampaigns.map((campaign) => (
      <CampaignGuide key={`campaign-${campaign.id}`} campaign={campaign} />
    )),
    <PyramidGuide key={'pyramid'} />,
  ];

  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  const currentItem = items[currentItemIndex];

  return (
    <Container>
      <Card>{currentItem}</Card>
      <Navigation
        itemsCount={items.length}
        currentItemIndex={currentItemIndex}
        setCurrentItemIndex={setCurrentItemIndex}
      />
    </Container>
  );
};

export default StrategyRoadmapGuide;
