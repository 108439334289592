import type { ReactNode } from 'react';
import styled from '@emotion/styled';

import Text from 'shared/components/Text';
import Divider from 'shared/components/Divider';
import Space from 'shared/components/Space';

const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.legacyColor.colorBoulder};
`;

type SideModalFooterProps = {
  className?: string;
  end?: ReactNode;
  start?: ReactNode;
};

const SideModalFooter = ({ start, end, className }: SideModalFooterProps) => (
  <Space direction={'vertical'} size={'small'} className={className}>
    <Divider />
    <Content>
      <StyledText ellipsis={true}>{start}</StyledText>
      <StyledText ellipsis={true}>{end}</StyledText>
    </Content>
  </Space>
);

export default SideModalFooter;
