import { useTranslation } from 'react-i18next';

import Attributes from 'strategy/Attributes';
import Heading from 'shared/components/Heading';
import ObjectiveDetailsSider from 'objective/ObjectiveDetails/ObjectiveDetailsSider';
import Spinner from 'shared/spinner/Spinner';
import Layout from 'shared/components/Layout';

import styles from './ObjectiveDetails.module.scss';
import ObjectiveDetailsProvider, {
  useObjectiveDetails,
} from './ObjectiveDetailsProvider';

const ObjectiveDetails = () => {
  const { t } = useTranslation();

  const { objective, isObjectiveLoading } = useObjectiveDetails();

  return isObjectiveLoading ? (
    <Spinner.Circle />
  ) : (
    <Layout.Content
      renderSider={() => <ObjectiveDetailsSider objective={objective!} />}
      hasPadding={false}
      key={objective?.id}
      className={styles.layout}
      variant={'contained'}
    >
      <Heading level={3} className={styles.heading}>
        {t('objective.objectiveLayout.header.navigation.details')}
      </Heading>
      <Attributes attributes={objective?.attributes} />
    </Layout.Content>
  );
};

export default () => (
  <ObjectiveDetailsProvider>
    <ObjectiveDetails />
  </ObjectiveDetailsProvider>
);
