import type { InputHTMLAttributes, ReactNode } from 'react';
import type { Merge } from 'type-fest';

import styles from './RadioInput.module.scss';

export type RenderLabelParams = {
  isChecked: boolean;
  isDisabled: boolean;
};

type RadioInputProps = Merge<
  Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>,
  {
    renderLabel: (params: RenderLabelParams) => ReactNode;
  }
>;

const RadioInput = ({
  checked,
  disabled,
  renderLabel,
  ...restProps
}: RadioInputProps) => (
  <label className={styles.container}>
    {renderLabel({
      isChecked: checked ?? false,
      isDisabled: disabled ?? false,
    })}
    <input
      {...restProps}
      type={'radio'}
      checked={checked}
      disabled={disabled}
      className={styles.input}
    />
  </label>
);

export default RadioInput;
