import styled from '@emotion/styled';

import { smallerThanDesktop } from 'shared/styles/utils';
import { useActionsLayout } from 'actions/ActionsLayout/ActionsLayoutProvider/useActionsLayout';
import { ReactComponent as GridIcon } from 'shared/static/icons/icon-layout-grid.svg';
import { ReactComponent as ListIcon } from 'shared/static/icons/icon-layout-list.svg';
import Toggle from 'shared/components/Toggle';

const Container = styled.div`
  @media (${smallerThanDesktop}) {
    margin-right: auto;
  }
`;

const ActionsLayoutToggle = () => {
  const { layout, toggleLayout } = useActionsLayout();

  return (
    <Container>
      <Toggle
        firstIcon={GridIcon}
        secondIcon={ListIcon}
        handleToggle={toggleLayout}
        firstChecked={layout === 'grid'}
      />
    </Container>
  );
};

export default ActionsLayoutToggle;
