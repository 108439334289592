import type * as Types from '../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteMetricsMutationVariables = Types.Exact<{
  metricId: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type DeleteMetricsMutation = { __typename: 'Mutation', deleteMetrics: boolean };


export const DeleteMetricsDocument = gql`
    mutation DeleteMetrics($metricId: [ID!]!) {
  deleteMetrics(idsToDelete: $metricId)
}
    `;
export type DeleteMetricsMutationFn = Apollo.MutationFunction<DeleteMetricsMutation, DeleteMetricsMutationVariables>;

/**
 * __useDeleteMetricsMutation__
 *
 * To run a mutation, you first call `useDeleteMetricsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMetricsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMetricsMutation, { data, loading, error }] = useDeleteMetricsMutation({
 *   variables: {
 *      metricId: // value for 'metricId'
 *   },
 * });
 */
export function useDeleteMetricsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMetricsMutation, DeleteMetricsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMetricsMutation, DeleteMetricsMutationVariables>(DeleteMetricsDocument, options);
      }
export type DeleteMetricsMutationHookResult = ReturnType<typeof useDeleteMetricsMutation>;
export type DeleteMetricsMutationResult = Apollo.MutationResult<DeleteMetricsMutation>;
export type DeleteMetricsMutationOptions = Apollo.BaseMutationOptions<DeleteMetricsMutation, DeleteMetricsMutationVariables>;