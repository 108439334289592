import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import ActionsColumn from 'actions/ActionsGrid/ActionsColumn';
import { useActionsLayout } from 'actions/ActionsLayout/ActionsLayoutProvider/useActionsLayout';
import {
  filterActionsByPriority,
  getActionsWithParentObjective,
  getActiveActions,
  getDoneActions,
  getInactiveActions,
} from 'actions/action.utils';
import type { ActionsObjectiveFragment } from 'actions/actions.fragment.graphql.generated';
import { ReactComponent as EmptyIllustration } from 'shared/static/illustrations/illustration-empty-state.svg';
import Space from 'shared/components/Space';
import Result from 'shared/components/Result';
import { useCompletedFilter } from 'shared/components/CompletedFilterButton/useCompletedFilter';

import styles from './ActionsGrid.module.scss';

type ActionsGridProps = {
  objectives: Maybe<ActionsObjectiveFragment[]>;
};

const ActionsGrid = ({ objectives }: ActionsGridProps) => {
  const { t } = useTranslation();

  const { statusFilter, priorityFilter } = useActionsLayout();

  const { showCompleted } = useCompletedFilter();

  const showAll = statusFilter.includes('ALL');
  const showInactive = statusFilter.includes('INACTIVE') || showAll;
  const showActive = statusFilter.includes('ACTIVE') || showAll;

  const { hasActions, inactiveActions, activeActions, doneActions } =
    useMemo(() => {
      const actions = getActionsWithParentObjective(objectives);

      const filteredActions = filterActionsByPriority(actions, priorityFilter);

      return {
        hasActions: !!filteredActions?.length,
        inactiveActions: getInactiveActions(filteredActions),
        activeActions: getActiveActions(filteredActions),
        doneActions: getDoneActions(filteredActions),
      };
    }, [objectives, priorityFilter]);

  return (
    <>
      {hasActions ? (
        <section className={styles.cardGrid}>
          {showInactive && (
            <ActionsColumn title={'inactive'} actions={inactiveActions} />
          )}
          {showActive && (
            <ActionsColumn title={'active'} actions={activeActions} />
          )}
          {showCompleted && (
            <ActionsColumn title={'done'} actions={doneActions} />
          )}
        </section>
      ) : (
        <Space isCentered={true}>
          <Result
            description={t('actions.empty')}
            illustration={EmptyIllustration}
            hasBackground={true}
          />
        </Space>
      )}
      <Outlet />
    </>
  );
};

export default ActionsGrid;
