import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'auth/AuthProvider';
import { authRoutesPaths } from 'auth/auth.routing.paths';
import { useUserValidation } from 'user/UserValidationProvider/useUserValidation';
import { ActingOrg } from 'org/ActingOrg';

import { useValidateLoggedInUserMutation } from './useValidateLoggedInUser.graphql.generated';

type UseValidateLoggedInUserReturnType = (
  accessToken: string,
) => Promise<unknown>;

export const useValidateLoggedInUser = () => {
  const { t } = useTranslation();
  const { signOut } = useAuth();
  const { onValidated } = useUserValidation();
  const navigate = useNavigate();

  const [validateLoggedInUser] = useValidateLoggedInUserMutation({
    onError: () =>
      signOut().then(() =>
        setTimeout(
          () =>
            navigate(
              authRoutesPaths.signUpWithError({
                query: { errorMessage: t('signUp.verificationErrorMessage') },
              }),
            ),
          1000,
        ),
      ),
  });

  return useCallback<UseValidateLoggedInUserReturnType>(
    (accessToken) =>
      validateLoggedInUser({
        context: {
          headers: {
            authorization: `Bearer ${accessToken}`,
            'X-CREDENTIAL_ACCESS_TOKEN': accessToken,
          },
        },
      }).then((response) => {
        const orgKey = response.data?.validateLoggedInUser.org?.orgKey;
        if (orgKey) ActingOrg.setActingOrg(orgKey);
        onValidated();
      }),
    [onValidated, validateLoggedInUser],
  );
};
