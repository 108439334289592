import { testStatusIndicatorAgainstId } from 'shared/status/StatusMultiSelect';
import type { ObjectivesByTheme } from 'objective/objective.utils';
import { testStrategyItemAgainstInterval } from 'strategy/strategy.utils';
import { testPriorityAgainstId } from 'shared/priority/PriorityMultiSelect';
import type { CalendarCheck } from 'shared/components/CalendarControls/CalendarControls.context';

import type {
  TeamInsightsOverviewThemeFilter,
  TeamInsightsOverviewStatusFilter,
  TeamInsightsOverviewUserFilter,
  TeamInsightsOverviewTheme,
  TeamInsightsOverviewObjective,
  TeamInsightsOverviewUser,
  TeamInsightsOverviewDateFilter,
  TeamInsightsOverviewPriorityFilter,
} from './TeamInsightsOverviewProvider.type';

export const filterObjectivesByTheme = (
  objectives: TeamInsightsOverviewObjective[],
  themeFilter: TeamInsightsOverviewThemeFilter,
) => {
  if (!themeFilter.length) {
    return objectives;
  }
  return objectives.filter((objective) =>
    themeFilter.some((filterValue) => objective.theme?.id === filterValue),
  );
};

export const filterObjectivesByStatus = (
  objectives: TeamInsightsOverviewObjective[],
  filter: TeamInsightsOverviewStatusFilter,
) =>
  objectives
    .map((objective) => ({
      ...objective,
      isFiltersMatch:
        objective.isFiltersMatch &&
        filter.some((statusMultiSelectItemId) =>
          testStatusIndicatorAgainstId(
            statusMultiSelectItemId,
            objective.currentObjectiveStatus?.statusIndicator,
          ),
        ),
      initiatives: objective.initiatives.filter((initiative) =>
        filter.some((statusMultiSelectItemId) =>
          testStatusIndicatorAgainstId(
            statusMultiSelectItemId,
            initiative.currentInitiativeStatus?.statusIndicator,
          ),
        ),
      ),
      metrics: objective.metrics.filter((metric) =>
        filter.some((statusMultiSelectItemId) =>
          testStatusIndicatorAgainstId(
            statusMultiSelectItemId,
            metric.currentMetricStatus?.statusIndicator,
          ),
        ),
      ),
      actions: objective.actions.filter((action) =>
        filter.some((statusMultiSelectItemId) =>
          testStatusIndicatorAgainstId(
            statusMultiSelectItemId,
            action.currentStatus?.statusIndicator,
          ),
        ),
      ),
    }))
    .filter(
      (objective) =>
        objective.metrics.length > 0 ||
        objective.initiatives.length > 0 ||
        objective.actions.length > 0 ||
        objective.isFiltersMatch,
    );

export const filterObjectivesByPriority = (
  objectives: TeamInsightsOverviewObjective[],
  filter: TeamInsightsOverviewPriorityFilter,
) =>
  objectives
    .map((objective) => ({
      ...objective,
      isFiltersMatch:
        objective.isFiltersMatch &&
        filter.some((priorityMultiSelectItemId) =>
          testPriorityAgainstId(priorityMultiSelectItemId, objective.priority),
        ),
      initiatives: objective.initiatives.filter((initiative) =>
        filter.some((priorityMultiSelectItemId) =>
          testPriorityAgainstId(priorityMultiSelectItemId, initiative.priority),
        ),
      ),
      metrics: objective.metrics.filter((metric) =>
        filter.some((priorityMultiSelectItemId) =>
          testPriorityAgainstId(priorityMultiSelectItemId, metric.priority),
        ),
      ),
      actions: objective.actions.filter((action) =>
        filter.some((priorityMultiSelectItemId) =>
          testPriorityAgainstId(priorityMultiSelectItemId, action.priority),
        ),
      ),
    }))
    .filter(
      (objective) =>
        objective.metrics.length > 0 ||
        objective.initiatives.length > 0 ||
        objective.actions.length > 0 ||
        objective.isFiltersMatch,
    );

export const filterObjectivesByDates = (
  objectives: TeamInsightsOverviewObjective[],
  startDate: TeamInsightsOverviewDateFilter,
  endDate: TeamInsightsOverviewDateFilter,
  check?: CalendarCheck,
) => {
  const interval = { start: startDate, end: endDate };

  return objectives
    .map((objective) => ({
      ...objective,
      isFiltersMatch:
        objective.isFiltersMatch &&
        testStrategyItemAgainstInterval(objective, interval, check),
      initiatives: objective.initiatives.filter((initiative) =>
        testStrategyItemAgainstInterval(initiative, interval, check),
      ),
      metrics: objective.metrics.filter((metric) =>
        testStrategyItemAgainstInterval(metric, interval, check),
      ),
      actions: objective.actions.filter((action) => {
        testStrategyItemAgainstInterval(action, interval, check);
      }),
    }))
    .filter(
      (objective) =>
        objective.metrics.length > 0 ||
        objective.initiatives.length > 0 ||
        objective.actions.length > 0 ||
        objective.isFiltersMatch,
    );
};

export const filterObjectivesByUser = (
  objectives: TeamInsightsOverviewObjective[],
  filter: TeamInsightsOverviewUserFilter,
) => {
  if (!filter.length) {
    return objectives;
  }
  return objectives
    .map((objective) => ({
      ...objective,
      isFiltersMatch:
        objective.isFiltersMatch &&
        filter.some((value) => value === objective.owner?.id),
      initiatives: objective.initiatives.filter((initiative) =>
        filter.some((value) => value === initiative.owner?.id),
      ),
      metrics: objective.metrics.filter((metric) =>
        filter.some((value) => value === metric.owner?.id),
      ),
      actions: objective.actions.filter((action) =>
        filter.some((value) => value === action.owner?.id),
      ),
    }))
    .filter(
      (objective) =>
        objective.metrics.length > 0 ||
        objective.initiatives.length > 0 ||
        objective.actions.length > 0 ||
        objective.isFiltersMatch,
    );
};

export const getObjectivesOwners = (
  objectives: TeamInsightsOverviewObjective[],
): TeamInsightsOverviewUser[] =>
  Object.values(
    objectives.reduce<
      Record<TeamInsightsOverviewUser['id'], TeamInsightsOverviewUser>
    >((accumulator, objective) => {
      const user = objective.owner;
      if (user && !accumulator[user.id]) {
        accumulator[user.id] = user;
      }
      return accumulator;
    }, {}),
  );

export const getActiveTheme = (
  objectivesByTheme: ObjectivesByTheme<
    TeamInsightsOverviewObjective,
    TeamInsightsOverviewTheme
  >[],
  themeFilter: TeamInsightsOverviewThemeFilter,
): Maybe<TeamInsightsOverviewTheme> => {
  const activeThemeFromFilter = themeFilter.at(0);
  if (activeThemeFromFilter) {
    return objectivesByTheme.find(
      ({ theme }) => theme?.id === activeThemeFromFilter,
    )?.theme;
  }
  return undefined;
};

export const getActiveThemeObjectives = (
  objectivesByTheme: ObjectivesByTheme<
    TeamInsightsOverviewObjective,
    TeamInsightsOverviewTheme
  >[],
  themeFilter: TeamInsightsOverviewThemeFilter,
): TeamInsightsOverviewObjective[] => {
  const activeThemeFromFilter = themeFilter.at(0);
  if (activeThemeFromFilter) {
    return (
      objectivesByTheme.find(({ theme }) => theme?.id === activeThemeFromFilter)
        ?.objectives || []
    );
  }
  return objectivesByTheme.flatMap(({ objectives }) => objectives);
};
