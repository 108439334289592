import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import Button from 'shared/components/Button';
import Modal from 'shared/components/Modal';
import type { ModalAnimation } from 'shared/components/Modal/Modal';
import InfoCard from 'shared/components/InfoCard';
import Sidesheets from 'shared/sidesheets';

import { initiativeFormId } from '../InitiativeForm';
import InitiativeSuggestions from './InitiativeSuggestions';
import InitiativeFormFields from './InitiativeFormFields';

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 1.5rem;
  justify-content: center;
`;

const FormContainer = styled.div`
  flex-grow: 1;
`;

const FormControls = styled.div`
  display: flex;
  gap: 16px;
  margin: 20px 0 20px 0;
  justify-content: flex-end;
`;

const SidePanel = styled.div`
  max-width: 24rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

type Props = {
  animation?: ModalAnimation;
  confirmLabel: string;
  headerTitle: string;
  isOpen: boolean;
  onClose: () => void;
};

const InitiativeFormModal = ({
  headerTitle,
  confirmLabel,
  isOpen,
  onClose,
  animation,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      confirmLabel={confirmLabel}
      headerTitle={headerTitle}
      isOpen={isOpen}
      onClose={onClose}
      formId={initiativeFormId}
      animation={animation}
    >
      <Container>
        <FormContainer>
          <InitiativeFormFields />

          <FormControls>
            <Button variant={'outlined'} color={'mono'} onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button color={'primary'} type={'submit'} form={initiativeFormId}>
              {confirmLabel}
            </Button>
          </FormControls>
        </FormContainer>

        <SidePanel>
          <InfoCard
            title={t('initiative.initiativeInfoCard.heading')}
            sideSheetTitle={t('sidesheets.context.objective.heading')}
            contentKey={'initiative.initiativeInfoCard.content'}
            orgTermKey={'initiative.initiative'}
          >
            <Sidesheets context={'initiative'} />
          </InfoCard>

          <InitiativeSuggestions shouldReinitialize={isOpen} />
        </SidePanel>
      </Container>
    </Modal>
  );
};

export default InitiativeFormModal;
