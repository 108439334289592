import { Trans, useTranslation } from 'react-i18next';
import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';

import Heading from 'shared/components/Heading';
import Stats from 'shared/components/Stats';
import Stat from 'shared/components/Stats/Stat';
import Flex from 'shared/components/Flex';

import { getStats } from './StrategyBoardHeader.utils';
import { useStrategyBoard } from '../StrategyBoardProvider';
import StrategyBoardFiltersChipGroup from '../StrategyBoardFiltersChipGroup';

const UsersStats = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.color.backgroundLight};
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: space-between;
  padding: 16px;
`;

const UsersStatsText = styled.div`
  max-width: 480px;
`;

const StrategyBoardHeader = ({ children }: PropsWithChildren) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { themes, objectives } = useStrategyBoard();

  const themesCount = themes.filter((theme) => theme.theme).length;

  const {
    strategyObjectivesActiveCount,
    strategyMetricsActiveCount,
    strategyInitiativesActiveCount,
    hasStats,
    statsActive,
    statDone,
  } = useMemo(() => getStats(objectives, theme, t), [objectives, t, theme]);

  return (
    <Flex
      direction={'column'}
      gap={12}
      css={css({ flex: '1', padding: '24px' })}
    >
      <Flex justifyContent={'space-between'} alignItems={'center'} gap={8}>
        <Heading level={3} hasMargin={false} css={css({ flex: '1' })}>
          {t('strategy.strategyBoard.header.heading')}
        </Heading>

        {children}
      </Flex>

      <UsersStats>
        <UsersStatsText>
          <Trans
            i18nKey={
              hasStats
                ? 'strategy.strategyBoard.header.nrOfStats'
                : 'strategy.strategyBoard.header.noStats'
            }
            values={{
              objectivesCount: strategyObjectivesActiveCount,
              themesCount,
              metricsCount: strategyMetricsActiveCount,
              initiativesCount: strategyInitiativesActiveCount,
            }}
          />
        </UsersStatsText>
        <Stats
          stats={statsActive}
          css={css`
            flex-basis: 460px;
            margin-left: auto;
          `}
        />
        <Stat stat={statDone} />
      </UsersStats>

      <StrategyBoardFiltersChipGroup />
    </Flex>
  );
};

export default StrategyBoardHeader;
