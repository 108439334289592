import ActiveOrgProvider from 'org/ActiveOrgProvider';
import SetActiveOrgLanguage from 'org/SetActiveOrgLanguage';
import TeamLayout from 'team/TeamLayout';
import InitialActiveOrgKeyProvider from 'org/InitialActiveOrgKeyProvider';
import NavigateToTeamRoot from 'team/NavigateToTeamRoot';
import OrgUnitProvider from 'orgUnit/OrgUnitProvider';
import OrgTermsProvider from 'org/OrgTermsProvider';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';

const TeamsRoot = () => (
  <InitialActiveOrgKeyProvider>
    <ActiveOrgProvider>
      <OrgTermsProvider>
        <OrgUnitProvider
          pathsToSkipOrgUnitAccessCheck={[objectiveRoutesPaths.root()]}
        >
          <SetActiveOrgLanguage>
            <TeamLayout>
              <NavigateToTeamRoot />
            </TeamLayout>
          </SetActiveOrgLanguage>
        </OrgUnitProvider>
      </OrgTermsProvider>
    </ActiveOrgProvider>
  </InitialActiveOrgKeyProvider>
);

export default TeamsRoot;
