import { Fragment } from 'react';
import cn from 'classnames';

import Space from 'shared/components/Space';
import { ReactComponent as ArrowRightIcon } from 'shared/static/icons/icon-arrow-right.svg';
import { useSteps } from 'shared/components/Steps/useSteps';
import StepsButton from 'shared/components/Steps/StepsButton';
import { submitForm } from 'shared/form/form.utils';

import type { StepsNavigationProps } from '../StepsNavigation';
import styles from './StepsNavigationButtons.module.scss';

type StepsNavigationButtonsProps = Pick<StepsNavigationProps, 'onClickStep'> & {
  className?: string;
};

const StepsNavigationButtons = ({
  onClickStep,
  className,
}: StepsNavigationButtonsProps) => {
  const { steps, setActiveStep, activeStep } = useSteps();

  const renderSeparator = () => (
    <ArrowRightIcon className={styles.stepsNavigationSeparator} />
  );

  return (
    <Space className={cn(styles.stepsNavigation, className)}>
      {steps.map((step, stepIndex) => (
        <Fragment key={stepIndex}>
          {stepIndex !== 0 && renderSeparator()}
          <StepsButton
            disabled={step.isDisabled}
            onClick={() => {
              setActiveStep(step.id);
              if (activeStep?.form && step?.id !== activeStep?.id) {
                submitForm(activeStep?.form);
              }
              onClickStep?.(step);
            }}
            step={step}
            key={step.id}
          />
        </Fragment>
      ))}
    </Space>
  );
};

export default StepsNavigationButtons;
