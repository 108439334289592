import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { InitialStep } from 'shared/components/Steps';
import Steps from 'shared/components/Steps';

type InsightReportWizardStepsProps = PropsWithChildren<object>;

export const InsightReportWizardSteps = ({
  children,
}: InsightReportWizardStepsProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'report.insightReportWizard',
  });

  const initialSteps = useMemo<InitialStep[]>(
    () => [
      {
        id: 'objectives',
        name: t('objectives.name'),
      },
      {
        id: 'statusUpdate',
        name: t('statusUpdate.name'),
      },
      {
        id: 'successes',
        name: t('successes.name'),
        form: 'successesForm',
      },
      {
        id: 'challenges',
        name: t('challenges.name'),
        form: 'challengesForm',
      },
      {
        id: 'actions',
        name: t('actions.name'),
        form: 'actionsForm',
      },
    ],
    [t],
  );

  return <Steps initialSteps={initialSteps}>{children}</Steps>;
};

export default InsightReportWizardSteps;
