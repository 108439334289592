import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { useObjectiveActions } from 'actions/ObjectiveActions/ObjectiveActionsProvider/useObjectiveActions';
import ActionsLayout from 'actions/ActionsLayout';
import ObjectiveActionsProvider from 'actions/ObjectiveActions/ObjectiveActionsProvider';
import Spinner from 'shared/spinner/Spinner';

const ObjectiveActions = () => {
  const { objective, isObjectiveActionsLoading } = useObjectiveActions();

  const objectives = useMemo(() => [objective].filter(Boolean), [objective]);

  return (
    <>
      {isObjectiveActionsLoading ? (
        <Spinner.Circle />
      ) : (
        <ActionsLayout
          objectives={objectives}
          context={'objective'}
          showCalendarControls={false}
        />
      )}
      <Outlet />
    </>
  );
};

export default () => (
  <ObjectiveActionsProvider>
    <ObjectiveActions />
  </ObjectiveActionsProvider>
);
