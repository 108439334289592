import type { AriaButtonProps } from 'react-aria';
import { useButton } from 'react-aria';
import type { RefObject, ComponentType, SVGProps } from 'react';
import { forwardRef } from 'react';
import cn from 'classnames';
import { css, useTheme } from '@emotion/react';

import { ReactComponent as TriangleIcon } from 'shared/static/icons/icon-triangle.svg';
import Space from 'shared/components/Space';
import type { Size } from 'shared/types/size.type';

import styles from './SelectOverlayTrigger.module.scss';

export type SelectOverlayTriggerProps = AriaButtonProps & {
  className?: string;
  fullWidth?: boolean;
  hasError?: boolean;
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
  iconColor?: 'primary' | 'secondary';
  iconSize?: Extract<Size, 'small' | 'medium' | 'big'>;
  size?: Extract<Size, 'small' | 'medium'>;
};

/**
 * Allows a user to open a [selection component](https://react-spectrum.adobe.com/react-stately/selection.html) overlay.
 */
const SelectOverlayTrigger = forwardRef<
  HTMLButtonElement,
  SelectOverlayTriggerProps
>((props, ref) => {
  const theme = useTheme();

  const {
    className,
    children,
    size = 'medium',
    icon: Icon = TriangleIcon,
    iconColor = 'primary',
    iconSize = 'small',
    fullWidth,
    ...ariaButtonProps
  } = props;

  const { buttonProps } = useButton(
    {
      ...ariaButtonProps,
      type: 'button',
    },
    ref as RefObject<HTMLButtonElement>,
  );

  return (
    <button
      {...buttonProps}
      className={cn(
        styles.selectOverlayTrigger,
        {
          [styles.selectOverlayTriggerSizeSmall]: size === 'small',
          [styles.selectOverlayTriggerSizeMedium]: size === 'medium',
          [styles.selectOverlayTriggerExpanded]: buttonProps['aria-expanded'],
          [styles.selectOverlayTriggerDisabled]: buttonProps.disabled,
          [styles.selectOverlayTriggerFullWidth]: fullWidth,
        },
        className,
      )}
      ref={ref}
      css={css`
        :focus-visible {
          box-shadow: 0 0 0 2px ${theme.color.primary} !important;
        }
      `}
      aria-invalid={props.hasError}
    >
      <Space size={size} isCentered={true} gap={8}>
        {children}
        <Space css={css({ flexGrow: 1 })} />
        <Icon
          className={cn(styles.selectOverlayTriggerIcon, {
            [styles.selectOverlayTriggerIconMedium]: iconSize === 'medium',
            [styles.selectOverlayTriggerIconBig]: iconSize === 'big',
            [styles.selectOverlayTriggerIconColorSecondary]:
              iconColor === 'secondary',
          })}
        />
      </Space>
    </button>
  );
});

export default SelectOverlayTrigger;
