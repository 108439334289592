import { useNavigate } from 'react-router-dom';

import EditStrategyWizard from 'strategy/EditStrategyWizard';
import { useStrategy } from 'strategy/StrategyProvider';
import { useActiveOrg } from 'org/ActiveOrgProvider';

const StrategyRoadmapEditStrategy = () => {
  const navigate = useNavigate();

  const { strategy } = useStrategy();

  const { activeOrg } = useActiveOrg();

  const handleCloseWizard = () => navigate('../');

  if (!strategy) return null;

  return (
    <EditStrategyWizard
      strategy={strategy}
      isOpen={true}
      onSuccess={handleCloseWizard}
      onClose={handleCloseWizard}
      org={activeOrg}
    />
  );
};

export default StrategyRoadmapEditStrategy;
