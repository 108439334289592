import type { CSSProperties } from 'react';

import styles from './RoadmapItemBackgroundTilePin.module.scss';

type RoadmapItemBackgroundTilePinProps = {
  verticalOffset?: CSSProperties['top'];
};

const RoadmapItemBackgroundTilePin = ({
  verticalOffset,
}: RoadmapItemBackgroundTilePinProps) => (
  <div
    className={styles.container}
    style={{
      ...(verticalOffset && {
        top: verticalOffset,
      }),
    }}
  />
);

export default RoadmapItemBackgroundTilePin;
