import { getInitiativeIcon } from 'initiative/initiative.utils';
import { getMetricIcon } from 'metric/metric.utils';
import { getObjectiveIcon } from 'objective/objective.utils';
import { getActionIcon } from 'actions/action.utils';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';

import type { ContributionMyFollowUpsStatusRequestFragment } from '../ContributionMyFollowUpsViewMore.graphql.generated';

export const getShareProgressButtonTargetPath = (
  statusRequest: ContributionMyFollowUpsStatusRequestFragment,
  teamSlug: string,
) => {
  switch (statusRequest.strategyElement.__typename) {
    case 'Objective':
      return;
    case 'Metric':
      return;
    case 'Initiative':
      return;
    case 'Action':
      if (statusRequest.strategyElement.objective) {
        return objectiveRoutesPaths.actions.action.root({
          params: {
            actionId: statusRequest.strategyElement.id,
            objectiveId: statusRequest.strategyElement.objective.id,
            teamSlug,
          },
        });
      }
  }
};

export const getStatusRequestTargetLinkPath = (
  statusRequest: ContributionMyFollowUpsStatusRequestFragment,
  teamSlug: string,
) => {
  switch (statusRequest.strategyElement.__typename) {
    case 'Objective':
      return objectiveRoutesPaths.root({
        params: {
          objectiveId: statusRequest.strategyElement.id,
          teamSlug,
        },
      });
    case 'Metric':
      if (statusRequest.strategyElement.objective) {
        return objectiveRoutesPaths.metrics.metric.root({
          params: {
            metricId: statusRequest.strategyElement.id,
            objectiveId: statusRequest.strategyElement.objective.id,
            teamSlug,
          },
        });
      }
      break;
    case 'Initiative':
      if (statusRequest.strategyElement.objective) {
        return objectiveRoutesPaths.initiatives.initiative.root({
          params: {
            initiativeId: statusRequest.strategyElement.id,
            objectiveId: statusRequest.strategyElement.objective.id,
            teamSlug,
          },
        });
      }
      break;
    case 'Action':
      if (statusRequest.strategyElement.objective) {
        return objectiveRoutesPaths.actions.action.root({
          params: {
            actionId: statusRequest.strategyElement.id,
            objectiveId: statusRequest.strategyElement.objective.id,
            teamSlug,
          },
        });
      }
  }
};

export const getIcon = (
  statusRequest: ContributionMyFollowUpsStatusRequestFragment,
) => {
  switch (statusRequest.strategyElement.__typename) {
    case 'Objective':
      return getObjectiveIcon();
    case 'Metric':
      return getMetricIcon();
    case 'Initiative':
      return getInitiativeIcon();
    case 'Action':
      return getActionIcon();
  }
};

export const getStatusIndicator = (
  statusRequest: ContributionMyFollowUpsStatusRequestFragment,
) => {
  switch (statusRequest.strategyElement.__typename) {
    case 'Objective':
      return statusRequest.strategyElement.currentObjectiveStatus
        ?.statusIndicator;
    case 'Metric':
      return statusRequest.strategyElement.currentMetricStatus?.statusIndicator;
    case 'Initiative':
      return statusRequest.strategyElement.currentInitiativeStatus
        ?.statusIndicator;
    case 'Action':
      return statusRequest.strategyElement.currentStatus?.statusIndicator;
  }
};
