import type { TypePolicies } from '@apollo/client';

import { readDateField, readDateTimeField } from 'shared/graphql/utils';

export const timeLineTypePolicies: TypePolicies = {
  TimeLine: {
    merge: true,
    fields: {
      completeDateTime: {
        read: readDateTimeField,
      },
      endDate: {
        read: readDateField,
      },
      startDate: {
        read: readDateField,
      },
    },
  },
};
