import { useDefinedContext } from 'shared/utils/context.utils';

import { ObjectiveRelatedObjectivesContext } from './ObjectiveRelatedObjectivesProvider.context';
import ObjectiveRelatedObjectivesProvider from './ObjectiveRelatedObjectivesProvider';

export const useRelatedObjectives = () =>
  useDefinedContext(ObjectiveRelatedObjectivesContext, {
    hookName: useRelatedObjectives.name,
    providerName: ObjectiveRelatedObjectivesProvider.name,
  });
