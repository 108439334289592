import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import GlobalSpinner from 'shared/spinner/GlobalSpinner';
import useHandleApolloError from 'shared/errors/useHandleApolloError';

import type { UserProviderContextValue } from './UserProvider.context';
import { UserProviderContext } from './UserProvider.context';
import { useCurrentUserQuery } from './UserProvider.graphql.generated';

type UserProviderProps = PropsWithChildren<object>;

const UserProvider = ({ children }: UserProviderProps) => {
  const onError = useHandleApolloError();

  const { data } = useCurrentUserQuery({ onError });

  const contextValue = useMemo<UserProviderContextValue | undefined>(
    () =>
      data
        ? {
            user: data.currentUser,
            userOrgUnits: data.currentUser.accessControl.orgUnitAccessList.map(
              (orgUnitAccess) => orgUnitAccess.orgUnit,
            ),
          }
        : undefined,
    [data],
  );

  if (!contextValue) return <GlobalSpinner />;

  return (
    <UserProviderContext.Provider value={contextValue}>
      {children}
    </UserProviderContext.Provider>
  );
};

export default UserProvider;
