import type { PropsWithChildren } from 'react';
import { css } from '@emotion/react';

import UserNavbar from 'user/UserNavbar';

import LayoutProvider from './LayoutProvider';
import LayoutHeader from './LayoutHeader';
import LayoutContent from './LayoutContent';
import LayoutFooter from './LayoutFooter';
import Flex from '../Flex';

const Layout = ({ children }: PropsWithChildren) => {
  return (
    <Flex
      direction={'column'}
      alignItems={'stretch'}
      css={css`
        height: 100vh;
        overflow-x: hidden;
      `}
    >
      <div
        css={css`
          scrollbar-gutter: stable;
        `}
      >
        <UserNavbar />
      </div>

      <Flex
        direction={'column'}
        justifyContent={'space-between'}
        grow={1}
        css={css`
          overflow-y: auto;
          scrollbar-gutter: stable;
        `}
      >
        {children}

        <LayoutFooter />
      </Flex>
    </Flex>
  );
};

const LayoutWithProvider = (props: PropsWithChildren) => (
  <LayoutProvider>
    <Layout {...props} />
  </LayoutProvider>
);

LayoutWithProvider.Header = LayoutHeader;
LayoutWithProvider.Content = LayoutContent;
LayoutWithProvider.Footer = LayoutFooter;

export default LayoutWithProvider;
