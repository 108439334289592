import type { Node, TreeState } from 'react-stately';
import { useRef } from 'react';
import { useMenuItem } from 'react-aria';
import cn from 'classnames';

import type { BaseMenuItem } from '../Menu.type';
import styles from './MenuRowContainer.module.scss';

type MenuRowContainerProps<MenuItem extends BaseMenuItem> = {
  item: Node<MenuItem>;
  state: TreeState<MenuItem>;
};

const MenuRowContainer = <MenuItem extends BaseMenuItem>({
  item,
  state,
}: MenuRowContainerProps<MenuItem>) => {
  const menuRowContainerRef = useRef<HTMLLIElement>(null);

  const { menuItemProps, isFocused, isDisabled } = useMenuItem(
    {
      key: item.key,
    },
    state,
    menuRowContainerRef,
  );

  return (
    <li
      {...menuItemProps}
      role={'menuitem'}
      className={cn(styles.menuRowContainer, {
        [styles.menuRowContainerFocused]: isFocused,
        [styles.menuRowContainerDisabled]: isDisabled,
      })}
      ref={menuRowContainerRef}
    >
      {item.rendered}
    </li>
  );
};

export default MenuRowContainer;
