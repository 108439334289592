import cn from 'classnames';
import type { ComponentType, MouseEventHandler, SVGProps } from 'react';
import { useMemo, useCallback, useRef } from 'react';

import IconButton from 'shared/components/IconButton';

import styles from './Toggle.module.scss';

const CONTAINER_PADDING = 4;

type ToggleProps = {
  firstChecked: boolean;
  firstIcon: ComponentType<SVGProps<SVGSVGElement>>;
  handleToggle: () => void;
  secondIcon: ComponentType<SVGProps<SVGSVGElement>>;
};

const Toggle = ({
  firstIcon: FirstIcon,
  secondIcon: SecondIcon,
  handleToggle,
  firstChecked,
}: ToggleProps) => {
  const firstButtonRef = useRef<HTMLButtonElement | null>(null);
  const secondButtonRef = useRef<HTMLButtonElement | null>(null);

  const buttonRef = useMemo(
    () => (firstChecked ? firstButtonRef : secondButtonRef),
    [firstChecked],
  );

  const setActiveBackgroundPosition = useCallback(
    (el: HTMLDivElement) => {
      if (buttonRef.current) {
        el.style?.setProperty(
          '--left',
          `${buttonRef.current.offsetLeft - CONTAINER_PADDING}px`,
        );
      }
    },
    [buttonRef],
  );

  const setInitialBackgroundPosition = useCallback(
    (el: HTMLDivElement) => {
      if (el) {
        setActiveBackgroundPosition(el);
      }
    },
    [setActiveBackgroundPosition],
  );

  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    setActiveBackgroundPosition(e.currentTarget);
    handleToggle();
  };

  return (
    <>
      <div
        className={styles.container}
        ref={setInitialBackgroundPosition}
        onClick={handleClick}
      >
        <IconButton
          icon={FirstIcon}
          ref={firstButtonRef}
          className={cn(styles.button, {
            [styles.active]: firstChecked,
            [styles.inactive]: !firstChecked,
          })}
        />
        <IconButton
          icon={SecondIcon}
          ref={secondButtonRef}
          className={cn(styles.button, {
            [styles.active]: !firstChecked,
            [styles.inactive]: firstChecked,
          })}
        />
        <div className={styles.activeBackground} />
      </div>
    </>
  );
};

export default Toggle;
