import { createContext } from 'react';

import type { EditStrategyWizardStrategyQuery } from './EditStrategyWizardProvider.graphql.generated';

export type EditStrategyWizardProviderContextValue = {
  strategy: EditStrategyWizardStrategyQuery['strategy'];
};

export const EditStrategyWizardProviderContext = createContext<
  EditStrategyWizardProviderContextValue | undefined
>(undefined);
