import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import IconButton from 'shared/components/IconButton';
import { ReactComponent as ArrowLeftIcon } from 'shared/static/icons/icon-arrow-left.svg';
import Heading from 'shared/components/Heading';
import { ReactComponent as ArrowRightIcon } from 'shared/static/icons/icon-arrow-right.svg';
import TextButton from 'shared/components/TextButton';
import { date } from 'shared/services/date.service';

const CalendarControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;

const YearSwitcher = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1em;
`;

const Year = styled(Heading)`
  align-items: center;
  background-color: ${({ theme }) => theme.color.backgroundDark};
  border-radius: 8px;
  display: flex;
  height: 32px;
  justify-content: center;
  line-height: normal;
  white-space: nowrap;
  width: 60px;
`;

export type CalendarControlsProps = {
  className?: string;
  onYearChange: (newYear: number) => void;
  year: number;
};

const CalendarControls = ({
  year,
  onYearChange,
  className,
}: CalendarControlsProps) => {
  const { t } = useTranslation();

  const currentYear = date.getYear(new Date());

  const isCurrentYear = year === currentYear;

  return (
    <CalendarControlsContainer className={className}>
      <YearSwitcher>
        <IconButton
          variant={'outlined'}
          icon={ArrowLeftIcon}
          onClick={() => {
            onYearChange(year - 1);
          }}
        />
        <Year level={3} as={5} hasMargin={false}>
          {year}
        </Year>
        <IconButton
          variant={'outlined'}
          icon={ArrowRightIcon}
          onClick={() => {
            onYearChange(year + 1);
          }}
        />
        {!isCurrentYear && (
          <TextButton onPress={() => onYearChange(date.getYear(new Date()))}>
            {t('strategy.strategyInsights.controls.returnToThisYear')}
          </TextButton>
        )}
      </YearSwitcher>
    </CalendarControlsContainer>
  );
};

export default CalendarControls;
