import type { TypePolicies } from '@apollo/client';

import { readDateTimeField } from 'shared/graphql/utils';

export const followUpsTypePolicies: TypePolicies = {
  StatusRequest: {
    fields: {
      deadline: {
        read: readDateTimeField,
      },
      requestSubmitTime: {
        read: readDateTimeField,
      },
    },
  },
};
