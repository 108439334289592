import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserLimitedProfileFragmentDoc } from '../../../user/userProfile.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ObjectiveOverviewQueryVariables = Types.Exact<{
  objectiveId: Types.Scalars['ID'];
}>;


export type ObjectiveOverviewQuery = { __typename: 'Query', objective: { __typename: 'Objective', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, childObjectives: Array<{ __typename: 'Objective', id: string }>, actions: Array<{ __typename: 'Action', id: string }>, metrics: Array<{ __typename: 'Metric', id: string, name?: string | undefined, currentMetricStatus?: { __typename: 'MetricStatus', id: string, complete: boolean, statusIndicator: StatusIndicatorClass } | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined }>, initiatives: Array<{ __typename: 'Initiative', id: string, name?: string | undefined, currentInitiativeStatus?: { __typename: 'InitiativeStatus', id: string, complete: boolean, statusIndicator: StatusIndicatorClass } | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined }> } };

export type ObjectiveOverviewObjectiveFragment = { __typename: 'Objective', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, childObjectives: Array<{ __typename: 'Objective', id: string }>, actions: Array<{ __typename: 'Action', id: string }>, metrics: Array<{ __typename: 'Metric', id: string, name?: string | undefined, currentMetricStatus?: { __typename: 'MetricStatus', id: string, complete: boolean, statusIndicator: StatusIndicatorClass } | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined }>, initiatives: Array<{ __typename: 'Initiative', id: string, name?: string | undefined, currentInitiativeStatus?: { __typename: 'InitiativeStatus', id: string, complete: boolean, statusIndicator: StatusIndicatorClass } | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined }> };

export const ObjectiveOverviewObjectiveFragmentDoc = gql`
    fragment objectiveOverviewObjective on Objective {
  id
  name
  currentUserAuthorizedActions
  childObjectives {
    id
  }
  actions {
    id
  }
  metrics {
    id
    name
    currentMetricStatus {
      id
      complete
      statusIndicator
    }
    owner {
      ...userLimitedProfile
    }
  }
  initiatives {
    id
    name
    currentInitiativeStatus {
      id
      complete
      statusIndicator
    }
    owner {
      ...userLimitedProfile
    }
  }
}
    ${UserLimitedProfileFragmentDoc}`;
export const ObjectiveOverviewDocument = gql`
    query ObjectiveOverview($objectiveId: ID!) {
  objective(id: $objectiveId) {
    ...objectiveOverviewObjective
  }
}
    ${ObjectiveOverviewObjectiveFragmentDoc}`;

/**
 * __useObjectiveOverviewQuery__
 *
 * To run a query within a React component, call `useObjectiveOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectiveOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectiveOverviewQuery({
 *   variables: {
 *      objectiveId: // value for 'objectiveId'
 *   },
 * });
 */
export function useObjectiveOverviewQuery(baseOptions: Apollo.QueryHookOptions<ObjectiveOverviewQuery, ObjectiveOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectiveOverviewQuery, ObjectiveOverviewQueryVariables>(ObjectiveOverviewDocument, options);
      }
export function useObjectiveOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectiveOverviewQuery, ObjectiveOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectiveOverviewQuery, ObjectiveOverviewQueryVariables>(ObjectiveOverviewDocument, options);
        }
export type ObjectiveOverviewQueryHookResult = ReturnType<typeof useObjectiveOverviewQuery>;
export type ObjectiveOverviewLazyQueryHookResult = ReturnType<typeof useObjectiveOverviewLazyQuery>;
export type ObjectiveOverviewQueryResult = Apollo.QueryResult<ObjectiveOverviewQuery, ObjectiveOverviewQueryVariables>;