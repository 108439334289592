import type { Column, ItemColumn } from '../Roadmap.type';
import type { RoadmapItemSize } from './RoadmapItem.type';
import { roadmapConfig } from '../Roadmap.config';

const { columnSizes, spacerSizes } = roadmapConfig;

type SizesLookupKey = `start${Column}end${Column}`;

const sizesLookup: Partial<Record<SizesLookupKey, RoadmapItemSize>> = {
  start1end3: {
    backgroundWidth: columnSizes[5],
  },
  start2end2: {
    backgroundWidth: columnSizes[2],
    spacerWidth: spacerSizes.medium,
  },
  start3end1: {
    backgroundWidth: columnSizes[3],
    spacerWidth: spacerSizes.small,
  },
  start3end2: {
    backgroundWidth: columnSizes[5],
  },
  start4end3: {
    backgroundWidth: columnSizes[5],
  },
  start5end4: {
    backgroundWidth: columnSizes[6],
  },
  start5end5: {
    backgroundWidth: columnSizes[5],
    spacerWidth: spacerSizes.small,
  },
};

export const getSize = (column: ItemColumn): RoadmapItemSize => {
  const start = column.start;
  const end = column.end;
  const lookupKey: SizesLookupKey = `start${start}end${end}`;
  return sizesLookup[lookupKey] as RoadmapItemSize;
};
