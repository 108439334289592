import type { ElementType, PropsWithChildren } from 'react';
import { forwardRef, memo } from 'react';
import { motion } from 'framer-motion';
import { css, useTheme } from '@emotion/react';

import type { CaptionColor, CaptionSize } from './Caption.type';
import { getCaptionElement } from './Caption.utils';

type CaptionProps = PropsWithChildren<{
  asSize?: CaptionSize;
  className?: string;
  color?: CaptionColor;
  size: CaptionSize;
}>;

const styleBySize = {
  C1: {
    fontSize: '1rem',
    lineHeight: '1.714rem',
  },
  C2: {
    fontSize: '.857rem',
    lineHeight: '1.429rem',
  },
  C3: {
    fontSize: '.714rem',
    lineHeight: '1.143rem',
  },
};

const Caption = forwardRef<HTMLHeadingElement, CaptionProps>(
  (
    { size, color = 'primary', children, className, asSize, ...restProps },
    ref,
  ) => {
    const theme = useTheme();
    const Component = getCaptionElement(size) as ElementType;
    const style = styleBySize[asSize ?? size];

    return (
      <Component
        ref={ref}
        color={color}
        className={className}
        css={css`
          font-family: 'Sofia Pro', sans-serif;
          margin: 0;
          text-transform: uppercase;
          font-size: ${style.fontSize};
          line-height: ${style.lineHeight};
          color: ${color === 'primary'
            ? theme.color.typoPrimary
            : theme.color.typoSecondary};
        `}
        {...restProps}
      >
        {children}
      </Component>
    );
  },
);

export default memo(motion(Caption, { forwardMotionProps: true }));
