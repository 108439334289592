import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import useHandleApolloError from 'shared/errors/useHandleApolloError';

import type { SelectUserTenantInputProviderContextValue } from './SelectUserTenantInputProvider.context';
import { SelectUserTenantInputProviderContext } from './SelectUserTenantInputProvider.context';
import { useSelectUserTenantInputQuery } from './SelectUserTenantInputProvider.graphql.generated';

type SelectUserTenantInputProviderProps = PropsWithChildren<object>;

const SelectUserTenantInputProvider = ({
  children,
}: SelectUserTenantInputProviderProps) => {
  const onError = useHandleApolloError();

  const { data } = useSelectUserTenantInputQuery({ onError });

  const contextValue =
    useMemo<SelectUserTenantInputProviderContextValue>(() => {
      const options =
        data?.allTenantsForCurrentUser.map((tenant) => ({
          id: tenant.id,
          value: tenant,
        })) || [];

      return { options };
    }, [data]);

  return (
    <SelectUserTenantInputProviderContext.Provider value={contextValue}>
      {children}
    </SelectUserTenantInputProviderContext.Provider>
  );
};

export default SelectUserTenantInputProvider;
