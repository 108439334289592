import Layout from 'shared/components/Layout';

import styles from './ObjectiveOverview.module.scss';
import ObjectiveOverviewHeader from './ObjectiveOverviewHeader';
import ObjectiveOverviewContent from './ObjectiveOverviewContent/ObjectiveOverviewContent';
import ObjectiveOverviewProvider from './ObjectiveOverviewProvider/ObjectiveOverviewProvider';

const ObjectiveOverview = () => (
  <Layout.Content className={styles.layout} variant={'contained'}>
    <ObjectiveOverviewHeader />
    <ObjectiveOverviewContent />
  </Layout.Content>
);

export default () => (
  <ObjectiveOverviewProvider>
    <ObjectiveOverview />
  </ObjectiveOverviewProvider>
);
