import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { contributionsRoutes } from 'contribution/contribution.routing';
import { authRoutes } from 'auth/auth.routing';
import UserProvider from 'user/UserProvider';
import { routesPaths } from 'App.routing.paths';
import { teamRoutes } from 'team/team.routing';
import { toolkitRoutes } from 'toolkit/toolkit.routing';
import AuthGuard from 'auth/AuthGuard';
import TeamsRoot from 'team/TeamsRoot';
import ErrorPageProvider from 'shared/errors/ErrorPage/ErrorPageProvider';

import VersionPage from './VersionPage';
import AppErrorBoundary from './App.errorBoundary';

export const routes: RouteObject[] = [
  {
    path: '*',
    element: <Navigate to={routesPaths.teams.root()} replace={true} />,
  },
  {
    path: '/version',
    element: <VersionPage />,
  },
  {
    path: routesPaths.teams.root(),
    element: (
      <ErrorPageProvider>
        <AuthGuard forLoggedInOnly={true}>
          <UserProvider>
            <TeamsRoot />
          </UserProvider>
        </AuthGuard>
      </ErrorPageProvider>
    ),
    children: [...teamRoutes, ...contributionsRoutes, ...toolkitRoutes],
    errorElement: (
      <ErrorPageProvider>
        <AppErrorBoundary />
      </ErrorPageProvider>
    ),
  },
  ...authRoutes,
];
