import type { Context } from 'react';
import { useContext } from 'react';

export const useDefinedContext = <ContextValue>(
  context: Context<ContextValue | undefined>,
  options: {
    hookName?: string;
    providerName?: string;
  } = {},
) => {
  const { hookName, providerName } = options;
  const contextValue = useContext(context);
  if (!contextValue) {
    const message = `${hookName || 'useContext'} should not be used outside ${
      providerName || 'its provider'
    }`;
    throw new Error(message);
  }
  return contextValue;
};
