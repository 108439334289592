import { createContext } from 'react';

import type { ObjectiveInitiativesObjectiveFragment } from './ObjectiveInitiativesProvider.graphql.generated';

export type ObjectiveInitiativesContextValue = {
  isObjectiveInitiativesLoading: boolean;
  objective?: ObjectiveInitiativesObjectiveFragment;
};

export const ObjectiveInitiativesContext = createContext<
  ObjectiveInitiativesContextValue | undefined
>(undefined);
