import { useDefinedContext } from 'shared/utils/context.utils';

import { StrategyObjectivesActionsContext } from './StrategyActionsProvider.context';
import StrategyActionsProvider from './StrategyActionsProvider';

export const useStrategyActions = () =>
  useDefinedContext(StrategyObjectivesActionsContext, {
    hookName: useStrategyActions.name,
    providerName: StrategyActionsProvider.name,
  });
