import { useToggle } from 'react-use';

import Layout from 'shared/components/Layout';
import Spinner from 'shared/spinner/Spinner';
import UserMultiSelect from 'user/UserMultiSelect';
import type { StatusMultiSelectItemId } from 'shared/status/StatusMultiSelect';
import StatusMultiSelect from 'shared/status/StatusMultiSelect';
import type { UserMultiSelectItemId } from 'user/UserMultiSelect';
import type { PriorityMultiSelectItemId } from 'shared/priority/PriorityMultiSelect';
import PriorityMultiSelect from 'shared/priority/PriorityMultiSelect';
import CalendarControlsProvider from 'shared/components/CalendarControls/CalendarControlsProvider';
import CompletedFilterProvider from 'shared/components/CompletedFilterButton/CompletedFilterProvider';

import StrategyBoardContent from './StrategyBoardContent';
import StrategyBoardHeader from './StrategyBoardHeader';
import StrategyBoardFilters from './StrategyBoardFilters';
import { useStrategyBoard } from './StrategyBoardProvider';
import StrategyBoardProvider from './StrategyBoardProvider/StrategyBoardProvider';

const StrategyBoard = () => {
  const { isStrategyBoardLoading } = useStrategyBoard();

  const [areAllSectionsExpanded, toggleAreAllSectionsExpanded] =
    useToggle(true);

  const {
    filters: {
      statusIndicatorFilter,
      setStatusIndicatorFilter,
      userFilter,
      setUserFilter,
      priorityFilter,
      setPriorityFilter,
    },
    users,
  } = useStrategyBoard();

  if (isStrategyBoardLoading) return <Spinner.Circle />;

  return (
    <UserMultiSelect.Provider
      selectedKeys={userFilter}
      onSelectionChange={(selection) => {
        setUserFilter([...selection] as UserMultiSelectItemId[]);
      }}
      users={users}
    >
      <StatusMultiSelect.Provider
        selectedKeys={statusIndicatorFilter}
        onSelectionChange={(selection) => {
          setStatusIndicatorFilter([...selection] as StatusMultiSelectItemId[]);
        }}
      >
        <PriorityMultiSelect.Provider
          selectedKeys={priorityFilter}
          onSelectionChange={(selection) => {
            setPriorityFilter([...selection] as PriorityMultiSelectItemId[]);
          }}
        >
          <Layout.Content variant={'contained'} hasPadding={false}>
            <StrategyBoardHeader>
              <StrategyBoardFilters
                areAllSectionsExpanded={areAllSectionsExpanded}
                toggleAreAllSectionsExpanded={toggleAreAllSectionsExpanded}
              />
            </StrategyBoardHeader>

            <StrategyBoardContent
              areAllSectionsExpanded={areAllSectionsExpanded}
            />
          </Layout.Content>
        </PriorityMultiSelect.Provider>
      </StatusMultiSelect.Provider>
    </UserMultiSelect.Provider>
  );
};

export default () => (
  <CalendarControlsProvider storeToSession={true}>
    <CompletedFilterProvider storeToSession={true}>
      <StrategyBoardProvider>
        <StrategyBoard />
      </StrategyBoardProvider>
    </CompletedFilterProvider>
  </CalendarControlsProvider>
);
