import styled from '@emotion/styled';

const Container = styled.div`
  width: 100%;
  text-align: center;
`;

const Dot = styled.span`
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.legacyColor.colorAiPurple};
  margin: 0 2px;
  animation: jump 0.75s infinite;

  &:nth-child(2) {
    animation-delay: 0.15s;
  }

  &:nth-child(3) {
    animation-delay: 0.3s;
  }

  @keyframes jump {
    0%,
    100% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-5px);
    }
  }
`;

const AiLoadingIcon = () => (
  <Container>
    <Dot />
    <Dot />
    <Dot />
  </Container>
);

export default AiLoadingIcon;
