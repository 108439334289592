import { useEffect } from 'react';

import { getAiSuggestionField } from 'ai/getAiSuggestionField';
import useAddValueToArrayField from 'shared/hooks/useAddValueToArrayField';

import { useSuggestions } from './SuggestionsProvider';

type Props<Item> = {
  createItem: (suggestedValue: string) => Item;
  fieldName?: string;
  getItemValue: (item: Item) => string;
  suggestionField: string;
};

const AiSuggestionsArrayField = <Item,>({
  suggestionField,
  fieldName = suggestionField,
  createItem,
  getItemValue,
}: Props<Item>) => {
  const { selectedSuggestion } = useSuggestions();

  const { addValue } = useAddValueToArrayField(
    fieldName,
    createItem,
    getItemValue,
  );

  useEffect(() => {
    if (!selectedSuggestion) return;

    const suggestedValue = getAiSuggestionField(
      selectedSuggestion,
      suggestionField,
    );

    addValue(suggestedValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSuggestion]);

  return null;
};

export default AiSuggestionsArrayField;
