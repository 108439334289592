import { createContext } from 'react';

import type { OrgCardOrgFragment } from './OrgCardProvider.graphql.generated';

export type OrgCardContextValue = {
  org: OrgCardOrgFragment;
};

export const OrgCardContext =
  createContext<Maybe<OrgCardContextValue>>(undefined);
