import type { PropsWithChildren } from 'react';

import { useActionsLayout } from 'actions/ActionsLayout/ActionsLayoutProvider';
import type { ActionStatusMultiSelectItemId } from 'actions/ActionStatusMultiSelect';
import ActionStatusMultiSelect from 'actions/ActionStatusMultiSelect';
import type { UserMultiSelectItemId } from 'user/UserMultiSelect';
import UserMultiSelect from 'user/UserMultiSelect';
import PriorityMultiSelectProvider from 'shared/priority/PriorityMultiSelect/PriorityMultiSelectProvider';
import type { PriorityMultiSelectItemId } from 'shared/priority/PriorityMultiSelect';

type ActionsLayoutFiltersProviderProps = PropsWithChildren<object>;

const ActionsLayoutFiltersProvider = ({
  children,
}: ActionsLayoutFiltersProviderProps) => {
  const {
    userFilter,
    setUserFilter,
    statusFilter,
    setStatusFilter,
    users,
    priorityFilter,
    setPriorityFilter,
  } = useActionsLayout();

  return (
    <ActionStatusMultiSelect.Provider
      selectedKeys={statusFilter}
      onSelectionChange={(selection) => {
        setStatusFilter([...selection] as ActionStatusMultiSelectItemId[]);
      }}
    >
      <UserMultiSelect.Provider
        selectedKeys={userFilter}
        onSelectionChange={(selection) => {
          setUserFilter([...selection] as UserMultiSelectItemId[]);
        }}
        users={users}
      >
        <PriorityMultiSelectProvider
          selectedKeys={priorityFilter}
          onSelectionChange={(selection) => {
            setPriorityFilter([...selection] as PriorityMultiSelectItemId[]);
          }}
        >
          {children}
        </PriorityMultiSelectProvider>
      </UserMultiSelect.Provider>
    </ActionStatusMultiSelect.Provider>
  );
};

export default ActionsLayoutFiltersProvider;
