/* eslint-disable i18next/no-literal-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as CameraIcon } from 'shared/static/icons/icon-camera.svg';
import useFileUpload from 'shared/hooks/useFileUpload';

import Avatar from '../Avatar/Avatar';
import Button from '../Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

type Props = {
  email?: string;
  onChange: (value?: string) => void;
  value: string;
};

const AvatarInput = ({ value, onChange, email }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { openFilePicker } = useFileUpload({
    accept: 'image/*',
    objectType: 'USER_PHOTO',
    onFileUploaded: onChange,
  });

  const handleClear = () => {
    onChange(undefined);
  };

  return (
    <Container>
      <Avatar size={'huge'} photoUrl={value} email={email} />
      <Controls>
        <Button
          variant={'outlined'}
          icon={CameraIcon}
          iconPosition={'start'}
          onClick={openFilePicker}
        >
          {t('avatarInput.upload')}
        </Button>
        <Button
          variant={'simple'}
          css={css({ color: theme.color.error })}
          onClick={handleClear}
          disabled={!value}
        >
          {t('avatarInput.clear')}
        </Button>
      </Controls>
    </Container>
  );
};

export default AvatarInput;
