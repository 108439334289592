import cn from 'classnames';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BlueJamLogoColor } from './static/bluejam-logo-color.svg';
import { ReactComponent as BlueJamLogoMono } from './static/bluejam-logo-mono.svg';
import styles from './Logo.module.scss';

type LogoProps = {
  className?: string;
  variant?: 'color' | 'mono';
};

const Logo = ({ variant = 'color', className }: LogoProps) => {
  const { t } = useTranslation();

  const logoLabelElementId = useId();

  const Image = {
    color: BlueJamLogoColor,
    mono: BlueJamLogoMono,
  }[variant];

  return (
    <span className={cn(styles.container, className)}>
      <span id={logoLabelElementId} className={styles.label}>
        {t('appTitle')}
      </span>
      <Image
        aria-labelledby={logoLabelElementId}
        role={'img'}
        className={styles.image}
      />
    </span>
  );
};

export default Logo;
