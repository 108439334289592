import { useTranslation } from 'react-i18next';

import Caption from 'shared/components/Caption';

import type { Comment, CommentsSection } from './InsightsCommentsSection.type';
import styles from './InsightsCommentSection.module.scss';

type ObjectiveInsightsCommentSectionProps = {
  comments?: Comment[];
  context: CommentsSection;
};

const ObjectiveInsightsCommentSection = ({
  context,
  comments,
}: ObjectiveInsightsCommentSectionProps) => {
  const { t } = useTranslation();

  const hasComments = comments?.find(({ text }) => !!text);

  if (!hasComments) return null;

  return (
    <section className={styles.section}>
      <Caption size={'C2'} className={styles.caption}>
        {t(`objective.insights.recentInsights.${context}.heading`)}
      </Caption>
      {hasComments ? (
        <ul className={styles.list}>
          {comments?.map((comment) => (
            <li key={comment.id}>{comment.text}</li>
          ))}
        </ul>
      ) : (
        <span className={styles.noComments}>
          {t(`objective.insights.recentInsights.${context}.noComments`)}
        </span>
      )}
    </section>
  );
};

export default ObjectiveInsightsCommentSection;
