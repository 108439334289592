import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { css } from '@emotion/react';
import { useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';

import Button from 'shared/components/Button';
import { useToasts } from 'shared/toast/useToasts';
import { usePageTitle } from 'shared/hooks/usePageTitle';
import Space from 'shared/components/Space';
import Text from 'shared/components/Text';
import SignUpWithEmailForm, {
  signUpWithEmailFormPromiseTrackerArea,
} from 'auth/SignUpWithEmailForm';
import {
  signUpWithEmailPromiseTrackerArea,
  useSignUpWithEmail,
} from 'auth/useSignUpWithEmail';
import { useHandleUnknownError } from 'shared/errors/unknown.error';

import SignUpLayout from './SignUpLayout';
import SignUpLinkSentResult from './SignUpLinkSentResult';
import ProviderSignUpButton from './ProviderSignUpButton';

const signUpWithEmailFormId = 'signUpWithEmailForm';

const ErrorMessage = styled.div`
  margin-top: 20px;
  color: ${(props) => props.theme.color.error};
`;

const AgreementNoticeContainer = styled.div`
  align-self: center;
  font-size: 0.875;
  margin-top: 10px;
  color: ${(props) => props.theme.color.typoSecondary};
  text-align: center;
  max-width: 300px;

  a {
    color: ${(props) => props.theme.color.primary};
    text-decoration: none;

    &:hover {
      opacity: 0.75;
    }
  }
`;

const CopyrightContainer = styled.div`
  align-self: center;
  font-size: 0.875;
  margin-top: 10px;
  color: ${(props) => props.theme.color.typoSecondary};
  text-align: center;
`;

const SignUp = () => {
  const [searchParams] = useSearchParams();
  const errorMessage = searchParams.get('errorMessage');

  const handleUnknownError = useHandleUnknownError();

  const { t } = useTranslation();

  const { addToast } = useToasts();

  const { sendSignUpLink } = useSignUpWithEmail();

  const [email, setEmail] = useState('');

  const handleResendEmailLink = async () => {
    try {
      await sendSignUpLink(email);
      addToast({
        children: t('signUp.linkSentResult.newLinkSentToast'),
        variant: 'success',
      });
    } catch (error: any) {
      handleUnknownError(error);
    }
  };

  usePageTitle(t('signUp.pageTitle'));

  const { promiseInProgress: isSignUpWithEmailFormPromiseInProgress } =
    usePromiseTracker({
      area: signUpWithEmailFormPromiseTrackerArea,
    });
  const { promiseInProgress: isSignUpWithEmailPromiseInProgress } =
    usePromiseTracker({
      area: signUpWithEmailPromiseTrackerArea,
    });

  if (email) {
    return (
      <SignUpLinkSentResult
        email={email}
        isNewLinkButtonDisabled={isSignUpWithEmailPromiseInProgress}
        onSendNewLink={handleResendEmailLink}
        onGoBack={() => setEmail('')}
      />
    );
  }

  return (
    <>
      <SignUpLayout
        heading={t('signUp.heading')}
        description={t('signUp.description')}
      >
        <SignUpWithEmailForm
          id={signUpWithEmailFormId}
          onSubmit={sendSignUpLink}
          onSuccess={setEmail}
        />
        <Space direction={'vertical'} isCentered={true}>
          <Button
            type={'submit'}
            variant={'contained'}
            form={signUpWithEmailFormId}
            disabled={isSignUpWithEmailFormPromiseInProgress}
            css={css`
              width: 100%;
              height: 3.75rem;
              margin-top: 1.25rem;
            `}
          >
            {t('signUp.emailLink.submitButton')}
          </Button>
          <Text>{t('or')}</Text>
          <ProviderSignUpButton provider={'microsoft'} />
          <ProviderSignUpButton provider={'google'} />
        </Space>
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <AgreementNoticeContainer>
          <Trans i18nKey={'signUp.agreementNotice'}>
            <a
              href={'https://bluejam.io/terms-of-service/'}
              target={'_blank'}
              rel={'noreferrer'}
            />
            <a
              href={'https://bluejam.io/privacy-policy/'}
              target={'_blank'}
              rel={'noreferrer'}
            />
          </Trans>
        </AgreementNoticeContainer>
        <CopyrightContainer>{t('signUp.copyright')}</CopyrightContainer>
      </SignUpLayout>
    </>
  );
};

export default SignUp;
