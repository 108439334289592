import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import Layout from 'shared/components/Layout';
import { useTeamAdapter } from 'team/TeamAdapter';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';

import {
  getBreadcrumbsItems,
  getNavigationItems,
} from './StrategyRoomLayout.utils';

const StrategyRoomLayout = () => {
  const { t } = useTranslation();

  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const { teamAdapter } = useTeamAdapter();

  const breadcrumbsItems = getBreadcrumbsItems({
    t,
    teamAdapter,
    orgUnit,
    activeOrg,
  });

  const navigationItems = getNavigationItems(t);

  return (
    <>
      <Layout.Header
        heading={t('strategy.strategyRoom.strategyRoom')}
        breadcrumbsItems={breadcrumbsItems}
        navigationItems={navigationItems}
      />
      <Outlet />
    </>
  );
};

export default StrategyRoomLayout;
