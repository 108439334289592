import { useDefinedContext } from 'shared/utils/context.utils';

import { SelectableStrategyItemsProviderContext } from './SelectableStrategyItemsProvider.context';
import SelectableStrategyItemsProvider from './SelectableStrategyItemsProvider';

export const useSelectableStrategyItems = () =>
  useDefinedContext(SelectableStrategyItemsProviderContext, {
    hookName: useSelectableStrategyItems.name,
    providerName: SelectableStrategyItemsProvider.name,
  });
