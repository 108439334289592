import type { ReactNode } from 'react';
import { Fragment } from 'react';
import cn from 'classnames';

import type {
  BaseTreeItem,
  GetParentTreeItemFn,
  GetChildTreeItemsFn,
} from 'shared/hooks/useTree';
import { useTree } from 'shared/hooks/useTree';
import Divider from 'shared/components/Divider';

import styles from './TreeList.module.scss';
import type { RenderTreeListRowContentParams } from './TreeList.type';
import TreeListRow from './TreeListRow';

export type TreeListProps<TreeItemParam extends BaseTreeItem> = {
  allExpanded?: boolean;
  className?: string;
  expandedItemsIds?: BaseTreeItem['id'][];
  getChildItems: GetChildTreeItemsFn<TreeItemParam>;
  getParentItem: GetParentTreeItemFn<TreeItemParam>;
  items: BaseTreeItem[];
  renderRowContent: (
    params: RenderTreeListRowContentParams<TreeItemParam>,
  ) => ReactNode;
  selectedItemsIds?: TreeItemParam['id'][];
  showDividers?: { bottom: boolean; top: boolean };
};

const TreeList = <TreeItemParam extends BaseTreeItem>({
  items,
  getParentItem,
  getChildItems,
  allExpanded,
  expandedItemsIds,
  renderRowContent,
  className,
  selectedItemsIds = [],
  showDividers = { top: true, bottom: true },
}: TreeListProps<TreeItemParam>) => {
  const {
    parentItems,
    nonParentItems,
    toggleIsItemExpanded,
    isItemExpanded,
    isItemSelected,
  } = useTree<TreeItemParam>({
    items: items as any,
    getParentItem: getParentItem as any,
    allExpanded,
    expandedItemsIds,
    selectedItemsIds,
  });

  return (
    <div className={className}>
      {parentItems.map((parentItem, parentItemIndex) => {
        const lastItemIndex = parentItems.length - 1;
        const isParentItemExpanded = isItemExpanded(parentItem.id);
        const hasMoreThanOneItem = parentItems.length > 1;
        const isSiblingItemExpanded =
          hasMoreThanOneItem &&
          isItemExpanded(parentItems[parentItemIndex + 1]?.id);
        const shouldShowTopDivider = !!(
          parentItemIndex === 0 && showDividers.top
        );
        const shouldShowBottomDivider = !(
          parentItemIndex === lastItemIndex && !showDividers.bottom
        );

        return (
          <Fragment key={parentItem.id}>
            {shouldShowTopDivider && (
              <Divider
                hasMargin={false}
                className={cn(styles.divider, {
                  [styles.dividerExpanded]: isParentItemExpanded,
                })}
              />
            )}
            <TreeListRow<TreeItemParam>
              parentItem={parentItem}
              childItems={nonParentItems}
              getChildItems={getChildItems}
              isExpanded={isParentItemExpanded}
              expandedItemsIds={expandedItemsIds}
              isSelected={isItemSelected(parentItem.id)}
              onToggleIsExpanded={() => toggleIsItemExpanded(parentItem.id)}
              level={1}
              allExpanded={allExpanded}
              renderRowContent={renderRowContent}
            />
            {shouldShowBottomDivider && (
              <Divider
                hasMargin={false}
                className={cn(styles.divider, {
                  [styles.dividerExpanded]:
                    isParentItemExpanded || isSiblingItemExpanded,
                })}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default TreeList;
