import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import EmojiIcon from 'shared/components/EmojiIcon';
import Flex from 'shared/components/Flex';
import Heading from 'shared/components/Heading';

const Label = styled.div`
  color: ${(props) => props.theme.color.secondaryVariant};
  font-size: 0.857rem;
  font-weight: 700;
  text-transform: uppercase;
`;

type Props = {
  tool: 'walkOfStrategy' | 'oneThing';
  variant: 'title' | 'label';
};

const ToolIconHeader = ({ tool, variant }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Flex alignItems={'center'} gap={8}>
      <EmojiIcon
        emoji={t(`toolkit.tools.${tool}.symbol`)}
        size={'tiny'}
        css={css(
          `background-color: ${theme.legacyColor.colorLightOragne} !important`,
        )}
      />

      {variant === 'title' && (
        <Heading level={3} css={css({ margin: 0 })}>
          {t(`toolkit.tools.${tool}.name`)}
        </Heading>
      )}

      {variant === 'label' && <Label>{t(`toolkit.tools.${tool}.name`)}</Label>}
    </Flex>
  );
};

export default ToolIconHeader;
