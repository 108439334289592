import { useCallback, useState } from 'react';

export default function useTemperature(
  startTemperature: number,
  maxTemperature: number,
  increment: number,
) {
  const [temperature, setTemperature] = useState(startTemperature);

  const resetTemperature = useCallback(() => {
    setTemperature(startTemperature);
  }, [startTemperature]);

  const increaseTemperature = useCallback(() => {
    setTemperature((currentTempearture) =>
      Math.min(currentTempearture + increment, maxTemperature),
    );
  }, [increment, maxTemperature]);

  return { temperature, increaseTemperature, resetTemperature };
}
