import { css, useTheme } from '@emotion/react';
import type { ComponentType, SVGProps } from 'react';

import Tooltip from '../Tooltip';
import Button from '../Button';

type Props = {
  Icon: ComponentType<SVGProps<SVGSVGElement>>;
  activeTooltip: string;
  inactiveTooltip: string;
  isActive: boolean;
  onToggleActive: () => void;
};

const ToggleFilterButton = ({
  isActive,
  onToggleActive,
  activeTooltip,
  inactiveTooltip,
  Icon,
}: Props) => {
  const theme = useTheme();
  const tooltip = isActive ? activeTooltip : inactiveTooltip;

  return (
    <Tooltip content={tooltip}>
      <Button
        variant={'icon'}
        icon={Icon}
        onClick={onToggleActive}
        css={css`
          width: 42px;
          height: 42px;
          box-sizing: border-box;

          svg {
            width: 16px;
            height: 16px;
            color: ${isActive ? theme.color.primary : theme.color.neutral2};
          }
        `}
      />
    </Tooltip>
  );
};

export default ToggleFilterButton;
