import cn from 'classnames';
import type { PropsWithChildren, ReactNode } from 'react';
import { motion } from 'framer-motion';

import { fadeAnimation } from 'shared/animations/fade.animation';

import styles from './LayoutContent.module.scss';
import { useLayout } from '../LayoutProvider';

type LayoutContentProps = PropsWithChildren<{
  className?: string;
  hasBorder?: boolean;
  hasPadding?: boolean;
  renderSider?: () => ReactNode;
  siderClassName?: string;
  variant?: 'contained' | 'full';
}>;

const LayoutContent = ({
  renderSider,
  hasPadding = true,
  children,
  className,
  hasBorder = false,
  siderClassName,
  variant,
}: LayoutContentProps) => {
  const { background } = useLayout();

  return (
    <motion.main
      {...fadeAnimation}
      className={cn(styles.container, background?.className, {
        [styles.padding]: hasPadding,
        [styles.contained]: variant === 'contained',
        [styles.containerWithBorder]: hasBorder,
      })}
      style={{
        ...(background && {
          backgroundColor: background?.color,
          backgroundImage: `url("${background?.image}")`,
        }),
      }}
    >
      <div
        className={cn(styles.innerContainer, {
          [styles.innerContainerWithBorder]: hasBorder,
        })}
      >
        <div className={cn(styles.content, className)}>{children}</div>
        {renderSider && (
          <aside className={cn(styles.sider, siderClassName)}>
            {renderSider()}
          </aside>
        )}
      </div>
    </motion.main>
  );
};

export default LayoutContent;
