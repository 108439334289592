import { useCallback, useEffect } from 'react';
import last from 'lodash/last';
import first from 'lodash/first';

import type {
  MultiSelectProps,
  RenderLabelFn,
} from 'shared/components/MultiSelect';
import MultiSelect from 'shared/components/MultiSelect';

import ActionStatusMultiSelectProvider, {
  useActionStatusMultiSelect,
} from './ActionStatusMultiSelectProvider';
import ActionStatusMultiSelectListBoxRow from './ActionStatusMultiSelectListBoxRow';
import ActionStatusMultiSelectLabel from './ActionStatusMultiSelectLabel';
import type {
  ActionStatusMultiSelectItem,
  ActionStatusMultiSelectItemId,
} from './ActionStatusMultiSelect.type';

type ActionStatusMultiSelectProps = Pick<MultiSelectProps, 'triggerClassName'>;

const ActionStatusMultiSelect = ({
  triggerClassName,
}: ActionStatusMultiSelectProps) => {
  const { state, items } = useActionStatusMultiSelect();

  const renderLabel = useCallback<RenderLabelFn<ActionStatusMultiSelectItem>>(
    () => <ActionStatusMultiSelectLabel state={state} />,
    [state],
  );

  useEffect(() => {
    const selectedKeys = [
      ...state.selectionManager.selectedKeys,
    ] as ActionStatusMultiSelectItemId[];
    if (selectedKeys.length > 1 && last(selectedKeys) === 'ALL') {
      state.selectionManager.replaceSelection('ALL');
    }
    if (selectedKeys.length > 1 && first(selectedKeys) === 'ALL') {
      state.selectionManager.toggleSelection('ALL');
    }
  }, [state.selectionManager]);

  return (
    <MultiSelect<ActionStatusMultiSelectItem>
      state={state}
      items={items}
      ListBoxRow={ActionStatusMultiSelectListBoxRow}
      label={renderLabel}
      triggerClassName={triggerClassName}
    />
  );
};

ActionStatusMultiSelect.Provider = ActionStatusMultiSelectProvider;

export default ActionStatusMultiSelect;
