import cn from 'classnames';

import StrategyCardItem from './StrategyCardItem';
import styles from './StrategyCard.module.scss';

type StrategyCardItemType = {
  description?: string;
  items?: StrategyCardItemType[];
  title?: string;
};

type StrategyCardProps = {
  className?: string;
  description?: string;
  heading?: string;
  items?: StrategyCardItemType[];
  itemsList?: StrategyCardItemType[];
  title?: string;
};

const StrategyCard = ({
  description,
  title,
  items,
  itemsList,
  heading,
  className,
}: StrategyCardProps) => (
  <>
    <section className={cn(styles.container, className)}>
      {heading ? (
        <div className={styles.heading}>
          <span className={styles.headingText} role={'heading'} aria-level={2}>
            {heading}
          </span>
        </div>
      ) : null}
      {title && (
        <div className={styles.title}>
          <span className={styles.titleText} role={'heading'} aria-level={3}>
            {title}
          </span>
        </div>
      )}
      {description ? (
        <div className={styles.desc}>
          <span className={styles.descText} role={'heading'} aria-level={4}>
            {description}
          </span>
        </div>
      ) : null}
      {items?.length
        ? items.map((item) => (
            <StrategyCardItem
              key={`${item.title}${item.description}`}
              {...item}
            />
          ))
        : null}
      {itemsList?.length ? (
        <ul className={styles.list}>
          {itemsList.map((item) => (
            <li key={`${item.title}${item.description}`}>
              <StrategyCardItem {...item} />
            </li>
          ))}
        </ul>
      ) : null}
    </section>
  </>
);

export default StrategyCard;
