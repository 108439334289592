import { Outlet, Route, Routes } from 'react-router-dom';

import { usePageTitle } from 'shared/hooks/usePageTitle';
import Layout from 'shared/components/Layout';
import Spinner from 'shared/spinner/Spinner';
import EditInitiative from 'initiative/EditInitiative';
import CreateInitiativeStatusPage from 'initiative/CreateInitiativeStatusPage';

import styles from './InitiativeOverview.module.scss';
import InitiativeProvider, {
  useInitiativeOverview,
} from './InitiativeOverviewProvider';
import { initiativeOverviewRoutesPaths } from './InitiativeOverview.routing.paths';
import InitiativeLayoutHeader from './InitiativeOverviewLayoutHeader';
import InitiativeSider from './InitiativeOverviewSider/InitiativeOverviewSider';
import InitiativeOverviewNavigateToInsights from './InitiativeOverviewNavigateToInsights';
import InitiativeOverviewInsights from './InitiativeOverviewInsights';
import InitiativeOverviewAttributes from './InitiativeOverviewAttributes';

const InitiativeOverview = () => {
  const { initiative, isInitiativeLoading } = useInitiativeOverview();

  usePageTitle(initiative?.name);

  return (
    <>
      <InitiativeLayoutHeader />
      {isInitiativeLoading ? (
        <Spinner.Circle />
      ) : (
        <Layout.Content
          hasPadding={false}
          renderSider={() => <InitiativeSider />}
          className={styles.layout}
          variant={'contained'}
        >
          <Outlet />
        </Layout.Content>
      )}
    </>
  );
};

const InitiativeWithRouting = () => (
  <Routes>
    <Route
      path={initiativeOverviewRoutesPaths.root()}
      element={<InitiativeOverview />}
    >
      <Route index={true} element={<InitiativeOverviewNavigateToInsights />} />
      <Route
        path={initiativeOverviewRoutesPaths.insights.root()}
        element={<InitiativeOverviewInsights />}
      />
      <Route
        path={initiativeOverviewRoutesPaths.insights.submit()}
        element={<CreateInitiativeStatusPage />}
      />
      <Route
        path={initiativeOverviewRoutesPaths.attributes()}
        element={<InitiativeOverviewAttributes />}
      />
      <Route
        path={initiativeOverviewRoutesPaths.edit()}
        element={<EditInitiative />}
      />
    </Route>
  </Routes>
);

export default () => (
  <InitiativeProvider>
    <InitiativeWithRouting />
  </InitiativeProvider>
);
