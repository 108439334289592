import type { SelectThemeInputOption } from '../SelectThemeInput.type';
import type { SelectThemeInputForOrgThemeFragment } from './SelectThemeInputForOrgProvider.graphql.generated';

export const getEmptyOption = (): SelectThemeInputOption => ({
  value: null,
});

export const getOptions = (params: {
  themes: SelectThemeInputForOrgThemeFragment[];
}): SelectThemeInputOption[] => {
  const { themes } = params;
  return [
    getEmptyOption(),
    ...themes.map((theme) => ({
      value: theme,
    })),
  ];
};
