import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Heading from 'shared/components/Heading';
import Layout from 'shared/components/Layout';
import Flex from 'shared/components/Flex';

import OverviewSection from './OverviewSection';
import DistributionSection from './DistributionSection';
import EngagementSection from './EngagementSection';

const Section = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.color.strokeLight};
  border-radius: 1.333rem;
  overflow: hidden;
`;

const HeadingContainer = styled.div`
  padding: 1.333rem;
`;

const WalkOfStrategyCampaignResultsInsights = () => {
  const { t } = useTranslation();

  return (
    <Layout.Content hasPadding={false} hasBorder={false}>
      <Flex direction={'column'} gap={'1.333rem'}>
        <Section>
          <HeadingContainer>
            <Heading level={4} hasMargin={false}>
              {t(
                'toolkit.toolPage.walkOfStrategy.results.insights.section.overview.title',
              )}
            </Heading>
          </HeadingContainer>
          <OverviewSection />
        </Section>
        <Section>
          <HeadingContainer>
            <Heading level={4} hasMargin={false}>
              {t(
                'toolkit.toolPage.walkOfStrategy.results.insights.section.distribution.title',
              )}
            </Heading>
          </HeadingContainer>
          <DistributionSection />
        </Section>
        <Section>
          <HeadingContainer>
            <Heading level={4} hasMargin={false}>
              {t(
                'toolkit.toolPage.walkOfStrategy.results.insights.section.engagement.title',
              )}
            </Heading>
          </HeadingContainer>
          <EngagementSection />
        </Section>
      </Flex>
    </Layout.Content>
  );
};

export default WalkOfStrategyCampaignResultsInsights;
