import type { PropsWithChildren } from 'react';
import cn from 'classnames';
import { css } from '@emotion/react';

import Space from 'shared/components/Space';
import Text from 'shared/components/Text';
import type { StatusMarksProps } from 'shared/status/StatusMarks';
import StatusMarks from 'shared/status/StatusMarks';
import EmojiBadge from 'shared/components/EmojiBadge';

import styles from './StrategyRoadmapChip.module.scss';

export type StrategyRoadmapChipProps = PropsWithChildren<{
  className?: string;
  statusMarksItems?: StatusMarksProps['items'];
  symbol?: string;
}>;

const StrategyRoadmapChip = ({
  className,
  statusMarksItems,
  symbol,
  children,
}: StrategyRoadmapChipProps) => (
  <Space
    className={cn(styles.container, className)}
    css={symbol && css({ padding: '0.5rem 1rem 0.5rem 0.5rem', gap: 7 })}
  >
    {symbol ? <EmojiBadge>{symbol}</EmojiBadge> : null}
    <Text variant={'emphasis'} ellipsis={true}>
      {children}
    </Text>
    {statusMarksItems ? (
      <StatusMarks
        maxStatusMarksCount={15}
        hasBorder={false}
        size={'small'}
        items={statusMarksItems}
      />
    ) : null}
  </Space>
);

export default StrategyRoadmapChip;
