// import 'shared/config/whyDidYouRender.config';
import ReactDOM from 'react-dom/client';

import env from 'environment';
import { initializeDatadog } from 'shared/config/datadog.config';

import App from './App';

initializeDatadog();

const renderApp = () => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );
  root.render(<App />);
};

const setupMockApi = async () => {
  const { setupWorker } = await import('msw');
  const { handlers } = await import('./api.mock');
  return setupWorker(...handlers).start({
    onUnhandledRequest: 'bypass',
  });
};

if (env.REACT_APP_IS_MOCK_API_ENABLED && env.REACT_APP_ENV !== 'production') {
  setupMockApi().then(() => {
    renderApp();
  });
} else {
  renderApp();
}
