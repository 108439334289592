import type { PropsWithChildren, ReactNode } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';

import type { BreadcrumbsProps } from 'shared/components/Breadcrumbs';
import type { LinkTabsProps } from 'shared/components/LinkTabs';
import LinkTabs from 'shared/components/LinkTabs';
import LayoutHeaderContainer from 'shared/components/Layout/LayoutHeaderContainer';
import type { LayoutHeaderTopSectionProps } from 'shared/components/Layout';
import LayoutHeaderTopSection from 'shared/components/Layout/LayoutHeaderTopSection';
import LayoutHeaderHeading from 'shared/components/Layout/LayoutHeaderHeading';
import Space from 'shared/components/Space';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';

import styles from './ObjectiveHeader.module.scss';

export type ObjectiveHeaderProps = PropsWithChildren<{
  breadcrumbsItems?: BreadcrumbsProps['items'];
  contextMenu: ReactNode;
  description?: string;
  heading?: string | { content: string | ReactNode; isBold: boolean };
  navigationItems?: LinkTabsProps['items'];
  priority?: string;
  theme?: string;
  topRightNavItem?: Maybe<LayoutHeaderTopSectionProps['topRightLinkItem']>;
}>;

const ObjectiveHeader = ({
  heading: headingProp,
  breadcrumbsItems,
  navigationItems,
  topRightNavItem,
  description,
  contextMenu,
  children,
  theme,
  priority,
}: ObjectiveHeaderProps) => {
  const { t } = useTranslation();

  const { priorities } = useStrategyProfilePriorities();

  const { isBold, heading } = useMemo(() => {
    if (typeof headingProp === 'string') {
      return { heading: headingProp, isBold: true };
    } else {
      return { heading: headingProp?.content, isBold: false };
    }
  }, [headingProp]);

  return (
    <LayoutHeaderContainer>
      <div className={styles.content}>
        <LayoutHeaderTopSection
          breadcrumbs={breadcrumbsItems}
          topRightLinkItem={topRightNavItem}
          className={styles.topSection}
        />
        <div className={styles.mainContainer}>
          {heading && <LayoutHeaderHeading content={heading} isBold={isBold} />}
          <div className={styles.headingExtra}>
            {children}
            {contextMenu}
          </div>
        </div>
        <Space size={'custom'} value={'20px'}>
          {description && <p className={styles.secondaryText}>{description}</p>}
          <p className={styles.secondaryText}>
            {`${capitalize(t('theme.theme'))}: ${theme ?? t('theme.noTheme')}`}
          </p>
          {(priorities || priority) && (
            <p className={styles.secondaryText}>
              {`${t('priority.priority')}: ${
                priority ?? t('priority.noPriority')
              }`}
            </p>
          )}
        </Space>
        {navigationItems ? <LinkTabs items={navigationItems} /> : null}
      </div>
    </LayoutHeaderContainer>
  );
};

export default ObjectiveHeader;
