import { useTranslation } from 'react-i18next';
import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import Layout from 'shared/components/Layout';
import { useTeamAdapter } from 'team/TeamAdapter';

import { getBreadcrumbsItems } from './ToolkitLayout.utils';

type ToolkitLayoutProps = PropsWithChildren<object>;

const ToolkitLayout = ({ children }: ToolkitLayoutProps) => {
  const { t } = useTranslation();

  const { teamAdapter } = useTeamAdapter();

  const breadcrumbsItems = useMemo(
    () => getBreadcrumbsItems(teamAdapter, t),
    [t, teamAdapter],
  );

  return (
    <>
      <Layout.Header
        breadcrumbsItems={breadcrumbsItems}
        heading={t('toolkit.toolkit')}
      />
      <Layout.Content hasPadding={false}>{children}</Layout.Content>
    </>
  );
};

export default ToolkitLayout;
