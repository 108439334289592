import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import ContributionInfoContainer from 'contribution/ContributionInfoContainer';
import type { ContributionSubject } from 'contribution/contribution.type';

import {
  getObjectivesSentence,
  getOtherTeamsSentence,
  getOwnershipsSentence,
  getTeamSentence,
} from './ContributionLineOfSightSection.utils';
import type { ContributionLineOfSightSectionUserFragment } from './ContributionLineOfSightSection.graphql.generated';

const Title = styled.div`
  color: ${({ theme }) => theme.color.typoSecondary};
  font-size: 0.714rem;
  font-weight: 700;
  margin-bottom: 3px;
  text-transform: uppercase;
`;

type ContributionLineOfSightSectionProps = {
  subject: ContributionSubject;
  user: ContributionLineOfSightSectionUserFragment;
};

const ContributionLineOfSightSection = ({
  user,
  subject,
}: ContributionLineOfSightSectionProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <ContributionInfoContainer
      icon={'🙌'}
      infoMessage={t('contribution.lineOfSight.tooltip')}
    >
      <Title>{t('contribution.lineOfSight.title')}</Title>
      {getObjectivesSentence(t, theme, subject, user)}{' '}
      {getTeamSentence(t, theme, subject, user)}{' '}
      {getOtherTeamsSentence(t, theme, subject, user)}{' '}
      {getOwnershipsSentence(t, subject, user)}
    </ContributionInfoContainer>
  );
};

export default ContributionLineOfSightSection;
