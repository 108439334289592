import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';

export const getInputStyle = (theme: Theme, hasError?: boolean) => {
  return css`
    border: 1px solid ${hasError ? theme.color.error : theme.color.strokeDark};
    background-color: ${hasError ? theme.color.errorLight : theme.color.white};
    border-radius: 12px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    &[data-focused='true'] {
      border: 1px solid ${theme.color.black};

      &:focus-within {
        outline: none;
        box-shadow: 0 0 0 2px ${theme.color.primary};
      }
    }

    input {
      flex-grow: 1;
      padding: 0;
      border: none;
      padding: 0.75rem 0 0.75rem 0.75rem;
      height: 40px;
      background-color: transparent;

      &:focus-visible {
        outline: none;
      }
    }

    button {
      height: 40px;
      border: none;
      background: none;
      padding: 0;
      padding-right: 0.75rem;
      padding-left: 0.5rem;

      svg {
        height: 0.625em;
        width: 0.625em;
      }
    }
  `;
};
