import { createContext } from 'react';

import type { ThemeInput } from 'types.graphql.generated';

import type { ThemeProviderThemeFragment } from './ThemeProvider.graphql.generated';

export type ThemeContextValue = {
  deleteTheme: () => void;
  theme: Maybe<ThemeProviderThemeFragment>;
  updateTheme: (input: ThemeInput) => void;
};

export const ThemeContext = createContext<ThemeContextValue | undefined>(
  undefined,
);
