import { useDefinedContext } from 'shared/utils/context.utils';

import ScrollspyProvider from './ScrollspyProvider';
import { ScrollspyProviderContext } from './ScrollspyProvider.context';

export const useScrollspy = () =>
  useDefinedContext(ScrollspyProviderContext, {
    hookName: useScrollspy.name,
    providerName: ScrollspyProvider.name,
  });
