import type { Org, OrgAuthorizedAction } from 'types.graphql.generated';

export type OrgWithAuthorizedActions = Pick<
  Org,
  'currentUserAuthorizedActions'
>;

export function canPerformOrgAction(
  org: OrgWithAuthorizedActions,
  action: OrgAuthorizedAction,
) {
  return org.currentUserAuthorizedActions.includes(action);
}
