import type { Merge } from 'type-fest';
import type { ButtonHTMLAttributes, ComponentType, SVGProps } from 'react';
import { forwardRef } from 'react';
import cn from 'classnames';

import type { Size } from 'shared/types/size.type';

import styles from './IconButton.module.scss';

export type IconButtonProps = Merge<
  ButtonHTMLAttributes<HTMLButtonElement>,
  {
    color?: 'default' | 'black';
    icon: ComponentType<SVGProps<SVGSVGElement>>;
    isActive?: boolean;
    size?: Extract<Size, 'medium' | 'small'>;
    variant?: 'outlined' | 'transparent';
  }
>;

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      icon: Icon,
      variant = 'transparent',
      isActive = false,
      children,
      size = 'medium',
      className,
      color = 'default',
      ...restProps
    },
    ref,
  ) => {
    const title = typeof children === 'string' ? children : undefined;

    return (
      <button
        type={'button'}
        title={title}
        {...restProps}
        aria-pressed={isActive}
        ref={ref}
        className={cn(
          styles.iconButton,
          {
            [styles.iconButtonOutlined]: variant === 'outlined',
            [styles.iconButtonTransparent]: variant === 'transparent',
            [styles.iconButtonColorDefault]: color === 'default',
            [styles.iconButtonColorBlack]: color === 'black',
            [styles.iconButtonMedium]: size === 'medium',
            [styles.iconButtonSmall]: size === 'small',
            [styles.iconButtonActive]: isActive,
          },
          className,
        )}
      >
        <Icon aria-hidden={true} className={styles.iconButtonIcon} />
        <span className={styles.iconButtonContent}>{children}</span>
      </button>
    );
  },
);

export default IconButton;
