import type { ReactNode } from 'react';

import Tile from '../RoadmapItemBackgroundTile';
import type { ItemColumn, Column } from '../Roadmap.type';

type TilesLayoutLookupFn = (params: {
  isFirst: boolean;
  isLast: boolean;
}) => ReactNode;

type TilesLayoutLookupKey = `start${Column}end${Column}`;

const tilesLayoutLookup: Partial<
  Record<TilesLayoutLookupKey, TilesLayoutLookupFn>
> = {
  start1end3: ({ isLast, isFirst }) => (
    <>
      <Tile.Fixed
        column={{
          start: 1,
          end: 5,
          size: 'medium',
        }}
        isHidden={isFirst}
      />
      <Tile.Flexible
        isCutOnTop={isFirst}
        isCutOnBottom={isLast}
        minHeight={'20px'}
        maxHeight={'40px'}
        isHidden={isLast && isFirst}
        cutSize={'30px'}
      >
        <Tile.Pin verticalOffset={isLast ? '-30px' : '-25px'} />
      </Tile.Flexible>
      <Tile.Flexible isHidden={isLast} />
      <Tile.Fixed
        column={{
          start: 5,
          end: 3,
          size: 'big',
        }}
        isHidden={isLast}
      />
      <Tile.Flexible column={3} isHidden={isLast} />
    </>
  ),
  start2end2: ({ isLast, isFirst }) => (
    <>
      <Tile.Flexible minHeight={'80px'} maxHeight={'80px'} isHidden={isFirst} />
      <Tile.Flexible
        isCutOnTop={isFirst}
        isCutOnBottom={isLast}
        isHidden={isLast && isFirst}
        minHeight={'20px'}
        maxHeight={'40px'}
      >
        <Tile.Pin verticalOffset={'-10px'} />
      </Tile.Flexible>
      <Tile.Flexible isHidden={isLast} />
    </>
  ),
  start3end1: ({ isLast, isFirst }) => (
    <>
      <Tile.Flexible minHeight={'80px'} maxHeight={'80px'} isHidden={isFirst} />
      <Tile.Flexible
        isCutOnTop={isFirst}
        isCutOnBottom={isLast}
        minHeight={'40px'}
        maxHeight={'60px'}
        isHidden={isLast && isFirst}
        cutSize={'30px'}
      >
        <Tile.Pin verticalOffset={isLast ? '-10px' : '-5px'} />
      </Tile.Flexible>
      <Tile.Flexible isHidden={isLast} />
      <Tile.Fixed
        column={{
          start: 3,
          end: 1,
          size: 'medium',
        }}
        isHidden={isLast}
      />
    </>
  ),
  start3end2: ({ isLast, isFirst }) => (
    <>
      <Tile.Fixed
        column={{
          start: 3,
          end: 5,
          size: 'medium',
        }}
        isHidden={isFirst}
      />
      <Tile.Flexible
        isCutOnTop={isFirst}
        isCutOnBottom={isLast}
        minHeight={'40px'}
        maxHeight={'60px'}
        isHidden={isLast && isFirst}
        cutSize={'40px'}
      >
        <Tile.Pin verticalOffset={isLast ? '-30px' : '-25px'} />
      </Tile.Flexible>
      <Tile.Flexible isHidden={isLast} />
      <Tile.Fixed
        column={{
          start: 5,
          end: 2,
          size: 'medium',
        }}
        isHidden={isLast}
      />
      <Tile.Flexible column={2} isHidden={isLast} />
    </>
  ),
  start4end3: ({ isLast, isFirst }) => (
    <>
      <Tile.Fixed
        column={{
          start: 4,
          end: 5,
          size: 'medium',
        }}
        isHidden={isFirst}
      />
      <Tile.Flexible
        isCutOnTop={isFirst}
        isCutOnBottom={isLast}
        minHeight={'40px'}
        maxHeight={'60px'}
        cutSize={'30px'}
        isHidden={isLast && isFirst}
      >
        <Tile.Pin verticalOffset={isLast ? '-30px' : '-25px'} />
      </Tile.Flexible>
      <Tile.Flexible isHidden={isLast} />
      <Tile.Fixed
        column={{
          start: 5,
          end: 3,
          size: 'medium',
        }}
        isHidden={isLast}
      />
    </>
  ),
  start5end4: ({ isLast, isFirst }) => (
    <>
      <Tile.Fixed
        column={{
          start: 5,
          end: 6,
          size: 'medium',
        }}
        isHidden={isFirst}
      />
      <Tile.Flexible
        isCutOnTop={isFirst}
        isCutOnBottom={isLast}
        minHeight={'40px'}
        maxHeight={'60px'}
        cutSize={isLast ? '40px' : undefined}
        isHidden={isLast && isFirst}
      >
        <Tile.Pin verticalOffset={isLast ? '-30px' : '-25px'} />
      </Tile.Flexible>
      <Tile.Flexible isHidden={isLast} />
      <Tile.Fixed
        column={{
          start: 6,
          end: 4,
          size: 'medium',
        }}
        isHidden={isLast}
      />
    </>
  ),
  start5end5: ({ isLast, isFirst }) => (
    <>
      <Tile.Flexible
        isCutOnTop={isFirst}
        cutSize={'95px'}
        maxHeight={'125px'}
        isHidden={isLast && isFirst}
      >
        <Tile.Pin verticalOffset={'17px'} />
      </Tile.Flexible>
      <Tile.Flexible isCutOnBottom={isLast} isHidden={isLast} />
    </>
  ),
};

export const getTilesLayout = (params: {
  column: ItemColumn;
  isFirst: boolean;
  isLast: boolean;
}): ReactNode => {
  const { column, isFirst, isLast } = params;
  const lookupKey: TilesLayoutLookupKey = `start${column.start}end${column.end}`;
  const lookupFn = tilesLayoutLookup[lookupKey] as TilesLayoutLookupFn;
  return lookupFn({
    isLast,
    isFirst,
  });
};
