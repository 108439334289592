import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EditInitiativeStatusDialog from 'initiative/EditInitiativeStatusDialog';
import ContextMenu from 'shared/components/ContextMenu';
import useDialogState from 'shared/hooks/useDialogState';
import type { Initiative } from 'types.graphql.generated';
import type { InitiativeStatusWizardInitiative } from 'initiative/InitiativeStatusWizard/InitiativeStatusWizard.type';
import type { InitiativeStatusForStatusWizard } from 'initiative/InitiativeStatusWizard';
import type { StrategyElementWithAuthorizedActions } from 'user/ability/canPerformStrategyElementAction';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';

export type InitiativeForStatusContextMenu = Pick<
  Initiative,
  'id' | 'name' | 'description' | 'isCurrentUserOwner'
> &
  InitiativeStatusWizardInitiative &
  StrategyElementWithAuthorizedActions;

type Props = {
  initiative: InitiativeForStatusContextMenu;
  status: InitiativeStatusForStatusWizard;
};

const InitiativeStatusContextMenu = ({ initiative, status }: Props) => {
  const { t } = useTranslation();
  const {
    isOpen: isEditOpen,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDialogState();

  const canWrite = canPerformStrategyElementAction(initiative, 'WRITE');

  const items = useMemo(
    () => [canWrite && { id: 'edit', title: t('edit') }].filter(Boolean),
    [canWrite, t],
  );

  const handleAction = useCallback(
    (itemId: string) => {
      switch (itemId) {
        case 'edit':
          onOpenEdit();
      }
    },
    [onOpenEdit],
  );

  if (items.length === 0) return null;

  return (
    <>
      <ContextMenu items={items} onAction={handleAction} />

      <EditInitiativeStatusDialog
        isOpen={isEditOpen}
        initiative={initiative}
        status={status}
        onClose={onCloseEdit}
      />
    </>
  );
};

export default InitiativeStatusContextMenu;
