import type { ComponentType, FocusEvent } from 'react';
import { useField, type FieldProps } from 'formik';

export type BaseFieldComponentProps = {
  hasError?: boolean;
  onBlur?: (event: FocusEvent<any>) => void | undefined;
  onChange?: (value: any) => void | undefined;
  value?: any;
};

export const withField =
  <ComponentProps extends BaseFieldComponentProps>(
    Component: ComponentType<ComponentProps>,
  ) =>
  ({ form, field: _field, meta: _meta, ...restProps }: FieldProps) => {
    const [field, meta] = useField(_field.name);

    return (
      <Component
        {...(restProps as ComponentProps)}
        value={field.value}
        name={field.name}
        onChange={(value) => {
          form.setFieldValue(field.name, value);
        }}
        onBlur={(event) => {
          form.setFieldValue(field.name, field.value);
          field.onBlur(event);
        }}
        hasError={
          (form.submitCount > 0 || meta.touched) && meta.error ? true : false
        }
      />
    );
  };

export const submitForm = (formId: string, options?: SubmitOptions) => {
  window.submitOptions = options || {};
  const buttonElement = document.createElement('button');
  buttonElement.setAttribute('type', 'submit');
  buttonElement.setAttribute('form', formId);
  buttonElement.setAttribute('style', 'display: none');
  document.body.appendChild(buttonElement);
  buttonElement.click();
  buttonElement.remove();
};
