import { useTranslation } from 'react-i18next';
import { useMemo, memo } from 'react';

import Heading from 'shared/components/Heading';
import Space from 'shared/components/Space';
import Text from 'shared/components/Text';
import type { StatusCommentsProps } from 'shared/status/StatusComments';
import StatusComments from 'shared/status/StatusComments';
import ThemeChip from 'objective/ThemeChip';
import type { StatusIndicator } from 'shared/status/StatusIndicator';
import UserUpdateStatus from 'user/UserUpdateStatus';

import styles from './InsightReportSummaryThemesAndComments.module.scss';
import { useInsightReportSummaryReport } from '../InsightReportSummaryProvider';

type InsightReportSummaryThemesAndCommentsProps = {
  heading: string;
  statusCommentsProps?: Partial<
    Pick<StatusCommentsProps, 'headings' | 'types'>
  >;
};

const InsightReportSummaryThemesAndComments = ({
  heading,
  statusCommentsProps = {},
}: InsightReportSummaryThemesAndCommentsProps) => {
  const { t } = useTranslation();

  const {
    report: {
      reportStatus: { statusComments, statusIndicator, reportThemeCategories },
      auditRecord: { updateBy, updateDateTime },
      strategy,
    },
  } = useInsightReportSummaryReport();

  const allThemes = useMemo(
    () =>
      reportThemeCategories
        .flatMap(({ reportThemes }) => reportThemes)
        .sort((previousValue) => {
          if (previousValue.theme) {
            return -1;
          }
          return 1;
        }),
    [reportThemeCategories],
  );

  const themes = useMemo(() => {
    if (allThemes.length) {
      return allThemes.map(({ theme, reportObjectives }) => {
        if (theme) {
          return (
            <ThemeChip
              theme={theme}
              objectiveStatuses={reportObjectives.map((objective) => ({
                auditRecord: objective.auditRecord,
                statusIndicator: objective.status?.statusIndicator,
              }))}
              key={theme.id}
            />
          );
        }
        return (
          <ThemeChip
            objectiveStatuses={reportObjectives.map((objective) => ({
              auditRecord: objective.auditRecord,
              statusIndicator: objective.status?.statusIndicator,
            }))}
            key={'noTheme'}
          />
        );
      });
    }
    return <Text>{t('noData')}</Text>;
  }, [allThemes, t]);

  const renderStatusComments = () => {
    if (statusComments.length) {
      return (
        <StatusComments
          {...statusCommentsProps}
          items={statusComments}
          listProps={{
            variant: 'emphasis',
            gutterSize: 'large',
          }}
          className={styles.statusComments}
        />
      );
    }
  };

  const renderUpdateStatus = () => {
    if (allThemes.length || statusComments.length) {
      return (
        <UserUpdateStatus
          auditRecordUser={updateBy}
          updateDateTime={updateDateTime}
          statusIndicator={statusIndicator as unknown as StatusIndicator}
        />
      );
    }
    return null;
  };

  return (
    <>
      <Heading level={3}>{heading}</Heading>
      {strategy?.vision?.name ? (
        <Text className={styles.visionName}>{strategy?.vision?.name}</Text>
      ) : null}
      <Space direction={'vertical'} className={styles.container}>
        <Text variant={'strong'} className={styles.caption}>
          {t('report.insightReportSummary.themesAndComments.themes.heading')}
        </Text>
        {themes}
        <Space
          direction={'vertical'}
          size={'large'}
          className={styles.statusCommentsContainer}
        >
          {renderStatusComments()}
          {renderUpdateStatus()}
        </Space>
      </Space>
    </>
  );
};

export default memo(InsightReportSummaryThemesAndComments);
