import cn from 'classnames';

import styles from './CompletionRateIcon.module.scss';

type CompletionRateIconProps = {
  className?: string;
  completionRate: number;
};

const CompletionRateIcon = ({
  completionRate,
  className,
}: CompletionRateIconProps) => {
  const completionDegree = Math.round(completionRate * 3.6);

  return (
    <div
      ref={(el) => {
        if (el) {
          el.style.setProperty('--completion-degree', `${completionDegree}deg`);
        }
      }}
      className={cn(styles.icon, className)}
    />
  );
};

export default CompletionRateIcon;
