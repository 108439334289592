import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useStatusMultiSelect } from 'shared/status/StatusMultiSelect';
import type { ChipGroupProps } from 'shared/components/ChipGroup';
import ChipGroup from 'shared/components/ChipGroup';
import { useUserMultiSelect } from 'user/UserMultiSelect/UserMultiSelectProvider';
import { useTeamInsightsOverview } from 'team/TeamInsights/TeamInsightsOverviewProvider';
import { date } from 'shared/services/date.service';
import { usePriorityMultiSelect } from 'shared/priority/PriorityMultiSelect';

import type { TeamInsightsOverviewFiltersChipGroupItem } from './TeamInsightsOverviewFiltersChipGroup.type';
import useChipGroupItems from './useChipGroupItems';

const TeamInsightsOverviewFiltersChipGroup = () => {
  const { t } = useTranslation();

  const {
    filters: { clearFilters, setStartDateFilter, setEndDateFilter },
  } = useTeamInsightsOverview();

  const { state: userMultiSelectState } = useUserMultiSelect();
  const { state: statusMultiSelectState } = useStatusMultiSelect();
  const { state: priorityMultiSelectState } = usePriorityMultiSelect();

  const chipGroupItems = useChipGroupItems();

  const renderLabel = useCallback<
    NonNullable<
      ChipGroupProps<TeamInsightsOverviewFiltersChipGroupItem>['renderLabel']
    >
  >(
    (item) => {
      if (item.status) {
        return item.status.statusIndicator?.getName();
      }
      if (item.user) {
        return item.user.displayName || item.user.email;
      }
      if (item.startDate) {
        return `${t('from')}: ${date.formatShort(item.startDate)}`;
      }
      if (item.endDate) {
        return `${t('to')}: ${date.formatShort(item.endDate)}`;
      }
      if (item.priority) {
        return item.priority.label;
      }
    },
    [t],
  );

  const isRemoveAllEnabled = chipGroupItems.length > 1;

  const onRemove = useCallback<
    NonNullable<
      ChipGroupProps<TeamInsightsOverviewFiltersChipGroupItem>['onRemove']
    >
  >(
    (item) => {
      if (item.status) {
        statusMultiSelectState.selectionManager.toggleSelection(item.status.id);
      }
      if (item.user) {
        userMultiSelectState.selectionManager.toggleSelection(item.user.id);
      }
      if (item.endDate) {
        setEndDateFilter(null);
      }
      if (item.startDate) {
        setStartDateFilter(null);
      }
      if (item.priority) {
        priorityMultiSelectState.selectionManager.toggleSelection(
          item.priority.id,
        );
      }
    },
    [
      statusMultiSelectState.selectionManager,
      userMultiSelectState.selectionManager,
      setEndDateFilter,
      setStartDateFilter,
      priorityMultiSelectState.selectionManager,
    ],
  );

  if (chipGroupItems.length === 0) return null;

  return (
    <ChipGroup<TeamInsightsOverviewFiltersChipGroupItem>
      items={chipGroupItems}
      onRemove={onRemove}
      onRemoveAll={isRemoveAllEnabled ? clearFilters : undefined}
      renderLabel={renderLabel}
    />
  );
};

export default TeamInsightsOverviewFiltersChipGroup;
