import { useTranslation } from 'react-i18next';
import { css, useTheme } from '@emotion/react';

import type { ButtonProps } from 'shared/components/Button';
import Button from 'shared/components/Button';
import Space from 'shared/components/Space';
import type { Provider } from 'auth/useSignUpWithProvider';

import { getIcon, getProviderName } from './SignUpButton.utils';

type SignUpButtonProps = Pick<ButtonProps, 'onClick'> & {
  provider: Provider;
};

const SignUpButton = ({ provider, onClick }: SignUpButtonProps) => {
  const { t } = useTranslation();
  const Icon = getIcon(provider);
  const theme = useTheme();

  return (
    <Button
      variant={'outlined'}
      onClick={onClick}
      css={css`
        color: ${theme.color.black};
        height: 4rem;
        width: 100%;
      `}
    >
      <Space isCentered={true} size={'small'}>
        <Icon
          css={css`
            height: 2rem;
            width: 2rem;
          `}
        />
        {t('signUp.signUpWith', { provider: getProviderName(provider) })}
      </Space>
    </Button>
  );
};

export default SignUpButton;
