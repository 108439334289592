import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsightsReportChallengeSuggestionsSubscriptionVariables = Types.Exact<{
  numberOfSuggestions: Types.Scalars['Int'];
  elementIds?: Types.InputMaybe<Types.StrategyElementListInput>;
  languageLocaleCode: Types.Scalars['String'];
  temperature?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type InsightsReportChallengeSuggestionsSubscription = { __typename: 'Subscription', suggestions: Array<{ __typename: 'AiSuggestionElement', typeAsText: string, fields: Array<{ __typename: 'KeyValue', key: string, value: string }> }> };


export const InsightsReportChallengeSuggestionsDocument = gql`
    subscription InsightsReportChallengeSuggestions($numberOfSuggestions: Int!, $elementIds: StrategyElementListInput, $languageLocaleCode: String!, $temperature: Float) {
  suggestions: aiSuggestionStream(
    input: {languageLocaleCode: $languageLocaleCode, elementIds: $elementIds, response: {typeAsText: "Insights Report", fields: ["success comment", "challenge comment", "action comment"]}, numberOfSuggestions: $numberOfSuggestions, model: {temperature: $temperature}, hints: ["Suggest summaries of challenges faced within the report items", "Make comment suggestion reflect broad patterns across the strategy elements", "Avoid mentioning audit records"]}
  ) {
    typeAsText
    fields {
      key
      value
    }
  }
}
    `;

/**
 * __useInsightsReportChallengeSuggestionsSubscription__
 *
 * To run a query within a React component, call `useInsightsReportChallengeSuggestionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInsightsReportChallengeSuggestionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsReportChallengeSuggestionsSubscription({
 *   variables: {
 *      numberOfSuggestions: // value for 'numberOfSuggestions'
 *      elementIds: // value for 'elementIds'
 *      languageLocaleCode: // value for 'languageLocaleCode'
 *      temperature: // value for 'temperature'
 *   },
 * });
 */
export function useInsightsReportChallengeSuggestionsSubscription(baseOptions: Apollo.SubscriptionHookOptions<InsightsReportChallengeSuggestionsSubscription, InsightsReportChallengeSuggestionsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<InsightsReportChallengeSuggestionsSubscription, InsightsReportChallengeSuggestionsSubscriptionVariables>(InsightsReportChallengeSuggestionsDocument, options);
      }
export type InsightsReportChallengeSuggestionsSubscriptionHookResult = ReturnType<typeof useInsightsReportChallengeSuggestionsSubscription>;
export type InsightsReportChallengeSuggestionsSubscriptionResult = Apollo.SubscriptionResult<InsightsReportChallengeSuggestionsSubscription>;