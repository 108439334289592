import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import Button from 'shared/components/Button/Button';
import { strategyRoomRoutesPaths } from 'strategy/StrategyRoom/StrategyRoom.routing.paths';
import Emoji from 'shared/components/Emoji';

import styles from './StrategyRoomGuide.module.scss';

const StrategyRoomGuide = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(strategyRoomRoutesPaths.strategy.pyramid());
  }, [navigate]);

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <Emoji icon={t('strategy.strategyRoom.guide.2steps.icon')} />
      </div>
      <div className={styles.title}>
        {t('strategy.strategyRoom.guide.2steps.title')}
      </div>
      <div className={styles.description}>
        {t('strategy.strategyRoom.guide.2steps.description')}
      </div>
      <Button onClick={handleClick} variant={'outlined'}>
        {t('strategy.strategyRoom.guide.2steps.button')}
      </Button>
    </div>
  );
};

export default StrategyRoomGuide;
