import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

const Bar = styled.div`
  position: relative;
  border-radius: 4px;
  height: 8px;
  background-color: ${(props) => props.theme.color.neutral3};
  margin-top: 5px;
`;

const Fill = styled.div<{ ration: number }>`
  width: ${(props) => props.ration}%;
  background-color: ${(props) => props.theme.color.primary};
  height: 100%;
  box-sizing: content-box;
  border-radius: 4px;
`;

const Marker = styled.div`
  margin-top: -3px;
  position: absolute;
  z-index: 100000;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  overflow: auto;
  border-width: 3px;
  border-style: solid;
  border-color: white;
  box-sizing: content-box;
`;

type Props = {
  markerRatio?: number;
  ratio: number;
};

const ProgressBar = ({ ratio, markerRatio }: Props) => {
  const theme = useTheme();

  const isMarkerActive =
    markerRatio !== undefined ? ratio > markerRatio : undefined;

  return (
    <>
      <Bar>
        {markerRatio !== undefined ? (
          <Marker
            css={css({
              left: `calc(${markerRatio}% - 6px)`,
              backgroundColor: isMarkerActive
                ? theme.color.primary
                : theme.color.neutral1,
            })}
          />
        ) : null}
        <Fill ration={ratio} />
      </Bar>
    </>
  );
};

export default ProgressBar;
