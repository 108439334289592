import Text from 'shared/components/Text';

import type {
  ListBoxRowComponentProps,
  BaseListBoxItem,
} from '../ListBox.type';

type ListBoxRowProps<ListBoxItem extends BaseListBoxItem = BaseListBoxItem> =
  ListBoxRowComponentProps<ListBoxItem>;

const ListBoxRow = <ListBoxItem extends BaseListBoxItem = BaseListBoxItem>({
  item,
}: ListBoxRowProps<ListBoxItem>) => (
  <Text variant={'emphasis'}>{item.title}</Text>
);

export default ListBoxRow;
