import { createContext } from 'react';

export type Submissions = Record<string, boolean>;

export type Value = {
  setSubmissions: (submissions: Submissions) => void;
  submissions: Submissions;
};

const SubmissionContext = createContext<Value | undefined>(undefined);

export default SubmissionContext;
