import FiltersButton from 'shared/components/FiltersButton';

import useChipGroupItems from './StrategyObjectivesFiltersChipGroup/useChipGroupItems';
import StrategyObjectivesFilters from './StrategyObjectivesFilters';

type Props = {
  clearFilters: () => void;
};
const StrategyObjectiveFiltersButton = ({ clearFilters }: Props) => {
  const { length: selectionCount } = useChipGroupItems();

  return (
    <FiltersButton clearFilters={clearFilters} selectionCount={selectionCount}>
      <StrategyObjectivesFilters />
    </FiltersButton>
  );
};

export default StrategyObjectiveFiltersButton;
