import { useTranslation } from 'react-i18next';

import type { ButtonProps } from 'shared/components/Button';
import Button from 'shared/components/Button';
import { ReactComponent as SquareIcon } from 'shared/static/icons/icon-square.svg';
import { ReactComponent as CheckSquareIcon } from 'shared/static/icons/icon-square-check.svg';
import { ReactComponent as IndeterminateSquareIcon } from 'shared/static/icons/icon-square-indeterminate.svg';

const getIcon = (isNoneSelected: boolean, areAllSelected: boolean) => {
  if (isNoneSelected && !areAllSelected) {
    return SquareIcon;
  }
  if (!isNoneSelected && areAllSelected) {
    return CheckSquareIcon;
  }
  return IndeterminateSquareIcon;
};

type SelectAllButtonProps = Omit<
  ButtonProps,
  'icon' | 'iconPosition' | 'iconSize'
> & {
  areAllSelected: boolean;
  isNoneSelected: boolean;
};

const SelectAllButton = ({
  children: childrenProp,
  isNoneSelected,
  areAllSelected,
  ...restProps
}: SelectAllButtonProps) => {
  const { t } = useTranslation();

  const children = childrenProp || t('selectAll');
  const Icon = getIcon(isNoneSelected, areAllSelected);

  return (
    <Button
      {...restProps}
      icon={Icon}
      iconSize={'small'}
      iconPosition={'start'}
      fontWeight={'light'}
    >
      {children}
    </Button>
  );
};

export default SelectAllButton;
