import { useTranslation } from 'react-i18next';
import { useCallback, useId, useMemo } from 'react';

import Heading from 'shared/components/Heading';
import Text from 'shared/components/Text';
import Space from 'shared/components/Space';
import ThemeMosaic from 'objective/ThemeMosaic';
import { StatusIndicator } from 'shared/status/StatusIndicator';

import styles from './InsightReportSummaryStrategyMap.module.scss';
import { useInsightReportSummaryReport } from '../InsightReportSummaryProvider';

const InsightReportSummaryStrategyMap = () => {
  const keyId = useId();

  const { t } = useTranslation();

  const {
    report: {
      reportStatus: { reportThemeCategories },
      strategy,
    },
  } = useInsightReportSummaryReport();

  const renderThemeCategory = useCallback(
    (name?: string) => (
      <Space>
        <div className={styles.themeCategory}>
          {name || t('theme.noThemeCategory')}
        </div>
      </Space>
    ),
    [t],
  );

  const renderReportThemes = useCallback(
    (reportThemes: (typeof reportThemeCategories)[0]['reportThemes']) => (
      <>
        {reportThemes.map((reportTheme, reportThemeIndex) => (
          <ThemeMosaic
            theme={
              reportTheme?.theme
                ? {
                    name: reportTheme.theme.name,
                    description: reportTheme.theme.description,
                  }
                : undefined
            }
            objectives={reportTheme?.reportObjectives.map(
              (reportObjective) => ({
                name: reportObjective.objective.name,
                id: reportObjective.id,
                currentObjectiveStatus: {
                  statusIndicator:
                    reportObjective.status?.statusIndicator ||
                    new StatusIndicator(),
                },
              }),
            )}
            key={`${keyId}${reportTheme.theme?.id || reportThemeIndex}`}
          />
        ))}
      </>
    ),
    [keyId],
  );

  const themeCategories = useMemo(() => {
    if (reportThemeCategories.length) {
      return (
        <Space direction={'vertical'} size={'custom'} value={'3rem'}>
          {reportThemeCategories.map(({ themeCategory, reportThemes }) => (
            <div key={themeCategory?.id || null}>
              {renderThemeCategory(themeCategory?.name)}
              <Space
                direction={'vertical'}
                size={'custom'}
                value={'3rem'}
                className={styles.themes}
              >
                {renderReportThemes(reportThemes)}
              </Space>
            </div>
          ))}
        </Space>
      );
    }
    return <Text>{t('noData')}</Text>;
  }, [reportThemeCategories, renderThemeCategory, renderReportThemes, t]);

  return (
    <Space direction={'vertical'}>
      <Heading level={3}>
        {t('report.insightReportSummary.strategyMap.heading')}
      </Heading>
      {strategy?.vision?.name ? (
        <Text className={styles.visionName}>{strategy?.vision?.name}</Text>
      ) : null}
      {themeCategories}
    </Space>
  );
};

export default InsightReportSummaryStrategyMap;
