import type { Objective, ObjectiveStatusInput } from 'types.graphql.generated';
import type { ObjectiveStatusValues } from 'objective/ObjectiveStatusWizard/ObjectiveStatusWizard.type';
import { date } from 'shared/services/date.service';
import type { TeamAdapter } from 'team/TeamAdapter';

import type { ObjectiveStatusForEdit } from './ObjectiveStatusWizard';

export const resolveObjectiveStatusInput = (params: {
  objective: Pick<Objective, 'id'>;
  status?: ObjectiveStatusForEdit;
  teamAdapter: TeamAdapter;
  values: ObjectiveStatusValues;
}): ObjectiveStatusInput => {
  const { status, values, objective, teamAdapter } = params;

  return {
    idToUpdate: status?.id,
    orgKey: teamAdapter.orgKey,
    objectiveId: objective.id,
    statusIndicator: values.status?.statusIndicator || undefined,
    comment: values.status?.comment,
    successComments: {
      add: values.comments?.successes.map((text) => ({ text })),
      idsToDelete: status?.successComments.map((comment) => comment.id),
    },
    challengeComments: {
      add: values.comments?.challenges.map((text) => ({ text })),
      idsToDelete: status?.challengeComments.map((comment) => comment.id),
    },
    actionComments: {
      add: values.comments?.actions.map((text) => ({ text })),
      idsToDelete: status?.actionComments.map((comment) => comment.id),
    },
    complete: values.status?.complete,
    statusDateTime: values.status?.date
      ? date.formatRfc3339(values.status?.date)
      : undefined,
  };
};
