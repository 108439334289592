import { useDefinedContext } from 'shared/utils/context.utils';

import { ListProviderContext } from './ListProvider.context';
import ListProvider from './ListProvider';

export const useList = () =>
  useDefinedContext(ListProviderContext, {
    providerName: ListProvider.name,
    hookName: useList.name,
  });
