import { useCallback } from 'react';

import type {
  MultiSelectProps,
  RenderLabelFn,
} from 'shared/components/MultiSelect';
import MultiSelect from 'shared/components/MultiSelect';

import ListBoxRow from './UserMultiSelectListBoxRow';
import Provider, { useUserMultiSelect } from './UserMultiSelectProvider';
import type { UserMultiSelectItem } from './UserMultiSelect.type';
import Label from './UserMultiSelectLabel';

type UserMultiSelectProps = Pick<
  MultiSelectProps<UserMultiSelectItem>,
  'triggerClassName'
>;

const UserMultiSelect = ({ triggerClassName }: UserMultiSelectProps) => {
  const { state, items } = useUserMultiSelect();

  const renderLabel = useCallback<RenderLabelFn<UserMultiSelectItem>>(
    () => <Label state={state} />,
    [state],
  );

  return (
    <MultiSelect<UserMultiSelectItem>
      state={state}
      items={items}
      ListBoxRow={ListBoxRow}
      label={renderLabel}
      triggerClassName={triggerClassName}
    />
  );
};

UserMultiSelect.Provider = Provider;

export default UserMultiSelect;
