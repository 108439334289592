import type { RouteObject } from 'react-router-dom';

import { contributionRoutesPaths } from './contribution.routing.paths';
import ContributionLayout from './ContributionLayout';
import ContributionMyContribution from './ContributionMyContribution';
import ContributionMyFollowUpsViewMore from './ContributionMyFollowUpsViewMore/ContributionMyFollowUpsViewMore';
import ContributionUserContribution from './ContributionUserContribution';

export const contributionsRoutes: RouteObject[] = [
  {
    path: contributionRoutesPaths.root(),
    element: <ContributionLayout />,
    children: [{ index: true, element: <ContributionMyContribution /> }],
  },
  {
    path: contributionRoutesPaths.followUps(),
    element: <ContributionMyFollowUpsViewMore />,
  },
  {
    path: contributionRoutesPaths.user(),
    element: <ContributionUserContribution />,
  },
];
