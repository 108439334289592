import { useDefinedContext } from 'shared/utils/context.utils';

import { GlobalSpinnerProviderContext } from './GlobalSpinnerProvider.context';
import GlobalSpinnerProvider from './GlobalSpinnerProvider';

export const useGlobalSpinner = () =>
  useDefinedContext(GlobalSpinnerProviderContext, {
    providerName: GlobalSpinnerProvider.name,
    hookName: useGlobalSpinner.name,
  });
