import { useDefinedContext } from 'shared/utils/context.utils';

import { StrategyObjectivesInitiativesContext } from './StrategyInitiativesProvider.context';
import StrategyInitiativesProvider from './StrategyInitiativesProvider';

export const useStrategyInitiatives = () =>
  useDefinedContext(StrategyObjectivesInitiativesContext, {
    hookName: useStrategyInitiatives.name,
    providerName: StrategyInitiativesProvider.name,
  });
