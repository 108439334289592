import { createContext } from 'react';

export type PreviousInsightsContextValue = {
  formId?: string;
  initiativeId?: string;
  isPreviousInsightsOpen: boolean;
  metricId?: string;
  objectiveId?: string;
  onClosePreviousInsights: () => void;
  onOpenPreviousInsights: () => void;
};

export const PreviousInsightsContext = createContext<
  PreviousInsightsContextValue | undefined
>(undefined);
