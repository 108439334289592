import { useField } from 'formik';
import { useEffect } from 'react';
import { get } from 'lodash';

import type { SubscriptionVariablesInterface } from './SuggestionsProvider';
import { useSuggestions } from './SuggestionsProvider';

type Props<SubscriptionVariables extends SubscriptionVariablesInterface> = {
  fieldName: string;
  fieldProperty: string;
  variableName: keyof SubscriptionVariables;
};

const AiSuggestionsVariableField = <
  SubscriptionVariables extends SubscriptionVariablesInterface,
>({
  fieldName,
  fieldProperty,
  variableName,
}: Props<SubscriptionVariables>) => {
  const { setVariables } = useSuggestions<SubscriptionVariables>();

  const [field] = useField(fieldName);

  useEffect(() => {
    const value = get(field.value, fieldProperty);

    const variables = { [variableName]: value } as SubscriptionVariables;

    setVariables(variables);
  }, [field.value, fieldProperty, setVariables, variableName]);

  return null;
};

export default AiSuggestionsVariableField;
