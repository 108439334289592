import { useDefinedContext } from 'shared/utils/context.utils';

import { StrategyObjectivesMetricsContext } from './StrategyMetricsProvider.context';
import MetricsProvider from './StrategyMetricsProvider';

export const useStrategyMetrics = () =>
  useDefinedContext(StrategyObjectivesMetricsContext, {
    hookName: useStrategyMetrics.name,
    providerName: MetricsProvider.name,
  });
