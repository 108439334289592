import { useTranslation } from 'react-i18next';
import { memo, useMemo } from 'react';
import cn from 'classnames';
import isNil from 'lodash/isNil';

import Text from 'shared/components/Text';
import Space from 'shared/components/Space';
import ObjectiveChip from 'objective/ObjectiveChip';

import styles from './ThemeMosaic.module.scss';
import type {
  ThemeMosaicTheme,
  ThemeMosaicObjective,
} from './ThemeMosaic.type';

type ThemeMosaicProps = {
  className?: string;
  objectives?: ThemeMosaicObjective[];
  theme?: ThemeMosaicTheme;
};

const ThemeMosaic = ({
  theme,
  objectives = [],
  className,
}: ThemeMosaicProps) => {
  const { t } = useTranslation();

  const objectiveChips = useMemo(() => {
    if (objectives.length) {
      return (
        <>
          {objectives.map((objective) => (
            <ObjectiveChip
              objective={objective}
              size={'big'}
              key={objective.id}
            />
          ))}
        </>
      );
    }
    return <Text>{t('objective.noObjectives')}</Text>;
  }, [objectives, t]);

  const renderTheme = () => {
    const name = !isNil(theme) ? theme?.name : t('theme.noTheme');
    const description = theme?.description;
    return (
      <Space
        direction={'vertical'}
        size={'small'}
        className={styles.themeMosaicTheme}
      >
        <Text variant={'strong'}>{name}</Text>
        {description ? <Text>{description}</Text> : null}
      </Space>
    );
  };

  return (
    <div className={cn(styles.themeMosaic, className)}>
      {renderTheme()}
      <Space className={styles.themeMosaicObjectives}>{objectiveChips}</Space>
    </div>
  );
};

export default memo(ThemeMosaic);
