import { useTranslation } from 'react-i18next';
import type { PropsWithChildren, ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { partition } from 'lodash';

import Text from 'shared/components/Text';
import Space from 'shared/components/Space';
import { ReactComponent as LayoutLayersIcon } from 'shared/static/icons/icon-layout-layers.svg';
import { ReactComponent as BlockIcon } from 'shared/static/icons/icon-block.svg';
import IconButton from 'shared/components/IconButton';
import { ReactComponent as ChevronIconUp } from 'shared/static/icons/icon-chevron-up.svg';
import { ReactComponent as ChevronIconDown } from 'shared/static/icons/icon-chevron-down.svg';
import Collapse from 'shared/components/Collapse';
import Tag from 'shared/components/Tag';

import type { StrategyRoomManageThemesObjectiveFragment } from './StrategyRoomThemesManageThemes.graphql.generated';

export type OrderNumberStyle = 'number' | 'dash' | 'hidden';

type StrategyRoomThemesListRowProps = PropsWithChildren<{
  category?: string;
  description?: string;
  headingActions?: ReactNode;
  hideInactive: boolean;
  isActive: boolean;
  name?: string;
  objectives: StrategyRoomManageThemesObjectiveFragment[];
  orderNumber: number;
  orderNumberStyle: OrderNumberStyle;
  orgUnitId?: string | null;
  showArchivedTag: boolean;
  themeId?: string;
}>;

const ThemesListRow = ({
  orderNumberStyle,
  orderNumber,
  children,
  headingActions,
  objectives,
  name,
  description,
  category,
  themeId,
  orgUnitId,
  isActive,
  hideInactive,
  showArchivedTag,
}: StrategyRoomThemesListRowProps) => {
  const { t } = useTranslation();
  const emotionTheme = useTheme();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleIsCollapsed = useCallback(
    () => setIsCollapsed((isCollapsed) => !isCollapsed),
    [],
  );

  const themeObjectives = objectives?.filter(
    (objective) => objective.theme?.id === themeId,
  );

  const hasActiveObjective = themeObjectives?.some(
    (objective) => !objective.currentObjectiveStatus?.complete,
  );

  if (hideInactive && !isActive && !hasActiveObjective) {
    return null;
  }

  const Icon = themeId ? LayoutLayersIcon : BlockIcon;

  const [orgUnitObjectives, otherOrgUnitObjectives] = partition(
    themeObjectives,
    (objective) =>
      orgUnitId === undefined ||
      (orgUnitId === null && !objective.orgUnit) ||
      objective.orgUnit?.id === orgUnitId,
  );

  return (
    <Collapse
      isCollapsed={isCollapsed}
      css={css(`
        border-bottom: 1px solid ${emotionTheme.color.hoverDark};
        border-radius: 0;
        background-color: ${emotionTheme.color.white};

        &:hover {
          background-color: ${emotionTheme.color.hoverLight};
        }
      `)}
      hasChevron={false}
      renderHeading={() => (
        <Space
          size={'medium'}
          isCentered={true}
          css={css(`
            flex: 1;
            height: 48px;
            justify-content: flex-start;
            padding: 0 24px;
        `)}
        >
          {orderNumberStyle !== 'hidden' && (
            <Space
              css={css({
                width: 16,
                justifyContent:
                  orderNumberStyle === 'dash' ? 'center' : 'flex-start',
              })}
            >
              <Text variant={'emphasis'}>
                {orderNumberStyle === 'dash' ? '-' : `${orderNumber}.`}
              </Text>
            </Space>
          )}
          <Space>
            <Icon
              css={css({ width: 16, color: emotionTheme.color.neutral2 })}
            />
          </Space>
          <Space css={css({ alignItems: 'center', width: 16, flexGrow: 1 })}>
            <Text
              variant={'emphasis'}
              onClick={toggleIsCollapsed}
              css={css({ cursor: 'pointer' })}
            >
              {name}
            </Text>
            {orgUnitObjectives.length > 0 && (
              <Text
                onClick={toggleIsCollapsed}
                css={css({
                  cursor: 'pointer',
                  textWrap: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                })}
              >
                {t('count.objective', { count: orgUnitObjectives.length })}
              </Text>
            )}
            {otherOrgUnitObjectives.length > 0 && (
              <Text
                onClick={toggleIsCollapsed}
                css={css({
                  cursor: 'pointer',
                  textWrap: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                })}
              >
                {'(+'}
                {t('count.other_team_objective', {
                  count: otherOrgUnitObjectives.length,
                })}
                {')'}
              </Text>
            )}
            {showArchivedTag && !isActive && (
              <Tag variant={'primary'} css={css({ fontSize: '0.571rem' })}>
                {t('strategy.strategyRoom.themes.archived')}
              </Tag>
            )}
            <IconButton
              size={'small'}
              icon={!isCollapsed ? ChevronIconUp : ChevronIconDown}
              onClick={toggleIsCollapsed}
            />
          </Space>
          {headingActions}
        </Space>
      )}
    >
      <Space
        direction={'vertical'}
        size={'medium'}
        css={css({ padding: '0 24px 20px 48px' })}
      >
        {description && (
          <Space>
            <Text>{description}</Text>
          </Space>
        )}
        <Space gap={'2rem'}>
          {category && (
            <Space size={'small'}>
              <Text>{t('strategy.strategyRoom.themes.category')}:</Text>
              <Text variant={'emphasis'}>{category}</Text>
            </Space>
          )}
        </Space>
        <Space>{children}</Space>
      </Space>
    </Collapse>
  );
};

export default ThemesListRow;
