import { Trans } from 'react-i18next';

import type { MetricStatus } from 'types.graphql.generated';

import styles from './MetricOverviewInsightsRowScoreComment.module.scss';

type MetricInsightsRowScoreCommentProps = {
  status: Pick<MetricStatus, 'statusValue' | 'forecastValue'>;
};

const MetricOverviewInsightsRowScoreComment = ({
  status,
}: MetricInsightsRowScoreCommentProps) => (
  <div className={styles.score}>
    <span>
      <Trans
        i18nKey={'objective.insights.recentInsights.score.current'}
        values={{
          value:
            status.statusValue === undefined || status.statusValue === null
              ? '-'
              : status.statusValue,
        }}
        components={{
          strong: <strong />,
        }}
      />
    </span>
    <span>
      <Trans
        i18nKey={'objective.insights.recentInsights.score.forecast'}
        values={{
          value:
            status.forecastValue === undefined || status.forecastValue === null
              ? '-'
              : status.forecastValue,
        }}
        components={{
          strong: <strong />,
        }}
      />
    </span>
  </div>
);

export default MetricOverviewInsightsRowScoreComment;
