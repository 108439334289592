import { useDefinedContext } from 'shared/utils/context.utils';

import { ObjectiveInsightsContext } from './ObjectiveInsightsProvider.context';
import ObjectiveInsightsProvider from './ObjectiveInsightsProvider';

export const useObjectiveInsights = () =>
  useDefinedContext(ObjectiveInsightsContext, {
    hookName: useObjectiveInsights.name,
    providerName: ObjectiveInsightsProvider.name,
  });
