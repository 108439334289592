import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash';

import ContributionSection from 'contribution/ContributionSection';
import type { Contribution } from 'contribution/ContributionTeamList/ContributionTeamList';
import ContributionTeamList from 'contribution/ContributionTeamList';
import type { User } from 'types.graphql.generated';

import ContributionOthersCommitmentsListHeading from './ContributionOthersCommitmentsListHeading';

type Owner = Pick<
  User,
  'id' | 'displayName' | 'email' | 'photoUrl' | 'isActive'
>;

type Props = {
  contributions: Contribution[];
};

const ContributionOthersCommitments = forwardRef<HTMLElement, Props>(
  ({ contributions }, ref) => {
    const { t } = useTranslation();

    if (contributions.length === 0) return null;

    const owners = uniqBy(
      contributions.map((contribution) => contribution.owner).filter(Boolean),
      (owner) => owner.id,
    );

    const contributionsByOwner = owners.map((owner) => [
      owner,
      contributions.filter(
        (contribution) => contribution.owner?.id === owner.id,
      ),
    ]) as [Owner, Contribution[]][];

    return (
      <ContributionSection innerRef={ref}>
        <ContributionSection.Header
          heading={t('contribution.othersCommitments.heading')}
          description={t('contribution.othersCommitments.description')}
        />
        {contributionsByOwner.map(([owner, contributions]) => (
          <ContributionTeamList
            heading={<ContributionOthersCommitmentsListHeading owner={owner} />}
            key={owner.id}
            contributions={contributions}
          />
        ))}
      </ContributionSection>
    );
  },
);

export default ContributionOthersCommitments;
