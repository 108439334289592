import { css } from '@emotion/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TextButton from 'shared/components/TextButton';

import ObjectiveNameCheatSheet from './ObjectiveNameCheatSheet';

const ObjectiveNameCheatSheetButton = () => {
  const { t } = useTranslation();
  const [isCheatSheetOpen, setIsCheatSheetOpen] = useState(false);

  return (
    <>
      <TextButton
        css={css({ alignSelf: 'flex-end' })}
        onPress={() => setIsCheatSheetOpen(true)}
      >
        {t('objective.objectiveNameCheatSheet.link')}
      </TextButton>

      <ObjectiveNameCheatSheet
        isOpen={isCheatSheetOpen}
        onClose={() => setIsCheatSheetOpen(false)}
      />
    </>
  );
};

export default ObjectiveNameCheatSheetButton;
