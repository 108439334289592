import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ContributionSection from 'contribution/ContributionSection/ContributionSection';
import { contributionRoutesPaths } from 'contribution/contribution.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';
import type { ContributionFollowUpsSectionUserFragment } from 'contribution/ContributionMyFollowUpsViewMore/ContributionMyFollowUpsViewMore.graphql.generated';

import ContributionMyFollowUpsSectionContent from './ContributionMyFollowUpsSectionContent';
import styles from './ContributionMyFollowUpsSection.module.scss';

type Props = {
  user: ContributionFollowUpsSectionUserFragment;
};

const ContributionMyFollowUpsSection = ({ user }: Props) => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();

  return (
    <ContributionSection>
      <div className={styles.header}>
        <ContributionSection.Header heading={t('followUps.myFollowUps')} />
        <div>
          <Link
            className={styles.link}
            to={contributionRoutesPaths.followUps({
              params: {
                teamSlug: teamAdapter.toParam(),
              },
            })}
          >
            {t('viewAll')}
          </Link>
        </div>
      </div>

      <ContributionMyFollowUpsSectionContent user={user} />
    </ContributionSection>
  );
};

export default ContributionMyFollowUpsSection;
