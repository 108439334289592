import type { PropsWithChildren } from 'react';

import Popover from 'shared/components/__DEPRECATED__/Popover';
import type { PopoverProps } from 'shared/components/__DEPRECATED__/Popover';

import MenuButton from './MenuButton';
import MenuItem from './MenuItem';
import { getResetListStyle } from './getResetListStyle';

export type MenuProps = PropsWithChildren<
  Pick<
    PopoverProps,
    'referenceElement' | 'isOpen' | 'placement' | 'onClose'
  > & {
    className?: string;
  }
>;

/**
 * @deprecated Use `shared/components/ContextMenu` instead
 */
const Menu = ({ onClose, children, ...restProps }: MenuProps) => (
  <Popover {...restProps} onClose={onClose}>
    <ul
      role={'menu'}
      onClick={() => {
        onClose?.();
      }}
      css={getResetListStyle()}
    >
      {children}
    </ul>
  </Popover>
);

Menu.Item = MenuItem;
Menu.Button = MenuButton;

export default Menu;
