import { useTranslation } from 'react-i18next';

import Heading from 'shared/components/Heading';
import Attributes from 'strategy/Attributes';

import { useInitiativeOverview } from '../InitiativeOverviewProvider';
import styles from './InitiativeOverviewAttributes.module.scss';

const InitiativeOverviewAttributes = () => {
  const { t } = useTranslation();

  const { initiative } = useInitiativeOverview();

  return (
    <>
      <Heading level={3} className={styles.heading}>
        {t('attributes.attributes')}
      </Heading>
      <Attributes attributes={initiative?.attributes} />
    </>
  );
};

export default InitiativeOverviewAttributes;
