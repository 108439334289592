import styled from '@emotion/styled';
import { range } from 'lodash';

import { ReactComponent as ChevronLeft } from 'shared/static/icons/icon-chevron-left.svg';
import { ReactComponent as ChevronRight } from 'shared/static/icons/icon-chevron-right.svg';

const Container = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
`;

const Button = styled.div<{ isDisabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  background-color: ${(props) => props.theme.color.white};
  border-radius: 8px;

  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'all')};

  svg {
    width: 16px;
    height: 16px;
  }
`;

const Items = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Item = styled.div<{ isCurrent: boolean }>`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${(props) =>
    props.isCurrent ? props.theme.color.primary : props.theme.color.neutral2};
`;

type Props = {
  currentItemIndex: number;
  itemsCount: number;
  setCurrentItemIndex: (index: number) => void;
};

const Navigation = ({
  itemsCount,
  currentItemIndex,
  setCurrentItemIndex,
}: Props) => {
  if (itemsCount === 1) return null;

  const firstItemIndex = 0;
  const lastItemIndex = itemsCount - 1;

  return (
    <Container>
      <Button
        isDisabled={currentItemIndex === firstItemIndex}
        onClick={() => setCurrentItemIndex(currentItemIndex - 1)}
      >
        <ChevronLeft />
      </Button>
      <Items>
        {range(0, itemsCount).map((index) => (
          <Item key={index} isCurrent={index === currentItemIndex} />
        ))}
      </Items>
      <Button
        isDisabled={currentItemIndex === lastItemIndex}
        onClick={() => setCurrentItemIndex(currentItemIndex + 1)}
      >
        <ChevronRight />
      </Button>
    </Container>
  );
};

export default Navigation;
