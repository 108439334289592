import { useTranslation } from 'react-i18next';

import Sidesheets from 'shared/sidesheets';
import InfoCard from 'shared/components/InfoCard';

type StatusCommentsInfoCardProps = {
  isSidesheetOpen: boolean;
  setIsSidesheetOpen: (isSidesheetOpen: boolean) => void;
};

const StatusCommentsInfoCard = ({
  isSidesheetOpen,
  setIsSidesheetOpen,
}: StatusCommentsInfoCardProps) => {
  const { t } = useTranslation();

  return (
    <InfoCard
      title={t('sidesheets.context.status.heading')}
      sideSheetTitle={t('sidesheets.context.status.heading')}
      contentKey={'status.statusCommentsInfoCard.content'}
      isSidesheetOpen={isSidesheetOpen}
      setIsSidesheetOpen={setIsSidesheetOpen}
    >
      <Sidesheets context={'status'} />
    </InfoCard>
  );
};

export default StatusCommentsInfoCard;
