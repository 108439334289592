import SiderDescription from 'shared/components/Description';
import DescriptionDates from 'shared/components/DescriptionDates';
import type { ObjectiveDetailsQuery } from 'objective/ObjectiveDetails/ObjectiveDetailsProvider/ObjectiveDetailsProvider.graphql.generated';
import ObjectiveLink from 'objective/ObjectiveLink';
import type { StrategyElementWithAuthorizedActions } from 'user/ability/canPerformStrategyElementAction';

import styles from './ObjectiveDetailsSider.module.scss';
import ObjectiveDetailsSiderMembers from '../ObjectiveDetailsSiderMembers/ObjectiveDetailsSiderMembers';

type ObjectiveDetailsSiderProps = {
  objective: Pick<
    ObjectiveDetailsQuery['objective'],
    | 'id'
    | 'timeLine'
    | 'description'
    | 'teamMembers'
    | 'owner'
    | 'parentObjective'
  > &
    StrategyElementWithAuthorizedActions;
};

const ObjectiveDetailsSider = ({ objective }: ObjectiveDetailsSiderProps) => (
  <div className={styles.container}>
    <SiderDescription description={objective?.description} />
    <DescriptionDates {...objective?.timeLine} />
    <ObjectiveLink
      objective={objective.parentObjective ?? objective}
      className={styles.link}
    />
    <ObjectiveDetailsSiderMembers objective={objective} />
  </div>
);

export default ObjectiveDetailsSider;
