import type { Node, ListState } from 'react-stately';
import { useListBoxSection } from 'react-aria';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import cn from 'classnames';

import ListBoxRowContainer from '../ListBoxRowContainer';
import styles from './ListBoxSection.module.scss';
import type { BaseListBoxItem } from '../ListBox.type';

type ListBoxSectionProps<ListBoxItem extends BaseListBoxItem> = {
  item: Node<ListBoxItem>;
  state: ListState<ListBoxItem>;
};

const ListBoxSection = <ListBoxItem extends BaseListBoxItem>({
  item,
  state,
}: ListBoxSectionProps<ListBoxItem>) => {
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: item.rendered,
    'aria-label': item['aria-label'],
  });

  const isFirstSection = item.key === state.collection.getFirstKey();

  const heading = useMemo<ReactNode>(() => {
    if (item.rendered) {
      return (
        <span {...headingProps} className={styles.listBoxSectionHeading}>
          {item.rendered}
        </span>
      );
    }
    return null;
  }, [headingProps, item.rendered]);

  return (
    <li
      {...itemProps}
      className={cn(styles.listBoxSection, {
        [styles.listBoxSectionFirst]: isFirstSection,
      })}
    >
      {heading}
      <ul {...groupProps} className={styles.listBoxSectionList}>
        {[...item.childNodes].map((node) => (
          <ListBoxRowContainer item={node} state={state} key={node.key} />
        ))}
      </ul>
    </li>
  );
};

export default ListBoxSection;
