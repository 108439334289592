import { useMemo } from 'react';
import type { SetOptional } from 'type-fest';

import type { FormProps } from 'shared/form/Form';
import Form from 'shared/form/Form';

import { createValidationSchema } from './ThemeForm.schema';
import { createInitialValues } from './ThemeForm.utils';
import type { ThemeFormValues } from './ThemeForm.type';
import ThemeFormFields from './ThemeFormFields/ThemeFormFields';
import ThemeFormProvider from './ThemeFormProvider/ThemeFormProvider';

export type ThemeFormProps = SetOptional<
  Pick<FormProps<ThemeFormValues>, 'initialValues' | 'onSubmit' | 'id'>,
  'initialValues'
>;

const ThemeForm = ({
  id,
  initialValues: initialValuesProp,
  onSubmit,
}: ThemeFormProps) => {
  const initialValues = useMemo(
    () => initialValuesProp || createInitialValues(),
    [initialValuesProp],
  );

  const validationSchema = useMemo(createValidationSchema, []);

  return (
    <ThemeFormProvider>
      <Form
        id={id}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <ThemeFormFields />
      </Form>
    </ThemeFormProvider>
  );
};

export default ThemeForm;
