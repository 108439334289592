import { components } from 'react-select';
import type { ValueContainerProps } from 'react-select';
import { css } from '@emotion/react';

type SelectValueContainerProps = ValueContainerProps;

const SelectValueContainer = ({
  className,
  ...restProps
}: SelectValueContainerProps) => (
  <components.ValueContainer
    {...restProps}
    className={className}
    css={css(`flex-wrap: nowrap !important;`)}
  />
);

export default SelectValueContainer;
