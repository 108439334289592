import { useTreeState } from 'react-stately';
import { useMenu } from 'react-aria';
import { useRef } from 'react';
import type { AriaMenuProps } from 'react-aria';

import NavbarMenuItem from '../NavbarMenuItem';
import styles from './NavbarMobileMenu.module.scss';

type NavbarMobileMenuProps = AriaMenuProps<object>;

const NavbarMobileMenu = (props: NavbarMobileMenuProps) => {
  const ref = useRef(null);
  const state = useTreeState(props);
  const { menuProps } = useMenu(props, state, ref);

  return (
    <ul {...menuProps} ref={ref} className={styles.mobileMenu}>
      {[...state.collection].map((item) => (
        <NavbarMenuItem key={item.key} item={item} />
      ))}
    </ul>
  );
};

export default NavbarMobileMenu;
