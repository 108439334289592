import { useTranslation } from 'react-i18next';

import type { ConfirmationModalProps } from 'shared/components/__DEPRECATED__/ConfirmationModal';
import ConfirmationModal from 'shared/components/__DEPRECATED__/ConfirmationModal';
import type { UseDeleteOrgUnitParams } from 'orgUnit/useDeleteOrgUnit';
import { useDeleteOrgUnit } from 'orgUnit/useDeleteOrgUnit';

type DeleteOrgUnitConfirmationModalProps = Pick<
  ConfirmationModalProps,
  'isOpen' | 'onClose'
> &
  UseDeleteOrgUnitParams;

const DeleteOrgUnitConfirmationModal = ({
  isOpen,
  onClose,
  orgUnit,
}: DeleteOrgUnitConfirmationModalProps) => {
  const { t } = useTranslation();
  const { deleteOrgUnit } = useDeleteOrgUnit({ orgUnit });

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={deleteOrgUnit}
    >
      {t('orgUnit.deleteOrgUnitConfirmation.content')}
    </ConfirmationModal>
  );
};

export default DeleteOrgUnitConfirmationModal;
