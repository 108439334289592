import type { ReactElement } from 'react';
import { Item, Section } from 'react-stately';
import { useCallback } from 'react';

import type { BaseListBoxItem, ListBoxRowComponent } from './ListBox.type';
import ListBoxRow from './ListBoxRow';

type ListBoxCollectionChildrenParams<ListBoxItem extends BaseListBoxItem> = {
  Row?: ListBoxRowComponent<ListBoxItem>;
};

type ListBoxCollectionChildrenReturnType<ListBoxItem extends BaseListBoxItem> =
  (item: ListBoxItem) => ReactElement;

export const useListBoxCollectionChildren = <
  ListBoxItem extends BaseListBoxItem,
>({
  Row = ListBoxRow,
}: ListBoxCollectionChildrenParams<ListBoxItem>): ListBoxCollectionChildrenReturnType<ListBoxItem> => {
  const renderCollectionChildren = useCallback(
    (item: ListBoxItem) => (
      <Item textValue={item.title}>
        <Row item={item} />
      </Item>
    ),
    [Row],
  );

  const renderCollectionChildrenWithSection = useCallback(
    (item: ListBoxItem) => {
      if (item.children) {
        return (
          <Section items={item.children as ListBoxItem[]} title={item.title}>
            {renderCollectionChildren}
          </Section>
        );
      }
      return renderCollectionChildren(item);
    },
    [renderCollectionChildren],
  );

  return renderCollectionChildrenWithSection;
};
