import { css } from '@emotion/react';

import { ReactComponent as IconRadioOff } from 'shared/static/icons/icon-radio-off.svg';
import { ReactComponent as IconRadioOn } from 'shared/static/icons/icon-radio-on.svg';

type Props = {
  value: boolean;
};

const RadioIcon = ({ value }: Props) => {
  const style = css`
    width: 16px;
    min-width: 16px;
  `;

  return value ? <IconRadioOn css={style} /> : <IconRadioOff css={style} />;
};

export default RadioIcon;
