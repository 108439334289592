import type { PropsWithChildren } from 'react';
import { useRef } from 'react';
import { usePopper } from 'react-popper';
import type { Placement } from '@popperjs/core';
import { AnimatePresence, motion } from 'framer-motion';
import { useClickAway } from 'react-use';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import hexToRgba from 'hex-to-rgba';

import { popAnimation } from 'shared/animations/pop.animation';

const Content = styled(motion.div)(
  (props) => `
  background-color: ${props.theme.color.white};
  border-radius: 8px;
  box-shadow: 0 0 0.75rem ${hexToRgba(props.theme.color.black, 0.1)};
  padding: 0.5rem;
`,
);

export type PopoverProps = PropsWithChildren<{
  className?: string;
  isOpen: boolean;
  onClose?: () => void;
  placement?: Placement;
  referenceElement: HTMLElement | null;
}>;

/**
 * @deprecated
 */
const Popover = ({
  referenceElement,
  isOpen,
  placement = 'bottom-end',
  onClose,
  children,
  className,
}: PopoverProps) => {
  const popperElementRef = useRef<HTMLDivElement>(null);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElementRef.current,
    {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: placement === 'bottom-end' ? [6, 4] : [0, 0],
          },
        },
      ],
    },
  );

  useClickAway(popperElementRef, (event) => {
    if (event.target !== referenceElement) {
      onClose?.();
    }
  });

  return (
    <>
      <div
        {...popperAttributes.popper}
        ref={popperElementRef}
        className={className}
        css={css({ zIndex: 1000 })}
        style={popperStyles.popper}
      >
        <AnimatePresence>
          {isOpen ? <Content {...popAnimation}>{children}</Content> : null}
        </AnimatePresence>
      </div>
    </>
  );
};

export default Popover;
