import cn from 'classnames';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

import type { TeamCardDetailsProps } from './TeamCardDetails';
import TeamCardDetails from './TeamCardDetails';
import styles from './TeamCard.module.scss';

export type TeamCardProps = Pick<
  TeamCardDetailsProps,
  | 'orgUnit'
  | 'childTeamsCount'
  | 'membersCascadingCount'
  | 'headingLevel'
  | 'onAction'
  | 'strategyName'
> & {
  backgroundImage?: string;
  className?: string;
  extra?: ReactNode;
};

const TeamCard = ({
  orgUnit,
  backgroundImage,
  childTeamsCount,
  membersCascadingCount,
  headingLevel,
  onAction,
  extra: extraProp,
  className,
  strategyName,
}: TeamCardProps) => {
  const extra = useMemo<ReactNode>(() => {
    if (extraProp) {
      return <div className={styles.top}>{extraProp}</div>;
    }
    return null;
  }, [extraProp]);

  return (
    <section
      className={cn(
        styles.teamCard,
        backgroundImage && styles.masked,
        className,
      )}
      style={
        backgroundImage
          ? ({
              '--bg-image': `url(${backgroundImage})`,
            } as React.CSSProperties)
          : {}
      }
    >
      <div className={styles.container}>
        {extra}
        <TeamCardDetails
          orgUnit={orgUnit}
          childTeamsCount={childTeamsCount}
          membersCascadingCount={membersCascadingCount}
          headingLevel={headingLevel}
          onAction={onAction}
          strategyName={strategyName}
        />
      </div>
    </section>
  );
};

export default TeamCard;
