import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { strategyRoutesPaths } from 'strategy/strategy.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';

const NavigateToTeamStrategyRoot = () => {
  const { teamAdapter } = useTeamAdapter();

  const to = useMemo(
    () =>
      strategyRoutesPaths.root({
        params: {
          teamSlug: teamAdapter.toParam(),
        },
      }),
    [teamAdapter],
  );

  return <Navigate to={to} replace={true} />;
};

export default NavigateToTeamStrategyRoot;
