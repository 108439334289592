import { AnimatePresence, motion } from 'framer-motion';
import type { MouseEvent, ReactNode } from 'react';
import { forwardRef, useId } from 'react';
import { useKey } from 'react-use';
import type { SerializedStyles } from '@emotion/react';
import { css, useTheme } from '@emotion/react';

import type { Animation } from 'shared/types/animation.type';
import { fadeAnimation } from 'shared/animations/fade.animation';
import { slideFromBottomAnimation } from 'shared/animations/slide.animation';
import Portal from 'shared/components/Portal';

import { getDefaultModalContainerStyle } from './getDefaultModalContainerStyle';

export type BaseModalProps = {
  /** children needs to have at least one tabbable/focusable element */
  children: ReactNode;
  className?: string;
  containerAnimation?: Animation;
  containerClassName?: string;
  containerCss?: SerializedStyles;
  isOpen: boolean;
  modalAnimation?: Animation;
  onClose: () => void;
};

/**
 * Base component for creating modal components
 * @component
 * @property children prop needs to have at least one tabbable/focusable element
 * @example
 * const [isOpen, setIsOpen] = useToggle(false)
 * return (
 *   <BaseModal isOpen={isOpen} onClose={setIsOpen}>
 *    {children}
 *   </BaseModal>
 * )
 */
const BaseModal = forwardRef<HTMLDivElement, BaseModalProps>(
  (
    {
      isOpen,
      onClose,
      children,
      className,
      modalAnimation = fadeAnimation,
      containerAnimation = slideFromBottomAnimation,
      containerClassName,
      containerCss,
    },
    ref,
  ) => {
    const modalId = useId();
    const theme = useTheme();

    const isCurrentModalOnTop = () =>
      [...document.querySelectorAll('[role="dialog"]')]
        .at(-1)
        ?.getAttribute('id') === modalId;

    useKey('Escape', () => {
      if (isCurrentModalOnTop()) {
        onClose();
      }
    });

    return (
      <Portal>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className={isOpen && containerClassName}
              css={
                containerCss
                  ? isOpen
                    ? containerCss
                    : undefined
                  : containerClassName
                  ? isOpen
                    ? containerClassName
                    : undefined
                  : isOpen
                  ? getDefaultModalContainerStyle(theme)
                  : undefined
              }
              {...modalAnimation}
              onClick={stopPropagation}
            >
              <motion.div
                id={modalId}
                role={'dialog'}
                className={className}
                css={css({ backgroundColor: theme.color.white })}
                ref={ref}
                {...containerAnimation}
              >
                {children}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </Portal>
    );
  },
);

const stopPropagation = (event: MouseEvent) => {
  event.stopPropagation();
};

export default BaseModal;
