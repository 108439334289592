import type { ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { TeamCardProps } from 'team/TeamCard';
import TeamCard from 'team/TeamCard';
import { teamRoutesPaths } from 'team/team.routing.paths';
import { TeamAdapter } from 'team/TeamAdapter';
import Tag from 'shared/components/Tag';
import Space from 'shared/components/Space';

import OrgCardProvider, { useOrgCard } from './OrgCardProvider';
import OrgCardContextMenu from './OrgCardContextMenu';

export type OrgCardProps = {
  withLabel?: boolean;
  withMenu?: boolean;
} & Pick<TeamCardProps, 'headingLevel' | 'className'>;

const OrgCard = ({
  headingLevel,
  withLabel = false,
  withMenu = true,
  className,
}: OrgCardProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { org } = useOrgCard();

  const teamAdapter = useMemo(() => TeamAdapter.fromOrg(org), [org]);

  const handleTeamCardAction = useCallback<
    NonNullable<TeamCardProps['onAction']>
  >(
    (action) => {
      switch (action) {
        case 'name': {
          navigate(
            teamRoutesPaths.root({
              params: {
                teamSlug: teamAdapter.toParam(),
              },
            }),
          );
          break;
        }
        case 'membersCount': {
          navigate(
            teamRoutesPaths.members.root({
              params: {
                teamSlug: teamAdapter.toParam(),
              },
            }),
          );
          break;
        }
        case 'childTeamsCount': {
          navigate(
            teamRoutesPaths.teamHierarchy({
              params: {
                teamSlug: teamAdapter.toParam(),
              },
            }),
          );
          break;
        }
      }
    },
    [teamAdapter, navigate],
  );

  const label = useMemo<ReactNode>(() => {
    if (withLabel) {
      return <Tag variant={'alert'}>{t('team.organizationStrategy')}</Tag>;
    }
    return null;
  }, [t, withLabel]);

  const extra = useMemo<ReactNode>(
    () => (
      <Space>
        {label}
        {withMenu && <OrgCardContextMenu strategy={org.currentStrategy} />}
      </Space>
    ),
    [label, withMenu, org],
  );

  return (
    <TeamCard
      orgUnit={undefined}
      backgroundImage={org.backgroundPhotoUrl}
      childTeamsCount={org.orgUnits.length}
      membersCascadingCount={org.employeesCount || 0}
      headingLevel={headingLevel}
      extra={extra}
      onAction={handleTeamCardAction}
      className={className}
      strategyName={org.currentStrategy?.name}
    />
  );
};

const OrgCardWithProvider = (props: OrgCardProps) => (
  <OrgCardProvider>
    <OrgCard {...props} />
  </OrgCardProvider>
);

export default OrgCardWithProvider;
