import { motion } from 'framer-motion';
import cn from 'classnames';

import { spring } from 'shared/animations/spring.animation';

import type { Stat } from '../Stats.type';
import styles from './StatLine.module.scss';

type StatLineProps = {
  className?: string;
  color: Stat['color'];
  title?: Stat['name'];
  value: Stat['value'];
  variant?: 'standalone' | 'sibling';
};

const StatLine = ({
  title,
  value,
  color,
  className,
  variant = 'sibling',
}: StatLineProps) => (
  <motion.div
    title={`${title}: ${value}%`}
    animate={{ width: `${value}%` }}
    transition={spring}
    className={cn(
      styles.statsLine,
      {
        [styles.standalone]: variant === 'standalone',
      },
      className,
    )}
    style={{ backgroundColor: color }}
  />
);

export default StatLine;
