import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

import { ReactComponent as IdeaIcon } from 'shared/static/icons/icon-idea.svg';

const Container = styled.h3`
  display: flex;
  gap: 8px;
  font-size: 1.063rem;
  line-height: 1.5rem;

  svg {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-top: 2px;
  }
`;

const InfoCardHeader = ({ children }: PropsWithChildren) => {
  return (
    <Container>
      <IdeaIcon />
      {children}
    </Container>
  );
};

export default InfoCardHeader;
