import { useMemo } from 'react';
import type { PropsWithChildren } from 'react';
import cn from 'classnames';

import type { RoadmapItemProps } from './RoadmapItem';
import RoadmapItem from './RoadmapItem';
import styles from './Roadmap.module.scss';

export type RoadmapProps = PropsWithChildren<{
  className?: string;
  items: RoadmapItemProps[];
}>;

/**
 * Visual representation of interface items in a road-like layout, showing their sequence.
 */
const Roadmap = ({ items, className }: RoadmapProps) => {
  const children = useMemo(
    () => (
      <>
        {items.map((item, itemIndex, itemsArray) => (
          <RoadmapItem
            {...item}
            isFirst={itemIndex === 0}
            isLast={itemIndex === itemsArray.length - 1}
            key={itemIndex}
          />
        ))}
      </>
    ),
    [items],
  );

  return <div className={cn(styles.container, className)}>{children}</div>;
};

Roadmap.Item = RoadmapItem;

export default Roadmap;
