import { Trans, useTranslation } from 'react-i18next';

import type { InitiativeOverviewInitiativeStatus } from '../InitiativeOverview.type';
import styles from './InitiativeOverviewInsightsRowScoreComment.module.scss';

type InitiativeInsightsRowScoreCommentProps = {
  status: InitiativeOverviewInitiativeStatus;
};

const InitiativeInsightsRowScoreComment = ({
  status,
}: InitiativeInsightsRowScoreCommentProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.score}>
      <span>
        <Trans
          i18nKey={'initiative.initiativeInsights.recentInsights.score.onTime'}
          values={{
            value: status.keyIndicatorOnTime ? t('yes') : t('no'),
          }}
          components={{
            strong: <strong />,
          }}
        />
      </span>
      <span>
        <Trans
          i18nKey={
            'initiative.initiativeInsights.recentInsights.score.onBudget'
          }
          values={{
            value: status.keyIndicatorOnBudget ? t('yes') : t('no'),
          }}
          components={{
            strong: <strong />,
          }}
        />
      </span>
      <span>
        <Trans
          i18nKey={
            'initiative.initiativeInsights.recentInsights.score.onOutcome'
          }
          values={{
            value: status.keyIndicatorOnOutcome ? t('yes') : t('no'),
          }}
          components={{
            strong: <strong />,
          }}
        />
      </span>
    </div>
  );
};

export default InitiativeInsightsRowScoreComment;
