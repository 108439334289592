import { useDefinedContext } from 'shared/utils/context.utils';

import InitiativesContentLayoutProvider from './InitiativesContentLayoutProvider';
import { InitiativesContentLayoutContext } from './InitiativesContentLayoutProvider.context';

export const useInitiativesContentLayout = () =>
  useDefinedContext(InitiativesContentLayoutContext, {
    hookName: useInitiativesContentLayout.name,
    providerName: InitiativesContentLayoutProvider.name,
  });
