import { useCallback, useEffect } from 'react';
import last from 'lodash/last';
import first from 'lodash/first';

import type {
  MultiSelectProps,
  RenderLabelFn,
} from 'shared/components/MultiSelect';
import MultiSelect from 'shared/components/MultiSelect';

import PriorityMultiSelectLabel from './PriorityMultiSelectLabel';
import PriorityMultiSelectListBoxRow from './PriorityMultiSelectListBoxRow';
import type {
  PriorityMultiSelectItem,
  PriorityMultiSelectItemId,
} from './PriorityMultiSelect.type';
import PriorityMultiSelectProvider, {
  usePriorityMultiSelect,
} from './PriorityMultiSelectProvider';
import PriorityMultiSelectChipGroup from './PriorityMultiSelectChipGroup';

export type PriorityMultiSelectProps = Pick<
  MultiSelectProps,
  'triggerClassName'
>;

const PriorityMultiSelect = ({
  triggerClassName,
}: PriorityMultiSelectProps) => {
  const { state, items, hasPriorities } = usePriorityMultiSelect();

  const renderLabel = useCallback<RenderLabelFn<PriorityMultiSelectItem>>(
    () => <PriorityMultiSelectLabel state={state} />,
    [state],
  );

  useEffect(() => {
    const selectedKeys = [
      ...state.selectionManager.selectedKeys,
    ] as PriorityMultiSelectItemId[];
    if (selectedKeys.length > 1 && last(selectedKeys) === 'ALL') {
      state.selectionManager.replaceSelection('ALL');
    }
    if (selectedKeys.length > 1 && first(selectedKeys) === 'ALL') {
      state.selectionManager.toggleSelection('ALL');
    }
  }, [state.selectionManager]);

  if (!hasPriorities) return null;

  return (
    <MultiSelect<PriorityMultiSelectItem>
      state={state}
      items={items}
      ListBoxRow={PriorityMultiSelectListBoxRow}
      label={renderLabel}
      triggerClassName={triggerClassName}
    />
  );
};

PriorityMultiSelect.Provider = PriorityMultiSelectProvider;
PriorityMultiSelect.ChipGroup = PriorityMultiSelectChipGroup;

export default PriorityMultiSelect;
