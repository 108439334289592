import { createContext } from 'react';

import type { ThemeFragment } from './ThemesProvider.graphql.generated';

export type ThemeContextValue = {
  themes: ThemeFragment[];
};

export const ThemeContext = createContext<ThemeContextValue | undefined>(
  undefined,
);
