import { Routes, Route, Outlet } from 'react-router-dom';
import type { ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LookingAtCardBoardBoxIllustration } from 'shared/static/illustrations/illustration-looking-at-cardboard-box.svg';
import Result from 'shared/components/Result';
import Space from 'shared/components/Space';
import { useTeamAdapter } from 'team/TeamAdapter';
import RemoveOrgUnitMemberConfirmationModal from 'orgUnit/RemoveOrgUnitMemberConfirmationModal';
import EditUserProfileModal from 'user/EditUserProfileModal';
import CreateUserButton from 'user/CreateUserButton';
import DeactivateUserDialog from 'user/DeactivateUserDialog';
import ActivateUserDialog from 'user/ActivateUserDialog';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';

import TeamMembersAddMember from './TeamMembersAddMember';
import type { TeamMembersListViewProps } from './TeamMembersListView';
import TeamMembersListView from './TeamMembersListView';
import TeamMembersProvider, { useTeamMembers } from './TeamMembersProvider';
import { teamMembersRoutesPaths } from './TeamMembers.routing.paths';
import TeamMembersAddMemberButton from './TeamMembersAddMemberButton';
import TeamMembersLayout from './TeamMembersLayout';
import type { TeamMembersUserFragment } from './TeamMembersProvider/TeamMembersProvider.graphql.generated';

const TeamMembers = () => {
  const { t } = useTranslation();

  const { orgUnit } = useOrgUnit();

  const [editUserId, setEditUserId] = useState<string>();
  const [deactivateUserId, setDeactivateUserId] = useState<string>();
  const [activateUserId, setActivateUserId] = useState<string>();

  const { users } = useTeamMembers();

  const hasMembers = users.length > 0;

  const [orgUnitMemberToRemove, setOrgUnitMemberToRemove] =
    useState<Maybe<TeamMembersUserFragment>>(undefined);

  const isRemoveOrgUnitMemberConfirmationModalOpen = !!orgUnitMemberToRemove;

  const closeRemoveOrgUnitMemberConfirmationModalOpen = () =>
    setOrgUnitMemberToRemove(undefined);

  const handleListViewAction = useCallback<
    TeamMembersListViewProps['onAction']
  >((action, user) => {
    switch (action) {
      case 'editProfile':
        setEditUserId(user.id);
        break;
      case 'deactivate':
        setDeactivateUserId(user.id);
        break;
      case 'activate':
        setActivateUserId(user.id);
        break;
      case 'remove':
        setOrgUnitMemberToRemove(user);
        break;
    }
  }, []);

  const emptyState = useMemo<ReactNode>(() => {
    if (!hasMembers) {
      return (
        <Space isCentered={true}>
          <Result
            description={t('team.teamMembers.noMembers')}
            illustration={LookingAtCardBoardBoxIllustration}
            hasBackground={true}
            after={
              orgUnit ? <TeamMembersAddMemberButton /> : <CreateUserButton />
            }
          />
        </Space>
      );
    }
    return null;
  }, [hasMembers, t, orgUnit]);

  return (
    <>
      <TeamMembersLayout>
        <TeamMembersListView onAction={handleListViewAction} />

        {emptyState}

        <Outlet />
      </TeamMembersLayout>

      {deactivateUserId && (
        <DeactivateUserDialog
          userId={deactivateUserId}
          onClose={() => setDeactivateUserId(undefined)}
          isOpen={true}
        />
      )}

      {activateUserId && (
        <ActivateUserDialog
          userId={activateUserId}
          onClose={() => setActivateUserId(undefined)}
          isOpen={true}
        />
      )}

      {orgUnitMemberToRemove && orgUnit && (
        <RemoveOrgUnitMemberConfirmationModal
          isOpen={isRemoveOrgUnitMemberConfirmationModalOpen}
          onClose={closeRemoveOrgUnitMemberConfirmationModalOpen}
          orgUnitId={orgUnit.id}
          orgUnit={orgUnit}
          userId={orgUnitMemberToRemove.id}
        />
      )}

      {editUserId && (
        <EditUserProfileModal
          userId={editUserId}
          onClose={() => setEditUserId(undefined)}
        />
      )}
    </>
  );
};

const TeamMembersWithRouting = () => (
  <Routes>
    <Route path={teamMembersRoutesPaths.root()} element={<TeamMembers />}>
      <Route
        path={teamMembersRoutesPaths.addMember()}
        element={<TeamMembersAddMember />}
      />
    </Route>
  </Routes>
);

const TeamMembersWithProviderAndRouting = () => {
  const { teamAdapter } = useTeamAdapter();

  return (
    <TeamMembersProvider teamAdapter={teamAdapter}>
      <TeamMembersWithRouting />
    </TeamMembersProvider>
  );
};

export default TeamMembersWithProviderAndRouting;
