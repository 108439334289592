import { useTranslation } from 'react-i18next';

import Heading from 'shared/components/Heading';
import Attributes from 'strategy/Attributes';

import { useMetricOverview } from '../MetricOverviewProvider';
import styles from './MetricOverviewAttributes.module.scss';

const MetricOverviewAttributes = () => {
  const { t } = useTranslation();

  const { metric } = useMetricOverview();

  return (
    <>
      <Heading level={3} className={styles.heading}>
        {t('attributes.attributes')}
      </Heading>
      <Attributes attributes={metric?.attributes} />
    </>
  );
};

export default MetricOverviewAttributes;
