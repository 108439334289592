import Spinner from 'shared/spinner/Spinner';
import Layout from 'shared/components/Layout';

import ObjectiveRelatedObjectivesHeader from './ObjectiveRelatedObjectivesHeader';
import ObjectiveRelatedObjectivesProvider, {
  useRelatedObjectives,
} from './ObjectiveRelatedObjectivesProvider';
import styles from './ObjectiveRelatedObjectives.module.scss';
import ObjectiveRelatedObjectivesContent from './ObjectiveRelatedObjectivesContent';

const ObjectiveRelatedObjectives = () => {
  const { isRelatedObjectivesLoading } = useRelatedObjectives();

  return (
    <Layout.Content
      className={styles.layout}
      variant={'contained'}
      hasPadding={false}
    >
      {isRelatedObjectivesLoading ? (
        <Spinner.Circle />
      ) : (
        <>
          <ObjectiveRelatedObjectivesHeader />
          <ObjectiveRelatedObjectivesContent />
        </>
      )}
    </Layout.Content>
  );
};

export default () => (
  <ObjectiveRelatedObjectivesProvider>
    <ObjectiveRelatedObjectives />
  </ObjectiveRelatedObjectivesProvider>
);
