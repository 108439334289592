import { useDefinedContext } from 'shared/utils/context.utils';

import { UserMultiSelectProviderContext } from './UserMultiSelectProvider.context';
import UserMultiSelectProvider from './UserMultiSelectProvider';

export const useUserMultiSelect = () =>
  useDefinedContext(UserMultiSelectProviderContext, {
    providerName: UserMultiSelectProvider.name,
    hookName: useUserMultiSelect.name,
  });
