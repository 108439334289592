import type { Context, ServerError } from '@apollo/client';
import isObject from 'lodash/isObject';

export const withBearerToken = (context: Context, bearerToken: string) => ({
  ...context,
  headers: {
    ...context.headers,
    authorization: `Bearer ${bearerToken}`,
  },
});

export const isServerError = (error: unknown): error is ServerError =>
  isObject(error) && 'statusCode' in error;
