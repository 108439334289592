import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { date } from 'shared/services/date.service';

type DateFilterParams = {
  paramName?: string;
};

type DateFilterReturnType = {
  dateFilter: Maybe<Date>;
  setDateFilter: (value?: Maybe<Date>) => void;
};

export const useDateFilter = ({
  paramName = 'date',
}: DateFilterParams = {}): DateFilterReturnType => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dateFilter = useMemo<DateFilterReturnType['dateFilter']>(() => {
    const param = searchParams.get(paramName);
    if (param && date.isValid(date.parseISO(param))) {
      return date.parseISO(param);
    }
    return undefined;
  }, [searchParams, paramName]);

  const setDateFilter = useCallback<DateFilterReturnType['setDateFilter']>(
    (value) => {
      setSearchParams((searchParams) => {
        if (value && date.isValid(value)) {
          searchParams.set(
            paramName,
            date.formatIso(value, {
              representation: 'date',
            }),
          );
        } else {
          searchParams.delete(paramName);
        }
        return searchParams;
      });
    },
    [setSearchParams, paramName],
  );

  return {
    dateFilter,
    setDateFilter,
  };
};
