import { createContext } from 'react';

import type { SelectUserTenantInputOption } from '../SelectUserTenantInput.type';

export type SelectUserTenantInputProviderContextValue = {
  options: SelectUserTenantInputOption[];
};

export const SelectUserTenantInputProviderContext =
  createContext<SelectUserTenantInputProviderContextValue>({
    options: [],
  });
