import type { SetOptional } from 'type-fest';

import type { FormProps } from 'shared/form/Form';
import type { StatusCommentType } from 'types.graphql.generated';
import AiSuggestionsArrayField from 'ai/AiSuggestions/AiSuggestionsArrayField';

import CommentsFieldArray from './StatusCommentsFormCommentsFieldArray';
import {
  AddCommentWhenEmpty,
  createStatusCommentItem,
} from './StatusCommentsForm.utils';
import type {
  StatusCommentItem,
  StatusCommentsFormValues,
} from './StatusCommentsForm.type';

export type StatusCommentsFormProps<
  Type extends StatusCommentType = StatusCommentType,
> = SetOptional<
  Pick<
    FormProps<StatusCommentsFormValues<Type>>,
    | 'onSubmit'
    | 'onChange'
    | 'id'
    | 'className'
    | 'initialValues'
    | 'enableReinitialize'
  >,
  'initialValues'
> & {
  hasTypeHeadings?: boolean;
  suggestionField: string;
  type: StatusCommentType;
};

const StatusCommentsForm = <
  Type extends StatusCommentType = StatusCommentType,
>({
  hasTypeHeadings = true,
  type,
  suggestionField,
}: StatusCommentsFormProps<Type>) => {
  return (
    <>
      <CommentsFieldArray name={type} hasTypeHeadings={hasTypeHeadings} />
      <AiSuggestionsArrayField
        fieldName={type}
        suggestionField={suggestionField}
        createItem={(suggestion) =>
          createStatusCommentItem({ text: suggestion, type })
        }
        getItemValue={(item: StatusCommentItem) => item.text}
      />
      <AddCommentWhenEmpty<Type> />
    </>
  );
};

export default StatusCommentsForm;
