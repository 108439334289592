import { useCallback } from 'react';

import TreeList from 'shared/components/TreeList';
import type { TreeListProps } from 'shared/components/TreeList';
import type { Org } from 'types.graphql.generated';
import { TeamAdapter } from 'team/TeamAdapter';

import TeamsTreeListRowContent from './TeamsTreeListRowContent';
import type { TeamsTreeListOrgUnit } from './TeamsTreeList.type';
import type { TeamsTreeListRowContentProps } from './TeamsTreeListRowContent/TeamsTreeListRowContent';

type TeamsTreeListProps = {
  className?: string;
  orgKey: Org['orgKey'];
  orgUnits: TeamsTreeListOrgUnit[];
} & Pick<
  TreeListProps<TeamsTreeListOrgUnit>,
  'allExpanded' | 'selectedItemsIds'
> &
  Pick<TeamsTreeListRowContentProps, 'renderRowEnd'>;

const TeamsTreeList = ({
  orgKey,
  orgUnits,
  allExpanded,
  selectedItemsIds,
  className,
  renderRowEnd,
}: TeamsTreeListProps) => {
  const renderRowContent = useCallback<
    TreeListProps<TeamsTreeListOrgUnit>['renderRowContent']
  >(
    ({ item: orgUnit, dropdownButton }) => (
      <TeamsTreeListRowContent
        orgUnit={orgUnit}
        teamAdapter={TeamAdapter.fromOrgUnit(orgUnit, orgKey)}
        childTeamsCount={orgUnit.childOrgUnitTree.length}
        membersCount={orgUnit.orgUnitMembershipsCascadingTotalCount || 0}
        dropdownButton={dropdownButton}
        renderRowEnd={renderRowEnd}
      />
    ),
    [orgKey, renderRowEnd],
  );

  return (
    <TreeList<TeamsTreeListOrgUnit>
      items={orgUnits}
      getChildItems={(item) => item.childOrgUnits}
      getParentItem={(item) => item.parentOrgUnit}
      renderRowContent={renderRowContent}
      allExpanded={allExpanded}
      selectedItemsIds={selectedItemsIds}
      className={className}
    />
  );
};

export default TeamsTreeList;
