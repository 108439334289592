import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import type { ConfirmationModalProps } from 'shared/components/__DEPRECATED__/ConfirmationModal';
import ConfirmationModal from 'shared/components/__DEPRECATED__/ConfirmationModal';
import { useLaunchStrategy } from 'strategy/useLaunchStrategy';
import type { Strategy } from 'types.graphql.generated';
import type { TeamAdapter } from 'team/TeamAdapter';

export type LaunchStrategyConfirmationModalProps = Pick<
  ConfirmationModalProps,
  'isOpen' | 'onClose'
> & {
  onSuccess?: () => void;
  strategyId: Strategy['id'];
  teamAdapter: TeamAdapter;
};

const LaunchStrategyConfirmationModal = ({
  isOpen,
  onClose,
  strategyId,
  teamAdapter,
  onSuccess,
}: LaunchStrategyConfirmationModalProps) => {
  const { t } = useTranslation();

  const { launchStrategy } = useLaunchStrategy({
    strategyId: strategyId,
    teamAdapter,
  });

  const handleConfirm = useCallback(() => {
    launchStrategy({
      onSuccess: () => {
        onClose();
        onSuccess?.();
      },
    });
  }, [launchStrategy, onClose, onSuccess]);

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleConfirm}
    >
      {t('strategy.launchStrategyConfirmationModal.content')}
    </ConfirmationModal>
  );
};

export default LaunchStrategyConfirmationModal;
