import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

import { TeamAdapter } from './TeamAdapter';

type UseTeamAdapterReturnType = {
  teamAdapter: TeamAdapter;
};

export const useTeamAdapter = (): UseTeamAdapterReturnType => {
  const { teamSlug } = useParams() as {
    teamSlug?: string;
  };

  if (!teamSlug) {
    throw new Error(
      `${useTeamAdapter.name} must be used within a route with an teamSlug param`,
    );
  }

  const teamAdapter = useMemo<TeamAdapter>(
    () => TeamAdapter.fromParam(teamSlug),
    [teamSlug],
  );

  return {
    teamAdapter,
  };
};
