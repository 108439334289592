import { useTranslation } from 'react-i18next';

import { useActionsLayout } from 'actions/ActionsLayout/ActionsLayoutProvider/useActionsLayout';
import type { ActionsSort } from 'actions/actions.type';
import SelectV2 from 'shared/components/__DEPRECATED__/Select/V2';

const ObjectiveActionFilters = () => {
  const { t } = useTranslation();

  const { setActionsSort } = useActionsLayout();

  return (
    <SelectV2<{ label?: string; value: ActionsSort }, false>
      defaultValue={[
        {
          value: 'deadlineAsc',
          label: t('actions.filters.sort.deadlineAsc'),
        },
      ]}
      options={[
        {
          value: 'deadlineAsc',
          label: t('actions.filters.sort.deadlineAsc'),
        },
        {
          value: 'deadlineDesc',
          label: t('actions.filters.sort.deadlineDesc'),
        },
        {
          value: 'completionAsc',
          label: t('actions.filters.sort.completionAsc'),
        },
        {
          value: 'completionDesc',
          label: t('actions.filters.sort.completionDesc'),
        },
      ]}
      onChange={(option) => {
        setActionsSort(option!.value);
      }}
      size={'small'}
    />
  );
};

export default ObjectiveActionFilters;
