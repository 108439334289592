import zip from 'lodash/zip';
import compact from 'lodash/compact';

import {
  hasMission,
  hasVision,
  hasGoals,
  hasChoices,
  hasBehaviors,
  hasValues,
} from 'strategy/strategy.utils';

import type {
  StrategyRoadmapConfigItem,
  StrategyRoadmapConfigComponent,
} from './StrategyRoadmapConfig.type';
import type {
  StrategyRoadmapStrategy,
  StrategyRoadmapObjectivesByTheme,
} from '../StrategyRoadmap.type';
import MissionCard from '../StrategyRoadmapMissionCard';
import VisionCard from '../StrategyRoadmapVisionCard';
import GoalsCard from '../StrategyRoadmapGoalsCard';
import ChoicesCard from '../StrategyRoadmapChoicesCard';
import ThemesCard from '../StrategyRoadmapThemesCard';
import BehaviorsCard from '../StrategyRoadmapBehaviorsCard';
import ValuesCard from '../StrategyRoadmapValuesCard';

export class StrategyRoadmapConfig {
  items: StrategyRoadmapConfigItem[];
  isEmpty: boolean;
  private readonly strategy: StrategyRoadmapStrategy;
  private readonly objectivesByTheme: StrategyRoadmapObjectivesByTheme[];

  constructor(
    strategy: StrategyRoadmapStrategy,
    objectivesByTheme: StrategyRoadmapObjectivesByTheme[],
  ) {
    this.strategy = strategy;
    this.objectivesByTheme = objectivesByTheme;
    this.items = this.getItems();
    this.isEmpty = !this.items.length;
  }

  private getItems(): StrategyRoadmapConfigItem[] {
    const components = this.getComponents();
    const roadmapItemColumns = StrategyRoadmapConfig.getRoadmapItemColumns();
    return compact(
      zip(roadmapItemColumns, components).map(
        ([roadmapItemColumn, component]) => {
          if (roadmapItemColumn && component) {
            return {
              component,
              column: roadmapItemColumn,
            };
          }
        },
      ),
    );
  }

  private getComponents(): StrategyRoadmapConfigComponent[] {
    const components = [];
    if (hasMission(this.strategy)) {
      components.push(MissionCard);
    }
    if (hasVision(this.strategy)) {
      components.push(VisionCard);
    }
    if (hasGoals(this.strategy)) {
      components.push(GoalsCard);
    }
    if (hasChoices(this.strategy)) {
      components.push(ChoicesCard);
    }
    if (this.objectivesByTheme.length > 0) {
      components.push(ThemesCard);
    }
    if (hasBehaviors(this.strategy)) {
      components.push(BehaviorsCard);
    }
    if (hasValues(this.strategy)) {
      components.push(ValuesCard);
    }
    return components;
  }

  private static getRoadmapItemColumns(): StrategyRoadmapConfigItem['column'][] {
    return [
      {
        start: 5,
        end: 5,
      },
      {
        start: 5,
        end: 4,
      },
      {
        start: 4,
        end: 3,
      },
      {
        start: 3,
        end: 1,
      },
      {
        start: 1,
        end: 3,
      },
      {
        start: 3,
        end: 2,
      },
      {
        start: 2,
        end: 2,
      },
    ];
  }
}
