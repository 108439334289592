import compact from 'lodash/compact';

import type { BreadcrumbsProps } from 'shared/components/Breadcrumbs';
import { i18n } from 'shared/services/i18n.service';
import { TeamAdapter } from 'team/TeamAdapter';
import { teamRoutesPaths } from 'team/team.routing.paths';
import type { Org, OrgUnit, Strategy } from 'types.graphql.generated';

type ParentOrgUnitTree = Array<
  Pick<OrgUnit, 'id' | 'name'> & {
    parentOrgUnit?: Maybe<Pick<OrgUnit, 'id'>>;
  }
>;

export const getBreadcrumbsItems = (params: {
  activeOrg: Pick<Org, 'orgKey' | 'displayName'> & {
    currentStrategy?: Pick<Strategy, 'id'>;
  };
  orgUnit?: Maybe<
    Pick<OrgUnit, 'id' | 'name'> & {
      parentOrgUnitTree: ParentOrgUnitTree;
    }
  >;
  teamAdapter: TeamAdapter;
}): BreadcrumbsProps['items'] => {
  const { teamAdapter, orgUnit, activeOrg } = params;

  const parentOrgUnits: ParentOrgUnitTree = [];
  if (orgUnit?.parentOrgUnitTree?.length) {
    parentOrgUnits.push(
      orgUnit.parentOrgUnitTree.find((item) => !item?.parentOrgUnit) as OrgUnit,
    );
    for (let i = 0; i < orgUnit?.parentOrgUnitTree?.length - 1; i++) {
      const find = orgUnit.parentOrgUnitTree.find(
        (item) =>
          item.parentOrgUnit?.id ===
          parentOrgUnits[parentOrgUnits.length - 1]?.id,
      );
      if (find) {
        parentOrgUnits.push(find);
      }
    }
  }

  return compact([
    {
      name: activeOrg.displayName as string,
      to: teamRoutesPaths.root({
        params: {
          teamSlug: TeamAdapter.fromOrg(activeOrg).toParam(),
        },
      }),
    },
    ...parentOrgUnits.map((item) => ({
      name: item.name as string,
      to: teamRoutesPaths.root({
        params: {
          teamSlug: TeamAdapter.fromOrgUnit(item, activeOrg.orgKey).toParam(),
        },
      }),
    })),
    orgUnit
      ? {
          name: orgUnit.name as string,
          to: teamRoutesPaths.root({
            params: {
              teamSlug: teamAdapter.toParam(),
            },
          }),
        }
      : undefined,
    {
      name: i18n.t('team.teamHierarchy.title'),
    },
  ]);
};
