import styled from '@emotion/styled';

const Container = styled.span`
  font-family: 'Noto Color Emoji';
  font-size: 0.85em;
`;

type Props = {
  className?: string;
  icon: string;
};

const Emoji = ({ icon: content, className }: Props) => (
  <Container className={className}>{content}</Container>
);

export default Emoji;
