import { useCallback } from 'react';
import compact from 'lodash/compact';
import { useTranslation } from 'react-i18next';

import type { Theme } from 'types.graphql.generated';
import ThemeButton from 'themes/ThemeButton';

import { useTeamInsightsOverview } from '../TeamInsightsOverviewProvider';
import styles from './TeamInsightsOverviewThemes.module.scss';

const TeamInsightsOverviewThemes = () => {
  const { t } = useTranslation();

  const {
    objectivesByTheme,
    filters: { themeFilter, setThemeFilter },
  } = useTeamInsightsOverview();

  const handlePressThemeChip = useCallback(
    (themeId?: Theme['id']) => {
      setThemeFilter(themeId ? [themeId] : []);
    },
    [setThemeFilter],
  );

  if (!objectivesByTheme.length) {
    return null;
  }

  return (
    <div className={styles.container}>
      {objectivesByTheme.map(({ theme, objectives }) => {
        const isPressed = theme?.id
          ? themeFilter.includes(theme.id)
          : !themeFilter.length;
        const objectiveStatuses = compact(
          objectives.map((objective) => objective.currentObjectiveStatus),
        );
        return (
          <ThemeButton
            theme={theme}
            objectiveStatuses={objectiveStatuses}
            onPress={() => {
              handlePressThemeChip(theme?.id);
            }}
            aria-pressed={isPressed}
            noThemeText={t('theme.allThemes')}
            key={theme?.id || 'allThemes'}
          />
        );
      })}
    </div>
  );
};

export default TeamInsightsOverviewThemes;
