import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Layout from 'shared/components/Layout';
import { useTeamAdapter } from 'team/TeamAdapter';
import Flex from 'shared/components/Flex';
import ToolEnabledTag from 'toolkit/ToolEnabledTag';
import useIsToolEnabled from 'toolkit/useIsToolEnabled';
import ToolIconHeader from 'toolkit/ToolIconHeader';

import { getBreadcrumbItems, getLinkTabsItems } from './OneThingLayout.utils';

const OneThingLayout = () => {
  const { teamAdapter } = useTeamAdapter();
  const { t } = useTranslation();
  const isEnabled = useIsToolEnabled('ONE_THING');

  return (
    <>
      <Layout.Header
        heading={
          <Flex justifyContent={'space-between'}>
            <ToolIconHeader tool={'oneThing'} variant={'title'} />
            <ToolEnabledTag tool={'ONE_THING'} />
          </Flex>
        }
        description={t('toolkit.tools.oneThing.description')}
        breadcrumbsItems={getBreadcrumbItems(teamAdapter, t)}
        navigationItems={getLinkTabsItems(teamAdapter, t, isEnabled)}
      />
      <Outlet />
    </>
  );
};

export default OneThingLayout;
