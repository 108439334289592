import type { CSSProperties } from 'react';

import type { Size } from 'shared/types/size.type';

import type { Column } from './Roadmap.type';
import styles from './Roadmap.module.scss';

const columnSizes: Partial<Record<Column, CSSProperties['width']>> = {
  2: styles.column2Size,
  3: styles.column3Size,
  5: styles.column5Size,
  6: styles.column6Size,
};

const contentSizes: Partial<Record<Size, CSSProperties['width']>> = {
  small: styles.contentSmallSize,
  medium: styles.contentMediumSize,
};

const spacerSizes: Partial<Record<Size, CSSProperties['width']>> = {
  small: styles.spacerSmallSize,
  medium: styles.spacerMediumSize,
};

export const roadmapConfig = {
  columnSizes,
  spacerSizes,
  contentSizes,
};
