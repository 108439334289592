import type { PropsWithChildren } from 'react';
import cn from 'classnames';

import Text from 'shared/components/Text';

import styles from './DescriptionsItem.module.scss';

type DescriptionsItemProps = PropsWithChildren<{
  className?: string;
  label: string;
}>;

const DescriptionsItem = ({
  label,
  children,
  className,
}: DescriptionsItemProps) => (
  <Text className={cn(styles.descriptionsItem, className)}>
    <Text className={styles.descriptionsItemLabel}>{label}</Text>
    <Text variant={'emphasis'}>{children}</Text>
  </Text>
);

export default DescriptionsItem;
