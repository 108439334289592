import { useEffect } from 'react';

import { useGlobalSpinner } from './GlobalSpinnerProvider';

const GlobalSpinner = () => {
  const { showGlobalSpinner, hideGlobalSpinner } = useGlobalSpinner();

  useEffect(() => {
    showGlobalSpinner();

    return hideGlobalSpinner;
  });

  return null;
};

export default GlobalSpinner;
