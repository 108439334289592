import { useSet } from 'react-use';
import { useCallback } from 'react';

import type { BaseTreeItem } from './useTree.type';

export type UseExpandableTreeItemsParams = {
  allExpanded?: boolean;
  expandedItemsIds?: BaseTreeItem['id'][];
};

export type UseExpandableTreeItemsReturnType<
  TreeItemParam extends BaseTreeItem,
> = {
  isItemExpanded: (itemId: TreeItemParam['id']) => boolean;
  toggleIsItemExpanded: (itemId: TreeItemParam['id']) => void;
};

export const useExpandableTreeItems = <TreeItemParam extends BaseTreeItem>(
  params: UseExpandableTreeItemsParams = {},
): UseExpandableTreeItemsReturnType<TreeItemParam> => {
  const { allExpanded, expandedItemsIds } = params;

  const [, { has, toggle: toggleIsItemExpanded }] = useSet<TreeItemParam['id']>(
    new Set(expandedItemsIds),
  );

  const isItemExpanded = useCallback(
    (itemId: TreeItemParam['id']) => {
      if (allExpanded) {
        return !has(itemId);
      }
      return has(itemId);
    },
    [has, allExpanded],
  );

  return {
    isItemExpanded,
    toggleIsItemExpanded,
  };
};
