import type { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import type {
  SideModalProps,
  SideModalJumbotronProps,
} from 'shared/components/__DEPRECATED__/SideModal';
import SideModal from 'shared/components/__DEPRECATED__/SideModal';
import { date } from 'shared/services/date.service';
import Space from 'shared/components/Space';
import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';
import useCanPerformOrgOrOrgUnitAction from 'user/ability/useCanPerformOrgOrOrgUnitAction';

import styles from './StrategyRoadmapSideModal.module.scss';

export type StrategyRoadmapSideModalProps = PropsWithChildren<
  Pick<SideModalProps, 'isOpen' | 'onClose'> &
    Pick<SideModalJumbotronProps, 'top' | 'heading' | 'description'> & {
      onEdit?: () => void;
      updateDateTime?: Maybe<Date>;
    }
>;

const StrategyRoadmapSideModal = ({
  isOpen,
  onClose,
  top,
  description,
  heading,
  updateDateTime: updateDateTimeProp,
  onEdit,
  children,
}: StrategyRoadmapSideModalProps) => {
  const { t } = useTranslation();

  const canManage = useCanPerformOrgOrOrgUnitAction('WRITE_STRATEGY', 'WRITE');

  const buttons = useMemo(
    () =>
      [
        canManage &&
          onEdit && {
            onClick: onEdit,
            icon: EditIcon,
            children: t('edit'),
          },
      ].filter(Boolean),
    [canManage, onEdit, t],
  );

  const updateDateTime = useMemo(() => {
    if (updateDateTimeProp) {
      const distance = date.formatDistanceToNow(updateDateTimeProp, {
        addSuffix: true,
      });
      return (
        <Trans
          i18nKey={'updatedAt'}
          values={{
            date: distance,
          }}
        />
      );
    }
    return null;
  }, [updateDateTimeProp]);

  return (
    <SideModal
      isOpen={isOpen}
      onClose={onClose}
      buttons={buttons}
      title={heading}
      scrollOffsetAfterTopBarIsVisible={200}
    >
      <Space direction={'vertical'}>
        <SideModal.Jumbotron
          top={top}
          heading={heading}
          description={description}
        />
        <SideModal.Content className={styles.content}>
          {children}
          <SideModal.Footer end={updateDateTime} className={styles.footer} />
        </SideModal.Content>
      </Space>
    </SideModal>
  );
};

export default StrategyRoadmapSideModal;
