import type { TFunction } from 'i18next';

import type { TeamAdapter } from 'team/TeamAdapter';
import { toolkitRoutesPaths } from 'toolkit/toolkit.routing.paths';

export const getBreadcrumbItems = (
  teamAdapter: TeamAdapter,
  t: TFunction,
  campaignName: string,
) => [
  {
    name: t('toolkit.toolkit'),
    to: toolkitRoutesPaths.root({
      params: {
        teamSlug: teamAdapter.toParam(),
      },
    }),
  },
  {
    name: t('toolkit.tools.oneThing.name'),
    to: toolkitRoutesPaths.oneThing.root({
      params: {
        teamSlug: teamAdapter.toParam(),
      },
    }),
  },
  { name: campaignName },
];
