import { Navigate } from 'react-router-dom';
import { useMemo } from 'react';

import { metricOverviewRoutesPaths } from '../MetricOverview.routing.paths';

const MetricOverviewNavigateToInsights = () => {
  const to = useMemo(() => metricOverviewRoutesPaths.insights.root(), []);

  return <Navigate to={to} replace={true} />;
};

export default MetricOverviewNavigateToInsights;
