import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserLimitedProfileFragmentDoc } from '../../user/userProfile.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetWalkOfStrategyCampaignQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetWalkOfStrategyCampaignQuery = { __typename: 'Query', campaign: { __typename: 'Campaign', id: string, name: string, deadline?: any | undefined, strategy: { __typename: 'Strategy', id: string, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.OrgUnitAuthorizedAction> } | undefined }, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined }, owner: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } } };


export const GetWalkOfStrategyCampaignDocument = gql`
    query GetWalkOfStrategyCampaign($id: ID!) {
  campaign(id: $id) {
    id
    name
    strategy {
      id
      orgUnit {
        id
        name
        currentUserAuthorizedActions
      }
    }
    deadline
    timeLine {
      startDate
      endDate
    }
    owner {
      ...userLimitedProfile
    }
  }
}
    ${UserLimitedProfileFragmentDoc}`;

/**
 * __useGetWalkOfStrategyCampaignQuery__
 *
 * To run a query within a React component, call `useGetWalkOfStrategyCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalkOfStrategyCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalkOfStrategyCampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWalkOfStrategyCampaignQuery(baseOptions: Apollo.QueryHookOptions<GetWalkOfStrategyCampaignQuery, GetWalkOfStrategyCampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWalkOfStrategyCampaignQuery, GetWalkOfStrategyCampaignQueryVariables>(GetWalkOfStrategyCampaignDocument, options);
      }
export function useGetWalkOfStrategyCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWalkOfStrategyCampaignQuery, GetWalkOfStrategyCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWalkOfStrategyCampaignQuery, GetWalkOfStrategyCampaignQueryVariables>(GetWalkOfStrategyCampaignDocument, options);
        }
export type GetWalkOfStrategyCampaignQueryHookResult = ReturnType<typeof useGetWalkOfStrategyCampaignQuery>;
export type GetWalkOfStrategyCampaignLazyQueryHookResult = ReturnType<typeof useGetWalkOfStrategyCampaignLazyQuery>;
export type GetWalkOfStrategyCampaignQueryResult = Apollo.QueryResult<GetWalkOfStrategyCampaignQuery, GetWalkOfStrategyCampaignQueryVariables>;