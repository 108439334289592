import type { TypePolicies, ApolloLink } from '@apollo/client';
import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { datadogRum } from '@datadog/browser-rum';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import { getIdToken } from 'auth/auth.utils';
import { idTokenLink } from 'auth/idTokenLink';
import { withBearerToken, isServerError } from 'shared/utils/apollo.utils';
import env from 'environment';
import { ActingOrg } from 'org/ActingOrg';

const httpLink = new HttpLink({
  uri: env.REACT_APP_API_URL,
});

const subscriptionsUrl = env.REACT_APP_API_URL.replace(
  'https://',
  'wss://',
).replace('/graphql', '/subscriptions');

const wsLink = new GraphQLWsLink(
  createClient({
    url: subscriptionsUrl,
    connectionParams: async () => ({
      authToken: await getIdToken(),
      ...ActingOrg.getHeaders(),
    }),
  }),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const authLink = setContext(async (_, previousContext) => {
  const idToken = await getIdToken();
  if (idToken) {
    return withBearerToken(previousContext, idToken);
  }
  return previousContext;
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => datadogRum.addError(error));
    }

    if (networkError) {
      datadogRum.addError(networkError);

      if (isServerError(networkError) && networkError.statusCode === 401) {
        getIdToken().then((idToken) => {
          if (idToken) {
            const previousContext = operation.getContext();
            operation.setContext(withBearerToken(previousContext, idToken));
            return forward(operation);
          }
        });
      }
    }
  },
);

const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error) => isServerError(error) && error.statusCode === 401,
  },
});

export const createApolloClient = (
  params: {
    additionalLinks?: ApolloLink[];
    typePolicies?: TypePolicies;
  } = {},
) => {
  const { additionalLinks = [], typePolicies } = params;
  return new ApolloClient({
    link: from([
      ...additionalLinks,
      retryLink,
      authLink,
      idTokenLink,
      errorLink,
      splitLink,
    ]),
    cache: new InMemoryCache({
      typePolicies,
    }),
    connectToDevTools: true,
  });
};
