import { useMemo } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { determineIfIsOverdue } from 'actions/action.utils';
import ActionChipOverdue from 'actions/ActionChipOverdue';
import type { ActionWithObjective } from 'actions/actions.type';
import { ReactComponent as DateIcon } from 'shared/static/icons/icon-date.svg';
import { ReactComponent as CheckSquareIcon } from 'shared/static/icons/icon-check-square.svg';
import UserAvatar from 'user/UserAvatar';
import { date } from 'shared/services/date.service';
import CompletionRate from 'shared/components/CompletionRate';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { useActionsLayout } from 'actions/ActionsLayout/ActionsLayoutProvider/useActionsLayout';
import { useTeamAdapter } from 'team/TeamAdapter';

import styles from './ActionCard.module.scss';

type ActionCardProps = {
  action: ActionWithObjective;
};

const ActionCard = ({ action }: ActionCardProps) => {
  const { teamAdapter } = useTeamAdapter();
  const { layout } = useActionsLayout();

  const navigate = useNavigate();

  const { name, description, timeLine, owner } = action;

  const isActionOverdue = useMemo(() => determineIfIsOverdue(action), [action]);

  const handleNavigateToAction = () => {
    navigate(
      objectiveRoutesPaths.actions.action.root({
        params: {
          teamSlug: teamAdapter.toParam(),
          objectiveId: action.objective.id,
          actionId: action.id,
        },
        query: {
          view: layout,
        },
      }),
      {
        preventScrollReset: true,
      },
    );
  };

  return (
    <div className={styles.card} onClick={handleNavigateToAction}>
      <div className={styles.content}>
        <div className={styles.name}>
          <CheckSquareIcon className={styles.nameIcon} />
          <div className={styles.nameText}>{name}</div>
        </div>
        {description && <div className={styles.description}>{description}</div>}
      </div>
      <footer className={styles.footer}>
        <div className={styles.timeline}>
          <DateIcon className={styles.timelineIcon} />
          <div
            className={cn(styles.timelineText, {
              [styles.timelineTextOverdue]: isActionOverdue,
            })}
          >
            {date.format(timeLine.endDate, 'dd MMM')}
          </div>
        </div>
        {isActionOverdue && <ActionChipOverdue />}
        <CompletionRate
          complete={action.currentStatus?.complete}
          completionRate={action.currentStatus?.completionRate}
        />
        <UserAvatar user={owner} containerClassName={styles.avatar} />
      </footer>
    </div>
  );
};

export default ActionCard;
