import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.color.strokeMedium};
  border-radius: 20px;
  overflow: hidden;
`;

type Props = PropsWithChildren;

const FormContainer = ({ children }: Props) => {
  return <Container>{children}</Container>;
};

export default FormContainer;
