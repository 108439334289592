import InitiativeOverviewHeader from '../InitiativeOverviewHeader/InitiativeOverviewHeader';
import InitiativeOverviewList from '../InitiativeOverviewList';

const InitiativeOverviewInsights = () => (
  <>
    <InitiativeOverviewHeader />
    <InitiativeOverviewList />
  </>
);

export default InitiativeOverviewInsights;
