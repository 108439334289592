import type { Org, OrgUnit } from 'types.graphql.generated';

import type {
  TeamAdapterParams,
  TeamAdapterTypename,
  TeamAdapterKeyArg,
  TeamAdapterInput,
  OrgForTeamAdapter,
} from './TeamAdapter.type';

/**
 * Facilitates seamless feature implementation in context of both Org and OrgUnit.
 */
export class TeamAdapter {
  typeName: TeamAdapterTypename;
  keyArg: TeamAdapterKeyArg;
  orgKey: Org['orgKey'];

  private constructor(params: TeamAdapterParams) {
    const { keyArg, typeName, orgKey } = params;
    this.typeName = typeName;
    this.keyArg = keyArg;
    this.orgKey = orgKey;
  }

  get isOrg(): boolean {
    return this.typeName === 'Org';
  }

  get isOrgUnit(): boolean {
    return this.typeName === 'OrgUnit';
  }

  toParam(): string {
    if (this.isOrgUnit) {
      return `${this.orgKey}:${this.keyArg}`;
    }
    return this.keyArg;
  }

  toInput(): TeamAdapterInput {
    if (this.isOrgUnit) {
      return {
        orgKey: this.orgKey,
        orgUnit: {
          idToSet: this.keyArg,
        },
      };
    }
    return {
      orgKey: this.orgKey,
    };
  }

  toOrgUnit() {
    if (!this.isOrgUnit) throw new Error('not OrgUnit');

    return { id: this.keyArg, __typename: 'OrgUnit' };
  }

  static fromParam(param: string): TeamAdapter {
    const { orgKey, orgUnitId } = this.parseParam(param);
    if (orgUnitId) {
      return this.fromOrgUnit({ id: orgUnitId }, orgKey);
    }
    return this.fromOrg({ orgKey });
  }

  static fromOrg(org: OrgForTeamAdapter): TeamAdapter {
    return new this({
      keyArg: org.orgKey,
      orgKey: org.orgKey,
      typeName: 'Org',
    });
  }

  static fromOrgUnit(
    orgUnit: Pick<OrgUnit, 'id'>,
    orgKey: Org['orgKey'],
  ): TeamAdapter {
    return new this({
      keyArg: orgUnit.id,
      orgKey: orgKey,
      typeName: 'OrgUnit',
    });
  }

  static fromOrgUnitOrOrg(
    org: OrgForTeamAdapter,
    orgUnit?: Pick<OrgUnit, 'id'>,
  ): TeamAdapter {
    return orgUnit ? this.fromOrgUnit(orgUnit, org.orgKey) : this.fromOrg(org);
  }

  static parseParam(param: string): {
    orgKey: string;
    orgUnitId?: string;
  } {
    const [orgKey, orgUnitId] = param.split(':');
    if (!orgKey) {
      throw new Error(`Invalid TeamAdapter param: ${param}`);
    }
    return {
      orgKey,
      orgUnitId,
    };
  }
}
