import type { ComponentType, PropsWithChildren, SVGProps } from 'react';

import Caption from 'shared/components/Caption';

import styles from './CollapsibleListTitle.module.scss';

type CollapsibleListTitleProps = PropsWithChildren<{
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
}>;

const CollapsibleListTitle = ({
  children,
  icon: Icon,
}: CollapsibleListTitleProps) => (
  <div className={styles.title}>
    {Icon && <Icon className={styles.icon} />}
    <Caption size={'C2'} color={'secondary'}>
      {children}
    </Caption>
  </div>
);

export default CollapsibleListTitle;
