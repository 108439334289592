import { useTranslation } from 'react-i18next';

import TextButton from '../TextButton';

type Props = { onClear?: () => void };

const ClearAllButton = ({ onClear }: Props) => {
  const { t } = useTranslation();

  if (!onClear) return null;

  return <TextButton onPress={onClear}>{t('clearAll')}</TextButton>;
};

export default ClearAllButton;
