import { useTranslation } from 'react-i18next';

import Emoji from 'shared/components/Emoji';
import OneThingCampaignRequestButton from 'toolkit/OneThingCampaignRequestButton';
import type { ContributionMyFollowUpsOneThingCampaignFragment } from 'contribution/ContributionMyFollowUpsViewMore/ContributionMyFollowUpsViewMore.graphql.generated';
import OneThingCampaignRequestNotice from 'toolkit/OneThingCampaignRequestNotice';

import Description from './Description';
import Header from './Header';
import Icon from './Icon';
import Title from './Title';

type Props = {
  campaign: ContributionMyFollowUpsOneThingCampaignFragment;
};

const OneThingCampaignGuide = ({ campaign }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Header>
        <Icon>
          <Emoji icon={t('toolkit.tools.oneThing.symbol')} />
        </Icon>
        <Title>{t('toolkit.tools.oneThing.name')}</Title>
      </Header>
      <Description>
        <OneThingCampaignRequestNotice campaign={campaign} />
      </Description>
      <OneThingCampaignRequestButton campaign={campaign} variant={'outlined'} />
    </>
  );
};

export default OneThingCampaignGuide;
