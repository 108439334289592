import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';

import type { CommentsFormProps } from 'shared/components/CommentsForm';
import CommentsForm from 'shared/components/CommentsForm';
import Heading from 'shared/components/Heading';
import Text from 'shared/components/Text';
import type { Metric, MetricStatus } from 'types.graphql.generated';
import StatusCommentsInfoCard from 'shared/status/StatusCommentsInfoCard';
import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import Space from 'shared/components/Space';
import StatusMark from 'shared/status/StatusMark';
import PreviousInsightsSidecard from 'status/PreviousInsightsSidecard/PreviousInsightsSidecard';
import { usePreviousInsights } from 'status/PreviousInsightsSidecard/usePreviousInsights';

import styles from './MetricStatusWizardComments.module.scss';

type MetricStatusWizardCommentsProps = Pick<
  CommentsFormProps,
  'id' | 'initialValues' | 'onChange' | 'onSubmit' | 'onError'
> & {
  metric: Pick<Metric, 'name'> & {
    currentMetricStatus?: Pick<MetricStatus, 'statusIndicator'>;
  };
};

const MetricStatusWizardComments = ({
  initialValues,
  onSubmit,
  metric,
  onChange,
  id,
}: MetricStatusWizardCommentsProps) => {
  const { t } = useTranslation();

  const [isSidesheetOpen, setIsSidesheetOpen] = useState(false);

  const { onOpenPreviousInsights } = usePreviousInsights();

  const renderForm = () => (
    <>
      <Heading level={3}>
        {t('metric.metricStatusWizard.steps.comments.heading')}
      </Heading>

      <Text className={styles.description}>
        <Trans
          i18nKey={'metric.metricStatusWizard.steps.comments.description'}
          values={{
            metricName: metric.name,
          }}
        >
          <Text variant={'emphasis'} />
        </Trans>
      </Text>

      {metric.currentMetricStatus ? (
        <Space className={styles.metricStatus}>
          <Text variant={'emphasis'}>
            {t('metric.metricStatusWizard.steps.comments.metricStatusLabel')}
          </Text>
          <StatusMark
            statusIndicator={metric.currentMetricStatus?.statusIndicator}
          />
        </Space>
      ) : null}

      <CommentsForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        onChange={onChange}
        id={id}
        onShowPreviousInsights={onOpenPreviousInsights}
      />
    </>
  );

  const renderInfoCard = () => (
    <StatusCommentsInfoCard
      isSidesheetOpen={isSidesheetOpen}
      setIsSidesheetOpen={setIsSidesheetOpen}
    />
  );

  return (
    <FormWithInfoCardLayout
      renderForm={renderForm}
      renderInfoCard={renderInfoCard}
      renderAfterInfoCard={<PreviousInsightsSidecard />}
    />
  );
};

export default MetricStatusWizardComments;
