import type { AriaSelectProps } from 'react-aria';
import type { SelectState } from 'react-stately';
import { useSelectState } from 'react-stately';
import type { Key, ReactNode } from 'react';
import { useRef } from 'react';
import { Overlay, usePopover, useSelect } from 'react-aria';
import { css, type SerializedStyles } from '@emotion/react';

import SelectOverlayTrigger from 'shared/components/SelectOverlayTrigger';
import type { ListBoxRowComponent } from 'shared/components/ListBox';
import ListBox, {
  useListBoxCollectionChildren,
} from 'shared/components/ListBox';
import useElementWidth from 'shared/hooks/useElementWidth';

import type { BaseSelectItem } from './Select.type';

export type SelectProps<SelectItem extends BaseSelectItem> = Pick<
  AriaSelectProps<SelectItem>,
  'items' | 'disabledKeys' | 'isDisabled'
> & {
  ListBoxRow?: ListBoxRowComponent<SelectItem>;
  emptyOptionLabel?: string;
  fullWidth?: boolean;
  hasError?: boolean;
  label?: ReactNode;
  onChange?: (value?: SelectItem) => void;
  placeholder?: ReactNode;
  state?: SelectState<SelectItem>;
  triggerStyle?: SerializedStyles;
  value?: Maybe<SelectItem>;
};

const emptyOptionId = 'empty';

/**
 * Allow a user to choose a single option from a collapsible list of options.
 */
const Select = <SelectItem extends BaseSelectItem>({
  items = [],
  disabledKeys,
  isDisabled,
  state: stateProp,
  placeholder,
  label,
  ListBoxRow,
  triggerStyle,
  value,
  onChange,
  fullWidth,
  emptyOptionLabel,
  hasError,
}: SelectProps<SelectItem>) => {
  const triggerElementRef = useRef<HTMLButtonElement>(null);
  const popoverElementRef = useRef<HTMLDivElement>(null);
  const triggerWidth = useElementWidth(triggerElementRef);

  const collectionChildren = useListBoxCollectionChildren({ Row: ListBoxRow });

  const allItems = emptyOptionLabel
    ? [{ id: emptyOptionId, title: emptyOptionLabel } as SelectItem, ...items]
    : items;

  const onSelectionChange = onChange
    ? (selectedKey: Key) => {
        if (selectedKey === emptyOptionId) {
          onChange(undefined);
        } else {
          const selectedItem = Array.from(allItems)
            .flatMap((item) => (item.children ? item.children : [item]))
            .find((item) => item.id === selectedKey);

          onChange(selectedItem as SelectItem);
        }
      }
    : undefined;

  const internalState = useSelectState({
    items: allItems,
    selectedKey: value ? value.id : emptyOptionId,
    disabledKeys,
    isDisabled,
    onSelectionChange,
    children: collectionChildren,
  });
  const state = stateProp ?? internalState;

  const { triggerProps, valueProps, menuProps } = useSelect(
    { items: allItems, isDisabled },
    state,
    triggerElementRef,
  );

  const { popoverProps } = usePopover(
    {
      triggerRef: triggerElementRef,
      popoverRef: popoverElementRef,
      offset: 5,
    },
    state,
  );

  return (
    <>
      <SelectOverlayTrigger
        {...triggerProps}
        css={triggerStyle}
        ref={triggerElementRef}
        fullWidth={fullWidth}
        hasError={hasError}
      >
        <span {...valueProps}>
          {label ||
            (state.selectedItem ? state.selectedItem.rendered : placeholder)}
        </span>
      </SelectOverlayTrigger>

      {state.isOpen && (
        <Overlay>
          <div {...popoverProps} ref={popoverElementRef}>
            <ListBox
              {...menuProps}
              style={css({ minWidth: triggerWidth })}
              items={items}
              state={state}
              Row={ListBoxRow}
              selectionMode={'single'}
            />
          </div>
        </Overlay>
      )}
    </>
  );
};

export default Select;
