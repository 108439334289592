import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
export type UpdateObjectiveStatusFragment = { __typename: 'ObjectiveStatus', id: string, complete: boolean, comment?: string | undefined, statusIndicator: StatusIndicatorClass, statusDateTime: any, actionComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }>, challengeComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }>, successComments: Array<{ __typename: 'TextItem', id: string, text?: string | undefined }>, auditRecord: { __typename: 'AuditRecord', createDateTime?: any | undefined, updateDateTime?: any | undefined, createBy?: { __typename: 'AuditRecordUser', displayName?: string | undefined, photoUrl?: string | undefined, email?: string | undefined } | undefined, updateBy?: { __typename: 'AuditRecordUser', displayName?: string | undefined, photoUrl?: string | undefined, email?: string | undefined } | undefined }, objective: { __typename: 'Objective', id: string, timeLine: { __typename: 'TimeLine', completeDateTime?: any | undefined }, currentObjectiveStatus?: { __typename: 'ObjectiveStatus', id: string } | undefined, objectiveStatusListAll: Array<{ __typename: 'ObjectiveStatus', id: string }> } };

export type UpdateStatusAuditRecordFragment = { __typename: 'AuditRecord', createDateTime?: any | undefined, updateDateTime?: any | undefined, createBy?: { __typename: 'AuditRecordUser', displayName?: string | undefined, photoUrl?: string | undefined, email?: string | undefined } | undefined, updateBy?: { __typename: 'AuditRecordUser', displayName?: string | undefined, photoUrl?: string | undefined, email?: string | undefined } | undefined };

export type UpdateObjectiveStatusCommentFragment = { __typename: 'TextItem', id: string, text?: string | undefined };

export const UpdateObjectiveStatusCommentFragmentDoc = gql`
    fragment updateObjectiveStatusComment on TextItem {
  id
  text
}
    `;
export const UpdateStatusAuditRecordFragmentDoc = gql`
    fragment updateStatusAuditRecord on AuditRecord {
  createBy {
    displayName
    photoUrl
    email
  }
  updateBy {
    displayName
    photoUrl
    email
  }
  createDateTime
  updateDateTime
}
    `;
export const UpdateObjectiveStatusFragmentDoc = gql`
    fragment updateObjectiveStatus on ObjectiveStatus {
  id
  complete
  comment
  actionComments {
    ...updateObjectiveStatusComment
  }
  challengeComments {
    ...updateObjectiveStatusComment
  }
  successComments {
    ...updateObjectiveStatusComment
  }
  auditRecord {
    ...updateStatusAuditRecord
  }
  statusIndicator
  statusDateTime
  objective {
    id
    timeLine {
      completeDateTime
    }
    currentObjectiveStatus {
      id
    }
    objectiveStatusListAll {
      id
    }
  }
}
    ${UpdateObjectiveStatusCommentFragmentDoc}
${UpdateStatusAuditRecordFragmentDoc}`;