import cn from 'classnames';

import { StatusIndicator } from 'shared/status/StatusIndicator';
import StatusMark from 'shared/status/StatusMark';
import Space from 'shared/components/Space';
import Text from 'shared/components/Text';

import styles from './StatusTag.module.scss';

type StatusTagProps = {
  className?: string;
  statusIndicator?: StatusIndicator;
};

const StatusTag = ({
  statusIndicator = new StatusIndicator(),
  className,
}: StatusTagProps) => (
  <Space isCentered={true} className={cn(styles.container, className)}>
    <StatusMark statusIndicator={statusIndicator} hasBorder={false} />
    <Text className={styles.label}>{statusIndicator.getName()}</Text>
  </Space>
);

export default StatusTag;
