import styled from '@emotion/styled';

import { useQuestions, type SurveyQuestion } from './QuestionsProvider.context';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Icon = styled.div<{ visible: boolean }>`
  font-size: 18px;
  width: 26px;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

const Button = styled.div<{ isDisabled: boolean; selected: boolean }>`
  padding: 10px 12px;
  border-radius: 8px;
  user-select: none;
  flex-grow: 1;

  cursor: ${(props) =>
    props.isDisabled || props.selected ? 'default' : 'pointer'};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'initial')};

  color: ${(props) =>
    props.selected
      ? props.theme.color.black
      : props.isDisabled
      ? props.theme.color.neutral3
      : props.theme.color.typoSecondary};

  background-color: ${(props) =>
    props.selected
      ? props.theme.color.backgroundDark
      : props.theme.color.white};

  &:hover {
    background-color: ${(props) =>
      props.selected
        ? props.theme.color.backgroundDark
        : props.isDisabled
        ? props.theme.color.white
        : props.theme.color.backgroundLight};
  }
`;

type Props = {
  question: SurveyQuestion;
};

const IndexItem = ({ question }: Props) => {
  const {
    questions,
    currentQuestion,
    setCurrentQuestion,
    visitedQuestions,
    isReadOnly,
  } = useQuestions();

  const questionIndex = questions.indexOf(question);
  const currentQuestionIndex = questions.indexOf(currentQuestion);

  const isQuestionVisited = visitedQuestions.includes(question);

  const isDisabled = !isQuestionVisited;

  const isLastQuestion = questionIndex === questions.length - 1;
  const isCurrentQuestion = question === currentQuestion;
  const isIconVisible = isReadOnly
    ? true
    : isCurrentQuestion
    ? questionIndex < currentQuestionIndex || isLastQuestion
    : isQuestionVisited;

  return (
    <Container key={question.id}>
      <Button
        selected={question === currentQuestion}
        onClick={() => setCurrentQuestion(question)}
        isDisabled={isDisabled}
      >
        {question.title}
      </Button>
      <Icon visible={isIconVisible}>{question.icon}</Icon>
    </Container>
  );
};

export default IndexItem;
