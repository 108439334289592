import type { RouteObject } from 'react-router-dom';

import AuthGuard from 'auth/AuthGuard';
import SignUp from 'auth/SignUp';
import FinishSignUpWithEmail from 'auth/FinishSignUpWithEmail';

import { authRoutesPaths } from './auth.routing.paths';
import FinishSignUpWithoutEmail from './FinishSignUpWithoutEmail/FinishSignUpWithoutEmail';
import SignUpWithPassword from './SignUpWithPassword';

export const authRoutes: RouteObject[] = [
  {
    path: authRoutesPaths.signUp(),
    element: (
      <AuthGuard forGuestOnly={true}>
        <SignUp />
      </AuthGuard>
    ),
  },
  {
    path: authRoutesPaths.signUpWithPassword(),
    element: (
      <AuthGuard forGuestOnly={true}>
        <SignUpWithPassword />
      </AuthGuard>
    ),
  },
  {
    path: authRoutesPaths.finishSignUp(),
    element: (
      <AuthGuard forGuestOnly={true}>
        <FinishSignUpWithEmail />
      </AuthGuard>
    ),
  },
  {
    path: authRoutesPaths.finishSignUpWithoutEmail(),
    element: (
      <AuthGuard forGuestOnly={true}>
        <FinishSignUpWithoutEmail />
      </AuthGuard>
    ),
  },
];
