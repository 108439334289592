import type {
  Initiative,
  InitiativeStatus,
  Objective,
  ObjectiveStatus,
} from 'types.graphql.generated';

import type { InitiativesContentInitiativeFragment } from './InitiativesContent.graphql.generated';

export const getObjectiveInitiatives = <
  TObjectiveStatus extends Pick<ObjectiveStatus, 'complete'>,
  TObjective extends Maybe<
    Pick<Objective, 'id'> & {
      currentObjectiveStatus?: TObjectiveStatus;
      initiatives?: InitiativesContentInitiativeFragment[];
    }
  >,
>(
  objectives: Maybe<TObjective[]>,
  initiativesIds?: Initiative['id'][],
) =>
  objectives?.reduce<InitiativesContentInitiativeFragment[]>(
    (acc, objective) => {
      const initiatives = initiativesIds
        ? objective?.initiatives?.filter((initiative) =>
            initiativesIds.includes(initiative.id),
          )
        : objective?.initiatives;
      return initiatives ? [...acc, ...initiatives] : acc;
    },
    [],
  );

export const getInitiativesActive = <
  TStatus extends Pick<InitiativeStatus, 'complete'>,
  TInitiative extends { currentInitiativeStatus?: TStatus },
>(
  initiatives: Maybe<TInitiative[]>,
) =>
  initiatives?.filter(
    (initiative) => !initiative.currentInitiativeStatus?.complete,
  );

export const getObjectivesWithInitiatives = <
  TObjective extends Maybe<
    Pick<Objective, 'id'> & {
      initiatives: Pick<Initiative, 'id'>[];
    }
  >,
>(
  objectives: Maybe<TObjective[]>,
) => objectives?.filter((objective) => !!objective?.initiatives?.length);

export const getObjectivesWithInitiativesActive = <
  TStatus extends Pick<InitiativeStatus, 'complete'>,
  TInitiative extends { currentInitiativeStatus?: TStatus; id: string },
  TObjective extends Maybe<
    Pick<Objective, 'id' | 'name'> & { initiatives: TInitiative[] }
  >,
>(
  objectives: Maybe<TObjective[]>,
  initiativesIds?: Initiative['id'][],
) =>
  objectives?.reduce<TObjective[]>((acc, cur) => {
    const initiatives = getInitiativesActive(cur?.initiatives);
    const filteredInitiatices =
      initiatives && initiativesIds
        ? initiatives.filter((initiative) =>
            initiativesIds?.includes(initiative?.id),
          )
        : initiatives;
    const objective = {
      ...cur,
      initiatives: filteredInitiatices,
    };

    return filteredInitiatices?.length ? [...acc, objective] : acc;
  }, []);
