import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';
import { css } from '@emotion/react';

import type { Initiative, Metric, Objective } from 'types.graphql.generated';
import Space from 'shared/components/Space';
import type { TeamAdapter } from 'team/TeamAdapter';
import { useInsightsReportWizard } from 'report/InsightReportWizard/InsightReportWizardProvider/useInsightsReportWizard';
import Flex from 'shared/components/Flex';
import { CalendarControlsProvider } from 'shared/components/CalendarControls';
import CompletedFilterProvider from 'shared/components/CompletedFilterButton/CompletedFilterProvider';
import type { ThemeMultiSelectItemId } from 'themes/ThemeMultiSelect';
import type { UserMultiSelectItemId } from 'user/UserMultiSelect';
import UserMultiSelect from 'user/UserMultiSelect';
import type { StatusMultiSelectItemId } from 'shared/status/StatusMultiSelect';
import StatusMultiSelect from 'shared/status/StatusMultiSelect';
import ThemeMultiSelect from 'themes/ThemeMultiSelect';
import type { PriorityMultiSelectItemId } from 'shared/priority/PriorityMultiSelect';
import PriorityMultiSelect from 'shared/priority/PriorityMultiSelect';
import Text from 'shared/components/Text';

import type {
  SelectableStrategyItemsValues,
  SubmitData,
} from './SelectableStrategyItems.type';
import styles from './SelectableStrategyItems.module.scss';
import SelectableStrategyItemsProvider from './SelectableStrategyItemsProvider';
import Section from './SelectableStrategyItemsSection';
import {
  getAllIds,
  getObjectiveIdFromObjectiveItem,
  getObjectiveItemsFromObjective,
  resolveSubmitData,
} from './SelectableStrategyItems.utils';
import { useSelectableStrategyItems } from './SelectableStrategyItemsProvider/useSelectableStrategyItems';
import SelectableStrategyItemsFiltersChipGroup from './SelectableStrategyItemsFiltersChipGroup';
import SelectableStrategyItemsHeader from './SelectableStrategyItemsHeader';

export type SelectableStrategyItemsProps = {
  id?: string;
  onChangeSelectedCount?: (params: {
    initiativesCount: number;
    metricsCount: number;
    objectivesCount: number;
  }) => void;
  onInitiativeChange: (values: string[]) => void;
  onMetricChange: (values: string[]) => void;
  onObjectiveChange: (values: string[]) => void;
  onSubmit?: (data: SubmitData) => void;
  teamAdapter: TeamAdapter;
  values: SelectableStrategyItemsValues;
};

const SelectableStrategyItems = ({
  onChangeSelectedCount,
  onSubmit,
  id,
  onObjectiveChange,
  onInitiativeChange,
  onMetricChange,
  values,
}: SelectableStrategyItemsProps) => {
  const { t } = useTranslation();

  const {
    objectives,
    filteredObjectives,
    filteredObjectivesByThemes,
    themes,
    users,
    filter: {
      statusFilter,
      setStatusFilter,
      themeFilter,
      setThemeFilter,
      userFilter,
      setUserFilter,
      priorityFilter,
      setPriorityFilter,
    },
  } = useSelectableStrategyItems();

  const { setHasSelection } = useInsightsReportWizard();

  const objectivesCount = values.objectiveIds.length;
  const metricsCount = values.metricIds.length;
  const initiativesCount = values.initiativeIds.length;

  const totalCount = objectivesCount + metricsCount + initiativesCount;
  const isNoneSelected = totalCount === 0;

  useEffect(() => {
    setHasSelection(!isNoneSelected);
  }, [isNoneSelected, setHasSelection]);

  useEffect(() => {
    onChangeSelectedCount?.({
      objectivesCount,
      metricsCount,
      initiativesCount,
    });
  }, [initiativesCount, metricsCount, objectivesCount, onChangeSelectedCount]);

  const addSelectedMetric = useCallback(
    (...metricIds: Metric['id'][]) =>
      onMetricChange(uniq([...values.metricIds, ...metricIds])),
    [onMetricChange, values.metricIds],
  );

  const removeSelectedMetric = useCallback(
    (...metricIds: Metric['id'][]) =>
      onMetricChange(
        values.metricIds.filter(
          (eachMetricId) => !metricIds.includes(eachMetricId),
        ),
      ),
    [onMetricChange, values.metricIds],
  );

  const addSelectedInitiative = useCallback(
    (...initiativeIds: Initiative['id'][]) =>
      onInitiativeChange(uniq([...values.initiativeIds, ...initiativeIds])),
    [onInitiativeChange, values.initiativeIds],
  );

  const removeSelectedInitiative = useCallback(
    (...initiativeIds: Metric['id'][]) =>
      onInitiativeChange(
        values.initiativeIds.filter(
          (eachInitiativeId) => !initiativeIds.includes(eachInitiativeId),
        ),
      ),
    [onInitiativeChange, values.initiativeIds],
  );

  const addSelectedObjective = useCallback(
    (...objectiveIds: Objective['id'][]) =>
      onObjectiveChange(uniq([...values.objectiveIds, ...objectiveIds])),
    [onObjectiveChange, values.objectiveIds],
  );

  const removeSelectedObjective = useCallback(
    (...objectiveIds: Objective['id'][]) =>
      onObjectiveChange(
        values.objectiveIds.filter(
          (eachObjectiveId) => !objectiveIds.includes(eachObjectiveId),
        ),
      ),
    [onObjectiveChange, values.objectiveIds],
  );

  const handleToggleSelectedObjective = useCallback(
    (objectiveId: Objective['id']) => {
      const isObjectiveSelected = values.objectiveIds.includes(objectiveId);
      const shouldSelect = !isObjectiveSelected;

      const { metrics, initiatives } = getObjectiveItemsFromObjective(
        objectiveId,
        filteredObjectives,
      );

      const metricIds = metrics.map((metric) => metric.id);
      const initiativeIds = initiatives.map((initiative) => initiative.id);

      if (shouldSelect) {
        addSelectedObjective(objectiveId);
        addSelectedMetric(...metricIds);
        addSelectedInitiative(...initiativeIds);
      } else {
        removeSelectedObjective(objectiveId);
        removeSelectedMetric(...metricIds);
        removeSelectedInitiative(...initiativeIds);
      }
    },
    [
      addSelectedInitiative,
      addSelectedMetric,
      addSelectedObjective,
      filteredObjectives,
      removeSelectedInitiative,
      removeSelectedMetric,
      removeSelectedObjective,
      values.objectiveIds,
    ],
  );

  const handleToggleSelectedMetric = useCallback(
    (metricId: Metric['id']) => {
      const isMetricSelected = values.metricIds.includes(metricId);
      const shouldSelect = !isMetricSelected;

      if (shouldSelect) {
        addSelectedMetric(metricId);

        const metricObjective = getObjectiveIdFromObjectiveItem(
          metricId,
          filteredObjectives,
        );

        addSelectedObjective(metricObjective.id);
      } else {
        removeSelectedMetric(metricId);
      }
    },
    [
      addSelectedMetric,
      addSelectedObjective,
      filteredObjectives,
      removeSelectedMetric,
      values.metricIds,
    ],
  );

  const handleToggleSelectedInitiative = useCallback(
    (initiativeId: Initiative['id']) => {
      const isInitiativeSelected = values.initiativeIds.includes(initiativeId);
      const shouldSelect = !isInitiativeSelected;

      if (shouldSelect) {
        addSelectedInitiative(initiativeId);

        const initiativeObjective = getObjectiveIdFromObjectiveItem(
          initiativeId,
          filteredObjectives,
        );

        addSelectedObjective(initiativeObjective.id);
      } else {
        removeSelectedInitiative(initiativeId);
      }
    },
    [
      addSelectedInitiative,
      addSelectedObjective,
      filteredObjectives,
      removeSelectedInitiative,
      values.initiativeIds,
    ],
  );

  if (objectives.length === 0) return <div>{t('objective.noObjectives')}</div>;

  return (
    <UserMultiSelect.Provider
      users={users}
      selectedKeys={userFilter}
      onSelectionChange={(selection) => {
        setUserFilter([...selection] as UserMultiSelectItemId[]);
      }}
    >
      <StatusMultiSelect.Provider
        selectedKeys={statusFilter}
        onSelectionChange={(selection) => {
          setStatusFilter([...selection] as StatusMultiSelectItemId[]);
        }}
      >
        <ThemeMultiSelect.Provider
          themes={themes || []}
          selectedKeys={themeFilter}
          onSelectionChange={(selection) => {
            setThemeFilter([...selection] as ThemeMultiSelectItemId[]);
          }}
        >
          <PriorityMultiSelect.Provider
            selectedKeys={priorityFilter}
            onSelectionChange={(selection) => {
              setPriorityFilter([...selection] as PriorityMultiSelectItemId[]);
            }}
          >
            <form
              className={styles.container}
              id={id}
              onSubmit={(event) => {
                event.preventDefault();
                onSubmit?.(
                  resolveSubmitData({
                    objectives,
                    initiativeIds: values.initiativeIds,
                    metricIds: values.metricIds,
                    objectiveIds: values.objectiveIds,
                  }),
                );
              }}
            >
              <Flex direction={'column'} gap={14}>
                <SelectableStrategyItemsHeader
                  values={values}
                  onObjectiveChange={onObjectiveChange}
                  onMetricChange={onMetricChange}
                  onInitiativeChange={onInitiativeChange}
                />

                <SelectableStrategyItemsFiltersChipGroup />

                <Space direction={'vertical'} className={styles.selection}>
                  {filteredObjectives.length === 0 && (
                    <Flex
                      justifyContent={'center'}
                      css={css({ margin: '2rem' })}
                    >
                      <Text>{t('strategy.selectableStrategyItems.empty')}</Text>
                    </Flex>
                  )}

                  {filteredObjectivesByThemes.map(
                    ({ theme, objectives: themeObjectives }) => (
                      <Section
                        objectives={themeObjectives}
                        theme={theme}
                        key={theme?.id || '-1'}
                        selectedObjectivesIds={values.objectiveIds}
                        selectedMetricsIds={values.metricIds}
                        selectedInitiativesIds={values.initiativeIds}
                        onToggleSelectedObjective={
                          handleToggleSelectedObjective
                        }
                        onToggleSelectedInitiative={
                          handleToggleSelectedInitiative
                        }
                        onToggleSelectedMetric={handleToggleSelectedMetric}
                        onSelectAll={() => {
                          const ids = getAllIds(themeObjectives);
                          addSelectedObjective(...ids.objectivesIds);
                          addSelectedMetric(...ids.metricsIds);
                          addSelectedInitiative(...ids.initiativesIds);
                        }}
                        onDeselectAll={() => {
                          const ids = getAllIds(themeObjectives);
                          removeSelectedObjective(...ids.objectivesIds);
                          removeSelectedMetric(...ids.metricsIds);
                          removeSelectedInitiative(...ids.initiativesIds);
                        }}
                      />
                    ),
                  )}
                </Space>
              </Flex>
            </form>
          </PriorityMultiSelect.Provider>
        </ThemeMultiSelect.Provider>
      </StatusMultiSelect.Provider>
    </UserMultiSelect.Provider>
  );
};

export default (props: SelectableStrategyItemsProps) => (
  <CalendarControlsProvider storeToSession={false}>
    <CompletedFilterProvider storeToSession={false}>
      <SelectableStrategyItemsProvider teamAdapter={props.teamAdapter}>
        <SelectableStrategyItems {...props} />
      </SelectableStrategyItemsProvider>
    </CompletedFilterProvider>
  </CalendarControlsProvider>
);
