import { Navigate, type RouteObject } from 'react-router-dom';

import ToolkitOverview from 'toolkit/ToolkitOverview';
import ToolkitLayout from 'toolkit/ToolkitLayout';
import { useTeamAdapter } from 'team/TeamAdapter';

import { toolkitRoutesPaths } from './toolkit.routing.paths';
import WalkOfStrategyLayout from './WalkOfStrategyLayout';
import WalkOfStrategyOverview from './WalkOfStrategyOverview';
import WalkOfStrategyCampaigns from './WalkOfStrategyCampaigns';
import CampaignResponse from './WalkOfStrategyCampaignResponse/WalkOfStrategyCampaignResponse';
import CampaignResultsLayout from './WalkOfStrategyCampaignResultsLayout';
import CampaignResultsInsights from './WalkOfStrategyCampaignResultsInsights';
import CampaignResultsProvider from './WalkOfStrategyCampaignResultsLayout/CampaignResultsProvider';
import CampaignResultsComments from './WalkOfStrategyCampaignResultsComments';
import OneThingLayout from './OneThingLayout';
import OneThingOverview from './OneThingOverview';
import OneThingCampaigns from './OneThingCampaigns';
import OneThingCampaignResults from './OneThingCampaignResults/OneThingCampaignResults';

const RedirectToStrategyWalkOverview = () => {
  const { teamAdapter } = useTeamAdapter();

  const teamSlug = teamAdapter.toParam();

  return (
    <Navigate
      to={toolkitRoutesPaths.walkOfStrategy.overview({ params: { teamSlug } })}
      replace={true}
    />
  );
};

const RedirectToOneThingOverview = () => {
  const { teamAdapter } = useTeamAdapter();

  const teamSlug = teamAdapter.toParam();

  return (
    <Navigate
      to={toolkitRoutesPaths.oneThing.overview({ params: { teamSlug } })}
      replace={true}
    />
  );
};

export const toolkitRoutes: RouteObject[] = [
  {
    path: toolkitRoutesPaths.root(),
    element: (
      <ToolkitLayout>
        <ToolkitOverview />
      </ToolkitLayout>
    ),
    children: [
      {
        index: true,
        element: <ToolkitOverview.RedirectToDefaultCategory />,
      },
      {
        path: toolkitRoutesPaths.category(),
        element: <ToolkitOverview.Category />,
      },
    ],
  },
  {
    path: toolkitRoutesPaths.walkOfStrategy.root(),
    element: <WalkOfStrategyLayout />,
    children: [
      {
        index: true,
        element: <RedirectToStrategyWalkOverview />,
      },
      {
        path: toolkitRoutesPaths.walkOfStrategy.overview(),
        element: <WalkOfStrategyOverview />,
      },
      {
        path: toolkitRoutesPaths.walkOfStrategy.campaigns.root(),
        element: <WalkOfStrategyCampaigns />,
      },
    ],
  },
  {
    path: toolkitRoutesPaths.walkOfStrategy.campaigns.results.root(),
    element: (
      <CampaignResultsProvider>
        <CampaignResultsLayout />
      </CampaignResultsProvider>
    ),
    children: [
      {
        path: toolkitRoutesPaths.walkOfStrategy.campaigns.results.insights(),
        element: <CampaignResultsInsights />,
      },
      {
        path: toolkitRoutesPaths.walkOfStrategy.campaigns.results.comments(),
        element: <CampaignResultsComments />,
      },
    ],
  },
  {
    path: toolkitRoutesPaths.walkOfStrategy.campaignResponses.respond(),
    element: <CampaignResponse />,
  },
  {
    path: toolkitRoutesPaths.oneThing.root(),
    element: <OneThingLayout />,
    children: [
      {
        index: true,
        element: <RedirectToOneThingOverview />,
      },
      {
        path: toolkitRoutesPaths.oneThing.overview(),
        element: <OneThingOverview />,
      },
      {
        path: toolkitRoutesPaths.oneThing.campaigns.root(),
        element: <OneThingCampaigns />,
      },
    ],
  },
  {
    path: toolkitRoutesPaths.oneThing.campaigns.results(),
    element: <OneThingCampaignResults />,
  },
];
