import cn from 'classnames';
import { memo } from 'react';

import styles from './Divider.module.scss';

type DividerProps = {
  className?: string;
  hasMargin?: boolean;
};

const Divider = ({ hasMargin = true, className }: DividerProps) => (
  <hr
    className={cn(
      styles.divider,
      { [styles.dividerNoMargin]: !hasMargin },
      className,
    )}
  />
);

export default memo(Divider);
