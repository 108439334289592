import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash';

import { date } from 'shared/services/date.service';
import Card from 'shared/components/Card';
import Text from 'shared/components/Text';
import Space from 'shared/components/Space';
import Heading from 'shared/components/Heading';
import List from 'shared/components/List';
import { useTeamAdapter } from 'team/TeamAdapter';

import type { StrategyRoadmapSideModalProps } from '../StrategyRoadmapSideModal';
import { illustrations, backgroundColors } from '../StrategyRoadmap.static';
import StrategyRoadmapSideModal from '../StrategyRoadmapSideModal';
import type { StrategyRoadmapStrategyFieldsFragment } from '../StrategyRoadmapProvider/StrategyRoadmapProvider.graphql.generated';
import { getEditStrategyPath } from '../StrategyRoadmap.utils';

type StrategyRoadmapBehaviorsModalProps = {
  showEditButton: boolean;
  strategy: StrategyRoadmapStrategyFieldsFragment;
} & Pick<StrategyRoadmapSideModalProps, 'isOpen' | 'onClose'>;

const StrategyRoadmapBehaviorsModal = ({
  strategy,
  isOpen,
  onClose,
  showEditButton,
}: StrategyRoadmapBehaviorsModalProps) => {
  const { behaviors } = strategy;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { teamAdapter } = useTeamAdapter();

  const content = useMemo(
    () => (
      <Space direction={'vertical'} size={'large'}>
        {behaviors.map((behavior) => (
          <Space direction={'vertical'} key={behavior.id}>
            <Space direction={'vertical'} size={'small'}>
              <Heading level={3} as={4} hasMargin={false}>
                {behavior.name}
              </Heading>
              <Text whiteSpace={'preWrap'}>{behavior.description}</Text>
            </Space>
            {behavior.behaviorSetGroups.map((behaviorSetGroup) => (
              <Card
                border={'full'}
                padding={'small'}
                hasBackground={true}
                key={behaviorSetGroup.id}
              >
                <Space direction={'vertical'}>
                  <Card.Heading level={4} hasMargin={false}>
                    {behaviorSetGroup.name}
                  </Card.Heading>
                  <Text whiteSpace={'preLine'}>
                    {behaviorSetGroup.description}
                  </Text>
                  <List
                    variant={'emphasis'}
                    gutterSize={'large'}
                    itemSize={'big'}
                  >
                    {behaviorSetGroup.items.map((item) => (
                      <List.Item key={item.id}>{item.text}</List.Item>
                    ))}
                  </List>
                </Space>
              </Card>
            ))}
          </Space>
        ))}
      </Space>
    ),
    [behaviors],
  );

  const updateDateTime = date.max(
    behaviors.map((behavior) => behavior.auditRecord.updateDateTime),
  );

  const handleEdit = useCallback(() => {
    onClose();
    navigate(getEditStrategyPath(teamAdapter, strategy, 'behaviors'));
  }, [onClose, navigate, teamAdapter, strategy]);

  return (
    <>
      <StrategyRoadmapSideModal
        top={{
          illustration: illustrations.behaviors,
          backgroundColor: backgroundColors.behaviors,
        }}
        heading={capitalize(t('strategy.behavior_other'))}
        description={t('strategy.strategyRoadmap.behaviors.modal.description')}
        updateDateTime={updateDateTime}
        onEdit={showEditButton ? handleEdit : undefined}
        isOpen={isOpen}
        onClose={onClose}
      >
        {content}
      </StrategyRoadmapSideModal>
    </>
  );
};

export default StrategyRoadmapBehaviorsModal;
