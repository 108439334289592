import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import styled from '@emotion/styled';

import { useTeamAdapter } from 'team/TeamAdapter';

import { getStatusRequestTargetLinkPath } from './TimelineEntryContentStatusRequestBodyItem.utils';
import type { ContributionMyFollowUpsStatusRequestFragment } from '../ContributionMyFollowUpsViewMore.graphql.generated';

const Link = styled.a`
  color: ${(props) => props.theme.color.black};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const StatusRequestSubjectLink = ({
  statusRequest,
}: {
  statusRequest: ContributionMyFollowUpsStatusRequestFragment;
}) => {
  const { teamAdapter } = useTeamAdapter();
  const navigate = useNavigate();

  const target = getStatusRequestTargetLinkPath(
    statusRequest,
    teamAdapter.toParam(),
  );

  const handleClick = useCallback(
    () => target && navigate(target),
    [navigate, target],
  );

  return (
    <Link onClick={handleClick}>{statusRequest.strategyElement.name}</Link>
  );
};

export default StatusRequestSubjectLink;
