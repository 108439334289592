import cn from 'classnames';
import type { PropsWithChildren } from 'react';

import { ReactComponent as CheckIcon } from 'shared/static/icons/icon-check.svg';
import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';

import styles from './Toast.module.scss';

type Variant = 'success' | 'error' | 'info';

const getIcon = (variant: Variant) =>
  ({
    success: CheckIcon,
    error: CloseIcon,
    info: CheckIcon,
  }[variant]);

export type ToastProps = PropsWithChildren<{
  className?: string;
  variant?: Variant;
}>;

const Toast = ({ variant = 'info', children, className }: ToastProps) => {
  const Icon = getIcon(variant);

  return (
    <div
      className={cn(
        styles.toast,
        {
          [styles.toastSuccess]: variant === 'success',
          [styles.toastError]: variant === 'error',
          [styles.toastInfo]: variant === 'info',
        },
        className,
      )}
    >
      <Icon className={styles.toastIcon} />
      {children}
    </div>
  );
};

export default Toast;
