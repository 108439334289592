import type { PropsWithChildren, ReactNode } from 'react';
import styled from '@emotion/styled';
import type { Theme } from '@emotion/react';
import { css, useTheme } from '@emotion/react';

import type { BaseModalProps } from 'shared/components/__DEPRECATED__/BaseModal';
import BaseModal from 'shared/components/__DEPRECATED__/BaseModal';
import Heading from 'shared/components/Heading';
import IconButton from 'shared/components/IconButton';
import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';

import type { RenderFooterParams } from './CompactModal.type';
import Controls from './CompactModalControls';
import { getDefaultModalContainerStyle } from '../BaseModal/getDefaultModalContainerStyle';

const StyledModal = styled(BaseModal)`
  background-color: $color-white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40rem;
`;

const Header = styled.header`
  border-bottom: 1px solid ${(props) => props.theme.color.neutral3};
  display: flex;
  flex-direction: column;
  height: 5.875rem;
  justify-content: flex-end;
  padding: 1rem 2.5em;
`;

const HeaderIcon = styled(IconButton)`
  height: 1.5rem;
  padding: 0;
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  width: 1.5rem;
`;

const Content = styled.div`
  max-height: 40rem;
  padding: 1.25rem 2.5rem;
`;

const Footer = styled.footer`
  border-top: 1px solid ${(props) => props.theme.color.neutral3};
  height: 6.75rem;
  padding: 1.25rem 2.5rem 2.5rem;
`;

const getContainerStyle = (theme: Theme) => css`
  ${getDefaultModalContainerStyle(theme)}

  align-items: center;
  display: flex;
  justify-content: center;
`;

export type CompactModalProps = PropsWithChildren<
  Pick<
    BaseModalProps,
    'isOpen' | 'onClose' | 'className' | 'containerClassName'
  >
> & {
  heading?: ReactNode;
  renderFooter?: (params: RenderFooterParams) => ReactNode;
};

const CompactModal = ({
  isOpen,
  onClose,
  children,
  renderFooter,
  heading,
  className,
  containerClassName,
}: CompactModalProps) => {
  const theme = useTheme();

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={onClose}
      className={className}
      containerClassName={containerClassName}
      containerCss={getContainerStyle(theme)}
    >
      <Header>
        <Heading level={2} as={3} hasMargin={false} ellipsis={true}>
          {heading}
        </Heading>
        <HeaderIcon
          onClick={(event) => {
            event.stopPropagation();
            onClose();
          }}
          icon={CloseIcon}
          color={'black'}
        />
      </Header>
      <Content>{children}</Content>
      {renderFooter && <Footer>{renderFooter({ onClose })}</Footer>}
    </StyledModal>
  );
};

CompactModal.Controls = Controls;

export default CompactModal;
