import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useToggle } from 'react-use';
import { capitalize } from 'lodash';

import MilestonesList from 'initiative/MilestonesList';
import Heading from 'shared/components/Heading';
import StatusMark from 'shared/status/StatusMark';
import Text from 'shared/components/Text';
import Card from 'shared/components/Card';
import KeyIndicators from 'initiative/KeyIndicators';
import Space from 'shared/components/Space';
import StatusComments from 'shared/status/StatusComments';
import Collapse from 'shared/components/Collapse';
import { migrateTextItemsToStatusComments } from 'shared/status/status.utils';
import type { InitiativeStatus } from 'types.graphql.generated';
import UserUpdateStatus from 'user/UserUpdateStatus';

import styles from './InitiativeSummary.module.scss';
import type { InitiativeSummaryInitiative } from './InitiativeSummary.type';

type InitiativeSummaryProps = {
  className?: string;
  hasInsightSummaryBackground?: boolean;
  initiative: InitiativeSummaryInitiative;
};

const InitiativeSummary = ({
  className,
  initiative,
  hasInsightSummaryBackground = true,
}: InitiativeSummaryProps) => {
  const { currentInitiativeStatus, milestones, name } = initiative;

  const { t } = useTranslation();

  const [isInsightSummaryCollapsed, toggleIsInsightSummaryCollapsed] =
    useToggle(true);

  return (
    <Card border={false} className={cn(styles.card, className)}>
      <Card.Heading
        before={
          <StatusMark
            statusIndicator={currentInitiativeStatus?.statusIndicator}
          />
        }
        after={<Text variant={'emphasis'}>{name}</Text>}
        level={5}
      >
        {capitalize(t('initiative.initiative'))}
      </Card.Heading>
      <Space direction={'vertical'} size={'large'}>
        <MilestonesList milestones={milestones} />
        <KeyIndicators
          variant={'tiles'}
          keyIndicatorOnBudget={currentInitiativeStatus?.keyIndicatorOnBudget}
          keyIndicatorOnTime={currentInitiativeStatus?.keyIndicatorOnTime}
          keyIndicatorOnOutcome={currentInitiativeStatus?.keyIndicatorOnOutcome}
        />
        <Collapse
          isCollapsed={isInsightSummaryCollapsed}
          onToggleIsCollapsed={toggleIsInsightSummaryCollapsed}
          renderHeading={() => (
            <Heading level={4} hasMargin={false}>
              {t('insightSummary')}
            </Heading>
          )}
          hasBackground={hasInsightSummaryBackground}
        >
          <Space direction={'vertical'} size={'large'}>
            <StatusComments
              items={migrateTextItemsToStatusComments(
                currentInitiativeStatus as InitiativeStatus,
              )}
            />
            <UserUpdateStatus
              auditRecordUser={currentInitiativeStatus?.auditRecord?.updateBy}
              updateDateTime={
                currentInitiativeStatus?.auditRecord?.updateDateTime
              }
              statusIndicator={currentInitiativeStatus?.statusIndicator}
            />
          </Space>
        </Collapse>
      </Space>
    </Card>
  );
};

export default InitiativeSummary;
