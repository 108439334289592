import { useDefinedContext } from 'shared/utils/context.utils';

import InitiativesListProvider from './InitiativesContentProvider';
import { InitiativesContentContext } from './InitiativesContentProvider.context';

export const useInitiativesListObjectives = () =>
  useDefinedContext(InitiativesContentContext, {
    hookName: useInitiativesListObjectives.name,
    providerName: InitiativesListProvider.name,
  });
