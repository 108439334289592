import type { PropsWithChildren } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

import type { Org } from 'types.graphql.generated';
import { ActingOrg } from 'org/ActingOrg';
import GlobalSpinner from 'shared/spinner/GlobalSpinner';
import { TeamAdapter } from 'team/TeamAdapter';
import { useUser } from 'user/UserProvider';

import type { InitialActiveOrgKeyProviderContextValue } from './InitialActiveOrgKeyProvider.context';
import { InitialActiveOrgKeyProviderContext } from './InitialActiveOrgKeyProvider.context';

type InitialActiveOrgKeyProviderProps = PropsWithChildren<object>;

const InitialActiveOrgKeyProvider = ({
  children,
}: InitialActiveOrgKeyProviderProps) => {
  const navigate = useNavigate();
  const { user } = useUser();

  const orgKeyFromUser = user.org?.orgKey;

  const { teamSlug } = useParams() as { teamSlug?: string };

  const teamAdapter = useMemo<Maybe<TeamAdapter>>(
    () => (teamSlug ? TeamAdapter.fromParam(teamSlug) : undefined),
    [teamSlug],
  );

  const orgKeyFromUrlParam = teamAdapter?.orgKey;

  const orgKeyFromStorage = ActingOrg.getActingOrgKey();

  const resolvedInitialActiveOrgKey = useMemo<Maybe<Org['orgKey']>>(
    () => orgKeyFromUrlParam || orgKeyFromStorage || orgKeyFromUser,
    [orgKeyFromUrlParam, orgKeyFromStorage, orgKeyFromUser],
  );

  useEffect(() => {
    if (resolvedInitialActiveOrgKey) {
      ActingOrg.setActingOrg(resolvedInitialActiveOrgKey);
    }
  }, [resolvedInitialActiveOrgKey, navigate]);

  const contextValue = useMemo<Maybe<InitialActiveOrgKeyProviderContextValue>>(
    () =>
      resolvedInitialActiveOrgKey
        ? { initialActiveOrgKey: resolvedInitialActiveOrgKey }
        : undefined,
    [resolvedInitialActiveOrgKey],
  );

  if (contextValue) {
    return (
      <InitialActiveOrgKeyProviderContext.Provider value={contextValue}>
        {children}
      </InitialActiveOrgKeyProviderContext.Provider>
    );
  }

  return <GlobalSpinner />;
};

export default InitialActiveOrgKeyProvider;
