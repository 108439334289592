import { useFormikContext } from 'formik';
import { useEffect } from 'react';

type Props = {
  value: unknown;
};
const ResetFormOnChange = ({ value }: Props) => {
  const { resetForm } = useFormikContext();

  useEffect(() => {
    resetForm();
  }, [resetForm, value]);

  return null;
};

export default ResetFormOnChange;
