import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'shared/components/Button/Button';
import CreateObjectiveStatusDialog from 'objective/CreateObjectiveStatusDialog';
import CreateMetricStatusDialog from 'metric/CreateMetricStatusDialog';
import { useTeamAdapter } from 'team/TeamAdapter';
import useDialogState from 'shared/hooks/useDialogState';
import CreateInitiativeStatusDialog from 'initiative/CreateInitiativeStatusDialog';

import type { ContributionMyFollowUpsStatusRequestFragment } from '../ContributionMyFollowUpsViewMore.graphql.generated';
import { getShareProgressButtonTargetPath } from './TimelineEntryContentStatusRequestBodyItem.utils';

const ShareProgressButton = ({
  statusRequest,
}: {
  statusRequest: ContributionMyFollowUpsStatusRequestFragment;
}) => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDialogState();

  const target = getShareProgressButtonTargetPath(
    statusRequest,
    teamAdapter.toParam(),
  );

  const handleOpen = useCallback(
    () => (target ? navigate(target) : onOpen()),
    [navigate, onOpen, target],
  );

  return (
    <>
      <Button onClick={handleOpen} fontSize={'small'}>
        {t('followUps.timeline.insightRequest.button.shareProgress')}
      </Button>

      {statusRequest.strategyElement.__typename === 'Objective' && (
        <CreateObjectiveStatusDialog
          objective={statusRequest.strategyElement}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}

      {statusRequest.strategyElement.__typename === 'Metric' && (
        <CreateMetricStatusDialog
          metric={statusRequest.strategyElement}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}

      {statusRequest.strategyElement.__typename === 'Initiative' && (
        <CreateInitiativeStatusDialog
          initiative={statusRequest.strategyElement}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default ShareProgressButton;
