import { Chart } from 'react-chartjs-2';
import { useMemo } from 'react';
import cn from 'classnames';

import styles from './StatusChart.module.scss';
import type { StatusChartObjective } from './StatusChart.type';
import {
  resolveChartValues,
  getChartPlugins,
  getChartOptions,
  getChartData,
} from './StatusChart.utils';

type StatusChartProps = {
  className?: string;
  objectives: StatusChartObjective[];
};

const StatusChart = ({ objectives, className }: StatusChartProps) => {
  const chartValues = useMemo(
    () => resolveChartValues(objectives),
    [objectives],
  );

  if (chartValues) {
    const key = chartValues.map((value) => value.count).toString();
    return (
      <Chart
        type={'doughnut'}
        data={getChartData(chartValues)}
        options={getChartOptions(chartValues)}
        plugins={getChartPlugins(chartValues)}
        className={cn(styles.chart, className)}
        key={key}
      />
    );
  }

  return null;
};

export default StatusChart;
