import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import Heading from 'shared/components/Heading';
import Button from 'shared/components/Button';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import Spinner from 'shared/spinner/Spinner';
import InitiativeOverviewMilestones from 'initiative/InitiativeOverview/InitiativeOverviewMilestones';
import CreateInitiativeStatusDialog from 'initiative/CreateInitiativeStatusDialog';
import useDialogState from 'shared/hooks/useDialogState';
import Flex from 'shared/components/Flex';
import SendStatusRequestButton from 'status/SendStatusRequestButton';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';

import { useInitiativeOverview } from '../InitiativeOverviewProvider';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 24px 24px 24px;
`;

const InitiativeOverviewHeader = () => {
  const { t } = useTranslation();
  const { initiative } = useInitiativeOverview();
  const { isOpen, onOpen, onClose } = useDialogState();

  if (!initiative) return <Spinner.Circle />;

  const canAddStatus = canPerformStrategyElementAction(initiative, 'WRITE');

  return (
    <>
      <Container>
        <Flex justifyContent={'space-between'} alignItems={'center'} gap={8}>
          <Heading level={3} hasMargin={false}>
            {t('initiative.initiativeInsights.heading')}
          </Heading>

          <Flex gap={8}>
            <SendStatusRequestButton strategyElement={initiative} />

            {canAddStatus && (
              <Button icon={PlusIcon} iconPosition={'start'} onClick={onOpen}>
                {t('initiative.initiativeInsights.actions.addInsightButton')}
              </Button>
            )}
          </Flex>
        </Flex>

        <InitiativeOverviewMilestones />
      </Container>

      <CreateInitiativeStatusDialog
        isOpen={isOpen}
        initiative={initiative}
        onClose={onClose}
      />
    </>
  );
};

export default InitiativeOverviewHeader;
