import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import FieldBox from 'shared/form/FieldBox';
import Flex from 'shared/components/Flex';
import {
  UserEmailInputField,
  UserEmailInputProvider,
} from 'user/UserEmailInput';
import { useTeamAdapter } from 'team/TeamAdapter';
import type { OrgFormValues } from 'org/EditOrg/OrgForm';
import { TextInputField } from 'shared/components/TextInput';
import { ColorPickerField } from 'shared/components/ColorPicker';
import MultiFieldRow from 'shared/form/MultiFieldRow';

const OrgQuickFormFields = () => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();

  const { getFieldMeta } = useFormikContext<OrgFormValues>();

  const hasError = (field: string) => getFieldMeta(field).error !== undefined;

  return (
    <Flex direction={'column'} gap={20}>
      <MultiFieldRow>
        <FieldBox
          name={'name'}
          label={t('org.form.field.name.label')}
          hasError={hasError('name')}
          hasMargin={false}
        >
          <Field
            component={TextInputField}
            name={'name'}
            hasError={hasError('name')}
            fontSize={'big'}
            maxLength={100}
            autoFocus={true}
          />
        </FieldBox>

        <FieldBox
          name={'acronym'}
          label={t('org.form.field.acronym.label')}
          hasError={hasError('acronym')}
          hasMargin={false}
          css={css({ maxWidth: 180 })}
        >
          <Field
            component={TextInputField}
            name={'acronym'}
            hasError={hasError('acronym')}
            fontSize={'big'}
            maxLength={3}
            autoFocus={true}
          />
        </FieldBox>
      </MultiFieldRow>

      <FieldBox
        name={'lead'}
        label={t('org.form.field.lead.label')}
        hasError={hasError('lead')}
        hasMargin={false}
      >
        <UserEmailInputProvider teamAdapter={teamAdapter}>
          <Field
            component={UserEmailInputField}
            name={'lead'}
            hasError={hasError('lead')}
            autoComplete={'false'}
          />
        </UserEmailInputProvider>
      </FieldBox>

      <FieldBox
        name={'color'}
        label={t('org.form.field.color.label')}
        hasError={hasError('color')}
        hasMargin={false}
      >
        <Field
          component={ColorPickerField}
          name={'color'}
          hasError={hasError('color')}
        />
      </FieldBox>

      <FieldBox
        name={'industryName'}
        label={t('org.form.field.industryName.label')}
        hasError={hasError('industryName')}
        hasMargin={false}
      >
        <Field
          component={TextInputField}
          name={'industryName'}
          hasError={hasError('industryName')}
        />
      </FieldBox>

      <FieldBox
        name={'industryDescription'}
        label={t('org.form.field.industryDescription.label')}
        hasError={hasError('industryDescription')}
        hasMargin={false}
      >
        <Field
          component={TextInputField}
          name={'industryDescription'}
          hasError={hasError('industryDescription')}
        />
      </FieldBox>
    </Flex>
  );
};

export default OrgQuickFormFields;
