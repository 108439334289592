import { css } from '@emotion/react';
import type { CSSProperties } from 'react';

import Emoji from 'shared/components/Emoji';

type Props = {
  fontSize?: CSSProperties['fontSize'];
};

const AiSophieIcon = ({ fontSize = '1.25rem' }: Props) => (
  <Emoji icon={'🤵'} css={css({ fontSize })} />
);

export default AiSophieIcon;
