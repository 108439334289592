import { ReactComponent as MissionIllustration } from 'shared/static/illustrations/illustration-mission.svg';
import { ReactComponent as VisionIllustration } from 'shared/static/illustrations/illustration-vision.svg';
import { ReactComponent as GoalIllustration } from 'shared/static/illustrations/illustration-goal.svg';
import { ReactComponent as CrossroadsIllustration } from 'shared/static/illustrations/illustration-crossroads.svg';
import { ReactComponent as HoldingBigHeartIllustration } from 'shared/static/illustrations/illustration-holding-big-heart.svg';
import { ReactComponent as BehaviorIllustration } from 'shared/static/illustrations/illustration-behavior.svg';
import { ReactComponent as ChoosingUnorderedListIllustration } from 'shared/static/illustrations/illustration-choosing-unordered-list.svg';

export const illustrations = {
  mission: MissionIllustration,
  vision: VisionIllustration,
  goals: GoalIllustration,
  choices: CrossroadsIllustration,
  themes: ChoosingUnorderedListIllustration,
  behaviors: BehaviorIllustration,
  values: HoldingBigHeartIllustration,
};

export const backgroundColors = {
  mission: '#FFF2E3',
  vision: '#FFE3E3',
  goals: '#FFF0BF',
  choices: '#FFF2E3',
  themes: '#D9E7FD',
  behaviors: '#FFF2E3',
  values: '#FFF0BF',
};
