import { datadogRum } from '@datadog/browser-rum';

export const initializeDatadog = () => {
  if (shouldSkipDatadogRecording()) return;

  datadogRum.init({
    applicationId: 'ac4153ca-e90e-435a-bb76-eadf01694623',
    clientToken: 'pub7a4e0c563b63cb14ede2f27328a03c19',
    site: 'datadoghq.eu',
    service: 'bluejam-frontend',
    env: process.env.REACT_APP_ENV,
    version: process.env.REACT_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });

  datadogRum.startSessionReplayRecording();
};

export const DATADOG_SKIP_RECORDING_KEY = 'skip-datadog-recording';

const shouldSkipDatadogRecording = (): boolean => {
  if (process.env.NODE_ENV === 'development') {
    return true;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const hasParam = urlParams.has(DATADOG_SKIP_RECORDING_KEY);

  if (hasParam) {
    const paramValue = urlParams.get(DATADOG_SKIP_RECORDING_KEY);

    if (paramValue === 'true') {
      sessionStorage.setItem(DATADOG_SKIP_RECORDING_KEY, 'true');
    } else {
      sessionStorage.removeItem(DATADOG_SKIP_RECORDING_KEY);
    }
  }

  return sessionStorage.getItem(DATADOG_SKIP_RECORDING_KEY) === 'true';
};
