import type { ActionsLayoutType } from 'actions/ActionsLayout/ActionsLayoutProvider';
import { createRoutePath } from 'shared/utils/router';

export const strategyRoutesPaths = {
  root: createRoutePath<{
    teamSlug: string;
  }>('/teams/:teamSlug/strategy'),
  overview: {
    root: createRoutePath<{
      teamSlug: string;
    }>(`/teams/:teamSlug/strategy/overview`),
    pyramid: createRoutePath<{
      teamSlug: string;
    }>(`/teams/:teamSlug/strategy/overview/pyramid`),
  },
  objectives: {
    root: createRoutePath<{
      teamSlug: string;
    }>('/teams/:teamSlug/strategy/objectives'),
  },
  actions: {
    root: createRoutePath<
      {
        teamSlug: string;
      },
      { view: ActionsLayoutType }
    >('/teams/:teamSlug/strategy/actions'),
    create: createRoutePath<
      {
        teamSlug: string;
      },
      { view: ActionsLayoutType }
    >(`/teams/:teamSlug/strategy/actions/create`),
    action: {
      root: createRoutePath<
        {
          actionId: string;
          teamSlug: string;
        },
        { view: ActionsLayoutType }
      >(`/teams/:teamSlug/strategy/actions/:actionId`),
      edit: createRoutePath<
        {
          actionId: string;
          teamSlug: string;
        },
        { view: ActionsLayoutType }
      >(`/teams/:teamSlug/strategy/actions/:actionId/edit`),
    },
  },
  metrics: {
    root: createRoutePath<{
      teamSlug: string;
    }>('/teams/:teamSlug/strategy/metrics'),
  },
  initiatives: {
    root: createRoutePath<{
      teamSlug: string;
    }>('/teams/:teamSlug/strategy/initiatives'),
    calendar: createRoutePath<{
      teamSlug: string;
    }>(`/teams/:teamSlug/strategy/initiatives/calendar`),
    list: createRoutePath<{
      teamSlug: string;
    }>(`/teams/:teamSlug/strategy/initiatives/list`),
  },
};
