import type { ReactNode } from 'react';
import { createContext } from 'react';

import type { CampaignQuestionType } from 'types.graphql.generated';
import { useDefinedContext } from 'shared/utils/context.utils';

import type { ChatMessage } from './ChatProvider';

export type SurveyQuestion = {
  icon: ReactNode;
  id: string;
  messages: ChatMessage[];
  title: string;
  type?: CampaignQuestionType;
};

type QuestionsContextValue = {
  currentQuestion: SurveyQuestion;
  currentQuestionIndex: number;
  isCampaignActive: boolean;
  isReadOnly: boolean;
  questions: SurveyQuestion[];
  setCurrentQuestion: (question: SurveyQuestion) => void;
  visitedQuestions: SurveyQuestion[];
};

const QuestionsContext = createContext<QuestionsContextValue | undefined>(
  undefined,
);

export default QuestionsContext;

export const useQuestions = () => useDefinedContext(QuestionsContext);
