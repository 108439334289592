import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import Heading from 'shared/components/Heading';
import SendStatusRequestButton from 'status/SendStatusRequestButton';
import Flex from 'shared/components/Flex';
import ShareObjectiveStatusButton from 'objective/ShareObjectiveStatusButton';

import { useObjectiveInsights } from '../ObjectiveInsightsProvider';

const ObjectiveInsightsHeader = () => {
  const { t } = useTranslation();

  const { objective } = useObjectiveInsights();

  if (!objective) return null;

  return (
    <Flex
      justifyContent={'space-between'}
      alignItems={'center'}
      gap={8}
      css={css({ margin: '24px' })}
    >
      <Heading level={3} hasMargin={false}>
        {t('insight.insight_other')}
      </Heading>

      <Flex gap={8}>
        <SendStatusRequestButton strategyElement={objective} />
        <ShareObjectiveStatusButton objective={objective} />
      </Flex>
    </Flex>
  );
};

export default ObjectiveInsightsHeader;
