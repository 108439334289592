import { useState } from 'react';

import Space from 'shared/components/Space';
import IconButton from 'shared/components/IconButton';
import { ReactComponent as ArrowIconUp } from 'shared/static/icons/icon-arrow-up.svg';
import { ReactComponent as ArrowIconDown } from 'shared/static/icons/icon-arrow-down.svg';
import IfCanPerformOrgOrOrgUnitAction from 'user/ability/IfCanPerformOrgOrOrgUnitAction';

import type { StrategyRoomManageThemesThemeFragment } from './StrategyRoomThemesManageThemes.graphql.generated';
import ThemesListRowContextMenu from './ThemesListRowContextMenu';

type Props = {
  onReorder: (
    theme: StrategyRoomManageThemesThemeFragment,
    orderNumber: number,
    sortedThemes: StrategyRoomManageThemesThemeFragment[],
  ) => Promise<void>;
  orderNumber?: number;
  sectionThemes: StrategyRoomManageThemesThemeFragment[];
  theme: StrategyRoomManageThemesThemeFragment;
  themeNext?: StrategyRoomManageThemesThemeFragment;
  themePrev?: StrategyRoomManageThemesThemeFragment;
};

const ThemesListRowActions = ({
  sectionThemes,
  theme,
  themeNext,
  themePrev,
  onReorder,
}: Props) => {
  const [isAction, setIsAction] = useState(false);

  const moveUp = async () => {
    if (themePrev) {
      const prevOrderNumber = themePrev.orderNumber;
      if (!prevOrderNumber) return;
      setIsAction(true);
      await onReorder(
        theme,
        prevOrderNumber,
        swapValues(theme, themePrev, sectionThemes),
      );
      setIsAction(false);
    }
  };

  const moveDown = async () => {
    if (themeNext) {
      const nextOrderNumber = themeNext.orderNumber;
      if (!nextOrderNumber) return;
      setIsAction(true);
      await onReorder(
        theme,
        nextOrderNumber,
        swapValues(theme, themeNext, sectionThemes),
      );
      setIsAction(false);
    }
  };

  return (
    <Space>
      {themePrev && (
        <IconButton disabled={isAction} icon={ArrowIconUp} onClick={moveUp} />
      )}
      {themeNext && (
        <IconButton
          disabled={isAction}
          icon={ArrowIconDown}
          onClick={moveDown}
        />
      )}
      <IfCanPerformOrgOrOrgUnitAction
        orgAction={'WRITE_STRATEGY'}
        orgUnitAction={'WRITE'}
      >
        <ThemesListRowContextMenu
          themeId={theme.id}
          isActive={theme.isActive}
          isEmpty={theme.objectives.length === 0}
        />
      </IfCanPerformOrgOrOrgUnitAction>
    </Space>
  );
};

export default ThemesListRowActions;

const swapValues = <T extends { id: string }>(
  theme1: T,
  theme2: T,
  themes: T[],
): T[] => {
  const theme1Index = themes.findIndex(
    (eachTheme) => eachTheme.id === theme1.id,
  );
  const theme2Index = themes.findIndex(
    (eachTheme) => eachTheme.id === theme2.id,
  );

  const result = [...themes];

  if (theme1Index !== -1 && theme2Index !== -1) {
    result[theme1Index] = theme2;
    result[theme2Index] = theme1;
  }

  return result;
};
