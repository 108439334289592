import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import type { CardVariant } from '../Card.type';
import type { CardProviderContextValue } from './CardProvider.context';
import { CardProviderContext } from './CardProvider.context';

type CardProviderProps = PropsWithChildren<{
  variant: CardVariant;
}>;

const CardProvider = ({ children, variant }: CardProviderProps) => {
  const contextValue = useMemo<CardProviderContextValue>(
    () => ({
      variant,
    }),
    [variant],
  );

  return (
    <CardProviderContext.Provider value={contextValue}>
      {children}
    </CardProviderContext.Provider>
  );
};

export default CardProvider;
