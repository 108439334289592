import { useState, useMemo, createContext, useEffect } from 'react';
import type { PropsWithChildren, Dispatch, SetStateAction } from 'react';

export type TabsProviderProps = PropsWithChildren<{
  activeTabId?: string;
  tabIds?: string[];
}>;

export type TabsContextContextValue = {
  activeTab: number | undefined;
  setActiveTab: Dispatch<SetStateAction<number>>;
};

export const TabsContext = createContext<TabsContextContextValue | undefined>(
  undefined,
);

const TabsProvider = ({ tabIds, activeTabId, children }: TabsProviderProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    const index =
      tabIds !== undefined && activeTabId !== undefined
        ? tabIds.indexOf(activeTabId)
        : undefined;

    if (index !== undefined && index !== -1) {
      setActiveTab(index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabId, tabIds?.join(',')]);

  const contextValue = useMemo<TabsContextContextValue | undefined>(
    () => ({ activeTab, setActiveTab }),
    [activeTab],
  );

  if (contextValue) {
    return (
      <TabsContext.Provider value={contextValue}>
        {children}
      </TabsContext.Provider>
    );
  }

  return null;
};

export default TabsProvider;
