import { useTranslation } from 'react-i18next';
import type { PropsWithChildren, ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Layout from 'shared/components/Layout';
import Space from 'shared/components/Space';
import Card from 'shared/components/Card';
import LinkTabs from 'shared/components/LinkTabs';
import type { LinkTabItem } from 'shared/components/LinkTabs/LinkTabs.type';
import { strategyRoomRoutesPaths } from 'strategy/StrategyRoom/StrategyRoom.routing.paths';
import Text from 'shared/components/Text';

import Header from './Header';

const TabsContainer = styled.div`
  margin: 20px;
`;

const Description = styled.div`
  margin: 30px 20px 16px 20px;
`;

const CardContainer = styled.div`
  max-width: 336px;
`;

type Props = PropsWithChildren<{
  description: string;
  headerButton: ReactNode;
  infoCard: ReactNode;
}>;

const StrategyRoomThemesLayout = ({
  children,
  description,
  infoCard,
  headerButton,
}: Props) => {
  const { t } = useTranslation();

  const tabItems = [
    {
      name: t('strategy.strategyRoom.themes.tabs.manageThemes.title'),
      to: `../${strategyRoomRoutesPaths.themes.manageThemes()}`,
    },
    {
      name: t('strategy.strategyRoom.themes.tabs.manageObjectives.title'),
      to: `../${strategyRoomRoutesPaths.themes.manageObjectives()}`,
    },
  ] satisfies LinkTabItem[];

  return (
    <Layout.Content hasPadding={false}>
      <Space
        direction={'horizontal'}
        css={css`
          align-items: flex-start;
        `}
      >
        <Card
          border={'full'}
          padding={'none'}
          css={css`
            border-radius: 16px;
            flex: 1;
            padding: 24px 0;
          `}
        >
          <Header>{headerButton}</Header>
          <TabsContainer>
            <LinkTabs items={tabItems} />
          </TabsContainer>
          <Description>
            <Text variant={'emphasis'}>{description}:</Text>
          </Description>
          {children}
        </Card>
        <CardContainer>{infoCard}</CardContainer>
      </Space>
    </Layout.Content>
  );
};

export default StrategyRoomThemesLayout;
