import cn from 'classnames';
import { useMemo } from 'react';

import styles from './Stats.module.scss';
import type { Stat } from './Stats.type';
import StatText from './StatText';
import StatLine from './StatLine';
import { getStatsPercentages } from './Stats.utils';

type StatsProps = {
  className?: string;
  stats: Stat[];
};

const Stats = ({ stats, className }: StatsProps) => {
  const statsWithPercentages = useMemo(
    () => getStatsPercentages(stats),
    [stats],
  );

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.statsTextContainer}>
        {statsWithPercentages.map((stat) => (
          <StatText
            label={stat?.name}
            value={stat?.value}
            key={`${stat.name}${stat.value}`}
          />
        ))}
      </div>
      <div className={styles.statsLineChartContainer}>
        {statsWithPercentages.map((stat) => (
          <StatLine
            title={stat.name}
            value={stat.value}
            color={stat.color}
            key={`${stat.name}${stat.value}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Stats;
