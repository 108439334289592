import { useActiveOrg } from 'org/ActiveOrgProvider';
import type { Tool } from 'types.graphql.generated';

const useIsToolEnabled = (tool: Tool) => {
  const { activeOrg } = useActiveOrg();

  return activeOrg.toolsEnabled.includes(tool);
};

export default useIsToolEnabled;
