import { useDefinedContext } from 'shared/utils/context.utils';

import CardProvider from './CardProvider';
import { CardProviderContext } from './CardProvider.context';

export const useCard = () =>
  useDefinedContext(CardProviderContext, {
    providerName: CardProvider.name,
    hookName: useCard.name,
  });
