import { useMenuSection } from 'react-aria';
import type { Node, TreeState } from 'react-stately';
import cn from 'classnames';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

import type { BaseMenuItem } from '../Menu.type';
import MenuRowContainer from '../MenuRowContainer';
import styles from './MenuSection.module.scss';

type MenuSectionProps<MenuItem extends BaseMenuItem> = {
  item: Node<MenuItem>;
  state: TreeState<MenuItem>;
};

const MenuSection = <MenuItem extends BaseMenuItem>({
  item,
  state,
}: MenuSectionProps<MenuItem>) => {
  const { itemProps, headingProps, groupProps } = useMenuSection({
    heading: item.rendered,
    'aria-label': item['aria-label'],
  });

  const isFirstSection = item.key === state.collection.getFirstKey();

  const heading = useMemo<ReactNode>(() => {
    if (item.rendered) {
      return (
        <span {...headingProps} className={styles.menuSectionHeading}>
          {item.rendered}
        </span>
      );
    }
    return null;
  }, [item.rendered, headingProps]);

  return (
    <li
      {...itemProps}
      className={cn(styles.menuSection, {
        [styles.menuSectionFirst]: isFirstSection,
      })}
    >
      {heading}
      <ul {...groupProps} className={styles.menuSectionList}>
        {[...item.childNodes].map((node) => (
          <MenuRowContainer item={node} state={state} key={node.key} />
        ))}
      </ul>
    </li>
  );
};

export default MenuSection;
