import { createContext } from 'react';

import type { CardVariant } from '../Card.type';

export type CardProviderContextValue = {
  variant: CardVariant;
};

export const CardProviderContext = createContext<
  CardProviderContextValue | undefined
>(undefined);
