import { useTranslation } from 'react-i18next';
import type { PropsWithChildren, ReactNode } from 'react';
import { useMemo } from 'react';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useTeamAdapter } from 'team/TeamAdapter';
import Layout from 'shared/components/Layout/Layout';
import TeamImage from 'team/TeamImage';
import Space from 'shared/components/Space';

import { getBreadcrumbsItems } from './TeamTeamHierarchyLayout.utils';

type TeamTeamHierarchyLayoutProps = PropsWithChildren<object>;

const TeamTeamHierarchyLayout = ({
  children,
}: TeamTeamHierarchyLayoutProps) => {
  const { t } = useTranslation();

  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const { teamAdapter } = useTeamAdapter();

  const teamImage = useMemo<ReactNode>(() => {
    if (teamAdapter.isOrgUnit && orgUnit) {
      return <TeamImage orgUnit={orgUnit} size={'small'} />;
    }
    return <TeamImage size={'small'} />;
  }, [teamAdapter, orgUnit]);

  const heading = useMemo<ReactNode>(
    () => (
      <Space isCentered={true} size={'small'}>
        {teamImage}
        {t('team.teamHierarchy.title')}
      </Space>
    ),
    [t, teamImage],
  );

  const breadcrumbsItems = useMemo(
    () =>
      getBreadcrumbsItems({
        teamAdapter,
        orgUnit,
        activeOrg,
      }),
    [activeOrg, orgUnit, teamAdapter],
  );

  return (
    <>
      <Layout.Header heading={heading} breadcrumbsItems={breadcrumbsItems} />
      <Layout.Content hasPadding={false}>{children}</Layout.Content>
    </>
  );
};

export default TeamTeamHierarchyLayout;
