import { useDefinedContext } from 'shared/utils/context.utils';

import StrategyInsightsReportsProvider from './StrategyInsightsReportsProvider';
import { StrategyInsightsReportsProviderContext } from './StrategyInsightsReportsProvider.context';

export const useStrategyInsightsReports = () =>
  useDefinedContext(StrategyInsightsReportsProviderContext, {
    hookName: useStrategyInsightsReports.name,
    providerName: StrategyInsightsReportsProvider.name,
  });
