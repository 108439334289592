import { useCallback } from 'react';

import type {
  MultiSelectProps,
  RenderLabelFn,
} from 'shared/components/MultiSelect';
import MultiSelect from 'shared/components/MultiSelect';

import ThemeMultiSelectListBoxRow from './ThemeMultiSelectListBoxRow';
import ThemeMultiSelectLabel from './ThemeMultiSelectLabel';
import type { ThemeMultiSelectItem } from './ThemeMultiSelect.type';
import ThemeMultiSelectProvider, {
  useThemeMultiSelect,
} from './ThemeMultiSelectProvider';

type ThemeMultiSelectProps = Pick<
  MultiSelectProps<ThemeMultiSelectItem>,
  'triggerClassName'
>;

const ThemeMultiSelect = ({ triggerClassName }: ThemeMultiSelectProps) => {
  const { state, items } = useThemeMultiSelect();

  const renderLabel = useCallback<RenderLabelFn<ThemeMultiSelectItem>>(
    () => <ThemeMultiSelectLabel state={state} />,
    [state],
  );

  return (
    <MultiSelect<ThemeMultiSelectItem>
      state={state}
      items={items}
      ListBoxRow={ThemeMultiSelectListBoxRow}
      label={renderLabel}
      triggerClassName={triggerClassName}
    />
  );
};

ThemeMultiSelect.Provider = ThemeMultiSelectProvider;

export default ThemeMultiSelect;
