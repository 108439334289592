import { getIn } from 'formik';
import type { FormikProps, FormikValues } from 'formik';

export type FormUtils<Values extends FormikValues> = {
  hasError: (name: keyof (Values & FormikValues)) => boolean;
};

export const createFormUtils = <Values extends FormikValues>(
  formikProps: FormikProps<Values>,
): FormUtils<Values> => ({
  hasError: (name) =>
    getIn(formikProps.touched, name as string) &&
    !!getIn(formikProps.errors, name as string),
});
