import { createContext } from 'react';

import type { MetricOverviewMetric } from '../MetricOverview.type';

export type MetricOverviewContextValue = {
  isMetricLoading: boolean;
  metric: Maybe<MetricOverviewMetric>;
};

export const MetricOverviewContext = createContext<
  MetricOverviewContextValue | undefined
>(undefined);
