import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import UserAvatar from 'user/UserAvatar';
import Text from 'shared/components/Text';
import IconButton from 'shared/components/IconButton';
import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';
import UserActiveTag from 'user/UserActiveTag';

import styles from './MembersMember.module.scss';
import type { Member } from '../Members.type';

type MembersMemberProps = {
  isOwner: boolean;
  member: Member;
  onRemoveMember?: () => void;
};

const MembersMember = ({
  member,
  isOwner,
  onRemoveMember,
}: MembersMemberProps) => {
  const { t } = useTranslation();

  const ownerText = `(${t('user.members.owner')})`;
  const roleText = `(${member.role})`;
  const title = `${member.user?.displayName ?? member.user?.email} ${
    isOwner ? ownerText : roleText
  }`;

  return (
    <div
      className={cn(styles.container, {
        [styles.containerWithTrashButton]: !!onRemoveMember,
      })}
      title={title}
      key={member.id}
    >
      <UserAvatar
        user={member.user}
        hasLabel={true}
        containerClassName={styles.userAvatarContainer}
      />
      {isOwner && (
        <Text whiteSpace={'noWrap'} className={styles.caption}>
          {ownerText}
        </Text>
      )}
      {member.role ? (
        <Text whiteSpace={'noWrap'} className={styles.caption}>
          {roleText}
        </Text>
      ) : null}
      {member.user && <UserActiveTag user={member.user} />}
      {onRemoveMember ? (
        <IconButton
          icon={TrashIcon}
          onClick={onRemoveMember}
          className={styles.containerTrashButton}
        >
          {t('remove')}
        </IconButton>
      ) : null}
    </div>
  );
};

export default MembersMember;
