import type { PropsWithChildren } from 'react';
import { useEffect, useMemo, useState } from 'react';

import type { ItemSize } from '../List.type';
import type { ListProviderContextValue } from './ListProvider.context';
import { ListProviderContext } from './ListProvider.context';
import { defaultItemSize } from '../List.config';

type ListProviderProps = PropsWithChildren<{
  itemSize?: ItemSize;
}>;

const ListProvider = ({
  children,
  itemSize: itemSizeProp = defaultItemSize,
}: ListProviderProps) => {
  const [itemSize, setItemSize] = useState<ItemSize>(itemSizeProp);

  const contextValue = useMemo<ListProviderContextValue>(
    () => ({
      setItemSize,
      itemSize,
    }),
    [itemSize],
  );

  useEffect(() => {
    setItemSize(itemSizeProp);
  }, [itemSizeProp]);

  return (
    <ListProviderContext.Provider value={contextValue}>
      {children}
    </ListProviderContext.Provider>
  );
};

export default ListProvider;
