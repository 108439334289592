import styled from '@emotion/styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Collapse from 'shared/components/Collapse';
import TeamImage from 'team/TeamImage';
import { ReactComponent as ChevronIconUp } from 'shared/static/icons/icon-chevron-up.svg';
import { ReactComponent as ChevronIconDown } from 'shared/static/icons/icon-chevron-down.svg';
import IconButton from 'shared/components/IconButton';
import ThemesListRow from 'strategy/StrategyRoomThemesManageThemes/ThemesListRow';
import { sortThemes } from 'strategy/StrategyRoomThemesManageThemes/ThemesList';
import type { OrgUnit } from 'types.graphql.generated';
import { useActiveOrg } from 'org/ActiveOrgProvider';

import type {
  StrategyRoomManageObjectivesObjectiveFragment,
  StrategyRoomManageObjectivesThemeFragment,
} from './StrategyRoomThemesManageObjectives.graphql.generated';
import ThemeObjectives from './ThemeObjectives';

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 8px 20px;
`;

const Name = styled.p`
  font-weight: 700;
  color: ${(props) => props.theme.color.black};
  user-select: none;
`;

type Props = {
  objectives: StrategyRoomManageObjectivesObjectiveFragment[];
  orgUnit?: Pick<
    OrgUnit,
    'id' | 'name' | 'nameAbbreviated' | 'backgroundColorAsHex'
  >;
  themes: StrategyRoomManageObjectivesThemeFragment[];
};

const TeamRow = ({ orgUnit, themes, objectives }: Props) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { activeOrg } = useActiveOrg();

  const toggleIsCollapsed = () => setIsCollapsed((isCollapsed) => !isCollapsed);

  const teamThemes = themes.filter(
    (theme) => theme.orgUnit?.id === orgUnit?.id,
  );
  const sortedTeamThemes = teamThemes.sort(sortThemes);

  const noThemeObjectives = objectives.filter((objective) => !objective.theme);
  const teamNoThemeObjectives = noThemeObjectives.filter(
    (objective) => objective.orgUnit?.id === orgUnit?.id,
  );

  const name = orgUnit
    ? orgUnit.name || orgUnit.nameAbbreviated || orgUnit.id
    : activeOrg.displayName || activeOrg.domainNames[0] || activeOrg.id;

  return (
    <Collapse
      renderHeading={() => (
        <HeadingContainer>
          <TeamImage orgUnit={orgUnit} size={'tiny'} />
          <Name onClick={toggleIsCollapsed}>{name}</Name>
          <IconButton
            size={'small'}
            icon={!isCollapsed ? ChevronIconUp : ChevronIconDown}
            onClick={toggleIsCollapsed}
          />
        </HeadingContainer>
      )}
      isCollapsed={isCollapsed}
      hasChevron={false}
    >
      {sortedTeamThemes.map((theme, index) => (
        <ThemesListRow
          key={theme.id}
          themeId={theme.id}
          name={theme.name}
          description={theme.description}
          category={theme.themeCategory?.name}
          isActive={theme.isActive}
          showArchivedTag={true}
          hideInactive={true}
          objectives={objectives}
          orderNumber={index + 1}
          orderNumberStyle={'hidden'}
        >
          <ThemeObjectives objectives={objectives} theme={theme} />
        </ThemesListRow>
      ))}

      <ThemesListRow
        key={'no-theme'}
        themeId={undefined}
        name={t('theme.noTheme')}
        isActive={false}
        showArchivedTag={false}
        hideInactive={true}
        orderNumber={-1}
        orderNumberStyle={'hidden'}
        objectives={teamNoThemeObjectives}
      >
        <ThemeObjectives objectives={teamNoThemeObjectives} theme={undefined} />
      </ThemesListRow>
    </Collapse>
  );
};

export default TeamRow;
