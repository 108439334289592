import type { SetOptional } from 'type-fest';

import type { MenuProps } from 'shared/components/Menu';
import Menu from 'shared/components/Menu';

import ContextMenuTrigger from './ContextMenuTrigger';
import ContextMenuRow from './ContextMenuRow';
import type { ContextMenuItem } from './ContextMenu.type';

export type ContextMenuProps<
  ContextMenuItemParam extends ContextMenuItem = ContextMenuItem,
> = SetOptional<
  Pick<
    MenuProps<ContextMenuItemParam>,
    | 'items'
    | 'onAction'
    | 'disabledKeys'
    | 'Trigger'
    | 'placement'
    | 'triggerClassName'
    | 'triggerStyle'
    | 'testId'
  >,
  'Trigger' | 'disabledKeys'
>;

/**
 * Displays a list of relevant actions that can be taken in the context of currently viewed item or page.
 */
const ContextMenu = <
  ContextMenuItemParam extends ContextMenuItem = ContextMenuItem,
>({
  Trigger = ContextMenuTrigger,
  placement = 'bottom right',
  triggerClassName,
  triggerStyle,
  disabledKeys = [],
  ...restProps
}: ContextMenuProps<ContextMenuItemParam>) => (
  <Menu<ContextMenuItem>
    Row={ContextMenuRow}
    Trigger={Trigger}
    placement={placement}
    triggerClassName={triggerClassName}
    triggerStyle={triggerStyle}
    disabledKeys={disabledKeys}
    {...restProps}
  />
);

export default ContextMenu;
