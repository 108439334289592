import type { PropsWithChildren } from 'react';
import { useCallback, useMemo, useState } from 'react';

import type { LayoutProviderContextValue } from './LayoutProvider.context';
import { LayoutProviderContext } from './LayoutProvider.context';

type LayoutProviderProps = PropsWithChildren<object>;

const LayoutProvider = ({ children }: LayoutProviderProps) => {
  const [background, setBackground] =
    useState<LayoutProviderContextValue['background']>();
  const clearBackground = useCallback(() => {
    setBackground(undefined);
  }, [setBackground]);

  const contextValue = useMemo<LayoutProviderContextValue>(
    () => ({
      background,
      clearBackground,
      setBackground,
    }),
    [background, clearBackground],
  );

  return (
    <LayoutProviderContext.Provider value={contextValue}>
      {children}
    </LayoutProviderContext.Provider>
  );
};

export default LayoutProvider;
