import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';
import type { ChartProps } from 'react-chartjs-2';
import { Chart } from 'react-chartjs-2';
import styled from '@emotion/styled';

import {
  getBackgroundStyle,
  getLabelAndValue,
} from './ChartWithCustomLegend.utils';

type ChartWithCustomLegendProps = ChartProps;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ChartContainer = styled.div`
  max-height: 320px;
  min-height: 240px;
  width: 100%;
`;

const Legend = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Entry = styled.li(
  ({ theme }: { theme: Theme }) => css`
    align-items: center;
    background-color: ${theme.color.backgroundLight};
    border-radius: 8px;
    color: ${theme.color.typoSecondary};
    display: flex;
    gap: 8px;
    padding: 8px 20px;
  `,
);

const Strong = styled.span(
  ({ theme }: { theme: Theme }) => css`
    color: ${theme.color.typoPrimary};
    font-weight: 700;
  `,
);

const Indicator = styled.div<{ backgroundColor?: string; border?: string }>`
  border: ${(props) => props.border || '1px solid white'};
  border-radius: 1px;
  height: 9px;
  width: 9px;
  background-color: ${(props) => props.backgroundColor};
`;

const ChartWithCustomLegend = (props: ChartWithCustomLegendProps) => {
  const { options, ...restProps } = props;
  const {
    data: { datasets },
  } = restProps;

  return (
    <Container>
      <ChartContainer>
        <Chart
          {...restProps}
          options={{
            ...options,
            maintainAspectRatio: false,
            plugins: { legend: { display: false } },
          }}
        />
      </ChartContainer>

      <Legend>
        {datasets.map((dataset) => {
          const [label, value] = getLabelAndValue(dataset.label);
          const backgroundStyle = getBackgroundStyle(
            dataset.backgroundColor?.toString(),
          );
          return (
            <Entry key={dataset.label}>
              <Indicator
                backgroundColor={backgroundStyle.backgroundColor}
                border={backgroundStyle.border}
              />
              <div>
                {`${label}:`}
                <Strong>{value}</Strong>
              </div>
            </Entry>
          );
        })}
      </Legend>
    </Container>
  );
};

export default ChartWithCustomLegend;
