import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SelectThemeInputForOrgUnitQueryVariables = Types.Exact<{
  orgUnitId: Types.Scalars['ID'];
}>;


export type SelectThemeInputForOrgUnitQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, displayName?: string | undefined, themes: { __typename: 'ThemeConnection', edges: Array<{ __typename: 'ThemeEdge', node: { __typename: 'Theme', id: string, name?: string | undefined } }> } }, orgUnit: { __typename: 'OrgUnit', id: string, name?: string | undefined, themes: { __typename: 'ThemeConnection', edges: Array<{ __typename: 'ThemeEdge', node: { __typename: 'Theme', id: string, name?: string | undefined } }> }, parentOrgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined, themes: { __typename: 'ThemeConnection', edges: Array<{ __typename: 'ThemeEdge', node: { __typename: 'Theme', id: string, name?: string | undefined } }> } } | undefined } };

export type SelectThemeInputForOrgUnitOrgFragment = { __typename: 'Org', id: string, displayName?: string | undefined, themes: { __typename: 'ThemeConnection', edges: Array<{ __typename: 'ThemeEdge', node: { __typename: 'Theme', id: string, name?: string | undefined } }> } };

export type SelectThemeInputForOrgUnitOrgUnitFragment = { __typename: 'OrgUnit', id: string, name?: string | undefined, themes: { __typename: 'ThemeConnection', edges: Array<{ __typename: 'ThemeEdge', node: { __typename: 'Theme', id: string, name?: string | undefined } }> }, parentOrgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined, themes: { __typename: 'ThemeConnection', edges: Array<{ __typename: 'ThemeEdge', node: { __typename: 'Theme', id: string, name?: string | undefined } }> } } | undefined };

export type SelectThemeInputForOrgUnitThemeFragment = { __typename: 'Theme', id: string, name?: string | undefined };

export const SelectThemeInputForOrgUnitThemeFragmentDoc = gql`
    fragment selectThemeInputForOrgUnitTheme on Theme {
  id
  name
}
    `;
export const SelectThemeInputForOrgUnitOrgFragmentDoc = gql`
    fragment selectThemeInputForOrgUnitOrg on Org {
  id
  displayName
  themes(filter: {orgUnitIds: {operator: IS_NULL}, isActive: true}) {
    edges {
      node {
        ...selectThemeInputForOrgUnitTheme
      }
    }
  }
}
    ${SelectThemeInputForOrgUnitThemeFragmentDoc}`;
export const SelectThemeInputForOrgUnitOrgUnitFragmentDoc = gql`
    fragment selectThemeInputForOrgUnitOrgUnit on OrgUnit {
  id
  name
  themes(filter: {isActive: true}) {
    edges {
      node {
        ...selectThemeInputForOrgUnitTheme
      }
    }
  }
  parentOrgUnit {
    id
    name
    themes(filter: {isActive: true}) {
      edges {
        node {
          ...selectThemeInputForOrgUnitTheme
        }
      }
    }
  }
}
    ${SelectThemeInputForOrgUnitThemeFragmentDoc}`;
export const SelectThemeInputForOrgUnitDocument = gql`
    query SelectThemeInputForOrgUnit($orgUnitId: ID!) {
  activeOrg {
    ...selectThemeInputForOrgUnitOrg
  }
  orgUnit(id: $orgUnitId) {
    ...selectThemeInputForOrgUnitOrgUnit
  }
}
    ${SelectThemeInputForOrgUnitOrgFragmentDoc}
${SelectThemeInputForOrgUnitOrgUnitFragmentDoc}`;

/**
 * __useSelectThemeInputForOrgUnitQuery__
 *
 * To run a query within a React component, call `useSelectThemeInputForOrgUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectThemeInputForOrgUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectThemeInputForOrgUnitQuery({
 *   variables: {
 *      orgUnitId: // value for 'orgUnitId'
 *   },
 * });
 */
export function useSelectThemeInputForOrgUnitQuery(baseOptions: Apollo.QueryHookOptions<SelectThemeInputForOrgUnitQuery, SelectThemeInputForOrgUnitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectThemeInputForOrgUnitQuery, SelectThemeInputForOrgUnitQueryVariables>(SelectThemeInputForOrgUnitDocument, options);
      }
export function useSelectThemeInputForOrgUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectThemeInputForOrgUnitQuery, SelectThemeInputForOrgUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectThemeInputForOrgUnitQuery, SelectThemeInputForOrgUnitQueryVariables>(SelectThemeInputForOrgUnitDocument, options);
        }
export type SelectThemeInputForOrgUnitQueryHookResult = ReturnType<typeof useSelectThemeInputForOrgUnitQuery>;
export type SelectThemeInputForOrgUnitLazyQueryHookResult = ReturnType<typeof useSelectThemeInputForOrgUnitLazyQuery>;
export type SelectThemeInputForOrgUnitQueryResult = Apollo.QueryResult<SelectThemeInputForOrgUnitQuery, SelectThemeInputForOrgUnitQueryVariables>;