import type { Org } from 'types.graphql.generated';
import type { OrgUnitFormValues } from 'orgUnit/OrgUnitForm';
import { resolveSetRemoveIdInput as resolveSelectOrgUnitInputSetRemoveIdInput } from 'orgUnit/SelectOrgUnitInput';

import type { CreateOrgUnitMutationVariables } from './CreateOrgUnitForm.graphql.generated';

export const resolveCreateOrgUnitInput = (params: {
  orgKey: Org['orgKey'];
  values: OrgUnitFormValues;
}): CreateOrgUnitMutationVariables['input'] => {
  const { values, orgKey } = params;
  return {
    orgKey: orgKey,
    name: values.name,
    nameAbbreviated: values.nameAbbreviated,
    description: values.description,
    parentOrgUnit: resolveSelectOrgUnitInputSetRemoveIdInput({
      option: values.parent,
    }),
    orgUnitLead: { emailToSet: values.lead },
    orgUnitAdmins: values.admins.map((emailToSet) => ({ emailToSet })),
    backgroundColorAsHex: values.backgroundColor,
  };
};
