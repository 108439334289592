import type { TypePolicies } from '@apollo/client';

import { readDateTimeField } from 'shared/graphql/utils';
import { readStatusIndicatorField } from 'shared/status/StatusIndicator';

export const reportTypePolicies: TypePolicies = {
  ReportStatus: {
    merge: true,
    keyFields: ['statusDateTime'],
    fields: {
      statusIndicator: {
        read: readStatusIndicatorField,
      },
    },
  },
  Report: {
    fields: {
      statusCutoffTime: readDateTimeField,
      statusDeadlineTime: readDateTimeField,
      reportSelection: {
        merge: true,
      },
    },
  },
};
