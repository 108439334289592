import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useToggle } from 'react-use';
import compact from 'lodash/compact';

import StrategyRoadmapChips from 'strategy/StrategyRoadmap/StrategyRoadmapChips';
import type { CardBorder } from 'shared/components/Card/Card.type';

import { illustrations, backgroundColors } from '../StrategyRoadmap.static';
import StrategyRoadmapCard from '../StrategyRoadmapCard';
import StrategyRoadmapThemesModal from '../StrategyRoadmapThemesModal';
import type { StrategyRoadmapObjectivesByTheme } from '../StrategyRoadmap.type';

type StrategyRoadmapThemesCardProps = {
  border?: CardBorder;
  hasShadow?: boolean;
  objectivesByTheme: StrategyRoadmapObjectivesByTheme[];
  showEditButton?: boolean;
};

const StrategyRoadmapThemesCard = ({
  objectivesByTheme,
  hasShadow,
  border,
  showEditButton = true,
}: StrategyRoadmapThemesCardProps) => {
  const [isModalOpened, toggleIsModalOpened] = useToggle(false);

  const { t } = useTranslation();

  const filteredObjectivesByTheme = objectivesByTheme.map((theme) => ({
    ...theme,
    objectives: theme.objectives.filter(
      (objective) => !objective.currentObjectiveStatus?.complete,
    ),
  }));

  const isEmpty = useMemo(
    () => !filteredObjectivesByTheme.length,
    [filteredObjectivesByTheme],
  );

  const content = useMemo(() => {
    if (!isEmpty) {
      const items = compact(
        filteredObjectivesByTheme.map(({ theme, objectives }) => {
          if (!theme?.isActive) {
            return null;
          }

          const statusMarksItems = objectives.map(
            ({ currentObjectiveStatus }) => ({
              statusIndicator: currentObjectiveStatus?.statusIndicator,
              updateDateTime:
                currentObjectiveStatus?.auditRecord?.updateDateTime,
            }),
          );

          return {
            id: theme.id,
            children: theme.name,
            statusMarksItems,
          };
        }),
      );
      return (
        <StrategyRoadmapChips
          items={items}
          maxItemsCount={6}
          onClickMore={toggleIsModalOpened}
        />
      );
    }
    return t('noData');
  }, [isEmpty, filteredObjectivesByTheme, toggleIsModalOpened, t]);

  return (
    <>
      <StrategyRoadmapCard
        illustration={illustrations.themes}
        illustrationBackgroundColor={backgroundColors.themes}
        heading={t('strategy.strategyRoadmap.themes.card.heading')}
        onMaximize={!isEmpty ? toggleIsModalOpened : undefined}
        hasShadow={hasShadow}
        border={border}
      >
        {content}
      </StrategyRoadmapCard>
      <StrategyRoadmapThemesModal
        objectivesByTheme={filteredObjectivesByTheme}
        isOpen={isModalOpened}
        onClose={toggleIsModalOpened}
        showEditButton={showEditButton}
      />
    </>
  );
};

export default StrategyRoadmapThemesCard;
