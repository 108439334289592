import type { ListBoxRowComponentProps } from 'shared/components/ListBox';
import Text from 'shared/components/Text';

import type { ThemeMultiSelectItem } from '../ThemeMultiSelect.type';

type ThemeMultiSelectListBoxRowProps =
  ListBoxRowComponentProps<ThemeMultiSelectItem>;

const ThemeMultiSelectListBoxRow = ({
  item,
}: ThemeMultiSelectListBoxRowProps) => (
  <Text variant={'emphasis'}>{item.name}</Text>
);

export default ThemeMultiSelectListBoxRow;
