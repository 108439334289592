import { createContext } from 'react';

import type { ObjectiveRelatedObjectivesObjectiveFragment } from './ObjectiveRelatedObjectivesProvider.graphql.generated';

export type ObjectiveRelatedObjectivesContextValue = {
  isRelatedObjectivesLoading: boolean;
  objective: Maybe<ObjectiveRelatedObjectivesObjectiveFragment>;
  relatedObjectives: Maybe<ObjectiveRelatedObjectivesObjectiveFragment[]>;
};

export const ObjectiveRelatedObjectivesContext = createContext<
  ObjectiveRelatedObjectivesContextValue | undefined
>(undefined);
