import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import ActivityTimeline from 'shared/components/ActivityTimeline';
import { ReactComponent as EmptyIllustration } from 'shared/static/illustrations/illustration-empty-state.svg';
import Space from 'shared/components/Space';
import Result from 'shared/components/Result';
import type { ActivityTimelineProps } from 'shared/components/ActivityTimeline/ActivityTimeline';
import Spinner from 'shared/spinner/Spinner';

import { useObjectiveInsights } from '../ObjectiveInsightsProvider';
import ObjectiveInsightsActivityTimelineRow from '../ObjectiveInsightsActivityTimelineRow';
import ObjectiveInsightsActivityTimelineFirstRow from '../ObjectiveInsightsActivityTimelineFirstRow';
import type { ObjectiveInsightsStatus } from '../ObjectiveInsights.type';
import { getObjectiveStatusListWithObjectiveCreationDate } from './ObjectiveInsightsContent.utils';
import styles from './ObjectiveInsightsContent.module.scss';

const ObjectiveInsightsContent = () => {
  const { t } = useTranslation();

  const { objective } = useObjectiveInsights();

  const objectiveStatusList = useMemo(
    () =>
      objective
        ? getObjectiveStatusListWithObjectiveCreationDate(
            objective,
            objective.objectiveStatusListAll,
          )
        : undefined,
    [objective],
  );

  const hasInsights = !!objective?.auditRecord.createDateTime;

  const renderRowContent = useCallback<
    ActivityTimelineProps<ObjectiveInsightsStatus>['renderRowContent']
  >(
    ({ isRowExpanded, isLastRow, item }) =>
      item.context === 'objectiveCreated' ? (
        <ObjectiveInsightsActivityTimelineFirstRow
          isLastRow={isLastRow}
          objective={objective}
          key={objective?.id}
        />
      ) : (
        objective && (
          <ObjectiveInsightsActivityTimelineRow
            objective={objective}
            isRowExpanded={isRowExpanded}
            isLastRow={isLastRow}
            status={item}
            key={item.id}
          />
        )
      ),
    [objective],
  );

  if (!objective || !objectiveStatusList) return <Spinner.Circle />;

  return hasInsights ? (
    <ActivityTimeline
      items={objectiveStatusList}
      itemDateKey={'statusDateTime'}
      renderRowContent={renderRowContent}
      className={styles.timeline}
    />
  ) : (
    <Space isCentered={true}>
      <Result
        description={t('objective.insights.recentInsights.noRecentInsights')}
        illustration={EmptyIllustration}
        hasBackground={true}
      />
    </Space>
  );
};

export default ObjectiveInsightsContent;
