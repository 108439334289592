import { useState, type PropsWithChildren, useMemo, useEffect } from 'react';

import { useSteps } from 'shared/components/Steps';

import { InsightsReportWizardContext } from './InsightsReportWizardProvider.context';

const InsightsReportWizardProvider = ({ children }: PropsWithChildren) => {
  const [hasSelection, setHasSelection] = useState(false);
  const [hasStatusRequests, setHasStatusRequests] = useState(false);
  const { setDisabledSteps } = useSteps();

  useEffect(() => {
    setDisabledSteps(
      hasSelection
        ? []
        : ['statusUpdate', 'successes', 'challenges', 'actions'],
    );
  }, [hasSelection, setDisabledSteps]);

  const value = useMemo(
    () => ({
      hasSelection,
      setHasSelection,
      hasStatusRequests,
      setHasStatusRequests,
    }),
    [hasSelection, hasStatusRequests],
  );

  return (
    <InsightsReportWizardContext.Provider value={value}>
      {children}
    </InsightsReportWizardContext.Provider>
  );
};

export default InsightsReportWizardProvider;
