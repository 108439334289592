import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';

import Caption from 'shared/components/Caption';
import CollapsibleList from 'shared/components/CollapsibleList';
import { ReactComponent as LayoutLayersIcon } from 'shared/static/icons/icon-layout-layers.svg';

import type { getStrategyThemes } from '../StrategyBoard.utils';
import StrategyBoardList from '../StrategyBoardList';
import styles from './StrategyBoardThemesSection.module.scss';
import {
  getInitiativesWithStatus,
  getMetricsWithStatus,
  getObjectiveWithStatus,
} from './StrategyBoardThemeSection.utils';

type StrategyBoardThemesSectionProps = {
  isExpanded?: boolean;
  objectivesByTheme: ReturnType<typeof getStrategyThemes>[0];
};

const StrategyBoardThemesSection = ({
  objectivesByTheme,
  isExpanded,
}: StrategyBoardThemesSectionProps) => {
  const { t } = useTranslation();

  const { theme, objectives } = objectivesByTheme;

  const isEmptySection = objectives.length === 0;

  if (isEmptySection) return null;

  return (
    <CollapsibleList
      title={theme?.name ?? t('theme.noTheme')}
      type={'objective'}
      listClassName={styles.themeSection}
      titleIcon={LayoutLayersIcon}
      childrenCount={objectives.length}
      expanded={isExpanded}
    >
      <header className={styles.header}>
        <Caption size={'C2'} color={'secondary'}>
          {capitalize(t('objective.objective_other'))}
        </Caption>
        <Caption size={'C2'} color={'secondary'}>
          {capitalize(t('metric.metric_other'))}
        </Caption>
        <Caption size={'C2'} color={'secondary'}>
          {capitalize(t('initiative.initiative_other'))}
        </Caption>
      </header>

      {objectives.map(({ metrics, initiatives, ...objective }) => {
        const objectiveWithStatus = getObjectiveWithStatus(objective as any);
        const metricsWithStatus = getMetricsWithStatus(metrics, objective.id);
        const initiativesWithStatus = getInitiativesWithStatus(
          initiatives,
          objective.id,
        );

        return (
          <li key={objective.id} className={styles.objectiveSection}>
            <StrategyBoardList
              items={[objectiveWithStatus]}
              operationalItemType={'Objective'}
              isDisabled={!objective.isFiltersMatch}
            />
            <StrategyBoardList
              items={metricsWithStatus}
              operationalItemType={'Metric'}
            />
            <StrategyBoardList
              items={initiativesWithStatus}
              operationalItemType={'Initiative'}
            />
          </li>
        );
      })}
    </CollapsibleList>
  );
};

export default StrategyBoardThemesSection;
