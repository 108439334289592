import { useMemo } from 'react';

import Calendar from 'shared/components/Calendar';
import InitiativeChip from 'initiative/InitiativeChip';
import type { Objective } from 'types.graphql.generated';
import { useTeamAdapter } from 'team/TeamAdapter';

import type { StrategyInsightsInitiativeFragment } from '../StrategyInsights.graphql.generated';

type StrategyInsightsCalendarViewProps = {
  // todo replace initiatives prop type with more readable type
  initiatives: Array<
    StrategyInsightsInitiativeFragment & {
      objective?: Pick<Objective, 'id' | 'name'>;
    }
  >;
  year: number;
};

const StrategyInsightsCalendarView = ({
  year,
  initiatives,
}: StrategyInsightsCalendarViewProps) => {
  const { teamAdapter } = useTeamAdapter();

  const items = useMemo(
    () =>
      initiatives
        .map((initiative) => {
          const { endDate, startDate } = initiative.timeLine;
          if (endDate && startDate) {
            return {
              children: (
                <InitiativeChip
                  initiative={initiative}
                  teamAdapter={teamAdapter}
                />
              ),
              startDateTime: initiative.timeLine.startDate!,
              endDateTime: initiative.timeLine.endDate!,
            };
          }
        })
        .filter(Boolean),
    [initiatives, teamAdapter],
  );

  const startDateTime = useMemo(() => new Date(year, 0), [year]);

  const endDateTime = useMemo(
    () => new Date(new Date(year + 1, 0).getTime() - 1),
    [year],
  );

  return (
    <Calendar
      items={items}
      intervalUnit={'month'}
      startDateTime={startDateTime}
      endDateTime={endDateTime}
      isWrapped={true}
    />
  );
};

export default StrategyInsightsCalendarView;
