import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

import { ReactComponent as LinkIcon } from 'shared/static/icons/icon-link.svg';
import { ReactComponent as CheckSquareIcon } from 'shared/static/icons/icon-check-square.svg';
import OverviewLinkItem from 'shared/components/OverviewLinkItem';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';

import styles from './ObjectiveOverviewNav.module.scss';
import { useObjectiveOverview } from '../ObjectiveOverviewProvider/useObjectiveOverview';

const ObjectiveOverviewNav = () => {
  const { t } = useTranslation();

  const { objectiveId } = useParams() as {
    objectiveId: string;
  };

  const { teamAdapter } = useTeamAdapter();
  const { objective } = useObjectiveOverview();

  const {
    actionsCount,
    hasActions,
    relatedObjectivesCount,
    hasRelatedObjectives,
  } = useMemo(() => {
    const relatedObjectivesCount = objective?.childObjectives.length;
    const actionsCount = objective?.actions?.length;
    const hasRelatedObjectives = !!relatedObjectivesCount;
    const hasActions = !!actionsCount;

    return {
      actionsCount,
      hasActions,
      relatedObjectivesCount,
      hasRelatedObjectives,
    };
  }, [objective]);

  return (
    <nav>
      <ul className={styles.container}>
        {hasRelatedObjectives ? (
          <OverviewLinkItem
            icon={LinkIcon}
            to={objectiveRoutesPaths.relatedObjectives({
              params: {
                teamSlug: teamAdapter.toParam(),
                objectiveId,
              },
            })}
          >
            {relatedObjectivesCount}{' '}
            {t('objective.relatedObjectives.relatedObjectives', {
              count: relatedObjectivesCount,
            })}
          </OverviewLinkItem>
        ) : (
          <li className={styles.noContent}>
            {t('objective.relatedObjectives.noRelatedObjectives')}
          </li>
        )}
        {hasActions ? (
          <OverviewLinkItem
            icon={CheckSquareIcon}
            to={objectiveRoutesPaths.actions.root({
              params: {
                teamSlug: teamAdapter.toParam(),
                objectiveId,
              },
            })}
          >
            {actionsCount} {t('actions.action', { count: actionsCount })}
          </OverviewLinkItem>
        ) : (
          <li className={styles.noContent}>{t('actions.noActions')}</li>
        )}
      </ul>
    </nav>
  );
};

export default ObjectiveOverviewNav;
