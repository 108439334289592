import type { TFunction } from 'react-i18next';

import { toolkitRoutesPaths } from 'toolkit/toolkit.routing.paths';
import type { BreadcrumbsProps } from 'shared/components/Breadcrumbs';
import type { TeamAdapter } from 'team/TeamAdapter';

export const getBreadcrumbsItems = (
  teamAdapter: TeamAdapter,
  t: TFunction,
): BreadcrumbsProps['items'] => [
  {
    name: t('toolkit.toolkit'),
    to: toolkitRoutesPaths.root({
      params: {
        teamSlug: teamAdapter.toParam(),
      },
    }),
  },
];
