import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import WalkOfStrategyCampaignForm from 'toolkit/WalkOfStrategyCampaignForm';
import Spinner from 'shared/spinner/Spinner';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import Dialog from 'shared/components/Dialog';
import { campaignFormId } from 'toolkit/WalkOfStrategyCampaignForm/WalkOfStrategyCampaignForm';

import { useGetWalkOfStrategyCampaignQuery } from './GetWalkOfStrategyCampaign.graphql.generated';

type Props = {
  campaignId: string;
  isOpen: boolean;
  onClose: () => void;
};

const WalkOfStrategyEditCampaign = ({ campaignId, isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  const handleApolloError = useHandleApolloError();

  const { data } = useGetWalkOfStrategyCampaignQuery({
    variables: { id: campaignId },
    fetchPolicy: 'network-only',
    onError: handleApolloError,
    skip: !isOpen,
  });

  return (
    <Dialog
      headerTitle={t('toolkit.toolPage.walkOfStrategy.editCampaign.edit.title')}
      isOpen={isOpen}
      onClose={onClose}
      confirmLabel={t('update')}
      cancelLabel={t('cancel')}
      formId={campaignFormId}
    >
      {data ? (
        <WalkOfStrategyCampaignForm
          onSuccess={onClose}
          initialValues={{
            deadline: data.campaign.deadline,
            startDate: data.campaign.timeLine.startDate,
            endDate: data.campaign.timeLine.endDate,
            id: data.campaign.id,
            name: data.campaign.name,
            owner: data.campaign.owner.email,
            strategy: data.campaign.strategy,
          }}
        />
      ) : (
        <div css={css({ minHeight: 200 })}>
          <Spinner.Circle />
        </div>
      )}
    </Dialog>
  );
};

export default WalkOfStrategyEditCampaign;
