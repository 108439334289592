import { useDefinedContext } from 'shared/utils/context.utils';

import { PriorityMultiSelectProviderContext } from './PriorityMultiSelectProvider.context';
import PriorityMultiSelectProvider from './PriorityMultiSelectProvider';

export const usePriorityMultiSelect = () =>
  useDefinedContext(PriorityMultiSelectProviderContext, {
    hookName: usePriorityMultiSelect.name,
    providerName: PriorityMultiSelectProvider.name,
  });
