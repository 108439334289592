import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCallback } from 'react';

import EditMetric from 'metric/EditMetric';
import type { RedirectToMyContributionLocation } from 'contribution';
import { useTeamSlug } from 'team/TeamAdapter/useTeamSlug';

import { handleOnClose } from './handleOnClose';

const EditMetricPage = () => {
  const { metricId } = useParams() as { metricId: Maybe<string> };
  const { state } = useLocation() as RedirectToMyContributionLocation;
  const { orgTeamSlug } = useTeamSlug();
  const navigate = useNavigate();

  const handleClose = useCallback(
    () =>
      handleOnClose({
        options: { state },
        orgTeamSlug,
        navigate,
      }),
    [navigate, orgTeamSlug, state],
  );

  if (!metricId) return null;

  return <EditMetric metricId={metricId} isOpen={true} onClose={handleClose} />;
};

export default EditMetricPage;
