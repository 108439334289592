import { useField, useFormikContext } from 'formik';

import type { InputBoxProps } from 'shared/components/InputBox';
import InputBox from 'shared/components/InputBox';

const FieldBox = (props: InputBoxProps) => {
  const [_field, meta, _helpers] = useField(props.name);
  const { submitCount } = useFormikContext();

  const error = meta.touched || submitCount > 0 ? meta.error : undefined;

  return <InputBox {...props} hasError={!!error} message={error} />;
};

export default FieldBox;
