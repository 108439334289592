import { useCallback, useState } from 'react';

import SelectObjectiveDialog from './SelectObjectiveDialog/SelectObjectiveDialog';
import type {
  CreateTaskObjective,
  OneThingCampaignForCreateTask,
} from './OneThingCreateTaskButton.types';
import TaskDialog from './TaskDialog';

type Props = {
  campaign: OneThingCampaignForCreateTask;
  isOpen: boolean;
  onClose: () => void;
};

const CreateTaskWizard = ({ campaign, isOpen, onClose }: Props) => {
  const [currentStep, setCurrentStep] = useState<0 | 1>(0);

  const [objective, setObjective] = useState<CreateTaskObjective>();

  const handleClose = useCallback(() => {
    setCurrentStep(0);
    setObjective(undefined);
    onClose();
  }, [onClose]);

  switch (currentStep) {
    case 0:
      return (
        <SelectObjectiveDialog
          campaign={campaign}
          isOpen={isOpen}
          onClose={handleClose}
          onSelect={(selectedObjective: CreateTaskObjective) => {
            setObjective(selectedObjective);
            setCurrentStep(1);
          }}
        />
      );
    case 1:
      return objective ? (
        <TaskDialog
          campaign={campaign}
          objective={objective}
          isOpen={isOpen}
          onSelect={() => setCurrentStep(1)}
          onBack={() => setCurrentStep(0)}
          onClose={handleClose}
        />
      ) : null;
  }
};

export default CreateTaskWizard;
