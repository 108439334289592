import type { ApolloError } from '@apollo/client';
import type { GraphQLError } from 'graphql';
import { isObject } from 'lodash';
import type { TFunction } from 'react-i18next';

export const findGraphQLErrorByErrorCode = (params: {
  apolloError: ApolloError;
  errorCode: string;
}): Maybe<GraphQLError> => {
  const { errorCode, apolloError } = params;
  return apolloError.graphQLErrors.find(
    (graphqlError) => graphqlError.extensions.errorCode === errorCode,
  );
};

export const getCurrentGraphQLError = (error: ApolloError) =>
  error.graphQLErrors[0];

export const getGraphQLErrorCode = (error: GraphQLError) =>
  error.extensions.errorCode;

export const isGraphqlError = (error: unknown): error is GraphQLError =>
  isObject(error) && 'extensions' in error;

export const getGraphqlErrorMessage = (t: TFunction, error: GraphQLError) => {
  const errorCode = getGraphQLErrorCode(error);

  if (!errorCode) return t(`errorMessage.unknown`);

  const hasErrorCodeMessage = (
    t('errorMessage', { returnObjects: true }) satisfies Record<string, string>
  )[errorCode];

  if (!hasErrorCodeMessage) return t(`errorMessage.unknown`);

  return t(`errorMessage.${errorCode}`, error.extensions);
};
