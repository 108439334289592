import { ReactComponent as SubTeamsIcon } from 'shared/static/icons/icon-sub-teams.svg';
import useCanPerformOrgOrOrgUnitAction from 'user/ability/useCanPerformOrgOrOrgUnitAction';

import { useShowChildrenFilter } from './useShowChildrenFilter';
import ToggleFilterButton from '../ToggleFilterButton';

type Props = {
  activeTooltip: string;
  inactiveTooltip: string;
};

const ShowChildrenFilterButton = ({
  activeTooltip,
  inactiveTooltip,
}: Props) => {
  const { showChildren, toggleShowChildren } = useShowChildrenFilter();

  const isVisible = useCanPerformOrgOrOrgUnitAction('WRITE_STRATEGY', 'WRITE');

  if (!isVisible) return null;

  return (
    <ToggleFilterButton
      Icon={SubTeamsIcon}
      activeTooltip={activeTooltip}
      inactiveTooltip={inactiveTooltip}
      isActive={showChildren}
      onToggleActive={toggleShowChildren}
    />
  );
};

export default ShowChildrenFilterButton;
