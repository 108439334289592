import { matchPath } from 'react-router-dom';
import { AnimatePresence, LayoutGroup } from 'framer-motion';
import styled from '@emotion/styled';

import type { BreadcrumbsItem } from './Breadcrumbs.type';
import Breadcrumb from './Breadcrumb';

export type BreadcrumbsProps = {
  className?: string;
  items: BreadcrumbsItem[];
};

const NavContainer = styled.nav({
  alignItems: 'center',
  display: 'flex',
  height: '20px',
});

const ListContainer = styled.ol({
  display: 'flex',
});

const Breadcrumbs = ({ items, className }: BreadcrumbsProps) => (
  <NavContainer className={className}>
    <ListContainer>
      <LayoutGroup>
        <AnimatePresence initial={false}>
          {items.map((item, itemIndex) => {
            const isLinkMatched =
              !!item.to && !!matchPath(item.to, window.location.pathname);
            const isFirstItem = itemIndex === 0;
            return (
              <Breadcrumb
                item={item}
                isFirstItem={isFirstItem}
                isLinkMatched={isLinkMatched}
                key={item.to + item.name + itemIndex}
              />
            );
          })}
        </AnimatePresence>
      </LayoutGroup>
    </ListContainer>
  </NavContainer>
);

export default Breadcrumbs;
