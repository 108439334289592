import { Suspense } from 'react';

import Spinner from 'shared/spinner/Spinner';
import Layout from 'shared/components/Layout';

import ObjectiveInsightsProvider, {
  useObjectiveInsights,
} from './ObjectiveInsightsProvider';
import styles from './ObjectiveInsights.module.scss';
import ObjectiveInsightsHeader from './ObjectiveInsightsHeader';
import ObjectiveInsightsContent from './ObjectiveInsightsContent';

const ObjectiveInsights = () => {
  const { isObjectiveLoading } = useObjectiveInsights();

  return (
    <>
      <Layout.Content
        hasPadding={false}
        variant={'contained'}
        className={styles.layout}
      >
        <Suspense fallback={<Spinner.Circle />}>
          {isObjectiveLoading ? (
            <Spinner.Circle />
          ) : (
            <>
              <ObjectiveInsightsHeader />
              <ObjectiveInsightsContent />
            </>
          )}
        </Suspense>
      </Layout.Content>
    </>
  );
};

export default () => (
  <ObjectiveInsightsProvider>
    <ObjectiveInsights />
  </ObjectiveInsightsProvider>
);
