import { Reorder } from 'framer-motion';
import { useEffect, useState } from 'react';

type DraggableListProps<T> = {
  items: T[];
  onReorder: (item: T, position: number, orderedItems: T[]) => Promise<unknown>;
  renderItem: (item: T, index: number) => React.ReactNode;
};

const DraggableList = <T extends { orderNumber?: number }>({
  items,
  renderItem,
  onReorder,
}: DraggableListProps<T>) => {
  const [localItems, setLocalItems] = useState(items);
  const [localItemsBeforeDrag, setLocalItemsBeforeDrag] = useState(items);

  useEffect(() => {
    setLocalItems(items);
  }, [items]);

  const handleReorder = (values: any) => {
    setLocalItems(values);
  };

  return (
    <Reorder.Group
      axis={'y'}
      values={localItems}
      onReorder={handleReorder}
      style={{ listStyle: 'none', margin: 0, padding: 0 }}
    >
      {localItems.map((item: any, i: number) => (
        <Reorder.Item
          key={item.id}
          value={item}
          onDragStart={() => setLocalItemsBeforeDrag(localItems)}
          onDragEnd={() => {
            const targetIndex = localItems.indexOf(item);

            const currentItemInTargetPosition =
              localItemsBeforeDrag[targetIndex];

            const isItemInSamePosition = item === currentItemInTargetPosition;

            if (isItemInSamePosition) return;

            const targetPosition =
              currentItemInTargetPosition.orderNumber || targetIndex + 1;

            onReorder(item, targetPosition, localItems);
          }}
        >
          {renderItem(item, i)}
        </Reorder.Item>
      ))}
    </Reorder.Group>
  );
};

export default DraggableList;
