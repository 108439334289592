import { useRef } from 'react';
import { useToggle } from 'react-use';

import type { MenuItemProps } from 'shared/components/__DEPRECATED__/Menu';
import Menu from 'shared/components/__DEPRECATED__/Menu';

type LayoutMenuHeaderProps = {
  menuItems?: MenuItemProps[];
  testId?: string;
};

const LayoutMenuHeader = ({ menuItems, testId }: LayoutMenuHeaderProps) => {
  const menuButtonElementRef = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, toggleIsMenuOpen] = useToggle(false);

  return menuItems?.length ? (
    <div>
      <Menu.Button
        onClick={toggleIsMenuOpen}
        ref={menuButtonElementRef}
        size={'small'}
        testId={testId}
      />
      <Menu
        referenceElement={menuButtonElementRef.current}
        isOpen={isMenuOpen}
        onClose={() => {
          toggleIsMenuOpen(false);
        }}
      >
        {menuItems?.map((menuItem, menuItemIndex) => (
          <Menu.Item {...menuItem} key={menuItemIndex} />
        ))}
      </Menu>
    </div>
  ) : null;
};

export default LayoutMenuHeader;
