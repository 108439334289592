import { Trans, useTranslation } from 'react-i18next';

import { yup } from 'shared/services/yup.service';
import StatusForm from 'shared/status/StatusForm';
import Text from 'shared/components/Text';
import type { StatusFormProps } from 'shared/status/StatusForm';
import type { Metric } from 'types.graphql.generated';
import Space from 'shared/components/Space';
import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import InfoCard from 'shared/components/InfoCard';
import PreviousInsightsSidecard from 'status/PreviousInsightsSidecard/PreviousInsightsSidecard';

type MetricStatusWizardStatusProps = Pick<
  StatusFormProps,
  'id' | 'initialValues' | 'onSubmit' | 'onChange' | 'onError'
> & {
  completeStatus?: boolean;
  forecastValue: number | null;
  metric: Pick<Metric, 'name' | 'description' | 'unitOfMeasure'>;
  statusValue: number | null;
};

const MetricStatusWizardStatus = ({
  initialValues,
  onSubmit,
  onError,
  metric,
  id,
  onChange,
  statusValue,
  forecastValue,
  completeStatus,
}: MetricStatusWizardStatusProps) => {
  const { t } = useTranslation();

  const renderMetric = () => (
    <Space direction={'vertical'}>
      <Text>
        <Trans
          i18nKey={'metric.metricStatusWizard.steps.status.metricName'}
          values={{
            name: metric.name,
          }}
        >
          <Text variant={'emphasis'} />
        </Trans>
      </Text>
      {!!metric.description && (
        <Text>
          <Trans
            i18nKey={'metric.metricStatusWizard.steps.status.metricDescription'}
            values={{
              description: metric.description,
            }}
          >
            <Text variant={'emphasis'} />
          </Trans>
        </Text>
      )}
      <Space direction={'horizontal'}>
        <Space size={'small'}>
          <Text variant={'emphasis'}>{t('metric.currentScore')}:</Text>
          <Text variant={'strong'}>
            {yup.number().isValidSync(statusValue) ? statusValue : t('noData')}
          </Text>
        </Space>
        <Space size={'small'}>
          <Text variant={'emphasis'}>{t('metric.forecast')}:</Text>
          <Text variant={'strong'}>
            {yup.number().isValidSync(forecastValue)
              ? forecastValue
              : t('noData')}
          </Text>
        </Space>
        <Space size={'small'}>
          <Text variant={'emphasis'}>{t('metric.unitOfMeasure')}:</Text>
          <Text variant={'strong'}>
            {metric.unitOfMeasure && metric.unitOfMeasure !== ''
              ? metric.unitOfMeasure
              : t('noData')}
          </Text>
        </Space>
      </Space>
    </Space>
  );

  const renderForm = () => (
    <StatusForm
      heading={t('metric.metricStatusWizard.steps.status.heading')}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onChange={onChange}
      validateOnBlur={true}
      onError={onError}
      id={id}
      completeStatus={completeStatus}
      completeLabel={t('metric.metricStatusWizard.steps.status.completeLabel')}
    >
      {renderMetric()}
    </StatusForm>
  );

  const renderInfoCard = () => (
    <InfoCard
      title={t('metric.metricStatusWizard.steps.status.infoCard.heading')}
      contentKey={'metric.metricStatusWizard.steps.status.infoCard.content'}
    />
  );

  return (
    <FormWithInfoCardLayout
      renderForm={renderForm}
      renderInfoCard={renderInfoCard}
      renderAfterInfoCard={<PreviousInsightsSidecard />}
    />
  );
};

export default MetricStatusWizardStatus;
