import { createContext } from 'react';

import type { StrategyInsightsReportsReportFragment } from './StrategyInsightsReportsProvider.graphql.generated';

export type StrategyInsightsReportsProviderContextValue = {
  areReportsLoading: boolean;
  refetchReports: () => Promise<unknown>;
  reports: StrategyInsightsReportsReportFragment[];
};

export const StrategyInsightsReportsProviderContext = createContext<
  StrategyInsightsReportsProviderContextValue | undefined
>(undefined);
