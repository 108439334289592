import { css } from '@emotion/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import Flex from 'shared/components/Flex';
import Tabs from 'shared/components/Tabs';

import { usePreviousInsightsData } from './usePreviousInsightsData';
import Section from './Section';

type InsightsSection = 'previous' | 'related' | 'children';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow-y: auto;
`;

const SideModalContent = () => {
  const { t } = useTranslation();

  const [section, setSection] = useState<InsightsSection>('previous');

  const {
    previousInsights,
    relatedInsights,
    relatedInsightsOlder,
    childrenInsights,
    childrenInsightsOlder,
  } = usePreviousInsightsData();

  return (
    <Flex direction={'column'} css={css({ height: '100%' })}>
      <Tabs
        tabIds={['previous', 'related', 'children']}
        activeTabId={section}
        css={css({ padding: '0 1.5rem', margin: 0 })}
      >
        <Tabs.Item
          onClick={() => setSection('previous')}
          isActive={section === 'previous'}
        >
          {t(`sidesheets.nav.previousInsights.heading`)}
        </Tabs.Item>
        <Tabs.Item
          onClick={() => setSection('related')}
          isActive={section === 'related'}
        >
          {t(`sidesheets.nav.relatedInsights.heading`)}
        </Tabs.Item>
        <Tabs.Item
          onClick={() => setSection('children')}
          isActive={section === 'children'}
        >
          {t(`sidesheets.nav.childrenInsights.heading`)}
        </Tabs.Item>
      </Tabs>

      <ContentContainer>
        <Content>
          {section === 'previous' && (
            <Section elements={previousInsights} showOrgUnit={false} />
          )}

          {section === 'related' && (
            <Section
              elements={relatedInsights}
              olderElements={relatedInsightsOlder}
              showOrgUnit={false}
            />
          )}

          {section === 'children' && (
            <Section
              elements={childrenInsights}
              olderElements={childrenInsightsOlder}
              showOrgUnit={true}
            />
          )}

          <div>&nbsp;</div>
        </Content>
      </ContentContainer>
    </Flex>
  );
};

export default SideModalContent;
