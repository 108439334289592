import type { SliderProps as RCSliderProps } from 'rc-slider';
import RCSlider from 'rc-slider';
import cn from 'classnames';

import 'rc-slider/assets/index.css';
import styles from './Slider2.module.scss';

type Size = 'small' | 'full';

type SliderProps = Pick<
  RCSliderProps,
  | 'value'
  | 'min'
  | 'max'
  | 'step'
  | 'marks'
  | 'disabled'
  | 'onChange'
  | 'className'
> & {
  size?: Size;
};

const Slider2 = ({ size = 'full', className, ...restProps }: SliderProps) => (
  <div
    className={cn(
      styles.slider,
      {
        [styles.sliderFull]: size === 'full',
        [styles.sliderSmall]: size === 'small',
      },
      className,
    )}
  >
    <RCSlider {...restProps} />
  </div>
);

export default Slider2;
