import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import type { StatusIndicator as StatusIndicatorEnum } from 'types.graphql.generated';

import { StatusIndicator } from './StatusIndicator';
import { isStatusIndicatorEnum } from './StatusIndicator.utils';

export const readStatusIndicatorField = (
  value: StatusIndicator,
  { readField }: { readField: ReadFieldFunction },
  isCompleted?: boolean,
) => {
  if (value instanceof StatusIndicator) {
    return value;
  }

  if (isStatusIndicatorEnum(value)) {
    return new StatusIndicator(value as StatusIndicatorEnum, {
      isCompleted: isCompleted || readField('complete'),
    });
  }

  return new StatusIndicator(undefined, {
    isCompleted: isCompleted || readField('complete'),
  });
};
