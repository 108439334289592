import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
export type TimeLineFragment = { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined, completeDateTime?: any | undefined };

export const TimeLineFragmentDoc = gql`
    fragment timeLine on TimeLine {
  startDate
  endDate
  completeDateTime
}
    `;