import { createContext } from 'react';

export type UserValidationContextValue = {
  isValidated: boolean;
  onInvalidated: () => void;
  onValidated: () => void;
};

export const UserValidationContext = createContext<
  UserValidationContextValue | undefined
>(undefined);
