import { useEffect } from 'react';

const useSupressResizeObservableErrors = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      return;
    }

    const handler = (e: ErrorEvent) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.remove();
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.remove();
        }
      }
    };

    window.addEventListener('error', handler);

    return () => window.removeEventListener('error', handler);
  }, []);
};

export default useSupressResizeObservableErrors;
