import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';

export const getPopoverStyle = (theme: Theme) => {
  return css`
    background-color: ${theme.color.white};
    border-radius: 0.5rem;
    border: 1px solid ${theme.color.strokeMedium};
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    overflow-y: auto;

    &[data-placement='bottom'] {
      margin-top: -0.2rem;
    }

    &[data-placement='top'] {
      margin-bottom: -0.3rem;
    }

    [role='option'] {
      padding: 0.625rem 1rem;

      &[data-selected='true'] {
        color: ${theme.color.white};
        background-color: ${theme.color.primary};
      }

      &[data-focused='true'] {
        background-color: ${theme.color.primaryVariant};
        color: ${theme.color.black};
      }

      &:hover:not([data-selected='true']) {
        background-color: ${theme.color.backgroundLight};
      }
    }
  `;
};
