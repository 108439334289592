import { useEffect } from 'react';

import type { LayoutBackground } from './Layout.type';
import { useLayout } from './LayoutProvider';

export const useLayoutBackground = ({
  image,
  color,
  className,
  skip,
}: LayoutBackground) => {
  const { setBackground, clearBackground } = useLayout();

  useEffect(() => {
    if (skip) {
      clearBackground();
      return;
    }

    setBackground({ image, color, className });

    return () => {
      clearBackground();
    };
  }, [setBackground, clearBackground, image, color, className, skip]);
};
