import type { ActionsObjectiveFragment } from 'actions/actions.fragment.graphql.generated';

import type { ActionsLayoutUser } from '../ActionsLayout.type';

export const getActionsOwners = (
  objectives: ActionsObjectiveFragment[],
): ActionsLayoutUser[] =>
  Object.values(
    objectives.reduce<Record<ActionsLayoutUser['id'], ActionsLayoutUser>>(
      (accumulator, objective) => {
        objective.actions.forEach((action) => {
          const user = action.owner;
          if (user && !accumulator[user.id]) {
            accumulator[user.id] = user;
          }
        });
        return accumulator;
      },
      {},
    ),
  );
