import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import Heading from 'shared/components/Heading';
import Text from 'shared/components/Text';
import type {
  StatusCommentsFormProps,
  StatusCommentsFormValues,
} from 'shared/status/StatusCommentsForm';
import StatusCommentsForm from 'shared/status/StatusCommentsForm';
import type { Report } from 'types.graphql.generated';
import AiSuggestions from 'ai/AiSuggestions';
import SuggestionsProvider from 'ai/AiSuggestions/SuggestionsProvider';
import { createInitialValues } from 'shared/status/StatusCommentsForm/StatusCommentsForm.utils';
import Form from 'shared/form/Form';
import Emoji from 'shared/components/Emoji';

import InsightReportWizardInfoCard from '../InsightReportWizardInfoCard';
import type {
  InsightsReportActionSuggestionsSubscription,
  InsightsReportActionSuggestionsSubscriptionVariables,
} from './InsightsReportActionSuggestions.graphql.generated';
import { InsightsReportActionSuggestionsDocument } from './InsightsReportActionSuggestions.graphql.generated';
import AllCommentsSideCard from '../AllCommentsSideCard';

export type InsightReportWizardActionsValues =
  StatusCommentsFormValues<'ACTION_COMMENT'>;

export type InsightReportWizardActionsProps = Pick<
  StatusCommentsFormProps<'ACTION_COMMENT'>,
  'initialValues' | 'onChange' | 'onSubmit' | 'id'
> & { reportId?: Report['id'] };

const InsightReportWizardActions = (props: InsightReportWizardActionsProps) => {
  const { t } = useTranslation();

  const initialValues = useMemo(
    () => props.initialValues || createInitialValues(['ACTION_COMMENT']),
    [props.initialValues],
  );

  const renderForm = () => (
    <>
      <Heading level={3}>
        {t('report.insightReportWizard.actions.heading')}
      </Heading>
      <Text>{t('report.insightReportWizard.actions.description')}</Text>
      <StatusCommentsForm
        type={'ACTION_COMMENT'}
        suggestionField={'action comment'}
        hasTypeHeadings={false}
        enableReinitialize={true}
        {...props}
      />
    </>
  );

  return (
    <SuggestionsProvider<
      InsightsReportActionSuggestionsSubscription,
      InsightsReportActionSuggestionsSubscriptionVariables
    >
      subscriptionDocument={InsightsReportActionSuggestionsDocument}
      hasVariables={() => props.reportId !== undefined}
      elementIds={{ reportIds: props.reportId ? [props.reportId] : [] }}
    >
      <Form {...props} initialValues={initialValues}>
        <FormWithInfoCardLayout
          renderForm={renderForm}
          renderAfterInfoCard={
            <>
              <InsightReportWizardInfoCard wizardStep={'actions'} />

              <AllCommentsSideCard
                reportId={props.reportId}
                commentType={'ACTION_COMMENT'}
                icon={<Emoji icon={'🛠️'} />}
                title={t('report.insightReportWizard.actions.viewAll.title')}
                showButtonLabel={t(
                  'report.insightReportWizard.actions.viewAll.button.show',
                )}
                hideButtonLabel={t(
                  'report.insightReportWizard.actions.viewAll.button.hide',
                )}
                emptyMessage={t(
                  'report.insightReportWizard.actions.viewAll.empty',
                )}
              />

              <AiSuggestions
                title={t('report.insightReportWizard.actions.askSophie.title')}
                buttonLabel={t(
                  'report.insightReportWizard.actions.askSophie.button.label',
                )}
                buttonLabelAfterFirstTrigger={t(
                  'report.insightReportWizard.actions.askSophie.button.triggeredLabel',
                )}
                titleFieldName={'action comment'}
                suggestionAction={'add'}
              />
            </>
          }
        />
      </Form>
    </SuggestionsProvider>
  );
};

export default InsightReportWizardActions;
