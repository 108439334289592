import { date } from 'shared/services/date.service';
import { getItemValues } from 'shared/utils/object.utils';

type GroupByTimeUnitOptions<TimeUnitGetterFunction = typeof date.getYear> = {
  key: string;
  timeUnitGetter?: TimeUnitGetterFunction;
};

/**
 * Groups items by time period
 * @param {Array} items - items to group by time period
 * @param {string} options.key - the potentially nested keypath or property callback by which to group items
 * @param {Function=} options.timeUnitGetter - the function to retrieve the time unit from the passed items; defaults to date.getYear
 * @return {Array} - an array of arrays, each having time period as first element and array of items grouped by that time period as second element, e.g `[[2022, [...itemsFrom2022]], [2021, [...itemsFrom2021]]]`
 * @example groupByTimeUnit(objective.objectiveStatusListAll, {key: 'auditRecord.updateDateTime', timeUnitGetter: date.getMonth})
 */
export const groupByTimeUnit = <Item extends Record<string, unknown>>(
  items: Item[],
  options: GroupByTimeUnitOptions,
): Array<[number, Item[]]> => {
  const { key, timeUnitGetter = date.getYear } = options;

  const reduceItemsToGroups = (
    accumulatedItems: { [key: number]: Item[] },
    currentItem: Item,
  ): { [key: number]: Item[] } => {
    const nestedValue = getItemValues(currentItem, key);

    const timeUnit = timeUnitGetter(nestedValue);

    return timeUnit in accumulatedItems
      ? {
          ...accumulatedItems,
          [timeUnit]: [...accumulatedItems[timeUnit], currentItem],
        }
      : { ...accumulatedItems, [timeUnit]: [currentItem] };
  };

  const itemsByTimeUnit = items.reduce(reduceItemsToGroups, {});

  const itemsSortedDesc: Array<[number, Item[]]> = Object.entries(
    itemsByTimeUnit,
  ).map((item) => [Number(item[0]), sortByDateDesc(item[1], key)]);

  return itemsSortedDesc;
};

export const sortByYearDesc = (
  itemsGroupedByTimeUnit: ReturnType<typeof groupByTimeUnit>,
) =>
  itemsGroupedByTimeUnit.sort((itemA, itemB) =>
    date.isAfter(itemA[0], itemB[0]) ? -1 : 1,
  );

export const sortByDateDesc = <Item extends Record<string, unknown>>(
  items: Item[],
  key: string,
) =>
  items.sort((itemA, itemB) => {
    const itemADate = getItemValues(itemA, key) as Date | number;
    const itemBDate = getItemValues(itemB, key) as Date | number;
    return date.isAfter(itemADate, itemBDate) ? -1 : 1;
  });
