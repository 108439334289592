import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import compact from 'lodash/compact';

import { useUserMultiSelect } from 'user/UserMultiSelect';
import { useActionStatusMultiSelect } from 'actions/ActionStatusMultiSelect';
import { usePriorityMultiSelect } from 'shared/priority/PriorityMultiSelect';

import { useActionsLayout } from '../ActionsLayoutProvider';
import type { ActionsLayoutFiltersChipGroupItem } from './ActionsLayoutFiltersChipGroup.type';

export function useChipGroupItems() {
  const { startDateFilter, endDateFilter } = useActionsLayout();

  const { state: userMultiSelectState, items: userMultiSelectItems } =
    useUserMultiSelect();
  const {
    state: actionStatusMultiSelectState,
    items: actionStatusMultiSelectItems,
  } = useActionStatusMultiSelect();

  const { state: priorityMultiSelectState, items: priorityMultiSelectItems } =
    usePriorityMultiSelect();

  const statusItems = useMemo<ActionsLayoutFiltersChipGroupItem[]>(
    () =>
      actionStatusMultiSelectItems
        .filter(
          (item) =>
            item.id !== 'ALL' &&
            actionStatusMultiSelectState.selectionManager.isSelected(item.id),
        )
        .map((item) => ({
          id: uuid(),
          status: item,
        })),
    [actionStatusMultiSelectItems, actionStatusMultiSelectState],
  );

  const priorityItems = useMemo<ActionsLayoutFiltersChipGroupItem[]>(
    () =>
      priorityMultiSelectItems
        .filter(
          (item) =>
            item.id !== 'ALL' &&
            priorityMultiSelectState.selectionManager.isSelected(item.id),
        )
        .map((item) => ({
          id: uuid(),
          priority: item,
        })),
    [priorityMultiSelectItems, priorityMultiSelectState.selectionManager],
  );

  const userItems = useMemo<ActionsLayoutFiltersChipGroupItem[]>(
    () =>
      userMultiSelectItems
        .filter((item) =>
          userMultiSelectState.selectionManager.isSelected(item.id),
        )
        .map((item) => ({
          id: uuid(),
          user: item,
        })),
    [userMultiSelectState, userMultiSelectItems],
  );

  const chipGroupItems = useMemo<ActionsLayoutFiltersChipGroupItem[]>(
    () =>
      compact([
        ...statusItems,
        ...priorityItems,
        ...userItems,
        startDateFilter
          ? {
              id: uuid(),
              startDate: startDateFilter,
            }
          : undefined,
        endDateFilter
          ? {
              id: uuid(),
              endDate: endDateFilter,
            }
          : undefined,
      ]),
    [userItems, statusItems, priorityItems, startDateFilter, endDateFilter],
  );

  return chipGroupItems;
}
