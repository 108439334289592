import { Radio } from 'react-aria-components';

import StatusMark from 'shared/status/StatusMark';
import type { OrgUnit, Theme } from 'types.graphql.generated';
import Text from 'shared/components/Text';
import Flex from 'shared/components/Flex';
import { useActiveOrg } from 'org/ActiveOrgProvider';

import type { CreateTaskObjective } from '../OneThingCreateTaskButton.types';

type Props = {
  objectives: CreateTaskObjective[];
  orgUnit?: Pick<OrgUnit, 'id' | 'name'>;
  theme?: Pick<Theme, 'id' | 'name'>;
};

const ObjectivesSelectionSection = ({ objectives, orgUnit, theme }: Props) => {
  const { activeOrg } = useActiveOrg();

  const filteredObjectives = objectives.filter(
    (objective) =>
      objective.orgUnit?.id === orgUnit?.id &&
      objective.theme?.id === theme?.id,
  );

  if (filteredObjectives.length === 0) return null;

  const orgUnitName = orgUnit ? orgUnit.name : activeOrg.displayName;
  const themeName = theme ? theme.name : 'no theme';

  return (
    <Flex direction={'column'} gap={'0.5rem'}>
      <Text isUppercase={true} size={'tiny'} isBold={true}>
        {orgUnitName}
        {' / '}
        {themeName}
      </Text>

      {filteredObjectives.map((objective) => (
        <Radio key={objective.id} value={objective.id}>
          <StatusMark
            statusIndicator={objective.currentObjectiveStatus?.statusIndicator}
          />
          {objective.name}
        </Radio>
      ))}
    </Flex>
  );
};

export default ObjectivesSelectionSection;
