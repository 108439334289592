import { useCallback, useMemo } from 'react';
import { useTheme } from '@emotion/react';

import type { OrgUnitFormProps } from 'orgUnit/OrgUnitForm';
import OrgUnitForm from 'orgUnit/OrgUnitForm';
import type { OrgUnit } from 'types.graphql.generated';
import Spinner from 'shared/spinner/Spinner';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { TeamTeamHierarchyDocument } from 'team/TeamTeamHierarchy/TeamTeamHierarchyProvider';
import { MyTeamsFromCurrentUserDocument } from 'team/MyTeams/MyTeamsProvider/MyTeamsProvider.graphql.generated';
import { useActiveOrg } from 'org/ActiveOrgProvider';

import type { EditOrgUnitMutation } from './EditOrgUnitForm.graphql.generated';
import {
  useEditOrgUnitInitialValuesQuery,
  useEditOrgUnitMutation,
} from './EditOrgUnitForm.graphql.generated';
import {
  resolveEditOrgUnitInput,
  resolveInitialValues,
} from './EditOrgUnitForm.utils';

export type EditOrgUnitFormProps = Omit<OrgUnitFormProps, 'onSubmit'> & {
  onSuccess: (result: EditOrgUnitMutation['updateOrgUnit']) => void;
  orgUnitId: OrgUnit['id'];
};

const EditOrgUnitForm = ({
  orgUnitId,
  onSuccess,
  id,
  ...restProps
}: EditOrgUnitFormProps) => {
  const { activeOrg } = useActiveOrg();
  const theme = useTheme();

  const onError = useHandleApolloError();

  const { data, loading } = useEditOrgUnitInitialValuesQuery({
    variables: { id: orgUnitId },
    onError,
  });

  const [editOrgUnit] = useEditOrgUnitMutation({
    onError,
    refetchQueries: [TeamTeamHierarchyDocument, MyTeamsFromCurrentUserDocument],
  });

  const initialValues = useMemo(
    () =>
      data ? resolveInitialValues(activeOrg, data.orgUnit, theme) : undefined,
    [activeOrg, data, theme],
  );

  const handleSubmit = useCallback<NonNullable<OrgUnitFormProps['onSubmit']>>(
    (values) => {
      if (initialValues && data) {
        return editOrgUnit({
          variables: {
            input: resolveEditOrgUnitInput({
              values,
              orgUnit: data.orgUnit,
              initialValues,
              allUsers: data.allUsers.edges.map((edge) => edge.node),
            }),
          },
          onCompleted: (result) => onSuccess(result.updateOrgUnit),
        });
      }
    },
    [data, editOrgUnit, initialValues, onSuccess],
  );

  if (initialValues) {
    return (
      <OrgUnitForm
        {...restProps}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        id={id}
        orgUnitId={orgUnitId}
      />
    );
  }

  if (loading) {
    return <Spinner.Circle containerSize={'medium'} />;
  }

  return null;
};

export default EditOrgUnitForm;
