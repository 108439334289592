import type { PropsWithChildren } from 'react';
import styled from '@emotion/styled';

import Emoji from 'shared/components/Emoji';
import InfoIcon from 'shared/components/InfoIcon';

const Container = styled.div`
  position: relative;
  align-items: center;
  background-color: ${(props) => props.theme.color.backgroundLight};
  border-radius: 20px;
  display: flex;
  padding: 17px 20px;
`;

const Icon = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.color.white};
  border-radius: 40px;
  display: flex;
  flex-shrink: 0;
  font-size: 30px;
  height: 60px;
  justify-content: center;
  margin-right: 12px;
  width: 60px;
`;

const Content = styled.div`
  flex-grow: 1;
`;

const Info = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

type ContributionInfoContainerProps = PropsWithChildren<{
  icon: string;
  infoMessage?: string;
}>;

const ContributionInfoContainer = ({
  icon,
  infoMessage,
  children,
}: ContributionInfoContainerProps) => (
  <Container>
    <Icon>
      <Emoji icon={icon} />
    </Icon>
    <Content>{children}</Content>
    {infoMessage && (
      <Info>
        <InfoIcon content={infoMessage} />
      </Info>
    )}
  </Container>
);

export default ContributionInfoContainer;
