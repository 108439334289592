import type { ComponentType } from 'react';

export const lazyRetry = <T extends ComponentType<any>>(
  componentImport: () => Promise<{ default: T }>,
  name?: string,
): Promise<{ default: T }> =>
  new Promise((resolve, reject) => {
    const sessionStorageKey = name
      ? `retry-lazy-refreshed-${name}`
      : 'retry-lazy-refreshed';
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(sessionStorageKey) || 'false',
    );

    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(sessionStorageKey, 'false');
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem(sessionStorageKey, 'true');
          return window.location.reload();
        }
        reject(error);
      });
  });
