import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { PriorityMultiSelectItemId } from 'shared/priority/PriorityMultiSelect';
import { isPriorityMultiSelectId } from 'shared/priority/PriorityMultiSelect';
import type { PriorityFragment } from 'strategy/useStrategyProfilePriorities/StrategyProfileFilters.graphql.generated';

import { isNumber } from '../PriorityMultiSelect/PriorityMultiSelect.utils';

type PriorityFilterParams = {
  priorities?: PriorityFragment[];
};

export type PriorityFilterValue = PriorityMultiSelectItemId[];

type PriorityFilterReturnType = {
  priorityFilter: PriorityFilterValue;
  setPriorityFilter: (value: PriorityMultiSelectItemId[]) => void;
};

export const usePriorityFilter = ({
  priorities,
}: PriorityFilterParams = {}): PriorityFilterReturnType => {
  const paramName = 'priority';

  const [searchParams, setSearchParams] = useSearchParams();

  const defaultValue = useMemo<PriorityMultiSelectItemId[]>(() => ['ALL'], []);

  const priorityFilter = useMemo<PriorityFilterValue>(() => {
    const params = searchParams.getAll(paramName);
    if (params.length) {
      return params
        .map((param: string) => {
          if (param === 'ALL' || param === 'UNKNOWN') {
            return param;
          } else if (
            isNumber(param) &&
            isPriorityMultiSelectId(param, priorities)
          ) {
            return Number(param);
          }
        })
        .filter(Boolean);
    }
    return defaultValue;
  }, [searchParams, defaultValue, priorities]);

  const setPriorityFilter = useCallback<
    PriorityFilterReturnType['setPriorityFilter']
  >(
    (value) => {
      setSearchParams((searchParams) => {
        searchParams.delete(paramName);
        value.forEach((id) => {
          searchParams.append(paramName, id.toString());
        });
        return searchParams;
      });
    },
    [setSearchParams, paramName],
  );

  return {
    priorityFilter,
    setPriorityFilter,
  };
};
