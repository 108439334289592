import defaults from 'lodash/defaults';
import type { Entries } from 'type-fest';

import type { CommentsFormValues, CommentsFormProps } from './CommentsForm';

export const createInitialValues = (
  data: CommentsFormProps['initialValues'],
): CommentsFormValues =>
  defaults(data, {
    actions: [''],
    challenges: [''],
    successes: [''],
  });

export const resolveSubmitValues = (
  values: CommentsFormValues,
): CommentsFormValues =>
  Object.fromEntries(
    (Object.entries(values) as Entries<typeof values>).map(
      ([key, comments]) => {
        const notEmptyComments = comments.filter((comment) => !!comment);
        return [key, notEmptyComments];
      },
    ),
  ) as CommentsFormValues;
