import { createContext } from 'react';

import type {
  InitiativesContentInitiative,
  InitiativesContentObjective,
} from './InitiativesContentProvider.type';

export type InitiativesContentContextValue = {
  allInitiatives: Maybe<InitiativesContentInitiative[]>;
  allInitiativesActive: Maybe<InitiativesContentInitiative[]>;
  allObjectives: Maybe<InitiativesContentObjective[]>;
  allObjectivesActiveWithInitiativesActive: Maybe<
    InitiativesContentObjective[]
  >;
  filteredInitiativesActive: Maybe<InitiativesContentInitiative[]>;
  isInitiativesListObjectivesLoading: boolean;
};

export const InitiativesContentContext = createContext<
  InitiativesContentContextValue | undefined
>(undefined);
