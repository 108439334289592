import React from 'react';
import cx from 'classnames';
import { LayoutGroup, motion } from 'framer-motion';

import style from './PillBar.module.scss';

type PillBarOptionProps = {
  fieldId: string;
  isActive: boolean;
  label: React.ReactNode;
};

const PillBarOption = ({ label, fieldId, isActive }: PillBarOptionProps) => (
  <>
    <div className={style.label}>{label}</div>
    {isActive && (
      <motion.div className={style.pill} layoutId={`pillbar-pill-${fieldId}`} />
    )}
  </>
);

type PillBarOptionObject = {
  label: React.ReactNode;
  value: string;
};

type PillBarProps = {
  fieldId: string;
  onChange: (value: string) => void;
  options: PillBarOptionObject[];
  value: string;
};

const PillBar = ({ options, value, onChange, fieldId }: PillBarProps) => (
  <div className={style.pillBar}>
    <LayoutGroup id={`pillbar-pill-${fieldId}`}>
      {options.map((o) => (
        <button
          key={o.value}
          className={cx([style.option, o.value === value && style.active])}
          onClick={() => onChange(o.value)}
        >
          <PillBarOption
            label={o.label}
            isActive={o.value === value}
            fieldId={fieldId}
          />
        </button>
      ))}
    </LayoutGroup>
  </div>
);

export default PillBar;
