import cn from 'classnames';
import type {
  CSSProperties,
  PropsWithChildren,
  ComponentType,
  SVGProps,
} from 'react';
import isDarkColor from 'is-dark-color';
import { useMemo } from 'react';

import type { HexColor } from 'shared/types/color.type';

import styles from './Tag.module.scss';
import type { TagVariant } from './Tag.type';

export type TagSize = 'regular' | 'small' | 'tiny';

type TagProps = PropsWithChildren<{
  className?: string;
  color?: HexColor;
  icon?: ComponentType<SVGProps<SVGSVGElement>>;
  size?: TagSize;
  variant?: TagVariant;
}>;

/**
 * Component used for items that need to be categorized using keywords.
 */
const Tag = ({
  variant = 'secondary',
  icon: Icon,
  children,
  color,
  className,
  size = 'regular',
}: TagProps) => {
  const cssProps = useMemo<CSSProperties>(() => {
    try {
      if (color) {
        const isDark = isDarkColor(color);
        return {
          backgroundColor: color,
          borderColor: color,
          color: isDark
            ? styles.colorForDarkBackground
            : styles.colorForLightBackground,
        };
      }
      return {};
    } catch {
      return {};
    }
  }, [color]);

  return (
    <span
      className={cn(
        styles.tag,
        {
          [styles.tagSizeTiny]: size === 'tiny',
          [styles.tagSizeSmall]: size === 'small',
          [styles.tagVariantPrimary]: variant === 'primary',
          [styles.tagVariantSecondary]: variant === 'secondary',
          [styles.tagVariantGhost]: variant === 'ghost',
          [styles.tagVariantSuccess]: variant === 'success',
          [styles.tagVariantAlert]: variant === 'alert',
          [styles.tagVariantDanger]: variant === 'danger',
        },
        className,
      )}
      style={cssProps}
    >
      <span className={styles.tagContent}>
        {Icon ? <Icon className={styles.tagIcon} /> : null}
        {children}
      </span>
    </span>
  );
};

export default Tag;
