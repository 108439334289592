import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import type { SetOptional } from 'type-fest';

import { yup } from 'shared/services/yup.service';
import Form from 'shared/form/Form';
import FieldBox from 'shared/form/FieldBox';
import { TextInputField } from 'shared/components/TextInput';
import TimelineField from 'shared/components/TimelineField';
import FormWithInfoCardLayout from 'shared/components/FormWithInfoCardLayout';
import Heading from 'shared/components/Heading';
import Space from 'shared/components/Space';
import type { FormProps } from 'shared/form/Form/Form';
import InfoCard from 'shared/components/InfoCard';

export type StrategyWizardNameValues = {
  name: string;
  timeline: {
    endDate: Date | null;
    startDate: Date | null;
  };
};

const createInitialValues = (): StrategyWizardNameValues => ({
  name: '',
  timeline: {
    startDate: null,
    endDate: null,
  },
});

type StrategyWizardNameProps = SetOptional<
  Pick<
    FormProps<StrategyWizardNameValues>,
    'id' | 'className' | 'initialValues' | 'onChange'
  >,
  'initialValues'
>;

const StrategyWizardName = ({
  onChange,
  initialValues = createInitialValues(),
  className,
  id,
}: StrategyWizardNameProps) => {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    name: yup.string().required(),
  });

  const renderForm = () => (
    <>
      <Heading level={4} as={5}>
        {t('strategy.strategyWizard.steps.name.form.heading')}
      </Heading>
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onChange={onChange}
        id={id}
        className={className}
      >
        {({ hasError }) => (
          <Space direction={'vertical'}>
            <FieldBox name={'name'} hasError={hasError('name')}>
              <Field
                name={'name'}
                component={TextInputField}
                maxLength={100}
                hasError={hasError('name')}
              />
            </FieldBox>
            <TimelineField name={'timeline'} />
          </Space>
        )}
      </Form>
    </>
  );

  const renderInfoCard = () => (
    <InfoCard
      title={t('strategy.strategyWizard.steps.name.infoCard.heading')}
      contentKey={'strategy.strategyWizard.steps.name.infoCard.content'}
    />
  );

  return (
    <FormWithInfoCardLayout
      renderInfoCard={renderInfoCard}
      renderForm={renderForm}
    />
  );
};
export default StrategyWizardName;
