import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ThemeFormQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ThemeFormQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, currentStrategyProfile?: { __typename: 'StrategyProfile', id: string, themeCategories: Array<{ __typename: 'ThemeCategory', id: string, name?: string | undefined }> } | undefined } };


export const ThemeFormDocument = gql`
    query ThemeForm {
  activeOrg {
    id
    currentStrategyProfile {
      id
      themeCategories {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useThemeFormQuery__
 *
 * To run a query within a React component, call `useThemeFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemeFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemeFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useThemeFormQuery(baseOptions?: Apollo.QueryHookOptions<ThemeFormQuery, ThemeFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThemeFormQuery, ThemeFormQueryVariables>(ThemeFormDocument, options);
      }
export function useThemeFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThemeFormQuery, ThemeFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThemeFormQuery, ThemeFormQueryVariables>(ThemeFormDocument, options);
        }
export type ThemeFormQueryHookResult = ReturnType<typeof useThemeFormQuery>;
export type ThemeFormLazyQueryHookResult = ReturnType<typeof useThemeFormLazyQuery>;
export type ThemeFormQueryResult = Apollo.QueryResult<ThemeFormQuery, ThemeFormQueryVariables>;