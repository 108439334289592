import type { TimeLine } from 'types.graphql.generated';

import type { TimelineFieldValue } from './TimelineField';

export const resolveInitialValue = (
  timeline: TimeLine | null | undefined,
  options: {
    endDate?: Date;
    startDate?: Date;
  } = {
    endDate: undefined,
    startDate: undefined,
  },
): TimelineFieldValue => {
  const { startDate = null, endDate = null } = options;
  if (timeline) {
    return {
      startDate: timeline.startDate || startDate,
      endDate: timeline.endDate || endDate,
    };
  }
  return {
    startDate,
    endDate,
  };
};
