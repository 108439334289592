import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditMetricQueryVariables = Types.Exact<{
  metricId: Types.Scalars['ID'];
}>;


export type EditMetricQuery = { __typename: 'Query', metric: { __typename: 'Metric', id: string, name?: string | undefined, description?: string | undefined, startValue?: number | undefined, targetValue?: number | undefined, unitOfMeasure?: string | undefined, isCurrentUserOwner: boolean, owner?: { __typename: 'UserLimited', id: string, email: string } | undefined, timeLine: { __typename: 'TimeLine', endDate?: any | undefined, startDate?: any | undefined }, objective?: { __typename: 'Objective', id: string, name?: string | undefined } | undefined, priority?: { __typename: 'Priority', number: number, label: string } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, attributes: Array<{ __typename: 'StrategyItemAttribute', id: string, name?: string | undefined, title?: string | undefined, description?: string | undefined, orderNumber?: number | undefined }> } };

export type UpdateMetricMutationVariables = Types.Exact<{
  input: Types.MetricInput;
}>;


export type UpdateMetricMutation = { __typename: 'Mutation', updateMetric: { __typename: 'Metric', id: string, name?: string | undefined, description?: string | undefined, startValue?: number | undefined, targetValue?: number | undefined, unitOfMeasure?: string | undefined, isCurrentUserOwner: boolean, owner?: { __typename: 'UserLimited', id: string, email: string } | undefined, timeLine: { __typename: 'TimeLine', endDate?: any | undefined, startDate?: any | undefined }, objective?: { __typename: 'Objective', id: string, name?: string | undefined } | undefined, priority?: { __typename: 'Priority', number: number, label: string } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, attributes: Array<{ __typename: 'StrategyItemAttribute', id: string, name?: string | undefined, title?: string | undefined, description?: string | undefined, orderNumber?: number | undefined }> } };

export type MetricForEditFragment = { __typename: 'Metric', id: string, name?: string | undefined, description?: string | undefined, startValue?: number | undefined, targetValue?: number | undefined, unitOfMeasure?: string | undefined, isCurrentUserOwner: boolean, owner?: { __typename: 'UserLimited', id: string, email: string } | undefined, timeLine: { __typename: 'TimeLine', endDate?: any | undefined, startDate?: any | undefined }, objective?: { __typename: 'Objective', id: string, name?: string | undefined } | undefined, priority?: { __typename: 'Priority', number: number, label: string } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined, attributes: Array<{ __typename: 'StrategyItemAttribute', id: string, name?: string | undefined, title?: string | undefined, description?: string | undefined, orderNumber?: number | undefined }> };

export const MetricForEditFragmentDoc = gql`
    fragment metricForEdit on Metric {
  id
  name
  description
  startValue
  targetValue
  unitOfMeasure
  isCurrentUserOwner
  owner {
    id
    email
  }
  timeLine {
    endDate
    startDate
  }
  objective {
    id
    name
  }
  priority {
    number
    label
  }
  orgUnit {
    id
  }
  attributes {
    id
    name
    title
    description
    orderNumber
  }
}
    `;
export const EditMetricDocument = gql`
    query EditMetric($metricId: ID!) {
  metric(id: $metricId) {
    ...metricForEdit
  }
}
    ${MetricForEditFragmentDoc}`;

/**
 * __useEditMetricQuery__
 *
 * To run a query within a React component, call `useEditMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMetricQuery({
 *   variables: {
 *      metricId: // value for 'metricId'
 *   },
 * });
 */
export function useEditMetricQuery(baseOptions: Apollo.QueryHookOptions<EditMetricQuery, EditMetricQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMetricQuery, EditMetricQueryVariables>(EditMetricDocument, options);
      }
export function useEditMetricLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMetricQuery, EditMetricQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMetricQuery, EditMetricQueryVariables>(EditMetricDocument, options);
        }
export type EditMetricQueryHookResult = ReturnType<typeof useEditMetricQuery>;
export type EditMetricLazyQueryHookResult = ReturnType<typeof useEditMetricLazyQuery>;
export type EditMetricQueryResult = Apollo.QueryResult<EditMetricQuery, EditMetricQueryVariables>;
export const UpdateMetricDocument = gql`
    mutation UpdateMetric($input: MetricInput!) {
  updateMetric(input: $input) {
    ...metricForEdit
  }
}
    ${MetricForEditFragmentDoc}`;
export type UpdateMetricMutationFn = Apollo.MutationFunction<UpdateMetricMutation, UpdateMetricMutationVariables>;

/**
 * __useUpdateMetricMutation__
 *
 * To run a mutation, you first call `useUpdateMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMetricMutation, { data, loading, error }] = useUpdateMetricMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMetricMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMetricMutation, UpdateMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMetricMutation, UpdateMetricMutationVariables>(UpdateMetricDocument, options);
      }
export type UpdateMetricMutationHookResult = ReturnType<typeof useUpdateMetricMutation>;
export type UpdateMetricMutationResult = Apollo.MutationResult<UpdateMetricMutation>;
export type UpdateMetricMutationOptions = Apollo.BaseMutationOptions<UpdateMetricMutation, UpdateMetricMutationVariables>;