import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { getYear } from 'date-fns';

import { DatePickerField } from 'shared/components/DatePicker';
import Space from 'shared/components/Space';
import { TextInputField } from 'shared/components/TextInput';
import FieldBox from 'shared/form/FieldBox';
import { useForm } from 'shared/form/Form';
import { useTeamAdapter } from 'team/TeamAdapter';
import {
  UserEmailInputField,
  UserEmailInputProvider,
} from 'user/UserEmailInput';
import {
  SelectOrgUnitInputField,
  SelectOrgUnitInputProvider,
} from 'orgUnit/SelectOrgUnitInput';

import type { CampaignFormValues } from './OneThingCampaignForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateFieldBox = styled(FieldBox)`
  flex-grow: 1;
`;

const CampaignFields = () => {
  const { values, hasError } = useForm<CampaignFormValues>();
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();

  const isCreate = !values.id;
  const isUpdate = !isCreate;

  const namePlaceholder = `${t('eg')} ${t(
    'toolkit.tools.oneThing.name',
  )} ${getYear(new Date())} ${t('quarterQ')}${getQuarter(new Date())}`;

  return (
    <Container>
      <section>
        <FieldBox
          name={'orgUnit'}
          label={t('objective.objectiveForm.orgUnit.label')}
          hasMargin={false}
        >
          <SelectOrgUnitInputProvider
            filterOrgByAction={'WRITE_STRATEGY'}
            filterOrgUnitsByAction={'WRITE'}
          >
            <Field
              name={'orgUnit'}
              component={SelectOrgUnitInputField}
              size={'full'}
              isDisabled={isUpdate}
            />
          </SelectOrgUnitInputProvider>
        </FieldBox>
      </section>
      <section>
        <FieldBox
          name={'name'}
          label={t('toolkit.toolPage.walkOfStrategy.campaignForm.name')}
        >
          <Field
            name={'name'}
            component={TextInputField}
            hasError={hasError('name')}
            maxLength={100}
            placeholder={namePlaceholder}
          />
        </FieldBox>
      </section>
      <section>
        <Space css={css({ flexGrow: 1 })}>
          <DateFieldBox
            name={'startDate'}
            label={t('toolkit.toolPage.walkOfStrategy.campaignForm.startDate')}
            hasMargin={false}
            css={css({ flexBasis: 0 })}
          >
            <Field
              name={'startDate'}
              component={DatePickerField}
              maxDate={values.deadline || values.endDate}
            />
          </DateFieldBox>
          <DateFieldBox
            name={'deadline'}
            label={t('toolkit.toolPage.walkOfStrategy.campaignForm.deadline')}
            hasMargin={false}
            isOptional={true}
            css={css({ flexBasis: 0 })}
          >
            <Field
              name={'deadline'}
              component={DatePickerField}
              minDate={values.startDate}
              maxDate={values.endDate}
            />
          </DateFieldBox>
          <DateFieldBox
            name={'endDate'}
            label={t('toolkit.toolPage.walkOfStrategy.campaignForm.endDate')}
            hasMargin={false}
            isOptional={true}
            css={css({ flexBasis: 0 })}
          >
            <Field
              name={'endDate'}
              component={DatePickerField}
              minDate={values.deadline || values.startDate}
            />
          </DateFieldBox>
        </Space>
      </section>
      <section>
        <UserEmailInputProvider teamAdapter={teamAdapter}>
          <FieldBox
            name={'owner'}
            label={t('toolkit.toolPage.walkOfStrategy.campaignForm.owner')}
          >
            <Field component={UserEmailInputField} name={'owner'} />
          </FieldBox>
        </UserEmailInputProvider>
      </section>
    </Container>
  );
};

export default CampaignFields;

const getQuarter = (date = new Date()) => Math.floor(date.getMonth() / 3 + 1);
