import upperFirst from 'lodash/upperFirst';

import styles from './RoadmapItemBackgroundTileFixed.module.scss';
import type { FixedTileColumn } from './RoadmapItemBackgroundTileFixed.type';

export const getColumnClassName = (column: FixedTileColumn) => {
  const start = column.start;
  const end = column.end;
  const size = upperFirst(column.size);
  return styles[`containerColumnStart${start}End${end}Size${size}`];
};
