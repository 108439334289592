import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import {
  StatusIndicator,
  isStatusIndicatorEnum,
} from 'shared/status/StatusIndicator';
import type { StatusIndicator as StatusIndicatorEnum } from 'types.graphql.generated';

export const readActionStatusIndicatorField = (
  value: StatusIndicator,
  { readField }: { readField: ReadFieldFunction },
  isCompletedProp?: boolean,
) => {
  const isCompleted = isCompletedProp || readField('complete');

  const isActive =
    Number(readField('completionRate')) > 0 &&
    Number(readField('completionRate')) < 100 &&
    !isCompleted;

  if (value instanceof StatusIndicator) {
    return value;
  }

  if (isStatusIndicatorEnum(value)) {
    return new StatusIndicator(value as StatusIndicatorEnum, {
      isCompleted,
    });
  }

  if (isActive) {
    return new StatusIndicator('ON_TRACK');
  }

  return new StatusIndicator(undefined, {
    isCompleted,
  });
};
