import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type PortalProps = PropsWithChildren<object>;

const Portal = ({ children }: PortalProps) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const portalElement = document.querySelector('#portal');

  if (mounted && portalElement) {
    return createPortal(children, portalElement);
  }
  return null;
};

export default Portal;
