import partition from 'lodash/partition';
import type { Entries } from 'type-fest';

import type { SelectOrgUnitInputOrgUnitFragment } from './SelectOrgUnitInputProvider.graphql.generated';

export type OrgUnitsLevel = {
  level: number;
  orgUnits: SelectOrgUnitInputOrgUnitFragment[];
};

const getOrgUnitsLevelsRecursively = (params: {
  currentLevel: number;
  orgUnits: SelectOrgUnitInputOrgUnitFragment[];
  orgUnitsLevels: Record<number, SelectOrgUnitInputOrgUnitFragment[]>;
  parentOrgUnits: SelectOrgUnitInputOrgUnitFragment[];
}): Record<number, SelectOrgUnitInputOrgUnitFragment[]> => {
  const { orgUnits, parentOrgUnits, currentLevel, orgUnitsLevels } = params;
  const currentLevelOrgUnits: SelectOrgUnitInputOrgUnitFragment[] = [];
  const elementsToOmit: SelectOrgUnitInputOrgUnitFragment['id'][] = [];
  orgUnits.forEach((orgUnit) => {
    const isInCurrentLevel = !!parentOrgUnits.find(
      (parentOrgUnit, _) => parentOrgUnit.id === orgUnit.parentOrgUnit?.id,
    );
    if (isInCurrentLevel) {
      elementsToOmit.push(orgUnit.id);
      currentLevelOrgUnits.push(orgUnit);
    }
  });
  const newOrgUnits = orgUnits.filter(
    (orgUnit) => !elementsToOmit.includes(orgUnit.id),
  );
  orgUnitsLevels[currentLevel] = currentLevelOrgUnits;
  if (newOrgUnits.length && parentOrgUnits.length) {
    return getOrgUnitsLevelsRecursively({
      orgUnits: newOrgUnits,
      currentLevel: currentLevel + 1,
      orgUnitsLevels,
      parentOrgUnits: currentLevelOrgUnits,
    });
  }
  return orgUnitsLevels;
};

export const getOrgUnitsLevels = (
  orgUnits: SelectOrgUnitInputOrgUnitFragment[],
): OrgUnitsLevel[] => {
  const [restOrgUnits, topLevelOrgUnits] = partition(
    orgUnits,
    (orgUnit) => !!orgUnit.parentOrgUnit,
  );
  const result = getOrgUnitsLevelsRecursively({
    orgUnits: restOrgUnits,
    parentOrgUnits: topLevelOrgUnits,
    orgUnitsLevels: {
      1: topLevelOrgUnits,
    },
    currentLevel: 2,
  });
  return (Object.entries(result) as unknown as Entries<typeof result>).map(
    ([level, orgUnits]) => ({
      level,
      orgUnits,
    }),
  );
};
