import { css, useTheme } from '@emotion/react';
import { Trans, useTranslation } from 'react-i18next';

import Result from 'shared/components/Result';
import { ReactComponent as PaperPlaneIllustration } from 'shared/static/illustrations/illustration-paper-plane.svg';

type SignUpLinkSentResultProps = {
  email: string;
  isNewLinkButtonDisabled: boolean;
  onGoBack: () => void;
  onSendNewLink: () => void;
};

const SignUpLinkSentResult = ({
  email,
  isNewLinkButtonDisabled,
  onSendNewLink,
  onGoBack,
}: SignUpLinkSentResultProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Result
      image={<PaperPlaneIllustration />}
      heading={t('signUp.linkSentResult.heading')}
      isGlobal={true}
      description={
        <Trans
          i18nKey={'signUp.linkSentResult.description'}
          values={{
            email,
          }}
        >
          <strong
            css={css`
              color: ${theme.color.primary};
              font-weight: 700;
            `}
          />
          <br />
        </Trans>
      }
      buttons={[
        {
          variant: 'outlined',
          children: t('signUp.linkSentResult.newLinkButton'),
          onClick: onSendNewLink,
          disabled: isNewLinkButtonDisabled,
          css: css`
            width: 14.375rem;
          `,
        },
        {
          variant: 'outlined',
          children: t('signUp.linkSentResult.goBackButton'),
          onClick: onGoBack,
          css: css`
            width: 14.375rem;
          `,
        },
      ]}
      aboveButtonsText={t('signUp.linkSentResult.aboveButtonsText')}
      css={css`
        inset: 0;
        position: absolute;
      `}
    />
  );
};

export default SignUpLinkSentResult;
