import compact from 'lodash/compact';

import type { StatusComment, TextItem } from 'types.graphql.generated';

// todo remove usages of this function when all TextItems are converted to StatusComments in backend
export const migrateTextItemsToStatusComments = (
  textItems: Partial<
    Record<
      'challengeComments' | 'actionComments' | 'successComments',
      TextItem[]
    >
  >,
): StatusComment[] => [
  ...compact(
    textItems?.successComments?.map((textItem) => ({
      ...textItem,
      type: textItem.type || 'SUCCESS_COMMENT',
      __typename: 'StatusComment' as const,
    })),
  ),
  ...compact(
    textItems?.challengeComments?.map((textItem) => ({
      ...textItem,
      type: textItem.type || 'CHALLENGE_COMMENT',
      __typename: 'StatusComment' as const,
    })),
  ),
  ...compact(
    textItems?.actionComments?.map((textItem) => ({
      ...textItem,
      type: textItem.type || 'ACTION_COMMENT',
      __typename: 'StatusComment' as const,
    })),
  ),
];
