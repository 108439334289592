import { createContext } from 'react';

import type { ActionFragment } from './ActionProvider.graphql.generated';

export type ActionContextValue = {
  action: ActionFragment;
};

export const ActionContext = createContext<ActionContextValue | undefined>(
  undefined,
);
