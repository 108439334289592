import { useTranslation } from 'react-i18next';

import Dialog from 'shared/components/Dialog';

import EditThemeForm from './EditThemeForm';
import ThemeProvider from '../ThemeProvider';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  themeId: string;
};

const editThemeFormId = 'editThemeForm';

const EditThemeModal = ({ themeId, isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <ThemeProvider themeId={themeId}>
      <Dialog
        headerTitle={t('theme.editTheme')}
        isOpen={isOpen}
        onClose={onClose}
        cancelLabel={t('cancel')}
        confirmLabel={t('update')}
        formId={editThemeFormId}
      >
        <EditThemeForm id={editThemeFormId} onSuccess={onClose} />
      </Dialog>
    </ThemeProvider>
  );
};

export default EditThemeModal;
