import { useRef, useEffect } from 'react';
import { max, min } from 'lodash';
import { css } from '@emotion/react';

import type {
  AiSuggestionElementInput,
  AiSuggestionInput,
  AiSuggestionResponseInput,
} from 'types.graphql.generated';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import Flex from 'shared/components/Flex';

import ResultsPageItem from './ResultsPageItem';
import type { Suggestion } from './MultiAiSuggestions';
import { useMultiAiSuggestionsSubscription } from './MultiAiSuggestions.graphql.generated';

type Props = {
  elements: AiSuggestionElementInput[];
  hints?: string[];
  isLoading: boolean;
  onSuggestionSelected: (suggestion: Suggestion) => void;
  response: AiSuggestionResponseInput;
  setIsLoading: (isLoading: boolean) => void;
  temperature: number;
};

const ResultsPage = ({
  elements,
  response,
  temperature,
  onSuggestionSelected,
  setIsLoading,
}: Props) => {
  useEffect(() => {
    setIsLoading(true);
  }, [setIsLoading]);

  const { activeOrg } = useActiveOrg();

  const normalizedTemperature = max([0, min([temperature, 1.5])]);

  const freezedInput = useRef<AiSuggestionInput>({
    elements,
    response,
    languageLocaleCode: activeOrg.languageLocaleCode,
    model: { temperature: normalizedTemperature },
  });

  const { data } = useMultiAiSuggestionsSubscription({
    variables: { input: freezedInput.current },
    onSubscriptionComplete: () => setIsLoading(false),
  });

  const results = data?.aiSuggestionStream || [];

  if (results.length === 0) {
    return null;
  }

  return (
    <Flex direction={'column'} gap={12} css={css({ marginBottom: 12 })}>
      {results.map((suggestion, index) => (
        <ResultsPageItem
          key={index}
          index={index}
          suggestion={suggestion}
          onSelect={() => onSuggestionSelected(suggestion)}
        />
      ))}
    </Flex>
  );
};

export default ResultsPage;
