import { memo } from 'react';
import styled from '@emotion/styled';

import { useActiveOrg } from 'org/ActiveOrgProvider';
import type { OrgUnit } from 'types.graphql.generated';
import Tooltip from 'shared/components/Tooltip';

type Size = 'tiny' | 'small' | 'medium';

const sizeStyles = {
  borderRadius: {
    tiny: '4px',
    small: '.5rem',
    medium: '.75rem',
  },
  fontSize: {
    tiny: '.8rem',
    small: '.875rem',
    medium: '1.5rem',
  },
  height: {
    tiny: '32px',
    small: '2.625rem',
    medium: '4.5rem',
  },
  padding: {
    tiny: '0',
    small: '.125rem',
    medium: '.125rem',
  },
  width: {
    tiny: '32px',
    small: '2.625rem',
    medium: '4.5rem',
  },
  border: {
    tiny: 'none',
    small: '',
    medium: '',
  },
};

const Container = styled.div<{ backgroundColor?: Maybe<string>; size: Size }>`
  align-items: center;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.legacyColor.colorAshGray};
  border: 2px solid ${(props) => props.theme.color.white};
  color: ${(props) => props.theme.color.white};
  display: flex;
  flex-shrink: 0;
  justify-content: center;

  border-radius: ${({ size }) => sizeStyles.borderRadius[size]};
  font-size: ${({ size }) => sizeStyles.fontSize[size]};
  height: ${({ size }) => sizeStyles.height[size]};
  padding: ${({ size }) => sizeStyles.padding[size]};
  width: ${({ size }) => sizeStyles.width[size]};
  border: ${({ size }) => sizeStyles.border[size]};
`;

type Props = {
  className?: string;
  orgUnit?: Maybe<
    Pick<OrgUnit, 'id' | 'name' | 'nameAbbreviated' | 'backgroundColorAsHex'>
  >;
  size?: Size;
};

const TeamImage = ({ orgUnit, size = 'medium', className }: Props) => {
  const { activeOrg } = useActiveOrg();

  const orgName =
    activeOrg.displayName ||
    activeOrg.displayNameAbbreviated ||
    activeOrg.domainNames[0] ||
    activeOrg.id;

  const orgUnitName = orgUnit?.name || orgUnit?.nameAbbreviated || orgUnit?.id;

  const longName = orgUnitName || orgName;

  const shortName = orgUnit
    ? orgUnit.nameAbbreviated || orgUnitName?.substring(0, 3)
    : activeOrg.displayNameAbbreviated || orgName.substring(0, 3);

  const backgroundColor = orgUnit
    ? orgUnit.backgroundColorAsHex
    : activeOrg.backgroundColorAsHex;

  return (
    <Tooltip content={longName} delay={0}>
      <Container
        size={size}
        className={className}
        role={'img'}
        aria-label={longName}
        backgroundColor={backgroundColor}
      >
        {shortName}
      </Container>
    </Tooltip>
  );
};

export default memo(TeamImage);
