import { useDefinedContext } from 'shared/utils/context.utils';

import TeamInsightsOverviewProvider from './TeamInsightsOverviewProvider';
import { TeamInsightsOverviewProviderContext } from './TeamInsightsOverviewProvider.context';

export const useTeamInsightsOverview = () =>
  useDefinedContext(TeamInsightsOverviewProviderContext, {
    hookName: useTeamInsightsOverview.name,
    providerName: TeamInsightsOverviewProvider.name,
  });
