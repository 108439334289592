import type { ListProps } from 'react-stately';
import { Item, useListState } from 'react-stately';
import type { ReactNode } from 'react';
import { useMemo, useRef } from 'react';
import { useGridList } from 'react-aria';
import cn from 'classnames';

import ListViewRowContainer from './ListViewRowContainer';
import styles from './ListView.module.scss';
import type { BaseListViewItem, ListViewRowComponent } from './ListView.type';

type ListViewProps<Value extends BaseListViewItem> = Pick<
  ListProps<Value>,
  'items'
> & {
  Row: ListViewRowComponent<Value>;
  after?: ReactNode;
  before?: ReactNode;
  className?: string;
};

/**
 * Displays a list of interactive elements.
 */
const ListView = <Item extends BaseListViewItem>({
  before: beforeProp,
  after: afterProp,
  items,
  Row,
  className,
}: ListViewProps<Item>) => {
  const state = useListState({
    items,
    children: (value) => (
      <Item>
        <Row item={value} />
      </Item>
    ),
  });
  const listElementRef = useRef<HTMLUListElement>(null);
  const { gridProps } = useGridList({ items }, state, listElementRef);

  const before = useMemo<ReactNode>(() => {
    if (beforeProp) {
      return <div className={styles.listViewBeforeContainer}>{beforeProp}</div>;
    }
    return null;
  }, [beforeProp]);

  const after = useMemo<ReactNode>(() => {
    if (afterProp) {
      return <div className={styles.listViewBeforeContainer}>{afterProp}</div>;
    }
    return null;
  }, [afterProp]);

  return (
    <ul
      {...gridProps}
      className={cn(styles.listView, className)}
      ref={listElementRef}
    >
      {before}
      {[...state.collection].map((item, itemIndex) => {
        const disableTopBorder = itemIndex === 0 && !!before;
        return (
          <ListViewRowContainer<Item>
            item={item}
            state={state}
            hasTopBorder={!disableTopBorder}
            key={item.key}
          />
        );
      })}
      {after}
    </ul>
  );
};

export default ListView;
