import { useCallback } from 'react';
import { AuthErrorCodes } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import SignUpButton from 'auth/SignUpButton';
import { isAuthError } from 'auth/auth.utils';
import { authRoutesPaths } from 'auth/auth.routing.paths';
import { useHandleUnknownError } from 'shared/errors/unknown.error';
import { useToasts } from 'shared/toast/useToasts';
import { useValidateLoggedInUser } from 'user/useValidateLoggedInUser';
import type { Provider } from 'auth/useSignUpWithProvider';
import { useSignUpWithProvider } from 'auth/useSignUpWithProvider';
import { useRedirectAfterSignUp } from 'auth/useRedirectAfterSignUp';
import { useUserValidation } from 'user/UserValidationProvider/useUserValidation';

type Props = {
  provider: Provider;
};

const ProviderSignUpButton = ({ provider }: Props) => {
  const handleUnknownError = useHandleUnknownError();

  const navigate = useNavigate();

  const { addToast } = useToasts();

  const { onInvalidated } = useUserValidation();
  const signUpWithProvider = useSignUpWithProvider();
  const validateLoggedInUser = useValidateLoggedInUser();
  const { redirectAfterSignup } = useRedirectAfterSignUp();

  const handleSignUpError = useCallback(
    (error: any) => {
      if (isAuthError(error)) {
        switch (error.code) {
          case AuthErrorCodes.POPUP_CLOSED_BY_USER: {
            return;
          }
          case AuthErrorCodes.EXPIRED_POPUP_REQUEST: {
            return;
          }
          default: {
            navigate(authRoutesPaths.signUp());
            addToast({
              id: 'signInWithEmailLinkErrorToast',
              variant: 'error',
              children: error.message,
            });
          }
        }
        return;
      }

      handleUnknownError(error);
    },
    [addToast, handleUnknownError, navigate],
  );

  const handleSignUp = useCallback(async () => {
    try {
      onInvalidated();
      const { accessToken } = await signUpWithProvider(provider);
      await validateLoggedInUser(accessToken).then(redirectAfterSignup);
    } catch (error) {
      handleSignUpError(error);
    }
  }, [
    onInvalidated,
    signUpWithProvider,
    provider,
    validateLoggedInUser,
    redirectAfterSignup,
    handleSignUpError,
  ]);

  return <SignUpButton provider={provider} onClick={handleSignUp} />;
};

export default ProviderSignUpButton;
