import { useDefinedContext } from 'shared/utils/context.utils';

import ActionsLayoutProvider from './ActionsLayoutProvider';
import { ActionsLayoutContext } from './ActionsLayoutProvider.context';

export const useActionsLayout = () =>
  useDefinedContext(ActionsLayoutContext, {
    hookName: useActionsLayout.name,
    providerName: ActionsLayoutProvider.name,
  });
