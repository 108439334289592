import cn from 'classnames';
import type { ReactElement, RefObject } from 'react';
import {
  Children,
  cloneElement,
  isValidElement,
  createRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { usePopper } from 'react-popper';

import styles from './Tabs.module.scss';
import TabsItem from './TabsItem';
import type { TabsProviderProps } from './TabsProvider';
import Provider from './TabsProvider';
import { useTabs } from './useTabs';

type ChildrenProps = Maybe<
  {
    props: {
      id: string;
      onTabClick: () => void;
      tabRef: RefObject<HTMLLIElement>;
    };
  } & ReactElement
>;

export type TabsProps = {
  children: Array<ChildrenProps>;
  className?: string;
};

const Tabs = ({ children, className }: TabsProps) => {
  const { activeTab } = useTabs();

  const [hasActiveIndicatorTransition, setHasActiveIndicatorTransition] =
    useState(false);

  const [referenceElement, setReferenceElement] =
    useState<HTMLLIElement | null>(null);

  const popperElementRef = useRef<HTMLDivElement>(null);
  const tabElementsRefs = useMemo<RefObject<HTMLLIElement>[]>(
    () => children.map(() => createRef<HTMLLIElement>()),
    [children],
  );

  const {
    styles: popperStyles,
    attributes: popperAttributes,
    forceUpdate,
  } = usePopper(referenceElement, popperElementRef.current, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -2],
        },
      },
      {
        name: 'computeStyles',
        options: {
          adaptive: false,
        },
      },
    ],
  });

  const childrenWithRefs = useMemo(
    () =>
      Children.map(children, (child, childIndex) =>
        isValidElement(child)
          ? cloneElement(child, {
              tabRef: tabElementsRefs[childIndex],
              tabIndex: childIndex,
            })
          : child,
      ),
    [children, tabElementsRefs],
  );

  useEffect(() => {
    const index = activeTab === undefined ? 0 : activeTab;
    setReferenceElement(tabElementsRefs[index].current);

    setTimeout(() => {
      forceUpdate?.();
    }, 250);
  }, [activeTab, forceUpdate, tabElementsRefs]);

  return (
    <ul
      className={cn(styles.list, className)}
      onClick={() => setHasActiveIndicatorTransition(true)}
      tabIndex={activeTab}
    >
      {childrenWithRefs}
      <div
        {...popperAttributes.popper}
        ref={popperElementRef}
        style={popperStyles.popper}
        className={cn(styles.activeIndicator, {
          [styles.activeIndicatorTransition]: hasActiveIndicatorTransition,
        })}
      />
    </ul>
  );
};

const TabsWithProvider = ({
  tabIds,
  activeTabId,
  ...props
}: TabsProps & TabsProviderProps) => (
  <Provider tabIds={tabIds} activeTabId={activeTabId}>
    <Tabs {...props} />
  </Provider>
);

TabsWithProvider.Item = TabsItem;

export default TabsWithProvider;
