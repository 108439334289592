import type { PropsWithChildren } from 'react';
import cn from 'classnames';

import styles from './ContributionSectionInnerContainer.module.scss';
type ContributionSectionInnerContainerProps = PropsWithChildren<{
  className?: string;
}>;

const ContributionSectionInnerContainer = ({
  children,
  className,
}: ContributionSectionInnerContainerProps) => (
  <section className={cn(styles.innerContainer, className)}>{children}</section>
);

export default ContributionSectionInnerContainer;
