import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import type { ConfirmationModalProps } from 'shared/components/__DEPRECATED__/ConfirmationModal';
import ConfirmationModal from 'shared/components/__DEPRECATED__/ConfirmationModal';
import type { UnlaunchStrategyParams } from 'strategy/useUnlaunchStrategy';
import { useUnlaunchStrategy } from 'strategy/useUnlaunchStrategy';

type UnlaunchStrategyConfirmationModalProps = Pick<
  ConfirmationModalProps,
  'isOpen' | 'onClose'
> &
  Pick<UnlaunchStrategyParams, 'strategyId' | 'teamAdapter'> & {
    onSuccess?: () => void;
  };

const UnlaunchStrategyConfirmationModal = ({
  isOpen,
  onClose,
  strategyId,
  teamAdapter,
  onSuccess,
}: UnlaunchStrategyConfirmationModalProps) => {
  const { t } = useTranslation();

  const { unlaunchStrategy, canUnlaunchStrategy } = useUnlaunchStrategy({
    strategyId,
    teamAdapter,
  });

  const handleConfirm = useCallback(() => {
    if (canUnlaunchStrategy) {
      unlaunchStrategy({
        onSuccess: onSuccess,
      });
    }
  }, [canUnlaunchStrategy, unlaunchStrategy, onSuccess]);

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleConfirm}
    >
      {t('strategy.unlaunchStrategyConfirmationModal.content')}
    </ConfirmationModal>
  );
};

export default UnlaunchStrategyConfirmationModal;
