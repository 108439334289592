import { useTranslation } from 'react-i18next';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

import StatusChart from 'shared/status/StatusChart';
import { useTeamInsightsOverview } from 'team/TeamInsights/TeamInsightsOverviewProvider';
import Space from 'shared/components/Space';
import Heading from 'shared/components/Heading';
import { useTeamAdapter } from 'team/TeamAdapter';
import Text from 'shared/components/Text';

import styles from './TeamInsightsOverviewChart.module.scss';

const TeamInsightsOverviewChart = () => {
  const { t } = useTranslation();

  const { teamAdapter } = useTeamAdapter();

  const { activeThemeObjectives, activeTheme, objectivesByTheme } =
    useTeamInsightsOverview();

  const activeObjectivesCount = useMemo(
    () =>
      activeThemeObjectives.reduce((accumulator, objective) => {
        if (!objective.currentObjectiveStatus?.complete) {
          return accumulator + 1;
        }
        return accumulator;
      }, 0),
    [activeThemeObjectives],
  );

  const objectivesSummary = useMemo<ReactNode>(() => {
    const teamI18nKeyFragment = teamAdapter.isOrgUnit ? 'OrgUnit' : 'Org';
    const themeI18nKeyFragment = activeTheme ? 'Theme' : 'AllThemes';
    const i18nKey = `team.teamInsights.overview.objectivesSummaryFor${themeI18nKeyFragment}And${teamI18nKeyFragment}`;
    return (
      <Text className={styles.summary}>
        {t(i18nKey, {
          objectivesCount: activeObjectivesCount,
          themesCount: objectivesByTheme.length - 1,
        })}
      </Text>
    );
  }, [t, activeObjectivesCount, objectivesByTheme, activeTheme, teamAdapter]);

  const theme = useMemo<ReactNode>(() => {
    const title = activeTheme?.name || t('theme.allThemes');
    return (
      <Space direction={'vertical'} size={'tiny'}>
        <Heading level={4} hasMargin={false} className={styles.heading}>
          {title}
        </Heading>
        {objectivesSummary}
      </Space>
    );
  }, [activeTheme, t, objectivesSummary]);

  return (
    <Space className={styles.container}>
      {theme}
      <div className={styles.chartContainer}>
        <div className={styles.chartWrapper}>
          <StatusChart
            objectives={activeThemeObjectives}
            className={styles.chart}
          />
        </div>
      </div>
    </Space>
  );
};

export default TeamInsightsOverviewChart;
