import type * as Types from '../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
export type OrgUnitForTeamImageFragment = { __typename: 'OrgUnit', id: string, name?: string | undefined, nameAbbreviated?: string | undefined, backgroundColorAsHex?: string | undefined };

export const OrgUnitForTeamImageFragmentDoc = gql`
    fragment orgUnitForTeamImage on OrgUnit {
  id
  name
  nameAbbreviated
  backgroundColorAsHex
}
    `;