import type { PropsWithChildren } from 'react';
import cn from 'classnames';
import { useCallback } from 'react';

import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';

import styles from './Chip.module.scss';

type ChipProps = PropsWithChildren<{
  className?: string;
  onRemove: () => void;
}>;

/**
 * Allow a user to add or remove item described by keywords.
 */
const Chip = ({ children, onRemove, className }: ChipProps) => {
  const handleRemove = useCallback(() => {
    onRemove();
  }, [onRemove]);

  return (
    <span onClick={handleRemove} className={cn(styles.chip, className)}>
      <span className={styles.chipContent}>{children}</span>
      <button className={styles.chipRemoveButton}>
        <CloseIcon className={styles.chipRemoveIcon} />
      </button>
    </span>
  );
};

export default Chip;
