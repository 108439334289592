import { strategyRoomRoutesPaths } from 'strategy/StrategyRoom/StrategyRoom.routing.paths';
import type { StrategyWizardStepId } from 'strategy/StrategyWizard';
import { teamRoutesPaths } from 'team/team.routing.paths';
import type { TeamAdapter } from 'team/TeamAdapter';
import type { Strategy } from 'types.graphql.generated';

export function getEditStrategyPath(
  teamAdapter: TeamAdapter,
  strategy: Pick<Strategy, 'id'>,
  step?: StrategyWizardStepId,
) {
  const strategyRoomRoot = teamRoutesPaths.strategyRoom({
    params: { teamSlug: teamAdapter.toParam() },
  });
  const strategyRoomStrategyRoot = strategyRoomRoutesPaths.strategy.root();
  const strategyRoomEditStrategy = strategyRoomRoutesPaths.edit({
    query: {
      strategyId: strategy.id,
      step,
    },
  });

  return `${strategyRoomRoot}/${strategyRoomStrategyRoot}/${strategyRoomEditStrategy}`;
}

export function getManageThemesPath(teamAdapter: TeamAdapter) {
  const strategyRoomRoot = teamRoutesPaths.strategyRoom({
    params: { teamSlug: teamAdapter.toParam() },
  });
  const themesRoot = strategyRoomRoutesPaths.themes.root();
  const manageThemesPath = strategyRoomRoutesPaths.themes.manageThemes();

  return `${strategyRoomRoot}/${themesRoot}/${manageThemesPath}`;
}
