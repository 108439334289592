import { useTranslation } from 'react-i18next';

import type { ConfirmationModalProps } from 'shared/components/__DEPRECATED__/ConfirmationModal';
import ConfirmationModal from 'shared/components/__DEPRECATED__/ConfirmationModal';
import type { Strategy } from 'types.graphql.generated';
import { useDeleteStrategy } from 'strategy/useDeleteStrategy';

type DeleteStrategyConfirmationModalProps = Pick<
  ConfirmationModalProps,
  'isOpen' | 'onClose'
> & {
  onSuccess?: () => void;
  strategy: Pick<Strategy, 'id' | 'name'>;
};

const DeleteStrategyConfirmationModal = ({
  strategy,
  onSuccess,
  isOpen,
  onClose,
}: DeleteStrategyConfirmationModalProps) => {
  const { t } = useTranslation();
  const { deleteStrategy } = useDeleteStrategy();

  const handleConfirm = () => {
    deleteStrategy({
      strategy: strategy,
      onSuccess: () => {
        onClose();
        onSuccess?.();
      },
    });
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleConfirm}
    >
      {t('strategy.deleteStrategyConfirmationModal.content')}
    </ConfirmationModal>
  );
};

export default DeleteStrategyConfirmationModal;
