import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Result from 'shared/components/Result';
import { ReactComponent as EmptyIllustration } from 'shared/static/illustrations/illustration-empty-state.svg';
import Space from 'shared/components/Space';
import Spinner from 'shared/spinner/Spinner';

import styles from './MetricsList.module.scss';
import MetricsListSection from './MetricsListSection';
import { useMetricsListObjectives } from './MetricsListProvider';

type MetricsListProps = {
  areAllSectionsExpanded?: boolean;
  hasHeader?: boolean;
  onlyActiveMetrics?: boolean;
};

const MetricsList = ({
  hasHeader,
  areAllSectionsExpanded,
}: MetricsListProps) => {
  const { t } = useTranslation();

  const {
    filteredMetricsActive,
    allObjectives,
    isMetricsListObjectivesLoading,
  } = useMetricsListObjectives();

  const hasMetrics = useMemo(
    () => !!filteredMetricsActive?.length,
    [filteredMetricsActive],
  );

  if (isMetricsListObjectivesLoading)
    return (
      <div className={styles.spinnerContainer}>
        <Spinner.Circle />
      </div>
    );

  if (!hasMetrics)
    return (
      <Space isCentered={true}>
        <Result
          description={t('metrics.empty')}
          illustration={EmptyIllustration}
          hasBackground={true}
        />
      </Space>
    );

  return (
    <>
      {allObjectives?.map((objective) => (
        <article key={objective.id}>
          <MetricsListSection
            objective={objective}
            hasHeader={hasHeader}
            isExpanded={areAllSectionsExpanded}
          />
        </article>
      ))}
    </>
  );
};

export default MetricsList;
