import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnlaunchStrategyStrategyQueryVariables = Types.Exact<{
  strategyId: Types.Scalars['ID'];
}>;


export type UnlaunchStrategyStrategyQuery = { __typename: 'Query', strategy: { __typename: 'Strategy', id: string, isLive: boolean, orgUnit?: { __typename: 'OrgUnit', id: string } | undefined } };

export type UnlaunchStrategyUpdateOrgMutationVariables = Types.Exact<{
  orgKey: Types.Scalars['String'];
  idToRemove: Types.Scalars['ID'];
}>;


export type UnlaunchStrategyUpdateOrgMutation = { __typename: 'Mutation', updateOrg: { __typename: 'Org', id: string, currentStrategy?: { __typename: 'Strategy', id: string, isLive: boolean } | undefined } };

export type UnlaunchStrategyUpdateOrgUnitMutationVariables = Types.Exact<{
  orgUnitId: Types.Scalars['ID'];
  idToRemove: Types.Scalars['ID'];
}>;


export type UnlaunchStrategyUpdateOrgUnitMutation = { __typename: 'Mutation', updateOrgUnit: { __typename: 'OrgUnit', id: string, currentStrategy?: { __typename: 'Strategy', id: string, isLive: boolean } | undefined } };


export const UnlaunchStrategyStrategyDocument = gql`
    query UnlaunchStrategyStrategy($strategyId: ID!) {
  strategy(id: $strategyId) {
    id
    isLive
    orgUnit {
      id
    }
  }
}
    `;

/**
 * __useUnlaunchStrategyStrategyQuery__
 *
 * To run a query within a React component, call `useUnlaunchStrategyStrategyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnlaunchStrategyStrategyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnlaunchStrategyStrategyQuery({
 *   variables: {
 *      strategyId: // value for 'strategyId'
 *   },
 * });
 */
export function useUnlaunchStrategyStrategyQuery(baseOptions: Apollo.QueryHookOptions<UnlaunchStrategyStrategyQuery, UnlaunchStrategyStrategyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnlaunchStrategyStrategyQuery, UnlaunchStrategyStrategyQueryVariables>(UnlaunchStrategyStrategyDocument, options);
      }
export function useUnlaunchStrategyStrategyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnlaunchStrategyStrategyQuery, UnlaunchStrategyStrategyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnlaunchStrategyStrategyQuery, UnlaunchStrategyStrategyQueryVariables>(UnlaunchStrategyStrategyDocument, options);
        }
export type UnlaunchStrategyStrategyQueryHookResult = ReturnType<typeof useUnlaunchStrategyStrategyQuery>;
export type UnlaunchStrategyStrategyLazyQueryHookResult = ReturnType<typeof useUnlaunchStrategyStrategyLazyQuery>;
export type UnlaunchStrategyStrategyQueryResult = Apollo.QueryResult<UnlaunchStrategyStrategyQuery, UnlaunchStrategyStrategyQueryVariables>;
export const UnlaunchStrategyUpdateOrgDocument = gql`
    mutation UnlaunchStrategyUpdateOrg($orgKey: String!, $idToRemove: ID!) {
  updateOrg(
    input: {orgKeyToUpdate: $orgKey, currentStrategy: {idToRemove: $idToRemove}}
  ) {
    id
    currentStrategy {
      id
      isLive
    }
  }
}
    `;
export type UnlaunchStrategyUpdateOrgMutationFn = Apollo.MutationFunction<UnlaunchStrategyUpdateOrgMutation, UnlaunchStrategyUpdateOrgMutationVariables>;

/**
 * __useUnlaunchStrategyUpdateOrgMutation__
 *
 * To run a mutation, you first call `useUnlaunchStrategyUpdateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlaunchStrategyUpdateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlaunchStrategyUpdateOrgMutation, { data, loading, error }] = useUnlaunchStrategyUpdateOrgMutation({
 *   variables: {
 *      orgKey: // value for 'orgKey'
 *      idToRemove: // value for 'idToRemove'
 *   },
 * });
 */
export function useUnlaunchStrategyUpdateOrgMutation(baseOptions?: Apollo.MutationHookOptions<UnlaunchStrategyUpdateOrgMutation, UnlaunchStrategyUpdateOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlaunchStrategyUpdateOrgMutation, UnlaunchStrategyUpdateOrgMutationVariables>(UnlaunchStrategyUpdateOrgDocument, options);
      }
export type UnlaunchStrategyUpdateOrgMutationHookResult = ReturnType<typeof useUnlaunchStrategyUpdateOrgMutation>;
export type UnlaunchStrategyUpdateOrgMutationResult = Apollo.MutationResult<UnlaunchStrategyUpdateOrgMutation>;
export type UnlaunchStrategyUpdateOrgMutationOptions = Apollo.BaseMutationOptions<UnlaunchStrategyUpdateOrgMutation, UnlaunchStrategyUpdateOrgMutationVariables>;
export const UnlaunchStrategyUpdateOrgUnitDocument = gql`
    mutation UnlaunchStrategyUpdateOrgUnit($orgUnitId: ID!, $idToRemove: ID!) {
  updateOrgUnit(
    input: {idToUpdate: $orgUnitId, currentStrategy: {idToRemove: $idToRemove}}
  ) {
    id
    currentStrategy {
      id
      isLive
    }
  }
}
    `;
export type UnlaunchStrategyUpdateOrgUnitMutationFn = Apollo.MutationFunction<UnlaunchStrategyUpdateOrgUnitMutation, UnlaunchStrategyUpdateOrgUnitMutationVariables>;

/**
 * __useUnlaunchStrategyUpdateOrgUnitMutation__
 *
 * To run a mutation, you first call `useUnlaunchStrategyUpdateOrgUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlaunchStrategyUpdateOrgUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlaunchStrategyUpdateOrgUnitMutation, { data, loading, error }] = useUnlaunchStrategyUpdateOrgUnitMutation({
 *   variables: {
 *      orgUnitId: // value for 'orgUnitId'
 *      idToRemove: // value for 'idToRemove'
 *   },
 * });
 */
export function useUnlaunchStrategyUpdateOrgUnitMutation(baseOptions?: Apollo.MutationHookOptions<UnlaunchStrategyUpdateOrgUnitMutation, UnlaunchStrategyUpdateOrgUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlaunchStrategyUpdateOrgUnitMutation, UnlaunchStrategyUpdateOrgUnitMutationVariables>(UnlaunchStrategyUpdateOrgUnitDocument, options);
      }
export type UnlaunchStrategyUpdateOrgUnitMutationHookResult = ReturnType<typeof useUnlaunchStrategyUpdateOrgUnitMutation>;
export type UnlaunchStrategyUpdateOrgUnitMutationResult = Apollo.MutationResult<UnlaunchStrategyUpdateOrgUnitMutation>;
export type UnlaunchStrategyUpdateOrgUnitMutationOptions = Apollo.BaseMutationOptions<UnlaunchStrategyUpdateOrgUnitMutation, UnlaunchStrategyUpdateOrgUnitMutationVariables>;