import { AnimatePresence } from 'framer-motion';

import MilestoneInsightCard from 'initiative/MilestoneInsightCard';

import styles from './InitiativeOverviewMilestonesInsights.module.scss';
import type { InitiativeOverviewInitiativeFragment } from '../InitiativeOverviewProvider/InitiativeOverviewProvider.graphql.generated';

type InitiativeOverviewMilestonesInsightsProps = {
  initiative: InitiativeOverviewInitiativeFragment;
};

const InitiativeOverviewMilestonesInsights = ({
  initiative,
}: InitiativeOverviewMilestonesInsightsProps) => (
  <div className={styles.container}>
    <AnimatePresence>
      {initiative?.milestones.map((milestone) => (
        <MilestoneInsightCard
          initiative={initiative}
          milestone={milestone}
          key={milestone.id}
        />
      ))}
    </AnimatePresence>
  </div>
);

export default InitiativeOverviewMilestonesInsights;
