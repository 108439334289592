import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import type { ButtonProps } from 'shared/components/Button';
import Space from 'shared/components/Space';
import Button from 'shared/components/Button';

const Container = styled(Space)`
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export type ModalControlsProps = {
  buttons: (ButtonProps | ButtonProps[])[];
  className?: string;
  renderAfter?: () => ReactNode;
  renderBefore?: () => ReactNode;
};

const ModalControls = ({
  buttons,
  renderBefore,
  renderAfter,
  className,
}: ModalControlsProps) => (
  <Container className={className}>
    {renderAfter?.()}
    {buttons.map((button, index) => (
      <ButtonContainer key={index}>{renderButtons(button)}</ButtonContainer>
    ))}
    {renderBefore?.()}
  </Container>
);

export default ModalControls;

const renderButtons = (
  buttonProps: ButtonProps | ButtonProps[],
): ReactNode[] => {
  if (Array.isArray(buttonProps)) {
    return buttonProps.map((eachButtonProps, index) => (
      <Button key={index} {...eachButtonProps} />
    ));
  } else {
    return [<Button key={0} {...buttonProps} />];
  }
};
