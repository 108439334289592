import { createContext } from 'react';

import type { SelectObjectiveInputFieldOption } from './SelectObjectiveInput.type';

export type SelectObjectiveInputContextValue = {
  options: SelectObjectiveInputFieldOption[];
};

export const SelectObjectiveInputContext =
  createContext<SelectObjectiveInputContextValue>({
    options: [],
  });
