import useMyFollowUpItems from 'contribution/ContributionMyFollowUpsViewMore/useMyFollowUpItems';
import TimelineEntryContent from 'contribution/ContributionMyFollowUpsViewMore/TimelineEntryContent';
import NoFollowUps from 'contribution/ContributionMyFollowUpsViewMore/NoFollowUps';
import type { ContributionFollowUpsSectionUserFragment } from 'contribution/ContributionMyFollowUpsViewMore/ContributionMyFollowUpsViewMore.graphql.generated';

type Props = {
  user: ContributionFollowUpsSectionUserFragment;
};

const ContributionMyFollowUpsSectionContent = ({ user }: Props) => {
  const {
    notifications: {
      statusRequests,
      campaignRequests,
      oneThingCampaignRequests,
    },
  } = user;

  const items = useMyFollowUpItems(
    true,
    statusRequests,
    campaignRequests,
    oneThingCampaignRequests,
  );

  if (items.length === 0) {
    const hasCompletedItems = statusRequests.length > 0;

    return <NoFollowUps hasCompletedItems={hasCompletedItems} />;
  }

  return (
    <>
      {items.map((item) => (
        <TimelineEntryContent key={item.id} item={item} />
      ))}
    </>
  );
};

export default ContributionMyFollowUpsSectionContent;
