import { Navigate } from 'react-router-dom';
import { useMemo } from 'react';

import { initiativesContentRoutesPaths } from '../InitiativesContent.routing';

const InitiativesContentNavigateToList = () => {
  const to = useMemo(() => initiativesContentRoutesPaths.list(), []);

  return <Navigate to={to} replace={true} />;
};

export default InitiativesContentNavigateToList;
