import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { ReactComponent as IconAlert } from 'shared/static/icons/icon-alert.svg';

const InfoBox = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 20px;
  background-color: ${(props) => props.theme.color.backgroundLight};
  border-radius: 8px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const CampaignFormInfoBox = () => {
  const { t } = useTranslation();

  return (
    <InfoBox>
      <IconAlert />
      {t('campaign.formInfo')}
    </InfoBox>
  );
};

export default CampaignFormInfoBox;
