import { useDefinedContext } from 'shared/utils/context.utils';

import { ObjectiveDetailsContext } from './ObjectiveDetailsProvider.context';
import ObjectiveDetailsProvider from './ObjectiveDetailsProvider';

export const useObjectiveDetails = () =>
  useDefinedContext(ObjectiveDetailsContext, {
    hookName: useObjectiveDetails.name,
    providerName: ObjectiveDetailsProvider.name,
  });
