import type { TypePolicies } from '@apollo/client';

import { objectiveTypePolicies } from 'objective/objective.typePolicies';
import { metricTypePolicies } from 'metric/metric.typePolicies';
import { initiativeTypePolicies } from 'initiative/initiative.typePolicies';
import { reportTypePolicies } from 'report/report.typePolicies';
import { followUpsTypePolicies } from 'followUps/followUps.typePolicies';
import { userTypePolicies } from 'user/user.typePolicies';
import { timeLineTypePolicies } from 'shared/graphql/typePolicies/timeLine.typePolicies';
import { orgTypePolicies } from 'org/org.typePolicies';
import { orgUnitTypePolicies } from 'orgUnit/orgUnit.typePolicies';
import { actionTypePolicies } from 'actions/actions.typePolicies';
import { toolkitTypePolicies } from 'toolkit/toolkit.typePolicies';

export const typePolicies: TypePolicies = {
  ...timeLineTypePolicies,
  ...objectiveTypePolicies,
  ...metricTypePolicies,
  ...initiativeTypePolicies,
  ...actionTypePolicies,
  ...reportTypePolicies,
  ...followUpsTypePolicies,
  ...userTypePolicies,
  ...orgTypePolicies,
  ...orgUnitTypePolicies,
  ...toolkitTypePolicies,
};
