import type { PropsWithChildren } from 'react';
import { useState, useMemo, createContext, useCallback } from 'react';

import { useDefinedContext } from 'shared/utils/context.utils';

import type { StrategyRoomManageObjectivesObjectiveFragment } from './StrategyRoomThemesManageObjectives.graphql.generated';

type ObjectiveSelection = {
  clearSelectedObjectives: () => void;
  isSelected: (
    objective: StrategyRoomManageObjectivesObjectiveFragment,
  ) => boolean;
  selectedObjectives: StrategyRoomManageObjectivesObjectiveFragment[];
  toggleSelection: (
    objective: StrategyRoomManageObjectivesObjectiveFragment,
  ) => void;
};

const ObjectiveSelectionContext = createContext<ObjectiveSelection | undefined>(
  undefined,
);

export const useObjectiveSelection = () =>
  useDefinedContext(ObjectiveSelectionContext);

const ObjectiveSelectionProvider = ({ children }: PropsWithChildren) => {
  const [selectedObjectives, setSelectedObjectives] = useState<
    StrategyRoomManageObjectivesObjectiveFragment[]
  >([]);

  const isSelected = useCallback(
    (objective: StrategyRoomManageObjectivesObjectiveFragment) =>
      selectedObjectives.some(
        (selectedObjective) => selectedObjective.id === objective.id,
      ),
    [selectedObjectives],
  );

  const toggleSelection = useCallback(
    (objective: StrategyRoomManageObjectivesObjectiveFragment) =>
      setSelectedObjectives(
        isSelected(objective)
          ? selectedObjectives.filter(
              (selectedObjective) => selectedObjective.id !== objective.id,
            )
          : [...selectedObjectives, objective],
      ),
    [isSelected, selectedObjectives],
  );

  const value = useMemo(
    () => ({
      selectedObjectives,
      isSelected,
      toggleSelection,
      clearSelectedObjectives: () => setSelectedObjectives([]),
    }),
    [isSelected, selectedObjectives, toggleSelection],
  );

  return (
    <ObjectiveSelectionContext.Provider value={value}>
      {children}
    </ObjectiveSelectionContext.Provider>
  );
};

export default ObjectiveSelectionProvider;
