import { createContext } from 'react';

import type { SelectThemeInputOption } from './SelectThemeInput.type';

export type SelectThemeInputContextValue = {
  options: SelectThemeInputOption[];
};

export const SelectThemeInputContext =
  createContext<SelectThemeInputContextValue>({
    options: [],
  });
