import type { InitiativeOverviewInitiativeStatusFragment } from 'initiative/InitiativeOverview/InitiativeOverviewProvider/InitiativeOverviewProvider.graphql.generated';
import { ReactComponent as MilestoneIcon } from 'shared/static/icons/icon-milestone.svg';
import StatusMark from 'shared/status/StatusMark/StatusMark';
import CompletionRate from 'shared/components/CompletionRate/CompletionRate';
import { hasContent } from 'shared/utils/string.utils';

import styles from './InitiativeOverviewMilestoneStatusSectionItem.module.scss';

type InitiativeOverviewMilestoneStatusSectionItemProps = {
  milestoneStatus: InitiativeOverviewInitiativeStatusFragment['milestoneStatuses']['0'];
};

const InitiativeOverviewMilestoneStatusSectionItem = ({
  milestoneStatus: {
    milestone,
    statusIndicator,
    complete,
    completionRate,
    comment,
  },
}: InitiativeOverviewMilestoneStatusSectionItemProps) => (
  <div className={styles.container}>
    <div className={styles.head}>
      <MilestoneIcon className={styles.icon} />
      <StatusMark statusIndicator={statusIndicator} hasBorder={false} />
      {milestone.name}
      <div className={styles.completionRate}>
        <CompletionRate complete={complete} completionRate={completionRate} />
      </div>
    </div>
    {hasContent(comment) && <div className={styles.comment}>{comment}</div>}
  </div>
);

export default InitiativeOverviewMilestoneStatusSectionItem;
