import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { Field } from 'formik';

import { yup } from 'shared/services/yup.service';
import Form from 'shared/form/Form';
import Spinner from 'shared/spinner/Spinner';
import FieldBox from 'shared/form/FieldBox';
import { TextInputField } from 'shared/components/TextInput';
import AvatarInputField from 'shared/components/AvatarInput/AvatarInput.field';
import MultiFieldRow from 'shared/form/MultiFieldRow';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import type { Authority, UserInput } from 'types.graphql.generated';
import {
  SelectOrgUnitInputField,
  SelectOrgUnitInputProvider,
  type SelectOrgUnitInputFieldOption,
} from 'orgUnit/SelectOrgUnitInput';
import useCanPerformOrgAction from 'user/ability/useCanPerformOrgAction';

import { useUserProfileFormQuery } from './UserProfileForm.graphql.generated';
import RoleMultiSelectField from './RoleMultiSelectField';

type UserFormValues = {
  authorities: Authority[];
  displayName: string;
  email?: string;
  id?: string;
  orgUnit: SelectOrgUnitInputFieldOption;
  photoUrl?: string;
};

export const userFormId = 'user';

type Props = {
  initialValues: UserFormValues;
  onSubmit: (values: UserInput) => void;
};

const UserProfileForm = ({ initialValues, onSubmit }: Props) => {
  const { t } = useTranslation();

  const handleApolloError = useHandleApolloError();

  const { data } = useUserProfileFormQuery({
    onError: handleApolloError,
  });

  const handleSubmit = useCallback(
    (values: UserFormValues) =>
      onSubmit({
        idToUpdate: values.id,
        email: values.email,
        displayName: values.displayName,
        photoUrl: values.photoUrl || null,
        authorities: {
          add: values.authorities.filter(
            (role) => !initialValues.authorities.includes(role),
          ),
          remove: initialValues.authorities.filter(
            (role) => !values.authorities.includes(role),
          ),
        },
        orgUnit: values.orgUnit.value?.id
          ? { idToSet: values.orgUnit.value.id }
          : values.id
          ? { idToRemove: initialValues.orgUnit.value?.id }
          : undefined,
      }),
    [initialValues.authorities, initialValues.orgUnit.value?.id, onSubmit],
  );

  const allowedOrgWriteAccount = useCanPerformOrgAction('WRITE_ACCOUNT');

  if (!data) return <Spinner.Circle />;

  const isCreate = !initialValues.id;

  const roles =
    initialValues.authorities.length === 0
      ? t('role.member')
      : initialValues.authorities.map((role) => t(`role.${role}`)).join(', ');

  const validationSchema = isCreate
    ? yup.object({ email: yup.string().email().required() })
    : undefined;

  return (
    <Form<UserFormValues>
      id={userFormId}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ hasError }) => (
        <>
          <section>
            <FieldBox name={'photoUrl'}>
              <Field name={'photoUrl'} component={AvatarInputField} />
            </FieldBox>
          </section>
          <section>
            <MultiFieldRow>
              <FieldBox
                name={'email'}
                label={t('user.editProfile.fields.email.label')}
                hasMargin={false}
              >
                {isCreate ? (
                  <Field
                    name={'email'}
                    component={TextInputField}
                    hasError={hasError('email')}
                  />
                ) : (
                  initialValues.email
                )}
              </FieldBox>
              <FieldBox
                name={'authorities'}
                label={t('user.editProfile.fields.role.label')}
                hasMargin={false}
              >
                {allowedOrgWriteAccount ? (
                  <Field
                    name={'authorities'}
                    component={RoleMultiSelectField}
                  />
                ) : (
                  roles
                )}
              </FieldBox>
            </MultiFieldRow>
          </section>
          <section>
            <FieldBox
              name={'displayName'}
              label={t('user.editProfile.fields.displayName.label')}
            >
              <Field
                name={'displayName'}
                component={TextInputField}
                hasError={hasError('displayName')}
                maxLength={100}
              />
            </FieldBox>
          </section>
          <section>
            <FieldBox
              name={'orgUnit'}
              label={t('user.editProfile.fields.team.label')}
              hasMargin={false}
            >
              <SelectOrgUnitInputProvider>
                <Field
                  name={'orgUnit'}
                  component={SelectOrgUnitInputField}
                  size={'full'}
                  isDisabled={!allowedOrgWriteAccount}
                />
              </SelectOrgUnitInputProvider>
            </FieldBox>
          </section>
        </>
      )}
    </Form>
  );
};

export default UserProfileForm;
