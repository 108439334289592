/* eslint-disable i18next/no-literal-string */
import type { MouseEvent, ReactNode } from 'react';
import { useMemo, useCallback, useState } from 'react';
import {
  Button,
  Calendar,
  CalendarCell,
  CalendarGrid,
  DateInput,
  DatePicker as AriaDatePicker,
  DateSegment,
  Dialog,
  Heading,
  Popover,
} from 'react-aria-components';
import { I18nProvider } from '@react-aria/i18n';
import { useTranslation } from 'react-i18next';
import {
  CalendarDate,
  fromDate,
  toCalendarDate,
} from '@internationalized/date';
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';
import { getDate, getMonth, getYear } from 'date-fns';

import { ReactComponent as CalendarIcon } from 'shared/static/icons/icon-calendar-2.svg';

import ClearButton from './ClearButton';
import Text from '../Text';
import Group from './Group';
import getCalendarStyle from './getCalendarStyle';
import getPopoverStyle from './getPopoverStyle';

export type DatePickerProps = {
  grow?: boolean;
  hasError?: boolean;
  labelPrefix?: string;
  maxDate?: Date | undefined | null;
  minDate?: Date | undefined | null;
  name?: string;
  onBlur?: (event: any) => void;
  onChange?: (value?: Maybe<Date>) => void;
  placeholder?: ReactNode;
  value?: Maybe<Date>;
};

const LabelPrefix = styled(Text)`
  white-space: pre;
`;

const CalendarButton = styled(Button)`
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  border-radius: 4px;

  svg {
    width: 18px;
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px ${(props) => props.theme.color.primary};
  }
`;

const defaultPlaceholderValue = new CalendarDate(2024, 1, 1);

const DatePicker = ({
  name,
  value,
  onChange,
  onBlur,
  minDate,
  maxDate,
  labelPrefix,
  placeholder,
  hasError,
  grow,
}: DatePickerProps) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [placeholderValue, setPlaceholderValue] = useState(
    defaultPlaceholderValue,
  );

  const parsedValue = useParsedDate(value);
  const parsedMinDate = useParsedDate(minDate);
  const parsedMaxDate = useParsedDate(maxDate);

  const handleChange = useCallback(
    (newValue: CalendarDate | null) => {
      const timezone = getTimezone();

      const result = newValue ? newValue.toDate(timezone) : null;

      onChange?.(result);
    },
    [onChange],
  );

  const onPlaceholderClick = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    setIsInputVisible(true);
  }, []);

  const hasValue = !!value;
  const showInput = hasValue || isInputVisible;
  const showPlaceholder = !showInput;
  const showClear = hasValue;

  return (
    <I18nProvider locale={i18n.language}>
      <AriaDatePicker
        id={name}
        css={[css({ minWidth: 145 }), grow && { flexGrow: 1 }]}
        placeholderValue={placeholderValue}
        defaultValue={null}
        value={parsedValue || null}
        onChange={handleChange}
        onBlur={onBlur}
        onFocusChange={(isFocused) => !isFocused && setIsInputVisible(false)}
        minValue={parsedMinDate}
        maxValue={parsedMaxDate}
        autoFocus={isInputVisible}
        isInvalid={hasError}
        onOpenChange={(isOpen: boolean) => {
          if (isOpen) {
            const today = new Date();

            const day = getDate(today);
            const month = getMonth(today) + 1; // +1 because months are 0-indexed
            const year = getYear(today);

            setPlaceholderValue(new CalendarDate(year, month, day));
          } else {
            /*
             * Sets the placeholder date to a month with 31 days to avoid not being able to
             * enter 31 in the first field before entering the month.
             *
             * See https://github.com/adobe/react-spectrum/issues/3256#issuecomment-1167326320
             */
            setPlaceholderValue(defaultPlaceholderValue);
          }
        }}
      >
        <Group hasError={hasError}>
          <div slot={'input'}>
            {labelPrefix && <LabelPrefix>{`${labelPrefix}: `}</LabelPrefix>}
            <span
              slot={'placeholder'}
              onClick={onPlaceholderClick}
              aria-invalid={hasError}
              css={css({ display: showPlaceholder ? 'block' : 'none' })}
            >
              {placeholder ? (
                placeholder
              ) : (
                <Text>{t('datePicker.placeholder')}</Text>
              )}
            </span>
            {showInput && (
              <DateInput>
                {(segment) => <DateSegment segment={segment} />}
              </DateInput>
            )}
          </div>
          <div slot={'controls'}>
            {showClear && <ClearButton />}
            <CalendarButton data-test-id={'calendar-button'}>
              <CalendarIcon />
            </CalendarButton>
          </div>
        </Group>
        <Popover css={getPopoverStyle(theme)}>
          <Dialog>
            <Calendar css={getCalendarStyle(theme)}>
              <header>
                <Button slot={'previous'}>◀</Button>
                <Heading />
                <Button slot={'next'}>▶</Button>
              </header>
              <CalendarGrid>
                {(date) => <CalendarCell date={date} />}
              </CalendarGrid>
            </Calendar>
          </Dialog>
        </Popover>
      </AriaDatePicker>
    </I18nProvider>
  );
};

export default DatePicker;

const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

const useParsedDate = (date?: Maybe<Date>) =>
  useMemo(
    () => (date ? toCalendarDate(fromDate(date, getTimezone())) : undefined),
    [date],
  );
