import type { PropsWithChildren } from 'react';

import type { OrgAuthorizedAction } from '../../types.graphql.generated';
import useCanPerformOrgAction from './useCanPerformOrgAction';

type Props = PropsWithChildren<{
  action: OrgAuthorizedAction;
}>;

const IfCanPerformOrgAction = ({ action, children }: Props) => {
  const canPerform = useCanPerformOrgAction(action);

  return canPerform ? <>{children}</> : null;
};

export default IfCanPerformOrgAction;
