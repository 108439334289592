const yup = require('yup');

// run only when not in webpack process
if (process.env.PUBLIC_URL === undefined) {
  require('dotenv-flow').config({
    purge_dotenv: true,
    silent: true,
    node_env: process.env.REACT_APP_ENV || 'development',
  });
}

const environmentSchema = yup.object({
  NODE_ENV: yup
    .string()
    .oneOf(['development', 'production', 'test'])
    .default('development'),
  // since we cannot override NODE_ENV variable in CRA, REACT_APP_ENV variable exists to indicate which app environment is the current one
  // https://create-react-app.dev/docs/adding-custom-environment-variables/
  REACT_APP_ENV: yup
    .string()
    .oneOf(['development', 'production', 'test'])
    .default('development'),
  REACT_APP_API_URL: yup.string().required(),
  REACT_APP_IS_MOCK_API_ENABLED: yup.boolean().default(false),
  REACT_APP_FIREBASE_CONFIG: yup.object().required(),
  SCHEMA_URL: yup.string(),
});

module.exports = environmentSchema.validateSync(process.env, {
  abortEarly: false,
  strict: false,
});
