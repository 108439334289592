import type { Animation } from 'shared/types/animation.type';

export const fadeAnimation: Animation = {
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  initial: { opacity: 0 },
  transition: {
    duration: 0.2,
  },
};
