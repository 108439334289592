import { css } from '@emotion/react';

import { ReactComponent as CollapseIcon } from 'shared/static/icons/icon-collapse.svg';
import { ReactComponent as ExpandIcon } from 'shared/static/icons/icon-expand.svg';

import Button from '../Button';

type Props = {
  isDisabled?: boolean;
  isExpanded: boolean;
  onToggle: () => void;
};

const ToggleExpandIconButton = ({
  isExpanded,
  isDisabled,
  onToggle,
}: Props) => (
  <Button
    icon={isExpanded ? CollapseIcon : ExpandIcon}
    variant={'icon'}
    css={css({ width: 42, height: 42, boxSizing: 'border-box' })}
    onClick={onToggle}
    disabled={isDisabled}
  />
);

export default ToggleExpandIconButton;
