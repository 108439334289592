import { Outlet } from 'react-router-dom';
import type { PropsWithChildren } from 'react';

import LayoutProvider from 'shared/components/Layout/LayoutProvider';
import Layout from 'shared/components/Layout';

type TeamLayoutProps = PropsWithChildren<object>;

const TeamLayout = ({ children }: TeamLayoutProps) => (
  <Layout>
    <Outlet />
    {children}
  </Layout>
);

export default (props: TeamLayoutProps) => (
  <LayoutProvider>
    <TeamLayout {...props} />
  </LayoutProvider>
);
