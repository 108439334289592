import { ReactComponent as CalendarIcon } from 'shared/static/icons/icon-calendar.svg';
import { ReactComponent as LayoutHorizontalIcon } from 'shared/static/icons/icon-layout-horizontal.svg';
import Toggle from 'shared/components/Toggle';

import { useInitiativesContentLayout } from '../InitiativesContentLayoutProvider/useInitiativesContentLayout';
import InitiativesContentLayoutProvider from '../InitiativesContentLayoutProvider';

const InitiativesContentLayoutToggle = () => {
  const { layout, toggleLayout } = useInitiativesContentLayout();

  return (
    <Toggle
      firstIcon={LayoutHorizontalIcon}
      secondIcon={CalendarIcon}
      handleToggle={toggleLayout}
      firstChecked={layout === 'list'}
    />
  );
};

export default () => (
  <InitiativesContentLayoutProvider>
    <InitiativesContentLayoutToggle />
  </InitiativesContentLayoutProvider>
);
