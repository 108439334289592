import { useDefinedContext } from 'shared/utils/context.utils';

import StrategyObjectivesProvider from './StrategyObjectivesProvider';
import { StrategyObjectivesProviderContext } from './StrategyObjectivesProvider.context';

export const useStrategyObjectives = () =>
  useDefinedContext(StrategyObjectivesProviderContext, {
    hookName: useStrategyObjectives.name,
    providerName: StrategyObjectivesProvider.name,
  });
