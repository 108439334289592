import styled from '@emotion/styled';

import { ReactComponent as ObjectiveIcon } from 'shared/static/icons/icon-flag.svg';
import { ReactComponent as MetricIcon } from 'shared/static/icons/icon-chart.svg';
import { ReactComponent as InitiativeIcon } from 'shared/static/icons/icon-initiative.svg';
import { ReactComponent as ActionIcon } from 'shared/static/icons/icon-check-square.svg';
import type {
  Action,
  Initiative,
  Metric,
  Objective,
} from 'types.graphql.generated';

const Container = styled.div`
  display: flex;

  svg {
    width: 16px;
    height: 16px;
    color: ${(props) => props.theme.color.neutral2};
  }
`;

type StrategyElementType =
  | Objective['__typename']
  | Metric['__typename']
  | Initiative['__typename']
  | Action['__typename'];

type Props = {
  type: StrategyElementType;
};

const StrategyElementIcon = ({ type }: Props) => {
  return <Container>{getIcon(type)}</Container>;
};

export default StrategyElementIcon;

function getIcon(type: StrategyElementType) {
  switch (type) {
    case 'Objective':
      return <ObjectiveIcon />;
    case 'Metric':
      return <MetricIcon />;
    case 'Initiative':
      return <InitiativeIcon />;
    case 'Action':
      return <ActionIcon />;
  }
}
