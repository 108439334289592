import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import type { StrategyAttributeDefinitionType } from 'types.graphql.generated';
import Spinner from 'shared/spinner/Spinner';
import useHandleApolloError from 'shared/errors/useHandleApolloError';

import { StrategyProfileAttributesContext } from './StrategyProfileAttributesProvider.context';
import { useActiveOrgStrategyProfileAttributesQuery } from './StrategyProfileAttributesProvider.graphql.generated';

type StrategyProfileAttributesProviderProps = PropsWithChildren<{
  types?: StrategyAttributeDefinitionType[];
}>;

const StrategyProfileAttributesProvider = ({
  types,
  children,
}: StrategyProfileAttributesProviderProps) => {
  const handleApolloError = useHandleApolloError();

  const { data, loading } = useActiveOrgStrategyProfileAttributesQuery({
    variables: { types },
    fetchPolicy: 'cache-and-network',
    onError: handleApolloError,
  });

  const contextValue = useMemo(
    () => ({
      isAttributesLoading: loading,
      attributes:
        data?.activeOrg.currentStrategyProfile?.strategyAttributeDefinitions.map(
          (attributeDefinition) => ({
            name: attributeDefinition.name,
            title: attributeDefinition.title,
          }),
        ),
    }),
    [
      data?.activeOrg.currentStrategyProfile?.strategyAttributeDefinitions,
      loading,
    ],
  );

  if (contextValue) {
    return (
      <StrategyProfileAttributesContext.Provider value={contextValue}>
        {children}
      </StrategyProfileAttributesContext.Provider>
    );
  }

  if (!data) {
    return <Spinner.Circle />;
  }

  return null;
};

export default StrategyProfileAttributesProvider;
