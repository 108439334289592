import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { css } from '@emotion/react';

import SignUpLayout from 'auth/SignUp/SignUpLayout';
import { useSignUpWithEmail } from 'auth/useSignUpWithEmail';
import SignUpWithEmailForm from 'auth/SignUpWithEmailForm';
import Space from 'shared/components/Space';
import Button from 'shared/components/Button';

const formId = 'signUpWithoutEmailForm';

const FinishSignUpWithoutEmail = () => {
  const { setEmail } = useSignUpWithEmail();
  const { t } = useTranslation();
  const location = useLocation();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = useCallback(
    async (email: string) => {
      setEmail(email);
      setIsSubmitted(true);
    },
    [setEmail],
  );

  const handleSuccess = useCallback(() => {
    // Allows the disabled sign in button to re-render
    setTimeout(() => {
      window.location = location.state.returnTo;
    }, 200);
  }, [location.state.returnTo]);

  return (
    <SignUpLayout
      heading={t('signUp.heading')}
      description={t('signUpWithoutEmail.description')}
    >
      <SignUpWithEmailForm
        id={formId}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
      />
      <Space direction={'vertical'} isCentered={true}>
        <Button
          type={'submit'}
          variant={'contained'}
          form={formId}
          disabled={isSubmitted}
          css={css`
            width: 100%;
            height: 3.75rem;
            margin-top: 1.25rem;
          `}
        >
          {t('signUpWithoutEmail.button')}
        </Button>
      </Space>
    </SignUpLayout>
  );
};

export default FinishSignUpWithoutEmail;
