import { useNavigate } from 'react-router-dom';

import GlobalSpinner from 'shared/spinner/GlobalSpinner';
import CreateMetricStatusDialog from 'metric/CreateMetricStatusDialog';
import { useMetricOverview } from 'metric/MetricOverview/MetricOverviewProvider';
import { TeamAdapter, useTeamAdapter } from 'team/TeamAdapter';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';

const CreateMetricStatusPage = () => {
  const navigate = useNavigate();
  const { metric } = useMetricOverview();
  const { teamAdapter } = useTeamAdapter();

  const teamSlug = metric?.orgUnit
    ? TeamAdapter.fromOrgUnit(metric.orgUnit, teamAdapter.orgKey).toParam()
    : teamAdapter.toParam();

  if (!metric) return <GlobalSpinner />;

  return (
    <CreateMetricStatusDialog
      isOpen={true}
      metric={metric}
      onClose={() =>
        navigate(
          objectiveRoutesPaths.metrics.metric.insights.root({
            params: {
              teamSlug,
              metricId: metric.id,
              objectiveId: metric.objective?.id || '',
            },
          }),
        )
      }
    />
  );
};

export default CreateMetricStatusPage;
