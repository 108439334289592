import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateLoggedInUserMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ValidateLoggedInUserMutation = { __typename: 'Mutation', validateLoggedInUser: { __typename: 'User', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, authorities: Array<Types.Authority>, org?: { __typename: 'Org', id: string, orgKey: string } | undefined } };


export const ValidateLoggedInUserDocument = gql`
    mutation ValidateLoggedInUser {
  validateLoggedInUser {
    id
    email
    displayName
    photoUrl
    authorities
    org {
      id
      orgKey
    }
  }
}
    `;
export type ValidateLoggedInUserMutationFn = Apollo.MutationFunction<ValidateLoggedInUserMutation, ValidateLoggedInUserMutationVariables>;

/**
 * __useValidateLoggedInUserMutation__
 *
 * To run a mutation, you first call `useValidateLoggedInUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateLoggedInUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateLoggedInUserMutation, { data, loading, error }] = useValidateLoggedInUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useValidateLoggedInUserMutation(baseOptions?: Apollo.MutationHookOptions<ValidateLoggedInUserMutation, ValidateLoggedInUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateLoggedInUserMutation, ValidateLoggedInUserMutationVariables>(ValidateLoggedInUserDocument, options);
      }
export type ValidateLoggedInUserMutationHookResult = ReturnType<typeof useValidateLoggedInUserMutation>;
export type ValidateLoggedInUserMutationResult = Apollo.MutationResult<ValidateLoggedInUserMutation>;
export type ValidateLoggedInUserMutationOptions = Apollo.BaseMutationOptions<ValidateLoggedInUserMutation, ValidateLoggedInUserMutationVariables>;