import { createContext } from 'react';

import type {
  StrategyObjectivesStatusFilter,
  StrategyObjectivesByTheme,
  StrategyObjectivesThemeFilter,
  StrategyObjectivesUserFilter,
  StrategyObjectivesUser,
  StrategyObjectivesStartDateFilter,
  StrategyObjectivesEndDateFilter,
  StrategyObjectivesPriorityFilter,
  StrategyObjectivesObjective,
} from '../StrategyObjectives.type';
import type { StrategyObjectivesThemeFragment } from './StrategyObjectivesProvider.graphql.generated';

export type StrategyObjectivesProviderContextValue = {
  activeObjectives: StrategyObjectivesObjective[];
  activeThemesCount: number;
  completedObjectives: StrategyObjectivesObjective[];
  filters: {
    clearFilters: () => void;
    endDateFilter: StrategyObjectivesEndDateFilter;
    priorityFilter: StrategyObjectivesPriorityFilter;
    setEndDateFilter: (value: StrategyObjectivesEndDateFilter) => void;
    setPriorityFilter: (value: StrategyObjectivesPriorityFilter) => void;
    setStartDateFilter: (value: StrategyObjectivesStartDateFilter) => void;
    setStatusIndicatorFilter: (
      newFilter: StrategyObjectivesStatusFilter,
    ) => void;
    setThemeFilter: (value: StrategyObjectivesThemeFilter) => void;
    setUserFilter: (value: StrategyObjectivesUserFilter) => void;
    startDateFilter: StrategyObjectivesStartDateFilter;
    statusIndicatorFilter: StrategyObjectivesStatusFilter;
    themeFilter: StrategyObjectivesThemeFilter;
    userFilter: StrategyObjectivesUserFilter;
  };
  objectives: StrategyObjectivesObjective[];
  objectivesByThemes: StrategyObjectivesByTheme[];
  themes: Maybe<StrategyObjectivesThemeFragment[]>;
  users: StrategyObjectivesUser[];
};

export const StrategyObjectivesProviderContext = createContext<
  StrategyObjectivesProviderContextValue | undefined
>(undefined);
