import type { PropsWithChildren } from 'react';

import Form from 'shared/form/Form';
import { useTeamAdapter } from 'team/TeamAdapter';
import type { ActionInput } from 'types.graphql.generated';
import { yup } from 'shared/services/yup.service';
import type { SelectObjectiveInputFieldOption } from 'objective/SelectObjectiveInput';
import { date } from 'shared/services/date.service';
import type { PriorityFieldValue } from 'strategy/PrioritySelect/PrioritySelect';
import { hasObjective } from 'objective/SelectObjectiveInput/SelectObjectiveInput.schema';

export type ActionFormValues = {
  description: string;
  id: string | null;
  name: string;
  objective: SelectObjectiveInputFieldOption;
  owner: string;
  priority: PriorityFieldValue | null;
  timeLine: {
    endDate?: Date | null;
  };
};

type Props = PropsWithChildren<{
  id: string;
  initialValues: ActionFormValues;
  onSubmit: (input: ActionInput) => Promise<unknown>;
}>;

const ActionForm = ({ id, initialValues, onSubmit, children }: Props) => {
  const { teamAdapter } = useTeamAdapter();

  const validationSchema = yup.object({
    name: yup.string().required(),
    owner: yup.string().email().required(),
    timeLine: yup.object({
      endDate: yup.date().required().nullable(),
    }),
    objective: yup.object().concat(hasObjective()),
  });

  const handleSubmit = (values: ActionFormValues) => {
    onSubmit({
      idToUpdate: values.id,
      orgKey: teamAdapter.orgKey,
      name: values.name,
      description: values.description,
      owner: {
        emailToSet: values.owner,
      },
      timeLine: {
        endDate: values.timeLine.endDate
          ? date.format(values.timeLine.endDate, 'yyyy-MM-dd')
          : null,
      },
      objective: values.objective.value
        ? { idToSet: values.objective.value.id }
        : values.id
        ? { idToRemove: initialValues.objective.value?.id }
        : undefined,
      priority: values.priority,
    });
  };

  return (
    <Form<ActionFormValues>
      id={id}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

export default ActionForm;
