import type { ConfirmationModalProps } from 'shared/components/__DEPRECATED__/ConfirmationModal';
import ConfirmationModal from 'shared/components/__DEPRECATED__/ConfirmationModal';
import type { UseDeleteReportParams } from 'report/useDeleteReport';
import { useDeleteReport } from 'report/useDeleteReport';

type DeleteReportConfirmationModalProps = Pick<
  ConfirmationModalProps,
  'isOpen' | 'onClose'
> &
  Pick<UseDeleteReportParams, 'report'> & {
    onSuccess?: () => void;
  };

const DeleteReportConfirmationModal = ({
  isOpen,
  onClose,
  report,
  onSuccess,
}: DeleteReportConfirmationModalProps) => {
  const { canDeleteReport, deleteReport } = useDeleteReport({ report });

  const handleConfirm = () => {
    if (canDeleteReport) {
      deleteReport({
        onSuccess,
      });
    }
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleConfirm}
    />
  );
};

export default DeleteReportConfirmationModal;
