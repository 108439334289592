import { ReactComponent as FlagIcon } from 'shared/static/icons/icon-flag.svg';
import CollapsibleList from 'shared/components/CollapsibleList';
import type { Objective } from 'types.graphql.generated';

import InitiativeRow from '../InitiativesContentListRow';
import type { InitiativesContentInitiative } from '../InitiativesContentProvider/InitiativesContentProvider.type';

type InitiativesContentListSectionProps = {
  hasHeader?: boolean;
  initiatives: Maybe<InitiativesContentInitiative[]>;
  isExpanded?: boolean;
  objectiveId: Objective['id'];
  title: Maybe<string>;
};

const InitiativesContentListSection = ({
  title,
  initiatives,
  hasHeader = true,
  isExpanded,
  objectiveId,
}: InitiativesContentListSectionProps) => (
  <CollapsibleList
    title={title}
    titleIcon={FlagIcon}
    type={'initiative'}
    hasHeader={hasHeader}
    expanded={isExpanded}
  >
    {initiatives?.map((initiative) => (
      <InitiativeRow
        initiative={initiative}
        objectiveId={objectiveId}
        key={initiative.id}
      />
    ))}
  </CollapsibleList>
);

export default InitiativesContentListSection;
