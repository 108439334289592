import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';

import type { ListViewRowProps } from 'shared/components/ListView';
import Text from 'shared/components/Text';
import UserAvatar from 'user/UserAvatar';
import UserActiveTag from 'user/UserActiveTag';
import Flex from 'shared/components/Flex';
import Tag from 'shared/components/Tag';

import type { TeamMembersListViewContextMenuItem } from '../TeamMembersListViewContextMenu';
import TeamMembersListViewContextMenu from '../TeamMembersListViewContextMenu';
import type { TeamMembersUserFragment } from '../TeamMembersProvider/TeamMembersProvider.graphql.generated';
import MembershipRow from './MembershipRow';

export type TeamMembersListViewRowProps =
  ListViewRowProps<TeamMembersUserFragment> & {
    onAction: (
      action: TeamMembersListViewContextMenuItem['id'],
      row: TeamMembersUserFragment,
    ) => void;
  };

const TeamMembersListViewRow = ({
  item: user,
  onAction,
}: TeamMembersListViewRowProps) => {
  const { t } = useTranslation();

  const orgRoles = user.authorities
    .filter((role) => role !== 'ROLE_ORG_USER')
    .map((role) => t(`role.${role}`));

  const primaryMembership = user.orgUnitMemberships.memberships.find(
    (membership) => membership.roles.includes('MEMBER'),
  );
  const otherMemberships = sortBy(
    user.orgUnitMemberships.memberships.filter(
      (membership) => !membership.roles.includes('MEMBER'),
    ),
    (membership) => membership.orgUnit.name,
  );

  const name =
    user.displayName && user.displayName !== '' ? user.displayName : user.email;

  return (
    <Flex justifyContent={'space-between'}>
      <div>
        <Flex alignItems={'center'} gap={8}>
          <UserAvatar user={user} size={'big'} showTooltip={false} />
          <Text variant={'emphasis'}>{name}</Text>
          <UserActiveTag user={user} />
          {orgRoles.map((role) => (
            <Tag key={role} size={'tiny'}>
              {role}
            </Tag>
          ))}
        </Flex>
      </div>

      <Flex gap={8}>
        <Flex direction={'column'} gap={8}>
          {primaryMembership && (
            <MembershipRow membership={primaryMembership} />
          )}
          {otherMemberships.map((membership) => (
            <MembershipRow
              key={membership.orgUnit.id}
              membership={membership}
            />
          ))}
        </Flex>

        <div
          css={css`
            width: 35px;

            button {
              width: 35px;
              height: 35px;
            }
          `}
        >
          <TeamMembersListViewContextMenu
            user={user}
            showRemove={!!user.orgUnit}
            onAction={(id) => onAction(id, user)}
          />
        </div>
      </Flex>
    </Flex>
  );
};

export default TeamMembersListViewRow;
