import type { TFunction } from 'react-i18next';
import type { Theme } from '@emotion/react';

import type { InitiativeStatus } from 'types.graphql.generated';

import type { InitiativesStatsActive } from './InitiativesContentHeader.type';

export const getInitiativesStats = <
  TStatus extends Pick<InitiativeStatus, 'statusIndicator'>,
  TInitiative extends { currentInitiativeStatus?: TStatus },
>(
  initiativesAll: Maybe<TInitiative[]>,
  initiativesActive: Maybe<TInitiative[]>,
  t: TFunction,
  theme: Theme,
) => {
  const { blocked, atRisk, onTrack, noStatus } =
    getInitiativesStatsActive(initiativesActive);

  return {
    statDone: {
      name: t('statusIndicator.COMPLETED.name'),
      value: getInitiativesStatsDone(initiativesAll),
      color: theme.color.primary,
    },
    statsActive: [
      {
        name: t('statusIndicator.UNKNOWN.name'),
        value: noStatus,
        color: theme.color.neutral2,
      },
      {
        name: t('statusIndicator.BLOCKED.name'),
        value: blocked,
        color: theme.color.error,
      },
      {
        name: t('statusIndicator.AT_RISK.name'),
        value: atRisk,
        color: theme.color.alert,
      },
      {
        name: t('statusIndicator.ON_TRACK.name'),
        value: onTrack,
        color: theme.color.success,
      },
    ],
  };
};

export const getInitiativesStatsActive = <
  TInitiative extends {
    currentInitiativeStatus?: Pick<InitiativeStatus, 'statusIndicator'>;
  },
>(
  initiatives: Maybe<TInitiative[]>,
) => {
  const initialStats = {
    blocked: 0,
    atRisk: 0,
    onTrack: 0,
    noStatus: 0,
  };

  return initiatives
    ? initiatives.reduce<InitiativesStatsActive>(
        (acc, { currentInitiativeStatus }) => ({
          noStatus: currentInitiativeStatus?.statusIndicator.value
            ? acc.noStatus
            : acc.noStatus + 1,
          blocked:
            currentInitiativeStatus?.statusIndicator.value === 'BLOCKED'
              ? acc.blocked + 1
              : acc.blocked,
          atRisk:
            currentInitiativeStatus?.statusIndicator.value === 'AT_RISK'
              ? acc.atRisk + 1
              : acc.atRisk,
          onTrack:
            currentInitiativeStatus?.statusIndicator.value === 'ON_TRACK'
              ? acc.onTrack + 1
              : acc.onTrack,
        }),
        initialStats,
      )
    : initialStats;
};

export const getInitiativesStatsDone = <
  TInitiative extends {
    currentInitiativeStatus?: Pick<InitiativeStatus, 'statusIndicator'>;
  },
>(
  initiatives: Maybe<TInitiative[]>,
) => {
  if (initiatives) {
    const initiativesDoneCount = getInitiativessCompletedCount(initiatives);

    const statDone = (initiativesDoneCount / initiatives.length) * 100;

    return Math.round(statDone);
  } else {
    return 0;
  }
};

export const getInitiativessCompletedCount = <
  TInitiative extends {
    currentInitiativeStatus?: Pick<InitiativeStatus, 'statusIndicator'>;
  },
>(
  initiatives: Maybe<TInitiative[]>,
) => {
  if (initiatives) {
    return initiatives.reduce<number>(
      (acc, { currentInitiativeStatus }) =>
        currentInitiativeStatus?.statusIndicator.isCompleted ? acc + 1 : acc,
      0,
    );
  } else {
    return 0;
  }
};
