import { createContext } from 'react';

export type ErrorCode = 'ACCESS_DENIED';

export type ErrorPageContextValue = {
  clearError: () => void;
  error?: Error;
  onError: (error: Error) => void;
  onErrorCode: (errorCode: ErrorCode) => void;
};

export const ErrorPageContext = createContext<
  ErrorPageContextValue | undefined
>(undefined);
