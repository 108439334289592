import { createContext } from 'react';

import type { InitiativeOverviewInitiative } from '../InitiativeOverview.type';

export type InitiativeContextValue = {
  initiative: Maybe<InitiativeOverviewInitiative>;
  isInitiativeLoading: boolean;
};

export const InitiativeContext = createContext<
  InitiativeContextValue | undefined
>(undefined);
