import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';

import MetricsListProvider from 'metric/MetricsList/MetricsListProvider';
import Spinner from 'shared/spinner/Spinner';
import Layout from 'shared/components/Layout';
import type { UserMultiSelectItemId } from 'user/UserMultiSelect';
import UserMultiSelect from 'user/UserMultiSelect';
import type { StatusMultiSelectItemId } from 'shared/status/StatusMultiSelect';
import StatusMultiSelect from 'shared/status/StatusMultiSelect';
import type { PriorityMultiSelectItemId } from 'shared/priority/PriorityMultiSelect';
import PriorityMultiSelect from 'shared/priority/PriorityMultiSelect';
import ToggleExpandIconButton from 'shared/components/ToggleExpandIconButton';
import CalendarControls, {
  CalendarControlsProvider,
} from 'shared/components/CalendarControls';
import CompletedFilterProvider from 'shared/components/CompletedFilterButton/CompletedFilterProvider';
import CompletedFilterButton from 'shared/components/CompletedFilterButton';
import ShowChildrenFilterButton from 'shared/components/ShowChildrenFilterButton';
import ShowChildrenFilterProvider from 'shared/components/ShowChildrenFilterButton/CompletedFilterProvider';

import StrategyMetricsProvider from './StrategyMetricsProvider';
import { useStrategyMetrics } from './StrategyMetricsProvider/useStrategyMetrics';
import MetricsListHeader from '../MetricsList/MetricsListHeader';
import styles from './StrategyMetrics.module.scss';
import StrategyMetricsFilterButton from './StrategyMetricsFilterButton';
import StrategyMetricsFiltersChipGroup from './StrategyMetricsFiltersChipGroup';
import MetricsListThemes from './MetricsListThemes/MetricsListThemes';

const StrategyMetrics = () => {
  const { t } = useTranslation();

  const [areAllSectionsExpanded, toggleAreAllSectionsExpanded] =
    useToggle(true);

  const {
    users,
    objectives,
    isStrategyMetricsLoading,
    filters: {
      statusIndicatorFilter,
      setStatusIndicatorFilter,
      userFilter,
      setUserFilter,
      priorityFilter,
      setPriorityFilter,
      clearFilters,
    },
  } = useStrategyMetrics();

  return (
    <UserMultiSelect.Provider
      selectedKeys={userFilter}
      onSelectionChange={(selection) => {
        setUserFilter([...selection] as UserMultiSelectItemId[]);
      }}
      users={users}
    >
      <StatusMultiSelect.Provider
        selectedKeys={statusIndicatorFilter}
        onSelectionChange={(selection) => {
          setStatusIndicatorFilter([...selection] as StatusMultiSelectItemId[]);
        }}
      >
        <PriorityMultiSelect.Provider
          selectedKeys={priorityFilter}
          onSelectionChange={(selection) => {
            setPriorityFilter([...selection] as PriorityMultiSelectItemId[]);
          }}
        >
          <MetricsListProvider objectives={objectives}>
            <Layout.Content
              hasPadding={false}
              className={styles.layout}
              variant={'contained'}
            >
              <MetricsListHeader
                afterStats={<StrategyMetricsFiltersChipGroup />}
                controls={<CalendarControls />}
                filters={
                  <>
                    <CompletedFilterButton />
                    <ShowChildrenFilterButton
                      activeTooltip={t(
                        'filters.showChildrenFilter.tooltip.hide.metric',
                      )}
                      inactiveTooltip={t(
                        'filters.showChildrenFilter.tooltip.show.metric',
                      )}
                    />
                    <StrategyMetricsFilterButton clearFilters={clearFilters} />
                    <ToggleExpandIconButton
                      onToggle={toggleAreAllSectionsExpanded}
                      isExpanded={areAllSectionsExpanded}
                    />
                  </>
                }
              />

              {isStrategyMetricsLoading ? (
                <Spinner.Circle />
              ) : (
                <MetricsListThemes
                  areAllSectionsExpanded={areAllSectionsExpanded}
                />
              )}
            </Layout.Content>
          </MetricsListProvider>
        </PriorityMultiSelect.Provider>
      </StatusMultiSelect.Provider>
    </UserMultiSelect.Provider>
  );
};

export default () => (
  <CalendarControlsProvider storeToSession={true}>
    <CompletedFilterProvider storeToSession={true}>
      <ShowChildrenFilterProvider storeToSession={true}>
        <StrategyMetricsProvider>
          <StrategyMetrics />
        </StrategyMetricsProvider>
      </ShowChildrenFilterProvider>
    </CompletedFilterProvider>
  </CalendarControlsProvider>
);
