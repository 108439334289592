import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import type { InitiativesContentContextValue } from './InitiativesContentProvider.context';
import { InitiativesContentContext } from './InitiativesContentProvider.context';
import {
  getInitiativesActive,
  getObjectiveInitiatives,
  getObjectivesWithInitiativesActive,
} from './InitiativesContentProvider.utils';
import type { InitiativesContentObjective } from './InitiativesContentProvider.type';

type Props = PropsWithChildren<{
  objectives?: Maybe<InitiativesContentObjective[]>;
}>;

const InitiativesContentProvider = ({ objectives, children }: Props) => {
  const contextValue = useMemo<
    InitiativesContentContextValue | undefined
  >(() => {
    const allObjectives = objectives;

    const allObjectivesActiveWithInitiativesActive =
      getObjectivesWithInitiativesActive(allObjectives);

    const allInitiatives = getObjectiveInitiatives(allObjectives);
    const allInitiativesActive = getInitiativesActive(allInitiatives);

    const filteredInitiativesActive = getObjectiveInitiatives(allObjectives);

    return {
      isInitiativesListObjectivesLoading: !objectives,
      allInitiatives,
      allInitiativesActive,
      filteredInitiativesActive,
      allObjectives,
      allObjectivesActiveWithInitiativesActive,
    };
  }, [objectives]);

  return (
    <InitiativesContentContext.Provider value={contextValue}>
      {children}
    </InitiativesContentContext.Provider>
  );
};

export default InitiativesContentProvider;
