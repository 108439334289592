import type { MenuTriggerState } from 'react-stately';
import type { ReactNode } from 'react';
import { useRef } from 'react';
import type {
  AriaPopoverProps,
  AriaMenuOptions,
  AriaMenuProps,
} from 'react-aria';
import { useMenuTrigger } from 'react-aria';
import { AnimatePresence } from 'framer-motion';
import type { SerializedStyles } from '@emotion/react';

import type { MenuTriggerComponent, BaseMenuItem } from '../Menu.type';
import MenuPopover from '../MenuPopover';

type MenuTriggerContainerProps<MenuItem extends BaseMenuItem> = Pick<
  AriaPopoverProps,
  'placement'
> &
  Pick<AriaMenuProps<MenuItem>, 'items'> & {
    Component: MenuTriggerComponent;
    children: (params: AriaMenuOptions<MenuItem>) => ReactNode;
    state: MenuTriggerState;
    testId?: string;
    triggerClassName?: string;
    triggerStyle?: SerializedStyles;
  };

const MenuTriggerContainer = <MenuItem extends BaseMenuItem>({
  state,
  Component,
  placement,
  items,
  children,
  triggerClassName,
  triggerStyle,
  testId,
}: MenuTriggerContainerProps<MenuItem>) => {
  const menuTriggerElementRef = useRef<HTMLElement>(null);

  const { menuTriggerProps, menuProps } = useMenuTrigger<MenuItem>(
    {},
    state,
    menuTriggerElementRef,
  );

  const itemsCount = (items as MenuItem[]).length || 0;
  const isTriggerDisplayed = itemsCount > 0;

  return (
    <>
      <AnimatePresence>
        {state.isOpen && (
          <MenuPopover
            triggerRef={menuTriggerElementRef}
            placement={placement}
            state={state}
          >
            {children(menuProps)}
          </MenuPopover>
        )}
      </AnimatePresence>
      {isTriggerDisplayed && (
        <Component
          state={state}
          triggerProps={menuTriggerProps}
          triggerRef={menuTriggerElementRef}
          className={triggerClassName}
          css={triggerStyle}
          testId={testId}
        />
      )}
    </>
  );
};

export default MenuTriggerContainer;
