import { useDefinedContext } from 'shared/utils/context.utils';

import ObjectiveProvider from './ObjectiveProvider';
import { ObjectiveProviderContext } from './ObjectiveProvider.context';

export const useObjective = () =>
  useDefinedContext(ObjectiveProviderContext, {
    hookName: useObjective.name,
    providerName: ObjectiveProvider.name,
  });
