import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SelectThemeInputForOrgQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SelectThemeInputForOrgQuery = { __typename: 'Query', activeOrg: { __typename: 'Org', id: string, themes: { __typename: 'ThemeConnection', edges: Array<{ __typename: 'ThemeEdge', node: { __typename: 'Theme', id: string, name?: string | undefined } }> } } };

export type SelectThemeInputForOrgThemeFragment = { __typename: 'Theme', id: string, name?: string | undefined };

export const SelectThemeInputForOrgThemeFragmentDoc = gql`
    fragment selectThemeInputForOrgTheme on Theme {
  id
  name
}
    `;
export const SelectThemeInputForOrgDocument = gql`
    query SelectThemeInputForOrg {
  activeOrg {
    id
    themes(filter: {isActive: true, orgUnitIds: {operator: IS_NULL}}) {
      edges {
        node {
          ...selectThemeInputForOrgTheme
        }
      }
    }
  }
}
    ${SelectThemeInputForOrgThemeFragmentDoc}`;

/**
 * __useSelectThemeInputForOrgQuery__
 *
 * To run a query within a React component, call `useSelectThemeInputForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectThemeInputForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectThemeInputForOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectThemeInputForOrgQuery(baseOptions?: Apollo.QueryHookOptions<SelectThemeInputForOrgQuery, SelectThemeInputForOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectThemeInputForOrgQuery, SelectThemeInputForOrgQueryVariables>(SelectThemeInputForOrgDocument, options);
      }
export function useSelectThemeInputForOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectThemeInputForOrgQuery, SelectThemeInputForOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectThemeInputForOrgQuery, SelectThemeInputForOrgQueryVariables>(SelectThemeInputForOrgDocument, options);
        }
export type SelectThemeInputForOrgQueryHookResult = ReturnType<typeof useSelectThemeInputForOrgQuery>;
export type SelectThemeInputForOrgLazyQueryHookResult = ReturnType<typeof useSelectThemeInputForOrgLazyQuery>;
export type SelectThemeInputForOrgQueryResult = Apollo.QueryResult<SelectThemeInputForOrgQuery, SelectThemeInputForOrgQueryVariables>;