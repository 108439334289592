import type { PropsWithChildren } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Spinner from 'shared/spinner/Spinner';

import type { GlobalSpinnerProviderContextValue } from './GlobalSpinnerProvider.context';
import { GlobalSpinnerProviderContext } from './GlobalSpinnerProvider.context';

type GlobalSpinnerProviderProps = PropsWithChildren<object>;

const GlobalSpinnerProvider = ({ children }: GlobalSpinnerProviderProps) => {
  const [isActive, setIsActive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isActive) {
      setIsVisible(true);
    } else {
      const hideTimeout = setTimeout(() => setIsVisible(false), 1000);

      return () => clearTimeout(hideTimeout);
    }
  }, [isActive]);

  const handleShow = useCallback(() => setIsActive(true), []);

  const handleHide = useCallback(() => setIsActive(false), []);

  const contextValue = useMemo<GlobalSpinnerProviderContextValue>(
    () => ({
      showGlobalSpinner: handleShow,
      hideGlobalSpinner: handleHide,
    }),
    [handleHide, handleShow],
  );

  return (
    <GlobalSpinnerProviderContext.Provider value={contextValue}>
      {children}
      {isVisible ? <Spinner.Bricks /> : null}
    </GlobalSpinnerProviderContext.Provider>
  );
};

export default GlobalSpinnerProvider;
