import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { useToasts } from 'shared/toast/useToasts';

export const useHandleUnknownError = () => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  return useCallback(
    (error: Error) => {
      if (error?.message === 'Failed to fetch') {
        addToast({
          variant: 'error',
          children: t('errorMessage.network'),
        });
      } else {
        addToast({
          variant: 'error',
          children: t('errorMessage.unknown'),
        });
      }
    },
    [addToast, t],
  );
};
