import { css } from '@emotion/react';

import { ReactComponent as Icon } from 'shared/static/icons/icon-info.svg';

import Tooltip from '../Tooltip';

type Props = {
  content: string;
};

const InfoIcon = ({ content }: Props) => (
  <Tooltip content={content} delay={0} css={css({ cursor: 'help' })}>
    <Icon />
  </Tooltip>
);

export default InfoIcon;
