import type { PropsWithChildren } from 'react';

import Space from 'shared/components/Space';

import TeamCardDescriptionsItem from '../TeamCardDescriptionsItem';
import styles from './TeamCardDescriptions.module.scss';

type TeamCardDescriptionsProps = PropsWithChildren<object>;

const TeamCardDescriptions = ({ children }: TeamCardDescriptionsProps) => (
  <Space
    direction={'horizontal'}
    size={'small'}
    className={styles.teamCardDescriptions}
  >
    {children}
  </Space>
);

TeamCardDescriptions.Item = TeamCardDescriptionsItem;

export default TeamCardDescriptions;
