import type { Priority } from 'types.graphql.generated';
import type { PriorityFragment } from 'strategy/useStrategyProfilePriorities/StrategyProfileFilters.graphql.generated';

import type { PriorityMultiSelectItemId } from './PriorityMultiSelect.type';

export const testPriorityAgainstId = (
  id: PriorityMultiSelectItemId,
  priority: Pick<Priority, 'number' | 'label'> | undefined,
) => {
  if (id === 'ALL') {
    return true;
  }
  if (id === 'UNKNOWN') {
    return !priority;
  }
  return priority && id === priority.number;
};

export const isPriorityMultiSelectId = (
  value: unknown,
  priorities?: PriorityFragment[],
): value is PriorityMultiSelectItemId => {
  if (isNumber(value)) {
    const numberValue = Number(value);
    return (priorities || [])
      .map((priority) => priority.number)
      .includes(numberValue);
  }
  if (typeof value === 'string') {
    return ['UNKNOWN', 'ALL'].includes(value);
  }
  return false;
};

export const isNumber = (value: unknown) => {
  const number = Number(value);

  return !isNaN(number);
};
