import { useDefinedContext } from 'shared/utils/context.utils';

import { StatusMultiSelectProviderContext } from './StatusMultiSelectProvider.context';
import StatusMultiSelectProvider from './StatusMultiSelectProvider';

export const useStatusMultiSelect = () =>
  useDefinedContext(StatusMultiSelectProviderContext, {
    hookName: useStatusMultiSelect.name,
    providerName: StatusMultiSelectProvider.name,
  });
