import styled from '@emotion/styled';

const heightBySize = {
  regular: 4,
  big: 6,
};

const Bar = styled.div<{ $backgroundColor?: string; $size: Size }>`
  overflow: hidden;
  border-radius: ${(props) => `${heightBySize[props.$size] / 2}px`};
  height: ${(props) => `${heightBySize[props.$size]}px`};
  background-color: ${(props) =>
    props.$backgroundColor || props.theme.color.neutral3};
  flex-grow: 1;
  max-height: 4px;
`;

const Fill = styled.div<{ $fillColor?: string; $ratio: number }>`
  width: ${(props) => props.$ratio}%;
  background-color: ${(props) => props.$fillColor || props.theme.color.primary};
  height: 100%;
  border-right: 2px solid white;
  box-sizing: content-box;
`;

type Size = 'regular' | 'big';

type Props = {
  backgroundColor?: string;
  fillColor?: string;
  ratio: number;
  size?: Size;
};

const ProgressBar = ({
  ratio,
  backgroundColor,
  fillColor,
  size = 'regular',
}: Props) => {
  return (
    <Bar $backgroundColor={backgroundColor} $size={size}>
      <Fill $fillColor={fillColor} $ratio={ratio} />
    </Bar>
  );
};

export default ProgressBar;
