import type { TFuncKey } from 'react-i18next';
import { Trans } from 'react-i18next';

import Text from 'shared/components/Text';
import Heading from 'shared/components/Heading';
import List from 'shared/components/List';
import { ReactComponent as IdeaIcon } from 'shared/static/icons/icon-idea.svg';
import Space from 'shared/components/Space';
import OrgTermAlsoKnownAs from 'org/OrgTermAlsoKnownAs';

import styles from '../Sidesheets.module.scss';

export type TipsAndTricksSegment = {
  article: string;
  description: string;
  heading: string;
};

type SidesheetsTipsAndTricksContentProps = {
  orgTermAlsoKnownAsI18nKey?: TFuncKey;
  segments: TipsAndTricksSegment[];
};

const SidesheetsTipsAndTricksContent = ({
  segments,
  orgTermAlsoKnownAsI18nKey,
}: SidesheetsTipsAndTricksContentProps) => (
  <>
    {segments.map(({ heading, description, article }, index) => (
      <section key={heading} className={styles.section}>
        <div className={styles.headingContainer}>
          <IdeaIcon className={styles.iconTipsTricks} />
          <Heading level={5} className={styles.heading}>
            {heading}
          </Heading>
        </div>
        <Space direction={'vertical'}>
          {orgTermAlsoKnownAsI18nKey && index === 0 && (
            <OrgTermAlsoKnownAs i18nKey={orgTermAlsoKnownAsI18nKey} />
          )}
          {description && (
            <Text variant={'emphasis'} className={styles.description}>
              {description}
            </Text>
          )}
          <Trans
            defaults={article}
            components={{
              ul: (
                <List
                  variant={'emphasis'}
                  gutterSize={'medium'}
                  className={styles.list}
                />
              ),
              li: <List.Item className={styles.listItem} />,
              span: <span />,
              strong: <Text variant={'strong'} />,
              capitalize: <Text variant={'note'} size={'tiny'} />,
            }}
          />
        </Space>
      </section>
    ))}
  </>
);

export default SidesheetsTipsAndTricksContent;
