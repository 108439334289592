import styled from '@emotion/styled';

import Emoji from 'shared/components/Emoji';

const selectedBackgroundColor = 'rgba(217, 231, 253, 0.2)';

const StepContainer = styled.div<{ isSelected: boolean }>`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.strokeMedium};
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 0.857rem;
  gap: 8px;
  padding: 24px;
  background-color: ${({ isSelected }) =>
    isSelected ? selectedBackgroundColor : 'initial'};
  border-color: ${({ isSelected, theme }) =>
    isSelected ? theme.color.primary : theme.color.strokeMedium};

  &:hover {
    background-color: ${({ isSelected, theme }) =>
      isSelected ? selectedBackgroundColor : theme.color.hoverLight};
  }
`;

const StepIcon = styled.div<{ isSelected: boolean }>`
  align-items: center;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.color.white : theme.color.backgroundDark};
  border-radius: 35px;
  display: flex;
  font-size: 2.143rem;
  height: 70px;
  justify-content: center;
  width: 70px;
`;

const StepTitle = styled.div`
  font-size: 1.142rem;
  font-weight: 700;
`;

const StepDescription = styled.div`
  color: ${({ theme }) => theme.color.typoSecondary};
  font-size: 1rem;
  text-align: center;
`;

type Props = {
  description: string;
  icon: string;
  isSelected: boolean;
  onClick: () => void;
  title: string;
};

const Step = ({ icon, title, description, isSelected, onClick }: Props) => (
  <StepContainer isSelected={isSelected} onClick={onClick}>
    <StepIcon isSelected={isSelected}>
      <Emoji icon={icon} />
    </StepIcon>
    <StepTitle>{title}</StepTitle>
    <StepDescription>{description}</StepDescription>
  </StepContainer>
);

export default Step;
