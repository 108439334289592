import type { ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import Button from 'shared/components/Button';
import Space from 'shared/components/Space';
import Chart from 'shared/components/ChartWithCustomLegend';
import Flex from 'shared/components/Flex';
import Text from 'shared/components/Text';

import {
  getChartOptions,
  getChartData,
  getChartUnit,
} from './MetricInsightsChart.utils';
import type {
  ChartData,
  MetricInsightsChartMetric,
  MetricInsightsChartTimeUnit,
} from './MetricInsightsChart.type';
import styles from './MetricInsightsChart.module.scss';

type MetricInsightsChartProps = {
  className?: string;
  hasControls?: boolean;
  metric: MetricInsightsChartMetric;
};

const MetricInsightsChart = ({
  metric,
  hasControls,
  className,
}: MetricInsightsChartProps) => {
  const { t } = useTranslation();

  const [unit, setUnit] = useState<MetricInsightsChartTimeUnit>(() =>
    getChartUnit(metric),
  );

  const chartData = useMemo<ChartData>(
    () => getChartData(metric, unit),
    [metric, unit],
  );

  const renderControlButton = useCallback(
    (unitParam: MetricInsightsChartTimeUnit) => (
      <Button
        variant={'outlined'}
        onClick={() => {
          setUnit(unitParam);
        }}
        className={cn(styles.controlsButton, {
          [styles.controlsButtonActive]: unitParam === unit,
        })}
      >
        {t(`metric.metricInsightsChart.controls.${unitParam}`)}
      </Button>
    ),
    [t, unit],
  );

  const controls = useMemo<ReactNode>(() => {
    if (hasControls) {
      return (
        <Flex justifyContent={'space-between'}>
          <Space isCentered={true} size={'tiny'} className={styles.controls}>
            {renderControlButton('day')}
            {renderControlButton('week')}
            {renderControlButton('month')}
            {renderControlButton('quarter')}
            {renderControlButton('year')}
          </Space>
          <Flex shrink={0} alignItems={'flex-end'}>
            <Text color={'regular'} css={css({ marginRight: 4 })}>
              {t('metric.metricInsightsChart.unitOfMeasure')}
              {':'}
            </Text>
            <b>
              {metric?.unitOfMeasure && metric?.unitOfMeasure !== ''
                ? metric?.unitOfMeasure
                : t('none')}
            </b>
          </Flex>
        </Flex>
      );
    }
  }, [hasControls, metric?.unitOfMeasure, renderControlButton, t]);

  const { labels, datasets } = chartData;

  return (
    <Space
      direction={'vertical'}
      value={14}
      size={'custom'}
      className={cn(
        { [styles.chartContainerWithControls]: !!hasControls },
        className,
      )}
    >
      {controls}

      <Chart
        type={'bar'}
        options={getChartOptions(chartData.datasets)}
        data={{
          labels,
          datasets: [
            {
              label: datasets.previous.label,
              //@ts-ignore: Datasets in shape of object an array can be combined properly
              data: datasets.previous.data,
              order: 3,
              backgroundColor: datasets.previous.color,
              barThickness: 20,
              borderRadius: 2,
            },
            {
              label: datasets.current.label,
              //@ts-ignore: Datasets in shape of object an array can be combined properly
              data: datasets.current.data,
              order: 1,
              backgroundColor: datasets.current.color,
              barThickness: 20,
              borderRadius: 2,
            },
            {
              label: datasets.target.label,
              //@ts-ignore: Datasets in shape of object an array can be combined properly
              data: datasets.target.data,
              order: 2,
              backgroundColor: datasets.target.color,
              barThickness: 20,
              borderRadius: 2,
            },
            {
              label: datasets.forecast.label,
              //@ts-ignore: Datasets in shape of object an array can be combined properly
              data: datasets.forecast.data,
              order: 0,
              type: 'line',
              borderColor: datasets.forecast.color,
              pointRadius: 7,
              pointStyle: 'rect',
              pointBorderWidth: 2,
              borderDash: () => [5],
              pointHoverRadius: 7,
              pointHoverBorderWidth: 2,
              borderRadius: 2,
            },
          ],
        }}
      />
    </Space>
  );

  return null;
};

export default MetricInsightsChart;
