import { createContext } from 'react';

import type { SelectOrgUnitInputFieldOption } from '../SelectOrgUnitInput.type';

export type SelectOrgUnitInputProviderContextValue = {
  options: SelectOrgUnitInputFieldOption[];
};

export const SelectOrgUnitInputProviderContext =
  createContext<SelectOrgUnitInputProviderContextValue>({
    options: [],
  });
