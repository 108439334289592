import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Emoji from 'shared/components/Emoji';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  flex-basis: 0;
`;

const Icon = styled.div`
  font-size: 24px;
`;

const Bar = styled.div<{ color: string; score?: number }>`
  width: 24px;
  height: ${(props) => (props.score ? props.score * 20 : 1)}px;
  background-color: ${(props) => props.color};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  z-index: 2;
  margin-top: 2px;
`;

type Props = {
  score?: number;
};

const OverviewSectionGraphItem = ({ score }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const ratings = [
    'STRONGLY_DISAGREE',
    'DISAGREE',
    'NEUTRAL',
    'AGREE',
    'STRONGLY_AGREE',
  ];

  const scoreIndex = score ? Math.round(score) - 1 : undefined;

  const scoreLabel = score ? score.toFixed(1) : '-';

  const colors = [
    theme.color.error,
    theme.color.error,
    theme.color.alert,
    theme.color.success,
    theme.color.success,
  ];
  const color =
    scoreIndex === undefined ? theme.color.primary : colors[scoreIndex];

  const icons = ratings.map((rating) =>
    t(`toolkit.toolPage.walkOfStrategy.survey.anwer.${rating}.icon`),
  );
  const icon = scoreIndex !== undefined ? icons[scoreIndex] : '';

  return (
    <Container>
      <Icon>
        <Emoji icon={icon} />
      </Icon>
      <strong>{scoreLabel}</strong>
      <Bar score={score} color={color} />
    </Container>
  );
};

export default OverviewSectionGraphItem;
