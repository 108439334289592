import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import Heading from 'shared/components/Heading';
import Text from 'shared/components/Text';
import { roundPercent } from 'shared/utils/percentage.utils';
import TimelineProgressBar from 'shared/components/TimelineProgressBar/TimelineProgressBar';

import type { OneThingCampaignResultsCampaignFragment } from './OneThingCampaignResults.graphql.generated';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 1.333rem;
  padding-right: 1.333rem;
  padding-bottom: 1.333rem;

  & > div {
    width: 100%;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const InfoBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  background-color: ${(props) => props.theme.color.backgroundLight};
  flex-basis: 0;
  flex-grow: 1;
  padding-top: 1.333rem;
  padding-bottom: 1.333rem;
  border-radius: 1.333rem;
`;

type Props = {
  campaign: OneThingCampaignResultsCampaignFragment;
};

const OverviewSection = ({ campaign }: Props) => {
  const { t } = useTranslation();

  const engagement = roundPercent(campaign.completionRate.percentEngaged);
  const createdTasks = campaign.completionRate.countEngaged;
  const completedTasks = campaign.completionRate.countDone;
  const completionRate = roundPercent(campaign.completionRate.percentDone);

  return (
    <Container>
      <TimelineProgressBar
        startDate={campaign.timeLine.startDate}
        deadline={campaign.deadline}
        endDate={campaign.timeLine.endDate}
      />
      <InfoContainer>
        <InfoBox>
          <Heading level={3} hasMargin={false}>
            {engagement}
            {'%'}
          </Heading>
          <Text size={'small'}>
            {t('toolkit.toolPage.oneThing.campaignResults.overview.engagement')}
          </Text>
        </InfoBox>
        <InfoBox>
          <Heading level={3} hasMargin={false}>
            {createdTasks}
          </Heading>
          <Text size={'small'}>
            {t(
              'toolkit.toolPage.oneThing.campaignResults.overview.createdTasks',
            )}
          </Text>
        </InfoBox>
        <InfoBox>
          <Heading level={3} hasMargin={false}>
            {completedTasks}
          </Heading>
          <Text size={'small'}>
            {t(
              'toolkit.toolPage.oneThing.campaignResults.overview.completedTasks',
            )}
          </Text>
        </InfoBox>
        <InfoBox>
          <Heading level={3} hasMargin={false}>
            {completionRate}
            {'%'}
          </Heading>
          <Text size={'small'}>
            {t(
              'toolkit.toolPage.oneThing.campaignResults.overview.completionRate',
            )}
          </Text>
        </InfoBox>
      </InfoContainer>
    </Container>
  );
};

export default OverviewSection;
