import cn from 'classnames';

import styles from './KeyIndicatorsTiles.module.scss';
import type { KeyIndicatorsProps } from '../KeyIndicators';
import KeyIndicatorTile from '../KeyIndicatorTile';

type KeyIndicatorsTilesProps = Omit<KeyIndicatorsProps, 'variant'>;

const KeyIndicatorsTiles = ({
  keyIndicatorOnBudget,
  keyIndicatorOnOutcome,
  keyIndicatorOnTime,
  className,
}: KeyIndicatorsTilesProps) => {
  const isAtLeastOneIndicatorDefined = true;

  if (isAtLeastOneIndicatorDefined) {
    return (
      <div className={cn(styles.keyIndicators, className)}>
        <KeyIndicatorTile name={'onTime'} value={keyIndicatorOnTime} />
        <KeyIndicatorTile name={'onBudget'} value={keyIndicatorOnBudget} />
        <KeyIndicatorTile name={'onOutcome'} value={keyIndicatorOnOutcome} />
      </div>
    );
  }

  return null;
};

export default KeyIndicatorsTiles;
