import styled from '@emotion/styled';

const Title = styled.div`
  color: ${(props) => props.theme.color.secondaryVariant};
  font-size: 0.714rem;
  font-weight: 700;
  text-transform: uppercase;
`;

export default Title;
