import type { ChartData, LayoutItem } from 'chart.js';
import type { Entries } from 'type-fest';

import { i18n } from 'shared/services/i18n.service';
import { DoughnutInnerText } from 'shared/chart/plugins/DoughnutInnerText.chartPlugin';
import { groupObjectivesByTheme } from 'objective/objective.utils';
import type { StatusIndicator as StatusIndicatorEnum } from 'types.graphql.generated';
import { StatusIndicator } from 'shared/status/StatusIndicator';

import styles from './StatusChart.module.scss';
import type {
  StatusChartValues,
  StatusChartObjective,
  StatusChartTheme,
} from './StatusChart.type';

const generateLabels = (values: StatusChartValues) =>
  values.map((value, index) => ({
    datasetIndex: index,
    text: `${value.label}: ${value.count}`,
    fillStyle: value.color,
    borderRadius: 0,
    lineWidth: 0,
  }));

export const resolveChartValues = (
  objectives?: StatusChartObjective[],
): StatusChartValues | undefined => {
  if (objectives) {
    const objectivesByTheme = groupObjectivesByTheme<
      StatusChartObjective,
      StatusChartTheme
    >(objectives);
    const objectivesStatusIndicators = objectivesByTheme.flatMap(
      ({ objectives }) =>
        objectives.map(
          (objective) => objective.currentObjectiveStatus?.statusIndicator,
        ),
    );
    const statusCounts = objectivesStatusIndicators.reduce(
      (accumulator, objectiveStatusIndicator) => {
        if (objectiveStatusIndicator?.isCompleted) {
          accumulator['COMPLETED'] += 1;
          return accumulator;
        }
        if (objectiveStatusIndicator?.value) {
          accumulator[objectiveStatusIndicator?.value] += 1;
          return accumulator;
        }
        accumulator['UNKNOWN'] += 1;
        return accumulator;
      },
      Object.fromEntries(
        ['UNKNOWN', 'ON_TRACK', 'AT_RISK', 'BLOCKED', 'COMPLETED'].map(
          (value) => [value, 0],
        ),
      ) as Record<StatusIndicatorEnum | 'COMPLETED' | 'UNKNOWN', number>,
    );
    return (Object.entries(statusCounts) as Entries<typeof statusCounts>).map(
      ([statusIndicatorValue, statusCount]) => {
        const statusIndicator = new StatusIndicator(
          statusIndicatorValue === 'UNKNOWN' ||
          statusIndicatorValue === 'COMPLETED'
            ? undefined
            : statusIndicatorValue,
          { isCompleted: statusIndicatorValue === 'COMPLETED' },
        );

        return {
          count: statusCount,
          label: statusIndicator.getName(),
          color: statusIndicator.getColor()!,
        };
      },
    );
  }
};

export const getChartOptions = (values: StatusChartValues) => ({
  responsive: true,
  maintainAspectRatio: true,
  cutout: '90%',
  plugins: {
    legend: {
      position: 'right',
      align: 'right',
      labels: {
        boxHeight: 10,
        boxWidth: 10,
        generateLabels: () => generateLabels(values),
      },
    } as unknown as LayoutItem,
  },
});

export const getChartData = (
  values: StatusChartValues,
): ChartData<'doughnut'> => ({
  labels: values.map((value) => value.label),
  datasets: [
    {
      data: values.map((value) => value.count),
      backgroundColor: values.map((value) => value.color),
      borderWidth: 0,
      label: i18n.t('objective.objectivesOverview.chart.objectives'),
      offset: 0,
      spacing: 0,
    },
  ],
});

export const getChartPlugins = (values: StatusChartValues) => [
  new DoughnutInnerText({
    titleText: `${values.reduce(
      (accumulator, value) => accumulator + value.count,
      0,
    )}`,
    subTitleText: i18n.t('objective.objectivesOverview.chart.objectives'),
    titleColor: styles.doughnutTitleColor,
    subTitleColor: styles.doughnutSubTitleColor,
    backgroundColor: '#ffffff',
    legendLabelColor: styles.chartLegendColor,
  }),
];
