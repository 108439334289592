import { useTranslation } from 'react-i18next';

import Space from 'shared/components/Space';
import Result from 'shared/components/Result';
import { ReactComponent as EmptyIllustration } from 'shared/static/illustrations/illustration-empty-state.svg';

import { useStrategyMetrics } from '../StrategyMetricsProvider';
import MetricsListThemesSection from './MetricsListThemesSection';

type Props = {
  areAllSectionsExpanded: boolean;
};

const MetricsListThemes = ({ areAllSectionsExpanded }: Props) => {
  const { t } = useTranslation();
  const { objectivesByTheme } = useStrategyMetrics();

  if (objectivesByTheme.length === 0)
    return (
      <Space isCentered={true}>
        <Result
          description={t('metrics.empty')}
          illustration={EmptyIllustration}
          hasBackground={true}
        />
      </Space>
    );

  return (
    <>
      {objectivesByTheme.map((eachObjectivesByTheme) => (
        <MetricsListThemesSection
          key={eachObjectivesByTheme.theme?.id || -1}
          objectivesByTheme={eachObjectivesByTheme}
          isExpanded={areAllSectionsExpanded}
        />
      ))}
    </>
  );
};

export default MetricsListThemes;
