import { date } from 'shared/services/date.service';
import type { Metric, MetricStatusInput } from 'types.graphql.generated';
import type { TeamAdapter } from 'team/TeamAdapter';

import type { MetricStatusValues } from './MetricStatusWizard.type';
import type { MetricStatusForStatusWizard } from './MetricStatusWizard';

export const resolveMetricStatusInput = (params: {
  metric: Pick<Metric, 'id'>;
  status?: MetricStatusForStatusWizard;
  teamAdapter: TeamAdapter;
  values: MetricStatusValues;
}): MetricStatusInput => {
  const { status, values, metric, teamAdapter } = params;

  return {
    idToUpdate: status?.id,
    orgKey: teamAdapter.orgKey,
    metricId: metric.id,
    statusValue: values.score?.status || null,
    forecastValue: values.score?.forecast || null,
    statusIndicator: values.status?.statusIndicator || undefined,
    comment: values.status?.comment,
    successComments: {
      add: values.comments?.successes.map((text) => ({ text })),
      idsToDelete: status?.successComments.map((comment) => comment.id),
    },
    challengeComments: {
      add: values.comments?.challenges.map((text) => ({ text })),
      idsToDelete: status?.challengeComments.map((comment) => comment.id),
    },
    actionComments: {
      add: values.comments?.actions.map((text) => ({ text })),
      idsToDelete: status?.actionComments.map((comment) => comment.id),
    },
    complete: values.status?.complete,
    statusDateTime: values.status?.date
      ? date.formatRfc3339(values.status?.date)
      : undefined,
  };
};
