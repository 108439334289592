import { useTranslation } from 'react-i18next';
import type { PropsWithChildren } from 'react';
import styled from '@emotion/styled';

import Heading from 'shared/components/Heading';

const Container = styled.header`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  padding: 0 24px;
`;

const HeadingSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 3.125rem;
`;

const StyledHeading = styled(Heading)`
  margin: 0;
`;

const Header = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <HeadingSection>
          <StyledHeading level={3}>
            {t('strategy.strategyRoom.themes.heading')}
          </StyledHeading>
          {children}
        </HeadingSection>
      </Container>
    </>
  );
};

export default Header;
