import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';
import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';
import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';
import type { ContextMenuItem } from 'shared/components/ContextMenu';
import ContextMenu from 'shared/components/ContextMenu';
import OneThingEditCampaign from 'toolkit/OneThingEditCampaign';
import type { CampaignStage } from 'types.graphql.generated';

import DeleteCampaignDialog from './DeleteCampaignDialog';
import CloseCampaignDialog from './CloseCampaignDialog';

type Props = {
  campaignId: string;
  campaignStage: CampaignStage;
};

type ItemId = 'edit' | 'close' | 'delete';

const CampaignContextMenu = ({ campaignId, campaignStage }: Props) => {
  const { t } = useTranslation();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCloseVisible, setIsCloseVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);

  const items = [
    ['INACTIVE', 'ACTIVE', 'CLOSED'].includes(campaignStage) && {
      id: 'edit' as ItemId,
      title: t('edit'),
      icon: EditIcon,
    },
    ['ACTIVE'].includes(campaignStage) && {
      id: 'close' as ItemId,
      title: t('close'),
      icon: CloseIcon,
    },
    ['INACTIVE', 'CLOSED', 'ABORTED'].includes(campaignStage) && {
      id: 'delete' as ItemId,
      title: t('remove'),
      icon: TrashIcon,
    },
  ].filter(Boolean);

  const onAction = (id: ItemId) => {
    switch (id) {
      case 'edit':
        setIsEditOpen(true);
        break;
      case 'close':
        setIsCloseVisible(true);
        break;
      case 'delete':
        setIsDeleteVisible(true);
    }
  };

  return (
    <>
      <ContextMenu<ContextMenuItem<ItemId>> items={items} onAction={onAction} />

      <OneThingEditCampaign
        campaignId={campaignId}
        isOpen={isEditOpen}
        onClose={() => setIsEditOpen(false)}
      />

      {isCloseVisible && (
        <CloseCampaignDialog
          campaignId={campaignId}
          onClose={() => setIsCloseVisible(false)}
        />
      )}

      {isDeleteVisible && (
        <DeleteCampaignDialog
          campaignId={campaignId}
          campaignStage={campaignStage}
          onClose={() => setIsDeleteVisible(false)}
        />
      )}
    </>
  );
};

export default CampaignContextMenu;
