import { Trans } from 'react-i18next';
import { useMemo } from 'react';
import cn from 'classnames';

import { date } from 'shared/services/date.service';
import AuditRecordUserAvatar from 'user/AuditRecordUserAvatar/AuditRecordUserAvatar';

import type { ObjectiveInsightsObjective } from '../ObjectiveInsights.type';
import styles from './ObjectiveInsightsActivityTimelineFirstRow.module.scss';

type ObjectiveInsightsActivityTimelineFirstRowProps = {
  isLastRow: boolean;
  objective: Maybe<
    Pick<
      ObjectiveInsightsObjective,
      'id' | 'auditRecord' | 'objectiveStatusListAll'
    >
  >;
};

const ObjectiveInsightsActivityTimelineFirstRow = ({
  objective,
  isLastRow,
}: ObjectiveInsightsActivityTimelineFirstRowProps) => {
  const createDateTime = useMemo(
    () =>
      objective?.auditRecord.createDateTime
        ? date.format(objective?.auditRecord.createDateTime, 'dd MMM yyyy')
        : '',
    [objective?.auditRecord.createDateTime],
  );

  const isOnlyItem = useMemo(
    () => !objective?.objectiveStatusListAll.length,
    [objective?.objectiveStatusListAll],
  );

  return (
    <li
      key={objective?.id}
      className={cn(styles.item, {
        [styles.lastRowItem]: isLastRow,
        [styles.onlyItem]: isOnlyItem,
      })}
    >
      <div className={styles.header}>
        <AuditRecordUserAvatar
          auditRecordUser={objective?.auditRecord.createBy}
          size={'small'}
        />
        <div className={styles.shortText}>
          <Trans
            i18nKey={'objective.insights.objectiveCreated'}
            values={{
              user:
                objective?.auditRecord.createBy?.displayName ??
                objective?.auditRecord.createBy?.email,
              date: createDateTime,
            }}
            components={{
              emphasis: <span className={styles.emphasis} />,
            }}
          />
        </div>
      </div>
    </li>
  );
};

export default ObjectiveInsightsActivityTimelineFirstRow;
