import { useState } from 'react';
import { useDebounce } from 'react-use';

export default function useDebouncedValue<T>(delay: number, value?: T) {
  const [debouncedValue, setDebouncedValue] = useState<T>();

  useDebounce(
    () => {
      setDebouncedValue(value);
    },
    delay,
    [value],
  );

  return debouncedValue;
}
