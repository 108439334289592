import {
  defaults,
  Chart,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  BarElement,
  BarController,
  ArcElement,
  DoughnutController,
} from 'chart.js';

import styles from './Chart.module.scss';

defaults.font.family = styles.defaultFontFamily;
defaults.color = styles.defaultColor;
defaults.plugins.legend = {
  ...defaults.plugins.legend,
  labels: {
    ...defaults.plugins.legend?.labels,
    font: {
      size: 16,
    },
  },
};

Chart.register(
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  LineController,
  BarController,
  ArcElement,
  DoughnutController,
);
