import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import StatusMultiSelect from 'shared/status/StatusMultiSelect';
import UserMultiSelect from 'user/UserMultiSelect';
import DatePicker from 'shared/components/DatePicker';
import PriorityMultiSelect from 'shared/priority/PriorityMultiSelect';
import Flex from 'shared/components/Flex';
import ToggleExpandIconButton from 'shared/components/ToggleExpandIconButton';
import FiltersButton from 'shared/components/FiltersButton';
import CalendarControls from 'shared/components/CalendarControls';
import CompletedFilterButton from 'shared/components/CompletedFilterButton';

import { useStrategyBoard } from '../StrategyBoardProvider';
import useChipGroupItems from '../StrategyBoardFiltersChipGroup/useChipGroupItems';

type StrategyBoardFiltersProps = {
  areAllSectionsExpanded: boolean;
  toggleAreAllSectionsExpanded: () => void;
};

const StrategyBoardFilters = ({
  areAllSectionsExpanded,
  toggleAreAllSectionsExpanded,
}: StrategyBoardFiltersProps) => {
  const { t } = useTranslation();

  const {
    filters: { clearFilters },
  } = useStrategyBoard();

  const { length: selectionCount } = useChipGroupItems();

  const {
    filters: {
      startDateFilter,
      setStartDateFilter,
      setEndDateFilter,
      endDateFilter,
    },
  } = useStrategyBoard();

  return (
    <>
      <CalendarControls />

      <Flex gap={8} css={css({ flex: '1', justifyContent: 'flex-end' })}>
        <CompletedFilterButton />

        <FiltersButton
          selectionCount={selectionCount}
          clearFilters={clearFilters}
        >
          <StatusMultiSelect />
          <PriorityMultiSelect />
          <UserMultiSelect />
          <Flex gap={'1rem'}>
            <DatePicker
              value={startDateFilter}
              onChange={setStartDateFilter}
              labelPrefix={t('from')}
              placeholder={t('allDates')}
              maxDate={endDateFilter}
              css={css({ width: '100%', flexGrow: 1 })}
              grow={true}
            />
            <DatePicker
              value={endDateFilter}
              onChange={setEndDateFilter}
              labelPrefix={t('to')}
              placeholder={t('allDates')}
              minDate={startDateFilter}
              grow={true}
            />
          </Flex>
        </FiltersButton>

        <ToggleExpandIconButton
          isExpanded={areAllSectionsExpanded}
          onToggle={toggleAreAllSectionsExpanded}
        />
      </Flex>
    </>
  );
};

export default StrategyBoardFilters;
