import styled from '@emotion/styled';
import type { ComponentType, CSSProperties, SVGProps } from 'react';
import { useMemo } from 'react';

import Heading from 'shared/components/Heading';
import Text from 'shared/components/Text';
import Space from 'shared/components/Space';

const Top = styled.div<{
  backgroundColor?: CSSProperties['backgroundColor'];
}>`
  align-items: center;
  display: flex;
  height: 15rem;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  background-color: ${(props) => props.backgroundColor || 'transparent'};
`;

const IllustrationWrapper = styled.svg`
  height: 13rem;
  width: 13rem;
`;

const Content = styled.div`
  margin-top: 1.25rem;
  padding: 0 2rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export type SideModalJumbotronProps = {
  className?: string;
  description?: string;
  heading?: string;
  top?: {
    backgroundColor?: CSSProperties['backgroundColor'];
    illustration?: Maybe<ComponentType<SVGProps<SVGSVGElement>>>;
  };
};

const SideModalJumbotron = ({
  top: topProp,
  description,
  heading,
  className,
}: SideModalJumbotronProps) => {
  const top = useMemo(() => {
    if (topProp) {
      const { backgroundColor, illustration: Illustration } = topProp;
      return (
        <Top backgroundColor={backgroundColor}>
          {Illustration ? <IllustrationWrapper as={Illustration} /> : null}
        </Top>
      );
    }
  }, [topProp]);

  const content = useMemo(
    () => (
      <Content>
        <Space size={'small'} direction={'vertical'}>
          {heading ? (
            <Heading level={2} hasMargin={false}>
              {heading}
            </Heading>
          ) : null}
          {description ? <Text>{description}</Text> : null}
        </Space>
      </Content>
    ),
    [description, heading],
  );

  return (
    <Container className={className}>
      {top}
      {content}
    </Container>
  );
};

export default SideModalJumbotron;
