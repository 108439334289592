import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';

import Caption from 'shared/components/Caption';

import styles from '../KeyIndicatorsTiles/KeyIndicatorsTiles.module.scss';

type KeyIndicatorTileProps = {
  name: string;
  value: Maybe<boolean>;
};

const KeyIndicatorTile = ({ name, value }: KeyIndicatorTileProps) => {
  const { t } = useTranslation();

  const label = t(isNil(value) ? 'noData' : value === true ? 'yes' : 'no');
  return (
    <section className={styles.keyIndicator}>
      <Caption size={'C2'} color={'secondary'}>
        {t(`initiative.initiativeMilestones.keyIndicators.${name}.heading`)}
      </Caption>
      <p className={styles.keyIndicatorValue}>{label}</p>
    </section>
  );
};

export default KeyIndicatorTile;
