import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import type { ListBoxRowComponentProps } from 'shared/components/ListBox';
import Text from 'shared/components/Text';

import type { PriorityMultiSelectItem } from '../PriorityMultiSelect.type';

type PriorityMultiSelectListBoxRowProps =
  ListBoxRowComponentProps<PriorityMultiSelectItem>;

const PriorityMultiSelectListBoxRow = ({
  item,
}: PriorityMultiSelectListBoxRowProps) => {
  const { t } = useTranslation();

  const title = useMemo(() => {
    switch (item.id) {
      case 'ALL': {
        return t('priority.allPriority');
      }
      case 'UNKNOWN': {
        return t('priority.noPriority');
      }
      default: {
        return item.label;
      }
    }
  }, [item, t]);

  return <Text variant={'emphasis'}>{title}</Text>;
};

export default PriorityMultiSelectListBoxRow;
