import type { PropsWithChildren } from 'react';

import Form from 'shared/form/Form';
import { useTeamAdapter } from 'team/TeamAdapter';
import type { MetricInput } from 'types.graphql.generated';
import { yup } from 'shared/services/yup.service';
import type { SelectObjectiveInputFieldOption } from 'objective/SelectObjectiveInput';
import { date } from 'shared/services/date.service';
import type { AttributeFieldValue } from 'shared/components/AttributesFields';
import type { PriorityFieldValue } from 'strategy/PrioritySelect/PrioritySelect';
import { hasObjective } from 'objective/SelectObjectiveInput/SelectObjectiveInput.schema';
import { timeLineValidations } from 'shared/utils/timeLine.utils';

export type MetricFormValues = {
  attributes: AttributeFieldValue[];
  description: string;
  id: string | null;
  name: string;
  objective: SelectObjectiveInputFieldOption;
  owner: string;
  priority: PriorityFieldValue | null;
  startValue: number | '';
  targetValue: number | '';
  timeLine: {
    endDate?: Date | null;
    startDate?: Date | null;
  };
  unitOfMeasure: string;
};

type Props = PropsWithChildren<{
  formId: string;
  initialValues: MetricFormValues;
  onSubmit: (input: MetricInput) => Promise<unknown>;
}>;

const MetricForm = ({ formId, initialValues, onSubmit, children }: Props) => {
  const { teamAdapter } = useTeamAdapter();

  const validationSchema = yup.object({
    name: yup.string().required(),
    owner: yup.string().email().required(),
    timeLine: timeLineValidations(),
    objective: yup.object().concat(hasObjective()),
  });

  const handleSubmit = (values: MetricFormValues) => {
    onSubmit({
      idToUpdate: values.id,
      orgKey: teamAdapter.orgKey,
      name: values.name,
      description: values.description,
      targetValue: values.targetValue === '' ? null : values.targetValue,
      startValue: values.startValue === '' ? null : values.startValue,
      unitOfMeasure: values.unitOfMeasure,
      owner: {
        emailToSet: values.owner,
      },
      timeLine: {
        startDate: values.timeLine.startDate
          ? date.format(values.timeLine.startDate, 'yyyy-MM-dd')
          : null,
        endDate: values.timeLine.endDate
          ? date.format(values.timeLine.endDate, 'yyyy-MM-dd')
          : null,
      },
      objective: values.objective.value
        ? { idToSet: values.objective.value.id }
        : values.id
        ? { idToRemove: initialValues.objective.value?.id }
        : undefined,
      priority: values.priority,
      attributes: {
        ...(values.id
          ? {
              update: values.attributes.map((attribute) => ({
                idToUpdate: attribute.id,
                description: attribute.description,
              })),
            }
          : {
              add: values.attributes.map((attribute, orderNumber) => ({
                ...attribute,
                orderNumber,
              })),
            }),
      },
    });
  };

  return (
    <Form<MetricFormValues>
      id={formId}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {children}
    </Form>
  );
};

export default MetricForm;
